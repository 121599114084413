import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../Global";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./featurelist.css";
import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";
import AddObservations1 from "./AddObservations1";
import EditObservations from "./EditObservations";
import EditSymptoms from "./EditSymptoms";
import Pagination from "react-js-pagination";

import LoadingOverlay from "react-loading-overlay";
import { ToggleButton } from "react-bootstrap";
import { ToggleButtonGroup } from "react-bootstrap";
import { expand } from "urijs";
import AddSymptoms from "./AddSymptoms";
import { useAlert } from "react-alert";

function ObservationSymptomsBO() {
  let navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [featureList, setFeatureList] = useState([]);
  const [rentalList, setRentalList] = useState([]);
  const [editShow, setEditShow] = useState(false);
  const [editSymShow, setEditSymShow] = useState(false);
  const alert = useAlert();
  const [current, setCurrent] = useState();
  const [refresh, setRefresh] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [addRentalShow, setAddRentalShow] = useState(false);
  const [category, setCategory] = useState(1);
  const [expand, setExpand] = useState();
  const [flag, setFlag] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState("");
  const [deleteType, setDeleteType] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    bindObservations();
  }, []);

  const bindObservations = () => {
    setLoading(true);
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/staff/master-observations/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {
          setLoading(false);
          const datas = res.data;

          setFeatureList(datas);
          //console.log(featureList)
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    bindSymptoms();
    bindObservations();
  }, [refresh]);

  const bindSymptoms = () => {
    setLoading(true);
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(`${APIURL}/api/v1/staff/master-symptoms/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        //console.log(res);
        if (res.status == 200) {
          setLoading(false);
          const datas = res.data;

          setRentalList(datas);

          //console.log(featureList)
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDeletePopUp = (id, type) => {
    //  e.preventDefault();
    setDeleteType(type);
    setDeleteIndex(id);
    setDeleteShow(true);
  };

  const handleDelete = (item) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    //  const dataToSend = {
    //   "id" : item
    // }

    axios
      .delete(
        `${APIURL}/api/v1/staff/master-observation-detail/${deleteIndex}/`,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.status == 204) {
          alert.success('Observation deleted successfully!!')

          setRefresh(!refresh)
        } else {
          console.log("else called ");
          //  setErrorMsg(res.data.message);
          //             setErrorSubmit(true);
        }

        setDeleteIndex("");
        setDeleteShow(false);
      })
      .catch((err) => {
        console.log("catch called");
        // setSubmitMsg("error");
        // setErrorSubmit(true);
      });
  };

  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Are you sure you want to delete?</h4>
        </Modal.Body>
        <Modal.Footer>
          

          <Button
            variant="danger"
            onClick={() => {
              if (deleteType === "observation") {
                handleDelete(deleteIndex);
              } else if (deleteType === "symptom") {
                handleDeleteSymp(deleteIndex);
              }
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitPopUp = ({ onHide }) => {
    return (
      <Modal
        show
        onHide
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-success">
            {deleteType === "observation"
              ? "Observation Deleted  Successfully!!"
              : "Symptom Deleted  Successfully!!"}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleDeleteSymp = (item) => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    //  const dataToSend = {
    //   "id" : item
    // }

    axios
      .delete(`${APIURL}/api/v1/staff/master-symptom-detail/${deleteIndex}/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 204) {
          alert.success('Symptom deleted successfully!!')

          setRefresh(!refresh)
        } else {
          console.log("else called ");
          //  setErrorMsg(res.data.message);
          //             setErrorSubmit(true);
        }

        setDeleteIndex("");
        setDeleteShow(false);
      })
      .catch((err) => {
        console.log("catch called");
        // setSubmitMsg("error");
        // setErrorSubmit(true);
      });
  };
  const handleChangeRadio = (val) => {
    setCategory(val);
  };

  const modalClose = () => {
    console.log('close')
    setEditShow(false);
    setEditSymShow(false);
    setRefresh(!refresh);
  };

  const addModalClose = () => {
    setAddShow(false);
    setRefresh(!refresh);
  };

  const rentalModalClose = () => {
    console.log('close')
    setAddRentalShow(false);
    setRefresh(!refresh);
    setFlag(true);
  };

  const notProceedClose = () => {
    setAddRentalShow(false);
    setRefresh(!refresh);
  };

  const EditPopUpObs = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Body>
          <EditObservations data={current} modalClose={modalClose} />
        </Modal.Body>
      </Modal>
    );
  };

  const EditPopUpSym = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Body>
          <EditSymptoms data={current} modalClose={modalClose} />
        </Modal.Body>
      </Modal>
    );
  };

  const handleObsClose = () => {
    bindObservations();
    setAddShow(false);
    setEditShow(false);
  };

  const handleSymClose = () => {
    bindSymptoms();
    setEditSymShow(false);
    setAddRentalShow(false);
  };

  const AddPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Body>
          <AddObservations1 modalClose={addModalClose} />
        </Modal.Body>
      </Modal>
    );
  };

  const AddSymptomsPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Body>
          <AddSymptoms
            modalClose={rentalModalClose}
            notProceedClose={notProceedClose}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const handleEditObs = (item) => {
    // const list = featureList[index];
    setCurrent(item);
    setEditShow(true);
  };

  const handleEditSym = (item) => {
    // const list = rentalList[index];
    setCurrent(item);
    setEditSymShow(true);
  };

  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index);
    } else {
      setExpand();
    }
  };
  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  let data = [];
  if (category === 1) {
    data = featureList
      .sort((a, b) => {
        if (a.observation_name.toLowerCase() < b.observation_name.toLowerCase())
          return -1;
        if (a.observation_name.toLowerCase() > b.observation_name.toLowerCase())
          return 1;
        return 0;
      })
      .filter((value) => {
        if (searchTerm === "") {
          return value;
        } else if (
          value.observation_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          return value;
        }
      });
  } else {
    data = rentalList
      .sort((a, b) => {
        if (a.symptom_name.toLowerCase() < b.symptom_name.toLowerCase())
          return -1;
        if (a.symptom_name.toLowerCase() > b.symptom_name.toLowerCase())
          return 1;
        return 0;
      })
      .filter((value) => {
        if (searchTerm === "") {
          return value;
        } else if (
          value.symptom_name.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return value;
        }
      });
  }

  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current1 = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = [];
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i);
  }
  const totalPages = pageNumbers.length;
  let dataDisplay = [];

  dataDisplay =
    category === 1 ? (
      data.length > 0 ? (
        current1.map((item, index) => {
          return (
            <div
              className="care-features"
              key={item.id}
              style={{ cursor: "pointer" }}
              onClick={() => handleDetail(index)}
            >
              <div className="feature-data">
                <h5 className="feature-title">
                  <b>
                    {" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDetail(index)}
                    >
                      {(currentPage - 1) * 10 + index + 1}.
                      {item.observation_name}
                    </span>
                    <i
                      className={
                        expand === index
                          ? "fa fa-angle-up fa-fw"
                          : "fa fa-angle-down fa-fw"
                      }
                      style={{ color: "#6788bc " }}
                    ></i>
                  </b>

                  <i
                    class="fas fa-trash-alt"
                    onClick={() => handleDeletePopUp(item.id, "observation")}
                    style={{ float: "right", color: "red", cursor: "pointer" }}
                  ></i>
                  <i
                    class="fas fa-pencil-alt"
                    onClick={() => handleEditObs(item)}
                    aria-hidden="true"
                    style={{
                      float: "right",
                      paddingRight: "25px",
                      color: "#5a9569",
                      cursor: "pointer",
                    }}
                  ></i>
                </h5>

                {expand === index ? (
                  <>
                    <div className="seperate-content" />
                    <div className="row-of-features">
                      <div className="col-of-features">
                        <h6>Observation Type: {item.observation_type}</h6>
                        <h6>Observation Code : {item.observation_code}</h6>
                        <h6>Created at : {item.created_at}</h6>
                        <h6>Updated at : {item.modified_at}</h6>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          );
        })
      ) : (
        <div
          style={{
            color: "red",
            fontSize: "25px",
            height: "200px",
            padding: "40px",
          }}
        >
          {!loading ? <h4>No Observation to show!!</h4> : ""}
        </div>
      )
    ) : 
      data.length > 0 ? (
        current1.map((item, index) => {
          return (
            <div
              className="care-features"
              key={item.id}
              style={{ cursor: "pointer" }}
              onClick={() => handleDetail(index)}
            >
              <div className="feature-data">
                <h5 className="feature-title">
                  <b>
                    {" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDetail(index)}
                    >
                      {(currentPage - 1) * 10 + index + 1}.{item.symptom_name}
                    </span>
                    <i
                      className={
                        expand === index
                          ? "fa fa-angle-up fa-fw"
                          : "fa fa-angle-down fa-fw"
                      }
                      style={{ color: "#6788bc " }}
                    ></i>
                  </b>

                  <i
                    class="fas fa-trash-alt"
                    onClick={() => handleDeletePopUp(item.id, "symptom")}
                    style={{ float: "right", color: "red", cursor: "pointer" }}
                  ></i>
                  <i
                    class="fas fa-pencil-alt"
                    onClick={() => handleEditSym(item)}
                    aria-hidden="true"
                    style={{
                      float: "right",
                      paddingRight: "25px",
                      color: "#5a9569",
                      cursor: "pointer",
                    }}
                  ></i>
                </h5>

                {/* <button onClick={()=> handleRentalEdit(index)} disable = {true} className='btn btn-primary btn-col'>Edit</button> */}
              

              {expand === index ? (
                <>
                  <div className="seperate-content" />
                  <div className="row-of-features">
                    <div className="col-of-features">
                      <h6>Symptom Type : {item.symptom_type}</h6>
                      <h6>Symptom Code: {item.symptom_code}</h6>
                      <h6>Created at : {item.created_at}</h6>
                      <h6>Updated at : {item.modified_at}</h6>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            </div>
          );
        })
      ) : (
        <div
        style={{
          color: "red",
          fontSize: "25px",
          height: "200px",
          padding: "40px",
        }}
      >
        {!loading ? <h4>No Symptoms to show!!</h4> : ""}
      </div>
      )
    
  const handleBack = (e) => {
    navigate(-1);
  };

  return (
    <>
      {/* <BackofficeNavBar /> */}
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "rgba(0, 0, 255, 0.5)",
            },
          }),
          overlay: {},
        }}
      >
        <div className="container displaylist-container ">
          {/* <button style={{ padding: "5%", marginTop: "5%" }}
          className="btn btn-info topleft-single-btn"
          onClick={handleBack}
        >
          {" "}
          <i className="fas fa-chevron-left back-btn-icon"></i>Back
        </button> */}
          <div className="header-display">
            <h2 className="title-of-page"> Add Observations and Symptoms</h2>

            <div className="search-section">
              <div className="search-div">
                <div style={{ display: "flex",marginLeft: "25%" }}>
                  <input
                    className="form-control search-input"
                    type="text"
                    placeholder="Search Here"
                    value={searchTerm}
                    maxLength="30"
                    onChange={handleSearchChange}
                  />
                  <button className="btn btn-primary btn-col search-btn">
                    {" "}
                    <i className="fas fa-search"></i>{" "}
                  </button>
                  <div className="buttons-wrapper">
              {/* <button className="btn btn-secondary" onClick={()=> setRefresh(!refresh)}>
            Refresh List <i className="fas fa-sync-alt"></i>{" "}
          </button> */}
              {category === 1 ? (
                <button
                  className="btn btn-primary btn-col"
                  style={{ marginLeft: "60%" }}
                  onClick={() => setAddShow(true)}
                >
                  Add Observations <i class="fas fa-plus"></i>
                </button>
              ) : category === 2 ? (
                <button
                  className="btn btn-primary btn-col"
                  style={{ marginLeft: "60%" }}
                  onClick={() => setAddRentalShow(true)}
                >
                  Add Symptoms <i class="fas fa-plus"></i>
                </button>
              ) : null}
            </div>
                </div>
              </div>
            </div>
           
          </div>

          <div className="mb-5 title-of-page">
            <div className="observations-symptoms-container">
            <ToggleButtonGroup
              type="radio"
              name="options"
              defaultValue={category}
              onChange={handleChangeRadio}
            >
              <ToggleButton
                id="tbg-radio-1"
                value={1}
                className="btn btn-light toggle-btns toggle-btn-1"
              >
                Observations
              </ToggleButton>
              <ToggleButton
                id="tbg-radio-2"
                value={2}
                className="btn btn-light toggle-btns toggle-btn-end"
              >
                Symptoms
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          </div>
          <div className="test-reports-display-list">
            <div className="feature-container">{dataDisplay}</div>
          </div>
          {totalPages > 1 ? (
            <div className="pagn pagn-small">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={perPage}
                totalItemsCount={total}
                pageRangeDisplayed={totalPages}
                onChange={handlePageChange}
                disabledClass="disabled-class"
              />
            </div>
          ) : null}

          {editShow ? (
            <EditPopUpObs show={editShow} onHide={() => setEditShow(false)} />
          ) : null}

          {deleteShow ? (
            <DeleteConfirmPopup
              show={deleteShow}
              onHide={() => {
                setDeleteShow(false);
                setDeleteIndex("");
              }}
            />
          ) : (
            ""
          )}

          {editSymShow ? (
            <EditPopUpSym
              show={editSymShow}
              onHide={() => {
                setEditSymShow(false);
              }}
            />
          ) : null}

          {addShow ? (
            <AddPopUp show={addShow} onHide={() => setAddShow(false)} />
          ) : null}

          {addRentalShow ? (
            <AddSymptomsPopUp
              show={addRentalShow}
              onHide={() => setAddRentalShow(false)}
            />
          ) : null}
        </div>
      </LoadingOverlay>
    </>
  );
}

export default ObservationSymptomsBO;
