import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "../ServiceRequests/service.css";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { APIURL } from "../../Global";
import { SOCKETURL } from "../../Global";
import { useNavigate, Navigate, Link } from "react-router-dom";
import EditRemark from "../Pharmacy/EditRemark";
import AddRemark from "../Pharmacy/AddRemark";
import LoadingOverlay from "react-loading-overlay";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useAlert } from "react-alert";

function AlliedServiceFulfillment() {
  const [changed, setChanged] = useState();
  var Typeahead = require("react-bootstrap-typeahead").Typeahead;
  const [reRender, setRerender] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(true);
  const [currentIndex, setCurrentIndex] = useState("");
  const [, setCurrentColumn] = useState("");
  const [display, setDisplay] = useState(false);
  const [perPage, setPerPage] = useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const[page,setPage]=useState(1)
  const [, setGetError] = useState("");
  const [tableData, setTableData] = useState([]);
 const [, setOptionsDisplay] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [remarkData, setRemarkData] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [remarkList, setRemarkList] = useState([]);
  const [backup, setBackup] = useState("");

  const [submitMsg, setSubmitMsg] = useState("");
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);

  const [editShow, setEditShow] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [cell, setCell] = useState("");
  const [count, setCount] = useState(0);
  const webSocket = useRef(null);
  const [id, setId] = useState("");
  const [patient, setpatient] = useState("");
  const [order, setOrder] = useState("");
  const [trackId, setTrackingId] = useState();
  const [infoShow, setinfoShow] = useState(false);
  const [infoData, setInfoData] = useState("");
  const [type, setType] = useState("");
  const alert = useAlert();
  let navigate = useNavigate();

  const [keys] = useState({ p: false, a: false, n: false, m: false, l: false });
  const refreshPage = () => {
    window.location.reload(false);
  };

  const handleInfoPopUp = (item,type) => {
    setInfoData(item);
    setinfoShow(true);

    if(type==="master_medicine"){
      setType("Medicines")
    }else{
      setType("Lab Tests")
    }
  };
  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"><h5>{type}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
           
            
        {infoData.map((item,index) => {
             return (            
             <div className="row-of-features">
               <div className="col-of-features"><br/>
                 <h6> {index+1}&nbsp;{item.item_service_name}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Quantity:&nbsp;{item.request_quantity}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Requested Due Date:&nbsp;{item.request_due_date}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Priority:&nbsp;{item.request_priority}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Status:&nbsp;{item.request_status}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Amount:&nbsp;{item.order_amount}</h6>
             

              
               </div>
             </div>
             );
          })}
              
          
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getServices();
  }, [reRender]);

  const getServices = () => {
    setLoading(true);
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/patient/assigned-sr-view/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setLoading(false);
          let dataList = res.data.orders;

          let updatedList = [];
          let filteredList = dataList.filter((item, index) => {
              return !(
                item.order_delivery_status.toLowerCase() === "delivered" ||
                item.order_delivery_status.toLowerCase() === "rejected"
              );
            })
            .sort((a, b) => {
              return new Date(b.created_at) - new Date(a.created_at);
            });
          updatedList = filteredList.map((item, index) => {
            let status;
            let statusObj;
            let newDate=  new Date(Math.min.apply(null, item.su_orders.map(function(e) {
            
              return new Date(e.tentative_delivery_date===null?e.request_due_date:e.tentative_delivery_date);
       
            })));
            if (item.order_delivery_status.toLowerCase() === "assigned") {
              status = "assigned";
              statusObj = { value: "assigned", label: "Assigned" };
            } else if (
              item.order_delivery_status.toLowerCase() === "accepted"
            ) {
              status = "accepted";
              statusObj = { value: "accepted", label: "Accepted" };
            } else if (
              item.order_delivery_status.toLowerCase() === "delivered"
            ) {
              status = "delivered";
              statusObj = { value: "delivered", label: "Delivered" };
            } else if (
              item.order_delivery_status.toLowerCase() === "rejected"
            ) {
              status = "rejected";
              statusObj = { value: "rejected", label: "Rejected" };
            } else if (
              item.order_delivery_status.toLowerCase() === "out_for_delivery"
            ) {
              status = "out_for_delivery";
              statusObj = {
                value: "out_for_delivery",
                label: "Out for Delivery",
              };
            }
       
          
            let priority;
            let priorityObj;
            if (item.su_orders[0].request_priority.toLowerCase() === "critical") {
              priority = "critical"; 
              priorityObj = { value: "critical", label: "Critical" };
            }else if (item.su_orders[0].request_priority.toLowerCase() === "high") {
              priority = "high";
              priorityObj = { value: "high", label: "High" };
            } else if (item.su_orders[0].request_priority.toLowerCase() === "medium") {
              priority = "medium";
              priorityObj = { value: "medium", label: "Medium" };
            } else if (item.su_orders[0].request_priority.toLowerCase() === "low") {
              priority = "low";
              priorityObj = { value: "low", label: "Low" };
            }
          
            return {
               order_type:item.order_type,
               su_orders:item.su_orders,
               name: item.patient_name,
               date: item.su_orders[0].request_due_date,
               order: item.order_type==="master_medicine"?"medicines":item.order_type==="master_labtest"?"lab tests":item.su_orders[0].item_service_name,
               order_id:item.order_id,
               id: item.id,
               priority: priority,
               priorityObj: priorityObj,
               ref: item.service_request_order,
               contact_number: item.contact_number,
             
               statusObj: statusObj,
               patient_id: item.patient_id,
               consent_level: parseInt(item.su_orders[0].consent_level_id),
              // address: item.address,
              // details: item.details,
              required_date:
              newDate,
              
               assigned_name:
                 item.order_assigned_to !== null ? item.order_assigned_to : null,

              quantity:item.order_type==="master_medicine"||item.order_type==="master_labtest"?item.su_orders.length:item.su_orders[0].request_quantity,

               remarks: item.remarks ? item.remarks : null,
               remark_list: item.remarks_list,
            };
          });


          setTableData(updatedList);
        } else {
          setLoading(false);

          setGetError(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);

        setGetError("Error in fetching data");
      });
  };

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/patient/assigned-sr-view/?q=${search}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        const assignee_List = [];
        if (res.data.status === "success") {
          const assigneeList = res.data.data;
          assigneeList.map((person, index) => assignee_List.push(person["id"]));

          setOptions(assigneeList);
          setOptionsDisplay(assignee_List);
        }
      })
      .catch((err) => {});
  }, [search]);

  useEffect(() => {
    setTableData(tableData);
  }, [render, tableData]);

  const statusOptions = [
    // { value: 'new', label: 'New' },
    // { value: 'ongoing', label: 'Ongoing' },
    { value: "assigned", label: "Assigned" },
    { value: "accepted", label: "Accepted" },

    // { value: 'pending', label: 'Pending' },
    // { value: 'cancelled', label: 'Cancelled' },
    { value: "delivered", label: "Delivered" },
    // { value: 'completed', label: 'Completed' },
    { value: "rejected", label: "Rejected" },
    { value: "out_for_delivery", label: "Out For Delivery" },
  ]; 

  
  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            {errorMsg !== ""
              ? errorMsg
              : "Error in Data Submission. Please try again!"}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const UpdatePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>Please save to complete changes </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderAssigned2 = (cellInfo) => {
    const { ref } = cellInfo.original;
    const {
      assigned_name,
      assigned_to,
      consent_level,
      patient_id,
      assigned_by_name,
    } = tableData.find((data) => data.ref === ref);

    return (
      <>
        <div style={{ display: "flex" }}>
          <Typeahead
            id={"assigned_name_" + ref}
            key={"assigned_name_" + ref}
            placeholder="Search..."
            onFocus={() => {
              const tokenString = sessionStorage.getItem("usertoken");

              let str = tokenString.replace(/["]+/g, "");

              axios
                .get(
                  `${APIURL}/api/v1/service-provider/sr-consent-users/?consent_level_id=${consent_level}&patient_id=${patient_id}`,
                  {
                    headers: {
                      Authorization: "Token " + str,
                    },
                  }
                )
                .then((res) => {
                  const assignee_List = [];
                  if (res.data.status === "success") {
                    const assigneeList = res.data.message;
                    assigneeList.map((person, index) =>
                      assignee_List.push(person["assigned_by_name"])
                    );

                    setOptions(assigneeList);

                    setOptionsDisplay(assignee_List);

                    setCurrentIndex(cellInfo.index);
                    setCurrentColumn(cellInfo.column.id);

                    setDisplay(true);

                    //setSearch(e.target.value);
                  }
                })
                .catch((err) => {});
            }}
            options={options.map((option) => {
              return {
                label: option["name"],
                id: option["id"],
                user: option["user_type"],
              };
            })}
            defaultInputValue={
              assigned_to && assigned_name ? assigned_name : ""
            }
            onChange={(data) => {
              setChanged(cellInfo.index);
              if (data.length) {
                tableData[cellInfo.index].assigned_to = data[0].id;
                setTableData([...tableData]);
                setSearch(data[0].label);
              }
            }}
          />
          {assigned_to && (
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="top"
              overlay={
                <Tooltip className="my-tooltip" id="button-tooltip">
                  ReAssign To:{" "}
                  {assigned_by_name !== null
                    ? assigned_by_name
                    : "Not Available"}{" "}
               
                </Tooltip>
              }
            >
              <i
                style={{
                  color: "#75759c",
                  paddingLeft: "5%",
                  paddingTop: "10%",
                }}
                className="fas fa-info-circle"
              ></i>
            </OverlayTrigger>
          )}
        </div>
      </>
    );
  };

 
  var sendData = null;

  function handleSend(childData) {
    sendData = childData;
  }


  const AddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark
            update={handleSend}
            trackId={trackId}
            id={id}
            patient={patient}
            order={order}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark data={remarkData} list={remarkList} update={handleSend} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleAdd = (index, id, trackId, name, order) => {
    setCell(index);
    setId(id);
    setTrackingId(trackId);
    setpatient(name);
    setOrder(order);
    setModalShow(true);
  };

 
  const renderRemarks = (cellInfo) => {
    return (
      <div style={{textAlign:"center"}}>
      <i
        style={{ color: "rgb(91 138 218)" }}
        onClick={() =>
          handleAdd(
            cellInfo.index,
            tableData[cellInfo.index].ref,
            tableData[cellInfo.index].id,
            tableData[cellInfo.index].name,
            tableData[cellInfo.index].order
          )
        }
        className="fas fa-comment"
      ></i></div>
    );

   
  };

  
  const renderTable = (cellInfo) => {
    return (
      <div style={{textAlign:"center"}}>
        {" "}
        {tableData[cellInfo.index][cellInfo.column.id]}
       
      </div>
    );
  };
  const renderName = (cellInfo) => {
    return (
      <div>
        {" "}
        {tableData[cellInfo.index][cellInfo.column.id]}
       
      </div>
    );
  };
  const renderId = (cellInfo) => {
    if (tableData[cellInfo.index][cellInfo.column.id]) {
      return (
        <div className="">
          {" "}
          {tableData[cellInfo.index][cellInfo.column.id]}
        </div>
      );
    } else {
      return null;
    }
  };
  const renderService = (cellInfo) => {
    return (
      <div>
        {" "}
        <span style={{}}>
          {tableData[cellInfo.index][cellInfo.column.id]}
        </span>
        {/* <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          Details: {tableData[cellInfo.index].details} <br/>
           
       
          </Tooltip> }><i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger>  */}
       {tableData[cellInfo.index].order_type==="master_medicine"||tableData[cellInfo.index].order_type==="master_labtest" ?<i
              style={{ color: "#75759c", paddingLeft: "5%" }}
              className="fas fa-info-circle fa-sm"
              onClick={() => {
               handleInfoPopUp(tableData[cellInfo.index].su_orders,tableData[cellInfo.index].order_type);
              }}
            ></i>:""}{" "}
      </div>
      
    );
  };

  /********
  const renderContact=(cellInfo)=> {
  
    return (
     
      <div > <span style={{margin:"10px"}}> 
          {tableData[cellInfo.index][cellInfo.column.id]}
       </span>  
       <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          
          
          Address: {tableData[cellInfo.index].address} <br/>
          </Tooltip> }><i style={{color:'var(--theme-blue)'}} className="fas fa-info-circle"></i></OverlayTrigger> 
       </div>
      
    );
  }

******/
  const renderContact = (cellInfo) => {
    return (
      <div>
        {" "}
        <span style={{ margin: "10px" }}>
          {tableData[cellInfo.index][cellInfo.column.id]}
        </span>
        {/* <OverlayTrigger trigger='click' rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">
          
          
          {tableData[cellInfo.index].address} <br/>
          </Tooltip> }>
            <h6 className="overlay-address">Address</h6>
          </OverlayTrigger>  */}
      </div>
    );
  };
  /*const renderDetails=(cellInfo)=> {
    
      return (
  
       <div > <p > {tableData[cellInfo.index][cellInfo.column.id]}</p></div>
        
      );
    }*/

  const renderStatus2 = (cellInfo) => {
    return (
      <>
        <Select
          value={tableData[cellInfo.index].statusObj}
          onChange={(data) => {
            setChanged(cellInfo.index);
            const List = tableData;

            List[cellInfo.index].statusObj = data;
            List[cellInfo.index].status = data.value;
            setTableData(List);
            setRender(!render);
          }}
          options={statusOptions}
          className={`status-select ${
            // tableData[cellInfo.index].status === "new" ? "new-status" :
            tableData[cellInfo.index].status === "accepted"
              ? "accepted-status"
              : // tableData[cellInfo.index].status === "completed" ? "completed-status" :
              tableData[cellInfo.index].status === "rejected"
              ? "rejected-status"
              : // tableData[cellInfo.index].status === "ongoing" ? "ongoing-status" :
              //   tableData[cellInfo.index].status === "pending" ? "pending-status" :
              // tableData[cellInfo.index].status === "cancelled" ? "cancelled-status" :
              tableData[cellInfo.index].status === "delivered"
              ? "delivered-status"
              : ""
          }`}
        />
      </>
    );
  };

 

  const renderPriority = (cellInfo) => {
    if (tableData[cellInfo.index].priority === "critical") {
      return (
        <div>
          {" "}
          <div className="priority critical-priority-2 caps">
            {tableData[cellInfo.index][cellInfo.column.id].value}
          </div>
        </div>
      );
    }else if (tableData[cellInfo.index].priority === "high") {
      return (
        <div>
          <div className="priority high-priority-2 caps">
            {" "}
            {tableData[cellInfo.index][cellInfo.column.id].value}
          </div>
        </div>
      );
    }
    if (tableData[cellInfo.index].priority === "medium") {
      return (
        <div>
          <div className="priority medium-priority-2 caps">
            {" "}
            {tableData[cellInfo.index][cellInfo.column.id].value}
          </div>
        </div>
      );
    }
    if (tableData[cellInfo.index].priority === "low") {
      return (
        <div>
          <div className="priority low-priority-2 caps">
            {" "}
            {tableData[cellInfo.index][cellInfo.column.id].value}
          </div>
        </div>
      );
    }
  };

 

  const renderDate = (cellInfo) => {
    let dateString = tableData[cellInfo.index][cellInfo.column.id];

    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (
      <div>
        <h6> {updatedDate.slice(4, 10)} </h6>
        {/* <h6> {updatedDate.slice(16, 24)}</h6> */}
      </div>
    );
  };

  const renderDueDate = (cellInfo) => {
    if (tableData[cellInfo.index][cellInfo.column.id]) {
      let dateString = tableData[cellInfo.index][cellInfo.column.id];
      let dateObject = new Date(dateString);

      const updatedDate = dateObject.toString();
      return (
        <div>
          <h6> {updatedDate.slice(4, 10)} </h6>
        </div>
      );
    } else {
      return null;
    }
  };

  const saveChanges = (index) => {
    const rowToUpdate = tableData.filter((elt, i) => {
      return i === index;
    });

    callSave(rowToUpdate[0]);
  };

  const validate = (rowToValidate) => {
    let isValid = true;

    {
      /********************************************
    if((rowToValidate.assigned_to===null || rowToValidate.assigned_to==="" || rowToValidate.assigned_to==="undefined" || !rowToValidate.assigned_to) && (rowToValidate.assigned_name && (rowToValidate.assigned_name!==null || rowToValidate.assigned_name!==""))){
  
  isValid=false;
   setErrorMsg("Error in Assignee field. Select assignee from the suggestion list.")
    setErrorSubmit(true);
    }
  
  ********************************removed assignee validation *****/
    }
    if (!rowToValidate.status && !rowToValidate.remarks) {
      isValid = false;

      alert.error('Enter Status')
    }
    if (!rowToValidate.priority) {
      isValid = false;

      alert.error("Enter priority");
    }

    return isValid;
  };

  const callSave = (rowToUpdate) => {
    // if (validate(rowToUpdate)) {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    const order_id = rowToUpdate.id;
    const assigned_to = rowToUpdate.assigned_to;
    const status = rowToUpdate.status;
    const order_comments = rowToUpdate.remarks;

    const dataToUpdate = {};
    if (status) {
      dataToUpdate.order_delivery_status = status;
    }
    if (assigned_to) {
      dataToUpdate.order_assigned_to = assigned_to;
      dataToUpdate.order_delivery_status = "assigned";
    }
    if (order_comments) {
      dataToUpdate.order_comments = order_comments;
    }


    axios
      .put(
        `${APIURL}/api/v1/patient/sr-track-order-update/${order_id}/`,
        dataToUpdate,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          alert.success('Updated successfully!')
          setBackup("");
          setRerender(!reRender);
        } else {
          alert.error("Error in submission"
          );
        }
      })
      .catch((err) => {
        alert.error("Error in submission");
      });
  };

  // Define a default UI for filtering
  /***********************
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }
  *******************/
  const columns = [
    {
      Header: "Id",
      accessor: "order_id",
      Cell: renderId,
      sortable: true,
      filterable: true,

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 100,
    },

    {
      Header: "Service Request",
      accessor: "order",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { whiteSpace: "unset" },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 140,
    },
    {
      Header: "Qty",
      accessor: "quantity",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      width: 50,
      filterable: true,
      sortable: true,
    },

    {
      Header: "Requested On",
      accessor: "date",
      Cell: renderDate,
      filterable: true,
      sortable: true,
      style: { backgroundColor: "rgba(0,0,0,0.1)" },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        let dateString = String(row[id]);
        let dateObject = new Date(dateString);
        const updatedDate = dateObject.toString();
        return row[id] !== undefined
          ? updatedDate.toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 90,
    },
    {
      Header: "Delivery Date",
      accessor: "required_date",
      Cell: renderDueDate,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      width: 90,
      filterable: true,
      sortable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        let dateString = String(row[id]);
        let dateObject = new Date(dateString);
        const updatedDate = dateObject.toString();
        return row[id] !== undefined
          ? updatedDate.toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: renderName,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 120,
    },
    {
      Header: "Contact",
      accessor: "contact_number",
      Cell: renderContact,
      sortable: false,
      filterable: true,
      width: 100,
    },
    {
      Header: "Priority",
      accessor: "priorityObj",
      Cell: renderPriority,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
      
        if (filter.value === "high") {
          return row[filter.id].value === "high";
        }

        if (filter.value === "critical") {
          return row[filter.id].value === "critical";
        }
        if (filter.value === "medium") {
          return row[filter.id].value === "medium";
        }
        if (filter.value === "low") {
          return row[filter.id].value === "low";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="critical">Critical</option>
         
          <option value="high">High</option>
          <option value="medium">Medium</option>
          <option value="low">Low</option>
        </select>
      ),

      width: 100,
    },

    {
      Header: "Status",
      accessor: "statusObj",
      Cell: renderStatus2,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        // if (filter.value.toLowerCase() === "ongoing") {
        //   return row[filter.id].value === "ongoing";
        // }
        if (filter.value.toLowerCase() === "accepted") {
          return row[filter.id].value === "accepted";
        }
        // if (filter.value.toLowerCase() === "new") {
        //   return row[filter.id].value === "new";
        // }
        // if (filter.value.toLowerCase() === "pending") {
        //   return row[filter.id].value === "pending";
        // }
        // if (filter.value.toLowerCase() === "cancelled") {
        //   return row[filter.id].value === "cancelled";
        // }
        if (filter.value.toLowerCase() === "delivered") {
          return row[filter.id].value === "delivered";
        }
        // if (filter.value.toLowerCase() === "completed") {
        //   return row[filter.id].value === "completed";
        // }
        if (filter.value.toLowerCase() === "rejected") {
          return row[filter.id].value === "rejected";
        }
        if (filter.value.toLowerCase() === "assigned") {
          return row[filter.id].value === "assigned";
        }
        if (filter.value.toLowerCase() === "out_for_delivery") {
          return row[filter.id].value === "out_for_delivery";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          {/* <option value="new">New</option> */}
          <option value="assigned">Assigned</option>

          <option value="accepted">Accepted</option>
          {/* <option value="ongoing">Ongoing</option> */}
          {/* <option value="pending">Pending</option> */}
          <option value="delivered">Delivered</option>
          {/* <option value="completed">Completed</option> */}
          {/* <option value="cancelled">Cancelled</option> */}
          <option value="rejected">Rejected</option>
          <option value="out_for_delivery">Out For Delivery</option>
        </select>
      ),

      width: 150,
    },
    {
      Header: "Reassign to ",
      accessor: "assign_to",
      Cell: renderAssigned2,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 150,
    },

    {
      Header: "",
      accessor: "remarks",
      Cell: renderRemarks,
      sortable: false,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 70,
    },

    {
      Header: "",
      Cell: (props) => {
        return changed === props.index ? (
          <button
            className="btn btn-primary btn-col btn-square btn-smallest"
            onClick={() => {
              saveChanges(props.index);
            }}
          >
            Save{" "}
          </button>
        ) : (
          " "
        );
      },
      sortable: false,
      filterable: false,
      minWidth: 50,
      width: 50,
    },
  ];

  const displayTable = (
    <ReactTable
      columns={columns}
      data={tableData}
      defaultPageSize={10}
      className="service-req-table"
      resizable
      style={{ overflow: "wrap",textAlign:"left" }}
    ></ReactTable>
  );

 
  return (
    <>
      {/* <HospDocNavbar /> */}
      {/* <div className=" text-left">
        <br/>
          <button className="btn btn-info back-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
    </div>  */}

      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "rgba(0, 0, 255, 0.5)",
            },
          }),
          overlay: {},
        }}
      >
        <div className="">
          <div className="text-center">
          <h5 className=""><b>
          Service Fulfillment
          </b></h5>
            <div className="service-table-holder padding1">
              <div
                className="service-table-holder"
                style={{
                  backgroundColor: "#efff001a",
                 
                  height: "34px",
                 
                }}
              >
                <div
                  class="row"
                  style={{ textAlign: "left", paddingTop: "4px" }}
                >
                  <div class="col-8">
                    
                  </div>

                  {/* <div class="col"></div>
                  <div class="col"></div> */}

                  <div class="col">
                    <button
                      disabled
                      type="button"
                      style={{ border: "none", textDecoration: "none" }}
                      class=" btn-link"
                    >
                      {" "}
                      Save All
                    </button>
                  </div>
                  <div class="col-2">
                    <button
                      onClick={() => navigate("/service_history_allied")}
                      type="button"
                      style={{
                        border: "none",
                        textDecoration: "none",
                        marginRight: "10px",
                      }}
                      class=" btn-link"
                    >
                      {" "}
                      Service History{" "}
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
               </div>
                {displayTable}
              </div>
            </div>
          </div>
       

        <br />
        <br />

        {errorMsg !== "" ? (
          <SubmitErrorPopup
            show={errorSubmit}
            onHide={() => {
              setErrorSubmit(false);
              setErrorMsg("");
              setRerender(!reRender);
            }}
          />
        ) : (
          ""
        )}
 {infoShow ? (
        <InfoPopup
          show={infoShow}
          onHide={() => {
            setinfoShow(false);
          }}
        />
      ) : (
        ""
      )}
        

        {modalShow ? (
          <AddPopup
            show={modalShow}
            onHide={() => {
              setModalShow(false);
            }}
          />
        ) : null}

        {editShow ? (
          <EditPopup
            show={editShow}
            onHide={() => {
              setEditShow(false);
            }}
            onSave={() => {
              if (sendData !== tableData[cell].remarks) {
                const data = tableData;
                data[cell].remarks = sendData;
                setTableData(data);
                setBackup(sendData);
                setEditShow(false);
                setRender(!render);
                setUpdateSuccess(true);
                //saveChanges(cell)
              }
            }}
          />
        ) : null}

        {updateSuccess ? (
          <UpdatePopup
            show={updateSuccess}
            onHide={() => {
              setUpdateSuccess(false);
            }}
          />
        ) : null}
      </LoadingOverlay>
    </>
  );
}

export default AlliedServiceFulfillment;
