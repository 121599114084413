import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
import Pending from './Pending';
import ApprovedRejected from './ApprovedRejected';


function Visitor() {
    const [selected, setSelected] = useState("relative")
    const [search, setSearch] = useState("");
    const [patient, setPatient] = useState({});
    const [requests, setRequests] = useState([])

    useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/service-provider/visitor-list/`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.status === 200) {
                    const filteredData = res.data.visitors.filter(x => {
                        return x.status === "pending"
                    })

                    setRequests(filteredData)

                }
            })
            .catch((err) => { });

    }, []);
    const handleSearchChange = (e) => {
        e.preventDefault();
        let searchTerm = e.target.value
        setSearch(e.target.value)
        if (e.target.value === "") {
           
             setPatient([])
            setSelected("")
            console.log(patient)
        }
        else {
            setPatient([])
            console.log(patient)
            let data = requests
            .sort((a, b) => {
                if ((a.first_name + a.last_name).toLowerCase() < (b.first_name + b.last_name).toLowerCase())
                    return -1;
                if ((a.first_name + a.last_name).toLowerCase() > (b.first_name + b.last_name).toLowerCase()) return 1;
                return 0;

            })
            .filter((value) => {
                if (
                    (value.first_name +" "+ value.last_name).toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                    return value;
                }
                else if (value.id.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
                    return value
                }


            });
            if (data.length === 0) {
                setPatient([])
                setSelected("")
            }
            else {
                let type = data[0].visitor_type
                const filteredData = data.filter(x => {
                    return x.visitor_type === type
                })
                setPatient(filteredData)

                if (data.length > 0 && data[0].visitor_type === "relative") {
                    setSelected("relative")
                } else if (data.length > 0 && data[0].visitor_type === "friend") {
                    setSelected("friend")
                }
                else if (data.length > 0 && data[0].visitor_type === "organisation") {
                    setSelected("organisation")
                }
                else if (data.length > 0 && data[0].visitor_type === "general") {
                    setSelected("general")
                }
               
            }
        }
    }

    return (
        <div style={{ paddingLeft: "1rem", paddingRight: "1.5rem" }} className=''>
            <div className='row'>
                <div className="col-lg-2 col-md-2">
                    <h4
                        style={{
                            color: "#245693",
                            marginTop: "8%",
                            fontFamily: "inter",
                            fontWeight: "700",
                            marginLeft: "40px",
                        }}
                    >
                        Visitors Report
                    </h4>
                    <div className="leftdivdeathrepo">
                        <div className="searchboxdeathrepo">  <input placeholder="Search Name or ID" type="text" value={search}
                            onChange={e => { handleSearchChange(e) }} style={{ marginLeft: '10px', marginTop: "3px", border: "0", outline: "none", width: "85%" }}></input></div>
                        <h6
                            style={{
                                color: "#2D3748",
                                fontFamily: "Helvetica",
                                fontWeight: "700",
                                marginLeft: "20px",
                                marginTop: "5px",
                                fontSize: "12px",

                            }}
                        >

                        </h6>
                        <button className="deathreportbutton" onClick={e => { setPatient([]); setSearch(""); setSelected("relative") }} style={{ backgroundColor: selected === "relative" ? "#6D4482" : "#F8F8F8", color: selected === "relative" ? "white" : "black" }}>
                            {" "}
                            <i
                                class="fa fa-file"
                                aria-hidden="true"
                                style={{ color: selected === "relative" ? "white" : "#6D4482" }}
                            ></i>{" "}
                            &nbsp;&nbsp;Relatives
                        </button>
                        <button className="deathreportbutton" onClick={e => { setPatient([]); setSearch(""); setSelected("friend") }} style={{ backgroundColor: selected === "friend" ? "#6D4482" : "#F8F8F8", color: selected === "friend" ? "white" : "black" }}>
                            {" "}
                            <i
                                class="fa fa-file"
                                aria-hidden="true"
                                style={{ color: selected === "friend" ? "white" : "#6D4482" }}
                            ></i>{" "}
                            &nbsp;&nbsp;Friends
                        </button>
                        <button className="deathreportbutton" onClick={e => { setPatient([]); setSearch(""); setSelected("general") }} style={{ backgroundColor: selected === "general" ? "#6D4482" : "#F8F8F8", color: selected === "general" ? "white" : "black" }}>
                            {" "}
                            <i
                                class="fa fa-file"
                                aria-hidden="true"
                                style={{ color: selected === "general" ? "white" : "#6D4482" }}
                            ></i>{" "}
                            &nbsp;&nbsp;General category
                        </button>
                        <button className="deathreportbutton" onClick={e => { setPatient([]); setSearch(""); setSelected("organisation") }} style={{ backgroundColor: selected === "organisation" ? "#6D4482" : "#F8F8F8", color: selected === "organisation" ? "white" : "black" }}>
                            {" "}
                            <i
                                class="fa fa-file"
                                aria-hidden="true"
                                style={{ color: selected === "organisation" ? "white" : "#6D4482" }}
                            ></i>{" "}
                            &nbsp;&nbsp;Organisations
                        </button>
                    </div>
                    <div className="leftdivdeathrepo" style={{ backgroundColor: "#6d448224", paddingTop: "1%", paddingBottom: "1%", marginTop: "1%", marginBottom: "1%" }}>
                        <button
                            className="deathreportbutton"
                            style={{ backgroundColor: selected === "approved" ? "#6D4482" : "#F8F8F8", color: selected === "approved" ? "white" : "black" }}
                            onClick={e => { setPatient([]); setSearch(""); setSelected("approved") }}
                        >
                            {" "}
                            <i
                                class="fa fa-file"
                                aria-hidden="true"
                                style={{ color: selected === "approved" ? "white" : "#6D4482" }}

                            ></i>{" "}
                            &nbsp;&nbsp;Approved
                        </button>
                        <button
                            className="deathreportbutton"
                            style={{ backgroundColor: selected === "rejected" ? "#6D4482" : "#F8F8F8", color: selected === "rejected" ? "white" : "black" }}
                            onClick={e => { setPatient([]); setSearch(""); setSelected("rejected") }}
                        >
                            {" "}
                            <i
                                class="fa fa-file"
                                aria-hidden="true"
                                style={{ color: selected === "rejected" ? "white" : "#6D4482" }}

                            ></i>{" "}
                            &nbsp;&nbsp;Rejected
                        </button>
                    </div>
                </div>

                <div className="col-lg-10 col-md-10">
                    {selected !== "approved" && selected !== "rejected" && selected !== "" ? <Pending type={selected} visitor={patient} /> : selected === "" ?
                        <div className="" style={{ marginTop: "10%", color: "red" }}>No Requests Pending!!</div> : <ApprovedRejected status={selected} />}
                </div>
            </div>
        </div>

    )
}
export default Visitor;