import React,{useState,useEffect} from 'react'
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import ZoomableImage from '../BDO/Admission/ZoomableImage';

function ImageUpdate(props) {

    const [imgData, setImgData]= useState()
    const [previewOn, setPreviewOn]=useState(false)
    const [imgUrl, setImgUrl]= useState('')
    const [sizeShow ,setSizeShow] =useState(false)
    const [validShow ,setValidShow] =useState(false)
    const [contentShow ,setContentShow] =useState(false)
    const [uploadShow ,setUploadShow] =useState(false)  
    const [url, setUrl]=useState('')
    const hostname = window && window.location && window.location.hostname;

    if(imgData !==undefined){
        let data = imgData
       props.imgUpdate(data)
    }

    const reader =new FileReader();

    useEffect(()=> {
        if(props.img instanceof File === true){
            const url =URL.createObjectURL(props.img)
            setUrl(url)
        } else {
    
          setImgUrl('https://'+ hostname + props.img)
          setPreviewOn(true);
     
        }
    },[])



    const removePicPreview=(e)=>{
        e.preventDefault();
        setPreviewOn(false);
        setImgData(null)
       }

    const handleClick= ()=> {
        const fileInput = document.getElementById('imageInput')
        fileInput.click();
    }

    const uploadImage= e=>{

        const file=  e.target.files[0];
        
        if(!file){
          setUploadShow(!uploadShow)
          //setInvalidPic('Please Select an Image')
          return false
        }
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
          setValidShow(!validShow)
          //setInvalidPic('Select valid image')
          return false;
        }
        if(file.size>10e6){
          setSizeShow(!sizeShow)
          return false
        }
        setImgData(file)
        const url =URL.createObjectURL(file)
        setImgUrl(url)
        setPreviewOn(true)
      
       
        reader.readAsDataURL(file);
      
       
        
      }

    const SizePopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h5 id="confirm-reject">You have exceeded image size limit 10MB</h5>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }
      
      const LoadPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Select an Image</h4>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }
      
      const ValidPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Select a valid Image</h4>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }
      
      const ContentPopUp = (props)=> {
        return(
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
               backdrop="static"
                keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                 
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
      
              <h4 id="confirm-reject">Invalid Image Content</h4>
               
              </Modal.Body>
              <Modal.Footer>
               
                 <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
        
              </Modal.Footer>
            </Modal>
          )
      }

    return (
        <div className='image-edit' style={{marginLeft:"17%"}}>
          {/* <div className="form-button title-of-tasks" style={{}}>
                    <i className="fa fa-search-plus"    onClick={() =>{ var GFG = document.getElementById("geeks");
                      var currWidth = GFG.clientWidth;
                      var currHeight=GFG.height;
                      if(currWidth<=600){
                      GFG.style.width = (currWidth + 10) + "px";}
                      if(currHeight<=700) {
                      GFG.style.height=(currHeight+10)+"px"}
                      } } >
                
              </i>
                <span>&nbsp;&nbsp;</span>
              <i className="fa fa-search-minus" onClick={() =>{  var GFG = document.getElementById("geeks");
                      var currWidth = GFG.clientWidth;
                      var currHeight=GFG.height;
                      if(currWidth>=400)
                      GFG.style.width = (currWidth - 10) + "px";     
                      if(currHeight>=500) 
                      GFG.style.height=(currHeight-10)+"px"} } >
                 
              </i>
               */}
              {/* </div> */}
            {
               props.img instanceof File ? previewOn===false ?
                    <>
                    
                        <p className='text-right'>
                        <i  onClick={handleClick} className="fas fa-camera cam"></i>
                            <input type='file' id='imageInput' onChange={uploadImage} hidden='hidden' ></input>
                           
                        </p>
                        <p className='text-center' style={{textAlign:"center"}}>
                          <ZoomableImage src={props.img instanceof File ? url : props.img}/>
                            {/* <img  className='update-class' alt='Scanned Image' src={props.img instanceof File ? url : props.img} width={500} height={600} id="geeks" GFG="250" ></img> */}
                        </p>                       
                    </>
                    :
                    <>
                        <p className='text-right'>
                            <div onClick={removePicPreview}><i className="fas fa-times-circle close-prev"></i></div>
                        </p>
                        <p className='text-center' style={{textAlign:"center"}}>
                          <ZoomableImage src={imgUrl}/>
                            {/* <img className='update-class' src={imgUrl} width='300' height='300' alt='Scanned Image'/> */}
                        </p>
                        {/*<button onClick={handleUpdate} className='btn btn-primary btn-col'>Update</button>*/}
                    </>   
        :
          <>
            <p className='text-right'>
              <input type='file' id='imageInput' onChange={uploadImage} hidden='hidden' ></input>
              <i onClick={handleClick} className="fas fa-camera cam"></i>
            </p>
            <p className='text-center'style={{textAlign:"center"}}>
            <ZoomableImage src={imgUrl}/>
            </p>
          </>
    }

{
        sizeShow ? 
        <SizePopUp
        show={sizeShow}
        onHide={()=>{
          setSizeShow(false)
        }} /> : null
      } 

{
        uploadShow ? 
        <LoadPopUp
        show={uploadShow}
        onHide={()=>{
          setUploadShow(false)
        }} /> : null
      } 

{
        validShow ? 
        <ValidPopUp
        show={validShow}
        onHide={()=>{
          setValidShow(false)
        }} /> : null
      } 

{
        contentShow ? 
        <ContentPopUp
        show={contentShow}
        onHide={()=>{
          setContentShow(false)
        }} /> : null
      }

        </div>
    )
}

export default ImageUpdate
