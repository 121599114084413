import React,{useState, useContext} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
import { ConfirmationContext } from '../../contexts/SubmitConfirmationContext';

function AddProcedure(props) {

  let formData =new FormData();
  const [image, setImage] = useState(null);

    const[data, setData]= useState({
        procedurename:'',
        proceduretype:'',
        

    })

    const [error,setError]=useState({
      procedurename:'',
      proceduretype:'',
       });
    const [refresh, setRefresh]= useState(false)
    //const [addRentalShow, setAddRentalShow]= useState(false)
    const[errorValidate,setErrorValidate]=useState(false)
    const alert = useAlert()

//     const serviceOptions = [{ value:'general', label:'General' },
//     { value:'service_request', label:'Service Request' },
//     { value:'rental', label:'Equipment Request' }
// ]
    const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
    const unitOptions = [{ value:'days', label:'Days' },
    { value: 'count', label: 'count' }
]



  const handleError = () =>
  {
    setErrorValidate(false);
  }
     
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

      const handleImageUpload = (event) => {
        
        const selectedImage = event.target.files[0];
        setImage(selectedImage);
      };
    
  const validate=()=>{
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["procedurename"] || input["procedurename"]==="" ) {
      isValid = false;
      errors["procedurename"] = "Enter procedure Name";
    }
    if (!input["proceduretype"] || input["proceduretype"].value==="" ) {
      isValid = false;
      errors["proceduretype"] = "Enter procedure Type";
    }
    

     setError(currentstate=>({
          ...currentstate,
          ...errors
        }));
    

    return isValid;
}

const handleContinue = () => {
  if(validate()){ 
    setShowConfirmation(true)
  };
}

const handleConfirm = () => {
  saveChanges();
};

const handleCancel = () => {
  closeConfirmationPopup()
};

  const saveChanges = (e)=> {
    // e.preventDefault();

        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
    procedure_name : data.procedurename.slice(0,1).toUpperCase() +data.procedurename.slice(1, data.procedurename.length),
    procedure_type : data.proceduretype,

  }
  const formData = new FormData();
  if (image) {
    
    formData.append('image', image);
  }
Object.keys(dataToSend).forEach(key => formData.append(key, dataToSend[key]));
 
     axios
       .post(`${APIURL}/api/v1/staff/master-procedure/`, formData , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          setRefresh(true)
          alert.success("Successfully saved")
          props.modalClose()
         } else {
           alert.error('Procedure already present')
           closeConfirmationPopup()
         }
       })
       .catch((err) => {
        alert.error('Procedure already present')
        closeConfirmationPopup()
       });  
}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Add procedure</h4>
    <div style={{display:"flex", marginLeft: "4rem", marginRight: "4rem", overlap:"hidden", padding: "0 10px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {image && (
         <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", padding: "5px" }}>
          <img src={URL.createObjectURL(image)} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
        <div style={{minHeight:'fit-content', width:'100%'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField">
                  <div  style={{display:"flex"}}>
                    <label className='align-rental'>*Procedure Name: </label>
                    <input type='text' className='form-control title-flex' name='procedurename' value={data.procedurename} onChange={handleTitle} /></div>
                    {error.procedurename ? <div className="error-validation-msg error-feature-inventory">{error.procedurename}</div> : null}
                </div>

                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='align-rental'>*Procedure Type : </label>
                    <input type='text' className='form-control title-flex' name='proceduretype' value={data.proceduretype} onChange={handleTitle} /></div>
                    {error.proceduretype ? <div className="error-validation-msg error-feature-inventory">{error.proceduretype}</div> : null}
                </div>

            </div>                            
        </div>
        <div style={{textAlign:'right'}}>
              <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>cancel</button>
              <button onClick={handleContinue} className='btn btn-primary btn-col mb-2'>Save</button>             
        </div>
        {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to add the procedure?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}
        </>
    )
}

export default AddProcedure
