import React, { useState, createContext } from "react";
export const UserManagementContext = createContext();

export const UserManagementProvider = (props) => {
    const userData = JSON.parse(sessionStorage.getItem("logindatas"));
    const role = userData && userData.role && userData.role
    const [selected, setSelected] = useState(role === 'manager' ? 'doctors' : 'manager');
    return (
        <UserManagementContext.Provider value={[selected, setSelected]}>
            {props.children}
        </UserManagementContext.Provider>
    );
};
