import React, {useState} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function MaritalStatus(props) {
    const alert = useAlert();
   
    const [maritalStatus, setMaritalStatus]=useState(props.married !== " "||props.married !== null ? props.married : " ")
    
    const handleGenderRadioButton = (e) => {
   
        let val = e.target.value;
      setMaritalStatus(!maritalStatus)
    }

    const handleSubmit = () => {

      

            const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
        const dataToUpdate = 
        {
            "married": maritalStatus
        }
       
     
       
         axios.put(`${APIURL}/api/v1/patient/user-details/?patient_id=${id}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
            alert.success(res.data.message)
               props.onSuccess()
          
       }
       else{
        alert.error(res.data.message)
        
       }
     
         })
         .catch(err=>{
       
          
     })

    

    }


    return (
        <div className='row'>
            <div  className="enquiryinput col-8">
            <input
                            type="radio"
                            id="male"
                            name="gender"
                            value="male"
                            checked={maritalStatus === false}
                            onClick={handleGenderRadioButton}
                        />

                            <label >Unmarried</label>
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                id="female"
                                name="gender"
                                value="female"
                                checked={maritalStatus === true}
                                onClick={handleGenderRadioButton}
                            />

                            <label >Married</label>
            </div>
          
          <span className='col-1'></span>
      <button disabled={maritalStatus===""&&props.married===maritalStatus} onClick={handleSubmit} style={{
        borderRadius: "10px",
        backgroundColor: "rgba(43, 147, 160, 1)",
        textAlign: "center",
        color: "white",

      }} className=' col-2'>Add</button>


           
     
        </div>
    )
}

export default MaritalStatus
