import React, { useState, useEffect, useRef } from "react";
import bed from "../../assets/images/icons/bed.png";
import ward from "../../assets/images/icons/ward.png";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import AddBuilding from "./AddBuilding";
import AddFloor from "./AddFloor";
import AddRooms from "./AddRooms";
import { APIURL } from "../../Global";
import { useAlert } from "react-alert";
import moment from "moment";
import axios from "axios";
import SimpleBar from 'simplebar-react';
import "simplebar-react/dist/simplebar.min.css";

import AddWards from "./AddWards";
import { NavDropdown } from "react-bootstrap";
const HospitalManagementComponent = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [addBuildingPopupVisible, setAddBuildingPopupVisible] = useState(false);
  const [addFloorPopupVisible, setAddFloorPopupVisible] = useState(false);
  const [addRoomPopupVisible, setAddRoomPopupVisible] = useState(false);
  const [addWardPopupVisible, setAddWardPopupVisible] = useState(false);
  const [roomShow, setRoomsShow] = useState(false);
  const [wardShow, setWardShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [expand, setExpand] = useState(0);
  const [buildname, setBuildName] = useState("");
  const [floorname, setFloorName] = useState("");
  const [refresh1, setRefresh1] = useState(false);
  const [refresh2, setRefresh2] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const alert = useAlert();
  const [show, setShow] = useState(false);
  const [showflr, setShowflr] = useState(false);
  const [error, setError] = useState({});
  const [floorvalue, setFloorvalue] = useState([]);
  const [buildingvalue, setBuildingValue] = useState([]);
  const [roomdata, setRoomdata] = useState({
    roomname: "",
    roomnumber: "",
    roomtype: { value: "", label: "" },
    roomprice: "",
  });

  const [warddata, setWardData] = useState({
    wardname: "",
    wardnumber: "",
    wardcount: { value: "", label: "" },
    wardprice: "",
    bedcount: "",
  });
  const [floors, setFloors] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [wards, setWards] = useState([]);

  const roomtypes = [
    { value: "deluxe", label: "Deluxe" },
    { value: "semi-deluxe", label: "Semi-Delux" },
    { value: "executive", label: "Executive" },
    { value: "vip", label: "VIP" },
    { value: "vvip", label: "VVIP" },
    { value: "standard", label: "Standard" },
  ];

  const wardType = [
    { value: "deluxe", label: "Deluxe" },
    { value: "semi-deluxe", label: "Semi-Delux" },
    { value: "executive", label: "Executive" },
    { value: "vip", label: "VIP" },
    { value: "vvip", label: "VVIP" },
    { value: "standard", label: "Standard" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    setRoomdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;

    setWardData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const roomTypeData = (data) => {
    setRoomdata((current) => ({
      ...current,
      roomtype: {
        value: data,
        label: data, // You can set the label as the same value if desired
      },
    }));
  };

  const wardTypeData = (data) => {
    setWardData((current) => ({
      ...current,
      wardcount: {
        value: data,
        label: data, // You can set the label as the same value if desired
      },
    }));
  };

  const validate = () => {
    let input = roomdata;
    let focussed = false;
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["roomname"] || input["roomname"] === "") {
      isValid = false;
      errors["roomname"] = "Enter Room Name";
    }

    if (!input["roomnumber"] || input["roomnumber"] === "") {
      isValid = false;
      errors["roomnumber"] = "Enter room number";
    }
    if (/^0[0-9]+$/.test(input["roomnumber"]) || (input["roomnumber"]==="0")) {
      isValid = false;
      errors["roomnumber"] = "Room number cannot start with zero";
       }

    if (
      typeof roomdata.roomtype.value == "undefined" ||
      roomdata.roomtype.value === "" ||
      roomdata.roomtype.value === null
    ) {
      isValid = false;
      errors["roomtype"] = "Select room type";
    }

    if (!input["roomprice"] || input["roomprice"] === "") {
      isValid = false;
      errors["roomprice"] = "Enter room price";
    }

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };

  const validate1 = () => {
    let input = warddata;
    let focussed = false;
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["wardname"] || input["wardname"] === "") {
      isValid = false;
      errors["wardname"] = "Enter Ward Name";
    }

    if (!input["wardnumber"] || input["wardnumber"] === "") {
      isValid = false;
      errors["wardnumber"] = "Enter ward number";
    }
    if (/^0[0-9]+$/.test(input["wardnumber"]) || (input["wardnumber"]==="0")) {
      isValid = false;
      errors["wardnumber"] = "Ward number cannot start with zero";
       }

    if (
      typeof warddata.wardcount.value == "undefined" ||
      warddata.wardcount.value === "" ||
      warddata.wardcount.value === null
    ) {
      isValid = false;
      errors["wardcount"] = "Select ward type";
    }

    if (!input["wardprice"] || input["wardprice"] === "") {
      isValid = false;
      errors["wardprice"] = "Enter ward price";
    }
    if (!input["bedcount"] || input["bedcount"] === "") {
      isValid = false;
      errors["bedcount"] = "Enter bed count";
    }

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };

  const numRooms = rooms.length;
  const roomText = numRooms === 1 ? "room" : "rooms";

  const numWards = wards.length;
  const wardText = numWards === 1 ? "ward" : "wards";

  const handleBuildingClick = (building, build_name) => {
    setSelectedFloor(null);
    setFloorvalue(null);
    setBuildingValue(null);
    setSelectedBuilding(building);
    setRoomsShow(false);
    setWardShow(false);
    setBuildName(build_name);
    setFloorName("");
    setRoomdata({
      roomname: "",
      roomnumber: "",
      roomtype: { value: "", label: "" },
      roomprice: "",
    });
    setWardData({
      wardname: "",
      wardnumber: "",
      wardcount: { value: "", label: "" },
      wardprice: "",
      bedcount: "",
    });

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/floor/?building=${building}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setFloors(res.data);
    
        }
      });
  
  };

  useEffect(() => {
    setSelectedFloor("");
    
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/building/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setBuildings(res.data);
      
        }
      });
  }, [refresh]);

  const handleFloorClick = (floor, building, floor_name) => {
    setError({});
    setSelectedFloor(floor);
    setFloorvalue(floor);
    setBuildingValue(building);
    setFloorName(floor_name);
    setRoomdata({
      roomname: "",
      roomnumber: "",
      roomtype: { value: "", label: "" },
      roomprice: "",
    });
    setWardData({
      wardname: "",
      wardnumber: "",
      wardcount: { value: "", label: "" },
      wardprice: "",
      bedcount: "",
    });

    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    if (roomShow) {
      axios
        .get(
          `${APIURL}/api/v1/service-provider/add-rooms/?building=${building}&&floor=${floor}`,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setRoomsShow(true);
            setRooms(res.data);
          }
        });
    } else if (wardShow) {
      axios
        .get(
          `${APIURL}/api/v1/service-provider/add-wards/?building=${building}&&floor=${floor}`,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setWardShow(true);
            setWards(res.data);
          }
        });
    }
  };

  const handleAddBuilding = () => {
    setAddBuildingPopupVisible(true);
  };

  const handleAddFloor = (selectedBuilding) => {
    setAddFloorPopupVisible(true);
  };

  const handleAddWard = (e) => {
    e.preventDefault();

    if (buildingvalue && floorvalue) {
      handleAddWardNew();
    } else {
      alert.error("Please Select building and floor");
    }
  };
  const handleAddWardNew = () => {
    

    if (validate1()) {
      const tokenStr = sessionStorage.getItem("usertoken");

      if (tokenStr) {
        const v = tokenStr;
        let v2 = v.replace(/["]+/g, "");

        const dataToSend = {
          ward_name: warddata.wardname,
          ward_number: warddata.wardnumber,
          bed_price: warddata.wardprice,
          bed_capacity: warddata.bedcount,
          building: buildingvalue,
          floor: floorvalue,
          ward_type: warddata.wardcount.value,
        };
        axios
          .post(`${APIURL}/api/v1/service-provider/add-wards/`, dataToSend, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + v2,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              alert.success(res.data.message);

              setRefresh1(!refresh1);
              setWardData({
                wardname: "",
                wardnumber: "",
                wardcount: { value: "", label: "" },
                wardprice: "",
                bedcount: "",
              });
            } else {
              alert.error(res.data.message);
            }
          })
          .catch((err) => {});
      }
    }
  };

  useEffect(() => {
    handleFloorClick(floorvalue, buildingvalue, floorname);
  }, [refresh1, roomShow, wardShow]);

  const handleAddRoom = (e) => {
    
    e.preventDefault();

    if (validate()) {
      const tokenStr = sessionStorage.getItem("usertoken");

      if (tokenStr) {
        const v = tokenStr;
        let v2 = v.replace(/["]+/g, "");

        const dataToSend = {
          room_name: roomdata.roomname,
          room_number: roomdata.roomnumber,
          room_type: roomdata.roomtype.value,
          room_price: roomdata.roomprice,
          building: buildingvalue,
          floor: floorvalue,
        };
        axios
          .post(`${APIURL}/api/v1/service-provider/add-rooms/`, dataToSend, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + v2,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              alert.success(res.data.message);

              setRefresh1(!refresh1);
              setRoomdata({
                roomname: "",
                roomnumber: "",
                roomtype: { value: "", label: "" },
                roomprice: "",
              });
            } else {
              alert.error(res.data.message);
            }
          })
          .catch((err) => {});
      }
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside1, true);

    return () => {
      document.removeEventListener("click", handleClickOutside1, true);
    };
  }, []);

  const handleRooms = (e) => {
    setRoomsShow(true);
    setWardShow(false);
    setWardData({
      wardname: "",
      wardnumber: "",
      wardcount: { value: "", label: "" },
      wardprice: "",
      bedcount: "",
    });
  };
  const handleWards = (e) => {
    setRoomsShow(false);
    setWardShow(true);
    setRoomdata({
      roomname: "",
      roomnumber: "",
      roomtype: { value: "", label: "" },
      roomprice: "",
    });
    // handleWard(floorvalue,buildingvalue);
  };

  const buildingShow = () => {
    setAddBuildingPopupVisible(false);
    setRefresh(!refresh);
  };

  const floorShow = () => {
    setAddFloorPopupVisible(false);
    window.location.reload(false);
  };

  const BuildingPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddBuilding handle={buildingShow} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };
  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index);
    } else {
      setExpand(0);
    }
  };

  const FloorPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddFloor handle={floorShow} data={props.data} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };

  let display1 =
    wards.length > 0
      ? wards.map((item, index) => {
          const bedButtons = [];

          for (let i = 1; i <= item.bed_capacity; i++) {
            bedButtons.push(
              <button key={i} className="bed-button">
                Bed {i}
              </button>
            );
          }

          return (
            <>
              <div
                className="list-item"
                style={{
                  width: "100%",
                  marginLeft: "-1%",
                  marginBottom: "1%",
                  marginTop: "1%",
                  textTransform:"capitalize"
                }}
                onClick={() => handleDetail(index)}
              >
                <div
                  class="row"
                  style={{ textAlign: "center", border: "none" }}
                >
                  <div class="col-2 ser_body" style={{ border: "none" }}>
                    {item.ward_name}
                  </div>
                  <div class="col-3 ser_body" style={{ border: "none" }}>
                    {item.ward_number}
                  </div>
                  <div class="col-1 ser_body" style={{ border: "none" }}>
                    {item.bed_capacity}
                  </div>
                  <div class="col-3 ser_body" style={{ border: "none" }}>
                    {item.ward_type}
                  </div>
                  <div class="col-1 ser_body" style={{ border: "none" }}>
                  <i class="fa fa-inr" aria-hidden="true"></i> {item.bed_price}
                  </div>
                  <div class="col-2 ser_body" style={{ border: "none" }}>
                    {moment(item.created_at).format("YYYY-MM-DD")}
                  </div>
                </div>
              </div>
              {expand === index ? (
                <div
                  className="bed-buttons-container"
                  style={{ width: "100%" }}
                >
                  {bedButtons}
                </div>
              ) : null}
            </>
          );
        })
      : null;
  const RoomPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRooms />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };

  const WardPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddWards />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  };

  // Add building, floor, or room logic goes here...
  const handleOpen = () => {
    setShow(!show);
  };
  const handleOpenFlr = () => {
    setShowflr(!showflr);
  };
  const handleClickOutside = (event) => {
    if (ref1.current && !ref1.current.contains(event.target)) {
      setShow(false);
    }
  };
  const handleClickOutside1 = (event) => {
    if (ref2.current && !ref2.current.contains(event.target)) {
      setShowflr(false);
    }
  };

  return (
    <div className="facilityouter container">
      {/* Top Part: Buildings */}
      <div
        className="row"
        style={{
          display: "flex",

          height: "72px",
          backgroundColor: "#DED58B",
          justifyContent: "flex-start",
          marginTop: "1%",
        }}
      >
        {buildings.map(
          (building, index) =>
            index < 5 && (
              <button
                className="col-1"
                key={building.id}
                style={{
                  textTransform:"capitalize",
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '100%',
                  height: "60px",
                  flexShrink: "0",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  marginTop: "12px",
                  color:
                    selectedBuilding === building.id
                      ? "black"
                      : "var(--green-lite, #F4FFF3)",
                  backgroundColor:
                    selectedBuilding === building.id ? "white" : "#DED58B",
                  border: "none",
                  borderTopLeftRadius: "15px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderTopRightRadius: "15px",
                  marginRight: "5px",
                  marginLeft: "3%",
                }}
                onClick={() =>
                  handleBuildingClick(building.id, building.build_name)
                }
              >
                {building.build_name}
                <br />
              </button>
            )
        )}
        {buildings.length > 5 && (
          <NavDropdown
            className="col-1"
            ref={ref1}
            style={{ zIndex: "1002" }}
            title={
              <button
                style={{
                
                  height: "60px",
                  flexShrink: "0",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  marginTop: "12px",
                  color: "var(--green-lite, #F4FFF3)",
                  backgroundColor: "#DED58B",
                  border: "none",
                  borderTopLeftRadius: "15px",
                  borderBottomLeftRadius: "0px",
                  borderBottomRightRadius: "0px",
                  borderTopRightRadius: "15px",
                  marginRight: "5px",
                  marginLeft: "3%",
                }}
              >
                <i
                  class="fa fa-angle-double-right fa-lg"
                  aria-hidden="true"
                ></i>
              </button>
            }
            id="collasible-nav-dropdown"
            renderMenuOnMount={true}
            onClick={handleOpen}
            show={show}
          >
            <NavDropdown.Item
              style={{
                
                overflow: "scroll",
                overflowX: "hidden",
                overflowY: "auto",

                maxHeight: "350px",
                width: "328px",
                color: "var(--green-lite, #F4FFF3)",
                backgroundColor: "#DED58B",
              }}
            >
              {buildings.map(
                (building, index) =>
                  index > 4 && (
                    <div
                      className="dropBuilding"
                      key={building.id}
                      style={{
                        textTransform:"capitalize",
                        height: "60px",

                        fontSize: "16px",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        marginTop: "12px",

                        border: "none",

                        marginRight: "5px",
                        marginLeft: "3%",
                      }}
                      onClick={() =>
                        handleBuildingClick(building.id, building.build_name)
                      }
                    >
                      {building.build_name}
                      <br />
                    </div>
                  )
              )}
            </NavDropdown.Item>
          </NavDropdown>
        )}

        <button
          className="facilitybuttons"
          style={{
            cursor: "pointer",
            marginTop: "25px",
            marginBottom: "1px",
            marginLeft: "10%",
            width: "160px",
            height: "40px",
          }}
          onClick={handleAddBuilding}
        >
          + Add New Building
        </button>
      </div>

      {buildname !== "" && (
        <h5 style={{ marginTop: "1%", color: "#115269" ,  textTransform:"capitalize",}}>
          {buildname}{" "}
          <i
            class="fa fa-angle-right"
            style={{ color: "black" }}
            aria-hidden="true"
          ></i>{" "}
          {floorname}
        </h5>
      )}

      <div
        className="row"
        style={{
          display: "flex",

          height: "44px",
          marginTop: "10px",
          borderRadius: "6px",
          justifyContent: "center",
          marginLeft: "5%",
        }}
      >
        {floors.map(
          (floor, index) =>
            index < 5 && (
              <button
                className="col-1"
                key={index}
                style={{
                  textTransform:"capitalize",
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '100%',
                  borderRadius: "6px",
                  border: "1px solid #D2D2D2",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginLeft: "20px",
                  marginRight: "20px",
                  backgroundColor:
                    selectedFloor === floor.id
                      ? "#8FD9C0"
                      : "var(--green-lite, #F4FFF3)",
                }}
                onClick={() =>
                  handleFloorClick(floor.id, floor.building, floor.floor_name)
                }
              >
                {floor.floor_name}
              </button>
            )
        )}
        {floors.length > 5 && (
          <NavDropdown
            className="col-1"
            ref={ref2}
            style={{ zIndex: "1001" }}
            title={
              <button
                className="col-1"
                style={{
                  textTransform:"capitalize",
                  marginTop: "10%",
                  borderRadius: "6px",
                  // border: "1px solid #D2D2D2",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  cursor: "pointer",

                  marginRight: "20px",
                  backgroundColor: "#fafafa",
                }}
              >
                <i
                  class="fa fa-angle-double-right fa-lg"
                  aria-hidden="true"
                ></i>
              </button>
            }
            id="collasible-nav-dropdown"
            renderMenuOnMount={true}
            onClick={handleOpenFlr}
            show={showflr}
          >
            <NavDropdown.Item
              style={{
                overflow: "scroll",
                overflowX: "hidden",
                overflowY: "auto",

                maxHeight: "350px",

                color: "black",
                backgroundColor: "white",
              }}
            >
              {floors.map(
                (floor, index) =>
                  index > 4 && (
                    <div
                      key={index}
                      style={{
                        borderRadius: "6px",
                        border: "1px solid #D2D2D2",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        cursor: "pointer",
                        margin: "5px",
                        padding: "10px",
                        backgroundColor: "var(--green-lite, #F4FFF3)",
                      }}
                      onClick={() =>
                        handleFloorClick(
                          floor.id,
                          floor.building,
                          floor.floor_name
                        )
                      }
                    >
                      {floor.floor_name}
                    </div>
                  )
              )}
            </NavDropdown.Item>
          </NavDropdown>
        )}
        {selectedBuilding && (
          <button
            className="facilitybuttons "
            style={{
              padding: "10px",
              cursor: "pointer",
              height: "43px",
              marginLeft: "10%",
              width: "120px",
            }}
            onClick={() => handleAddFloor(selectedBuilding)} // Pass the building value of the selected floor
          >
            + Add New Floor
          </button>
        )}

        <br />
      </div>
      <div style={{ display: "flex" }}>
        {/* <h5 style={{ marginLeft: "6%", marginTop: "3%", color: "#115269" }}>{buildname}  <i class="fa fa-angle-right" style={{ color: "black" }} aria-hidden="true"></i> {floorname}</h5> */}
        {floors.length > 0 && selectedFloor !== null ? (
          <>
            {" "}
            <button
              className={`btn btn-light toggle-btns toggle-btn-1 ${
                roomShow ? "active" : ""
              }`}
              onClick={(e) => handleRooms(e)}
              style={{
                marginLeft: "85%",
                height: "3%",
                width: "5%",
                backgroundColor: roomShow ? "#5F8D4E" : "#FFF",
                marginTop: "2%",
              }}
            >
              <img
                src={bed}
                alt="Room"
                style={{ width: "70%", marginTop: "-12%" }}
              />
            </button>
            <button
              className={`btn btn-light toggle-btns toggle-btn-end ${
                wardShow ? "active" : ""
              }`}
              onClick={(e) => handleWards(e)}
              style={{
                height: "3%",
                width: "5%",
                backgroundColor: wardShow ? "#5F8D4E" : "#FFF",
                marginTop: "2%",
              }}
            >
              <img
                src={ward}
                alt="Ward"
                style={{ width: "70%", marginTop: "-12%" }}
              />
            </button>
          </>
        ) : (
          ""
        )}
      </div>

      {roomShow ? (
        <>
          <form className="roomsouter" onSubmit={handleAddRoom}>
            <label
              style={{
                color: "#212121",
                fontSize: "14px",
                fontFamily: "Poppins",
                fontWeight: "500",
                lineHeight: "21px",
                marginLeft: "7%",
              }}
            >
              Create Rooms
            </label>

            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-2">
                <input
                  style={{
                    height: "50px",
                    flexShrink: "0",

                    borderRadius: "10px",
                    border: "1px solid #9A9A9A",
                    background: "#FFF",
                    color: "#000",
                  }}
                  type="text"
                  placeholder="Room Name"
                  name="roomname"
                  value={roomdata.roomname}
                  onChange={handleChange}
                />
                {error.roomname ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.roomname}
                  </div>
                ) : null}
              </div>
              <div className="col-2">
                <input
                  type="number"
                  min="1"
                  placeholder="Room No"
                  name="roomnumber"
                  value={roomdata.roomnumber}
                  onChange={handleChange}
                  style={{
                    height: "50px",
                    flexShrink: "0",
                    borderRadius: "10px",
                    border: "1px solid #9A9A9A",
                    color: "#000",
                    background: "#FFF",
                  }}
                />
                {error.roomnumber ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.roomnumber}
                  </div>
                ) : null}
              </div>
              <div className="col-2">
                <select
                  style={{
                    width: "100%",
                    height: "50px",
                    flexShrink: "0",
                    borderRadius: "10px",
                    border: "1px solid #9A9A9A",
                    color: "#000",
                    background: "#FFF",
                  }}
                  value={roomdata.roomtype.value} // Set the value property instead of the whole object
                  onChange={(e) => roomTypeData(e.target.value)} // Pass the selected value instead of the whole event
                  name="roomtype"
                  className=""
                >
                  <option value="">Select Room Type</option>{" "}
                  {/* Add a default option */}
                  {roomtypes.map((roomtype) => (
                    <option key={roomtype.value} value={roomtype.value}>
                      {roomtype.label}
                    </option>
                  ))}
                </select>
                {error.roomtype ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.roomtype}
                  </div>
                ) : null}
              </div>

              <div className="col-1">
                {" "}
                <input
                  type="number"
                  min="0"
                  placeholder="Room Price"
                  name="roomprice"
                  value={roomdata.roomprice}
                  onChange={handleChange}
                  style={{
                    height: "50px",
                    flexShrink: "0",
                    borderRadius: "10px",
                    color: "#000",
                    border: "1px solid #9A9A9A",
                    background: "#FFF",
                  }}
                />
                {error.roomprice ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.roomprice}
                  </div>
                ) : null}
              </div>
              <button
                className="facilitybuttons col-1"
                style={{
                  paddingTop: "15px",
                  cursor: "pointer",
                  height: "50px",
                  marginLeft: "10%",
                }}
              >
                Add New Room
              </button>
            </div>
          </form>

          <div className="facilitytableouter"   >
            {/* List of Rooms */}
            <label
              style={{
                fontSize: "18px",
                color: "#212121",
                fontFamily: "Poppins",
              }}
            >
              List of Rooms{" "}
              <h6
                style={{
                  fontSize: "12px",
                  color: "#B5B5C3",
                  fontFamily: "Poppins",
                  textTransform:"capitalize"
                }}
              >
               <span style={{textTransform:"capitalize"}}> {rooms.length > 0 ? rooms[0].build_name : ""}</span> -{" "}
               <span style={{textTransform:"capitalize"}}>{rooms.length > 0 ? rooms[0].floor_name : ""}</span>
              </h6>
            </label>

            <label
              style={{
                fontSize: "12px",
                color: "#B5B5C3",
                fontFamily: "Poppins",
              }}
            >
              {numRooms} {roomText} added.
            </label>
            <SimpleBar style={{ maxHeight: '500px',width:"1270px" }}>
            <table
              style={{
                margin: "20px 0",
                borderCollapse: "separate",
                borderSpacing: "0 5px",
                width: "100%",
                height: "auto",
                textTransform:"capitalize"
              }}
            >
              <thead>
                <tr
                  style={{
                    height: "43px",
                    flexShrink: "0",
                    borderRadius: "6px",
                    backgroundColor: "var(--dark-teal)",
                  }}
                >
                  <th className="col-1" style={{ color: "white" }}>
                    Sl.No.
                  </th>
                  <th className="col-2" style={{ color: "white" }}>
                    Room Name
                  </th>
                  <th className="col-2" style={{ color: "white" }}>
                    Number
                  </th>
                  <th className="col-2" style={{ color: "white" }}>
                    Floor
                  </th>
                  <th className="col-2" style={{ color: "white" }}>
                    Type
                  </th>
                  <th className="col-2" style={{ color: "white" }}>
                    Price
                  </th>
                  <th className="col-2" style={{ color: "white" }}>
                    Date Added
                  </th>
                </tr>
              </thead>
              <tbody>
             
                {rooms.map((room, index) => (
                  <tr key={room.id} style={{ marginTop: "20px" }}>
                    <td className="col-1">{index + 1}</td>
                    <td className="col-2">{room.room_name}</td>
                    <td className="col-2">{room.room_number}</td>
                    <td className="col-2">{room.floor_name}</td>
                    <td className="col-2">{room.room_type}</td>
                    <td className="col-2">
                      <i class="fa fa-inr" aria-hidden="true"></i>
                      {room.room_price}
                    </td>
                    <td className="col-2">
                      {moment(room.created_at).format("YYYY-MM-DD")}
                    </td>
                  </tr>
                ))}
                    
              </tbody>
            </table>
            </SimpleBar>
          
          </div>
          <br />
        </>
      ) : wardShow ? (
        <>
          <form className="roomsouter " onSubmit={handleAddWard}>
            <label
              style={{
                color: "#212121",
                fontSize: "14px",
                fontFamily: "Poppins",
                fontWeight: "500",
                lineHeight: "21px",
                marginLeft: "7%",
              }}
            >
              Create Wards
            </label>
            <div className="row" style={{ paddingLeft: "2%" }}>
              <div className="col-2">
                <input
                  style={{
                    height: "50px",
                    flexShrink: "0",

                    borderRadius: "10px",
                    border: "1px solid #9A9A9A",
                    background: "#FFF",
                    color: "#000",
                  }}
                  type="text"
                  placeholder="Ward Name"
                  name="wardname"
                  value={warddata.wardname}
                  onChange={handleChange1}
                />
                {error.wardname ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.wardname}
                  </div>
                ) : null}
              </div>
              <div className="col-2">
                <input
                  type="number"
                  min="1"
                  placeholder="Ward No"
                  name="wardnumber"
                  value={warddata.wardnumber}
                  onChange={handleChange1}
                  style={{
                    height: "50px",
                    flexShrink: "0",
                    borderRadius: "10px",
                    border: "1px solid #9A9A9A",
                    color: "#000",
                    background: "#FFF",
                    // marginRight: "10px",
                  }}
                />
                {error.wardnumber ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.wardnumber}
                  </div>
                ) : null}
              </div>

              <div className="col-2">
                <input
                  type="number"
                  min="0"
                  placeholder="Bed Count"
                  name="bedcount"
                  value={warddata.bedcount}
                  onChange={handleChange1}
                  style={{
                    height: "50px",
                    flexShrink: "0",
                    borderRadius: "10px",
                    border: "1px solid #9A9A9A",
                    color: "#000",
                    background: "#FFF",
                  }}
                />
                {error.bedcount ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.bedcount}
                  </div>
                ) : null}
              </div>
              <div className="col-2">
                <select
                  style={{
                    width: "100%",
                    height: "50px",
                    flexShrink: "0",
                    borderRadius: "10px",
                    border: "1px solid #9A9A9A",
                    color: "#000",
                    background: "#FFF",
                  }}
                  value={warddata.wardcount.value} // Set the value property instead of the whole object
                  onChange={(e) => wardTypeData(e.target.value)} // Pass the selected value instead of the whole event
                  name="wardcount"
                  className=" "
                >
                  <option value="">Select ward Type</option>{" "}
                  {/* Add a default option */}
                  {wardType.map((wardtype) => (
                    <option key={wardtype.value} value={wardtype.value}>
                      {wardtype.label}
                    </option>
                  ))}
                </select>
                {error.wardcount ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.wardcount}
                  </div>
                ) : null}
              </div>

              <div className="col-1">
                {" "}
                <input
                  type="number"
                  min="0"
                  placeholder="Bed Price"
                  name="wardprice"
                  value={warddata.wardprice}
                  onChange={handleChange1}
                  style={{
                    height: "50px",
                    flexShrink: "0",
                    borderRadius: "10px",
                    color: "#000",
                    border: "1px solid #9A9A9A",
                    background: "#FFF",
                    // marginRight: "10px",
                  }}
                />
                {error.wardprice ? (
                  <div
                    style={{ zIndex: "auto" }}
                    className="error-validation-msg"
                  >
                    {error.wardprice}
                  </div>
                ) : null}
              </div>
              <button
                className="facilitybuttons col-1"
                style={{
                  paddingTop: "6px",
                  cursor: "pointer",
                  height: "50px",
                  marginLeft: "8%",
                }}
              >
                Add New Ward
              </button>
            </div>
          </form>
          <div className="facilitytableouter">
            {/* List of Rooms */}
            <label
              style={{
                fontSize: "18px",
                color: "#212121",
                fontFamily: "Poppins",
              }}
            >
              List of Wards
            </label>
            <label
              style={{
                fontSize: "12px",
                color: "#B5B5C3",
                fontFamily: "Poppins",
              }}
            >
              {numWards} {wardText} added.
            </label>
<div>
<SimpleBar style={{ maxHeight: '500px',width:"1370px" }}><>
            <div
              class="row"
              style={{ textAlign: "center", width: "100%", paddingTop: "1%" }}
            >
            
              <div class="col-2 ser_header" style={{ border: "none" }}>
                <h6 style={{ marginTop: "5%" }}>Ward Name</h6>
                {/* <input /> */}
              </div>
              <div class="col-3 ser_header" style={{ border: "none" }}>
                <h6 style={{ marginTop: "5%" }}>Ward Number</h6>
                {/* <input /> */}
              </div>
              <div class="col-1 ser_header" style={{ border: "none" }}>
                <h6 style={{ marginTop: "15%" }}>Bed Count</h6>
                {/* <input /> */}
              </div>
              <div class="col-3 ser_header" style={{ border: "none" }}>
                <h6 style={{ marginTop: "5%" }}>Ward Type</h6>
                {/* <input /> */}
              </div>
              <div class="col-1 ser_header" style={{ border: "none" }}>
                <h6 style={{ marginTop: "15%" }}>Bed Price</h6>
                {/* <input /> */}
              </div>
              <div class="col-2 ser_header" style={{ border: "none" }}>
                <h6 style={{ marginTop: "7%" }}>Date of Added</h6>
                {/* <input /> */}
              </div>
            </div>

            {wardShow ? display1 : ""}
         </>   </SimpleBar>
            </div>
          </div>{" "}

        </>
      ) : (
        ""
      )}
      {/* Add Building Popup */}
      {addBuildingPopupVisible ? (
        <BuildingPopup
          show={addBuildingPopupVisible}
          onHide={() => {
            setAddBuildingPopupVisible(false);
          }}
        />
      ) : null}
      {/* Add Floor Popup */}
      {addFloorPopupVisible ? (
        <FloorPopup
          show={addFloorPopupVisible}
          data={selectedBuilding}
          onHide={() => {
            setAddFloorPopupVisible(false);
          }}
        />
      ) : null}

      {addRoomPopupVisible ? (
        <RoomPopup
          show={addRoomPopupVisible}
          onHide={() => {
            setAddRoomPopupVisible(false);
          }}
        />
      ) : null}

      {addWardPopupVisible ? (
        <WardPopup
          show={addWardPopupVisible}
          onHide={() => {
            setAddWardPopupVisible(false);
          }}
        />
      ) : null}

      {/* Add Room Popup */}
      <br />
    </div>
  );
};

export default HospitalManagementComponent;
