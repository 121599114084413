import React, { useState, useEffect, useRef } from 'react';
import { Switch } from "antd";
import './addMachine.css';
import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from 'react-alert';
import { CgCloseR } from "react-icons/cg";

const MachineView = ({ close, machine, handleReload }) => {
    const [machineDatas, setMachineDatas] = useState(machine);
    const inputRefs = useRef([]);
    const modalContentRef = useRef(null);
    const submitButtonRef = useRef(null);
    const alert = useAlert();
    const [error, setError] = useState({});
    const [onload, setOnload] = useState(false);

    useEffect(() => {
        const handleKey = (e) => {
            if (e.key === "Enter") {
                e.preventDefault();
                const activeElement = document.activeElement;
                if (
                    !inputRefs.current.includes(activeElement) &&
                    activeElement !== submitButtonRef.current
                ) {
                    submitButtonRef.current.click();
                }
            } else if (e.altKey && e.key === 's') {
                submitButtonRef.current.click();
            } else if (e.altKey && e.key === 'c') {
                close();
            }
        };

        document.addEventListener("keydown", handleKey);

        return () => {
            document.removeEventListener("keydown", handleKey);
        };
    }, [close]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
                close();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [close]);

    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (index === 33) {
                submitButtonRef.current.click();
            } else {
                const nextIndex = index + 1;
                if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMachineDatas(prevState => ({
            ...prevState,
            [name]: value
        }));
        setError((currentstate) => ({
            ...currentstate,
            [name]: "",
        }));
    };

    const getChangedData = (machineDatas) => {
        let objectByKeys = {};
        if (machine) {
            let sda_changed_keys = Object.keys(machineDatas).filter(key => machineDatas[key] !== machine[key]);
            sda_changed_keys.forEach(key => {
                objectByKeys[key] = machineDatas[key];
            });
        }
        return objectByKeys;
    };

    const validate = async () => {
        setOnload(true);
        await new Promise(resolve => setTimeout(resolve, 0));

        let input = machineDatas;
        let errors = {};
        let isValid = true;

        setError({});
        if (input["model_name"] === '' && machine.model_name !== '') {
            isValid = false
            errors.model_name = 'Model name cannot be cleared'
        }
        setError(errors);

        if (isValid) {
            handleSaveChanges();
        } else {
            setOnload(false);
        }
    };

    const handleSaveChanges = () => {
        const tokenStr = sessionStorage.getItem('usertoken');
        if (tokenStr) {
            const token = tokenStr.replace(/["]+/g, '');
            const dataToSend = getChangedData(machineDatas);

            axios.put(`${APIURL}/api/v1/service-provider/dialysis-unit-detail/?unit_no=${machine.unit_no}`, dataToSend, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
                .then(res => {
                    if (res.data.status === "success") {
                        alert.success('Updated successfully!');
                        handleReload();
                        close()
                    } else {
                        alert.error(res.data.message);
                    }
                })
                .catch(err => {
                    alert.error("Error occurred. Try again!");
                })
                .finally(() => {
                    setOnload(false);
                });
        } else {
            alert.error("User token not found. Please log in again.");
            setOnload(false);
        }
    };

    return (
        <div className='viewmachine-modal'>
            <div className='viewmachine-modal-content' ref={modalContentRef}>
                <div style={{ textAlign: 'right' }}><CgCloseR onClick={close} style={{ cursor: 'pointer' }} /></div>
                <div>
                    <div className='dialysis-icon'>
                        <svg width="59" height="56" viewBox="0 0 69 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="100%" height="100%" fill="url(#pattern0_2828_2823)" />
                            <defs>
                                <pattern id="pattern0_2828_2823" patternContentUnits="objectBoundingBox" width="1" height="1">
                                    <use href="#image0_2828_2823" transform="matrix(0.00956522 0 0 0.01 0.0217391 0)" />
                                </pattern>
                                <image id="image0_2828_2823" width="100" height="100" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEP0lEQVR4nO2dX4hVRRzHZxVJYdNK7cFA2S0fwofUFUmKzEDw2ZDSHiqEUjKkFcU/D4a7wYKCz+tDYW/e1SLqYckUMagkqXwRFUp78EVJTbMlV/Yjg/NwV7Z77t4zc8/Mb34fGFiW3Z3fdz7cc87OmTPHGEVRFEVRFEVRFKEAS4E9wCGglkg7BOy2tRspAC8BZ0ifn4AVJmWAzcAochgFNpkUAV4HxpDHGPCmSQlgDnALudwCZptUAD5BPv0mFYCLyOeSSQFgJvnwuIkdYGETQYYj+B+jVtBsjUUsNLEDLGoiyCITOQjJISYIQnKICYKQHC0FAbqBnopbd9kckoTYk2jV1MrmiBIVEhkqJDJUSGRIOfYiJIeYIAjJISYIQnKICULmJ/UB4GzFbaBsjijRq6zIUCGRoUJkCFkBrKu4jVtzlfU5JEYQkkNMEITkEBMEITmaDdJjIgdYlpOQ08B8EynAAuD7nIRY/gE+BVYB0yKoexrwGvCZq43chNRzF/gO6LMLmYHFwPSANc5wfdi++l3ftobJUp0QoMOFWFtwPd+LP64BvwJfu0/TQWAvsBV4z7UNdX1vqPv+VvezB93v2r/xm/ubvugtGIu1bsw6fMt4FvjZY5DcOGPH0JeMTuBy1YkE8IcdSx9CPqw6iSC2+BDyOfFz37XYOexDSAyL2IrYAewkfmo5CDnqrgBtO0bciBfyJ/BUXa1PuJNnrIgWMjrRc+PAcuA/MhbSyiJlH2xvUHO7zieTXTQuVshxYEqDmqe46ZDQqBAeTvyNe5ajwYztbRUS/hOyaRKf7g9USFghvzQ6VP3PoSvkHFzWh6wx4OUW6n+FcGQtZLhEBnsREIKshawukWFNoJqyFfJXmZs8blrlaoC6shVy3EOObwLUla2QUx5y2JUuvslWyA3gsRIZpgM3A9SVrRDLOyUyvEsYshZyDZjXQv3PANcD1ZS1EMsFoGsStXcF3t0ueyG4CcOPGq3gsDu9AduAO4RFhTyyM+gX7t7H267Zr78E/qY9qJDIUCGRoUIiI0ohQxPsDnc2k9b9SPahdggZLOjkZOlOhACcKhirQR+dfFzQyb92r3eTOcBcYKRgrPb66OgtijkCTDWZAkx1KyiLWO+js9lNvgPkBLDSPp1kMoGHT2K92sShynIPeNJXx+1Y4ySdb73IaMNCgVxY5U2Ik2Kf0VNa4yuvMuremvN7iwXlzBXgae9CnJTFge68SeUG8EIQGXVSngPOV500AS4BzweVUSdlFnCgiX+EcmQE2G/fNNQWGROsMN8HnKt6FCLgnBuL+TFNHdjXq24kHzYCS2x2Eyvu9mkudJoUcIsRpHPepILbYUc6fSYV7CSaWyAtlZtJvXrV4rYmkvpy4jdMitjnAYW9vvse8L5JGbdJ8o+kzw/Ai0YK7np9l7tHX0ukDbqal1Q9foqiKIqiKIqiKCYQDwAkUKz1Q+aAAwAAAABJRU5ErkJggg==" />
                            </defs>
                        </svg>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className='switch-container'>
                            <label className='col-6'>Machine Status</label>
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                checked={machineDatas.is_active === "true" || machineDatas.is_active === true || machineDatas.is_active === "True"}
                                onChange={(checked) => {
                                    setMachineDatas(current => ({
                                        ...current,
                                        is_active: checked ? "True" : "False"
                                    }));
                                }} />
                        </div>
                        <div className='switch-container'>
                            <label className='col-6'>Back Up Machine</label>
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                checked={machineDatas.is_standby === "true" || machineDatas.is_standby === true || machineDatas.is_standby === "True"}
                                onChange={(checked) => {
                                    setMachineDatas(current => ({
                                        ...current,
                                        is_standby: checked ? "True" : "False"
                                    }));
                                }} />
                        </div>
                    </div>
                    <div className='input-container'>
                        <label>Unit Number<span>*</span></label>
                        <input
                            className='input1'
                            disabled
                            value={machineDatas.unit_no}
                        />
                    </div>
                    <div className='input-container'>
                        <label className={error.model_name ? 'error-label' : ''}>
                            {error.model_name || <>Model Name</>}
                        </label>
                        <input
                            className={`input1 ${error.model_name ? 'error-border-viewmachine' : ''}`}
                            ref={el => inputRefs.current[1] = el}
                            onKeyDown={(e) => handleKeyDown(e, 1)}
                            type="text"
                            maxLength="50"
                            value={machineDatas.model_name}
                            name="model_name"
                            onChange={handleChange}
                        />
                    </div>
                    <div className='input-container'>
                        <label>Remarks</label>
                        <input
                            className='input1'
                            ref={el => inputRefs.current[2] = el}
                            onKeyDown={(e) => handleKeyDown(e, 2)}
                            type="text"
                            maxLength="100"
                            value={machineDatas.remarks}
                            name="remarks"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='viewmachine-button-container'>
                    <button onClick={validate} disabled={onload} ref={submitButtonRef} className='done-button' style={{ width: "150px", cursor: onload ? 'progress' : '' }}>Save Changes</button>
                </div>
            </div>
        </div>
    );
};

export default MachineView;
