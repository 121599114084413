import React, { useEffect, useState, useContext } from "react";
import { Link, useParams, useLocation,useNavigate } from "react-router-dom";
import PatientNav from "./PatientNav";
function SearchNavigation({ setShowNavbarAndFooter }) {
    const { state } = useLocation()
    const [id, setId] = useState(state.details);
    const navigate = useNavigate();
    useEffect(() => {
      
          navigate('/search', {state:{details:id }});
       
      }, [id]);
    useEffect(() => {
        setShowNavbarAndFooter(false);
        return () => setShowNavbarAndFooter(true);
    }, [setShowNavbarAndFooter]);
   
   
return(<>
    <PatientNav/>

    </>
)
}
export default SearchNavigation;