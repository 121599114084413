import React, { useState, useEffect, useRef } from 'react';
import { Switch } from "antd";
import './addMachine.css';
import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from 'react-alert';
import { CgCloseR } from "react-icons/cg";

const AddMachinePopup = (props) => {

    const inputRefs = useRef([]);
    const modalContentRef = useRef(null);
    const submitButtonRef = useRef(null);
    const alert = useAlert()
    const [onload, setOnload] = useState(false)
    const [error, setError] = useState({})
    const [machineDatas, setMachineDatas] = useState({
        unit_no: '',
        model_name: '',
        is_standby: false,
        is_active: true,
        remarks: ''
    });

    useEffect(() => {
        document.addEventListener("keydown", handleKey);

        return () => {
            document.removeEventListener("keydown", handleKey);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault()

            if (index === 33) {
                submitButtonRef.current.click();
            } else {
                e.preventDefault();
                const nextIndex = index + 1;
                if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const handleKey = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            const activeElement = document.activeElement;
            if (
                !inputRefs.current.includes(activeElement) &&
                activeElement !== submitButtonRef.current
            ) {
                submitButtonRef.current.click();
            }
        } else if (e.altKey && e.key === 's') {
            submitButtonRef.current.click();
        } else if (e.altKey && e.key === 'c') {
            props.close()
        }
    };

    const handleClickOutside = (event) => {
        if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
            props.close();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setError(prevState => ({
            ...prevState,
            [name]: ''
        }))

        setMachineDatas(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validate = async () => {
        setOnload(true);
        await new Promise(resolve => setTimeout(resolve, 0));

        let input = machineDatas;
        let errors = {};
        let isValid = true;

        setError({});

        if (!input["unit_no"] || input["unit_no"] === "") {
            isValid = false;
            errors.unit_no = 'Unit no required';
        }

        setError(errors);

        if (isValid) {
            handleAddMachine()
        }
        else {
            alert.error("Check all the fields")
            setOnload(false)
        }
    }

    const handleAddMachine = async () => {
        const tokenString = sessionStorage.getItem('usertoken');
        let str = tokenString.replace(/["]+/g, '');
        const formData = new FormData();
        formData.append('unit_no', machineDatas.unit_no);
        formData.append('model_name', machineDatas.model_name);
        formData.append('is_standby', machineDatas.is_standby);
        formData.append('is_active', machineDatas.is_active);
        if (machineDatas.remarks) {
            formData.append('remarks', machineDatas.remarks);
        }

        try {
            const res = await axios.post(`${APIURL}/api/v1/service-provider/dialysis-unit/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Token " + str
                }
            });

            if (res.status === 200) {
                if (res.data.status === "success") {
                    alert.success(res.data.message);
                    props.handleReload();
                    props.close();
                } else {
                    alert.error(res.data.message);
                }
            } else {
                alert.error(res.data.message);
            }
        } catch (err) {
            alert.error('An error occurred. Please try again.');
        } finally {
            setOnload(false);
        }
    };


    return (
            <div className='' ref={modalContentRef}>
                {!props.primary && <div style={{ textAlign: 'right' }}><CgCloseR onClick={props.close} style={{ cursor: 'pointer' }} /></div>
                }
                <div className='add-machine-input'>
                    <div className='addmachine-head'>
                        <label>Add Machine Details</label>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div className='col-6 input-container'>
                            <label className={error.unit_no ? 'error-label' : ''}>
                                {error.unit_no || <>Unit Number<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.unit_no ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[0] = el}
                                onKeyDown={(e) => handleKeyDown(e, 0)}
                                type="text"
                                maxLength="50"
                                value={machineDatas.unit_no}
                                name="unit_no"
                                onChange={handleChange}
                            />
                        </div>
                        <div className='col-6 input-container'>
                            <label className={error.model_name ? 'error-label' : ''}>
                                {error.model_name || <>Model Name</>}
                            </label>
                            <input
                                className={`input1 ${error.model_name ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[1] = el}
                                onKeyDown={(e) => handleKeyDown(e, 1)}
                                type="text"
                                maxLength="50"
                                value={machineDatas.model_name}
                                name="model_name"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div style={{ display: "flex", margin: "10px 0 20px 0" }}>
                        <div className='col-6'>
                            <label>Back Up Machine</label>
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                onChange={(checked) => {
                                    setMachineDatas(current => ({
                                        ...current,
                                        is_standby: checked ? "true" : "false"
                                    }));
                                }} />
                        </div>
                        <div className='col-6'>
                            <label>Machine Status</label>
                            <Switch
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                checked={machineDatas.is_active === "true" || machineDatas.is_active === true}
                                onChange={(checked) => {
                                    setMachineDatas(current => ({
                                        ...current,
                                        is_active: checked ? "true" : "false"
                                    }));
                                }} />
                        </div>
                    </div>
                    <div>
                        <div className='col-12 input-container'>
                            <label>Remarks</label>
                            <input
                                className='input1'
                                ref={el => inputRefs.current[2] = el}
                                onKeyDown={(e) => handleKeyDown(e, 2)}
                                type="text"
                                maxLength="100"
                                value={machineDatas.remarks}
                                name="remarks"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className='addmachine-button-container'>
                    <button ref={submitButtonRef} onClick={() => { validate() }} disabled={onload} style={{ cursor: onload ? 'progress' : '' }} className='done-button'>Done</button>
                </div>
            </div>
    )
};

export default AddMachinePopup;