import React, {useState} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function WeightAdd(props) {
  

    const [dispense, setDispense]=useState(props.seteditDispence !== "" ? props.seteditDispence : "")

    const [modalShow, setModalShow]=useState(false)
    const [errorShow, setErrorShow]= useState(false)
    const [limitShow , setLimitShow]= useState(false)
    const alert = useAlert()
    const handleChange = (e)=> {
        
        const val = e.target.value
        if (props.intake === "nos") {
          if (val <= props.seteditDispence && val !== "0") {
            setDispense(e.target.value);
          } else {
            alert.error("Check the count you added");
          }
        } else {
          // Allow editing when intake is not "nos"
          setDispense(e.target.value);
        }
        
    }

    const handleSubmit = () => {

       
     if(dispense===props.seteditDispence){
        alert.error("already exisit")
      }

     else if(dispense){

            const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               dispensing_count: dispense
           }
     
     
       
         axios.put(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/${id}/`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
                setModalShow(true)
                setDispense(" ")
                props.handle()
          
       }
       else{
            setErrorShow(true)
        
       }
     
         })
         .catch(err=>{
       
            setErrorShow(true)
     })
    }

   

else{
  alert.error("add count")
}





        

        
    }

    const SubmitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-success'>Dispense count added Successfully !!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const ErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> Error in data submission.Please check again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const LimitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-muted'>Please check weight value</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

    return (
        <div className='flex-col'>
           <input style={{width:'50%'}} type="text"  placeholder="Dispense count "     inputMode="numeric"
        pattern="[0-9]*"  
       value={dispense} onChange={handleChange} className="form-control"/><br/>
           <button  onClick={handleSubmit} className='btn btn-primary btn-col'>Add</button> 


           {
      modalShow ? 
      <SubmitPopup
        show={modalShow}
        onHide= {() => { setModalShow(false); props.onHide(); props.onHide();     
        }}
      /> : null
    }

{
      errorShow ? 
      <ErrorPopup
        show={errorShow}
        onHide= {() => { setErrorShow(false)     
        }}
      /> : null
    }

{
      limitShow ? 
      <LimitPopup
        show={limitShow}
        onHide= {() => { setLimitShow(false)     
        }}
      /> : null
    }

        </div>
    )
}

export default WeightAdd;
