import React, { useContext, useState, useEffect, useRef } from "react";
import ChartistGraph from "react-chartist";
import { useLocation, NavLink } from "react-router-dom";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin.js";
import Sidebar from "../components/Sidebar/Sidebar.js"


import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import sidebarImage from "../assets/img/sidebar-8.jpg";

// react-bootstrap components
import {
  Badge,

  Card,

  Table,

  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Monitor from "./Monitor";
import CareTouch from "./CareTouch";

function AdminHome() {
  const [show,setShow]=useState("treatment")
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  const currentTime = new Date().getTime();
  const checkTime = new Date();
  const morningStart = new Date(checkTime.setHours(7, 0, 0, 0)).getTime();
  const morningEnd = new Date(checkTime.setHours(11, 59, 0, 0)).getTime();
  
  const afternoonStart = new Date(checkTime.setHours(12, 0, 0, 0)).getTime();
  const afternoonEnd = new Date(checkTime.setHours(15, 29, 0, 0)).getTime();
  
  const eveningStart = new Date(checkTime.setHours(15, 30, 0, 0)).getTime();
  const eveningEnd = new Date(checkTime.setHours(19, 29, 0, 0)).getTime();
  
  const nightStart = new Date(checkTime.setHours(19, 30, 0, 0)).getTime();
  const nightEnd = new Date(checkTime.setHours(23, 59, 59, 999)).getTime();
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("white");
  const [hasImage, setHasImage] = React.useState(true);
  const [id,setId]=useState("")
  const location = useLocation();
  const mainPanel = React.useRef(null);
  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/admin") {
  //       return (
  //         <Route
  //           path={prop.layout + prop.path}
  //           render={(props) => <prop.component {...props} />}
  //           key={key}
  //         />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  const onSuccess = (data) => {
    setId(data)
 
   };
  return (
    <>
      <Container fluid >
      <div className="row"style={{minHeight:"750px"}}>
         <div className="col-2" >
            <div className="sidebar" >
              <div
                className="sidebar-background"
                style={{
                  // backgroundImage: "url(" + image + ")"
                }}
              />
              <div className="sidebar-wrapper">
                <div className="logo d-flex align-items-center justify-content-start">
                  <a
                    href="https://www.creative-tim.com?ref=lbd-sidebar"
                    className="simple-text logo-mini mx-1"
                  >
                    <div className="logo-img">
                      {/* <img src={require("../../assets/img/reactlogo.png")} alt="..." /> */}
                    </div>
                  </a>
                  <a className="simple-text" href="http://www.creative-tim.com">

                  </a>
                </div>
                <Nav>
                  <Sidebar color={color} image={hasImage ? image : "" } callid={onSuccess} onSelect={(data) =>{setShow(data)}}  />
                  <FixedPlugin
                    hasImage={hasImage}
                    setHasImage={() => setHasImage(!hasImage)}
                    color={color}
                    setColor={(color) => setColor(color)}
                    image={image}
                    setImage={(image) => setImage(image)}
                  />
                </Nav>
              </div>
            </div>
          </div>
         <div className="col-10">
          {show==="treatment"?
          currentTime >= morningStart && currentTime <=nightEnd?
            <Monitor id={id}/>
            :<div  style={{height:"650px", padding:"20%"}} className="text-warning">
                 <h4><b> Administration starts at 7 am..</b></h4>
        </div>:
        show==="care"?
      <CareTouch id={id}/>:<div  style={{height:"650px", padding:"20%"}} className="text-warning">
      <h4><b> Feature is under construction</b></h4>
</div>}
          </div>


        </div>

      </Container>
    </>
  );
}

export default AdminHome;
