import React, { useState, useEffect } from "react";
import "react-table-v6/react-table.css";
import axios from "axios";
import { APIURL } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { useAlert } from "react-alert";
function EditMedicine(data) {
  const alert = useAlert();

  const [frequency, setFrequency] = useState(data.data.frequency)
  const [intakeTime, setIntakeTime] = useState(data.data.intake_time)
  const [duration, setDuration] = useState(data.data.duration)
  const [durationUnit, setDurationUnit] = useState({ label: data.data.duration_unit, value: data.data.duration_unit })

  const [intakeCount, setIntakeCount] = useState(data.data.intake)
  const [intakeunit, setIntakeUnit] = useState({ label: data.data.intake_unit, value: data.data.intake_unit })
  const [dosage, setDosage] = useState(data.data.intake_count)
  const [remark, setRemark] = useState(data.data.remarks)

  const [options, setOptions] = useState([])



  const intakecountoptions = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },

  ];

  const freqOptions = [
    { value: "0-0-0-1", label: "0-0-0-1" },
    { value: "0-0-1-0", label: "0-0-1-0" },
    { value: "0-0-1-1", label: "0-0-1-1" },
    { value: "0-1-0-0", label: "0-1-0-0" },
    { value: "0-1-0-1", label: "0-1-0-1" },
    { value: "0-1-1-0", label: "0-1-1-0" },
    { value: "0-1-1-1", label: "0-1-1-1" },
    { value: "1-0-0-0", label: "1-0-0-0" },
    { value: "1-0-0-1", label: "1-0-0-1" },
    { value: "1-0-1-0", label: "1-0-1-0" },
    { value: "1-0-1-1", label: "1-0-1-1" },
    { value: "1-1-0-0", label: "1-1-0-0" },
    { value: "1-1-0-1", label: "1-1-0-1" },
    { value: "1-1-1-0", label: "1-1-1-0" },
    { value: "1-1-1-1", label: "1-1-1-1" },
  ];
  const durationOptions = [
    { value: "days", label: "Days" },
    { value: "weeks", label: "Weeks" },
    { value: "months", label: "Months" },
  ];
  const durationOptions1 = [

    { value: "weeks", label: "Weeks" },
    { value: "months", label: "Months" },
  ];

  const durationOptions2 = [


    { value: "months", label: "Months" },
  ];
  const intakeTimeOptions = [

    { value: "before food", label: "Before food" },
    { value: "after food", label: "After food" },
  ];


  const IntakeUnitOptions1 = [

    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "oz", label: "ounces" },
    { value: "nos", label: "number" },
    { value: "shots", label: "shots" },
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" },
    { value: "cc", label: "cc" },
    { value: "g", label: "g" },
    { value: "puffs", label: "puffs" },

  ];
  const IntakeUnitOptions2 = [
    { value: "ml", label: "mL" },
    { value: "cc", label: "cc" },
    { value: "shots", label: "shots" }
  ];
  const IntakeUnitOptions3 = [
    { value: "ml", label: "mL" },
    { value: "puffs", label: "puffs" }
  ]
  const IntakeUnitOptions4 = [
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" }
  ]

  const IntakeUnitOptions5 = [
    { value: "nos", label: "number" },
    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "g", label: "g" }
  ]
  const IntakeUnitOptions6 = [
    { value: "tbsp", label: "tbsp" },
    { value: "tsp", label: "tsp" },
    { value: "oz", label: "ounces" },
    { value: "ml", label: "mL" },
    { value: "drops", label: "drops" }
  ]
  const IntakeUnitOptions7 = [
    { value: "nos", label: "number" }
  ]
  useEffect(() => {
    if (intakeCount === "Daily" || intakeCount === "daily") {
      setOptions(durationOptions)
    }
    else if (intakeCount === "Weekly" || intakeCount === "weekly") {
      setOptions(durationOptions1)
    }
    else if (intakeCount === "Monthly" || intakeCount === "monthly") {
      setOptions(durationOptions2)
    }
  }, [intakeCount])
  const handleChange = (e) => {
    const val = e.target.value;
    const key = e.target.label;

    setDurationUnit({ key: val, value: val })

  }
  const handleChange1 = (e) => {
    const val = e.target.value;
   

    setIntakeUnit({ label: val, value: val })

  }


  const handleSearchChange = (e) => {
    const val = e.target.value;
    const key = e.target.name;
    if (key === "frequency") {
      setFrequency(val)
    }
    if (key === "intakeTime") {
      setIntakeTime(val)
    }
    if (key === "period") {

      setDuration(val)


    }

    if (key === "intakeUnitValue") {

      setIntakeUnit(val)


    }
    if (key === "intakeCount") {
      setDurationUnit({ key: "", value: "" })
      setIntakeCount(val)
    }
    if (key === "dosage") {
      setDosage(val)
    }
    if (key === "remark") {
      setRemark(val)
    }
  }
  const medicineSubmit = (MedData) => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");


    if (dosage < 1) {
      alert.error("*Intake Count is mandatory!");
    }
    else if (duration<1) {
      alert.error("*Please choose a valid duration!");

    }
    else if(durationUnit.value===""){
      alert.error("*Please choose a valid duration!");
    }

    else {
      const dataToSend = {
        frequency: frequency,
        intake: intakeCount,
        intake_time: intakeTime,
        duration: duration,
        duration_unit: durationUnit.value,
        intake_count: dosage,
        remarks: remark,
        intake_unit: intakeunit.value
      };

      axios
        .put(`${APIURL}/api/v1/doctor/patient-prescriptions-detail/${data.data.id}/`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + str,
          },
        })
        .then((res) => {

          if (res.data.status === "success") {
            alert.success("Updated successfully")
            data.modalClose()


          } else {
            alert.error(res.data.message);

          }
        })
        .catch((err) => {
          alert.error("error");

        });
    }
  };
  const handleCancel = () => {
    data.modalClose();
  };

  return (
    <>

      <div className="flex-head">
        <h4 className="title-of-tasks title-mg"> Edit Medicines</h4>
        <h5 className="title-of-tasks"> {data.data.medicine_name}</h5>
      </div>
      <br />

      <div className=" ">
        <div className="" >
          <div style={{ display: "flex" }}>

            <label className="col-4">*Frequency :</label>
            <select
              name="frequency"
              type="text"
              className=" col-7"
              value={frequency}
              defaultInputValue={frequency}
              onChange={handleSearchChange}
              options={freqOptions}
            >
              {freqOptions.map(item => (
                <option value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

          </div><br />
          <div style={{ display: "flex" }}>

            <label className="col-4">*Intake Time :</label>
            <select

              name="intakeTime"
              type="text"
              className=" col-7"
              value={intakeTime}
              defaultInputValue={intakeTime}
              onChange={handleSearchChange}
              options={intakeTimeOptions}
            >
              {intakeTimeOptions.map(item => (
                <option value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

          </div><br />
          <div style={{ display: "flex" }}>

            <label className="col-4">*Intake Period :</label>
            <select
              name="intakeCount"
              type="text"
              className=" col-7"
              value={intakeCount}
              // defaultInputValue={intakecount_label}
              onChange={handleSearchChange}
              options={intakecountoptions}
            >
              {intakecountoptions.map(item => (
                <option value={item.label}>
                  {item.label}
                </option>
              ))}
            </select>

          </div><br />
          <div className="" style={{ display: "flex" }}>

            <label className="col-4">*Duration :</label>
            <div className="col-7"  >

              <div style={{ display: "flex", marginLeft: "-10px" }}>
                <input
                  name="period"
                  type="number"
                  min="1"
                  value={duration}
                  className=" col-3"
                  onChange={handleSearchChange}
                /><span>&nbsp;&nbsp;</span>
                <select
                  name="durationValue"
                  type="text"
                  className=" col-9"
                  value={durationUnit.value}
                  onChange={handleChange}
                  // options={options}
                >
                   <option hidden value="" ></option>
                 {options.map((item)=>{
                  return(
                  <option  hidden={item.value===""} value={item.value} >{item.label}</option>
                  )
                })}
                </select>

              </div>
            </div></div><br />

          <div style={{ display: "flex", paddingRight: "3%" }}>

            <label className="col-4">*Intake Count:</label>
            <input
              name="dosage"
              style={{ marginLeft: "3%" }}
              type="number"
              min="1"
              max="200"
              className=" col-7"
              value={dosage}
              defaultInputValue={dosage}
              onChange={handleSearchChange}

            />

          </div><br />
          <div style={{ display: "flex" }}>

            <label className="col-4">*Intake Unit :</label>
            <select

              name="intakeUnitValue"
              type="text"
              className=" col-7"
              value={intakeunit.value}
             
              onChange={handleChange1}
             
            >
               <option hidden value="" ></option>
               { data.data.form.toLowerCase().includes("capsule") || data.data.form.toLowerCase().includes("chewable") || data.data.form.toLowerCase().includes("suppositor") || data.data.form.toLowerCase().includes("tablet")? IntakeUnitOptions7.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }) :data.data.form.toLowerCase().includes("solution") || data.data.form.toLowerCase().includes("emulsion") || data.data.form.toLowerCase().includes("lotion") ? IntakeUnitOptions6.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }) :data.data.form.toLowerCase().includes("suspension") || data.data.form.toLowerCase().includes("cream") || data.data.form.toLowerCase().includes("ointment")? IntakeUnitOptions4.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }):data.data.form === "effervescent granules" || data.data.form.toLowerCase().includes("granul")?IntakeUnitOptions5.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }): data.data.form === "aerosols" || data.data.form === "gases" || data.data.form.toLowerCase().includes("aerosol") || data.data.form.toLowerCase().includes("gas") ?IntakeUnitOptions3.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }):data.data.form === "injections" || data.data.form.toLowerCase().includes("injection")?IntakeUnitOptions2.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            }):IntakeUnitOptions1.map((item)=>{
              return(
                <option hidden={item.value===""} value={item.value} >{item.label}</option>
              )
            })}
              </select>



          </div><br />
          <div style={{ display: "flex", paddingRight: "3%" }}>

            <label className="col-4">Remark :</label>
            <textarea
              name="remark"
              style={{ marginLeft: "3%" }}
              type="text"
              className=" col-7"
              value={remark}
              defaultInputValue={remark}
              onChange={handleSearchChange}

            />

          </div><br />
          
        <div style={{ marginTop: '20px', display: 'flex' ,float:"right", }}>
        <button onClick={handleCancel} className="btn btn-secondary mb-2 mr-2" >
          Cancel
        </button>
        <button onClick={medicineSubmit} type="submit" className="btn btn-primary btn-col mb-2" >
          Save
        </button>
        </div>

        </div>
      </div>


    </>
  );
}

export default EditMedicine;
