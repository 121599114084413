import React, { useCallback, useState, useEffect, useRef } from "react";
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAlert } from "react-alert";
//import TargetSet from "../TargetSet/TargetSet";

function DiseaseAdd(pr) {
  const [disease, setDisease] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [limitShow, setLimitShow] = useState(false);
  const [singleSelections, setSingleSelections] = useState([]);
  const [singleSelectionsKeyup, setSingleSelectionsKeyup] = useState("");
  const [assignList, setAssignList] = useState([]);
  const [searchAssign, setSearchAssign] = useState([]);
  const [, setGetError] = useState("");
  const [stage, setStage] = useState("");
  const [duration, setDuration] = useState("");
  const [displaychronicValueId, setdisplaysetChronicValueId] = useState(0);
  const id = pr.id;
  const [submitMsg, setSubmitMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [submitShow, setSubmitShow] = useState("");
  const [displaychronicValue, displaysetChronicValue] = useState([]);
  const [displaystage, displaysetStage] = useState("");
  const [displayduration, displaysetDuration] = useState("");
  const [stageAddShow, setStageAddShow] = useState([]);
  const [durationAddShow, setDurationAddShow] = useState([]);
  const [diseaseAddShow, setDiseaseAddShow] = useState([]);
  const [targetData, setTargetData] = useState([]);
  const [diabeticTargetShow, setDiabeticTargetShow] = useState(false);
  const [targetShow, setTargetShow] = useState(false);
  const alert = useAlert();
  const inputRefs = useRef([])
  const saveButtonRef = useRef(null)
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {  
      e.preventDefault()
      if(index === 2 ) {
        saveButtonRef.current.click();
      }else{
        e.preventDefault();    
        const nextIndex = index + 1;
        if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
          inputRefs.current[nextIndex].focus();
        }
    }
    }else if (e.altKey && e.key === 's') {
      saveButtonRef.current.click(); 
    }
  };

  // useEffect(() => {
    
  //   const handleKeyDown = (e) => {
  //     if (e.altKey && e.key === 'c') {
  //       console.log(diseaseAddShow)
  //       setDiseaseAddShow(false); 
  //     }
  //   };
    
  //   document.addEventListener("keydown", handleKeyDown);
    
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   }
    
  // }, []);


  const handleChange = (e) => {
    const val = e.target.value;
    if (val.length < 4) {
      setDisease(e.target.value);
    } else {
      return false;
    }
  };



  const handleTargetsDiabetic = () => {
    // const tokenString = sessionStorage.getItem("usertoken");

    // let str = tokenString.replace(/["]+/g, "");

    // axios
    //   .get(
    //     `${APIURL}/api/v1/patient/targets-for-patient-type/?patient_type=t2_diabetic`,
    //     {
    //       headers: {
    //         Authorization: "Token " + str,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     if (res.data.status === "success") {
    //       setTargetData(res.data.target_vitals);
    //       setDiabeticTargetShow(true);
    //     } else {
    //     }
    //   })
    //   .catch((err) => {});
  };


  const SubmitSuccessPopup1 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-success"> {submitMsg} </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="btn btn-primary btn-col" onClick={pr.onSubmit}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
          {errorMsg}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-smallest btn-square btn-primary btn-col btn-col " onClick={pr.onSubmit}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const refreshPage=()=> {

    window.location.reload(false);

 

  }

  const TargetPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <TargetSet {...props} /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-smallest btn-square btn-primary btn-col btn-col " onClick={pr.onSubmit}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const DiabeticTargetsPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Proceed to set the following Targets : </h5>
          {targetData.map((item, index) => {
            return (
              <>
                <span className="text-muted font-weight-bold">
                  {index + 1}. {item.vital_name}
                </span>
                <br />
              </>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={pr.onSubmit}>
            Not Now
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onCall}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const setOnChangeTypeaheadDisease = (e) => {
    if (e != null && e != undefined) {
      // if (e.length > 0) {
      //   if (e[0].disease_name == "Diabetic") {
      //     handleTargetsDiabetic();
      //   }
        setSingleSelections(e);
      
    }
  };

  const handleKeyPress = useCallback((e) => {
    if (e != "" && e != null) {
      setSingleSelectionsKeyup(e);
      setSingleSelections([]);
    } else {
      setSingleSelections([]);
    }
  });
  const handlesetStage = (e) => {
    setStage(e.target.value);
  };
  const handleSetDuration = (e) => {
    setDuration(e.target.value);
  };
  const retunButton = (props) => {
    let value = "";
    let text = "Save";

    text = "Save";
    const handleUpdateStageAndDuration = (e) => {
      if (e == "Save") {
        Save();
      } else {
        Update();
      }
    };
 
    const Save = () => {
      if (singleSelections.length > 0) {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const dataToSend = {
          patient: id,
          disease: singleSelections[0].disease_name,
          stage: stage,
          duration: duration,
        };

        axios
          .post(`${APIURL}/api/v1/doctor/primary-disease-list/`, dataToSend, {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
            if (res.status == 200 && res.data.status=='success') {
              alert.success(res.data.message);
             
            
          
              pr.onSubmit();
             
             
      
              //handleTargets();
            } else if (res.status == 200 && res.data.status=='error'){
              alert.error(res.data.message);
             
              setReRender(!reRender);
            }
            else{
              setErrorMsg(res);
              setErrorSubmit(true);
            }
          })
         
      } else {
        if (singleSelectionsKeyup != "") {
          const tokenString = sessionStorage.getItem("usertoken");
          let str = tokenString.replace(/["]+/g, "");
          const dataToSend = {
            patient: id,
            disease: singleSelectionsKeyup,
            stage: stage,
            duration: duration,
          };

          axios
            .post(`${APIURL}/api/v1/doctor/primary-disease-list/`, dataToSend, {
              headers: {
                Authorization: "Token " + str,
              },
            })
            .then((res) => {
              if (res.status == 200 && res.data.status=='success') {
                alert.success(res.data.message);
                setSingleSelections([]);
                setSingleSelections("");
                setSingleSelectionsKeyup("");
              
            
                pr.onSubmit();
                
              } else if (res.status == 200 && res.data.status=='error') {
                alert.error(res.data.message);
                
                setReRender(!reRender);
              }
              else{
                setErrorMsg(res);
                setErrorSubmit(true);
              }
            })
           
        } else {
          setErrorMsg("Please add a disease here!!");
          setErrorSubmit(true);
        }
      }
    };

    const Update = () => {
      if (displaychronicValueId > 0) {
        if (
          stage != null &&
          stage != undefined &&
          stage != "" &&
          duration > 0
        ) {
          const tokenString = sessionStorage.getItem("usertoken");
          let str = tokenString.replace(/["]+/g, "");
          const dataToSend = {
            stage: stage,
            duration: duration,
          };
          axios
            .put(
              `${APIURL}/api/v1/doctor/primary-disease-details/${displaychronicValueId}/`,
              dataToSend,
              {
                headers: {
                  Authorization: "Token " + str,
                },
              }
            )
            .then((res) => {
              if (res.status == 200 && res.data.status=='success') {
                setSubmitMsg(res.data.message);
                setSubmitShow(true);
                
                displaysetChronicValue(res.data.data.disease);
                displaysetStage(res.data.data.stage);
                displaysetDuration(res.data.data.duration);
              } else if (res.status == 200 && res.data.status=='error') {
                setErrorMsg(res.data.message);
                setErrorSubmit(true);
                setReRender(!reRender);
              }
              else{
                setErrorMsg(res);
                setErrorSubmit(true);
              }
            })
            .catch((err) => {
              setSubmitMsg("error");

              setErrorSubmit(true);
              setReRender(!reRender);
            });
        } else {
          setErrorMsg("Enter stage and duration");
          setErrorSubmit(true);
        }
      } else {
        setErrorMsg("Please add a disease here!!");
        setErrorSubmit(true);
      }
    };


    const handleTargets = () => {
      dispalyLatest();
    };
    const dispalyLatest = () => {
      const tokenString = sessionStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");

      axios
        .get(`${APIURL}/api/v1/doctor/primary-disease-list/?patient_id=${id}`, {
          headers: {
            Authorization: "Token " + str,
          },
        })

        .then((res) => {
          if (res.status == 200 && res.data.status=='success') {
            displaysetChronicValue("");
            setdisplaysetChronicValueId(0);
            displaysetStage("");
            displaysetDuration("");

            if (res.data.message.length > 0) {
              const count = res.data.message.length - 1;
              displaysetChronicValue(res.data.message[count].disease);
              setdisplaysetChronicValueId(res.data.message[count].id);
              if (
                res.data.message[count].stage != "" &&
                res.data.message[count].stage != null
              ) {
                displaysetStage(res.data.message[count].stage);
              }

              if (
                res.data.message[count].duration != "" &&
                res.data.message[count].duration != null
              ) {
                displaysetDuration(res.data.message[count].duration);
              }
            }
          } else  if (res.status == 200 && res.data.status=='error') {
            setErrorMsg(res.data.message);
            setErrorSubmit(true);
          }
          else{
            setErrorMsg(res);
            setErrorSubmit(true);
          }
        })
        .catch((err) => {});
      setStageAddShow(false);
      setDurationAddShow(false);
      setDiseaseAddShow(false);
    };

    return (
      <button style={{margin:"auto"}}
        ref={saveButtonRef}
        type="submit"
        className="btn btn-smallest btn-square btn-primary btn-col btn-col "
        // disabled={disableSaveStageAndDuration}
        onClick={() =>{if((singleSelections.length>0||singleSelectionsKeyup != "")&&stage!==""&&duration!==""){ handleUpdateStageAndDuration(text)}
        else{alert.error("Enter all fields")}}}
       
      >
        {" "}
        {text}{" "}
      </button>
    );
  };
  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/doctor/primary-diseases/?search=${searchAssign}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setAssignList(res.data);
        } else {
          setGetError(res.data.message);
        }
      })
      .catch((err) => {
        setGetError("Error in fetching data");
      });
  }, [searchAssign]);

  return (
    <>
      <div className="primary-disease">
        <span className="primary-disease-label">
          <b>Disease Name *: </b>
        </span>
        <br />
        <br />
        <Typeahead
          ref={el => inputRefs.current[0] = el} 
          onKeyDown={(e) => handleKeyDown(e,0)}
          id="basic-typeahead-single"
          labelKey="disease_name"
          onChange={setOnChangeTypeaheadDisease}
          options={assignList}
          selected={singleSelections}
          placeholder="Add Disease Name here.."
          onInputChange={handleKeyPress}
        />
        <br />
        <br />

        <span className="primary-disease-label">
          <b>Stage *: </b>
        </span>
        <input
          ref={el => inputRefs.current[1] = el} 
          onKeyDown={(e) => handleKeyDown(e,1)}
          type="text"
          className="form-control"
          name="stage"
          value={stage}
          onChange={handlesetStage}
        />
        <br />
        <span className="primary-disease-label">
          <b>Duration (yrs) *: </b>
        </span>
        <input
          ref={el => inputRefs.current[2] = el} 
          onKeyDown={(e) => handleKeyDown(e,2)}
          type="number"
          className="form-control"
          name="duration"
          value={duration}
          placeholder="In Years"
          onChange={handleSetDuration}
        />
        <br />
        <div style={{display:"flex"}}>{retunButton()}</div>
      </div>
      {submitMsg !== "" ? (
        <SubmitSuccessPopup1
          show={submitShow}
          onHide={() => {
            setSubmitShow(false);
            setSingleSelections([]);
            setSingleSelections("");
            setSingleSelectionsKeyup("");
          
        
            pr.onHide();
            //refreshPage();
          }}
        />
      ) : (
        ""
      )}

      {errorMsg !== "" ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}
        />
      ) : (
        ""
      )}

      {diabeticTargetShow ? (
        <DiabeticTargetsPopup
          show={diabeticTargetShow}
          onHide={() => setDiabeticTargetShow(false)}
          onCall={() => {
            setDiabeticTargetShow(false);
            setTargetShow(true);
          }}
        />
      ) : (
        ""
      )}

      {targetShow ? (
        <TargetPopup
          show={targetShow}
          onHide={() => {
            setTargetShow(false);
          }}
          id={id}
          diabeticTargets={targetData}
          select={2}
        />
      ) : null}
    </>
  );
}

export default DiseaseAdd;
