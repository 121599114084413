import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

const RejectPopUp = (props) => {
    const [remarkData, setRemarkData] = useState('');

    return (
        <div>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                show={true}
            >
                <Modal.Body>
                    <h4 id="confirm-reject" style={{ color: "red" }}>
                        Do you want to reject the primary enquiry form of {props.selectedPatient.first_name}?
                    </h4>
                    <div className='input-container'>
                        <label style={{ textAlign: 'left' }}>Add Comments<span>*</span></label>
                        <input className='input1'
                            type='text'
                            value={remarkData}
                            onChange={(e) => setRemarkData(e.target.value)} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button style={{ marginRight: '10px' }} variant="secondary" onClick={() => { props.onHide(); }}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                props.handleRejectApproval(remarkData);
                                setRemarkData('');
                            }}
                            disabled={remarkData === '' || remarkData === undefined}
                        >
                            Reject
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default RejectPopUp;
