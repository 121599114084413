import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./addinfo.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./dob copy.css";
import DatePicker from "react-date-picker";
import {APIURL} from "../../../Global";
//import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
//import BackButton from "../common/BackButton";

function AddInfo({hide}) {
  const [doctorDetails,setDoctorDetails]=useState({
    specialization: "",
    id_proof:"",
    experience:"",
  "medical_competence":"",
"education_cred":"",
  registration_year: null,
  council:"",
  "practice_lic":"",
  
})
let formData =new FormData();
const reader =new FileReader();
const [errormsg, setErrormsg] = useState("");
const [errorSubmit, setErrorSubmit] = useState(false);
const [validShow ,setValidShow] =useState(false)
const [error,setError]=useState({
  "medical_competence":"",
 "council":"",
 "practice_lic":"",
 "experience":"",
 

  specialization:'',
  id_proof:'',
  "education_cred":"",
  registration_year:""
 
 
  
 });
  const [fileName,setFileName]=useState("*Upload ID Proof");
  //const [dobValue, setDobValue] = useState();

  const [uploadShow ,setUploadShow] =useState(false)
  const [errorMsg, setErrorMsg] = useState("");
  const [sizeShow ,setSizeShow] =useState(false)
  const [contentShow ,setContentShow] =useState(false)
  const [submitMsg, setSubmitMsg] = useState("");
  const [data, setData] = useState("");
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      
);
 


 const hide1=hide;
  const [errorShow, setErrorShow] = useState(false);
  const[refresh,setRefresh]=useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(()=>{

    const tokenString= sessionStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '')                           
      
     
      axios.get(`${APIURL}/api/v1/doctor/medical-profile-view/?doctor_id=${login_datas.user_id}`, { headers: {
        
        "Authorization" : 'Token '+str  }})
      .then(res=>{
          
        if(res.data.status==="success"){
        
      setData(res.data[0])
       
        }
  
  
      })
      .catch(err=>{
  
  })
  
  
  },[refresh]);

  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
          {errormsg}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  function SubmitPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps">{submitMsg}</h4>
        </Modal.Body>
        <Modal.Footer>
    
            <Button className="secondary" onClick={hide}>OK </Button>
        
        </Modal.Footer>
      </Modal>
    );
  }

  function ErrorPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps" style={{ color: "red" }}>
            {errorMsg}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }





  const handleSubmit = (e) => {
    
   e.preventDefault();
  if(doctorDetails.medical_competence!=="" && doctorDetails.specialization!==""&& doctorDetails.experience!=="" &&doctorDetails.id_proof!=="" && doctorDetails.id_proof!==null &&doctorDetails.registration_year!==null&&doctorDetails.council!==""&&doctorDetails.practice_lic!==""&&doctorDetails.education_cred!=="")
  {

      const tokenStr = sessionStorage.getItem("usertoken");

      if (tokenStr) {
        const v = tokenStr;
        let v2 = v.replace(/["]+/g, "");

   
      
          formData.append('medical_competencies',doctorDetails.medical_competence)
          formData.append('specialization',doctorDetails.specialization)
          formData.append('years_of_experience',doctorDetails.experience)
          formData.append('identity_proof',doctorDetails.id_proof)
        
       
          formData.append('registeration_year',doctorDetails.registration_year)
    
          formData.append('registration_council',doctorDetails.council)
         
          formData.append('official_practice_licenses',doctorDetails.practice_lic)
  
          formData.append('educational_credentials',doctorDetails.education_cred)
   
      
  

        
      
        axios.post(`${APIURL}/api/v1/doctor/medical-profile-view/`,formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + v2,
            },
          })
          .then((res) => {
        
            if (res.data.status === "success") {
              setSubmitMsg(
               "Successfully added"
              );
            

               setModalShow(true);
            } else {
              setErrorMsg(
               "Error in submission"
              );

              setErrorShow(true)
            }
          })
          .catch((err) => {
          
            setErrorMsg("Error in submission" + err)
          });
       
    
        } 
      }
    
    
    else{
      setErrormsg("please fill all fields");
      setErrorSubmit(true);}
  };

 
const SizePopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h5 id="confirm-reject">You have exceeded image size limit 10MB</h5>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}
const LoadPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject" style={{color:"red"}}>Select an Image</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}
const ContentPopUp = (props)=> {
  return(
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <h4 id="confirm-reject">Invalid Image Content</h4>
         
        </Modal.Body>
        <Modal.Footer>
         
           <Button variant="secondary" onClick={props.onHide}>Ok</Button>          
  
        </Modal.Footer>
      </Modal>
    )
}
  const handleChange= e=>{
    const val=e.target.value;
        const key=e.target.name;
      
           setDoctorDetails(currentstate=>({
              ...currentstate,
              [key]: val
            }));
        
  }

  // const handleChangeExperience= e=>{
  //   const val=e.target.value;
  //       const key=e.target.name;
      
  //          setExperience(currentstate=>({
  //             ...currentstate,
  //             [key]: val
  //           }));
        
  // }

 





  const handleFile=e=>{
    
    const file=  e.target.files[0];
 
  if(!file){
    setDoctorDetails(currentstate=>({...currentstate, id_proof:null}))
    setUploadShow(!uploadShow)
    setFileName('Upload ID Proof');
    return false
  }
  if (!file.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/)) {
    setDoctorDetails(currentstate=>({...currentstate, id_proof:null}))
    setValidShow(!validShow)
    setFileName('Upload ID Proof');
    return false;
  }
  if(file.size>10e6){
    setDoctorDetails(currentstate=>({...currentstate, id_proof:null}))
    setSizeShow(!sizeShow)
    setFileName('Upload ID Proof');
    return false
  }
  reader.onload = (evt) => {
    const img = new Image();
    img.onload = () => {
      setDoctorDetails(currentstate=>({...currentstate, id_proof:file}))
      setFileName(file.name);
    };

    img.onerror = () => {
      setDoctorDetails(currentstate=>({...currentstate, id_proof:null}))
      setContentShow(!contentShow)
      setFileName('Upload ID Proof');
   
      return false;
    };
    img.src = evt.target.result;
  };
  reader.readAsDataURL(file);


  
   
  };


  //const objectURL = URL.createObjectURL(file);
  
  
 


  const validate = (data,imgData) => {
    let input = doctorDetails;
    let errors = {};
    let isValid = true;
    setError({});

    

  
    if (!input["medical_competencies"] || input["medical_competencies"] === "") {
      isValid = false;
      errors["medical_competencies"] = "Enter medical_competencies";
    }
    if (!input["specializations"] || input["specializations"] === "") {
      isValid = false;
      errors["specializations"] = "Enter specializations";
    }   if (!input["registration_council"] || input["registration_council"] === "") {
      isValid = false;
      errors["registration_council"] = "Enter registration_council";
    }   if (!input["official_practice_licenses"] || input["official_practice_licenses"] === "") {
      isValid = false;
      errors["official_practice_licenses"] = "Enter official_practice_licenses";
    }   if (!input["educational_credentials"] || input["educational_credentials"] === "") {
      isValid = false;
      errors["educational_credentials"] = "Enter educational_credentials";
    }  

 
 
    // if (data.id_proof !== "undefined") {
    //   var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
    //   if (pattern2.test(data.id_proof)) {
    //     isValid = false;
    //     errors["id_proof"] = "Special Characters are not allowed";
    //   }
    // }
  //   if (data.experience !== null ) {
  //     let exp_yrs= data.experience;
  //   if(isNaN(exp_yrs) || !(parseInt(Number(exp_yrs)) === exp_yrs) || 
  //    isNaN(parseInt(exp_yrs, 10)) || exp_yrs <0 || exp_yrs>90){
  
  //     isValid = false;
     
  //     errors["experience"] = "Enter a valid integer";
  //   }


  // }

//  if (data.registration_year!== null && data.registration_year!== "" && data.registration_year!== undefined) {
//   let regn_yr= data.registration_year
//  if(isNaN(regn_yr) || !(parseInt(Number(regn_yr)) == regn_yr) || regn_yr<1900 || regn_yr> new Date().getFullYear()){
 

//    isValid = false;
//    errors["registration_year"] = "Enter a valid year";
//  }


// }
// if (imgData.id_proof === null || imgData.id_proof==="") {
//   isValid = false;
//   errors["id_proof"] = "*ID Proof Image is mandatory!";

// }

  

 

   

    


    
    // if (typeof input["specializations"] !== "undefined") {
    //   var pattern2 = new RegExp(/[`!@#$%^&*()_+\=\[\]{};':"\\|<>\/.?~]/);
    //   if (pattern2.test(input["specializations"])) {
    //     isValid = false;
    //     errors["specializations"] = "Check the characters used";
    //   }
    // }

 

   

  


    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };


  return (
    <>
    <div className="add-dr-page">
 

      <div className="add-dr-form-holder">

      {/* specialization: "",
    id_proof:"",

  "medical_competence":"",
"education_cred":"",
  registration_year: null,
  council:"",
  "practice_lic":"", */}
    
    
        <form className="add-dr-form" onSubmit={handleSubmit}>
          <h4 className="title-of-page"> Doctor Details</h4>
        
         
            <div className="form-group col-lg-6 item-left-row">
              <label className="">*Medical Competence: </label>
              <textarea rows="6" className="text-area-about" name="medical_competence" placeholder=""  onChange={handleChange}/>
              {error.medical_competence ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.medical_competence}
                </div>
              ) : null}
            </div>
            
            
           

         
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Super Speciality: </label>

              <input
                className="form-control"
                type="text"
                maxLength="150"
                placeholder=""
                name="specialization"
                onChange={handleChange}
              />
              {error.specialization ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.specialization}
                </div>
              ) : null}
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Year of Experience: </label>

              <input
                className="form-control"
                type="number"
                maxLength="50"
                min="0"
                maxDate="90"
                placeholder=""
                name="experience"
                onChange={handleChange}
              />
              {error.experience ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.experience}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Registration Year: </label>

              <input
                className="form-control"
                type="number"
                maxLength="50"
                min="0"
                maxDate="90"
                placeholder=""
                name="registration_year"
                onChange={handleChange}
              />
              {error.registration_year ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.registration_year}
                </div>
              ) : null}
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className=""> *Registration Council: </label>

              <input
                className="form-control"
                type="text"
                maxLength="50"
               
                placeholder=""
                name="council"
                onChange={handleChange}
              />
              {error.council ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.council}
                </div>
              ) : null}
            </div>
           
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Official Practice Licence: </label>

              <input
                className="form-control"
                type="text"
                maxLength="50"
                
                placeholder=""
                name="practice_lic"
                onChange={handleChange}
              />
              {error.practice_lic ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.practice_lic}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className=""> *Educational Credentials: </label>

              <input
                className="form-control"
                type="text"
                maxLength="50"
               
                placeholder=""
                name="education_cred"
                onChange={handleChange}
              />
              {error.education_cred ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.education_cred}
                </div>
              ) : null}
            </div>
           
            <div className="form-group col-lg-6 item-left-row">
              <label className=""> *Identity Proof: </label>

              
              
             
                  <div className="input-group mb-3">
                <div className="custom-file">
                  <input type="file" name="id_proof" className="custom-file-input" id="inputGroupFile02"  onChange={handleFile}/>
                  <label className="custom-file-label" htmlFor="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{fileName} </label>                 
                </div>
                </div>
               
             
            </div>
           
      
    
     
          <div className="form-button">
          
          <button type="submit" className="btn btn-primary btn-col">
            {" "}
            Submit Data
          </button>{" "}
          </div>
        
          <br />
          <br />
          <h6 className="text-muted">All fields marked * are mandatory</h6>
      
        </form>
        <button className="btn btn-primary btn-col" onClick={hide} style={{float:"right"}}>Close</button>
        {submitMsg !== "" ? (
          <SubmitPopUp
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setSubmitMsg("");
            
              setRefresh(!refresh);
           
            }}
          />
        ) : null}

        {errorMsg !== "" ? (
          <ErrorPopUp
            show={errorShow}
            onHide={() => {
              setErrorShow(false);
              setErrorMsg("");
            
            }}
          />
        ) : null}
        {
        uploadShow ? 
        <LoadPopUp
        show={uploadShow}
        onHide={()=>{
          setUploadShow(false)
        }} /> : null
      } 
      {
        contentShow ? 
        <ContentPopUp
        show={contentShow}
        onHide={()=>{
          setContentShow(false)
        }} /> : null
      }
      {
        sizeShow ? 
        <SizePopUp
        show={sizeShow}
        onHide={()=>{
          setSizeShow(false)
        }} /> : null
      } 
      {errormsg !== "" ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}
        />
      ) : (
        ""
      )}
      {errorSubmit ? (
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() =>{setErrorSubmit(false); setRefresh(!refresh);}}
        />
      ) : (
        ""
      )}

    
   </div>
    </div>
    </>
  );
}

export default AddInfo;
