import React from 'react';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import { Document, Page } from 'react-pdf';

const ZoomableImage = ({ src }) => {
  console.log(src)
  if (src && src.toLowerCase().endsWith('.pdf')) {
    // If the source is a PDF, render a PDF viewer
    return (
    
      <div>
      <object data={src}  type="application/pdf" width="95%" height="400px">
       <p>Alternative text - include a link <a href={src}>to the PDF!</a></p>
     </object>
     </div>
   
    );
  } else  if (src && (src.toLowerCase().endsWith('.jpg')|| src.toLowerCase().endsWith('.JPG')||src.toLowerCase().endsWith('.jpeg')|| src.toLowerCase().endsWith('.JPEG')||src.toLowerCase().endsWith('.png')||src.toLowerCase().endsWith('.PNG'))) {
    // If the source is an image, render a zoomable image
    return (
      <div>
        <PinchZoomPan initialScale={1} maxScale={3}>
          <img style={{display:"inline"}} src={src} alt="Image not uploaded" />
        </PinchZoomPan>


       
      </div>
    );
  }
  else{
    return(
      <object data={src}  type="application/pdf" width="95%" height="400px">
      <p>Alternative text - include a link <a href={src}>to the PDF!</a></p>
    </object>
    )
  }
};

export default ZoomableImage;

