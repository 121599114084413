import React, { useState, useEffect, } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import "./pagination.css";
import './userlist.css'
import axios from "axios";
import { APIURL } from '../../Global'

function UserListAllied() {
  const navigate = useNavigate();
  const [patientList, setPatientList] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(10);
  const [hasNext, setHasNext] = useState(true)
  const [page, setPage] = useState(0)
  const [load, setLoad] = useState(false)
  const handleLoad = async (page, per_page) => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */




    axios.get(`${APIURL}/api/v1/doctor/patient-record-search/?per_page=10&page=${page}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        /* this to be changed once API is fixed */

        if (res.data.status === "success") {
          let Data = []
         
          setHasNext(res.data.has_next)
          setPage(res.data.page)
        
          Data = res.data.patients
          .filter(function (o1) {
           
            return !patientList.some(function (o2) {
                return o1.id === o2.id;
            });
        })
        
        setPatientList([...patientList,...Data]);
        setLoad(!load)
        } else {
          // setErrorMsg("error");
        }

       
      })
      .catch(err => {

        // setErrorMsg("error");
      })

  };

  useEffect(() => {
  
        if (hasNext) {
            handleLoad(page + 1, perPage)
        }
    
},[load])



  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSearchTerm(e.target.value);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  let data =JSON.parse(JSON.stringify(patientList)) 
    .sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase())
        return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    })
    .filter((value) => {
      if (searchTerm === "") {
        return value;
      } else if (
        value.name.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return value;
      }
    });
  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current1 = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = [];
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i);
  }
  const totalPages = pageNumbers.length;




  return (<>



    <div className="search-patient-page directory-page">

      <h2 className=" title-of-tasks"> Patient Records Directory </h2><br />

      <div className="search-section">


        <div className="search-div">
          <form style={{ display: "flex" }}>
            <input
              className="form-control search-input"
              type="text"
              placeholder="Enter Name"
              value={searchTerm}
              maxLength="150"
              onChange={handleSearchChange}
            />
            <button className="btn btn-primary btn-col search-btn">

              <i className="fas fa-search"></i>
            </button>
          </form>     </div>
        <div className="container search-results">
          {
            current1.length > 0 ?
              current1.map((value, index) => {
                // 

                return (


                  <div onClick={() => navigate("/patient-record/${value.id}", {
                    state: { id: value.id, mobile: value.mobile_number },
                  })} key={index} className='list-item'>
                    <h5 className="each-list-link" >{(currentPage - 1) * 10 + index + 1}.{value.name}</h5>
                    <div className="details">
                      <p> Id: {value.id} </p>
                      {/* <span> Hospital Id: {value.hospital_patient_id} </span> */}
                      <p> Phone : {value.mobile_number} </p>
                    </div>
                  </div>

                )
              }) : <h6 style={{ color: "red" }}>No Patients Found</h6>
          }

        </div>

      </div>
      <br />
      <br />
      {totalPages > 0 ?
        <div className="pagn" >

          <Pagination
            activePage={currentPage}
            itemsCountPerPage={perPage}
            totalItemsCount={total}
            pageRangeDisplayed={totalPages}
            onChange={handlePageChange}
            disabledClass="disabled-class"
          />
        </div>
        : null}
    </div>
  </>
  )
}


export default UserListAllied;