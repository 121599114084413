import React, {useState} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function WeightAdd(props) {
  
  const alert = useAlert();
    const [weight, setWeight]=useState(props.seteditweight !== "" ? props.seteditweight : "")
    const [height, setHeight]=useState(props.seteditheight !== "" ? props.seteditheight : "")

   

    const handleChange = (e)=> {
        const val = e.target.value
        if(val.length < 6){
            setWeight(e.target.value)
        }
        else {
            return false
        }
    }

    const handleSubmit = () => {

        if(weight < 300) {

            const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               "weight": weight
           }
     
     
       
         axios.put(`${APIURL}/api/v1/patient/user-details/?patient_id=${id}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
               alert.success("Weight added Successfully ")
               props.onSuccess()
         
          
       }
       else{
        alert.error("Error in data submission.Please check again!!")
        
       }
     
         })
      


     if (weight > 1 && weight <= 500 && height > 1 && height <= 280) {
      let bmi = (weight / ((height * height) / 10000)).toFixed(2);
      
        const bmidata =  {
          'patient_id':id,
          'weight': parseFloat(weight).toFixed(1),
          'height' : parseFloat(height).toFixed(1),
          'bmi': parseFloat(bmi).toFixed(1)
        }  
        axios.post(`${APIURL}/api/v1/patient/patient-bmi-calculation/`,bmidata, { headers: {
          "Authorization" : 'Token '+str,
          'Content-Type': 'application/json'  }
        })
        .then(res=>{
          
        })
        .catch(err=>{
          
        })

    }

        }
        else {
          alert.error("Please check weight value")
        }

        
    }

    return (
        <div className='row'>
           <input style={{width:'50%'}} type="number" min='0' max='300' placeholder="Add Weight " value={weight} onChange={handleChange}  className="enquiryinput col-8" />
           <span className='col-1'></span>
      <button disabled={weight<1 || weight=== undefined} onClick={handleSubmit} style={{
        borderRadius: "10px",
        backgroundColor: "rgba(43, 147, 160, 1)",
        textAlign: "center",
        color: "white",

      }} className=' col-2'>Add</button>

    </div>

    )
}

export default WeightAdd;
