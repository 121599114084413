import React, { useState, useRef, useEffect, Fragment } from "react";
import axios from "axios";
import { useAlert } from "react-alert";
import { APIURL } from "../../../Global";
import hospitalbed from "../../../assets/images/icons/hospital-room.png";
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
function ViewRoom(props) {
    const [assignData, setAssignData] = useState([]);
    const [bedUnit,setBedunit]=useState("")
    useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
    
        axios
          .get(`${APIURL}/api/v1/service-provider/assign-room/?patient=${props.id}`, {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
            if (res.status == 200) {
              setAssignData(res.data.data.allotment)
                setBedunit(res.data.data.bedunit_code)
                
            }
          });
    
      }, []);
 return(
    <div>
         <Card
            className="mb-2"
            bg={"light"}
            border="light"
            style={{ height: "auto", top: "-10%" }}
          >
           

            <Card.Body>
              <ListGroup style={{ textAlign: "left" }}
                className="list-group-flush"
              >
                <ListGroupItem style={{textAlign:"center"}}>
                <img
                                  src={hospitalbed}
                                  className=""
                                  width="35px"
                                />&nbsp;&nbsp;<b>{bedUnit} </b>
                                 
                 
                  
                </ListGroupItem>
                <ListGroupItem>
                  <b>Building </b>
                  <span style={{ float: "right" }}>
                    {assignData.building }
                  </span>
                </ListGroupItem>
                <ListGroupItem>
                  <b>Floor </b>
                  <span style={{ float: "right" }}>
                    {assignData.floor}
                  </span>
                </ListGroupItem>
                {assignData.ward&& <ListGroupItem>
                  <b>Ward </b>
                  <span style={{ float: "right" }}>
                    {assignData.ward}
                  </span>
                </ListGroupItem>}
                {assignData.room&& <ListGroupItem>
                  <b>Room</b>
                  <span style={{ float: "right" }}>
                    {assignData.room}
                  </span>
                </ListGroupItem>}
                {assignData.bedunit&&<ListGroupItem>
                  <b>Bed</b>
                  <span style={{ float: "right" }}>
                    {assignData.bedunit}
                  </span>
                </ListGroupItem>}
                
              </ListGroup>
<br/>
              <button  style={{width:"40%"}} onClick={() => {props.onSuccess()}}  className=' ads-button'>Reassign</button>
            </Card.Body>
          </Card>
    </div>
 )
}
export default ViewRoom;