import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../BDO/addbdo.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../BDO/dob.css";
import Select from 'react-select';
import DatePicker from "react-date-picker";
import { APIURL } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import BackButton from "../common/BackButton";
import { useAlert } from 'react-alert' 
function AddBuilding(props) {
  const [visitorDatas, setVisitorDatas] = useState({
    buildname: "",
    buildnumber: "",
    floorcapacity: "",
  
   
  })
  const alert = useAlert()
  const [error, setError] = useState({
   
  });

  //const [dobValue, setDobValue] = useState();
  const [dobValue, setDobValue] = useState();

  const [errorMsg, setErrorMsg] = useState("");

  const [, setFormattedDob] = useState("");
 
  const [submitMsg, setSubmitMsg] = useState("");
  const [validateRawPhoneMsg, setValidateRawPhoneMsg] = useState("");
  const [rawPhone, setRawPhone] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);

  const [errorShow, setErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  //const history = useHistory();


  // const handleBuildNumberBlur = (event) => {
  //   const inputValue = event.target.value;
  
  //   if (/^0[0-9]+$/.test(inputValue) || (inputValue==="0")) {
  //     alert.error("Build number cannot start with zero")
  //   } else {
  //     setError((prevError) => ({
  //       ...prevError,
  //       buildnumber: ""
  //     }));
  //   }
  // };

  
  function ErrorPopUp(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="caps" style={{ color: "red" }}>
            {errorMsg}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }




  const handleSubmit = () => {
    
    
   
      
      const tokenStr = sessionStorage.getItem("usertoken");

      if (tokenStr) {
        const v = tokenStr;
        let v2 = v.replace(/["]+/g, "");

        const dataToSend = {
            build_name: visitorDatas.buildname ,
            build_number:visitorDatas.buildnumber,
            floor_capacity: visitorDatas.floorcapacity,
         

        }
  
        
 
         
        
          
      

        axios
          .post(`${APIURL}/api/v1/service-provider/building/`, dataToSend, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + v2,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              alert.success('Added successfully!')
              props.handle();
              
            } else {
              alert.error(res.data.message ? res.data.message : "Error in submission")
              
            }
          })
          .catch((err) => {
            alert.error('Error in submission')
          });
      }
  
  };




  const handleContinue = async (e) => {
    
    
    e.preventDefault();

    if (validate() ) {
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    setVisitorDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
  };








  const validate = () => {
    let input = visitorDatas;
    let focussed = false;
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["buildname"] || input["buildname"] === "") {
      isValid = false;
      errors["buildname"] = "Enter build name ";
    }

    if (!input["buildnumber"] || input["buildnumber"] === "") {
      isValid = false;
      errors["buildnumber"] = "Enter build number";
    }

    if (!input["floorcapacity"] || input["floorcapacity"] === ""||input["floorcapacity"] <1) {
      isValid = false;
      errors["floorcapacity"] = "Enter floor count";
    }
    if (/^0[0-9]+$/.test(input["buildnumber"]) || (input["buildnumber"]==="0")) {
      isValid = false;
      errors["buildnumber"] = "build number cannot start with zero";
       }





  

 




  
      
  





 
 


 

 

  

 

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };


  return (
    <div className="add-dr-page">
 

      <div className="add-dr-form-holder">

     
        <form className="add-dr-form" onSubmit={handleContinue} style={{width:"80%" , backgroundColor:"rgb(217 214 189)"}}>
          <h4 className="title-of-page"> Add New Building</h4>
          <div className="add-dr-div">
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Building Name: </label>
              <input
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=" Building Name"
                name="buildname"
                onChange={handleChange}
              />
              {error.buildname ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.buildname}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Building Number: </label>

              <input
                className="form-control"
                type="number"
                
                 min="1"
                 max="50"
                placeholder=" Build Number"
                name="buildnumber"
                onChange={handleChange}
                //onBlur={handleBuildNumberBlur}
              />
              {error.buildnumber ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.buildnumber}
                </div>
              ) : null}
            </div>
      
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Floor Count: </label>

              <input
                className="form-control"
                type="number"
                min="1"
                max="50"
                placeholder="Floor Count"
                name="floorcapacity"
                onChange={handleChange}
              />
              {error.floorcapacity ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.floorcapacity}
                </div>
              ) : null}
            </div>

        

            

          

        

            

          
           
          </div>
          <div className="form-button">
          <button type="submit" className="btn btn-primary btn-col">
            {" "}
            Add
          </button>{" "}
          </div>
          <br />
          <br />
          <h6 className="text-muted">All fields marked * are mandatory</h6>
        </form>

        {errorMsg !== "" ? (
          <ErrorPopUp
            show={errorShow}
            onHide={() => {
              setErrorShow(false);
              setErrorMsg("");
            }}
          />
        ) : null}

        {/************************Unused***************
    {(() => {
      switch(submitMsg){
      case "success":
      return   (
          <div>
            <h5 style={{ color: "green" }}> Data submitted sucessfully !!</h5>{" "}

            


            <br />
            <br />
           
          
            
          </div>
        );

        case "error": 
        return (
          <h5 style={{ color: "red" }}>
            
            Error in data submission.Please check again!
          </h5>
        );
        default: 
        return "";
           }
      })()}

    *************************/}

        {validateRawPhoneMsg === "error" ? (
          <h5 style={{ color: "red" }}>Phone number field is mandatory!</h5>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AddBuilding;
