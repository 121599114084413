import React, { useState, useRef, useEffect, Fragment } from "react";
import axios from "axios";
import { useAlert } from "react-alert";
import { APIURL } from "../../../Global";
import hospitalbed from "../../../assets/images/icons/hospital-room.png";
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import SimpleBar from "simplebar-react";
import ZoomableImage from "./ZoomableImage";
import AddDoc from "../../HospSearchPatient/UserListAddDoc";
function ViewDoc(props) {
    const id=props.id;
    const [reports, setReports] = useState([])
    const [perPage1] = useState(10);
    const [hasNext1, setHasNext1] = useState(true)
  const [page1, setPage1] = useState(0)
  const [load1, setLoad1] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState("")
  const [addShow, setAddShow] = useState(false);
    useEffect(() => {

        if (hasNext1) {
          GetReport(page1 + 1, perPage1)
        }
    
      }, [load1])
      useEffect(() => {
        GetReport(1, 10)
    
      }, [id, refresh])
      const onSuccess = () => {

        setAddShow(false)
        GetReport(1, 10)
    
      };
      const AddPopUp = (props) => {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            style={{ minHeight: "450px" }}
          >
    
            <Modal.Body style={{ width: "95%", minHeight: "450px", paddingRight: "5%" }}>
              <h6 style={{ marginLeft: "2%", textTransform: "capitalize" }}>
                <button style={{ backgroundColor: "white", float: "right" }} variant="secondary" onClick={props.onHide}>
                  <i class="fa fa-times fa-lg" style={{ color: "black" }} aria-hidden="true"></i>
                </button></h6>
              <AddDoc id={id} onSuccess={onSuccess} onClose={props.onHide} />
    
    
            </Modal.Body>
    
          </Modal>
        )
      }
    
      const InfoPopup = (props) => {
        return (
          <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
              <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
                <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
              </button>
            </Modal.Header>
            <Modal.Body style={{ paddingRight: "7%", textAlign: "center" }}>
              <ZoomableImage src={info} />
            </Modal.Body>
          </Modal>
        );
      };
    const GetReport = async (page, per_page) => {
        if(id!==""){
            const tokenString = sessionStorage.getItem("usertoken");
        
            let str = tokenString.replace(/["]+/g, "");
            await axios
              .get(
                `${APIURL}/api/v1/doctor/patient-documents-view/?patient_id=${id}&per_page=10&page=${page}`,
                {
                  headers: {
                    Authorization: "Token " + str,
                  },
                }
              )
              .then((res) => {
                if (res.data.status === "success") {
                  let Data = []
        
                  setHasNext1(res.data.has_next)
                  setPage1(res.data.page)
        
                  Data = res.data.results
                    .filter(function (o1) {
        
                      return !reports.some(function (o2) {
                        return o1.id === o2.id;
                      });
                    })
        
                  setReports([...reports, ...Data]);
                  setLoad1(!load1)
                } else {
                  // setErrorMsg("error");
                }
              });
            }
        
          };
          const deleteItem = (id1) => {
            const tokenString = sessionStorage.getItem('usertoken');
        
            let str = tokenString.replace(/["]+/g, '')
        
            axios.put(`${APIURL}/api/v1/doctor/patient-documents-view/?id=${id1}&patient_id=${id} `, { is_deleted: true }, {
              headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Token ' + str
              }
            })
              .then(res => {
        
        
                if (res.data.status === "success") {
        
                  alert.success("Deleted successfully")
                  reports.splice(0, reports.length + 1)
                  setRefresh(!refresh)
        
                } else {
        
        
                }
        
              })
              .catch(err => {
        
        
              })
        
          }
return(
    <div className='doc-right'>
    <div style={{ minHeight: "400px", paddingTop: "1%" }}>
      {id === "" ? <div className='text-center'><svg style={{ marginTop: "120px" }} xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 24 24"><path fill="rgba(158, 158, 158, 1)" d="M21.26 21.21L3.79 3.74L2.38 5.15l2.74 2.74l-.12.12V19c0 1.1.9 2 2 2h10c.35 0 .68-.1.97-.26l1.88 1.88l1.41-1.41zM7 19V9.77L16.23 19H7zm3.84-14H17v9.11l2 2V5c0-1.1-.9-2-2-2h-6.99L7.95 5.06l1.41 1.41L10.84 5z" /></svg>

        <h4 style={{ color: "rgba(158, 158, 158, 1)", marginTop: "2%" }}>Select a profile</h4></div> :
        <SimpleBar style={{ maxHeight: "380px", paddingRight: "2%", paddingLeft: "2%" }}>
          <div >  {reports.length > 0 ? reports.map((item, index) => {
            return (
              <div className='row' style={{ marginBottom: "2%", marginLeft: "5%" }}>
                <div className='col-9 row listofrep ' style={{ padding: "1%" }}>
                  <div className='col-6 text-center' style={{ borderRight: "1.5px solid rgba(0, 0, 0, 1)" }}>
                    <h6 style={{ textTransform: "capitalize" }}><b>{item.doc_type.replace("_", " ")}</b></h6>
                    <span className="">{item.uploaded_at.slice(0, 10)}</span>
                  </div>
                  <div style={{ backgroundColor: "rgba(240, 240, 240, 1)" }} className='col-6'>
                    <h6 style={{ color: item.comment ? "black" : "red" }}>{item.comment ? item.comment : "No Remarks!!"}</h6>
                  </div>
                </div>
                &nbsp; &nbsp; &nbsp;
                <div className='col-2  ' style={{ padding: "1%", display: "flex", height: "45px" }}>
                  <button onClick={() => { setInfo(item.images); setShow(true) }} style={{ backgroundColor: "rgba(36, 86, 147, 1)", color: "white", width: "55px", borderRadius: "10px" }} className='dir-button '>View</button>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <i onClick={() => { deleteItem(item.id) }} style={{ color: "rgba(229, 89, 11, 1)",cursor:"pointer" }} class="fa fa-trash fa-2x" aria-hidden="true"></i>

                </div>



              </div>

            )
          }) : <div className='title-of-tasks' style={{ color: "lightgrey", marginTop: "25%" }}><svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 15 15"><path fill="none" stroke="currentColor" d="m9.5 5.5l-4 4m5-9h-8a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-10l-3-3Zm-3 10a3 3 0 1 1 0-6a3 3 0 0 1 0 6Z" /></svg>
            <br /><span>--No Data--</span>
          </div>}
          </div>
        </SimpleBar>}
    </div>
    {id !== "" && <div className='text-center'>
      <button  onClick={() => { setAddShow(true) }} style={{ width: "50%", backgroundColor: "rgba(9, 188, 255, 1)", height: "40px" }} className='cons-button'>
        <svg style={{ paddingBottom: "1%" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="white"><path fill-rule="evenodd" d="M12 15.75a.75.75 0 0 0 .75-.75V4.027l1.68 1.961a.75.75 0 1 0 1.14-.976l-3-3.5a.75.75 0 0 0-1.14 0l-3 3.5a.75.75 0 1 0 1.14.976l1.68-1.96V15c0 .414.336.75.75.75Z" clip-rule="evenodd" /><path d="M16 9c-.702 0-1.053 0-1.306.169a1 1 0 0 0-.275.275c-.169.253-.169.604-.169 1.306V15a2.25 2.25 0 1 1-4.5 0v-4.25c0-.702 0-1.053-.169-1.306a1 1 0 0 0-.275-.275C9.053 9 8.702 9 8 9c-2.828 0-4.243 0-5.121.879C2 10.757 2 12.17 2 14.999v1c0 2.83 0 4.243.879 5.122C3.757 22 5.172 22 8 22h8c2.828 0 4.243 0 5.121-.879C22 20.242 22 18.828 22 16v-1c0-2.829 0-4.243-.879-5.121C20.243 9 18.828 9 16 9Z" /></g></svg>&nbsp;&nbsp;<span style={{ paddingTop: "1%" }}>Upload Document</span></button>

    </div>}
    {show ? (
      <InfoPopup
        show={show}
        onHide={() => {
          setShow(false);
        }}
      />
    ) : (
      ""
    )}
     {
      addShow ?
        <AddPopUp
          show={addShow}
          onHide={() => setAddShow(false)}

        /> : null
    }
  </div>
)   
 
 
}
export default ViewDoc;