import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { APIURL } from "../../Global";
import moment from 'moment'
import { positions, useAlert } from "react-alert";
import SimpleBar from 'simplebar-react';
import { SOCKETURL } from "../../Global";
import { Modal, Button } from "react-bootstrap";
import CallforAction from "./CallforAction";
function CallforActionPat(props) {
    const webSocket = useRef(null);
    const id=props.id&&props.id
    const name=props.name
    const [actions,setActions]=useState([])
    const [actionshow, setActionShow] = useState(false);
useEffect(() => {
   
    setTimeout(() => {

      handlingWebsocket();
  }, 10000)
  },[])
  const ActionPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
       
        <Modal.Body style={{paddingRight: "4%"}}>
        <h6 style={{ marginTop:"-10px",marginLeft:"-10px",marginRight:"1%",padding:"0.5%",boxShadow: "0 3px 5px -3px rgba(0, 0, 0, 0.25)"}}><b>Call for action</b>
                        <button style={{ backgroundColor: "white",float:"right"}} variant="secondary" onClick={props.onHide}>
                            <i class="fa fa-times" style={{ color: "black" }} aria-hidden="true"></i>
                        </button></h6>
            <CallforAction from="pat" id={id} name={name}/>
         
        </Modal.Body>

      </Modal>
    );
  };
  
  const handlingWebsocket=(props)=>{


    const tokenString = sessionStorage.getItem("usertoken");
    const hospId = sessionStorage.getItem("hospId");
    let str = tokenString.replace(/["]+/g, "");
    webSocket.current = new WebSocket(
     
      `${SOCKETURL}/ws/call_action/action_sp_${hospId}pat_${id}/?token=` +
      str
      
    );
    webSocket.current.onopen = () => {
        console.log("WebSocket connected");
      
      };
  
    webSocket.current.onmessage = (evt) => {
      const message = JSON.parse(evt.data);
      console.log(message.message)
   setActions(message.message.message)
      
    };
   
    webSocket.current.onclose = () => {
      console.log("WebSocket disconnected");
    
    };
  }

return(<div style={{boxShadow:"0 5px 1px -1px rgba(0, 0, 0, 0.1)"}} >
    <div style={{marginLeft:"2%",marginTop:"10%",minHeight:"200px",}}>
    {actions.length>0&&actions.map((item)=>{
        return(
          <div  onClick={() => setActionShow(true)} style={{  width:"100%",backgroundColor:"rgba(255, 251, 237, 1)",borderLeft:"3px solid red",marginLeft:"0%"}} className="callaction_lists row">
         <div className="col-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="red" d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8s8 3.589 8 8s-3.589 8-8 8z"/><path fill="currentColor" d="M11 11h2v6h-2zm0-4h2v2h-2z"/></svg></div>
          <div className="col-10" style={{ }} >
            <h6 style={{fontSize:"13px"}}>{item.cal_for_name.slice(0, 20)}</h6>
            <h6 style={{color:"red",fontSize:"13px"}}>{item.cal_for_reason}</h6>
            
          </div>
          

      </div>
        )
       })}
       {actionshow === true ? (
        <ActionPopup
          show={actionshow}
          onHide={() => {
            setActionShow(false);
          }}
        />
      ) : null}
      </div>
      <div >
      <svg  onClick={() => setActionShow(true)} style={{float:"right",marginRight:"1%",marginTop:"-30px"}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill={actions.length>0?"red":"#1e656e" }d="M21 19v1H3v-1l2-2v-6c0-3.1 2.03-5.83 5-6.71V4a2 2 0 0 1 2-2a2 2 0 0 1 2 2v.29c2.97.88 5 3.61 5 6.71v6l2 2m-7 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2"/></svg>
      </div>
       </div>
)}
export default  CallforActionPat