import React, { useState, useEffect, useRef } from 'react'
import './treat.css'
import { useAlert } from "react-alert";
import axios from "axios";
import { APIURL } from "../../../Global";
function ItemList(props) {
  const alert = useAlert();
  const [name, setName] = useState("")
  const [qty, setQty] = useState(null)
  const [cal, setCal] = useState(null)
  const [consId, setConsId] = useState("")
  const [custom, setCustom] = useState(false)
  const [custom1, setCustom1] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const [consumables,setConsumables]=useState([])
  const [display, setDisplay] = useState(false);
  const [refresh,setRefresh]=useState(false)
  useEffect(() => {

if(searchTerm!==""){
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(
        `${APIURL}/api/v1/doctor/search-consum/?search=${searchTerm}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        }
      )
      .then((res) => {

        if (res.data.status === "success") {
        setConsumables(res.data.consumables)
        setDisplay(true)
        }

      })
   
    }

  }, [refresh]);
  useEffect(() => {

    setQty(null)
    setCal(null)
    setCustom(false)

    setCustom1(false)

    setName("")

  },[props.type])

  const handleAdd = (e) => {
    if (name === "") {
      if(props.type==="consumables"){
        alert.error("add item from dropdown")
      }
      else{
      alert.error("add item")
      }
    }

    else if((props.type === "liquid"&&qty === undefined)||(props.type === "liquid"&&qty === null)||(props.type === "liquid"&&qty <0.1)){
      alert.error("add Quantity")
    }
    else if ((props.type === "consumables"&&qty === undefined)||(props.type === "consumables"&&qty === null)||(props.type === "consumables"&&qty <1)){
      alert.error("add Quantity")
    }
    else if ((props.type === "food"&&cal === undefined&&qty === undefined)||(props.type === "food"&&cal === undefined&&qty === null)||(props.type === "food"&&cal === null&&qty === null)||(props.type === "food"&&cal === null&&qty ===undefined)||(props.type === "food"&&cal <0.1&&qty<0.1)) {
     
        alert.error("add either Calorie or quantity")
    }
      else{
        props.onSuccess({id:"item"+props.n, name: name, qty: qty===null?0:qty, cal: cal===null?0:cal ,consId:consId&&consId});
        setName('');
        setQty(0);
        setCal(0);
        setDisplay(false)
        setSearchTerm("")
        setCustom(false)

        setCustom1(false)
      }
  }
  const handleSearchChange = (e) => {
    e.preventDefault();
    if(e.target.value===""){
     
      setDisplay(false)
    }
  
    setSearchTerm(e.target.value)
    setRefresh(!refresh)
    
  }
  const handleChange = (e) => {

    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    if (key === "name") {
      setName(val)
    }
    else if (key === "qty") {
      setQty(val)
      setCustom(false)
    }
    else if (key === "cal") {
      setCal(val)
      setCustom1(false)
    }
    // else if (key === "no") {
    //   setNo(val)
    // }
  };
  return (
    props.type !== "medicine" &&  props.type !== "others" && <div className='treat-div' style={{ padding: "2%", width: "100%", borderRadius: " 10px",height:"fit-content" ,backgroundColor:"rgba(222, 214, 138, 1)"}}>
      <div className=" " style={{ height: "38px", marginTop: "10px", border: "1px solid #948686", backgroundColor: "white", borderRadius: "10px" }}>
        <div style={{ display: "flex", marginTop: "0.8%", paddingRight: "1%" }}>
          <span>&nbsp;&nbsp;&nbsp;</span>

          <span>&nbsp;</span>

          <input
            name="name"
            type="text"
            className=""
            value={props.type==="consumables"?searchTerm:name}
            placeholder={props.type}
            onChange={props.type==="consumables"?handleSearchChange:handleChange}
            style={{ width: "80%", marginTop: "1.5%", paddingLeft: "5px", border: "none", fontSize: "15px", color: "black", textTransform: "capitalize" }}
          />

        </div>
      </div >
      {props.type==="consumables"&&display ? (
              <div className="suggest-display adjust-dimensions">
                {consumables.length > 0 ? (
                  consumables.map((item) => {
                    return (
                      <p
                       onClick={(e) => {setDisplay(false);setName(item.consumable_name);setSearchTerm(item.consumable_name);setConsId(item.id);}}
                        className=" text-secondary"
                        style={{padding:"2%"}}
                      >

                     
                          <>{item.consumable_name}<span>&nbsp;&nbsp;</span>  </>
                         
                        
                      </p>
                    );
                  })
                ) : (
                  <h6 className="text-muted" style={{ padding: 10 }}>
                    No Item found !!
                  </h6>
                )}
              </div>
            ) : null}
      {props.type === "food" && <> <h6 style={{ marginTop: "2%", marginLeft: "2%" }}>Calorie per serve</h6>
        <div className='row' style={{ padding: "2%", marginLeft: "0.5%" }}>
          <div style={{ border: cal === 200 && "1px solid black",cursor:"pointer" }} onClick={() => { setCustom(false);setCal(200) }} className='col-2 qty-div'>200cal</div>
          <div style={{ border: cal === 250 && "1px solid black",cursor:"pointer" }} onClick={() => {setCustom(false); setCal(250) }} className='col-2 qty-div'>250cal</div>
          <div style={{ border: cal === 300 && "1px solid black",cursor:"pointer" }} onClick={() => { setCustom(false);setCal(300) }} className='col-2 qty-div'>300cal</div>
          <div style={{ border: cal === 350&& "1px solid black",cursor:"pointer" }} onClick={() => { setCustom(false);setCal(350) }} className='col-2 qty-div'>350cal</div>
          {custom ? <input

            type="number"
            className="col-2 qty-div"
        
            placeholder='in cal'
            value={cal}
            name="cal"
            onChange={handleChange}
          /> : <div style={{cursor:"pointer"}} onClick={() => {setCal(null); setCustom(true) }} className='col-2 qty-div'>Custom</div>}
        </div></>}
      <h6 style={{ marginTop: "2%", marginLeft: "2%" }}>{props.type==="consumables"?"Quantity":"Quantity per serve"}</h6>
      <div className='row' style={{ padding: "2%", marginLeft: "0.5%" }}>
        {props.type==="consumables"?
        <div style={{ border: qty === 1&& "1px solid black" ,cursor:"pointer"}} onClick={() => {setCustom1(false);setQty(1) }} className='col-2 qty-div'>1</div>:
        <div style={{ border: qty === 50&& "1px solid black",cursor:"pointer" }} onClick={() => {setCustom1(false);setQty(50) }} className='col-2 qty-div'>{props.type === "food" ? "50gm" :"50ml"}</div>}
        {props.type==="consumables"?
        <div style={{ border: qty === 2&& "1px solid black" ,cursor:"pointer"}} onClick={() => {setCustom1(false);setQty(2) }} className='col-2 qty-div'>2</div>:
        <div style={{ border: qty === 100 && "1px solid black" ,cursor:"pointer"}} onClick={() => {setCustom1(false); setQty(100) }} className='col-2 qty-div'>{props.type === "food" ? "100gm" : "100ml"}</div>}
        {props.type==="consumables"?
        <div style={{ border: qty === 3&& "1px solid black",cursor:"pointer" }} onClick={() => {setCustom1(false);setQty(3) }} className='col-2 qty-div'>3</div>:
        <div style={{ border: qty === 150 && "1px solid black",cursor:"pointer" }} onClick={() => {setCustom1(false);setQty(150) }} className='col-2 qty-div'>{props.type === "food" ? "150gm" : "150ml"}</div>}
        {props.type==="consumables"?
        <div style={{ border: qty === 4&& "1px solid black",cursor:"pointer" }} onClick={() => {setCustom1(false);setQty(4) }} className='col-2 qty-div'>4</div>:
        <div style={{ border: qty === 300 && "1px solid black",cursor:"pointer" }} onClick={() => {setCustom1(false);setQty(300) }} className='col-2 qty-div'>{props.type === "food" ? "300gm" :"300ml"}</div>}
        {custom1 ? <input

          type="number"
          className="col-2 qty-div"
          style={{}}
          placeholder={props.type === "food" ? 'in gm' :props.type === "liquid" ? 'in ml':"in Nos"}
          value={qty}
          name="qty"
          onChange={handleChange}
        /> : <div style={{cursor:"pointer"}}onClick={() => {setQty(null); setCustom1(true) }} className='col-2 qty-div'>Custom</div>}
      </div>
     
      <div className='row' style={{ padding: "2%", marginLeft: "0.5%" }}>
        
        <button onClick={() => { handleAdd() }} style={{ color: "green", marginLeft: "82%", width: "60px" }} className='qty-div'>Add</button>
      </div>

    </div>
  )
}
export default ItemList
