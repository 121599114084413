import React, { useEffect, useContext } from "react";
import Swal from "sweetalert2";

const SuccessModal = ({ message, handleClose }) => {
    const handleConfirm = () => {
        const parts = message.split(" "); // Split the message to extract the enquiry number
        const enquiryNumber = parts[parts.length - 1]; // Assuming the last part is the enquiry number
        const highlightedMessage = message.replace(enquiryNumber, `<strong>${enquiryNumber}</strong>`); // Make the enquiry number bold

        Swal.fire({
            html: highlightedMessage, // Use 'html' instead of 'text' to allow HTML rendering
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#54a129",
            backdrop: "static",
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                handleClose();
            }
        });
    };

    useEffect(() => {
        handleConfirm();
    }, []);

    return null;
};

export default SuccessModal;
