import { useState, useEffect, useRef } from "react"
import axios from "axios";
import { APIURL } from "../../../Global";
import { Spinner } from 'react-bootstrap';
import { ClearButton, Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { ToggleButton } from "react-bootstrap";
import { ToggleButtonGroup } from "react-bootstrap";
function AddSubservice({ onSuccess, id1, type, sname ,data}) {
    const [unified_code, setUnicode] = useState("")
    const [color, setColor] = useState("")
    const [volume, setVolume] = useState("")
    const [size, setSize] = useState("")
    const [weight, setWeight] = useState("")
    const [sub_service, setServiceName] = useState("")
    const [serviceList, SetServiceList] = useState([]);
    const [error, setError] = useState("")
    const [error1, setError1] = useState("")
    const [nameError, setNameError] = useState("")
    const [dataList, setDataList] = useState([])
    const [service_category, setCategory] = useState(type === "consumables" ? "master_medicine" : "master_labtest")
    const [id, setId] = useState("")
    const main_service=id1
   
    let arr = [];

    let placeholder = "Please chooce a Category"
    const SubjectTypeahead = useRef(null)

    const addService = (e) => {
     


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */

        const dataToSend = {
            subservices: serviceList

        }

        axios
            .post(`${APIURL}/api/v1/staff/sub-service-list/`, dataToSend, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {

                    onSuccess();

                } else {
                    setError(res.data.message)
                }
            })
    }

    const handleRemoveItem = idx => {
        // assigning the list to temp variable
        const temp = [...serviceList];

        // removing the element using splice
        temp.splice(idx, 1);

        // updating the list
        SetServiceList(temp);
    }
    const handleCategory = (val) => {
        setCategory(val);
    };

    let url = ""
    let name = ""
    if (service_category === "master_medicine") {
        name = "med_name"
        placeholder = "Search a Medicine"
        url = "/api/v1/staff/master-medicines/?is_approved=true"
    }
    else if (service_category === "master_labtest") {
        name = "test_name"
        placeholder = "Search a Test"
        url = "/api/v1/staff/master-labtests/"
    }
    else if (service_category === "master_procedure") {
        name = "procedure_name"
        placeholder = "Search a Procedure"
        url = "/api/v1/staff/master-procedure/"
    }
    else if (service_category === "master_scan") {
        name = "scan_name"
        placeholder = "Search a Scan"
        url = "/api/v1/staff/master-scans/"
    }
    else if (service_category === "master_consumable") {
        name = "consumable_name"
        placeholder = "Search a Consumable Service"
        url = "/api/v1/staff/master-consumables/"
    }
    else if (service_category === "misc") {
        name = "misc_name"
        placeholder = "Search a miscellaneous Service"
        url="/api/v1/staff/master-miscellaneous/"
    }
    else {
        placeholder = "Please Chooce a Category"

    }

    const isValid = dataList.filter((option) => !!option[name]).length === dataList.length;

    const options = isValid ? dataList && dataList.map((option) => {
        if (service_category === "master_consumable"){
            return {
                label: option[name]  ,
                unified_code: option["unified_code"],
                id: option["id"],
                categ: option["service_category"],
                color:option["color"],
                size:option["size"],
                weight:option["weight"],
                volume:option["volume"],

            }
        }
        else{
        return {
            label: option[name],
            unified_code: option["unified_code"],
            id: option["id"],
            categ: option["service_category"]
        }
    }

    }) : [];

    return (<>
    <h4 className="title-of-tasks">Add Subservice</h4><br/>
        <div className="" style={{backgroundColor:"#a9c08f21",marginRight:"4%"}} >
            
            
            <div style={{ display: "flex" ,}}>
                <div className="col-md-5 " style={{padding:"5%"}} >
                    <h6 className="sub-headings"> Service Category </h6>
                    {type === "consumables" ?
                        <ToggleButtonGroup

                            type="radio"
                            style={{ display: "block", textAlign:'left' }}
                            name="options"
                            defaultValue={service_category}
                            onChange={handleCategory}>
                            <input
                                id="tbg-radio-1"
                                value={"master_medicine"}
                                className="form-check-input"
                            />
                            Master Medicine

                            <br />
                            <input
                                id="tbg-radio-2"
                                value={"master_consumable"}
                                className="form-check-input"
                            />
                            Master Consumable
                        </ToggleButtonGroup>


                        : <ToggleButtonGroup

                            type="radio"
                            name="options"
                            style={{ display: "block", textAlign:'left'  }}
                            defaultValue={service_category}
                            onChange={handleCategory}>
                            <input
                                id="tbg-radio-1"
                                value={"master_labtest"}
                                className="form-check-input"
                            />
                            master_labtest

                            <br /> <input
                                id="tbg-radio-2"
                                value={"master_scan"}
                                className="form-check-input"
                            />
                            master_scan


                            <br /> <input
                                id="tbg-radio-3"
                                value={"master_procedure"}
                                className="form-check-input"
                            />
                            master_procedure

                            <br /> <input
                                id="tbg-radio-4"
                                value={"misc"}
                                className="form-check-input"
                            />
                            Miscellaneous
                        </ToggleButtonGroup>}<br />

                    <div style={{ display: "flex" }}>

                        <Typeahead
                            ref={SubjectTypeahead}
                            placeholder={placeholder}

                            onFocus={() => {
                                setNameError("")
                                const tokenString = sessionStorage.getItem("usertoken");

                                let v2 = tokenString.replace(
                                    /["]+/g,
                                    ""
                                ); /* REGEX To remove double quotes from tokenstring */
                                
                                if (!url) {
                                    return;
                                }
                                axios
                                    .get(`${APIURL}${url}`, {
                                        headers: { Authorization: "Token " + v2 },
                                    })
                                    .then((res) => {
                                        //console.log(res);
                                        if (res.status === 200) {

                                            const datas = res.data;

                                            setDataList(datas);
                                            //console.log(featureList)
                                        }
                                    })
                            }}

                            options={options}
                            onChange={(data) => {
                                setError1("")
                              
                                if (data.length) {
                                    if (service_category === "master_consumable"){ 
                                    setColor(data[0].color)
                                    setVolume(data[0].volume)
                                    setSize(data[0].size)
                                    setWeight(data[0].weight)
                                    setServiceName(data[0].label);
                                    setUnicode(data[0].unified_code);
                                    setId(data[0].id)
                                    }
                                    else{
                                        setServiceName(data[0].label);
                                        setUnicode(data[0].unified_code);
                                        setId(data[0].id)  
                                    }
                                    // setSerCategory(data[0].service_category)
                                }
                            }
                            }

                        >
                            {({ onClear, selected }) => (
                                <div className="rbt-aux">
                                    {!!selected.length && <ClearButton onClick={onClear} />}
                                    {!selected.length && <Spinner animation="grow" size="sm" />}
                                </div>
                            )}
                        </Typeahead>
                     
                        <button onClick={() =>{
                            let filt=  data.filter((item) => {
                                return item.unified_code === unified_code;
                              })
                              if(filt.length===0){
                               
                            if(service_category === "master_consumable"){
                                if(sub_service){
                                    SetServiceList(prevArray => [...prevArray, { sub_service, unified_code, id, service_category, main_service,color,volume,size, weight }]) 
                                }
                            }
                            else{
                                if(sub_service){
                                    SetServiceList(prevArray => [...prevArray, { sub_service, unified_code, id, service_category, main_service }])
                                }
                            }
                        }
                        else{
                            setError1("Already added")
                        }
                        }}
                            className="btn btn-primary btn-col search-btn">
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </button>
                        
                    </div>
                    {error1!==""?<div className="error-validation-msg">
                                    {error1}
                                </div>:""}
                    {nameError ? <div className="error-validation-msg">{nameError}</div>:null}
                </div>
                <div className="col-md-6 banner1 " style={{marginLeft:"5%",marginTop:"5%"}} >
                <div className="">
                <h5 className="title-of-tasks">{sname}</h5></div>
                    <div className="formField">
                        {serviceList.filter(value => Object.keys(value).length > 0).map((list, idx) => {
                            const value = list.sub_service;
                            return <>
                                <div key={idx} className='list-item ' style={{ padding: "5px", marginBottom: "5px", marginTop: "5px" }}>
                                    <label style={{ color: "#395A75 " }} className=''>{list.sub_service}</label><span>&nbsp;&nbsp;</span> {list.color!==""&&list.color!==null&&list.color!=="null"&&list.color?<><i style={{color:list.color, border:"1px solid black",borderRadius:"50%"}} class="fa fa-circle" aria-hidden="true"></i><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                    {list.size!==""&&list.size!==null&&list.size!=="null"&&list.size?<><span style={{border:"1px solid black"}}  aria-hidden="true">{list.size}</span><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                    {list.weight!==""&&list.weight!==null&&list.weight!=="null"&&list.weight!=="0"&&list.weight?<><span style={{border:"1px solid black"}}  aria-hidden="true">{list.weight}Kg</span><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                    {list.volume!==""&&list.volume!==null&&list.volume!=="null"&&list.volume!=="0"&&list.volume?<><span style={{border:"1px solid black"}}  aria-hidden="true">{list.volume}L</span><span>&nbsp;&nbsp;</span><span>&nbsp;&nbsp;</span></>:""}
                                    <i
                                        class="fas fa-trash-alt"
                                        onClick={() => handleRemoveItem(idx)}
                                        style={{ float: "right", color: "red" }}
                                    ></i>
                                </div>
                               
                            </>

                        })}<br />
                          {serviceList?.length !== 0 && <div className="" style={{paddingBottom:"2%",paddingTop:"2%",float:"right"}}>
                            <button   onClick={() => addService()} className="btn btn-primary btn-col">Save</button>
                            {error ? (
                                <div className="error-validation-msg">
                                    {error}
                                </div>
                            ) : null}

                        </div>}
                       


                    </div>
                   
                </div>
            </div><br/>
           
        </div></>

    )
}
export default AddSubservice