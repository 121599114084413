import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from 'react-bootstrap';
import { useAlert } from "react-alert";
import AvailableSlots from './AvailableSlots';
function DoctorAppointments() {
    const [followup, setFollowUp] = useState({})
    const [show, setShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [id,setId]=useState()
    const [appId,setAppId]=useState()
    const [docid,setDocId]=useState()
    const [date,setDate]=useState("")
    const [refresh,setRefresh]=useState(false)
    const [login_datas, setLogin_datas] = useState(
        JSON.parse(sessionStorage.getItem("logindatas"))
            ? JSON.parse(sessionStorage.getItem("logindatas"))
            : null
    );

    const onSuccess = () => {
        setEditShow(false)
        setRefresh(!refresh)
      
    
      }
    const alert = useAlert();
    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );

        axios.get(`${APIURL}/api/v1/patient/appointments/`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    setFollowUp(res.data.appointments)
                }

            })
            .catch((err) => {

            })

    }, [refresh])

    const handleEdit = (appid,bookId,date,doc) => {
        setId(bookId)
        setAppId(appid)
        setDate(date)
        setDocId(doc)
        setEditShow(true)
        
    }

    const handleCancel = (bookId) => {

        const tokenString = sessionStorage.getItem("usertoken");
  
        let str = tokenString.replace(/["]+/g, "");
  
        const data = {
          patient_id: login_datas.user_id,
          booking_id: bookId
        }
      
        axios
          .put(`${APIURL}/api/v1/patient/appointments/cancel/`, data , {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
      
            if (res.data.status === "success") {
             alert.success("Cancelled successfully")
             setRefresh(!refresh)
            }
            else{
                
            }
          })
          .catch((err) => {
         
          });
  
      }
    const InfoPopup = (props) => {
        return (
          <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
          >
             <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}>
               
              <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
                <i class="fa fa-times" style={{ color: "#6d6f70" }} aria-hidden="true"></i>
              </button>
            </Modal.Header>
            <Modal.Body style={{marginLeft:"-2.5px"}}>
            {followup.length > 0 && followup.map((item, index) => {
                return (
                    <div className="booking-details-div row">
                    <div className='col-2' style={{paddingTop:"3%"}}>
                        <div className='first-letter '>{item.doctor_name.slice(0, 3) === "Dr." ? item.doctor_name.slice(4, 5).toUpperCase() : item.doctor_name.slice(0, 1).toUpperCase()}
                        </div>
                    </div>
                    <div className='col-10'>
                            <span className='content-appointment '>{item.doctor_name}</span> 
                            <i  onClick={() =>{handleCancel(item.id); }} style={{ fontSize:"medium",marginLeft:"3%",float:"right", marginTop:"3%", color:"#6d6f70",cursor:"pointer"}} class="fa fa-times-circle-o" aria-hidden="true"></i>
                            <i  onClick={() =>{handleEdit(item.id,item.slot_id,item.date, item.doctor_id); }} style={{fontSize:"medium",float:"right", marginTop:"3%", color:"#6d6f70",cursor:"pointer"}} class="fas fa-pencil-alt-square-o" aria-hidden="true"></i><br/> 
                            <span className='content-appointment ' style={{ fontWeight: "normal", fontSize: "small" }}>{item.specialization}</span>
                            <br />
                            {item.is_online?<span className='doc-details'><i style={{color:"#007E85", fontSize:"small"}} class="fa fa-video-camera" aria-hidden="true"></i>&nbsp;<b>Video Consultation</b></span>:
                                   <span className='doc-details'><i style={{color:"#007E85", fontSize:"small"}} class="fa fa-user-md" aria-hidden="true"></i>&nbsp;<b> Hospital Visit</b></span>}{" "}<span className='content-appointment ' style={{ fontWeight: "600", fontSize: "x-small" }}>Token-{item.token}</span><br/>
                                   <span className='content-appointment ' style={{ fontWeight: "normal", fontSize: "x-small" }}>{item.date}</span> <span className='content-appointment' style={{ fontWeight: "normal", fontSize: "x-small" }}>|{" "}{item.slot.slice(0,5)}</span>

                        </div>
                     
                    </div>)
            })
            }
            </Modal.Body>
          </Modal>
        );
      };
      const EditPopup = (props) => {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
          >
             <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}>
             <h6>Reschedule</h6>
              <button style={{ backgroundColor: "white", marginLeft: "75%" }} variant="secondary" onClick={props.onHide}>
                <i class="fa fa-times" style={{ color: "#6d6f70" }} aria-hidden="true"></i>
              </button>
            </Modal.Header>
            <Modal.Body style={{marginLeft:"-2.5px"}}>
            <div><AvailableSlots id={id} date={date} docid1={docid} appId={appId} onSuccess={onSuccess}/></div>
            </Modal.Body>
          </Modal>
        );
      };
    return (
        <div className='container'>

            <div><span className="booking-heading">Bookings</span>
                <button onClick={() => setShow(true)} className="booking-button">View All</button></div>

            {followup.length > 0 && followup.map((item, index) => {
                return (
                    index < 1 && <div className="booking-details-div row">
                        <div className='col-2' style={{paddingTop:"3%"}}>
                            <div className='first-letter '>{item.doctor_name.slice(0, 3) === "Dr." ? item.doctor_name.slice(4, 5).toUpperCase() : item.doctor_name.slice(0, 1).toUpperCase()}
                            </div>
                        </div>
                        <div className='col-10'>
                            <span className='content-appointment '>{item.doctor_name}</span>
                             <i  onClick={() =>{handleCancel(item.id); }} style={{ fontSize:"medium",float:"right",marginLeft:"3%", marginTop:"3%", color:"#6d6f70",cursor:"pointer"}} class="fa fa-times-circle-o" aria-hidden="true"></i>
                            <i  onClick={() =>{handleEdit(item.id,item.slot_id,item.date, item.doctor_id); }} style={{fontSize:"medium",float:"right", marginTop:"3%", color:"#6d6f70",cursor:"pointer"}} class="fas fa-pencil-alt-square-o" aria-hidden="true"></i><br/> 
                            <span className='content-appointment ' style={{ fontWeight: "normal", fontSize: "small" }}>{item.specialization}</span>
                            <br />
                            {item.is_online?<span className='doc-details'><i style={{color:"#007E85", fontSize:"small"}} class="fa fa-video-camera" aria-hidden="true"></i>&nbsp;<b>Video Consultation</b></span>:
                                   <span className='doc-details'><i style={{color:"#007E85", fontSize:"small"}} class="fa fa-user-md" aria-hidden="true"></i>&nbsp;<b> Hospital Visit</b></span>}{" "}<span className='content-appointment ' style={{ fontWeight: "600", fontSize: "x-small" }}>Token-{item.token}</span><br/>
                                   <span className='content-appointment ' style={{ fontWeight: "normal", fontSize: "x-small" }}>{item.date}</span> <span className='content-appointment' style={{ fontWeight: "normal", fontSize: "x-small" }}>|{" "}{item.slot}</span>

                        </div>
                     
                    </div>)
            })
            }
 {show ? (
        <InfoPopup
          show={show}
          onHide={() => {
            setShow(false);
          }}
        />
      ) : (
        ""
      )}
      {editShow ? (
        <EditPopup
          show={editShow}
          onHide={() => {
            setEditShow(false);
          }}
        />
      ) : (
        ""
      )}
        </div>
    );
}
export default DoctorAppointments;
