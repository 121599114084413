import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';

const OpBookedPopup = ({ close, content }) => {
    console.log(content)
    const formatDateString = (dateString) => {
        const isYYYYMMDD = /^\d{4}[/-]\d{2}[/-]\d{2}/.test(dateString);

        const date = isYYYYMMDD
            ? moment(dateString, ['YYYY/MM/DD', 'YYYY-MM-DD', 'DD/MM/YYYY', 'DD-MM-YYYY'])
            : moment(dateString, ['DD/MM/YYYY', 'DD-MM-YYYY']);

        return date.format('DD, MMMM - ddd');
    };


    const getName = () => {
        return content.doctor_name || content.machine_id || content.physio_name || '';
    };

    return (
        <div>
            <Modal
                show={true} centered
            >
                <Modal.Body style={{ textAlign: "center" }}>
                    <div><label style={{ color: "#008000", fontSize: "22px", fontWeight: 500 }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="27"
                            viewBox="0 0 28 27"
                            fill="#109A04"
                        >
                            <path
                                d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z"
                                fill="#109A04"
                            />
                        </svg>
                        Booking Confirmed!
                    </label></div>
                    <div style={{ display: "flex", flexDirection: "column", color: "#000" }}>
                        {content.token ? <label>Token:  {content.token}</label> : ''}
                        <div style={{ display: "flex", textAlign: "center", margin: "auto", alignItems: "center" }}>
                            <label style={{ fontSize: "20px", fontWeight: 400 }}>{getName()}
                            </label>&nbsp;&nbsp;
                        </div>
                        <div style={{ display: "flex", textAlign: "center", margin: "auto" }}>
                            <label>{formatDateString(content.date)}</label>,&nbsp;
                            <label>{content.slot ? content.slot : content.time}</label>
                        </div>
                        {content.arrival_time ? <label>Arrival Time:{content.arrival_time}</label>
                            : <label>Arrive 30 minutes before the scheduled time.</label>}
                    </div>
                    <div>
                        <button style={{ margin: "auto", borderRadius: "25px", color: "#fff", backgroundColor: '#2B69A3', width: "100px" }} onClick={() => close()}> Close</button>
                    </div>

                </Modal.Body>
            </Modal>
        </div >
    )
}
export default OpBookedPopup