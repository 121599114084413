import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { APIURL } from "../../Global";
import VerifyOTP from "./VerifyOTP";
import axios from 'axios';
function AdminResetPwd() {
    const [login_datas, setLogin_datas] = useState(
        JSON.parse(sessionStorage.getItem("logindatas"))
            ? JSON.parse(sessionStorage.getItem("logindatas"))
            : null
    );
    const [verifyOTP, setVerifyOTP] = useState(false)
    const [passwordType, setPasswordType] = useState("password");
    const [passwordType1, setPasswordType1] = useState("password");
    const [modalShow, setModalShow] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [submitMsg, setSubmitMsg] = useState("");
    const [display, setDisplay] = useState(false)
    const [error, setError] = useState({ password: '', confirm_password: "", });
    const handlePasswordChange = (e) => {


        setPassword(e.target.value);
    }
    const handleConfirmPassword = (e) => {

        setConfirmPassword(e.target.value);
    }
    const onSuccess = () => {
        setVerifyOTP(false);
        callSubmit();

    };
    const onCancel = () => {

        setVerifyOTP(false);

    };
    function SubmitPopUp(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4> Password updated successfully!!</h4>

                </Modal.Body>
                <Modal.Footer>



                    <Button onClick={props.onHide} className='btn btn-primary btn-col' >Ok</Button>

                </Modal.Footer>
            </Modal>
        );
    }
    function OtpPopUp(props) {
        return (

            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body >
                    <VerifyOTP onSuccess={onSuccess} onCancel={onCancel} />
                </Modal.Body>

            </Modal>
        );
    }

    const validate = async () => {

        let errors = {};
        let isValid = true;
        setError({});

        if (password !== "undefined") {
            let pattern = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$/);
            if (!pattern.test(password)) {
                isValid = false;
                errors["password"] = "Password must contain at least 1 uppercase, 1 lower case, 1 number, 1 special character(@$!#^%*?&) and don't use space";
            }
        }
        if (!password || password === "" || password === null) {
            isValid = false;
            errors["password"] = "Enter password";
        }

        if (!confirmPassword || confirmPassword === "" || confirmPassword === null || typeof confirmPassword === "undefined") {
            isValid = false;
            errors["confirm_password"] = "Enter confirm password";
        }

        if (password && typeof password !== "undefined") {
            if (password.length > 0 && password.length < 8) {
                isValid = false;
                errors["password"] = "Must contain atleast 8 characters";
            }
        }

        if (typeof password !== "undefined" && password !== "" && password !== null && typeof confirmPassword !== "undefined" && confirmPassword !== "" && confirmPassword !== null) {
            if (password !== confirmPassword) {
                isValid = false;
                errors["confirm_password"] = "Passwords don't match";
            }
        }

        await setError(currentstate => ({
            ...currentstate,
            ...errors
        }));


        return isValid;
    }
    const togglePasswordVisiblity = () => {

        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const togglePasswordVisiblity1 = () => {

        if (passwordType1 === "password") {
            setPasswordType1("text")
            return;
        }
        setPasswordType1("password")
    }
    const handleSubmit = async () => {
      
        if (await validate()) {
            setVerifyOTP(true)


        }
    }
    const callSubmit = () => {

        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );


        const data = {
            password: password
        }

        axios
            .put(`${APIURL}/api/v1/account/admin-reset-pwd/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Token " + v2
                },
            })
            .then((res) => {
                if (res.data.status === "success") {

                    setModalShow(true);

                } else {

                    setSubmitMsg("error");
                }
            })
            .catch((err) => {

                setSubmitMsg("error");
            });
    }

    return (
        <div style={{background:"radial-gradient(aliceblue, transparent)"}}>
            <h5 className="title_config"> Config Admin Settings </h5>
            <div style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }} className="">
                <i class="fa fa-user-circle fa-3x title_config" aria-hidden="true"></i>
            </div><br/>
            <div>
                <div className="row">
                    <div className="col-2"></div><br/>
                    
                    <div className="col-8 ">
                    <div className=" second-part padding " >
                        <div  style={{display:"flex"}}>
                        <span className="col-6 span-reset"><i style={{ color: "#7D0752" }} class="fa fa-envelope " aria-hidden="true"></i> {login_datas.email}</span>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <span  className="col-6 span-reset" ><i style={{ color: "#7D0752" }} class="fa fa-phone " aria-hidden="true"></i> {login_datas.country_code + " " + login_datas.mobile_number}</span></div><br />
                        <div  style={{display:"flex"}}>
                        <span  className="col-6 span-reset"  ><i style={{ color: "#7D0752" }} class="fa fa-user " aria-hidden="true"></i> {login_datas.username}</span>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <span  className="col-6 span-reset" ><i style={{ color: "#7D0752" }} class="fa fa-key " aria-hidden="true"></i> *********</span>
                        <i style={{ color: "#7D0752" }}  onClick={() => { setDisplay(true) }}  class="fas fa-pencil-alt-square-o" aria-hidden="true"></i></div>
                       
                    </div><br/>
                        {display &&<div style={{marginLeft:"20%"}}> <div className=" second-part2 text-style-here"   ><br />
                            <h6 className="title_config"> Reset Password </h6><br />

                            <div className="form-group">

                                <div style={{marginLeft:"30%"}}  className="search-div">
                                    <div style={{ display: "flex" }}>
                                    <button style={{ backgroundColor: "#96716978" }} className="save-button">
                                        {passwordType === "password" ? <i style={{ color: "#7e7e7e" }} onClick={togglePasswordVisiblity} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity} className="fa fa-eye"></i>}
                                    </button>
                                        <input className="boxpwd " type={passwordType} placeholder="*Enter new Password" value={password} onChange={handlePasswordChange} />
                                       

                                    </div>
                                </div>


                                {error.password ? <div className="error-validation-msg"> {error.password}</div> : null}
                            </div>
                            <div  className="form-group ">
                                <div style={{marginLeft:"30%"}} className="search-div">
                                    <div style={{ display: "flex" }}>
                                    <button style={{ backgroundColor: "#96716978" }} className="save-button">
                                        {passwordType1 === "password" ? <i style={{ color: "#7e7e7e" }} onClick={togglePasswordVisiblity1} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity1} className="fa fa-eye"></i>}
                                    </button>
                                        <input className="boxpwd " type={passwordType1} maxLength='30' placeholder="*Confirm Password" value={confirmPassword} onChange={handleConfirmPassword} />
                                        

                                    </div>
                                </div>

                                {error.confirm_password ? <div className="error-validation-msg"> {error.confirm_password}</div> : null}

                            </div>

                            <div className=" " style={{ display: "flex", justifyContent: "center" }}>
                                <button onClick={() => { setDisplay(false) }} className="edit" > Cancel</button>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <button onClick={() => { handleSubmit()}}className="save">Update</button>
                            </div>

                            {submitMsg === "error" ?
                                <h6 style={{ color: "red" }}>

                                    Error in data submission.Please try again!
                                </h6>
                                :
                                null}





                        </div></div>}

                        <SubmitPopUp
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />

                    </div>
                </div>
            </div><br/>
            {verifyOTP ? (
                <OtpPopUp
                    show={verifyOTP}
                    onHide={() => {
                        setVerifyOTP(false);
                    }}
                />
            ) : null}
        </div>
    )
};
export default AdminResetPwd