import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';

function EditMedicine(props) {
  
  const [selectedImage, setSelectedImage] = useState(null);
    const[data, setData]= useState(props.data)
    const value = props.data.dosage_form ? props.data.dosage_form.toLowerCase() : '';
    const label = props.data.dosage_form ? props.data.dosage_form.toUpperCase() : '';
    const [dosage_type, Setdosage_type]= useState({value:value, label: label});
   const [routine, setRoutine]= useState({value:props.data.route, label: props.data.route})
    const[strength_unit,setStrengthUnit]=useState({value:props.data.strength_unit, label: props.data.strength_unit})
    const [errorSubmitModalShow,setErrorSubmitModalShow]=useState(false);
    const [errorMsg,setErrorMsg]=useState('');
    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)

    const [error,setError]=useState({

      med_type:'',
      generic_name:'',
      med_code:'',
      composition:''
      
        
       });

   // const [year, setYear]= useState(data.year_of_manufacture=== null ? '' : new Date(data.year_of_manufacture))
    //const [expiry, setExpiry]= useState(data.expiry_date=== null ? '' : new Date(data.expiry_date))

  //  const [yearData, setYearData]= useState(data.year_of_manufacture)
    //const [expiryData, setExpiryData]= useState(data.expiry_date)
    const DosageOptions = [{ value:'tablets', label:'TABLETS' },                       
    { value:'capsules', label:'CAPSULES' },
    { value:'chewable tablets', label:'CHEWABLE TABLETS' },
    { value:'powders', label:'POWDERS' },
    { value:'solutions', label:'SOLUTIONS' },
    { value:'emulsions', label:'EMULSIONS' },
    { value:'suspensions', label:'SUSPENSIONS' },
    { value:'lotions', label:'LOTIONS' },
    { value:'creams', label:'CREAMS' },
    { value:'ointments', label:'OINTMENTS' },
    { value:'effervescent granules', label:'EFFERVESCENT GRANULES' },
    { value:'aerosols', label:'AEROSOLS' },
    { value:'gases', label:'GASES' },
    { value:'suppositories', label:'SUPPOSITORIES' },
    { value:'injections', label:'INJECTIONS' }
]

const RouteOptions = [{ value:'oral', label:'ORAL' },
{ value:'topical_skin', label:'TOPICAL_SKIN' },
{ value:'topical-eyes', label:'TOPICAL_EYE' },
{ value:'topical_ears', label:'TOPICAL_EARS' },
{ value:'inhalation', label:'INHALATION' },
{ value:'injections', label:'INJECTIONS' },
{ value:'vaginal', label:'VAGINAL' },
{ value:'anal', label:'ANAL' },
{ value:'sub_lingual', label:'SUB_LINGUAL' }

]
const Strengthoptions = [{ value:'mg', label:'mg' },                       
{ value:'g', label:'g' },
{ value:'ml', label:'mL' },
{ value:'mcg', label:'mcg' },
{ value:'mEq', label:'mEq' },
{ value:'iu', label:'IU' },
]


const handleServiceChange= (data) => {
  Setdosage_type(data)
}

const handleUnitChange= (data) => {

  setRoutine(data)
}

const strengthChange = (data)=>
{
 setStrengthUnit(data)
}


    const SuccessPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4 className='text-success'>Approved Successfully</h4>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }
    
      const ErrorSubmitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> {errorMsg} </h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }
      const ErrorPopUp=(props)=> {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
             backdrop="static"
              keyboard={false}
              centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <h5 style={{color: "red"}}>Error in data submission.Please try again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
              
             <Button className='btn btn-primary btn-col' onClick={props.onHide}>Ok</Button> 
             
            
            </Modal.Footer>
          </Modal>
        );
      }

      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }

//       const handleServiceChange= (data) => {

//         setType(data)
//    }

//    const handleUnitChange= (data) => {

//         setUnit(data)
//   }

//   const handleRequest = (e)=> {
//    const val = e.target.value
//    const key = e.target.name

//    setData((current) => ({
//        ...current , [key] : val==='true'? true : val==='false'? false : null
//    }))

//   }

//   const handleYear = (date)=> {
//     setYear(date);
      
//       let dateUpdated= date;
//         if(date!==null){
//       const offset = date.getTimezoneOffset();
      
//       dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
//       let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
//       /*let dateString= DateExtracted.toString(); */
//       setYearData(DateExtracted);
      
//       }
//   }

//   const handleExpiry = (date)=> {
//     setExpiry(date);
      
//       let dateUpdated= date;
//         if(date!==null){
//       const offset = date.getTimezoneOffset();
      
//       dateUpdated = new Date(date.getTime() - (offset*60*1000))
      
//       let DateExtracted = dateUpdated.toISOString().split('T')[0];
      
//       /*let dateString= DateExtracted.toString(); */
//       setExpiryData(DateExtracted);
      
//       }
//   }
const validate=()=>{
  ;
    
  let input = data;
  let errors = {};
  let isValid = true;
setError({});

// medicinename :'',
// genericname:'',
// strength:'',
// medicinetype:'',
// dosage_type:'',
// medicinecode:'',


  if (!input["med_type"] || input["med_type"]==="" ) {
    isValid = false;
    errors["med_type"] = "Please add medtype";
  }

  if (!input["generic_name"] || input["generic_name"]=== null ) {
    isValid = false;
    errors["generic_name"] = "Please add generic name";
  }
  if (!input["med_code"] || input["med_code"]==null ) {
    isValid = false;
    errors["med_code"] = "Please add medicine code";
  }
  if (!input["composition"] || input["composition"]==null ) {
    isValid = false;
    errors["composition"] = "Please add composition";
  }



  
  

   setError(currentstate=>({
         ...currentstate,
        ...errors
      }));
  

  return isValid;
}

const saveChanges = (e)=> {
    e.preventDefault();

;

    if(validate()){
      
        const tokenString = sessionStorage.getItem("usertoken");
        const userid = sessionStorage.getItem("userid");
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
     const formData = new FormData();

     formData.append('med_url',data.med_url)
    formData.append('med_interactions',data.med_interactions)
     formData.append('approved_by', userid);
    //"approved_by" : userid,
    //med_name : data. med_name,
    formData.append('generic_name', data.generic_name);
   // generic_name :data. generic_name,
    //strength : data.strength,
    formData.append('med_type', data.med_type);
  //   med_type :   data. med_type,                               
  //  // dosage_form : dosage_type.value,
  formData.append('med_code',  data.med_code);

   formData.append('composition',data.composition);
   formData.append('is_approved',"True");
   // composition:data.composition,
    // "is_approved":"True",
        
    if (selectedImage) {
      formData.append('image', selectedImage);
    }

 
     axios
       .put(`${APIURL}/api/v1/staff/master-medicine-detail/${data.id}/`, formData , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.status==200) {
          setSuccessShow(true);
          setData({
            medicinename :'',
            genericname:'',
            strength:'',
            medicinetype:'',
            dosage_type:{ value:'', label:'' },
            medicinecode:'',
            routine: { value:'', label:'' },
            description:'',
            manufacturername:'',
            strength_unit:'',
            composition:''
         });}
          else {
           setErrorShow(true)
         }
       })
       .catch((err) => {
         setErrorShow(true)
       });
       setSuccessShow(false)
  
    }
      else{
        setErrorMsg("Medicine Type,Code, Generic Name and Composition are mandatory");
        setErrorSubmitModalShow(true);
      }
    }

    const handleImageUpload = (event) => {
      const image = event.target.files[0];
      setData(prevData => ({...prevData, image: URL.createObjectURL(image)}));
      setSelectedImage(image);
    };




    return (
        <>
     <h2 style={{marginTop:'0'}} className="title-of-page">Approve Medicine</h2>
     <div style={{display:"flex" ,marginLeft:"380px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {data.image && (
         <div style={{ display: 'flex' }}>
          <p>Selected Image:</p>
          <img src={data.image} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
        <div style={{maxHeight:'1000px'}} className='config-box fit-content'>

            
            <div className='feature-data pad-features'>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Medicine Name : </span>
                    <input type='text' className='form-control title-flex' name='med_name' value={data.med_name} onChange={handleTitle} />
                    {/* {error.med_name ? <div className="error-validation-msg error-feature-inventory">{error.med_name}</div> : null} */}
                </div>

                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Generic Name : </span>
                    <input type='text' className='form-control title-flex' name='generic_name' value={data.generic_name} onChange={handleTitle} />
                    {error.generic_name ? <div className="error-validation-msg error-feature-inventory">{error.generic_name}</div> : null}
                </div>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>Strength: </span>
                    <input type='text' className='form-control title-flex' name='strength' value={data.strength} onChange={handleTitle} />
                    {/* {error.strength ? <div className="error-validation-msg error-feature-inventory">{error.strength}</div> : null} */}
                </div>

                <div className='flex-row name-content' style={{marginLeft:"-1%", width:"30%"}} >
                <span className='align-rental'>Strength Unit: </span>
                <Select
                           defaultValue={strength_unit}
                            onChange={(data,e)=>{strengthChange(data,e)
                            }}
                            options={Strengthoptions}
                            name='strength_unit'
                            className="select-currency select-flex"
                        />
                    {/* {error.dosage_type ? <div className="error-validation-msg error-feature-inventory">{error.dosage_type}</div> : null} */}
                </div>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'>*Medicine Type : </span>
                    <input type='text' className='form-control title-flex' name='med_type' value={data. med_type} onChange={handleTitle} />
                    {error.med_type ? <div className="error-validation-msg error-feature-inventory">{error.med_type}</div> : null}
                </div>
                
                <div className='flex-col justify-feature'>
                    <span className='align-rental'> *Medicine Code : </span>
                    <input type='text' className='form-control title-flex' name='med_code' value={data. med_code} onChange={handleTitle} />
                    {error.med_code ? <div className="error-validation-msg error-feature-inventory">{error.med_code}</div> : null}
                </div>
               
                <div className='flex-col justify-feature'>
                    <span className='align-rental'> Description : </span>
                    <textarea type='text' className='form-control title-flex' name='description' value={data.description} onChange={handleTitle} />
                    {/* {error.description ? <div className="error-validation-msg error-feature-inventory">{error.description}</div> : null} */}
                </div>
                <div className='flex-col justify-feature'>
                    <span className='align-rental'> Manufacture Name : </span>
                    <input type='text' className='form-control title-flex' name='manufacturer_name' value={data.manufacturer_name} onChange={handleTitle} />
                    {/* {error.manufacturername ? <div className="error-validation-msg error-feature-inventory">{error.manufacturername}</div> : null} */}
                </div>
                <div className='flex-row name-content' style={{marginLeft:"-1%"}}>
                <span className='align-rental'>Dosage: </span>
                <Select
                           defaultValue={dosage_type}
                            onChange={(data,e)=>{handleServiceChange(data,e)
                            }}
                            options={DosageOptions}
                            name='dosage_type'
                            className="select-currency select-flex"
                        />
                    {/* {error.dosage_type ? <div className="error-validation-msg error-feature-inventory">{error.dosage_type}</div> : null} */}
                </div>

                <div className='flex-row name-content' style={{marginLeft:"-2%"}} >
                <span className='align-rental'>Route: </span>
                <Select
                defaultValue={routine}
                           
                            onChange={(data,e)=>{handleUnitChange(data,e)
                            }}
                            options={RouteOptions}
                            name='routine'
                            className="select-currency select-flex"
                        />
                    {/* {error.routine ? <div className="error-validation-msg error-feature-inventory">{error.routine}</div> : null} */}
                </div>
                <div className='flex-col justify-feature' style={{marginLeft:"11.9%"}}>
                    <span className='align-rental'>*Composition: </span>
                    <input type='text' className='form-control title-flex' name='composition' value={data.composition} onChange={handleTitle} />
                    {error.composition ? <div className="error-validation-msg error-feature-inventory">{error.composition}</div> : null}
                </div>
                <div className="flex-col justify-feature" >
                    <span className='align-rental'>Drug Interactions: </span>
                    <textarea type='text' className='form-control title-flex' name='med_interactions' value={data.med_interactions} onChange={handleTitle} />
                    {/* {error.description ? <div className="error-validation-msg error-feature-inventory">{error.description}</div> : null} */}
                </div>
                <div className="flex-col justify-feature" >
                    <span className='align-rental'>Drug Interactions URL: </span>
                    <textarea type='text' className='form-control title-flex' name='med_url' value={data.med_url} onChange={handleTitle} />
                    {/* {error.description ? <div className="error-validation-msg error-feature-inventory">{error.description}</div> : null} */}
                </div>
                </div>

            <div className='text-center'>
                <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Approve</button>
                
            </div>
            {successShow ? 
<SuccessPopUp
show={successShow}
onHide={() =>  {setSuccessShow(false);props.modalClose() }}
/> 
: null}

{errorShow ? 

<ErrorPopUp
show={errorShow}
onHide={() =>  {setErrorShow(false)}}
/> 
: null}
{errorMsg !=="" ?
    <ErrorSubmitPopup
        show={errorSubmitModalShow}
        onHide={() =>  {setErrorSubmitModalShow(false);
                        setErrorMsg('')}}
        
      />   : null } 
            
        </div>
        </>
    )
}

export default EditMedicine
