import React, { useState, useEffect, useRef, useContext } from 'react'
import './csc.css'
import './bookings.css'
import axios from "axios";
import { APIURL } from "../../../Global";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
let lastPageCalled = 1
const Bookings = ({ selectedDate, setSelectedDate, selectedItem, setSelectedItem, selected, setReload, reload, role }) => {
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [searchTerm, setSearchTerm] = useState("")
    const [itemList, setItemList] = useState([])
    const [selectedDay, setSelectedDay] = useState(new Date());
    let lastScrollTop = 0;
    let lastScrollLeft = 0;

    useEffect(() => {
        setItemList([])
        setSelectedDay(new Date())
        setSelectedDate(moment(new Date()).format('YYYY-MM-DD'))
        if (selected === 'GeneralOp') {
            lastPageCalled = 1
            doctorSearch(searchTerm, 1)
        } else if (selected === 'DialysisOp') {
            machineSearch(searchTerm)
        } else {
            alliedSearch(searchTerm)
        }
    }, [selected])


    const handleScroll = (event) => {
        const scrollContainer = event.target;
        const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

        const isVerticalScroll = Math.abs(scrollTop - lastScrollTop) > Math.abs(scrollLeft - lastScrollLeft);
        lastScrollTop = scrollTop;
        lastScrollLeft = scrollLeft;
        const isBottom = scrollTop + clientHeight >= scrollHeight - 10;

        if (isBottom && !loading && hasNextPage && isVerticalScroll && (page !== lastPageCalled)) {
            if (selected === 'GeneralOp') {
                doctorSearch(searchTerm, page);
            }
            lastPageCalled = page
        }
    }

    useEffect(() => {
        const scrollContainer = document.getElementById('doctorlist');
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleScroll);

            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [loading, page, hasNextPage]);

    const handleItemSearch = e => {
        if (selected === 'GeneralOp') {
            if (page > 1) {
                setPage(1)
            }
            lastPageCalled = 1
            const currentPage = 1
            setItemList([])
            setSearchTerm(e.target.value)
            doctorSearch(e.target.value, currentPage)
        }
        else if (selected === 'DialysisOp') {
            setItemList([])
            setSearchTerm(e.target.value)
            machineSearch(e.target.value)
        } else {
            setItemList([])
            setSearchTerm(e.target.value)
            alliedSearch(e.target.value)
        }
    }


    const doctorSearch = (val, currentPage) => {

        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");
        setLoading(true)
        axios
            .get(`${APIURL}/api/v1/connect/doctorslist/?q=${val}&page=${currentPage}&per_page=6`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.data.status === "success") {
                    const pageData = res.data.results
                    setItemList((prevItemList) => [...prevItemList, ...pageData]);
                    setPage(currentPage + 1)
                    setHasNextPage(res.data.has_next)
                }
            })
            .catch((err) => {

            });
        setLoading(false)
    }

    const machineSearch = (val) => {

        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        axios
            .get(`${APIURL}/api/v1/service-provider/dialysis-unit/?is_active=True&is_standby=False&search=${val}`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.data.status === "success") {
                    const pageData = res.data.message
                    setItemList(pageData)
                }
            })
            .catch((err) => {

            });
    }

    const alliedSearch = (val) => {

        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        axios
            .get(`${APIURL}/api/v1/service-provider/access-department-users/?department[]=5&name=${val}`, {

                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.status === 200) {
                    const pageData = res.data.details
                    setItemList(pageData)
                }
            })
            .catch((err) => {

            });
    }

    const handleDayClick = (date) => {
        const clickedDay = moment(date).format('YYYY-MM-DD');
        console.log('Clicked Day:', clickedDay);

        setSelectedDate(clickedDay);
        setSelectedDay(date);

    };

    const tileClassName = ({ date, view }) => {
        if (!date) return '';

        if (view === 'month') {
            const selectedDateObj = new Date(selectedDate);
            return date.getTime() === selectedDateObj.getTime() ? 'highlight' : null;
        }
        return null;
    };

    const handleItemClick = (item) => {
        if (selectedItem.id === item.id) {
            setSelectedItem('')
            setReload(!reload)
        } else {
            setSelectedItem(item)
        }
    }

    return (
        <div className="col-lg-3 col-md-3 container">
            <div className='side-box1'>
                <Calendar
                    onChange={handleDayClick}
                    value={selectedDay}
                    className="small-calendar"
                    formatShortWeekday={(locale, date) => {
                        const options = { weekday: 'short' };
                        return new Intl.DateTimeFormat(locale, options).format(date);
                    }}
                    tileClassName={tileClassName}
                    tileContent={({ date, view }) => {
                        if (!date || !selectedDate) return null;
                        else if (view === 'month' && date.toDateString() === selectedDay.toDateString()) {
                            return <div className="highlight"></div>;
                        }
                        return null;
                    }}
                />
            </div>{role !== 'alliedprofessional' &&
                <div className='side-box1'>
                    <div className='side-box-head'>
                        <div><label>{selected === "GeneralOp" ? "Doctors List" : selected === "DialysisOp" ? "Machines List" : "Allied Professionals List"}</label></div>
                        <div className='sidebox-search-input-container' style={{ backgroundColor: "#F5EFE8" }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3851 15.4461C13.0371 16.5232 11.3278 17.0432 9.60827 16.8994C7.88877 16.7556 6.28961 15.9588 5.13922 14.6728C3.98883 13.3867 3.37455 11.709 3.42254 9.98415C3.47052 8.25932 4.17713 6.61834 5.39725 5.39822C6.61736 4.17811 8.25835 3.4715 9.98318 3.42351C11.708 3.37553 13.3857 3.98981 14.6718 5.1402C15.9579 6.29058 16.7546 7.88975 16.8984 9.60924C17.0422 11.3287 16.5222 13.038 15.4451 14.3861L20.6011 19.5411C20.6748 19.6097 20.7339 19.6925 20.7749 19.7845C20.8159 19.8765 20.8379 19.9758 20.8397 20.0765C20.8415 20.1772 20.8229 20.2773 20.7852 20.3707C20.7475 20.4641 20.6913 20.5489 20.6201 20.6201C20.5489 20.6913 20.4641 20.7475 20.3707 20.7852C20.2773 20.8229 20.1773 20.8414 20.0766 20.8397C19.9759 20.8379 19.8766 20.8158 19.7846 20.7748C19.6926 20.7339 19.6098 20.6748 19.5411 20.6011L14.3851 15.4461ZM6.46009 13.8841C5.72613 13.15 5.22624 12.2149 5.0236 11.1968C4.82096 10.1788 4.92466 9.1235 5.3216 8.16437C5.71854 7.20523 6.3909 6.38529 7.2537 5.80817C8.11651 5.23105 9.13103 4.92265 10.1691 4.92195C11.2071 4.92125 12.222 5.22828 13.0856 5.80424C13.9492 6.3802 14.6226 7.19924 15.0209 8.15784C15.4191 9.11644 15.5242 10.1716 15.323 11.1899C15.1217 12.2082 14.6231 13.144 13.8901 13.8791L13.8851 13.8841L13.8801 13.8881C12.8952 14.8707 11.5605 15.4222 10.1693 15.4214C8.77801 15.4207 7.44394 14.8678 6.46009 13.8841Z" fill="#777777" />
                            </svg>
                            <input
                                className='sidebox-search-input'
                                type='text'
                                placeholder={selected === 'GeneralOp' ? 'Search Doctor...' : selected === 'DialysisOp' ? 'Search Machine...' : 'Search Allied...'}
                                value={searchTerm}
                                onChange={handleItemSearch} />
                        </div>
                    </div>
                    <div></div>

                    {selected === 'GeneralOp' ? (
                        <div className='doctor-list-container' id='doctorlist'>
                            {itemList.length > 0 ? itemList.map((item, index) => {
                                let specializations;
                                if (item.specialization && item.specialization.startsWith("[") && item.specialization.endsWith("]")) {
                                    try {
                                        specializations = JSON.parse(item.specialization);
                                    } catch (e) {
                                        specializations = [];
                                    }
                                } else {
                                    specializations = [item.specialization];
                                }
                                const specializationText = specializations.join(", ");
                                return (
                                    <div
                                        key={item.id}
                                        className={`csc-doctorlist ${selectedItem === item ? 'selected-item' : ''}`}
                                        onClick={() => { handleItemClick(item) }}
                                    >
                                        <div style={{ flex: 1 }}>
                                            <label className=""> {index + 1 + "."}</label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: 'column', flex: 10 }}>
                                            <label className="head-style"> {item.full_name}</label>
                                            <label className="content-style"> {specializationText}</label>
                                        </div>
                                    </div>
                                )
                            }) : (
                                <div className="" style={{ marginTop: "10%", color: "red" }}>No Doctors!!</div>
                            )}
                        </div>
                    ) : selected === 'DialysisOp' ? (
                        <div className='doctor-list-container'>
                            {itemList.length > 0 ? itemList.map((item, index) => (
                                <div
                                    key={item.id}
                                    className={`csc-doctorlist ${selectedItem === item ? 'selected-item' : ''}`}
                                    onClick={() => { handleItemClick(item) }}
                                >
                                    <div style={{ flex: 1 }}>
                                        <label className=""> {index + 1 + "."}</label>
                                    </div>
                                    <div style={{ display: "flex ", flexDirection: 'column', flex: 10 }}>
                                        <label className="head-style"> {item.unit_no}</label>
                                        <label className="content-style"> {item.model_name}</label>
                                    </div>
                                </div>
                            )
                            ) : (
                                <div className="" style={{ marginTop: "10%", color: "red" }}>No Machines!!</div>
                            )}
                        </div>
                    ) : (selected === 'PhysioOp') ? (
                        <div className='doctor-list-container'>
                            {itemList.length > 0 ? itemList.map((item, index) => {
                                // Check if item.specializations is a string and starts with '[' and ends with ']'
                                let specializations = [];
                                if (typeof item.specializations === 'string') {
                                    if (item.specializations.startsWith('[') && item.specializations.endsWith(']')) {
                                        try {
                                            const jsonString = item.specializations.replace(/'/g, '"');
                                            specializations = JSON.parse(jsonString);
                                        } catch (error) {
                                            specializations = item.specializations.split(',');
                                        }
                                    } else {
                                        specializations = item.specializations.split(',');
                                    }
                                } else if (Array.isArray(item.specializations)) {
                                    specializations = item.specializations;
                                }

                                return (
                                    <div
                                        key={item.id}
                                        className={`csc-doctorlist ${selectedItem === item ? 'selected-item' : ''}`}
                                        onClick={() => { handleItemClick(item) }}
                                    >
                                        <div style={{ flex: 1 }}>
                                            <label className=""> {index + 1 + "."}</label>
                                        </div>
                                        <div style={{ display: "flex ", flexDirection: 'column', flex: 10 }}>
                                            <label className="head-style"> {item.name}</label>
                                            <label className="content-style">
                                                {specializations.map((specialization, specIndex) => (
                                                    <span key={specIndex}>
                                                        {specialization.trim()}{specIndex < specializations.length - 1 && ', '}
                                                    </span>
                                                ))}
                                            </label>
                                        </div>
                                    </div>
                                )
                            }
                            ) : (
                                <div className="" style={{ marginTop: "10%", color: "red" }}>No Allied Professionals!!</div>
                            )}
                        </div>

                    ) : ''}
                </div>
            }
        </div >
    )
}
export default Bookings