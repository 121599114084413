import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import './Dialysis.css';
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
let lastPageCalled = 1

function Inhouse({ type, searchData, search }) {
    const [selected, setSelected] = useState([]);
    const [patientList, setPatientList] = useState([]);
    const [hasNext, setHasNext] = useState(false);
    const [page, setPage] = useState(1);
    const alert = useAlert();
    let lastScrollTop = 0;
    let lastScrollLeft = 0;

    useEffect(() => {
        if (search === '') {
            handleLoad(1);
        }
    }, []);

    useEffect(() => {
        console.log(searchData)
        console.log(search)
        if (search !== '') {
            console.log(searchData)
            setPatientList(searchData)
        }
    }, [searchData, search])

    const handleScroll = (event) => {
        const scrollContainer = event.target;
        const { scrollTop, scrollLeft, clientHeight, scrollHeight, clientWidth, scrollWidth } = scrollContainer;

        const isVerticalScroll = Math.abs(scrollTop - lastScrollTop) > Math.abs(scrollLeft - lastScrollLeft);
        lastScrollTop = scrollTop;
        lastScrollLeft = scrollLeft;

        const isBottom = scrollTop + clientHeight >= scrollHeight - 10;

        if (isBottom && hasNext && isVerticalScroll && (page !== lastPageCalled)) {
            handleLoad(page);
            lastPageCalled = page;
        }
    };


    useEffect(() => {
        const scrollContainer = document.getElementById('list');
        if (scrollContainer && search === '') {
            scrollContainer.addEventListener('scroll', handleScroll);

            return () => {
                scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [page, hasNext]);

    const handleLoad = async (currentPage) => {
        const tokenString = sessionStorage.getItem('usertoken');
        const str = tokenString.replace(/["]+/g, '');

        try {
            const res = await axios.get(`${APIURL}/api/v1/service-provider/in-house-queue/?queue_type=${type}&per_page=20&page=${currentPage}`, {
                headers: {
                    "Authorization": 'Token ' + str
                }
            });

            if (res.data.status === "success") {
                const Data = res.data.inhouse_members;
                if (currentPage === 1) {
                    setPatientList(Data);
                } else {
                    setPatientList((prevPatientList) => [...prevPatientList, ...Data]);
                }
                setHasNext(res.data.pagination.has_next);
                setPage(currentPage + 1);
            } else {
                alert.error('Failed to load patient data.');
            }
        } catch (err) {
            alert.error('Error occurred while loading patient data.');
        }
    };

    const handleItemClick = (item) => {
        setSelected((prevSelected) => {
            if (prevSelected.includes(item)) {
                return prevSelected.filter(a => a !== item);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    const handleAdd = async () => {
        const tokenString = sessionStorage.getItem('usertoken');
        const token = tokenString.replace(/["]+/g, '');
        if (selected.length === 0) {
            alert.error("Select patients to add")
        } else {
            const payload = {
                "members": selected.map((item) => ({
                    "admission_number": item.pv_admission_number,
                    "user_id": item.user_id,
                })),
                "queue_type": type
            };

            try {
                const res = await axios.post(`${APIURL}/api/v1/service-provider/in-house-queue/`, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                });

                if (res.status === 200) {
                    if (res.data.status === 'success') {
                        alert.success(res.data.message);
                        setPage(1)
                        setHasNext(true)
                        handleLoad(1)
                    } else {
                        alert.error(res.data.message);
                    }
                } else {
                    alert.error('Unexpected error occurred.');
                }
            } catch (err) {
                alert.error('Error occurred. Try again!');
            }
            setSelected([])
        }
    };

    return (
        <>
            {patientList && patientList.length > 0 ? (
                <>
                    <div style={{ marginLeft: '-14px', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <label className='inhouse-label'>In-house members</label>
                        {selected.length > 0 && <button onClick={handleAdd} className="inhouse-save">Add to queue</button>}
                    </div>
                    <div className='row' id='list' style={{ maxHeight: '500px', overflowY: 'auto' }}>
                        {patientList.map((item) => (
                            <div className='col-6' style={{ paddingLeft: 0 }} key={item.id}>
                                <div className="dialysisreq_lists dialysis_inhouse" style={{ cursor: "pointer" }} onClick={() => handleItemClick(item)}>
                                    <div className="col-8 dename-content">
                                        <label>{item.full_name}</label>
                                    </div>
                                    <div className='selection col-2'>
                                        {selected.includes(item) ? <FaCheckCircle /> : selected.length > 0 ? <FaRegCircle /> : ''}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div className="nodata">No requests pending!!</div>
            )}
        </>
    );
}

export default Inhouse;