import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import HOCard from "./HOCard";
// import "./managerlist.css";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { APIURL } from "../../Global";
//import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import LoadingOverlay from 'react-loading-overlay';
//import BackButton from "../common/BackButton";
import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
function HOList() {
  
  const [loading,setLoading]=useState(false);
  const [bdoList, setBdoList] = useState([]);
  const [freeCount, setFreeCount] = useState();
  const [activeCount, setActiveCount] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
  
  useEffect(() => {
    if(showConfirmation){
    closeConfirmationPopup();
    }
  }, [showConfirmation, closeConfirmationPopup]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
bdolist();
  }, []);

  const bdolist=()=>
  {
    
    setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/higher-officials/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + v2,
        },
      })
      .then((res) => {
        if (res.status ===200) {
          
          const datas = res.data;

          //setFreeCount(res.data.free_user_count);
          //setActiveCount(res.data.active_free_users);
          setLoading(false)
         
          setBdoList(datas);
        } else {
          setErrorMsg("error");
          setLoading(false)
        }
      })
      .catch((err) => {
        setErrorMsg("error");
        setLoading(false)
      });
  }

  const refreshHandle = () => {
    setRefresh(!refresh);
    bdolist();
  };
 

  return (
   
   
    <div className="drlist-page">
    

<LoadingOverlay
    active={loading}
    spinner
    styles={{
      spinner: (base) => ({
        ...base,
        width: '50px',
        '& svg circle': {
          stroke: 'rgba(0, 0, 255, 0.5)',
          
        }
      }),
      overlay: (base) => ({
        ...base,
        background: '',
     position:"initial"
        
      })
    }}
   
  >
 
      <h2 className="title-of-page"> Trust Users List</h2>
      <div className="quick-links">
        <Link to="/add-HO">
          <button type="button" className="btn btn-primary btn-col dr-btn">
            {" "}
            Add Trust Users to List{" "}
          </button>
        </Link>
        {/* <Link to={{ pathname: "license-details", flag: "bdo-page" }}> */}
          {/* <button
            type="button"
            style={{ marginLeft: "10px" }}
            className="btn btn-info dr-btn"
          >
            {" "}
            {t("View Licence Details")}
          </button>
        </Link> */}
      </div>

      {bdoList.length !== 0 ? (
        <div>
          <div className="drs-container">
            {bdoList.length > 0
            
              ? bdoList.map((data, index) => {
           
                  return (
                    <HOCard
                      key={index}
                      data={data}
                      // freeCount={freeCount}
                      // activeCount={activeCount}
                      handle={refreshHandle}
                    />
                
                  );
                })
              : null}
          </div>

          <div className="quick-links">
            <Link to="/add-HO">
              <button type="button" className="btn btn-primary btn-col dr-btn">
                {" "}
                 Add Trust Users to List{" "}
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <h5 style={{ color: "red", margin: "20px" }}>Trust Users List is empty </h5>
      )}

      {errorMsg === "error" ? (
        <h5 style={{ color: "red" }}>
          Error in data fetching.Please try again!
        </h5>
      ) : null}
    
    </LoadingOverlay>
    </div>
  );
}

export default HOList;
