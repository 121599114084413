import React, { useEffect, useState } from 'react';
import './addMachine.css';
import AddMachinePopup from './AddMachinePopup';
import MachineView from './MachineView';
import axios from "axios";
import { APIURL } from "../../../Global";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { HiEllipsisVertical } from "react-icons/hi2";
import { RxCrossCircled } from "react-icons/rx";
import { Modal } from 'react-bootstrap';
const AddMachine = () => {
    const [machines, setMachines] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showMachine, setShowMachine] = useState(false);
    const [selectedMachine, setSelectedMachine] = useState('');

    useEffect(() => {
        addedMachines()
    }, [])

    const addedMachines = async (currentPage) => {

        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/service-provider/dialysis-unit/`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    const filteredData = res.data.message
                    setMachines(filteredData)
                }
            })
            .catch((err) => { });
    }

    const closePopup = () => {
        setShowModal(false);
        setShowMachine(false);
        setSelectedMachine('');
    };

    const handleReload = () => {
        addedMachines();
    };

    const handleCardClick = (machine) => {
        setSelectedMachine(machine);
        setShowMachine(true)
    };

    const AddMachines = (props) => {
        return (
            <Modal
                {...props}
                size=""
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body style={{ textAlign: "left" }}>
                    <AddMachinePopup primary={false} close={closePopup} handleReload={handleReload} />
                </Modal.Body>
            </Modal>
        );
    };

    return (
        (machines && machines.length > 0) ? (
            <div className='addmachine-container'>
                <div className='addmachine-header'>
                    <button className='add-machine-button' onClick={() => setShowModal(true)}>Add Machine</button>
                </div>
                <div className='machine-grid'>
                    {machines.length > 0 && machines.map((machine) => (
                        <div key={machine.id} className='machine-card' onClick={() => handleCardClick(machine)}>
                            <div style={{ flex: 5, textAlign: "center", display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", padding: "0 5px" }}>
                                    {/* <div style={{ flex: 1, textAlign: "left" }}>
                                    {machine.is_active ? (
                                        <svg stroke="#3DB642" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path fill="none" stroke="#3DB642" stroke-width="2" d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"></path>
                                        </svg>
                                    ) : (
                                        <RxCrossCircled />
                                    )}
                                </div> */}
                                    <div className='three-dots'>
                                        <HiEllipsisVertical />
                                    </div>
                                </div>
                                <div className='dialysis-icon2'>
                                    <svg width="40" height="37" viewBox="0 0 69 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="100%" height="100%" fill="url(#pattern0_2828_2823)" />
                                        <defs>
                                            <pattern id="pattern0_2828_2823" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                <use href="#image0_2828_2823" transform="matrix(0.00956522 0 0 0.01 0.0217391 0)" />
                                            </pattern>
                                            <image id="image0_2828_2823" width="100" height="100" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEP0lEQVR4nO2dX4hVRRzHZxVJYdNK7cFA2S0fwofUFUmKzEDw2ZDSHiqEUjKkFcU/D4a7wYKCz+tDYW/e1SLqYckUMagkqXwRFUp78EVJTbMlV/Yjg/NwV7Z77t4zc8/Mb34fGFiW3Z3fdz7cc87OmTPHGEVRFEVRFEVRFKEAS4E9wCGglkg7BOy2tRspAC8BZ0ifn4AVJmWAzcAochgFNpkUAV4HxpDHGPCmSQlgDnALudwCZptUAD5BPv0mFYCLyOeSSQFgJvnwuIkdYGETQYYj+B+jVtBsjUUsNLEDLGoiyCITOQjJISYIQnKICYKQHC0FAbqBnopbd9kckoTYk2jV1MrmiBIVEhkqJDJUSGRIOfYiJIeYIAjJISYIQnKICULmJ/UB4GzFbaBsjijRq6zIUCGRoUJkCFkBrKu4jVtzlfU5JEYQkkNMEITkEBMEITmaDdJjIgdYlpOQ08B8EynAAuD7nIRY/gE+BVYB0yKoexrwGvCZq43chNRzF/gO6LMLmYHFwPSANc5wfdi++l3ftobJUp0QoMOFWFtwPd+LP64BvwJfu0/TQWAvsBV4z7UNdX1vqPv+VvezB93v2r/xm/ubvugtGIu1bsw6fMt4FvjZY5DcOGPH0JeMTuBy1YkE8IcdSx9CPqw6iSC2+BDyOfFz37XYOexDSAyL2IrYAewkfmo5CDnqrgBtO0bciBfyJ/BUXa1PuJNnrIgWMjrRc+PAcuA/MhbSyiJlH2xvUHO7zieTXTQuVshxYEqDmqe46ZDQqBAeTvyNe5ajwYztbRUS/hOyaRKf7g9USFghvzQ6VP3PoSvkHFzWh6wx4OUW6n+FcGQtZLhEBnsREIKshawukWFNoJqyFfJXmZs8blrlaoC6shVy3EOObwLUla2QUx5y2JUuvslWyA3gsRIZpgM3A9SVrRDLOyUyvEsYshZyDZjXQv3PANcD1ZS1EMsFoGsStXcF3t0ueyG4CcOPGq3gsDu9AduAO4RFhTyyM+gX7t7H267Zr78E/qY9qJDIUCGRoUIiI0ohQxPsDnc2k9b9SPahdggZLOjkZOlOhACcKhirQR+dfFzQyb92r3eTOcBcYKRgrPb66OgtijkCTDWZAkx1KyiLWO+js9lNvgPkBLDSPp1kMoGHT2K92sShynIPeNJXx+1Y4ySdb73IaMNCgVxY5U2Ik2Kf0VNa4yuvMuremvN7iwXlzBXgae9CnJTFge68SeUG8EIQGXVSngPOV500AS4BzweVUSdlFnCgiX+EcmQE2G/fNNQWGROsMN8HnKt6FCLgnBuL+TFNHdjXq24kHzYCS2x2Eyvu9mkudJoUcIsRpHPepILbYUc6fSYV7CSaWyAtlZtJvXrV4rYmkvpy4jdMitjnAYW9vvse8L5JGbdJ8o+kzw/Ai0YK7np9l7tHX0ukDbqal1Q9foqiKIqiKIqiKCYQDwAkUKz1Q+aAAwAAAABJRU5ErkJggg==" />
                                        </defs>
                                    </svg>
                                </div>
                                <label className='model-name'>{machine.unit_no}</label>
                                <label className='unit-no'>{machine.model_name}</label>
                            </div>
                            <div style={{ flex: 1 }} className={`active-div ${(machine.is_active === false || machine.is_active === "false") ? 'gry-color' : ''}`}>
                                <label>{machine.is_standby ? "Back Up" : ""}</label>
                            </div>
                        </div>
                    ))}
                </div>
                {showModal &&
                    < AddMachines
                        show={showModal}
                        onHide={() => {
                            setShowModal(false);
                        }}
                    />}
                {
                    showMachine && (
                        <MachineView machine={selectedMachine} close={closePopup} handleReload={handleReload} />
                    )
                }
            </div >) : (
            <div className='mschedule-primary-container'>
                <AddMachinePopup primary={true} handleReload={handleReload} />
            </div>
        )
    );
};

export default AddMachine;
