import React, { useState, useEffect } from "react";
import { CountryDropdown, } from "react-country-region-selector";
import "./config.css";
import axios from "axios";
import { useAlert } from 'react-alert' 
import { APIURL } from "../../Global";
import PhoneInput from "react-phone-input-2";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import VerifyOTP from "./VerifyOTP";
import 'react-phone-input-2/lib/style.css'
import noimage from '../../assets/images/felixa_logo.png'
import orglogo from '../../assets/images/icons/logo2.png'
import orglogo1 from '../../assets/images/org_logo.png'
function ConfigDash() {
    const [isData, setIsData] = useState(false)
    const [isChange, setIsChange] = useState(true)
    const [isChange1, setIsChange1] = useState(false)
    const [isChange2, setIsChange2] = useState(false)
    const [isChange3, setIsChange3] = useState(false)
    const [isChange4, setIsChange4] = useState(false)
    const [isChange5, setIsChange5] = useState(false)
    const [isChange6, setIsChange6] = useState(false)
    const [isChange7, setIsChange7] = useState(false)
    const [isChange8, setIsChange8] = useState(false)
    const [isChange9, setIsChange9] = useState(false)
    const [isChange10, setIsChange10] = useState(false)
    const [verifyOTP, setVerifyOTP] = useState(false)
    const alert = useAlert()
    const [refresh, setRefresh] = useState(false)
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [codeLength, setCodeLength] = useState()
    const [errorMsg, setErrorMsg] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(true);
    const [submitMsg, setSubmitMsg] = useState("");
    const [errorShow, setErrorShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [sizeShow, setSizeShow] = useState(false);
    const [validShow, setValidShow] = useState(false);
    const [contentShow, setContentShow] = useState(false);
    const [uploadShow, setUploadShow] = useState(false);
    const [img_app, setImg] = useState("");
    const [imgUrl, setImgUrl] = useState("")
    const [img_org, setImg_org] = useState("");
    const [img_OrgUrl, setImg_OrgUrl] = useState("");
    const [invalidPic, setInvalidPic] = useState(null);
    const [previewOn, setPreviewOn] = useState(false);
    const [previewOnorg, setPreviewOnorg] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [passwordType1, setPasswordType1] = useState("password");
    const [aggregator, setAggregator] = useState(true)
    const [isNumEditable, setNumEditable] = useState(false)
    const [aggregationType, setAggregationType] = useState("aggregator")
    const [numSp, setNumSP] = useState(1)


    const projectType = sessionStorage.getItem("project_type")?sessionStorage.getItem("project_type"):null
       
     
    const [projType,setProjType]=useState("")
    const togglePasswordVisiblity = (e) => {

        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const togglePasswordVisiblity1 = (e) => {

        if (passwordType1 === "password") {
            setPasswordType1("text")
            return;
        }
        setPasswordType1("password")
    }
    const onSuccess = () => {
        setVerifyOTP(false);
        if (isData) {
            updateInfo()
        } else {
            handleSubmit();
        }
    };
    const onCancel = () => {

        setVerifyOTP(false);

    };
    useEffect(() => {
        setNumEditable(!aggregator || isChange10)
    }, [aggregator, isChange10]);

    const [error, setError] = useState({
        email: "",
        phone: "",
    });
    const [generalInfo, setGeneralInfo] = useState({
        name_of_organization: "",
        office_email: "",
        country: "",
        country_code: "",
        mobile_number: "",
        img: "",
        img_org: "",
        website: "",
        official_address: "",
        pincode: "",
        app_name: "",
        backoffice_username: "",
        backoffice_password: "",
        confirmpassword: "",
        app_logo: ""
    });


    useEffect(() => {

        
        const tokenString = sessionStorage.getItem('usertoken');
        let str = tokenString.replace(/["]+/g, '');

//         const dataToSend ={
           
//                    project_type : projectType !==null ? projectType : 'home_care'
//   };

//       axios.post(`${APIURL}/api/v1/account/general-info/`, dataToSend, {
//                 headers: {
//                     "Content-Type": "application/json",
//                     Authorization: "Token " + str,
//                 },
//             })



        axios.get(`${APIURL}/api/v1/account/general-info/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": 'Token ' + str
                }
            })
            .then(res => {
                if (res.data.status === "success") {

setProjType(res.data.project_type)
                    setGeneralInfo(res.data.message);
                    window.original_value = res.data.message;
                    window.app_logo = res.data.app_logo
                    window.org_logo = res.data.organization_logo
                    window.aggrType = res.data.aggregation_type
                    window.num = res.data['service provider count']
                    if (res.data.app_logo !== "") {
                        setPreviewOn(true)
                    }
                    if (res.data.aggregation_type === "aggregator") {
                        setAggregator(true)
                        setIsChange(false)
                    }
                    else {
                        setAggregator(false)

                    }
                    setNumSP(res.data['service provider count'])

                    if (res.data.message.name_of_organization !== "") {
                        setIsData(true)
                        setIsChange1(true)
                        setIsChange2(true)
                        setIsChange3(true)
                        setIsChange4(true)
                        setIsChange5(true)
                        setIsChange6(true)
                        setIsChange7(true)
                        setIsChange8(true)
                        setIsChange9(true)
                        setIsChange10(true)

                    }
                    if (res.data.organization_logo !== "") {
                        setPreviewOnorg(true)
                    }
                    setImg(res.data.app_logo)
                    setImg_org(res.data.organization_logo)
                }
                else {

                }
            })
            .catch(err => {

                setErrorMsg("Error in fetching data");

            })
    }, [refresh])
    const handleContinue = () => {


if(projType===""){
    alert.error("Select project type")
}
else{
        if (validate() && isValidEmail && isValidPhone  ) {
            setVerifyOTP(true)
            // handleSubmit();
        }
    }
    };
    const handleUpdateInfo = () => {
        
        if(projType===""){
            alert.error("Select a project type")
        }
        else{
        if (validateUpdation()) {

            setVerifyOTP(true)

        }
    }
    };
    function OtpPopUp(props) {
        return (

            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body >
                    <VerifyOTP onSuccess={onSuccess} onCancel={onCancel} />
                </Modal.Body>

            </Modal>
        );
    }

    const getChangedData = (value1) => {
        let objectByKeys = {};
        if (window.original_value) {
            let changed_keys = Object.keys(value1).filter(key => value1[key] !== window.original_value[key]);
            changed_keys.forEach(key => {
                objectByKeys[key] = value1[key]
            });
            delete window.original_value;
        }
        return objectByKeys;
    }

    function ErrorPopUp(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className="caps" style={{ color: "red" }}>
                        {errorMsg}
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }


    const SizePopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 id="confirm-reject">You have exceeded image size limit 10MB</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    const handleSubmit = () => {

        const tokenStr = sessionStorage.getItem("usertoken");

        let fd = new FormData();
        const value1 = {
            name_of_organization: generalInfo.name_of_organization,
            office_email: generalInfo.office_email,
            country: generalInfo.country,
            country_code: generalInfo.country_code,
            mobile_number: generalInfo.mobile_number,
            website: generalInfo.website ? generalInfo.website : "https://www.intpurple.com/",
            official_address: generalInfo.official_address,
            app_name: generalInfo.app_name,
            backoffice_username: generalInfo.backoffice_username,
            backoffice_password: generalInfo.backoffice_password,


        }
        if (img_app === "") {
            fd.append("app_logo", dataURLtoFile(noimage), "felixa_logo");
        } else {
            fd.append("app_logo", img_app);
        }
        if (img_org === "") {
            fd.append("organization_logo", dataURLtoFile(orglogo), "felixa_org")

        } else {
            fd.append("organization_logo", img_org)
        }
        const value = JSON.stringify(value1)
        fd.append("aggregation_type", aggregator ? "aggregator" : "non-aggregator")
        fd.append("no_of_sp", numSp)
        fd.append("value", value);
        fd.append("name", "general info");
        fd.append("project_type",projType)


        if (tokenStr) {
            const v = tokenStr;
            let v2 = v.replace(/["]+/g, "");
            axios.post(`${APIURL}/api/v1/account/general-info/`, fd, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + v2,
                },
            })
                .then((res) => {
                    if (res.data.status === "success") {
                        setSubmitMsg(
                            res.data.message ? res.data.message : "Successfully added"
                        );
                        setModalShow(true);
                    } else {
                        setErrorMsg(
                            res.data.message ? res.data.message : "Error in submission"
                        );

                        setErrorShow(true);
                    }
                })
                .catch((err) => {
                    setErrorMsg("Error in submission");
                });
        }

    };
    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n) {
            u8arr[n - 1] = bstr.charCodeAt(n - 1)
            n -= 1 // to make eslint happy
        }
        return new File([u8arr], filename, { type: mime })
    }

    const ValidPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 id="confirm-reject">Select a valid Image</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
    const ContentPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 id="confirm-reject">Invalid Image Content</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const updateInfo = () => {
        if (validateUpdation()) {

            const tokenStr = sessionStorage.getItem("usertoken");
            const value1 = {
                name_of_organization: generalInfo.name_of_organization,
                office_email: generalInfo.office_email,
                country: generalInfo.country,
                country_code: generalInfo.country_code,
                mobile_number: generalInfo.mobile_number,
                website: generalInfo.website ? generalInfo.website : "https://www.intpurple.com/",
                official_address: generalInfo.official_address,
                app_name: generalInfo.app_name,
                backoffice_username: generalInfo.backoffice_username,
                backoffice_password: generalInfo.backoffice_password,
                aggregation_type: generalInfo.aggregation_type,
                no_of_sp: generalInfo.no_of_sp,
            
            }
            if (tokenStr) {
                const v = tokenStr;
                let v2 = v.replace(/["]+/g, "");
                const dataToSend = getChangedData(value1);
                let fd = new FormData();
                fd.append("aggregation_type", aggregator ? "aggregator" : "non-aggregator")
                fd.append("no_of_sp", numSp)
                fd.append("project_type",projType)
                if (dataToSend) {
                    Object.keys(dataToSend).forEach(function (key) {
                        fd.append(key, dataToSend[key]);
                    });
                }


                if (window.app_logo !== img_app && window.org_logo !== img_org) {
                    if (img_app === "") {
                        fd.append("app_logo", dataURLtoFile(noimage), "felixa_logo");
                    } else {
                        fd.append("app_logo", img_app);
                    }
                    if (img_org === "") {
                        fd.append("organization_logo", dataURLtoFile(orglogo), "felixa_org")

                    } else {
                        fd.append("organization_logo", img_org)
                    }
                }
                else if (window.app_logo !== img_app) {

                    if (img_app === "") {
                        fd.append("app_logo", dataURLtoFile(noimage), "felixa_logo");
                    } else {
                        fd.append("app_logo", img_app);
                    }

                }
                else if (window.org_logo !== img_org) {
                    if (img_org === "") {
                        fd.append("organization_logo", dataURLtoFile(orglogo), "felixa_org")

                    } else {
                        fd.append("organization_logo", img_org)
                    }

                }
                axios.put(`${APIURL}/api/v1/account/general-info/`, fd, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Token " + v2,
                    },
                })
                    .then((res) => {
                        if (res.data.status === "success") {
                            setSubmitMsg(
                                res.data.message ? res.data.message : "Successfully Updated"
                            );


                            setModalShow(true);
                        } else {
                            setErrorMsg(
                                res.data.message ? res.data.message : "Error in submission"
                            );

                            setErrorShow(true);
                        }
                    })
                    .catch((err) => {
                        setErrorMsg("Error in submission");
                    });
            }
        }
    };
    const handleCheckExistingPhone = (e) => {
        setIsValidPhone(true);
        if (e.target.value !== "") {
            setError((currentstate) => ({
                ...currentstate,
                phone: "",
            }));

            const data = {
                code: generalInfo.mobile_number.dialcode,
                mobile_number: generalInfo.mobile_number.mobile_number,
            };

            axios
                .post(`${APIURL}/api/v1/account/check-user/`, data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        if (res.data.is_existing_user === true) {
                            setError((currentstate) => ({
                                ...currentstate,
                                phone: "This phone number is already registered!",
                            }));
                            setIsValidPhone(false);
                        } else {
                            setIsValidPhone(true);
                        }
                    } else {
                        setIsValidPhone(true);
                    }
                })
                .catch((err) => { });
        }
    };
    const handleCountry = (val) => {
        setGeneralInfo((currentstate) => ({
            ...currentstate,
            country: val,
        }));
    };
    const handleCheckEmail = (e) => {
        setIsValidEmail(true);
        if (e.target.value !== "") {
            setError((currentstate) => ({
                ...currentstate,
                email: "",
            }));
            e.preventDefault();
            let key = e.target.name;

            const data = {
                email: e.target.value,
            };

            axios
                .post(`${APIURL}/api/v1/account/check-user/`, data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        if (res.data.is_existing_user === true) {
                            setError((currentstate) => ({
                                ...currentstate,
                                [key]: `This ${key} is already registered!`,
                            }));
                            setIsValidEmail(false);
                        } else {
                            setIsValidEmail(true);
                        }
                    } else {
                        setIsValidEmail(true);
                    }
                })
                .catch((err) => { });
        }
    };
    const handlePhoneInput = (value, data) => {
        if (value === "") {
            setError((currentstate) => ({
                ...currentstate,
                phone: "Enter mobile number",
            }))
        }
        setCodeLength(data.dialCode.length)
        setGeneralInfo((currentstate) => ({
            ...currentstate,
            mobile_number: value,
            country_code: data.dialCode
        }));
    };
    const validateUpdation = () => {
        let input = generalInfo;
        let errors = {};
        let isValid = true;
        setError({});
        if (!isChange1) {
            if (!input["name_of_organization"] || input["name_of_organization"] === "") {
                isValid = false;
                errors["name_of_organization"] = "Enter name of organization";
            }
            if (typeof input["name_of_organization"] !== "undefined") {
                var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
                if (pattern1.test(input["name_of_organization"])) {
                    isValid = false;
                    errors["name_of_organization"] = "Special Characters are not allowed";
                }
            }

            if (input["name_of_organization"] && typeof input["name_of_organization"] !== "undefined") {
                if (input["name_of_organization"].length > 0 && input["name_of_organization"].length < 3) {
                    isValid = false;
                    errors["name_of_organization"] = "Must contain atleast 3 characters";
                }
            }

        }
        if (!isChange2) {
            if (typeof input["office_email"] !== "undefined") {
                var pattern = new RegExp(
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                );
                if (!pattern.test(input["office_email"])) {
                    isValid = false;
                    errors["email"] = "Enter a valid email address.";
                }
            }
            if (isValidEmail === false) {
                isValid = false;
                errors["email"] = "Email already registered!";
            }
        }
        if (!isChange3) {
            if (!input["country"] || input["country"] === "") {
                isValid = false;
                errors["country"] = "Select a country";
            }
        }
        if (!isChange6) {
            if (!input["official_address"] || input["country"] === "") {
                isValid = false;
                errors["address"] = "Enter address";
            }
        }
        if (!isChange7) {
            if (!input["app_name"] || input["app_name"] === "") {
                isValid = false;
                errors["appname"] = "Enter app name";
            }
        }
        if (!isChange4) {
            if (isValidPhone === false) {
                isValid = false;
                errors["phone"] = "Phone number already registered!";
            }
            if (!input["mobile_number"] || input["mobile_number"] === "") {
                isValid = false;
                errors["phone"] = "Enter mobile number";
            }


        }
        if (!isChange5) {
            if (
                input["website"] !== "" &&
                input["website"] !== null
            ) {
                var regex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
                var pattern3 = new RegExp(regex);
                if (!pattern3.test(input["website"])) {
                    isValid = false;
                    errors["website"] = "Enter a valid url.";

                }
            }
        }

        setError((currentstate) => ({
            ...currentstate,
            ...errors,
        }));

        return isValid;
    }

    const validate = () => {
        let input = generalInfo;
        let errors = {};
        let isValid = true;
        setError({});

        if (!input["name_of_organization"] || input["name_of_organization"] === "") {
            isValid = false;
            errors["name_of_organization"] = "Enter name of organization";
        }


        if (!input["country"] || input["country"] === "") {
            isValid = false;
            errors["country"] = "Select a country";
        }

        if (!input["mobile_number"] || input["mobile_number"] === "") {
            isValid = false;
            errors["phone"] = "Enter mobile number";
        }

        if (typeof input["office_email"] !== "undefined") {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(input["office_email"])) {
                isValid = false;
                errors["email"] = "Enter a valid email address.";
            }
        }

        if (typeof input["name_of_organization"] !== "undefined") {
            var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
            if (pattern1.test(input["name_of_organization"])) {
                isValid = false;
                errors["name_of_organization"] = "Special Characters are not allowed";
            }
        }

        if (input["name_of_organization"] && typeof input["name_of_organization"] !== "undefined") {
            if (input["name_of_organization"].length > 0 && input["name_of_organization"].length < 3) {
                isValid = false;
                errors["name_of_organization"] = "Must contain atleast 3 characters";
            }
        }

        if (!input["backoffice_username"] || input["backoffice_username"] === "" || input["backoffice_username"] === null) {
            isValid = false;
            errors["username"] = "Enter username";
        }
        if (input["backoffice_username"] !== "undefined") {
            var pattern2 = new RegExp(/^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){0,18}[a-zA-Z0-9]$/);
            if (!pattern2.test(input["backoffice_username"]) || input["backoffice_username"].length < 6) {
                isValid = false;
                errors["username"] = "Special Characters and space are not allowed in username and Characters limit is 6-20";
            }
        }

        if (!input["backoffice_password"] || input["backoffice_password"] === "" || input["password"] === null) {
            isValid = false;
            errors["password"] = "Enter password";
        }

        if (!input["confirmPassword"] || input["confirmPassword"] === "" || input["confirmPassword"] === null || typeof input["confirmPassword"] === "undefined") {
            isValid = false;
            errors["confirmpassword"] = "Enter confirm password";
        }
        if (typeof input["backoffice_password"] !== "undefined") {
            let pattern = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,20}/);
            if (!pattern.test(input["backoffice_password"])) {
                isValid = false;
                errors["password"] = "Password must contain at least 1 uppercase, 1 lower case, 1 number, 1 special char(@$!#^%*?&) and don't allow space";

            }
        }

        if (input["backoffice_password"].length > 0 && input["backoffice_password"].length < 8) {
            isValid = false;
            errors["password"] = "Password must contain atleast 8 characters";
        }

        // if (projType==="") {
        //     isValid = false;
        //     errors["password"] = "Password must contain atleast 8 characters";
        // }

        if (input["backoffice_password"] !== input["confirmPassword"]) {
            isValid = false;
            errors["confirmpassword"] = "Passwords don't match";
        }
        if (
            input["website"] !== "" &&
            input["website"] !== null
        ) {
            var regex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
            var pattern3 = new RegExp(regex);
            if (!pattern3.test(input["website"])) {
                isValid = false;
                errors["website"] = "Enter a valid url.";

            }
        }
        if (!input["official_address"] || input["official_address"] === "") {
            isValid = false;
            errors["address"] = "Enter address";
        }
        if (!input["app_name"] || input["app_name"] === "") {
            isValid = false;
            errors["appname"] = "Enter app name";
        }

        if (!input["office_email"]) {
            isValid = false;

            errors["email"] = "Enter email address.";
        }

        if (isValidPhone === false) {
            isValid = false;
            errors["phone"] = "Phone number already registered!";
        }


        if (isValidEmail === false) {
            isValid = false;
            errors["email"] = "Email already registered!";
        }

        setError((currentstate) => ({
            ...currentstate,
            ...errors,
        }));

        return isValid;
    };
    const handleChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;

        setGeneralInfo((currentstate) => ({
            ...currentstate,
            [key]: val,
        }));
    };
    const handleChangeNumSp = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;

        setNumSP(val)
    };

    const handleRadioChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;

       setProjType(val)
    };

    function SubmitPopUp(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className="caps">{submitMsg}</h4>
                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={props.onHide} className="btn btn-primary btn-col">Ok </Button>


                </Modal.Footer>
            </Modal>
        );
    }


    const removePicPreview = (e) => {
        e.preventDefault();
        setPreviewOn(false);
        setImg("")

    };
    const removePicPreviewOrg = (e) => {
        e.preventDefault();
        setPreviewOnorg(false);
        setImg_org("")
    };
    const reader = new FileReader();
    const uploadImage = async (e) => {
        const key = e.target.name;
        const file = e.target.files[0];


        if (!file) {
            setUploadShow(!uploadShow);
           
            return false;
        }
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setValidShow(!validShow);
        
            return false;
        }
        if (file.size > 10e6) {
            setSizeShow(!sizeShow);
            return false;
        }

        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                if (key === "app_logo") {
                    setImg(file);
                }
                else {
                    setImg_org(file);
                }

                setInvalidPic(null);
            };

            img.onerror = () => {
                setPreviewOn(false);
                setContentShow(!contentShow);
                //setInvalidPic('Invalid Image Content')
                return false;
            };
            img.src = e.target.result;
        };

        reader.readAsDataURL(file);
        const url = URL.createObjectURL(file)

        const objectURL = URL.createObjectURL(file);
        if (key === "app_logo") {
            setGeneralInfo((currentstate) => ({
                ...currentstate,
                img_url: objectURL,
            }));
            setImgUrl(url)
            setPreviewOn(true);
        } else {
            setGeneralInfo((currentstate) => ({
                ...currentstate,
                img_url_org: objectURL,
            }));
            setImg_OrgUrl(url)
            setPreviewOnorg(true);
        }


    };


    return (
        <>
            <div className="" style={{ background: "radial-gradient(aliceblue, transparent)" }}>
                {/* <h6 ><i style={{ color: "blue", float: "right", paddingRight: "3%" }} onClick={() => handleRestart()} class="fa fa-refresh fa-lg">Config Restart</i></h6> */}
                <h4 className="title_config"> General Configuration</h4><br />
                <div className="row">
                    <div className="col-2">
                        <div className="side-viewbar1"><br /><br />
                            <h6 style={{ float: "right", marginRight: "5%" }} > General Information</h6><br /><br />
                            <h6 style={{ float: "right", marginRight: "5%", color: "GrayText" }} > Feature</h6>
                        </div>

                    </div>
                    <div className="col-10 row">
                        <div className="col-6 second-part1">

                            <div style={{ display: "flex", paddingLeft: "1%", paddingTop: "3%" }}>
                                <div className='col-6'>
                                    <span className="text-style-here">Name of the organization</span>

                                    <div className="search-div">
                                        <div style={{ display: "flex" }}>
                                            {isData && <button style={{ width: "40px" }} className={isChange1 ? "edit-button " : "save-button"}>
                                                <i onClick={() => { setIsChange1(false) }} className={isChange1 ? "fas fa-pencil-alt" : "fas fa-pencil-alt fa-lg"}></i> {" "}
                                            </button>}
                                            <input disabled={isChange1} value={generalInfo.name_of_organization} name="name_of_organization" type="text" className="boxpwdconfig" onChange={handleChange} ></input>

                                        </div>
                                    </div>

                                    {error.name_of_organization ? (
                                        <div
                                            style={{ zIndex: "auto" }}
                                            className="error-validation-msg"
                                        >
                                            {error.name_of_organization}
                                        </div>
                                    ) : null}
                                </div><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <div className='col-6' >
                                    <span className="text-style-here">Official-Email</span>
                                    <div className="search-div">
                                        <div style={{ display: "flex" }}>
                                            {isData && <button style={{ width: "40px" }} className={isChange2 ? "edit-button " : "save-button"}>
                                                <i onClick={() => { setIsChange2(false) }} className={isChange2 ? "fas fa-pencil-alt" : "fas fa-pencil-alt fa-lg"}></i> {" "}
                                            </button>}
                                            <input disabled={isChange2} value={generalInfo.office_email} name="office_email" type="text" className="boxpwdconfig" onChange={handleChange} onBlur={handleCheckEmail} ></input>
                                        </div>
                                    </div>
                                    {error.email ? (
                                        <div
                                            style={{ zIndex: "auto" }}
                                            className="error-validation-msg"
                                        >
                                            {error.email}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div style={{ display: "flex", paddingLeft: "1%", paddingTop: "3%" }}>
                                <div className='col-6'>
                                    <span className="text-style-here">Select Country of Operation</span>
                                    <div className="search-div">
                                        <div style={{ display: "flex" }}>
                                            {isData && <button style={{ width: "40px" }} className={isChange3 ? "edit-button " : "save-button"}>
                                                <i onClick={() => { setIsChange3(false) }} className={isChange3 ? "fas fa-pencil-alt" : "fas fa-pencil-alt fa-lg"}></i> {" "}
                                            </button>}
                                            <CountryDropdown
                                                value={generalInfo.country}
                                                onChange={(val) => handleCountry(val)}
                                                className="boxpwdconfig"
                                                disabled={isChange3}
                                            />
                                        </div>
                                    </div>
                                    {error.country ? (
                                        <div
                                            style={{ zIndex: "auto" }}
                                            className="error-validation-msg"
                                        >
                                            {error.country}
                                        </div>
                                    ) : null}
                                </div><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <div className='col-6' >
                                    <span className="text-style-here">Official Contact</span>
                                    <div className="search-div">
                                        <div style={{ display: "flex" }}>
                                            {isData && <button style={{ width: "40px" }} className={isChange4 ? "edit-button " : "save-button"}>
                                                <i onClick={() => { setIsChange4(false) }} className={isChange4 ? "fas fa-pencil-alt" : "fas fa-pencil-alt fa-lg"}></i> {" "}
                                            </button>}
                                            <PhoneInput

                                                inputStyle={{ height: '36px !important' }}
                                                inputClass="boxpwd"
                                                name="mobile_number"
                                                disabled={isChange4}
                                                country={"in"}
                                                defaultCountry={generalInfo.country_code}
                                                value={generalInfo.mobile_number}
                                                inputProps={{}}
                                                containerClass="boxpwd"
                                                onChange={handlePhoneInput}
                                                onBlur={handleCheckExistingPhone}


                                            />
                                        </div></div>
                                    {error.phone ? (
                                        <div
                                            style={{ zIndex: "auto" }}
                                            className="error-validation-msg"
                                        >
                                            {error.phone}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div style={{ paddingLeft: "1%", paddingTop: "3%", marginLeft: "1.8%" }}>
                                <div className=''>
                                    <span className="text-style-here">Website</span><br />
                                    <div className="search-div">
                                        <div style={{ display: "flex" }}>
                                            {isData && <button style={{ width: "40px" }} className={isChange5 ? "edit-button " : "save-button"}>
                                                <i onClick={() => { setIsChange5(false) }} className={isChange5 ? "fas fa-pencil-alt" : "fas fa-pencil-alt fa-lg"}></i> {" "}
                                            </button>}
                                            <input placeholder="https://www.example.com" disabled={isChange5} value={generalInfo.website} name="website" type="text" className="boxpwd1" onChange={handleChange} ></input>
                                        </div></div>
                                    {error.website ? (
                                        <div
                                            style={{ zIndex: "auto" }}
                                            className="error-validation-msg"
                                        >
                                            {error.website}
                                        </div>
                                    ) : null}
                                </div>

                            </div>
                            <div style={{ paddingLeft: "1%", paddingTop: "3%", marginLeft: "1.8%" }}>
                                <div className=''>
                                    <span className="text-style-here">Official address</span><br />
                                    <div className="search-div">
                                        <div style={{ display: "flex" }}>
                                            {isData && <button style={{ width: "40px" }} className={isChange6 ? "edit-button1 " : "save-button1"}>
                                                <i onClick={() => { setIsChange6(false) }} className={isChange6 ? "fas fa-pencil-alt" : "fas fa-pencil-alt fa-lg"}></i> {" "}
                                            </button>}
                                            <textarea disabled={isChange6} value={generalInfo.official_address} name="official_address" type="text" className="boxpwd2" onChange={handleChange} ></textarea>
                                        </div></div>
                                    {error.address ? (
                                        <div
                                            style={{ zIndex: "auto" }}
                                            className="error-validation-msg"
                                        >
                                            {error.address}
                                        </div>
                                    ) : null}
                                </div>

                            </div>
                            {isData ? <div style={{ paddingLeft: "1%", paddingTop: "3%", marginLeft: "1.8%" }}>
                                <div className=''>
                                    <span className="text-style-here">Back Office Admin Username</span><br />

                                    <input disabled value={generalInfo.backoffice_username} name="username" type="text" className="boxpwd1"  ></input>

                                </div>

                            </div> : ""}

                        </div>

                        <div style={{ paddingLeft: "3%" }} className="col-5">
                            <span className="text-style-here">App Name</span>
                            <div className="search-div">
                                <div style={{ display: "flex" }}>
                                    {isData && <button style={{ width: "40px" }} className={isChange7 ? "edit-button " : "save-button"}>
                                        <i onClick={() => { setIsChange7(false) }} className={isChange7 ? "fas fa-pencil-alt" : "fas fa-pencil-alt fa-lg"}></i>{" "}
                                    </button>}
                                    <input disabled={isChange7} value={generalInfo.app_name} name="app_name" type="text" className="boxpwd1 " onChange={handleChange}></input>
                                </div>
                                {error.appname ? (
                                    <div
                                        style={{ zIndex: "auto" }}
                                        className="error-validation-msg"
                                    >
                                        {error.appname}
                                    </div>
                                ) : null}</div><br />
                            <div style={{ display: "flex" }}>
                                <div style={{ height: "185px" }}>
                                    <span className="text-style-here">Apps logo</span>
                                    <div className="apps-logobar">
                                        <div className="apps-logobar-inside">
                                            {previewOn === false ? (
                                                <div className="">
                                                    <input

                                                        name="app_logo"
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="validatedCustomFile"
                                                        onChange={uploadImage}
                                                    />
                                                    {invalidPic && <p className="error">{invalidPic}</p>}
                                                    <label className="" htmlFor="validatedCustomFile">

                                                        <div style={{ float: "right" }} className="camera-icon">
                                                            <i className="fas fa-camera cam-icon fa-lg"></i>
                                                        </div>
                                                    </label>


                                                </div>
                                            ) : (
                                                <div>
                                                    {!isChange9 && <div onClick={removePicPreview}>
                                                        <i
                                                            style={{ position: "absolute" }}
                                                            className="fas fa-times-circle"
                                                        ></i>
                                                    </div>}
                                                    <img
                                                        src={imgUrl ? imgUrl : img_app}
                                                        alt=""
                                                        className="avatar1"
                                                    />
                                                </div>
                                            )}</div>
                                    </div>
                                    <button className="apps-logo-bottom"> {isData ? <i onClick={() => { setIsChange9(false) }} className={isChange9 ? "fas fa-pencil-alt" : "fas fa-pencil-alt fa-lg"}></i> : ""}</button>
                                    <h3 style={{ paddingTop: "8%", marginLeft: "8%" }} className="text-style-here"> Size should be 250 x 250 px</h3><br />
                                </div><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <div>
                                    <span className="text-style-here">Organization logo</span>
                                    <div className="apps-logobar">
                                        <div className="apps-logobar-inside">
                                            {previewOnorg === false ? (
                                                <div className="">
                                                    <input
                                                        name="org_logo"
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="validatedCustomFile1"
                                                        onChange={uploadImage}
                                                    />
                                                    {invalidPic && <p className="error">{invalidPic}</p>}
                                                    <label className="" htmlFor="validatedCustomFile1">

                                                        <div style={{ float: "right" }} className="camera-icon">
                                                            <i className="fas fa-camera cam-icon fa-lg"></i>
                                                        </div>
                                                    </label>
                                                </div>
                                            ) : (
                                                <div>
                                                    {!isChange8 && <div onClick={removePicPreviewOrg}>
                                                        <i
                                                            style={{ float: "right", paddingTop: "-5px", position: "absolute" }}
                                                            className="fas fa-times-circle"
                                                        ></i>
                                                    </div>}
                                                    <img
                                                        src={img_OrgUrl ? img_OrgUrl : img_org}
                                                        alt=""
                                                        className="avatar1"
                                                    />
                                                </div>
                                            )}</div>

                                    </div>
                                    <button className="apps-logo-bottom">{isData ? <i onClick={() => { setIsChange8(false) }} className={isChange8 ? "fas fa-pencil-alt" : "fas fa-pencil-alt fa-lg"}></i> : ""}</button>

                                    <h3 style={{ paddingTop: "8%", marginLeft: "8%" }} className="text-style-here"> Size should be 250 x 250 px</h3>
                                </div>
                            </div>
                            <br />
                            <div style={{ marginTop: "0.5%", marginLeft: "1%" }} className="row">
                                <div style={{ display: "flex" }}> <span style={{ marginTop: "2px" }} className="text-style-here">*Project Type: <span>&nbsp;&nbsp;</span></span>

                                    <div className="radio-options">
                                        <input
                                            style={{ marginTop: "1%" }}
                                            type="radio"
                                            id="Hospital"
                                            name="health"
                                            value="home_care"
                                          checked={projType === "home_care"}
                                          onChange={handleRadioChange}
                                        /><span>&nbsp;</span>
                                        <small htmlFor="hospital">
                                            Home Care
                                        </small>

                                        <span>&nbsp;&nbsp;</span>

                                        <input
                                            style={{ marginTop: "1%" }}
                                            type="radio"
                                            id="Hospital"
                                            name="health"
                                            value="elder_care"
                                          checked={projType === "elder_care"}
                                          onChange={handleRadioChange}
                                        /><span>&nbsp;</span>
                                        <small htmlFor="hospital">
                                            Elder Care
                                        </small>

                                        <span>&nbsp;&nbsp;</span>

                                        <input
                                            style={{ marginTop: "1%" }}
                                            type="radio"
                                            id="Pharmacy"
                                            name="health"
                                            value="remote_care"
                                          checked={projType === "remote_care"}
                                          onChange={handleRadioChange}
                                        /><span>&nbsp;</span>
                                        <small htmlFor="Pharmacy">Remote Care</small>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row" style={{ paddingLeft: "3%" }}>
                                <div style={{ textAlign: "center" }} className={aggregator ? "apps-logobar1 col-5 " : "apps-logobar2 col-5 "}>
                                    <h6 className="title_aggr" ><b>Aggregator</b> </h6>
                                    <p style={{ color: aggregator ? "black" : "#C6C6C6" }}>Service providers </p>
                                    <input disabled={isNumEditable} type="number" onChange={handleChangeNumSp} value={numSp} min={1} style={{ backgroundColor: !isNumEditable ? "#7D0752" : "#C6C6C6" }} className="nos"></input>{" "}
                                    <i style={{ color: aggregator ? isChange10 ? "#0350f6a7" : "#077d58e9" : "#C6C6C6" }} onClick={() => { aggregator && setIsChange10(false) }} className={isChange10 ? "fas fa-pencil-alt" : "fas fa-pencil-alt fa-lg"}></i>{" "}
                                </div>
                                <div style={{ marginTop: "2rem", textAlign: "center" }} className="col-2">
                                    <i onClick={() => isChange && setAggregator(!aggregator)} style={{ color: "#7D0752" }} class={aggregator ? "fa fa-toggle-on fa-rotate-180 fa-lg" : "fa fa-toggle-on fa-lg"} aria-hidden="true"></i></div>
                                <div style={{ textAlign: "center" }} className={aggregator ? "apps-logobar2 col-5 " : "apps-logobar1 col-5 "}>
                                    <h6 className="title_aggr" ><b>Non Aggregator</b> </h6>
                                    <p style={{ paddingTop: "0.5rem" }}>Only one Hospital</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div><br />
                {!isData ? <> <h5 className="title_config"> Back Office Admin</h5>
                    <div style={{ display: "flex" }}>
                        <div className="col-2">

                        </div>
                        <div className='col-3'>
                            <span className="text-style-here">Username</span>
                            <input value={generalInfo.backoffice_username} name="backoffice_username" type="text" className="boxpwd" onChange={handleChange} ></input>
                            {error.username ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {error.username}
                                </div>
                            ) : null}
                        </div>
                        <div className='col-3' >
                            <span className="text-style-here">Password</span>

                            <div className="search-div">
                                <div style={{ display: "flex" }}>
                                    <button style={{ backgroundColor: "#96716978" }} className="save-button">
                                        {passwordType === "password" ? <i style={{ color: "#7e7e7e" }} onClick={togglePasswordVisiblity} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity} className="fa fa-eye"></i>}
                                    </button>
                                    <input value={generalInfo.backoffice_password} name="backoffice_password" type={passwordType} className="boxpwd" onChange={handleChange}></input>
                                </div>
                            </div>
                            {error.password ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {error.password}
                                </div>
                            ) : null}
                        </div>
                        <div className='col-3' >
                            <span className="text-style-here">Confirm Password</span>
                            <div className="search-div">
                                <div style={{ display: "flex" }}>
                                    <button style={{ backgroundColor: "#96716978" }} className="save-button">
                                        {passwordType1 === "password" ? <i style={{ color: "#7e7e7e" }} onClick={togglePasswordVisiblity1} className="fa fa-eye-slash"></i> : <i onClick={togglePasswordVisiblity1} className="fa fa-eye"></i>}
                                    </button>
                                    <input value={generalInfo.confirmPassword} name="confirmPassword" type={passwordType1} className="boxpwd" onChange={handleChange} ></input>
                                </div>
                            </div>
                            {error.confirmpassword ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {error.confirmpassword}
                                </div>
                            ) : null}
                        </div>
                    </div></> : ""}<br />

                <div className="" style={{ display: "flex", justifyContent: "center" }}>


                    <span>&nbsp;&nbsp;&nbsp;</span>
                    {/* {isData ? <button onClick={() => { isChange10 ? setIsChange10(false) : updateInfo() }} className={isChange10 ? "edit" : "save"}>{isChange10 ? "Reset Password" : "Save"}</button> :  */}
                    <button onClick={() => { isData ? handleUpdateInfo() : handleContinue() }} className="save">{isData ? "UPDATE" : "SAVE"} </button>
                </div><br /><br />
            </div>
            {submitMsg !== "" ? (
                <SubmitPopUp
                    show={modalShow}
                    onHide={() => {
                        setRefresh(!refresh)
                        setModalShow(false);
                        setIsChange(true)
                        setIsChange1(true)
                        setIsChange2(true)
                        setIsChange3(true)
                        setIsChange4(true)
                        setIsChange5(true)
                        setIsChange6(true)
                        setIsChange7(true)
                        setIsChange8(true)
                        setIsChange9(true)
                        setIsChange10(true)
                        setSubmitMsg("");


                    }}
                />
            ) : null}
            {validShow ? (
                <ValidPopUp
                    show={validShow}
                    onHide={() => {
                        setValidShow(false);
                    }}
                />
            ) : null}
            {sizeShow ? (
                <SizePopUp
                    show={sizeShow}
                    onHide={() => {
                        setSizeShow(false);
                    }}
                />
            ) : null}
            {verifyOTP ? (
                <OtpPopUp
                    show={verifyOTP}
                    onHide={() => {
                        setVerifyOTP(false);
                    }}
                />
            ) : null}
            {contentShow ? (
                <ContentPopUp
                    show={contentShow}
                    onHide={() => {
                        setContentShow(false);
                    }}
                />
            ) : null}
            {errorMsg !== "" ? (
                <ErrorPopUp
                    show={errorShow}
                    onHide={() => {
                        setErrorShow(false);
                        setErrorMsg("");
                    }}
                />
            ) : null}
        </>
    );
}
export default ConfigDash;
