import React ,{useState, useContext} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import './hospprofile.css'
import {Button ,Modal } from 'react-bootstrap';
import {ProfileContext} from '../contexts/ProfileContext'
import { useAlert } from 'react-alert';

function RegUpdate(props) {

    const[regno ,setRegno] =useState(props.regno !== '' ? props.regno : '')
    const alert = useAlert()

    const [error,setError]=useState({
        regno:"",
       });

    const handleChange=(e)=>{
        e.preventDefault();
        const val=e.target.value;  
        const key=e.target.name;
      
          if(key==='regno'){
            setRegno(val)
          }  
        }

        const  validate = () => {
          //let input = email;
          let errors = {};
          let isValid = true;
    
          if (regno==="" ) {
            isValid = false;
            errors["regno"] = "Enter registartion number";
          }
      
          setError(currentstate=>({
            ...currentstate,...errors
          }))
      
          return isValid;
      }

      const handleContinue =async(e) => {
        e.preventDefault();
    
        if( validate() ){
          submitReg()
        }
      }

        const submitReg = (e) =>{
            const tokenString= sessionStorage.getItem('usertoken');
             
             let str= tokenString.replace(/["]+/g, '') 
          
             const data = {
                reg_number : regno
             }
          
             axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/` ,data, { headers: {
          
              "Authorization" : 'Token '+str  }})
            .then(res=>{
            
                  
              if(res.data.status==="success"){
                
                alert.success("Updated successfully")  
                props.handle()      
            
              }else if (res.data.status === "error") {
                alert.error(res.data.message);
              } else {
                alert.error("Error in fetching data!");
              }
            
            })
            .catch(err=>{
              alert.error("Error occured. Try again!")
            
            })
          
          }

    return (
        <div>
            <div className="item"><input  type="text" className="form-control"   name="regno" value={regno} onChange={handleChange}/>

                {error.regno ? <div className="error-validation-msg"> {error.regno}</div> : null}

            </div>
            <br/>
            <div className="form-button">
                <button  onClick={handleContinue} className="btn btn-primary btn-col" >Update Registration Number</button>
            </div>
          </div>
    )
}

export default RegUpdate
