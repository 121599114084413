import React, { useState, useEffect, useRef } from "react";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./service.css";
import { SOCKETURL } from "../../Global";
import { useNavigate, Navigate, Link,useLocation } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Medicines from "./Medicines";
import Consumables from "./Consumables";

function ServiceRequests_doc(props) {
  const { state } = useLocation()
  const [reRender, setRerender] = useState(false);
  const [reRender1, setRerender1] = useState(false);
  const [deletes, setDelete] = useState([]);
  const [render, setRender] = useState(true);
  const [messageType, setMessageType] = useState("");
  const [, setGetError] = useState('');
  const [tableData, setTableData] = useState([]);
  const [medData, setMedData] = useState([]);
  const [dispenseShow, setDispenseShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [medicineShow, setMedicineShow] = useState(state.from==="medHistory"||state.from==="dashboard"?true:false)
  const [consumableShow, setConsumableShow] = useState(state.from==="conshistory"?true:false)
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [displayAlert,setDisplayAlert]=useState(false)
  const webSocket = useRef(null);
  const [tempdata,settempData]=useState([]) 
  const [orderId, serOrderId] = useState()
  let navigate = useNavigate();
  const [keys,] = useState({ 'p': false, 'a': false, 'n': false, 'm': false, 'l': false });
 
  const handlingWebsocket = () => {
    const tokenString = sessionStorage.getItem("usertoken");
    const hospId = sessionStorage.getItem("hospId");
    let str = tokenString.replace(/["]+/g, "");
    webSocket.current = new WebSocket(
      `${SOCKETURL}/ws/pharmacy_request/sp_` +
      hospId +
      "/?token=" +
      str
    );
    webSocket.current.onopen = () => {
      // on connecting, do nothing but log it to the console
      console.log("WebSocket connected inside servicereq ");
    };

    webSocket.current.onmessage = (evt) => {
      // listen to data sent from the websocket server
      const message = JSON.parse(evt.data);
      console.log(message);
      //  console.log(message.message.count);
      if (message.message.type === "consumables") {
        setCount(message.message.count + count);
      } else if (message.message.type === "medicine") {

        setCount1(message.message.count + count1);

      }
      else if (message.message.message_type === "pharmacy_fulfillment") {
        console.log(message)
       setDisplayAlert(true)
        let data = {}
        data={ id: message.message.order_id, pamstatus: message.message.pam_status ,is_deleted:message.message.is_deleted}
        settempData([...tempdata,data])
       
      }
      //setCount(message.message.count);
   
      serOrderId(message.message.order_id ? message.message.order_id : "")
      setMessageType(message.message.type ? message.message.type : message.message.request_type)
      if (message.message.message_type === "order_request") {
        setUpdate(true)

      }


      if (message.message.message_type === "pharmacy_fulfillment") {
        setUpdate(true)
      }
    };

    webSocket.current.onclose = () => {
      console.log("disconnected");
      // automatically try to reconnect on connection loss
    };
  }
useEffect(()=>{
  console.log(tempdata)
  let del=tempdata.filter((item)=>{
    return item.is_deleted===true
  })
 
  let z=[]
  del.map((item)=>{
    z.push(item.id)
    console.log("z",z)
  })
  setDelete(z)
},[tempdata])
  useEffect(() => {

    setTimeout(() => {

      handlingWebsocket();
    }, 10000)
  }, [])

  useEffect(() => { window.scrollTo(0, 0) }, [])

  const reloadTable = (e) => {
    //setMedData([])
    
    setMedicineShow(true);
    setConsumableShow(false);
    setDispenseShow(false)
    
    setCount1(0);
    
    setUpdate(false)
    setRerender(!reRender)
  };
  const reloadTable1 = (e) => {

    // setTableData([])
    setConsumableShow(true)
    setMedicineShow(false);

    
    setCount(0);
    setUpdate(false)
    setRerender(!reRender)


  };

  useEffect(() => {
    setTableData(tableData)
  }, [render, tableData]);

  useEffect(() => {
    setMedData(medData)
  }, [render, medData]);




  const handleMedicine = (e) => {

    reloadTable();
  }

  const handleConsumables = (e) => {

    reloadTable1();
  }

  return (
    <>



      <div className="">

        <div className="text-center">
          <h5 className="" style={{ marginTop: "1%" }}><b>
            Medicine & Consumable Requests
          </b></h5>

          <div className="service-table-holder padding1">
            <div className="" >

              <div class="row" style={{ paddingTop: "4px" }}>
                {/* <div class="col-3">
                </div> */}
                <div class="col-3">

                  <button className="pharmacybuttons" style={{
                    backgroundColor: medicineShow ? "#3C689E" : "#C8C8C8",
                    color: medicineShow ? "white" : "black",
                  }} onClick={(e) => handleMedicine(e)}> Medicine</button><span>&nbsp;&nbsp;&nbsp;</span>



                  <button className="pharmacybuttons" style={{
                    backgroundColor: consumableShow ? "#3C689E" : "#C8C8C8",
                    color: consumableShow ? "white" : "black",
                  }} onClick={(e) => handleConsumables(e)}>  Consumables</button>
                  <br />
                  {/* <h5 className="" style={{ color: "var(--theme-blue)" }}><b> <i class="fa fa-th-large" aria-hidden="true"></i>{" "}Service Fulfillment</b></h5> */}
                </div>

                <div class="col">
{displayAlert&&<span className="" style={{color:"orange"}}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"><path fill="currentColor" d="M11 3a1 1 0 1 0-2 0v1a1 1 0 1 0 2 0V3Zm4.657 2.757a1 1 0 0 0-1.414-1.414l-.707.707a1 1 0 0 0 1.414 1.414l.707-.707ZM18 10a1 1 0 0 1-1 1h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 1 1ZM5.05 6.464A1 1 0 1 0 6.464 5.05l-.707-.707a1 1 0 0 0-1.414 1.414l.707.707ZM5 10a1 1 0 0 1-1 1H3a1 1 0 1 1 0-2h1a1 1 0 0 1 1 1Zm3 6v-1h4v1a2 2 0 1 1-4 0Zm4-2c.015-.34.208-.646.477-.859a4 4 0 1 0-4.954 0c.27.213.462.519.476.859h4.002Z"/></svg>
There have been recent updates in the medicine.<button onClick={(e) =>{setCount1(0);setRerender(!reRender); setDisplayAlert(false)}}
                    type="button"
                    style={{
                      border: "none",
                      
                      curser: "pointer",
                      color: "#007bff",
                    }}
                    className="notification1  btn-link"
                  >
                   
                    Click here

                  </button> to view </span>}
                </div>
                <div class="col">
                  <button onClick={(e) =>{setCount1(0);setDisplayAlert(false);setDelete([]);handleMedicine(e)}}
                    type="button"
                    style={{
                      border: "none",
                      textDecoration: "none",
                      curser: "pointer",
                      color: "#007bff",
                    }}
                    className="notification1  btn-link"
                  >
                    <span
                      style={{ fontSize: 10, curser: "pointer", }}
                      className="badge"
                    >
                      {count1}
                    </span>
                    Update Medicine

                  </button> <span>&nbsp;</span> <button onClick={(e) =>{setCount(0);setDelete([]);handleConsumables(e)}}
                    type="button"
                    style={{
                      border: "none",
                      textDecoration: "none",
                      curser: "pointer",
                      color: "#007bff",
                    }}
                    className="notification1  btn-link"
                  >
                    <span
                      style={{ fontSize: 10, curser: "pointer", }}
                      className="badge"
                    >
                      {count}
                    </span>
                    Update Consumables

                  </button>
                </div>

                {/* <div class="col">
                  <button disabled type="button" style={{ border: "none", textDecoration: "none" }} class=" btn-link"> Save All</button>
                </div> */}
                <div class="col-2">
                  {medicineShow ?
                    <button onClick={() => navigate("/service_history_doc")} type="button" style={{ border: "none", textDecoration: "none", marginRight: "10px" }} class=" btn-link"> Medicine History{" "}<i class="fa fa-angle-right" aria-hidden="true"></i></button>
                    : <button onClick={() => navigate("/service_history_doc1")} type="button" style={{ border: "none", textDecoration: "none", marginRight: "10px" }} class=" btn-link"> Consumables History{" "}<i class="fa fa-angle-right" aria-hidden="true"></i></button>}
                </div>

              </div>

            </div>
            {medicineShow ? <Medicines data={reloadTable} render={reRender}  del={deletes}/> : consumableShow ? <Consumables data={reloadTable1} render={reRender} /> : <Medicines  del={deletes} />}
            {/* { medicineShow ? displayTable : consumableShow ? displayTable1 :displayTable } */}
          </div>


        </div>
      </div>















    </>
  );
}

export default ServiceRequests_doc;
