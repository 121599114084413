import React, { useState, useEffect, useRef } from 'react';
import { Switch } from "antd";
import '../PRO/Dialysis/addMachine.css';
import axios from "axios";
import { APIURL } from "../../Global";
import { useAlert } from 'react-alert';
import { CgCloseR } from "react-icons/cg";
import moment from 'moment';

const AddTherapySlots = (props) => {
    const [selectedSlot, setSelectedSlot] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const startDate = new Date().toISOString().slice(0, 10);
    const endDate = getEndDate(new Date());
    const alert = useAlert();
    const modalContentRef = useRef(null);
    const [data, setData] = useState([]);

    function getEndDate(date) {
        const updated = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 30);
        return updated.toISOString().slice(0, 10);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
            props.onHide();
        }
    };

    useEffect(() => {
        slots();
    }, []);

    const slots = () => {
        const tokenString = sessionStorage.getItem('usertoken');
        let str = tokenString.replace(/["]+/g, '');
        const allied_id = props.alliedId;
        axios
            .get(
                `${APIURL}/api/v1/service-provider/allied-schedule/?start_date=${startDate}&end_date=${endDate}&allied_id=${allied_id}`,
                {
                    headers: {
                        Authorization: 'Token ' + str,
                    }
                }
            )
            .then((res) => {
                if (res.data.status === 'success') {
                    const data = transformData(res.data.message);
                    setData(data);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const transformData = (data) => {
        const groupedData = {};

        data.forEach(schedule => {
            const { start_date, slots, schedule_id } = schedule;
            if (!groupedData[start_date]) {
                groupedData[start_date] = [];
            }

            // Filter slots to remove those with from_time earlier than current time
            const currentTime = moment(); // Current time
            const filteredSlots = slots.filter(slot => {
                const slotDateTime = moment(`${start_date} ${slot.from_time}`, 'YYYY-MM-DD HH:mm:ss');
                return slotDateTime.isSameOrAfter(currentTime);
            });

            // Add filtered slots to groupedData
            filteredSlots.forEach(slot => {
                slot.schedule_id = schedule_id; // Assign schedule_id to slot
                groupedData[start_date].push(slot);
            });
        });

        // Convert groupedData to array format
        return Object.entries(groupedData).map(([date, slots]) => ({ date, slots }));
    };


    const handleSlotChange = (slot, date) => {
        setSelectedSlot(slot);
        setSelectedDate(date);
        console.log(slot)
        console.log(date)
    };

    return (
        <div className='addmachine-modal' style={{ zIndex: 999 }}>
            <div className='addmachine-modal-content' ref={modalContentRef}>
                <div style={{ textAlign: 'right' }}><CgCloseR onClick={props.onHide} style={{ cursor: 'pointer' }} /></div>
                <div className='add-machine-input'>
                    <div className='addmachine-head'>
                        <label>Select Slot</label>
                    </div>
                    <div style={{ height: '300px', width: '100%', overflow: 'auto' }}>
                        {data.map((item, index) => (
                            <div key={index} style={{
                                display: "flex", padding: '15px 0 10px 0',
                                backgroundColor: selectedDate === item.date ? '#e4dd9f7a' : '',
                                borderRadius: '7px'
                            }}>
                                <label className='col-3' style={{
                                    padding: 0,
                                    color: selectedDate === item.date ? '#004280' : ''
                                }}>
                                    <b>{moment(item.date).format('DD/MM/YYYY')}</b>
                                </label>
                                <div className='col-9' style={{ padding: 0, display: "flex" }}>
                                    {item.slots.map((slot, slotIndex) => (
                                        <div
                                            key={slotIndex}
                                            className='therapy-slots'
                                            style={{
                                                flex: 1,
                                                textAlign: 'left',
                                            }}
                                        >
                                            <label>
                                                <input
                                                    type='radio'
                                                    name='slot'
                                                    value={slot.id}
                                                    checked={selectedSlot && selectedSlot.id === slot.id && selectedDate === item.date}
                                                    onChange={() => handleSlotChange(slot, item.date)}
                                                />
                                            </label>&nbsp;
                                            <label style={{
                                                padding: 0,
                                                color: selectedSlot && selectedSlot.id === slot.id && selectedDate === item.date ? '#2B69A3' : ''
                                            }}>
                                                {slot.from_time} - {slot.to_time}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div>
                        <button className='therapy-button' onClick={() => props.onSave(selectedDate, selectedSlot)}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTherapySlots;
