import React, { useState, useEffect } from "react";

function Admitted({ approvedList, onEnvelopClick, onEnquiryClick, onAdmissionClick, handleVerify }) {
    const [itemList, setItemList] = useState([]);
    const [user, setUser] = useState("")
    const userData = JSON.parse(sessionStorage.getItem("logindatas"));
    const role = userData.role

    useEffect(() => {
        if (Array.isArray(approvedList)) {
            setItemList(approvedList);
        } else {
            setItemList([]);
        }
    }, [approvedList]);

    return (
        <div>
            {itemList.length > 0 && (
                <div style={{ display: "flex", marginTop: "3%", width: "62rem" }}>
                    <h6 className="col-4" style={{ marginLeft: "2%" }}></h6>
                    <div className="col-8" style={{ display: "flex" }}>
                        <h6
                            className={role === 'manager' ? 'col-3' : 'col-4'}
                            style={{
                                fontFamily: "inter",
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#6D4482",
                                textAlign: "center",
                                marginLeft: "-1%"
                            }}
                        >
                            Primary
                        </h6>
                        <h6
                            className={role === 'manager' ? 'col-3' : 'col-4'}
                            style={{
                                fontFamily: "inter",
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#6D4482",
                                textAlign: "center",
                                marginLeft: "-1%"
                            }}
                        >
                            Enquiry
                        </h6>
                        <h6
                            className={role === 'manager' ? 'col-3' : 'col-4'}
                            style={{
                                fontFamily: "inter",
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#6D4482",
                                textAlign: "center",
                                marginLeft: "-1%"
                            }}
                        >
                            Admission
                        </h6>
                    </div>
                </div>
            )}
            {itemList.map((item, id) => (
                <div key={id}>
                    <div
                        className="row"
                        onClick={() => setUser(item.pv_enquiry_number)}
                        style={{ display: "flex", width: "62rem" }}
                    >
                        <div
                            className="patientlistview"
                            style={{
                                border: user === item.pv_enquiry_number && "1px solid #6D4482",
                                display: "flex",
                                marginLeft: "2%",
                                marginBottom: "1%"
                            }}
                        >
                            <div className="col-4">
                                <h6
                                    style={{
                                        color: "black",
                                        fontWeight: "700",
                                        fontFamily: "inter",
                                        marginTop: "10px",
                                        marginLeft: "20px",
                                        justifyContent: "center",
                                        textTransform: "capitalize",
                                        fontSize: "16px"
                                    }}
                                >
                                    {`${item.first_name ? item.first_name.charAt(0).toUpperCase() + item.first_name.slice(1).toLowerCase() : item.full_name.charAt(0).toUpperCase() + item.full_name.slice(1).toLowerCase()}`}{" "}
                                    {`${item.last_name ? item.last_name.charAt(0).toUpperCase() + item.last_name.slice(1).toLowerCase() : ""}`}
                                    <br />
                                    <span style={{ fontSize: "x-small", color: "#207170" }}>
                                        <b>{item.pv_enquiry_number}</b>
                                    </span>
                                    <br />
                                </h6>
                            </div>
                            <div className="col-8" style={{ display: "flex" }}>
                                <div className={role === 'manager' ? 'col-3' : 'col-4'}>
                                    <div
                                        style={{
                                            width: "123px",
                                            height: "40px",
                                            border: "1px solid grey",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            margin: "auto",
                                            marginTop: "18px",
                                            alignItems: "center"
                                        }}
                                    >
                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginTop: "4%" }}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="25"
                                                    fill="#5ECB5B"
                                                    className="bi bi-envelope-check-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                                    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                                </svg>
                                            </div>
                                            <div
                                                style={{
                                                    width: "1px",
                                                    backgroundColor: "grey",
                                                    marginLeft: "25px",
                                                    marginTop: "5px"
                                                }}
                                            ></div>
                                            <div
                                                style={{
                                                    marginLeft: "15px",
                                                    marginTop: "4px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => onEnvelopClick(item.pv_enquiry_number)}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="25"
                                                    height="28"
                                                    viewBox="0 0 32 32"
                                                >
                                                    <circle cx="16" cy="16" r="4" fill="#245693" />
                                                    <path
                                                        fill="#245693"
                                                        d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={role === 'manager' ? 'col-3' : 'col-4'}>
                                    <div
                                        style={{
                                            flexDirection: "column",
                                            width: "123px",
                                            height: "40px",
                                            border: "1px solid grey",
                                            margin: "auto",
                                            borderRadius: "5px",
                                            marginTop: "18px",
                                            textAlign: "center",
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginTop: "5px", marginLeft: "5px" }}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="25"
                                                    fill="#5ECB5B"
                                                    className="bi bi-envelope-check-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                                    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                                </svg>
                                            </div>
                                            <div
                                                style={{
                                                    width: "1px",
                                                    backgroundColor: "grey",
                                                    marginLeft: "25px",
                                                    marginTop: "5px"
                                                }}
                                            ></div>
                                            <div
                                                style={{
                                                    marginLeft: "15px",
                                                    marginTop: "4px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => onEnquiryClick(item.pv_enquiry_number)}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="25"
                                                    height="28"
                                                    viewBox="0 0 32 32"
                                                >
                                                    <circle cx="16" cy="16" r="4" fill="#245693" />
                                                    <path
                                                        fill="#245693"
                                                        d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={role === 'manager' ? 'col-3' : 'col-4'}>
                                    <div
                                        style={{
                                            flexDirection: "column",
                                            width: "123px",
                                            height: "40px",
                                            border: "1px solid grey",
                                            margin: "auto",
                                            borderRadius: "5px",
                                            marginTop: "18px",
                                            textAlign: "center",
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                    >
                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginTop: "5px", marginLeft: "5px" }}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="30"
                                                    height="25"
                                                    fill="#5ECB5B"
                                                    className="bi bi-envelope-check-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                                    <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z" />
                                                </svg>
                                            </div>
                                            <div
                                                style={{
                                                    width: "1px",
                                                    backgroundColor: "grey",
                                                    marginLeft: "25px",
                                                    marginTop: "5px"
                                                }}
                                            ></div>
                                            <div
                                                style={{
                                                    marginLeft: "15px",
                                                    marginTop: "4px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => onAdmissionClick(item.pv_enquiry_number)}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="25"
                                                    height="28"
                                                    viewBox="0 0 32 32"
                                                >
                                                    <circle cx="16" cy="16" r="4" fill="#245693" />
                                                    <path
                                                        fill="#245693"
                                                        d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68ZM16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5Z"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {role === "manager" && <div className="col-3" style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                    {item.admission_verified ? <svg xmlns="http://www.w3.org/2000/svg" width="40" height="35" viewBox="0 0 24 24"><path fill="#5ECB5B" d="M24 11.617a6.818 6.818 0 0 1-6.813 6.817H6.816a6.817 6.817 0 1 1 0-13.634h10.366a6.818 6.818 0 0 1 6.817 6.813zm-6.817-4.545a4.542 4.542 0 1 0 0 9.084a4.542 4.542 0 0 0 .002-9.084z" /></svg> :
                                        <svg onClick={() => {
                                            handleVerify(item.pv_enquiry_number);
                                        }} xmlns="http://www.w3.org/2000/svg" width="40" height="35" viewBox="0 0 32 32"><path fill="#5ECB5B" d="M9 7c-.621 0-1.227.066-1.813.188a9.238 9.238 0 0 0-.875.218A9.073 9.073 0 0 0 .72 12.5c-.114.27-.227.531-.313.813A8.848 8.848 0 0 0 0 16c0 .93.145 1.813.406 2.656c.004.008-.004.024 0 .032A9.073 9.073 0 0 0 5.5 24.28c.27.114.531.227.813.313A8.83 8.83 0 0 0 9 24.999h14c4.957 0 9-4.043 9-9s-4.043-9-9-9zm0 2c3.879 0 7 3.121 7 7s-3.121 7-7 7s-7-3.121-7-7c0-.242.008-.484.031-.719A6.985 6.985 0 0 1 9 9m5.625 0H23c3.879 0 7 3.121 7 7s-3.121 7-7 7h-8.375C16.675 21.348 18 18.828 18 16c0-2.828-1.324-5.348-3.375-7" /></svg>}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Admitted;
