import React,{useState, useContext} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
import { ConfirmationContext } from '../../contexts/SubmitConfirmationContext';

function AddObservations1(props) {

    const[data, setData]= useState({
      observationname:'',
      observationtype:'',
      observationcode :''

      
        
    })
    const alert = useAlert(); 
    const [error,setError]=useState({
      observationname:'',
        
       });

    const[errorValidate,setErrorValidate]=useState(false)
    const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
      const handleError = () =>
  {
    setErrorValidate(false);
  }
    
      
     
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


  

  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["observationname"] || input["observationname"]==="" ) {
      isValid = false;
      errors["observationname"] = "Enter observation Name";
    }
    if (!input["observationtype"] || input["observationtype"]==="" ) {
      isValid = false;
      errors["observationtype"] = "Enter observation Type";
    }
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}
const handleContinue = () => {
  if(validate()){ 
    setShowConfirmation(true)
  };
}

const handleConfirm = () => {
  saveChanges();
};

const handleCancel = () => {
  closeConfirmationPopup()
};
  const saveChanges = (e)=> {
    // e.preventDefault();
 ;
 console.log(validate())
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
    observation_name: data.observationname.slice(0,1).toUpperCase() +data.observationname.slice(1, data.observationname.length),                             
    observation_type : data.observationtype,
    observation_code : data.observationcode
   

  }
 
     axios
       .post(`${APIURL}/api/v1/staff/master-observations/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          alert.success('Saved successfully!') 
          props.modalClose()
          setData({
            observationname : '',
            observationtype : '',
            observationcode : ''
         });
         
         } else {
           alert.error('Observation already present')
           closeConfirmationPopup()
         }
       })
       .catch((err) => {
        alert.error('Observation already present')
        closeConfirmationPopup()
       });
}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Add Observations</h4>
        <div style={{ minHeight: 'fit-content', width:'100%' }} className='config-box fit-content'>
            
            <div className='feature-data pad-features'><br/>
                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='align-rental'>*Observation Name : </label>
                    <textarea type='text' className='form-control title-flex' name='observationname' value={data.observationname} onChange={handleTitle} /></div>
                    {error.observationname ? <div className="error-validation-msg error-feature-inventory">{error.observationname}</div> : null}
                </div><br/>

                <div className="formField"style={{display:"flex"}}>
                    <label className='align-rental'>*Observation Type : </label>
                    <input type='text' className='form-control title-flex' name='observationtype' value={data.observationtype} onChange={handleTitle} />
                    {error.observationtype ? <div className="error-validation-msg error-feature-inventory">{error.observationtype}</div> : null}
                </div><br/>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Observation Code : </label>
                    <input type='text' className='form-control title-flex' name='observationcode' value={data.observationcode} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>                      </div>            
        </div>
        <div style={{textAlign:'right'}}>
          <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>Cancel</button>
          <button onClick={handleContinue} className='btn btn-primary btn-col mb-2'>Save</button>   
        </div>
        {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to add the observation?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}
        </>
    )
}

export default AddObservations1
