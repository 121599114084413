
import axios from "axios";
import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import "./deathreport.css";
import SimpleBar from 'simplebar-react';
import PersonalInventory from '../Inventory/PersonalInventory'
import "rsuite/dist/rsuite.min.css";
import 'react-phone-input-2/lib/style.css';
import FormView from "../../Manager/DeathCertificate/FormView";
function Rejected(props) {
   
    const [requests, setRequests] = useState([])
    const [formShow, setFormShow] = useState(false)
    const [invShow, setInvShow] = useState(false)
    const [patientDetails, setPatientDetails] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [info, setInfo] = useState("")
    const [show, setShow] = useState(false);
    useEffect(() => {
        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if(props.person.length>0&&props.person[0].id){
            setRequests(props.person)
        }
        else{
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/service-provider/patient-death/?timeZone=${currentTimezone}`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.data.status === "success") {
                    const filteredData = res.data.message.filter(x => {
                        return x.status === "rejected"
                    })
                    setRequests(filteredData)

                }
            })
            .catch((err) => { });
        }
    }, [refresh]);
  
    const FormPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <FormView patient={patientDetails} />
                   

                </Modal.Body>
            </Modal>
        );
    };
    const InfoPopup = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
                    <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
                        <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
                    </button>
                </Modal.Header>
                <Modal.Body style={{ paddingRight: "7%" }}>
                    <div className='flex-row'>

                        <img src={info} alt="Image" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    const InvPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <PersonalInventory patient={patientDetails} from={"deathrep"}/>
                </Modal.Body>
            </Modal>
        );
    };
 
   
    return (
        <div>
            {requests.length > 0 &&<div className="row">
                <label className='death-text-style-here col-4 ' ></label>
                <label className='death-text-style-here col-2' >Form for the Deceased</label>
                <label className='death-text-style-here col-2' >Personal Inventories</label>
                <label className='death-text-style-here col-2' >Upload Death Report</label>
                <label className='death-text-style-here col-2' >Resubmit</label>
            </div>}
            <SimpleBar style={{ maxHeight: '800px',paddingLeft:"2%",paddingRight:"2%"}}>
            {requests.length > 0 ? requests.map((item) => {
                return (
                    <div className="deathreq_lists row">
                        <div className="col-4" style={{ padding: "1%", fontSize: "18px" }}>{item.patient}&nbsp;&nbsp;&nbsp;{item.patient_name}</div>
                        <div className="col-2" style={{ paddingLeft: "2%",paddingTop:"0.5%" }} >
                            <div style={{ border: "1px solid rgba(205, 205, 205, 1)", borderRadius: "5px", padding: "1%", width: "70%" }} className="row">
                                <div style={{ borderRight: "1px solid rgba(158, 158, 158, 1)" }} className="col-6">


                                    <svg style={{ marginTop: "18%" }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" class="bi bi-envelope-x-fill" viewBox="0 0 16 16">
                                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 4.697v4.974A4.491 4.491 0 0 0 12.5 8a4.49 4.49 0 0 0-1.965.45l-.338-.207L16 4.697Z" />
                                        <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-4.854-1.354a.5.5 0 0 0 0 .708l.647.646-.647.646a.5.5 0 0 0 .708.708l.646-.647.646.647a.5.5 0 0 0 .708-.708l-.647-.646.647-.646a.5.5 0 0 0-.708-.708l-.646.647-.646-.647a.5.5 0 0 0-.708 0Z" />
                                    </svg>

                                </div>
                                <div style={{ paddingTop: "4%" }} className="col-6">
                                    <i onClick={() => { setFormShow(true); setPatientDetails(item) }}
                                        class="fa fa-eye" style={{ fontSize: "23px", marginLeft: "5%", color: "rgba(36, 86, 147, 1)" }} aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-2" onClick={() => { setInvShow(true); setPatientDetails(item) }} style={{ paddingTop: "0.5%", paddingLeft: "5%" }}><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24"><path fill="#2ea098" d="M17.03 6C18.11 6 19 6.88 19 8v11c0 1.13-.89 2-1.97 2c0 .58-.47 1-1.03 1c-.5 0-1-.42-1-1H9c0 .58-.5 1-1 1c-.56 0-1.03-.42-1.03-1C5.89 21 5 20.13 5 19V8c0-1.12.89-2 1.97-2H9V3c0-.58.46-1 1-1h4c.54 0 1 .42 1 1v3h2.03M13.5 6V3.5h-3V6h3M8 9v9h1.5V9H8m6.5 0v9H16V9h-1.5m-3.25 0v9h1.5V9h-1.5Z" /></svg></div>
                        <div className="col-2" style={{ paddingTop: "", paddingLeft: "5%" }}>

                            <i
                                class="fa fa-plus-circle"
                                aria-hidden="true"
                                style={{
                                    fontSize: "25px",
                                    fontWeight: "900",
                                    color: "gray",
                                    width: "44px",
                                    height: "20px",
                                    marginTop: "8%",

                                    cursor: "not-allowed", // Change cursor style
                                }}


                            > </i></div>
                        <div style={{ paddingTop: "0.5%" }} className="col-2">
                            <button
                                onClick={() => props.onSuccess(item) }
                                style={{ width: "80px", height: "35px", borderRadius: "5px",  backgroundColor: "rgb(255, 138, 0)", }}>
                                <i class="fa fa-refresh" aria-hidden="true" style={{marginTop:"5%",color:"white",fontSize:"18px"}}></i></button></div>
                    </div>
                )
            }): <div className="" style={{ marginTop: "10%", color: "red" }}>No Reports!!</div>}
            </SimpleBar>
            {formShow ? (
                <FormPopUp
                    show={formShow}
                    onHide={() => {
                        setFormShow(false);
                    }}
                />
            ) : null}
            {invShow ? (
                <InvPopUp
                    show={invShow}
                    onHide={() => {
                        setInvShow(false);
                    }}
                />
            ) : null}
            {show ? (
                <InfoPopup
                    show={show}
                    onHide={() => {
                        setShow(false);
                    }}
                />
            ) : (
                ""
            )}
        </div>
    )
} export default Rejected;