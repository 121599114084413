import React from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CgCloseR } from "react-icons/cg";

const RemarkPopup = ({ close, content }) => {
    return (
        <div>
            <Modal show={true} centered>
                <Modal.Body style={{ textAlign: "center", overflow: "hidden" }}>
                    <div style={{ textAlign: 'right' }}><CgCloseR onClick={() => { close() }} style={{ cursor: 'pointer' }} /></div>
                    <div><label style={{ fontSize: '20px', fontWeight: 500 }}><b>Remarks</b></label></div>
                    <div style={{ overflow: "auto" }}><label style={{ textTransform: 'capitalize' }}>
                        {content ? content : 'No remarks added'}
                    </label></div>
                </Modal.Body>
            </Modal>
        </div >
    )
}
export default RemarkPopup