import React, { useContext, useState  ,useRef } from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
//import { useHistory } from "react-router-dom";
import './hospprofile.css'
import { Button, Modal } from 'react-bootstrap';
import Map from "./Map";
import { useNavigate } from "react-router-dom";
import { LoginStatusContext } from "../contexts/LoginStatusContext";

function LocationUpdate(props,loc,strt,lon,lat) {
  const [isLoggedIn, setIsLoggedIn] = useContext(LoginStatusContext);
  const navigate = useNavigate();
  const [location, setLocation] = useState(loc)
  const [show, setShow] = useState(false);
  const[street,setStreet]=useState(strt)
  const [modalShow, setModalShow] = useState(false);
 
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubmit, setErrorSubmit] = useState(false);
  const[flag,setFlag]=useState(false)
  const [latitude, setLatitude] = useState(props.lat);
  const [longitude, setLongitude] = useState(props.lon);
  const [isMapError, setIsMapError] = useState(false);
  const [isMapSucess, setIsMapSucess] = useState(false);
  // const history =useHistory();
  const address = useRef(null);
  const [error, setError] = useState({
    location: "",
  });
  const newCoordinates = (lat, lng, address, city) => {
   ;
  lat=Number((lat+"").slice(0,7));
  lng=Number((lng+"").slice(0,7));
   setStreet(address)
    setLocation(city)
    setLatitude(lat);
    setLongitude(lng);
    setError((currentstate) => ({
      ...currentstate,
      address: "",
      street: "",
      location: "",
      pincode: "",
      state: "",
    }));
   
    setLatitude(lat);
    setLongitude(lng);
    setTimeout(() => {
      setIsMapSucess(true);
    }, 300);
    setTimeout(() => {
       setIsMapSucess(true);
    }, 300);
  }


 

  const validate = () => {
    //let input = email;
    let errors = {};
    let isValid = true;

    if (location === "") {
      isValid = false;
      errors["location"] = "Enter a location";
    }

    setError(currentstate => ({
      ...currentstate, ...errors
    }))

    return isValid;
  }
  const callLogout = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/account/logout/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
        } else {
        }
      })
      .catch((err) => { });

    navigate("/login");
  };
  const handleLogout = () => {
    callLogout();
    setIsLoggedIn(false);

    sessionStorage.removeItem("role");
    sessionStorage.removeItem("userid");
    sessionStorage.removeItem("usertoken");
    sessionStorage.removeItem("usertype");
    sessionStorage.removeItem("logindatas");
  };
  const handleContinue = async () => {

    if (validate()) {
      submitAddress()
    }
  }

  const submitAddress = (e) => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    const data = {
      location: location,
      street:street,
      longitude:longitude,
      latitude:latitude

    }

    axios.put(`${APIURL}/api/v1/service-provider/hospital-profile/`, data, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          setFlag(false)
         
          setShow(true)
         


        } else {
          setErrorMsg(res.data.message);
          setErrorSubmit(true);


        }

      })
      .catch(err => {
        setErrorMsg("error");

        setErrorSubmit(true);

      })

  }

  const ConfirmPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h4 class="updated-message">You will be Logged Out after Updating Hospital Location. Are you sure to Continue ?</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>No</Button>
          <Button variant="danger" onClick={props.onCall}>Yes</Button>

        </Modal.Footer>
      </Modal>
    )
  }


  const SuccessPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 class="updated-message">Updated Successfully</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const setMapError = (lat, lng) => {
    setIsMapError(true);
    setLatitude(lat);
    setLongitude(lng);
  }
  const MapError = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>Please Select Address within India</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const MapSuccess = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ }}>You have selected the following Address</h5>
          <br/>
            <label className="">Latitude: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{latitude}</span><br/>
            <label className="">Longitude: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{longitude}</span><br/>
            <label className="">Address: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{street}</span><br/>
            <label className="">Location/City: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{location}</span><br/>
            {/* <label className="">State: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.state}</span><br/>
            <label className="">Pincode: </label> &nbsp; <span style={{fontSize: 16, fontWeight: 'bold'}}>{hospitalDatas.pincode}</span> */}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const SubmitErrorPopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 class="updated-message" style={{ color: "red" }}>Error in Data Submission. Please try again! </h5>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>




        </Modal.Footer>
      </Modal>
    );
  }



  return (
    <div>
      <div className="item">
         
      <Map
                    google={props.google}
                    ref={address}
                    center={{lat:latitude, lng:longitude}}
                    height={300}
                    sendBack={newCoordinates}
                    setError={setMapError}
                    zoom={15}
                  />
        {error.location ? <div className="error-validation-msg"> {error.location}</div> : null}

      </div>
      <br />
      <div className="form-button">
        <button disabled={!flag} onClick={() => setModalShow(true)} className="btn btn-primary btn-col" >Update Location</button>
      </div>

      {
        modalShow ?
          <ConfirmPopUp
            show={modalShow}
            onCall={() => { handleContinue(); setModalShow(false) }}
            onHide={() => setModalShow(false)} /> : null
      }

      {
        show ?
          <SuccessPopUp
            show={show}
            onHide={() => {
              setShow(false)
              handleLogout();
            }} />
          : null
      }
  {isMapError ? (
        <MapError
          show={isMapError}
          onHide={() => {
            setIsMapError(false);
          }}
          />
      ) : null}
        {isMapSucess ? (
        <MapSuccess
          show={isMapSucess}
          onHide={() => {
            setFlag(true)
            setIsMapSucess(false);
          }}
          />
      ) : null}
      {errorMsg !== '' ?
        <SubmitErrorPopup
          show={errorSubmit}
          onHide={() => {
            setErrorSubmit(false);
            setErrorMsg('');
          }}

        /> : ''
      }


    </div>
  )
}

export default LocationUpdate
