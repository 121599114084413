
import React, { Component } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useContext, useState, useEffect, useRef } from "react";
import { Nav } from "react-bootstrap";

import logo from "../../assets/img/reactlogo.png";
import { Navbar, Container,  Dropdown, Button } from "react-bootstrap";
import ControlledExpandedNode from "../../views/ControlledExpandedNode";
function Sidebar({ color, image, callid,onSelect }) {
  const location = useLocation();
  const [selected,setSelected]=useState("treatment")
  const [name,setName]=useState("Organization")
  
  const onSuccess = (data) => {
   setName(data.name)

   callid(data.id)
  };
  return (
    <div className="sidebar" style={{borderRight:"1px solid rgb(43, 147, 160)"}} data-image={image} data-color="white">
      <div
        className="sidebar-background"
      
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
         
            <div className="logo-img" style={{paddingLeft:"30px"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="black" d="M3 21v-2l2-2v4H3Zm4 0v-6l2-2v8H7Zm4 0v-8l2 2.025V21h-2Zm4 0v-5.975l2-2V21h-2Zm4 0V11l2-2v12h-2ZM3 15.825V13l7-7l4 4l7-7v2.825l-7 7l-4-4l-7 7Z"/></svg>
            </div>
          
            <h4
                        style={{
                            color: "#245693",
                            marginTop: "8%",
                            fontFamily: "inter",
                            fontWeight: "700",
                            paddingLeft:"20px"
                           
                        }}
                    >
                       Monitor
                    </h4>
         
        </div>
        
        <Nav>
        <Dropdown as={Nav.Item}>
                      <Dropdown.Toggle
                        as={Nav.Link}
                        data-toggle="dropdown"
                        id="dropdown-67443507"
                        variant="default"
                        className="m-0"
                      >
          <div className="icons-right" style={{display:"flex",width:"100%"}}>
            <svg style={{marginLeft:"1%"}}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><path fill="currentColor" d="M440 424V88h-88V13.005L88 58.522V424H16v32h86.9L352 490.358V120h56v336h88v-32Zm-120 29.642l-200-27.586V85.478L320 51Z"/><path fill="currentColor" d="M256 232h32v64h-32z"/></svg>&nbsp;&nbsp;&nbsp;
            <h6 style={{paddingTop:"2%",textTransform:"capitalize", fontWeight:"normal"}}>{name}</h6></div>
          </Dropdown.Toggle>
                      <Dropdown.Menu style={{width:"100%",}}>
                        <ControlledExpandedNode  onSuccess={onSuccess}/>
                        </Dropdown.Menu>
                        </Dropdown>
          
                <li 
                 className={selected==="services"&&
                 " active active-pro"
                  
               }
                 onClick={() =>{
                  onSelect("services");setSelected("services")
                }}
                
                  
                >
                  <NavLink
                    to=""
                    className="nav-link patientRecbutton"
                    style={{backgroundColor:selected==="services"&&"#024851 "}}
                    activeClassName="activeRoute"
                  ><b>
                    <i style={{color:selected==="services"&&"white "}} className="nc-icon nc-grid-45" />
                    <p style={{color:selected==="services"&&"white "}}>Services</p></b>
                  </NavLink>
                </li>
                
                <li
                 onClick={() =>{
                  onSelect("vitals");setSelected("vitals")
                }}
                className={selected==="vitals"&&
                " active active-pro"
                 
              }
                  
                >
                  <NavLink
                    to=""
                    className="nav-link patientRecbutton"
                    style={{backgroundColor:selected==="vitals"&&"#024851 "}}
                    activeClassName="activeRoute"
                   
                  >
                    <i  style={{color:selected==="vitals"&&"white "}}className="nc-icon nc-preferences-circle-rotate" />
                    <p style={{color:selected==="vitals"&&"white "}}>Vitals</p>
                  </NavLink>
                </li>
                
                <li
                 onClick={() =>{
                  onSelect("treatment");setSelected("treatment")
                }}
                  className={selected==="treatment"&&
                    " active active-pro"
                     
                  }
                  
                >
                  <NavLink
              
                    to=""
                    className="nav-link patientRecbutton"
                    activeClassName="icons-right"
                    style={{backgroundColor:selected==="treatment"&&"#024851 "}}
                  >
                    <i style={{color:selected==="treatment"&&"white "}}className="nc-icon nc-chart-bar-32" />
                    <p  style={{color:selected==="treatment"&&"white "}}>Treatment Plan</p>
                  </NavLink>
                </li>
                <li
                  onClick={() =>{
                    onSelect("pharmacy");setSelected("pharmacy")
                  }}
                  className={selected==="pharmacy"&&
                  " active active-pro"
                   
                }
                >
                  <NavLink
                    to=""
                    className="nav-link patientRecbutton"
                    activeClassName="activeRoute"
                    style={{backgroundColor:selected==="pharmacy"&&"#024851 "}}
                  >
                    <i style={{color:selected==="pharmacy"&&"white "}} className="nc-icon nc-layers-3" />
                    <p style={{color:selected==="pharmacy"&&"white "}}>Pharmacy</p>
                  </NavLink>
                </li>
                <li
                  onClick={() =>{
                    onSelect("general");setSelected("general")
                  }}
                  className={selected==="general"&&
                  " active active-pro"
                   
                }
                >
                  <NavLink
                    to=""
                    className="nav-link patientRecbutton"
                    activeClassName="activeRoute"
                    style={{backgroundColor:selected==="general"&&"#024851 "}}
                  >
                    <i style={{color:selected==="general"&&"white "}} className="nc-icon nc-support-17" />
                    <p style={{color:selected==="general"&&"white "}}>General</p>
                  </NavLink>
                </li>
                <li
                  onClick={() =>{
                    onSelect("health");setSelected("health")
                  }}
                  className={selected==="health"&&
                  " active active-pro"
                   
                }
                >
                  <NavLink
                    to=""
                    className="nav-link patientRecbutton"
                    activeClassName="active"
                    style={{backgroundColor:selected==="health"&&"#024851 "}}
                  >
                    <i  style={{color:selected==="health"&&"white "}}className="nc-icon nc-satisfied" />
                    <p style={{color:selected==="health"&&"white "}}>Health</p>
                  </NavLink>
                </li>
                
                <li
                  onClick={() =>{
                    onSelect("care");setSelected("care")
                  }}
                  className={selected==="care"?
                  " active active-pro icons-right":"icons-right"
                   
                }
                style={{backgroundColor:selected==="care"&&"#024851 "}}
                >
                    <NavLink
                  
                  to=""
                  className="nav-link patientRecbutton"
                  activeClassName="active"
                  >
                    <p   style={{color:selected==="care"&&"white "}}><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 64 64"><path fill="currentColor" d="M30.825 14.252h1.959v-1.629h1.63v-1.958h-1.63V9.03h-1.959v1.635h-1.63v1.958h1.63z"/><path fill="currentColor" d="M55.132.673H8.558C3.994.673.282 4.386.282 8.951v46.567c0 4.565 3.713 8.279 8.276 8.279h46.574c4.564 0 8.276-3.713 8.276-8.279V8.951c0-4.564-3.712-8.278-8.276-8.278M31.818 7.938c2.794 0 7.37 1.175 9.922 3.963l-3.435 6.592a8.175 8.175 0 0 0-6.486-3.187a8.167 8.167 0 0 0-6.432 3.122l-3.502-6.38c2.949-2.948 7.019-4.11 9.934-4.11zm6.295 15.304a6.302 6.302 0 0 1-6.305 6.297a6.297 6.297 0 0 1 0-12.594a6.302 6.302 0 0 1 6.305 6.297M19.545 36.789c.572-1.999 3.099-5.544 7.47-5.565l9.824.009c4.273.105 6.749 3.581 7.314 5.557l3.615 11.962h-5.297l-2.307-7.895H38.55l2.293 7.895h-18l2.303-7.895h-1.613l-2.302 7.895h-5.372l3.685-11.963zm35.827 18.963H8.253v-4.483h47.118z"/></svg>
                    &nbsp;&nbsp;Care Touch</p>
                  </NavLink>
                </li>
                
               
        </Nav>
      
      </div>
    </div>
  );
}

export default Sidebar;
