import React, { useState, useEffect, useContext } from "react";
import { Link,useNavigate,useParams } from "react-router-dom";
import "./hospitalupdatebdo.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
//import BackButton  from "../common/BackButton";
import axios from 'axios';
//import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import Select from "react-select";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { APIURL } from "../../Global";
import { useAlert } from "react-alert";
import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../common/SubmitConfirm";

function HospitalUpdateManager(props) {
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
  const [modalShow, setModalShow] = useState(false);
  const [bdoDatas, setBdoDatas] = useState({
    name: "",

    phone: "",
    email: "",

    specializations: [],
    qualification: [],
    department: [],
    id: ''


  })

  const [error, setError] = useState({
    email: '',
    phone: "",
  });
  const alert = useAlert();
  const navigate = useNavigate();
  const [submitMsg, setSubmitMsg] = useState("");
  const [submitSuccessMsg, setSubmitSuccessMsg] = useState("");
  const [rawPhone, setRawPhone] = useState("");
  const [dialCode, setDialCode] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [department, setDepartment] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([])
  const {id} = useParams();

  
  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
  
    let str = tokenString.replace(/["]+/g, "");
    axios
  
      .get(`${APIURL}/api/v1/service-provider/master-departments/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          let data = []
          res.data.length > 0 && res.data.map((item) => {
            data = ([...data, { value: item.id, label: item.department_name }])
          })
          setDepartmentOptions(data)
  
        } else {
          alert.error(res.data.message);
        }
      });
  }, []);
  
  useEffect(() => {
    if (departmentOptions.length > 0){
    const tokenStr = sessionStorage.getItem('usertoken');

    if (tokenStr) {

      const v = tokenStr;
      let v2 = v.replace(/["]+/g, '');
      axios.get(`${APIURL}/api/v1/service-provider/manager/${id}/`, {
        
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + v2
        }
      })
        .then(res => {       

          if (res.data.status === "success") {

            if (res.data.manager && res.data.manager.phone && !res.data.manager.phone.startsWith('91')) {
              res.data.manager.phone = '91' + res.data.manager.phone;
            }
            const Data = res.data.manager;
            if (Data.department && Array.isArray(Data.department)) {
              const selectedDeps = mapSelectedDepartment(Data.department);
              setDepartment(selectedDeps);
            }
            setBdoDatas(Data);
            window.original_value = Data;
          } else if (res.data.status === "error") {

            setSubmitMsg(res.data.message)

          } else {

            setSubmitMsg("Error in fetching data!")

          }

        })
        .catch(err => {
          setSubmitMsg("Error in fetching data!")

        })

    }}

  }, [id, departmentOptions])

  const mapSelectedOptions = (selectedSpecs, options) => {
    return selectedSpecs.map((selectedSpec) => {
      const foundOption = options.find((option) => option.label === selectedSpec);
      if (foundOption) {
        return foundOption;
      }
      return { value: selectedSpec, label: selectedSpec };
    });
  };
  
  const mapSelectedDepartment = (selectedSpecs) => {
    return mapSelectedOptions(selectedSpecs, departmentOptions);
  };
  
  const handleMultiSelectChange = (selectedOptions, stateSetter, stateName, valueType) => {
    setError(currentState => ({
      ...currentState,
      [stateName]: ""
    }));
  
    if (!selectedOptions) {
      stateSetter([]);
      setBdoDatas(current => ({ ...current, [stateName]: [] }));
      return;
    }
  
    const selectedValues = selectedOptions.map(option => valueType === 'label' ? option.label : option.value);  
    stateSetter(selectedOptions);  
    setBdoDatas(current => ({
      ...current,
      [stateName]: selectedValues
    }));
  };


  const getChangedData = (bdoDatas) => {
    let objectByKeys = {};
    if (window.original_value) {
      let sda_changed_keys = Object.keys(bdoDatas).filter(key => bdoDatas[key] !== window.original_value[key]);
      sda_changed_keys.forEach(key => {
        objectByKeys[key] = bdoDatas[key]
      });
      delete window.original_value;
    }
    return objectByKeys;
  }

  const handleSubmit = () => {

    const tokenStr = sessionStorage.getItem('usertoken');

    if (tokenStr) {

      const v = tokenStr;
      let v2 = v.replace(/["]+/g, '');



      let dataToSend = getChangedData(bdoDatas);


      axios.put(`${APIURL}/api/v1/service-provider/manager/${id}/`, dataToSend, {
        headers: {
          'Content-Type': 'application/json',
          Accept: "application/json",
          Authorization: 'Token ' + v2
        }
      })
        .then(res => {
          if (res.data.status === "success") {
            alert.success('Updated successfully!');
            navigate('/manager-list');

          } else if (res.data.status === "error") {

            alert.error(res.data.message);
          }
          else {

            alert.error("Error in data update!");
          }


        })
        .catch(err => {
          alert.error("Error in data update!");

        })



    }




  }

  const handleCheckEmail = (e) => {
    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError(currentstate => ({
        ...currentstate,
        email: ''
      }));
      e.preventDefault();
      let key = e.target.name;


      const data = {
        [e.target.name]: e.target.value
      }

      axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {

          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {

              setError(currentstate => ({
                ...currentstate,
                [key]: `This ${key} is already registered!`
              }));
              setIsValidEmail(false);
            }
            else {

              setIsValidEmail(true);

            }


          }
          else {

            setIsValidEmail(true);

          }

        })
        .catch(err => {


        })
    }
  }


  const handleCheckExistingPhone = (e) => {
    setIsValidPhone(true);
    if (e.target.value !== "") {

      setError(currentstate => ({
        ...currentstate,
        phone: ''
      }));



      const data = {
        code: dialCode,
        mobile_number: rawPhone
      }

      axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {

          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {

              setError(currentstate => ({
                ...currentstate,
                phone: "This phone number is already registered!"
              }));
              setIsValidPhone(false);
            }
            else {

              setIsValidPhone(true);

            }



          }
          else {

            setIsValidPhone(true);

          }

        })
        .catch(err => {


        })

    }


  }

  const handleContinue= async (e)=>{
    e.preventDefault();   
    if(validate() && isValidEmail && isValidPhone){
      setShowConfirmation(true);
    }
  }
  const handleConfirm = () => {    
    handleSubmit();
  };
  const handleCancel = () => {
    closeConfirmationPopup()
  };

  const handleChange = e => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    setBdoDatas(currentstate => ({
      ...currentstate,
      [key]: val
    }));

  }

  const handleQualification = e => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    let arr = [];
    if (val && val.includes(',')) {
      arr = val.split(',');
    }
    else {
      arr.push(val);
    }
    setBdoDatas(currentstate => ({
      ...currentstate,
      [key]: arr
    }));

  }

  const handlePhoneInput = (value, data, event, formattedValue) => {


    setRawPhone(value.slice(data.dialCode.length));
    setDialCode(value.slice(0, data.dialCode.length))
    setBdoDatas(currentstate => ({
      ...currentstate,
      phone: value

    }));
  }


  const validate = () => {

    let input = bdoDatas
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["name"] || input["name"] === "") {
      isValid = false;
      errors["name"] = "Enter name";
    }
    if (!input['qualification'] || input['qualification'].length === 0 || input['qualification'][0] === "") {
      isValid = false;
      errors["qualification"] = "Enter qualification";
    }
    if (!input['department'] || input['department'].length === 0 || input['department'][0] === "") {
      isValid = false;
      errors["department"] = "Select department";
    }
    if (!input['phone'] || input['phone'] === "") {
      isValid = false;
      errors["phone"] = "Enter phone number";
    }
    if (rawPhone.length > 0 && rawPhone.length < 10) {
      isValid = false;
      errors["phone"] = "Please enter valid phone number ";
    }
    if (typeof input["email"] !== "undefined") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Enter a valid email address.";
      }
    }

    if (typeof input["name"] !== "undefined") {

      var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern1.test(input["name"])) {
        isValid = false;
        errors["name"] = "Special Characters are not allowed";
      }
    }

    if (!input["email"]) {
      isValid = false;

      errors["email"] = "Enter email address.";
    }

    if (isValidPhone === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }
    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
    }

    setError(currentstate => ({
      ...currentstate,
      ...errors
    }));
   
    return isValid;

  }

  return (<div className="add-dr-page" >
 


    <div className="add-dr-form-holder">
   

      <div className="quick-links">
        <Link to="/manager-list"><button type="button" className="btn btn-primary btn-col dr-btn btn-custom"> Goto Manager List </button></Link>

      </div>
      <form className="add-dr-form" onSubmit={handleContinue}>
        <h4 className="title-of-page"> Update Manager details</h4>
        <div className="add-dr-div">
          <div className="form-group col-lg-6">
            <label > *Full Name: </label>
            <input className="form-control" type="text" maxLength='30' name="name" value={bdoDatas.name} onChange={handleChange} />
            {error.name ? <div style={{ zIndex: 'auto' }} className="error-validation-msg">{error.name}</div> : null}
          </div>

          <div className="form-group col-lg-6">
            <label> *Email: </label>
            <input className="form-control " type="email" maxLength='50' value={bdoDatas.email} name="email" onChange={handleChange} onBlur={handleCheckEmail} />
            {error.email ? <div style={{ zIndex: 'auto' }} className="error-validation-msg"> {error.email}</div> : null}
          </div>
          <div className="form-group col-lg-6">
            <label> *Phone Num: </label>
            {/*  <input className="form-control " type="text" value={doctorDatas.phone} name="phone" onChange={handleChange} required/>
           */}
            {/* value={this.state.phone}  */}

            <PhoneInput
              inputProps={{


              }}
              country={"in"}
              onlyCountries={['in']}
              countryCodeEditable={false}
              value={bdoDatas.phone}
              onChange={handlePhoneInput}
              
              onBlur={handleCheckExistingPhone}

            />

            {error.phone ? <div style={{ zIndex: 'auto' }} className="error-validation-msg"> {error.phone}</div> : null}

          </div>



          <div className="form-group col-lg-6">


            <label> *Qualification: </label>
            <input className="form-control" type="text" maxLength='150' value={bdoDatas.qualification} name="qualification" onChange={handleQualification} />
            {error.qualification ? <div style={{ zIndex: 'auto' }} className="error-validation-msg">{error.qualification}</div> : null}
          </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Access Departments: </label>
              <Select
                styles={{
                  control: (vided) => ({
                    ...vided,
                    minHeight: '44px',
                    border: '1px solid #E3E3E3',
                  })
                }}
                isMulti
                value={department}
                onChange={(data, e) => 
                  handleMultiSelectChange(data, setDepartment, 'department', 'value')
                }
                options={departmentOptions}
                name="department"
              />
              {error.department ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.department}
                </div>
              ) : null}
            </div>
        </div>
        <button type="submit" className="btn btn-primary btn-col"> Submit Data</button>
      </form>
    </div>
    {showConfirmation && (
      <SubmitConfirmationPopup
        message="Are you sure you want to submit the form?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    )}
  </div>
  )
}

export default HospitalUpdateManager;

