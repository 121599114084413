import React, { useEffect,useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

import {APIURL} from "../../Global";
import "./emailconfirmation.css";
export default function EmailNotification(props) {


  const [errorMsg,setErrorMsg] =useState('');
  const [errorShow,setErrorShow]=useState(false);
  const [successMsg,setSuccessMsg]=useState('');
  const  [successShow,setSuccessShow]=useState(false);
 
  const params = new URLSearchParams(window.location.search);
const email = params.get("email");

const token = params.get("token");


  useEffect(()=>{

          
          const dataToSend={
            email,
            token : token
          }
    
  
    axios.post(`${APIURL}/api/v1/account/confirm-email/`,dataToSend)
    .then(res=>{
     

   
   
      if(res.data.status==="success"){

   setSuccessMsg(res.data.message);
   setSuccessShow(true);
      }
        else if(res.data.status==="error"){
          setErrorShow(true);
        setErrorMsg(res.data.message);
      }
       else {
          setErrorShow(true);
        setErrorMsg("Error in Email Confirmation");
      }


    })
    .catch(err=>{
      setErrorShow(true);
  setErrorMsg("Error in Email confirmation");
})


},[]);


 



const SuccessPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"green"}}>{successMsg} </h5>
        
        
     
       
      </Modal.Body>
      <Modal.Footer>
        <Link to="/login"> <Button className='btn btn-primary btn-col' > Login</Button></Link>
         <Button variant="secondary" onClick={props.onHide}> Close</Button>

         

       
       

      </Modal.Footer>
    </Modal>
  );
}

  
  const ErrorPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <h5 style={{color:"red"}}>{errorMsg} </h5>
        
        
     
       
      </Modal.Body>
      <Modal.Footer>
         
         <Button variant="secondary" onClick={props.onHide}> Close </Button>

         

       
       

      </Modal.Footer>
    </Modal>
  );
}
  
return (<>

 <div className="container "> <br/><br/>
 <div className="email-confirmation-holder ">
<h2 className="title-of-tasks"> Email Confirmation </h2>

<br/>
<br/>
<div style={{textAlign: 'center'}}>
<Link to="/login"><h5 > Go to Login </h5></Link>
<h4 className="title-of-tasks">* Patients can login through  only APP </h4>
</div>


</div>
  {successMsg !=="" ?
    <SuccessPopup
        show={successShow}
       
        onHide={() => { setSuccessShow(false);
                        setSuccessMsg('');
                         }}
       
      />: ''}

    {errorMsg !=="" ?
    <ErrorPopup
        show={errorShow}
       
        onHide={() => { setErrorShow(false);
                        setErrorMsg('');
                         }}
       
      />: ''}


</div>
  </>)
  

}