import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import './profile.css'
import { Button, Modal } from 'react-bootstrap';
import { useAlert } from 'react-alert';

function MailUpdate(props) {
  const [email, setEmail] = useState(props.email !== " " ? props.email : " ");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const alert = useAlert()
  const [error, setError] = useState({
    username: "",
    email: '',
    phone: ""
  });

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    if (key === 'email') {
      setEmail(val)
    }
  }

  const handleCheckExisting = () => {

    if (email !== "") {
      setError(currentstate => ({
        ...currentstate,
        email: ''
      }));
    
      


      const data = {
        email:email
      }

      axios.post(`${APIURL}/api/v1/account/check-user/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {

          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {

              setError(currentstate => ({
                ...currentstate,
                email: `This Email is already registered!`
              }));
              setIsValidEmail(false);
            }
            else {
              setIsValidEmail(true);
            }


          }
          else {



          }

        })
        .catch(err => {


        })
    }

  }

  const validate = () => {
    //let input = email;
    let errors = {};
    let isValid = true;


    if (!email) {
      isValid = false;
      errors["email"] = "Enter  email Address.";
    }

    if (email !== "undefined") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(email)) {
        isValid = false;
        errors["email"] = "Enter valid email address.";
      }
    }

    setError(currentstate => ({
      ...currentstate, ...errors
    }))

    return isValid;
  }

  const handleContinue = async () => {
    if (validate() && isValidEmail) {
      submitMail()
    }
  }


  const submitMail = (e) => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    const data = {
      email: email
    }

    axios.put(`${APIURL}/api/v1/account/profile/`, data, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {

          alert.success("updated successfully")
          props.handle()
          setEmail()

        } else {
          alert.error("Error in Data Submission. Please try again!")           
        }

      })
      .catch(err => {
        alert.error("Error in Data Submission. Please try again!")           
      })

  }

  return (
    <div>
      <div className='row'>
        <div className='col-8'>
          <div> <input className="form-control" type="email" name="email" placeholder="E-mail Address*" value={email} onChange={handleChange} />

            {error.email ? <div className="error-validation-msg"> {error.email}</div> : null}</div>
        
        </div>
        <button style={{height:"40px"}}className=" btn-info btn-col col-3" onClick={() => handleCheckExisting()}> Validate</button>
</div>
        <br />
        <div className="form-button">
          <button disabled={!isValidEmail} style={{ cursor:isValidEmail? "pointer":"not-allowed" }} onClick={handleContinue} className="btn btn-primary btn-col" >Update Email</button>
        </div>
      </div>
      )
}

export default MailUpdate
