import React, { useState, useEffect } from 'react';
import './csc.css';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { APIURL } from '../../../Global';
import Calendar from 'react-calendar';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { useAlert } from 'react-alert';
import OpBookingConfirmPopup from './OpBookingConfirmPopup';
import OpBookedPopup from './OpBookedPopup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import SubmitConfirmationPopup from "../../common/SubmitConfirm";

function PhysioOp({ selectedPatient, setSelectedPatient, selectedAllied, setSelectedAllied, selected }) {
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedDayForCalendar, setSelectedDayForCalendar] = useState('');
    const [display, setDisplay] = useState(false);
    const [scheduleId, setScheduleId] = useState('')
    const [bookInfo, setBookInfo] = useState({})
    const [confirmationData, setConfirmationData] = useState({});
    const [booked, setBooked] = useState(false);
    const [data, setData] = useState([]);
    const [slots, setSlots] = useState([]);
    const startDate = new Date().toISOString().slice(0, 10);
    const [popStateEventFired, setPopStateEventFired] = useState(false);
    const [showBackPopup, setShowBackPopup] = useState(false);
    const [highlightedDaysCount, setHighlightedDaysCount] = useState(0);
    const endDate = getEndDate(new Date());
    const [onload, setOnload] = useState(false)
    const alert = useAlert();
    const navigate = useNavigate()

    function getEndDate(date) {
        const updated = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 30);
        return updated.toISOString().slice(0, 10);
    }

    const handlePopState = (e) => {
        if (!popStateEventFired) {
            console.log('User clicked back button');
            setPopStateEventFired(true);
            setShowBackPopup(true);
        }
    };

    useEffect(() => {
        if (display) {
            window.history.pushState(null, "", window.location.href);
            const handlePopStateOverride = () => {
                window.history.pushState(null, "", window.location.href);
            };
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('popstate', handlePopStateOverride);

            return () => {
                window.removeEventListener('popstate', handlePopState);
                window.removeEventListener('popstate', handlePopStateOverride);
            };
        }
    }, [display]);

    useEffect(() => {
        setData([]);
    }, [selected]);

    useEffect(() => {
        setSlots([]);
        setSelectedDay('');
        setSelectedDayForCalendar('');
        if (selectedAllied === '') {
            setData([]);
        } else {
            const alliedId = selectedAllied.id;
            if (alliedId !== '' && alliedId !== null && alliedId !== undefined) {
                const tokenString = sessionStorage.getItem('usertoken');
                let str = tokenString.replace(/["]+/g, '');

                axios
                    .get(
                        `${APIURL}/api/v1/service-provider/allied-schedule/?start_date=${startDate}&end_date=${endDate}&allied_id=${alliedId}`,
                        {
                            headers: {
                                Authorization: 'Token ' + str,
                            }
                        }
                    )
                    .then((res) => {
                        if (res.data.status === 'success') {
                            const data = res.data.message;
                            setData(data);
                            const count = countHighlightedDays(data);
                            setHighlightedDaysCount(count);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }
    }, [selectedAllied, startDate, endDate]);

    const handleDayClick = (date) => {
        const clickedDayForDisplay = moment(date).format('DD/MM/YYYY');
        const clickedDayForComparison = moment(date).format('YYYY-MM-DD');
        const today = moment().format('YYYY-MM-DD');
        const currentTime = moment().format('HH:mm:ss');

        console.log('Clicked Day:', clickedDayForComparison);

        setSelectedDay(clickedDayForDisplay);
        setSelectedDayForCalendar(date);

        // Find all items with the same start_date
        const clickedDayData = data.filter(item => item.start_date === clickedDayForComparison);

        if (clickedDayData.length > 0) {
            //slots from all items with the same start_date
            let slotss = clickedDayData.reduce((acc, item) => {
                const slotsWithScheduleId = item.slots.map(slot => ({ ...slot, schedule_id: item.schedule_id }));
                return acc.concat(slotsWithScheduleId);
            }, []);

            if (clickedDayForComparison === today) {
                slotss = slotss.filter(slot => slot.to_time > currentTime);
            }

            if (slotss.length > 0) {
                setSlots(slotss)
            } else {
                setSlots([]);
            }
        } else {
            setSlots([]);
        }
    };

    const getTileClassName = ({ date, view }) => {
        if (view === 'month') {
            const day = moment(date).format('YYYY-MM-DD');
            const today = moment().format('YYYY-MM-DD');
            const currentTime = moment().format('HH:mm:ss');

            // Find all items with the same start_date
            const dayData = Array.isArray(data) && data.filter(item => item.start_date === day);

            if (dayData.length > 0) {
                // Collect all slots for the day
                let slotsForDay = dayData.reduce((acc, item) => acc.concat(item.slots), []);

                if (day === today) {
                    // Filter slots that are in the future
                    slotsForDay = slotsForDay.filter(slot => slot.to_time > currentTime);
                }

                if (slotsForDay.length > 0) {
                    return 'highlight';
                }
            }
        }
        return null;
    };

    const countHighlightedDays = (data) => {
        const today = moment().format('YYYY-MM-DD');
        const currentTime = moment().format('HH:mm:ss');

        // Create a Set to keep track of unique highlighted dates
        const highlightedDays = new Set();

        data.forEach(item => {
            const startDate = item.start_date;
            let slotsForDay = item.slots;

            // If the start date is today, filter slots that are in the future
            if (startDate === today) {
                slotsForDay = slotsForDay.filter(slot => slot.to_time > currentTime);
            }

            // If there are any slots for the day, add the date to the highlightedDays set
            if (slotsForDay.length > 0) {
                highlightedDays.add(startDate);
            }
        });

        // Return the size of the Set, which represents the count of unique highlighted days
        return highlightedDays.size;
    };

    const handleRadioClick = (e, slot) => {
        if (selectedSlot && selectedSlot.id === slot.id) {
            setSelectedSlot(null);
            e.target.checked = false;
        }
    };

    const handleRadioChange = (slot) => {
        setSelectedSlot(prevSlot => prevSlot && prevSlot.id === slot.id ? null : slot);
    };

    const handleContinue = async () => {
        setOnload(true);
        await new Promise(resolve => setTimeout(resolve, 0));
        if (selectedPatient === '') {
            alert.error('Select a patient')
            setOnload(false)
        } else if (selectedSlot === null) {
            alert.error('Select a slot')
            setOnload(false)
        } else {
            const tokenString = sessionStorage.getItem("usertoken");
            let str = tokenString.replace(/["]+/g, "");
            const phe_number = selectedPatient.phe_number
            const payload = {
                slot: selectedSlot.id,
                date: moment(selectedDayForCalendar).format('YYYY-MM-DD'),
                schedule_id: selectedSlot.schedule_id,
                allied_id: selectedAllied.id
            }
            axios
                .post(`${APIURL}/api/v1/patient/allied-appointment/?phe_number=${phe_number}`, payload, {
                    headers: {
                        Authorization: "Token " + str,
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {

                    if (res.data.status === "success") {
                        alert.success(res.data.message)
                        setBookInfo(res.data.appointment_id)
                        handleContinueRest(res.data.appointment_id)
                        setScheduleId('')
                    }
                    else {
                        alert.error(res.data.message)
                    }
                    setOnload(false)
                })
                .catch((err) => {
                    alert.error('error occured')
                    setOnload(false)
                });
        }

    }

    const handleContinueRest = (appt) => {
        const appointment_id = appt
        const patient_name =selectedPatient.applicant_details.full_name;
        const physio_id = selectedAllied.id;
        const physio_name = selectedAllied.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
        const date = selectedDay;
        const time = `${selectedSlot.from_time} - ${selectedSlot.to_time}`;

        setConfirmationData({
            appointment_id,
            patient_name,
            physio_id,
            physio_name,
            date,
            time,
        });
        setDisplay(true);
    }

    const onConfirm = () => {
        if (!onload) {
            handleBooking()
        }
    }

    const handleBooking = async () => {
        setOnload(true);
        await new Promise(resolve => setTimeout(resolve, 0));
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        const payload = {
            appt_id: bookInfo,
            status: 'success'
        };

        axios
            .put(`${APIURL}/api/v1/patient/allied-appointment-detail/`, payload, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    const token = res.data.token;
                    setConfirmationData((prevData) => ({
                        ...prevData,
                        token: token,
                    }));
                    alert.success("Booking completed successfully");
                    setDisplay(false);
                    setBooked(true);
                } else {
                    alert.error(res.data.message);
                }
                setOnload(false);
            })
            .catch((err) => {
                alert.error('Error occurred');
                setOnload(false);
            });
    };


    const handleSuccess = () => {
        setBooked(false);
        setSelectedPatient('');
        setSelectedDay('');
        setSelectedSlot('');
        setData([]);
        setSlots([]);
        setSelectedDayForCalendar(new Date());
        setSelectedAllied('');
    };

    const onCancel = () => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const payload = {
            booking_id: bookInfo
        }
        axios
            .put(`${APIURL}/api/v1/service-provider/allied-booking-detail/?cancel=True`, payload, {
                headers: {
                    Authorization: "Token " + str,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {

                if (res.data.status === "success") {
                    alert.success(res.data.message)
                    setDisplay(false)
                    setShowBackPopup(false)
                }
                else {
                    alert.error(res.data.message)
                }
            })
            .catch((err) => {
            });
    }
    const handleGoBack = () => {
        onCancel()
        navigate("../")
        setShowBackPopup(false)
        setDisplay(false)
    }

    const handleCancelGoBack = () => {
        setShowBackPopup(false)
        setPopStateEventFired(false)
    }

    return (
        <div className='csc-calendercontainer'>
            <div className='calender-app-main'>
                <Calendar
                    value={selectedDayForCalendar}
                    onClickDay={handleDayClick}
                    tileClassName={getTileClassName}
                    formatShortWeekday={(locale, date) => {
                        const options = { weekday: 'long' };
                        return new Intl.DateTimeFormat(locale, options).format(date);
                    }}
                />
            </div>
            &nbsp;&nbsp;&nbsp;
            <div className='slots-box'>
                <div className='slots-box-head'>
                    <label>Available Slots</label>
                </div>
                <div className='slots-box-content'>
                    {slots.length > 0 && (
                        <>
                            <div className='slots-box-subcontent'>
                                <label>{selectedAllied.name}</label>
                                <label>{highlightedDaysCount} days</label>
                            </div>
                            <div style={{ display: "flex" }}>
                                <label className='blue-label'>{selectedDay}</label>
                            </div>
                        </>
                    )}
                    <div className='slots-list-wrapper'>
                        {slots.length > 0 ? (
                            slots.map((slot, index) => (
                                <div className='csc-slotlist' key={index}>
                                    <label className='col-6' style={{ padding: 0 }}>{slot.from_time} - {slot.to_time}</label>
                                    <label className='col-4'><span style={{ fontSize: '12px', fontWeight: 500 }}><b> {slot.remaining_token} {slot.remaining_token === 1 ? "Token" : "Tokens"}</b></span></label>
                                    <label className='col-2' style={{ padding: 0 }}>
                                        <input
                                            type='radio'
                                            name='slot'
                                            value={slot.id}
                                            onChange={() => handleRadioChange(slot)}
                                            onClick={(e) => handleRadioClick(e, slot)}
                                            checked={selectedSlot && selectedSlot.id === slot.id}
                                            disabled={(selected !== 'PhysioOp' && slot.remaining_token < 1) || selectedPatient === ''}
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={!selectedPatient ? 'Select patient' : ''}
                                        />
                                    </label>
                                </div>
                            ))
                        ) : (
                            <label>No available slots for selected date</label>
                        )}
                    </div>
                    {slots.length > 0 &&
                        <>
                            <button
                                className='save-button'
                                disabled={!selectedPatient || onload}
                                style={{ width: '200px', backgroundColor: !selectedPatient ? '#2456ae82' : '', cursor: onload ? 'progress' : '' }}
                                onClick={() => handleContinue()}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={!selectedPatient ? 'Select patient' : ''}
                            >
                                Book Appointment
                            </button>
                            <Tooltip id="my-tooltip" style={{ backgroundColor: "#04040466;", color: "#FFFFFF" }} />
                        </>}
                </div>
            </div>
            {display && <OpBookingConfirmPopup content={confirmationData} close={onCancel} confirm={onConfirm} />}
            {booked && <OpBookedPopup content={confirmationData} close={handleSuccess} />}
            {showBackPopup && (
                <SubmitConfirmationPopup
                    message="Are you sure you want to exit the page without confirming the appoinment? "
                    onConfirm={handleGoBack}
                    onCancel={handleCancelGoBack}
                />
            )}
        </div>
    );
}

export default PhysioOp;
