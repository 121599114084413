import React, { useEffect, useState } from "react";
import axios from "axios";
import service2 from "../../assets/images/2.png";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { APIURL } from "../../Global";
function Summarypage() {
  
  const navigate = useNavigate();
  const summaryDetails = JSON.parse(sessionStorage.getItem("summarydetails"));

  // const addressDetails = JSON.parse(sessionStorage.getItem("addressdetails"));
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );
  const [addres, setAddress] = useState()
  const [addressList, setAddressList] = useState([])
 // const  checkoutCartId  =sessionStorage.getItem("checkout");
  useEffect(() => {
    
    if(login_datas){
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(`${APIURL}/api/v1/patient/shipping-address/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {

        setAddressList(res.data.message);
        const filteredData = res.data.message.filter(x => {
          return x.is_default === true
        })
        setAddress(filteredData[0])
        console.log(addres)
      })
      .catch((err) => { });
    }
  }, []);


  const orderIds = summaryDetails ? summaryDetails.map((item) => item.checkoutcart_id) : [];
  let cartItems =summaryDetails && summaryDetails.filter(item => !item.hasOwnProperty('checkoutcart_id'));
 const price = cartItems ? cartItems.reduce((acc, item) => acc + item.total_item_amount, 0) : 0;
 const discount = cartItems ? cartItems.reduce((acc, item) => acc + item.discount_amount, 0) : 0;
 const deliveryCharge = cartItems ? cartItems.reduce((acc, item) => acc + item.shipping_charge, 0) : 0;
 const vat = cartItems ? cartItems.reduce((acc, item) => acc + item.vat_amount, 0) : 0;
 const total = price - discount + deliveryCharge + vat;

  const handleFinal =(e)=>{
    e.preventDefault();
    navigate("/webordering")
    sessionStorage.removeItem("summarydetails");
    sessionStorage.removeItem("addressdetails");
    sessionStorage.removeItem("checkout");
  }

 

  //   const calculateSubtotal = ()=> {
  //     return cartItems.reduce((total, item) => total + item.total_item_amount, 0);
  //   }
  return (
    <>
      <section className="checkout-area ptb-70" style={{ marginTop: "-5%" }}>

        {cartItems  &&
        <div className="summarypage">
          <div className="summaryfirst" style={{ marginTop: "7%" }}>
            <span
              style={{ color: "#554E56", marginLeft: "5%", marginTop: "1%" }}
            >
              <b>Checkout ID:</b> {orderIds}
    
            </span>{" "}
            &nbsp;&nbsp;
            <button  disabled  className="summaryinvoice">
              <b>Invoice</b>
            </button>
            &nbsp;&nbsp;{" "}
            <button className="summarytrack" disabled  style={{ color: "white" }}>
              Track Order
            </button>
          </div>

          <div
            className="summaryitems"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {
              cartItems.map((item) => (
                <div
                  key={item.id}
                  className="summaryitem"
                  style={{ display: "flex", marginTop: "-10px" }}
                >
                  <img
                    src={
                      item.order_type === "master_consumable" ||
                      item.order_type === "master_medicine"
                        ? item.image
                        : service2
                    }
                    alt={item.item_service_name}
                    className="summaryimage"
                  />

                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "200px",
                    }}
                  >
                    <span style={{ marginTop: "20%", marginLeft: "5%" }}>
                      <b>{item.item_service_name}</b>
                    </span>
                    <br />
                    <span
                      style={{
                        marginTop: "-5%",
                        marginLeft: "5%",
                        fontSize: "10px",
                      }}
                    >
                      {" "}
                      Seller:{item.hospital_name}
                    </span>
                  </div>
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "200px",
                    }}
                  >
                    <span style={{ marginTop: "20%", marginLeft: "22%" }}>
                      <b> ${item.total_item_amount}</b>{" "}
                    </span>
                    <span
                      style={{
                        marginTop: "10%",
                        marginLeft: "22%",
                        fontSize: "10px",
                      }}
                    >
                      <b>Qty: {item.item_quantity}</b>
                    </span>
                  </div>
                </div>
              ))}
          </div>

          <div className="summarysubtotal">
            <div className="summarysubtotal-item">
              <span>
                <b style={{ fontSize: "12px" }}>Subtotal:</b>
              </span>
              <span>
                <b style={{ fontSize: "12px" }}>${price}</b>
              </span>
            </div>
            <hr className="line" />
            <div className="summarysubtotal-item">
              <span>
                <b style={{ fontSize: "12px" }}>Discount:</b>
              </span>
              <span>
                <b style={{ fontSize: "12px" }}>${discount}</b>
              </span>
            </div>
            <hr className="line" />
            <div className="summarysubtotal-item">
              <span>
                <b style={{ fontSize: "12px" }}>VAT:</b>
              </span>
              <span>
                <b style={{ fontSize: "12px" }}>${vat}</b>
              </span>
            </div>
            <hr className="line" />
            <div className="summarysubtotal-item">
              <span>
                <b style={{ fontSize: "12px" }}>Shipping:</b>
              </span>
              <span>
                <b style={{ fontSize: "12px" }}>${deliveryCharge}</b>
              </span>
            </div>
            <hr className="line" />
            <div className="summarysubtotal-item">
              <span>
                <b>Total:</b>
              </span>
              <span>
                <b>${total.toFixed(2)}</b>
              </span>
            </div>
          </div>

          <div className="summaryaddress" style={{ marginTop: "18%", fontSize: "12px",marginLeft:"15%", width: "300px" }}>
  <span style={{ textAlign: "justify" }}>
    <b>Shipping Address:</b> {addres && addres.first_name} {addres && addres.last_name},<br/>
    {addres && addres.apt_no}, {addres && addres.province}, {addres && addres.landmark}, {addres && addres.city}, {addres && addres.location}<br/>
    {addres && addres.state}, {addres && addres.country} {addres && addres.pincode}, {addres && addres.mobile_number}
  </span>
</div>


         <button className="summarybutton" onClick={handleFinal}>Continue Shopping</button>


        </div>
}
      </section>
    </>
  );
}
export default Summarypage;
