import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ManagerCard from "../Manager/ManagerCard";

import "./managerlist.css";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { APIURL } from "../../Global";
//import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import LoadingOverlay from 'react-loading-overlay';
//import BackButton from "../common/BackButton";
function ManagerList() {
  
  const [loading,setLoading]=useState(false);
  const [bdoList, setBdoList] = useState([]);
  const [freeCount, setFreeCount] = useState();
  const [activeCount, setActiveCount] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
bdolist();
  }, []);

  const bdolist=()=>
  {
    
    setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/manager/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + v2,
        },
      })
      .then((res) => {
        if (res.status ===200) {
          
          const datas = res.data;

          //setFreeCount(res.data.free_user_count);
          //setActiveCount(res.data.active_free_users);
          setLoading(false)
         
          setBdoList(datas.reverse());
        } else {
          setErrorMsg("error");
          setLoading(false)
        }
      })
      .catch((err) => {
        setErrorMsg("error");
        setLoading(false)
      });
  }

  const refreshHandle = () => {
    setRefresh(!refresh);
    bdolist();
  };
 

  return (
   
   
    <div className="drlist-page">
    


 
      <h2 className="title-of-page"> Manager List</h2>
      <div className="quick-links">
       
        {/* <Link to={{ pathname: "license-details", flag: "bdo-page" }}> */}
          {/* <button
            type="button"
            style={{ marginLeft: "10px" }}
            className="btn btn-info dr-btn"
          >
            {" "}
            {t("View Licence Details")}
          </button>
        </Link> */}
      </div>

      {bdoList.length !== 0 ? (
        <div><br/>
          <div className="">
            
                    <ManagerCard
                     
                      bdoList={bdoList}
                      user="manager"
                      handle={refreshHandle}
                    />
                
          </div>

         
        </div>
      ) : (
        <h5 style={{ color: "red", margin: "20px" }}>Manager List is empty </h5>
      )}

      {errorMsg === "error" ? (
        <h5 style={{ color: "red" }}>
          Error in data fetching.Please try again!
        </h5>
      ) : null}
    
    
    </div>
  );
}

export default ManagerList;
