import React, { useState, useContext, useEffect } from "react";
import "./managercard.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL, TZ } from "../../Global";
import { useAlert } from "react-alert";
import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../common/SubmitConfirm";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { UserManagementContext } from "../contexts/UserManagementContext";
function BdoCard(props) {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
  const [selected, setSelected] = useContext(UserManagementContext)
  const [action, setAction] = useState("")
  const alert = useAlert()
  const [list, setList] = useState({})
  const update_link = `/editUser`;
  const [searchTerm, setSearchTerm] = useState("");
  const [bdoList, setBdoList] = useState(props.userList)
  const handleConfirm = () => {
    closeConfirmationPopup()
    if (action === "del") {
      handleDelete();
    }
    else {
      handleSuspend();
    }
  };

  useEffect(() => {
    setBdoList([])
  }, [selected])

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    let filteredList = [...props.userList];

    console.log(filteredList, "initial list");

    if (searchTerm === "") {
      setBdoList(props.userList);
    } else {
      filteredList = filteredList.filter(value => {
        return (
          value.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          value.id.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setBdoList(filteredList);
      console.log(filteredList, "filtered list");
    }
  }, [searchTerm, props.userList]);

  const handleCancel = () => {
    closeConfirmationPopup()
  };


  const handleDeletePopUp = () => {


    setShowConfirmation(true);
  }
  const handleSuspendPopUp = () => {

    setAction("sus")
    setShowConfirmation(true);

  };



  const handleSuspend = (e) => {
    //	e.preventDefault();

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );


    const data1 = {
      is_suspended: !list.is_suspended
    }
    console.log(selected)

    axios.put(`${APIURL}/api/v1/service-provider/${selected === 'higher-officials' ? 'higherofficial-detail' : selected === "pharmacyuser" ? "pharmacy-user-alter" : selected === "nurse" ? "nurse-detail" : selected === "carers" ? "carer-detail" : selected === "doctors" ? "doctors" : selected}/${list.id}/`, data1, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Token " + v2
      },
    })
      .then((res) => {
        if (res.data.status === "success") {
          alert.success(`${list.is_suspended ? "Authorised" : "Suspended"} Successfully!!`);
          props.handle()

        } else {
          alert.error("Error occured. Try again!")
        }


      })
      .catch((err) => {
        alert.error("Error occured. Try again!")
        // setSubmitMsg("error");
      });

  };
  const handleDelete = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const role = selected === 'higher-officials' ? 'higher_official' : selected === 'doctors' ? 'doctor' : selected === 'carers' ? 'carer' : selected
    axios
      .post(
        `${APIURL}/api/v1/service-provider/hospital-user-delete/?user_id=${list.id}&role=${role}`,
        {},
        {
          headers: {
            Authorization: "Token " + str,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          alert.success("Deleted successfully")
          props.handle();
        } else {
          alert.error("Error occured. Try again!")
        }



      })
      .catch((err) => {
      });
  };
  return (

    <>
      <div className="row" style={{ display: "flex" }}>
        <div className="col-9">
          <div style={{ display: "flex", backgroundColor: "rgba(230, 238, 248, 1)", border: "0.5px solid rgba(217, 217, 217, 1)", borderRadius: "12px", height: "44px", paddingRight: "2%" }}>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ paddingTop: "8px" }} width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="m19.485 20.154l-6.262-6.262q-.75.639-1.725.989t-1.96.35q-2.402 0-4.066-1.663T3.808 9.503T5.47 5.436t4.064-1.667t4.068 1.664T15.268 9.5q0 1.042-.369 2.017t-.97 1.668l6.262 6.261zM9.539 14.23q1.99 0 3.36-1.37t1.37-3.361t-1.37-3.36t-3.36-1.37t-3.361 1.37t-1.37 3.36t1.37 3.36t3.36 1.37" /></svg>
            <input

              className=""
              style={{ border: "none", width: "98%", backgroundColor: "rgba(230, 238, 248, 1)" }}
              type="text"
              maxLength="100"
              placeholder="Search name, ID"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="col-3">
          <button
            onClick={() => { navigate('/addUser') }}
            style={{ backgroundColor: "rgba(43, 105, 163, 1)", width: "100%", color: "white", border: "0.5px solid rgba(217, 217, 217, 1)", borderRadius: "12px", height: "44px" }}>
            ADD NEW USERS
          </button>
        </div>
      </div>
      <div className="row" style={{ marginTop: "2%" }}>
        {bdoList.length > 0
          ? bdoList.map((data, index) => {
            return (
              <div className="col-4" style={{ width: '100% !important' }}>
                <div onClick={() => {
                  setList(data)
                }} key={props.key} className="" style={{ backgroundColor: "white", width: '100%' }}>
                  <div style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)", borderRadius: "10px", width: '100%', height: '224px', marginBottom: '15px' }}>
                    <div className="" style={{ alignContent: 'center', display: 'flex', justifyContent: 'space-between', backgroundColor: data.user_name || data.username ? "rgba(43, 105, 163, 1)" : "#e4dd9fc2", padding: "1%", borderRadius: "10px 10px 0px 0px", height: "40px" }}>
                      <div style={{ flexWrap: 'wrap', margin: '1%', display: 'flex', alignContent: 'center', backgroundColor: '#fff', borderRadius: '10px', width: '65px' }}>
                        <div className="col-6" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', alignContent: 'center', padding: 0 }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M10 4H14C17.771 4 19.657 4 20.828 5.172C22 6.343 22 8.229 22 12C22 15.771 22 17.657 20.828 18.828C19.657 20 17.771 20 14 20H10C6.229 20 4.343 20 3.172 18.828C2 17.657 2 15.771 2 12C2 8.229 2 6.343 3.172 5.172C4.343 4 6.229 4 10 4ZM13.25 9C13.25 8.80109 13.329 8.61032 13.4697 8.46967C13.6103 8.32902 13.8011 8.25 14 8.25H19C19.1989 8.25 19.3897 8.32902 19.5303 8.46967C19.671 8.61032 19.75 8.80109 19.75 9C19.75 9.19891 19.671 9.38968 19.5303 9.53033C19.3897 9.67098 19.1989 9.75 19 9.75H14C13.8011 9.75 13.6103 9.67098 13.4697 9.53033C13.329 9.38968 13.25 9.19891 13.25 9ZM14.25 12C14.25 11.8011 14.329 11.6103 14.4697 11.4697C14.6103 11.329 14.8011 11.25 15 11.25H19C19.1989 11.25 19.3897 11.329 19.5303 11.4697C19.671 11.6103 19.75 11.8011 19.75 12C19.75 12.1989 19.671 12.3897 19.5303 12.5303C19.3897 12.671 19.1989 12.75 19 12.75H15C14.8011 12.75 14.6103 12.671 14.4697 12.5303C14.329 12.3897 14.25 12.1989 14.25 12ZM15.25 15C15.25 14.8011 15.329 14.6103 15.4697 14.4697C15.6103 14.329 15.8011 14.25 16 14.25H19C19.1989 14.25 19.3897 14.329 19.5303 14.4697C19.671 14.6103 19.75 14.8011 19.75 15C19.75 15.1989 19.671 15.3897 19.5303 15.5303C19.3897 15.671 19.1989 15.75 19 15.75H16C15.8011 15.75 15.6103 15.671 15.4697 15.5303C15.329 15.3897 15.25 15.1989 15.25 15ZM11 9C11 9.53043 10.7893 10.0391 10.4142 10.4142C10.0391 10.7893 9.53043 11 9 11C8.46957 11 7.96086 10.7893 7.58579 10.4142C7.21071 10.0391 7 9.53043 7 9C7 8.46957 7.21071 7.96086 7.58579 7.58579C7.96086 7.21071 8.46957 7 9 7C9.53043 7 10.0391 7.21071 10.4142 7.58579C10.7893 7.96086 11 8.46957 11 9ZM9 17C13 17 13 16.105 13 15C13 13.895 11.21 13 9 13C6.79 13 5 13.895 5 15C5 16.105 5 17 9 17Z" fill="#21466B" />
                        </svg>                        </div>
                        <div className="col-6" style={{ padding: 0, display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}><label style={{ margin: 0, fontSize: '.9rem', fontWeight: 600, color: 'rgb(33, 70, 107)' }}>{data.id}</label></div>
                      </div>
                      <div style={{ display: "flex", alignItems: 'center' }}>
                        <div onClick={() => {
                          setList(data)
                          setAction("sus")
                          handleSuspendPopUp();
                        }} className="round-head"
                          data-tooltip-id="my-tooltip" data-tooltip-content={data.is_suspended ? 'Authorize User' : 'Suspend User'}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 20 20"><path fill={data.is_suspended ? "red" : "rgb(33, 70, 107)"} d="M5.25 3A2.25 2.25 0 0 0 3 5.25v9.5A2.25 2.25 0 0 0 5.25 17h9.5A2.25 2.25 0 0 0 17 14.75v-9.5A2.25 2.25 0 0 0 14.75 3z" /></svg>
                        </div><Tooltip id="my-tooltip" style={{ backgroundColor: "#04040466;", color: "#FFFFFF" }} />&nbsp;
                        <div className="round-head">
                          <Link onClick={() => {
                            setList(data)
                          }} to={update_link} state={{ id: data.id }}
                            data-tooltip-id="my-tooltip1" data-tooltip-content='Edit Details'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="rgb(33, 70, 107)" d="m7 17.013l4.413-.015l9.632-9.54c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.756-.756-2.075-.752-2.825-.003L7 12.583zM18.045 4.458l1.589 1.583l-1.597 1.582l-1.586-1.585zM9 13.417l6.03-5.973l1.586 1.586l-6.029 5.971L9 15.006z" /><path fill="rgb(33, 70, 107)" d="M5 21h14c1.103 0 2-.897 2-2v-8.668l-2 2V19H8.158c-.026 0-.053.01-.079.01c-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2" /></svg></Link>
                        </div><Tooltip id="my-tooltip1" style={{ backgroundColor: "#04040466;", color: "#FFFFFF" }} />&nbsp;
                        <div onClick={() => {
                          setList(data)
                          setAction("del"); handleDeletePopUp();
                        }} className="round-head"
                          data-tooltip-id="my-tooltip2" data-tooltip-content='Delete User'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 32 32"><path fill="rgb(33, 70, 107)" d="M13.5 6.5V7h5v-.5a2.5 2.5 0 0 0-5 0m-2 .5v-.5a4.5 4.5 0 1 1 9 0V7H28a1 1 0 1 1 0 2h-1.508L24.6 25.568A5 5 0 0 1 19.63 30h-7.26a5 5 0 0 1-4.97-4.432L5.508 9H4a1 1 0 0 1 0-2zM9.388 25.34a3 3 0 0 0 2.98 2.66h7.263a3 3 0 0 0 2.98-2.66L24.48 9H7.521zM13 12.5a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0v-10a1 1 0 0 1 1-1m7 1a1 1 0 1 0-2 0v10a1 1 0 1 0 2 0z" /></svg>
                        </div><Tooltip id="my-tooltip2" style={{ backgroundColor: "#04040466;", color: "#FFFFFF" }} />
                      </div>
                    </div>
                    <div className="row" style={{ display: 'flex', height: '26px', paddingTop: '1%', width: '100%', margin: 'auto' }}>
                      <label
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',  // Ensures the text stays on a single line
                          paddingLeft: '2%',
                          textTransform: 'capitalize',
                          fontSize: '16px',
                          color: 'rgba(33, 70, 107, 1)'
                        }}>
                        {data.name}
                      </label>
                    </div>

                    <div className="card-user-details">
                      {/* <span style={{ textTransform: "capitalize" }}>
                        {Array.isArray(data.qualification) && data.qualification.length > 0 ? (
                          data.qualification.map((item, index) => (
                            <span key={index} style={{ textTransform: "capitalize" }}>
                              {item}{index !== data.qualification.length - 1 && ', '}
                            </span>
                          ))
                        ) : (
                          <span style={{ textTransform: "capitalize" }}>{data.qualification}</span>
                        )}
                      </span> */}
                      <span>
                        {(() => {
                          let qualificationArray;

                          if (Array.isArray(data.qualification)) {
                            qualificationArray = data.qualification;
                          } else if (typeof data.qualification === 'string') {
                            if (data.qualification.startsWith('[') && data.qualification.endsWith(']')) {
                              try {
                                const jsonString = data.qualification.replace(/'/g, '"');
                                qualificationArray = JSON.parse(jsonString);
                              } catch (e) {
                                qualificationArray = [data.qualification];
                              }
                            } else {
                              qualificationArray = [data.qualification];
                            }
                          } else {
                            qualificationArray = [];
                          }

                          return qualificationArray.map((item, index) => (
                            <span key={index} style={{ textTransform: "capitalize" }}>
                              {item.trim()}{index !== qualificationArray.length - 1 && ', '}
                            </span>
                          ));
                        })()}
                      </span>
                      {selected !== "pharmacyuser" && (
                        <>
                          {selected !== "manager" && selected !== "higher_official" && (
                            <span>
                              {(() => {
                                let specializationsArray = [];

                                if (Array.isArray(data.specializations)) {
                                  // Check if the first element is a string that looks like a JSON array
                                  if (typeof data.specializations[0] === 'string' &&
                                    data.specializations[0].startsWith('[') &&
                                    data.specializations[0].endsWith(']')) {
                                    try {
                                      // Parse the string as JSON after replacing single quotes with double quotes
                                      const jsonString = data.specializations[0].replace(/'/g, '"');
                                      specializationsArray = JSON.parse(jsonString);
                                    } catch (e) {
                                      specializationsArray = data.specializations;
                                    }
                                  } else {
                                    specializationsArray = data.specializations;
                                  }
                                }

                                return specializationsArray.map((item, index) => (
                                  <span key={index} style={{ textTransform: "capitalize" }}>
                                    {item.trim()}{index !== specializationsArray.length - 1 && ', '}
                                  </span>
                                ));
                              })()}
                            </span>
                          )}
                          {data.department && (
                            <span style={{ textTransform: "capitalize" }}>
                              {Array.isArray(data.department) ? (
                                data.department.map((item, index) => (
                                  <span key={index} style={{ textTransform: "capitalize" }}>
                                    {item}{index !== data.department.length - 1 && ', '}
                                  </span>
                                ))
                              ) : (
                                <span style={{ textTransform: "capitalize" }}>
                                  {data.department}
                                </span>
                              )}
                            </span>
                          )}
                        </>
                      )}
                      {selected === 'higher-officials' && (
                        <span style={{ color: "var(--green)" }}>
                          {data.is_official_access ? 'Trust Secretary' : 'Trust member'}
                        </span>
                      )}
                      {(data.user_name || data.username) && (
                        <>
                          <span className="">
                            <labal style={{ color: 'rgb(43, 105, 163)', margin: 0 }}>Username </labal>{': '} {data.user_name || data.username}
                          </span>
                        </>
                      )}

                    </div>
                    <div style={{ height: '57px', display: 'flex', justifyContent: 'space-between', fontSize: "12px" }}>
                      <div className='col-6' style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <i style={{ alignContent: 'center' }} className="fa fa-envelope" aria-hidden="true"></i>
                          <label style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.email}</label>
                        </div>
                        <div style={{ padding: "0px 5px" }}>
                          <button onClick={() => { if (data.user_name || data.username) { navigate('/changepassword', { state: { id: data.id } }); } else { navigate('/createuser', { state: { data: data } }); } }} style={{ border: "1px solid rgba(43, 105, 163, 1)", backgroundColor: (data.user_name || data.username) ? "rgba(230, 238, 248, 1)" : "rgba(43, 105, 163, 1)", color: (data.user_name || data.username) ? "rgba(43, 105, 163, 1)" : "white", borderRadius: "25px", padding: "1%", fontSize: "11px", width: "100%", paddingTop: "2%", paddingBottom: "2%" }}>{(data.user_name || data.username) ? " Change password " : " Credentials "}</button>
                        </div>
                      </div>
                      <div className='col-6' style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <i style={{ alignContent: 'center' }} className="fa fa-phone fa-sm" aria-hidden="true"></i>
                          <label style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.phone.length === 10 ? "+91 " + data.phone : "+" + data.phone}</label>
                        </div>
                        <div style={{ padding: "0px 5px" }}>
                          <button disabled onClick={() => { navigate('/changeaccess', { state: { data: data } }); }} style={{ border: "1px solid rgba(43, 105, 163, 1)", backgroundColor: "#fff", color: "rgba(43, 105, 163, 1)", borderRadius: "25px", padding: "1%", fontSize: "11px", width: "100%", paddingTop: "2%", paddingBottom: "2%" }}>
                            <svg width="8" height="18" viewBox="0 0 10 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.0091 2.625C2.80363 2.625 0.998556 4.52041 0.998556 6.83616V7.41132H2.60469V6.83616C2.60469 5.43182 3.67168 4.31148 5.0091 4.31148C6.34652 4.31148 7.41352 5.43182 7.41352 6.83616V9.14337C6.70598 8.69945 5.88027 8.44364 5 8.44364C2.39766 8.44364 0.270782 10.6769 0.270782 13.4093C0.270782 16.1418 2.39766 18.375 5 18.375C7.60234 18.375 9.72922 16.1418 9.72922 13.4093C9.72922 12.4541 9.46902 11.56 9.01969 10.8009V6.83616C9.01969 4.52041 7.21457 2.625 5.0091 2.625ZM5 10.1301C6.7343 10.1301 8.12305 11.5883 8.12305 13.4093C8.12305 15.2303 6.7343 16.6885 5 16.6885C3.2657 16.6885 1.87695 15.2304 1.87695 13.4093C1.87695 11.5883 3.2657 10.1301 5 10.1301ZM5.01543 12.033C4.2836 12.033 3.69035 12.6559 3.69035 13.4243C3.69035 14.1927 4.2836 14.8156 5.01543 14.8156C5.74727 14.8156 6.34051 14.1927 6.34051 13.4243C6.34051 12.6559 5.74723 12.033 5.01543 12.033Z" fill="#21466B" />
                            </svg>&nbsp;&nbsp;
                            Access level
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
          : <div className="nodata">No results found!!</div>}

      </div >
      {showConfirmation && (
        <SubmitConfirmationPopup
          message={action === "del" ? " Please Confirm to Delete the User ID " + list.id : list.is_suspended ? (" Please Confirm to Authorise the User ID " + list.id) : ("Please Confirm to Suspend the User ID " + list.id)}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )
      }


    </>
  );
}

export default BdoCard;