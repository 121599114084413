import React, { useState, useEffect,useRef } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import axios from "axios";
import { APIURL } from "../../Global";
import { useNavigate, Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import "./service.css";
import { SOCKETURL } from "../../Global";
import { Modal } from "react-bootstrap";
import AddRemark from "./AddRemark";
import { Tooltip } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
let ServiceHistoryDoc = () => {
  const webSocket = useRef(null);
  const [infoShow, setinfoShow] = useState(false);
  const [infoData, setInfoData] = useState("");
  const [type, setType] = useState("");
  const [tableData,setTableData]=useState([]);
  const [hasNext, setHasNext] = useState(false)
  const [activating, setActivating] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const[page,setPage]=useState(1)
  let tableData2=[]
  
  let navigate = useNavigate();
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
  const formattedTime = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
  
 
  const [infoShow1, setinfoShow1] = useState(false);

  const [infoData1, setInfoData1] = useState("");

// Get current timezone dynamically
const handleInfoPopUp1 = (item) => {

  // setInfoData(item);
  const tokenString = sessionStorage.getItem('usertoken');

  let str = tokenString.replace(/["]+/g, '');



  axios.get(`${APIURL}/api/v1/doctor/patient-prescriptions-detail/${item}/`, {
    headers: {

      "Authorization": 'Token ' + str
    }
  })
    .then(res => {
      if (res.status === 200) {
      
        let data=res.data

      setInfoData1(data);
      setinfoShow1(true);
      
      }

 
  else {
   

  
  }



})
.catch(err => {
 

})

};


const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  
  const loadData = async  (page, per_page)  => {
    setActivating(true);
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(
        `${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicine&page=${page}&per_page=${per_page}&timeZone=${currentTimezone}&current_date=${formattedDate}`,
        {
          headers: { Authorization: "Token " + v2 },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
    
            let data=res.data.requests
            setHasNext(res.data.has_next)
            if (data && data.length > 0) {
              if (data && data.length > 0) {
                const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
                setTableData((prevData) => [...prevData, ...newData]);
              }
              setHasNext(res.data.has_next)
              
             setTotalRows(res.data.total_records)
            }
            
          }
           else {
      
          //  setErrorShow(true)
        }
      })
      .catch((err) => {
       
        // setErrorShow(true)
      });
  };


  useEffect(() => {
    loadData(page,perPage);
  }, [perPage]);


  const handlePageChange = page => {
    if(hasNext){
    loadData(page+1, perPage);
    setPage(page)
    }

  }

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage);
    
  }


  const statusOptions = [{ value: 'delivered', label: 'Delivered' },
  { value: 'rejected', label: 'Rejected' }];

  const renderDate = (cellInfo) => {

    let dateString = tableData[cellInfo.index][cellInfo.column.id];

    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (<div >
      <h6> {updatedDate.slice(4, 10)}  </h6>
      {/* <h6> {updatedDate.slice(16, 24)}</h6> */}
    </div>)

  }
  const handleInfoPopUp = (item,type) => {
    setInfoData(item);
    setinfoShow(true);
    console.log(item)
    if(type==="master_medicine"){
      setType("Medicines")
    }else{
      setType("Lab Tests")
    }
  };
  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"><h5>{type}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
           
            
        {infoData.map((item,index) => {
             return (            
             <div className="row-of-features">
               <div className="col-of-features"><br/>
                 <h6> {index+1}&nbsp;{item.item_service_name}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Quantity:&nbsp;{item.request_quantity}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Requested Due Date:&nbsp;{item.request_due_date}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Priority:&nbsp;{item.request_priority}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Status:&nbsp;{item.request_status}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Amount:&nbsp;{item.order_amount}</h6>
             

              
               </div>
             </div>
             );
          })}
              
          
          </div>
        </Modal.Body>
      </Modal>
    );
  };


  const renderService = (cellInfo) => {

    return (

      <div > {cellInfo.column.id === "request_pid"&&tableData[cellInfo.index].pam_status&&
      <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled"> Updated by doctor</Tooltip>}>
      <svg style={{marginTop:"-5px"}}  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M0 0h24v24H0z"/><path fill="orange" d="M4 11a1 1 0 0 1 .117 1.993L4 13H3a1 1 0 0 1-.117-1.993L3 11h1zm8-9a1 1 0 0 1 .993.883L13 3v1a1 1 0 0 1-1.993.117L11 4V3a1 1 0 0 1 1-1zm9 9a1 1 0 0 1 .117 1.993L21 13h-1a1 1 0 0 1-.117-1.993L20 11h1zM4.893 4.893a1 1 0 0 1 1.32-.083l.094.083l.7.7a1 1 0 0 1-1.32 1.497l-.094-.083l-.7-.7a1 1 0 0 1 0-1.414zm12.8 0a1 1 0 0 1 1.497 1.32l-.083.094l-.7.7a1 1 0 0 1-1.497-1.32l.083-.094l.7-.7zM14 18a1 1 0 0 1 1 1a3 3 0 0 1-6 0a1 1 0 0 1 .883-.993L10 18h4zM12 6a6 6 0 0 1 3.6 10.8a1 1 0 0 1-.471.192L15 17H9a1 1 0 0 1-.6-.2A6 6 0 0 1 12 6z"/></g></svg></OverlayTrigger>}
       <span style={{textTransform: "capitalize" ,textAlign:"center" }}>
        {tableData[cellInfo.index][cellInfo.column.id]}{cellInfo.column.id === "request_items"&&<i
              style={{ color: "#75759c", paddingLeft: "5%" }}
              className="fas fa-info-circle fa-sm"
              onClick={() => {
               handleInfoPopUp1(tableData[cellInfo.index].request_pid);
              }}
            ></i>}
      </span>
      {/* <i
              style={{ color: "#75759c", paddingLeft: "5%" }}
              className="fas fa-info-circle fa-sm"
              onClick={() => {
               handleInfoPopUp(tableData[cellInfo.index].request_items,tableData[cellInfo.index].request_type);
              }}
            ></i> */}
      </div>

    );
  }
  const renderStatus = (cellInfo) => {
    return (
      <>
        <button  style={{textTransform: "capitalize" ,textAlign:"center",width: "199px",
height: "39px",
flexShrink: "0",
borderRadius: "5px",
background: tableData[cellInfo.index].status==="approved" ? "#61C65F" :"#C6725F", color: "white"}}  >
          {tableData[cellInfo.index].status}
        
         
         
        </button>
      </>
    );
  };

  const filterData = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
  let data =[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicine&page=1&per_page=20&timeZone=Asia/Calcutta&current_date=${formattedDate}&request_pid=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
     

        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
      setTotalRows(response.data.total_records)
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataStatus = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data= [];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicines&page=1&per_page=20&timeZone=Asia/Calcutta&current_date=${formattedDate}&status=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
        
      
      } // Update the state with the filtered data from the API
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterData1 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicine&page=1&per_page=20&timeZone=Asia/Calcutta&current_date=${formattedDate}&name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
      setTotalRows(response.data.total_records)
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterData2 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data =[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicine&page=1&per_page=20&timeZone=Asia/Calcutta&current_date=${formattedDate}&patient_name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
      setTotalRows(response.data.total_records)
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  const filterData3 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
let data=[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicine&page=1&per_page=20&timeZone=Asia/Calcutta&current_date=${formattedDate}&doctor_name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       
        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
      setTotalRows(response.data.total_records)
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterData4 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
  let data =[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicine&page=1&per_page=20&timeZone=Asia/Calcutta&current_date=${formattedDate}&date=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       

        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
      setTotalRows(response.data.total_records)
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterData5 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
  let data =[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicine&page=1&per_page=20&timeZone=Asia/Calcutta&current_date=${formattedDate}&quantity=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
      

        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
      setTotalRows(response.data.total_records)
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterData6 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
  let data =[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicine&page=1&per_page=20&timeZone=Asia/Calcutta&current_date=${formattedDate}&nurse_name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
      

        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
      setTotalRows(response.data.total_records)
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterData7 = (filterValue) => {
    
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
  let data =[];
    axios.get(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/?type=medicine&page=1&per_page=20&timeZone=Asia/Calcutta&current_date=${formattedDate}&bedunit_code=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
       

        data = response.data.requests
        if (data.length>0){
          // if (data && data.length > 0) {
          //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setTableData(data);
           }
           else{
            const data1 = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
            setTableData((prevData) => [...prevData, ...data1]);
           }
      setTotalRows(response.data.total_records)
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };
  var sendData = null

  function handleSend(childData) {
    sendData = childData
    
  }
 
  const handleRender=(e)=>{
    
  
    // setRerender(!reRender)
   
  
  setTableData([])
 
  // window.location.reload(false)
   
  }
 
  const InfoPopup1 = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
     
        </Modal.Header>
        <Modal.Body>
          <div>
           
            

                  
             <div className="row-of-features">
               <div className="col-of-features"><br/>               
                 <h6 >&nbsp;&nbsp;&nbsp;Medicine Name:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.medicine_name}</span></h6>
                 <h6 >&nbsp;&nbsp;&nbsp;Strength:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.strength}</span></h6>
                 <h6>&nbsp;&nbsp;&nbsp;Medicine Type:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.med_type}</span></h6>
                 <h6 >&nbsp;&nbsp;&nbsp;Form:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.form.toLowerCase()}</span></h6>
                 <h6>&nbsp;&nbsp;&nbsp;Frequency:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.frequency}</span></h6>
                 <h6>&nbsp;&nbsp;&nbsp;Duration:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.duration}&nbsp;{infoData1.duration_unit} </span></h6>
                 <h6>&nbsp;&nbsp;&nbsp;Intake Period:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.intake}</span></h6>
                 <h6>&nbsp;&nbsp;&nbsp;Intake Count:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.intake_count}</span></h6>
                 <h6>&nbsp;&nbsp;&nbsp;Intake Time:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.intake_time}</span></h6>
                 <h6>&nbsp;&nbsp;&nbsp;Intake Unit:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.intake_unit}</span></h6>
                 <h6>&nbsp;&nbsp;&nbsp;Till_date:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.till_date}</span></h6>
                 <h6>&nbsp;&nbsp;&nbsp;Route:<span>&nbsp;&nbsp;&nbsp;</span><span>{infoData1.route}</span></h6>
                 <h6 style={{textAlign:"left"}}>&nbsp;&nbsp;&nbsp;Remarks:<span>&nbsp;&nbsp;&nbsp;</span> <span>{infoData1.remarks}</span></h6>
               </div>
             </div>
        
              
          
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderTableDate = (cellInfo) => {
    const datetimeString = tableData[cellInfo.index][cellInfo.column.id];
    const date = new Date(datetimeString);
    const formattedDate = date.toISOString().split('T')[0]; // Extract the date part
  
    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
        {formattedDate}
      </div>
    );
  };


  
  const columns1 = [
    // {
    //   Header: "Interactions",

      
 
    //   width: 120

    // },
    {
      Header: "Id",
      accessor: "request_pid",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 90,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterData(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },

    {
      Header: "Medicine",
      accessor: "request_items",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 200,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterData1(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },

    {
      Header: "Dispense Count",
      accessor: "dispensing_count",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 100,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterData5(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Date",
      accessor: "date",
      Cell: renderTableDate,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 80,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterData4(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Patient",
      accessor: "patient",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 200,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterData2(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Doctor",
      accessor: "doctor",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' ,color:"black"},

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 200,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterData3(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Nurse",
      accessor: "nurse",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' ,color:"black"},

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 200,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterData6(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: "Ward/Room",
      accessor: "admit_details",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 200,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterData7(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),

    },
    {
      Header: " Status",
      accessor: "status",
      Cell: renderStatus,
      sortable: false,
      filterable: true,
      style: { whiteSpace: "unset",color:"black" },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      width: 200,
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataStatus(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),
      width: 250,
    },
   
 
 
   
   ]

  return (
    <>
      <div
        id="patient-presc-page"
        className="appForm "
        style={{ width: "100%" }}
      >
        {/* <LoadingOverlay
          active={activating}
          spinner
          styles={{
            spinner: (base) => ({
              ...base,
              width: "50px",
              "& svg circle": {
                stroke: "rgba(0, 0, 255, 0.5)",
              },
            }),
            overlay: {},
          }}
        > */}

          <div className="patient-presc-sections patient-presc-sections2">
            <div className="table-holder ">
            <h5 className="text-center"><b>
          Medicine History
          </b></h5>
              <div className="service-table-holder" style={{ backgroundColor: "#efff001a", height: "34px" }}>

                <div class="row" style={{ textAlign: "left", paddingTop: "4px" }}>
                  <div class="col-5">
                    {/* <h5 className="" style={{ color: "var(--theme-blue)" }}><b> <i class="fa fa-th-large" aria-hidden="true"></i>{" "}Service History</b></h5> */}
                  </div>


                  <div class="col">

                  </div>
                  <div class="col">
                    <button  onClick={() => navigate("/service-request", { state: {from: "medHistory"} })} type="button" style={{ border: "none", float: "right", textDecoration: "none" }} class=" btn-link"> Pharmacy Fulfillment{" "}<i class="fa fa-angle-right" aria-hidden="true"></i></button>

                  </div>

                </div>
              </div>
           <ReactTable 
         columns={columns1}
         data={tableData}
         className="service-req-table"
         resizable
         minRows={perPage - 1}
         style={{ textAlign: "center" }}
         pageSizeOptions={[ perPage,Math.ceil(totalRows / 4), Math.ceil(totalRows / 3),Math.ceil(totalRows/ 2),totalRows]}
         //pageSizeOptions={[8,10,Math.ceil(totalRows1 / 10),totalRows1]}
         pages={Math.ceil(totalRows / perPage)}
         showPagination={tableData.length > 0} // Hide pagination if no data
         showPaginationNext={tableData.length > 0 && page < totalRows} // Show "Next" button if there is more data
         // onPageChange={(pageIndex) => {
         //   setPage(pageIndex + 1); // Update the page state
         // }}
        onPageChange={(pageIndex) => { handlePageChange(pageIndex);  }}
     onPageSizeChange={handlePerRowsChange}
     defaultPageSize={perPage}
      
                
         >

         </ReactTable> 
            </div>
          </div>
        {/* </LoadingOverlay>{" "} */}
      </div>
      {infoShow ? (
        <InfoPopup
          show={infoShow}
          onHide={() => {
            setinfoShow(false);
          }}
        />
      ) : (
        ""
      )}
     {infoShow1 ? (
        <InfoPopup1
          show={infoShow1}
          onHide={() => {
            setinfoShow1(false);
          }}
        />
      ) : (
        ""
      )}

    </>
  );
};

export default ServiceHistoryDoc;
