import React from 'react';
import ZoomableImage from '../../BDO/Admission/ZoomableImage';

function DetailView({ detail }) {
  if (detail.data.length === 0) {
    return <p>No details found</p>;
  }

  return (
    <div style={{ textAlign: 'left' }}>
      <h3 style={{ textAlign: 'center' }}>{detail.name}</h3>
      {detail.name === 'Residents Signature' && (
        <div>
          {detail.data[0].endsWith('.pdf') ? (
            <embed src={detail.data[0]} type="application/pdf" width="100%" height="600px" />
          ) : (
            <div style={{ textAlign: "center" }}>
            <ZoomableImage src={detail.data[0]} alt="Signature" style={{ maxWidth: "100%" }} />
            </div>
          )}
        </div>
      )}

      {detail.name === 'Witness' && (
        <>
          {[0, 1].map((index) => (
            <div key={index}>
              {detail.data[index * 4] && (
                <>
                  <p><b>{detail.data[index * 4]}</b></p>
                  <p><b>Name</b>: {detail.data[index * 4 + 1]}</p>
                  <p><b>Contact</b>: {detail.data[index * 4 + 2]}</p>
                </>
              )}
              {detail.data[index * 4 + 3] && (
                <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                  {detail.data[index * 4 + 3].endsWith('.pdf') ? (
                    <embed src={detail.data[index * 4 + 3]} type="application/pdf" width="100%" height="600px" />
                  ) : (
                    <div style={{ textAlign: "center" }}>
                    <ZoomableImage src={detail.data[index * 4 + 3]} alt={`Witness ${index + 1} Signature`} style={{ maxWidth: "100%" }} />
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </>
      )}

      {detail.name === 'Guardian' && (
        <>
          <p><b>Name</b>: {detail.data[0]}</p>
          <p><b>Address</b>: {detail.data[1]}</p>
          <p><b>Contact</b>: {detail.data[2]}</p>
          {detail.data[3] && (
            <div style={{ textAlign: 'center' }}>
              {detail.data[3].endsWith('.pdf') ? (
                <embed src={detail.data[3]} type="application/pdf" width="100%" height="600px" />
              ) : (
                <div style={{ textAlign: "center" }}>
                <ZoomableImage src={detail.data[3]} alt="Guardian Signature" style={{ maxWidth: "100%" }} />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default DetailView;
