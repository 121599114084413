import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "./service.css";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import { APIURL } from "../../../Global";
import { SOCKETURL } from "../../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import BdoNavbar from "../BdoNavbar";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import EditRemark from "./EditRemark";
import AddRemark from "./AddRemark";
import GenerateInvoiceView from "./GenerateInvoiceViewBdo";
import { useAlert } from 'react-alert' 
function ServiceRequests() {
  const alert = useAlert()
  var Typeahead = require("react-bootstrap-typeahead").Typeahead;
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(true);
  const [reRender, setRerender] = useState(false);
 
  const [, setGetError] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [optionsFiltered, setOptionsFiltered] = useState([]);
  const [, setOptionsDisplay] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [submitMsg, setSubmitMsg] = useState("");
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const [cell, setCell] = useState("");
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [backup, setBackup] = useState("");
  const [count, setCount] = useState(0);
  const [editShow, setEditShow] = useState(false);
  const [remarkData, setRemarkData] = useState("");
  const [remarkList, setRemarkList] = useState([]);
  const [consent, setConsent] = useState()
  const [patientId, setPatientId] = useState()
  const webSocket = useRef(null);
  const [patient, setpatient] = useState("");
  const [patient1, setpatient1] = useState("");
  const [orderbill, setorderBill] = useState("");
  const [orderType, setOrderType] = useState("");
  const [orderID, setOrderID] = useState("");
  const [contact, setContact] = useState("");
  const [payStatus, setPayStatus] = useState("");
  const [order, setOrder] = useState("")
  const [id, setId] = useState("");
  const [viewShow, setViewShow]= useState(false)
 const[hasNext,setHasNext]=useState(false)
  let navigate = useNavigate();
  const [changed, setChanged] = useState()
  const [flag, setFlag] = useState(false)
  const [saved, setSaved] = useState(false)
  let viewbutton = false
  const [currentIndex, setCurrentIndex] = useState("");
  const [, setCurrentColumn] = useState("");
  const [display, setDisplay] = useState(false);
  const [update, setUpdate] = useState(false)
  const [infoShow, setinfoShow] = useState(false);
  const [infoData, setInfoData] = useState("");
  const [type, setType] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const[page,setPage]=useState(1)
  let tableData2=[]
  // sessionStorage.setItem("usertoken", JSON.stringify(res.data.token));
 
 
  useEffect(() => {

    loadFuncser(page,perPage);
  },[perPage, reRender])
  useEffect(() => {
   
    setTimeout(() => {

      handlingWebsocket();
  }, 10000)
  },[])

  const loadFuncser = async  (page, per_page) => {
    setLoading(true);
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
   
    axios
      .get(`${APIURL}/api/v1/service-provider/service-request-sp/?page=${page}&per_page=${per_page}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setLoading(false); 
           let dataList = res.data.orders.filter((item, index) => {
            return (item.su_orders.length>0)
          });
           setTotalRows(res.data.total_records)
           let updatedList = [];
           updatedList = dataList.map((item, index) => {
            let status;
            let statusObj;
          let newDate=  new Date(Math.min.apply(null, item.su_orders.map(function(e) {           
              return new Date(e.request_due_date);
          
            })));
         
            if (item.request_status.toLowerCase() === "new") {
              status = "new";
              statusObj = { value: "new", label: "New" };
            } else if (item.request_status.toLowerCase() === "accepted") {
              status = "accepted";
              statusObj = { value: "accepted", label: "Accepted" };
            } else if (item.request_status.toLowerCase() === "ongoing") {
              status = "ongoing";
              statusObj = { value: "ongoing", label: "Ongoing" };
            } else if (item.request_status.toLowerCase() === "pending") {
              status = "pending";
              statusObj = { value: "pending", label: "Pending" };
            } else if (item.request_status.toLowerCase() === "delivered") {
              status = "delivered";
              statusObj = { value: "delivered", label: "Delivered" };
            } else if (item.request_status.toLowerCase() === "out_for_delivery") {
              status = "Out for delivery";
              statusObj = { value: "out_for_delivery", label: "out for delivery" };
            }
            let priority;
            let priorityObj;
            if (item.su_orders[0].request_priority.toLowerCase() === "critical") {
              priority = "critical";
              priorityObj = { value: "critical", label: "Critical" };
            }else if (item.su_orders[0].request_priority.toLowerCase() === "high") {
              priority = "high";
              priorityObj = { value: "high", label: "High" };
            } else if (item.su_orders[0].request_priority.toLowerCase() === "medium") {
              priority = "medium";
              priorityObj = { value: "medium", label: "Medium" };
            } else if (item.su_orders[0].request_priority.toLowerCase() === "low") {
              priority = "low";
              priorityObj = { value: "low", label: "Low" };
            }

            return {
              order_type:item.order_type,
              su_orders:item.su_orders,
              name: item.patient_name,
              order: item.order_type==="master_medicine"?"Medicines":item.order_type==="master_labtest"?"Lab Tests":item.su_orders[0].item_service_name,
              priority: priority,
              priorityObj: priorityObj,
              id_ord:item.id,
              ref: item.order_id,
              consent_level_id: parseInt(item.su_orders[0].consent_level_id),
              status: status,
              statusObj: statusObj,
              patient_id: item.patient,
              address: item.address,
              details: item.details,
              required_date:newDate,
              // required_date:  item.su_orders.length >0 ? item.su_orders.slice(-1)[0].request_due_date :item.su_orders[0].request_due_date,
              assigned_by: item.assigned_by !== null ? item.assigned_by : null,
              assigned_by_name:
                item.assigned_by_name !== null ? item.assigned_by_name : null,
              assigned_to: item.assigned_to !== null ? item.assigned_to : null,
              assigned_name:
                item.assigned_to_name !== null ? item.assigned_to_name : null,
              delivery_date: item.su_orders[0].tentative_delivery_date,
              quantity:item.order_type==="master_medicine"||item.order_type==="master_labtest"?item.su_orders.length:item.su_orders[0].request_quantity,
              contact: item.patient_contact_no,
              remarks: item.remarks,
              ordered_time: item.su_orders[0].created_at,
              subscribed: item.is_subscribed,
              paid_status:item.payment_details.paid_status,
              payment_mode:item.payment_details.payment_mode

            };
          });


          var arrSortunshiftByDate = updatedList.sort(
            (a, b) => new Date(b.ordered_time) - new Date(a.ordered_time)
          );
         
          tableData2 = arrSortunshiftByDate.filter(function(o1){
          
            return !tableData.some(function(o2){
                return o1.id_ord === o2.id_ord;          
            });
        })
         
     
            setTableData([...tableData, ...tableData2]);
       
        } else {
          setLoading(false);
          setGetError(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        setGetError("Error in fetching data");
      });
     
    
  };

  const handlingWebsocket=(props)=>{
    const tokenString = sessionStorage.getItem("usertoken");
    const hospId = sessionStorage.getItem("hospId");
    let str = tokenString.replace(/["]+/g, "");
    webSocket.current = new WebSocket(
      `${SOCKETURL}/ws/service_request/sp_` +
      hospId +
      "/?token=" +
      str
    );
    webSocket.current.onopen = () => {
      // on connecting, do nothing but log it to the console
    
    };

    webSocket.current.onmessage = (evt) => {
   
      // listen to data sent from the websocket server
      const message = JSON.parse(evt.data);
   
      setCount(message.message.count);
      if (message.message.message_type === "service_fulfillment_update") {
        setUpdate(true)
      }
    };

    webSocket.current.onclose = () => {
   
    
    };
  }

  
  const handlePageChange = page => {
    loadFuncser(page+1, perPage);
  }

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage);
    
  }
  
  useEffect(() => {
    setTableData(tableData);

  }, [render, tableData]);
  const statusOptions = [
    { value: "ongoing", label: "Ongoing" },
    { value: "accepted", label: "Accepted" },
    { value: "rejected", label: "Rejected" },
    { value: "cancelled", label: "Cancelled" },
    { value: "completed", label: "Completed" },
  ];
  const priorityOptions = [
    { value: "critical", label: "Critical" },
   
    { value: "high", label: "High" },
    { value: "medium", label: "Medium" },
    { value: "low", label: "Low" },
  ];

  
  const SubmitErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            {" "}
            {errorMsg !== ""
              ? errorMsg
              : "Error in Data Submission. Please try again!"}{" "}
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const UpdatePopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5 style={{ color: "green" }}>Please save to complete changes </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const renderAssigned2 = (cellInfo) => {
    const { ref } = cellInfo.original;
    const {
      assigned_name,
      assigned_to,
      consent_level_id,
      patient_id,
      assigned_by_name,
      status
    } = tableData.find(data => data.ref === ref);
    setConsent(consent_level_id);
    setPatientId(patient_id)
    return (
      <div>
        <div style={{ display: "flex" }}>
          <input
            style={{ backgroundColor: "#fafafa" }}
            value={assigned_name ? assigned_name : ""}
            className="form-control"
            name="assignee"

            onChange={(e) => {
              let a = 0
              if (status === "Out for delivery" || status === "delivered") {
                a = 1
              }
              if (a === 0) {
                e.preventDefault();
                setFlag(true)
                setChanged(cellInfo.index)
                const list = tableData;
                list[cellInfo.index].assigned_name = e.target.value;

                setTableData(list);

                setCurrentIndex(cellInfo.index);
                setCurrentColumn(cellInfo.column.id);

                setDisplay(true);
                let fdata = options.filter((value) => {
                  if (e.target.value === "") {
                    return value;
                  } else if (
                    value.name.toLowerCase().includes(e.target.value.toLowerCase())
                  ) {
                    return value;
                  }
                })
                setSearch(e.target.value);
                setOptionsFiltered(fdata)
              }
            }}
            onClick={() => {
              const tokenString = sessionStorage.getItem("usertoken");

              let str = tokenString.replace(/["]+/g, "");

              axios
                .get(
                  `${APIURL}/api/v1/service-provider/sr-consent-users/?consent_level_id=${consent_level_id}&patient_id=${patient_id}`,
                  {
                    headers: {
                      Authorization: "Token " + str,
                    },
                  }
                )
                .then((res) => {
                  const assignee_List = [];
                  if (res.data.status === "success") {
                    const assigneeList = res.data.message;
                    assigneeList.map((person, index) =>
                      assignee_List.push(person["assigned_by_name"])
                    );


                    setOptions(assigneeList);
                    setOptionsDisplay(assignee_List);

                  }
                })
                .catch((err) => { });
            }}

          />
          {assigned_to && (
            <OverlayTrigger
              trigger="hover"
              rootClose
              placement="top"
              overlay={
                <Tooltip className="my-tooltip" id="button-tooltip">
                  Assigned By:{" "}
                  {assigned_by_name !== null
                    ? assigned_by_name
                    : "Not Available"}{" "}

                </Tooltip>
              }
            >
              <i
                style={{
                  color: "#75759c",
                  paddingLeft: "5%",
                  paddingTop: "10%",
                }}
                className="fas fa-info-circle"
              ></i>
            </OverlayTrigger>
          )}</div>
        {currentIndex === cellInfo.index &&
          cellInfo.column.id === "assigned_name" &&
          display && (
            <div className="suggestions-box-holder">
              <button
                className="btn-suggestion btn btn-secondary btn-smallest"
                title="close"
                onClick={() => setDisplay(false)}
              >
                {" "}
                X{" "}
              </button>

              <div className="suggestions-box suggestions-assignee-box">
                {optionsFiltered.map((item, i) => {
                  return (
                    <div
                      className="scan-item"
                      key={i}
                      onClick={(e) => {
                        e.preventDefault();

                        setRender(!render);

                        const List = tableData;
                        List[cellInfo.index].assigned_to = item.id;
                        List[cellInfo.index].assigned_name = item.name;
                        setSearch(e.target.value)
                        setTableData(List);
                        setDisplay(false);
                      }}
                    >
                      <div className="search-item each-option-suggestion">

                        <h6 className=""> {item.name}</h6>

                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    );
  };

  const InvoiceViewPopup=(props)=>{
      
  
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
         backdrop="static"
          keyboard={false}
          dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
            <GenerateInvoiceView  patient={patient1} orderdetail={orderbill} ordertype={orderType} contactpt={contact} status ={payStatus} orderID={orderID}/>  
        
        </Modal.Body>
        <Modal.Footer>
           
          <Button variant="secondary" onClick={props.onHide}>Close</Button>
         
  
        </Modal.Footer>
      </Modal>
    );
  }
  const handleInfoPopUp = (item,type) => {
    setInfoData(item);
    setinfoShow(true);
    if(type==="master_medicine"){
      setType("Medicines")
    }else{
      setType("Lab Tests")
    }
  };
  const renderTable = (cellInfo) => {
    return (<>

      <div className="fixed-co" style={{textTransform: "capitalize"}}>
        {" "}

        {cellInfo.column.id === "name" && (
          <span style={{ color: "#75759c", paddingRight: "2%", marginTop: "3%", fontSize: "9px" }}  >
            {tableData[cellInfo.index].subscribed ?
              <OverlayTrigger
                trigger="hover"
                rootClose
                placement="top"
                overlay={
                  <Tooltip className="my-tooltip" id="button-tooltip">
                    Subscribed

                  </Tooltip>
                }

              >
                <div style={{ marginTop: "4px", fontSize: "11px" }} class="fa-stack ">

                  <i style={{ color: "purple" }} class="fa fa fa-circle fa-2x fa-stack-2x "></i>
                  <i style={{ color: "white", marginTop: "-6px" }} class="fa fa-stack-1x"><b>s</b></i>
                </div></OverlayTrigger> : ""}{" "}
            <OverlayTrigger
              trigger="hover"
              rootClose
              placement="top"
              overlay={
                <Tooltip className="my-tooltip" id="button-tooltip">
                  Contact No:{" "}
                  {tableData[cellInfo.index].contact !== null
                    ? tableData[cellInfo.index].contact
                    : "Not Available"}{" "}

                </Tooltip>
              }

            >
              <i

                className="fas fa-info-circle"
              ></i></OverlayTrigger>
          </span>


        )}
        {" "}

        {tableData[cellInfo.index][cellInfo.column.id]}

      </div></>
    );
  };
  const renderId = (cellInfo) => {
    if (tableData[cellInfo.index][cellInfo.column.id]) {
      return (
        <div className="">
          {" "}
          {tableData[cellInfo.index][cellInfo.column.id]}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderService = (cellInfo) => {
    const today = new Date();
    const offset = today.getTimezoneOffset();
    let dateUpdated = new Date(today.getTime() - offset);
    const m = moment(dateUpdated, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss");
    let ordered_time = tableData[cellInfo.index].ordered_time;
    let ot = moment.utc(ordered_time).toDate();
   
    var ms = moment(ot, "YYYY-MM-DD").format("YYYY-MM-DDTHH:mm:ss");
    let timediff = moment
      .duration(
        moment(m, "YYYY-MM-DDTHH:mm:ss").diff(moment(ms, "YYYY-MM-DDTHH:mm:ss"))
      )
      .asHours();
    let assigned = tableData[cellInfo.index].assigned_to;
   
    let colorCode = "black";
    if (!assigned) {
      if (timediff <= 1) {
        colorCode = "green";
      } else if (timediff > 1 && timediff <= 3) {
        colorCode = "orange";
      } else {
        colorCode = "red";
      }
    }

    return (
      <div style={{ display: "flex", textTransform: "capitalize" }}>
        {" "}

        <div
          style={{
            color: colorCode,

          }}
        >
          {tableData[cellInfo.index][cellInfo.column.id]}
        </div>
     {tableData[cellInfo.index].order_type==="master_medicine"||tableData[cellInfo.index].order_type==="master_labtest" ?<i
              style={{ color: "#75759c", paddingLeft: "5%" }}
              className="fas fa-info-circle fa-sm"
              onClick={() => {
               handleInfoPopUp(tableData[cellInfo.index].su_orders,tableData[cellInfo.index].order_type);
              }}
            ></i>:""}{" "}
      </div>
    );
  };

  const renderStatus2 = (cellInfo) => {
    return (
      <>
        <Select
          value={tableData[cellInfo.index].statusObj}
          onChange={(data) => {
            setChanged(cellInfo.index)
            const List = tableData;

            List[cellInfo.index].statusObj = data;
            List[cellInfo.index].status = data.value;
            setTableData(List);
            setRender(!render);
          }}
          options={statusOptions}
          className={`status-select ${tableData[cellInfo.index].status === "new"
            ? "new-status"
            : tableData[cellInfo.index].status === "accepted"
              ? "accepted-status"
              : tableData[cellInfo.index].status === "ongoing"
                ? "ongoing-status"
                : tableData[cellInfo.index].status === "pending"
                  ? "pending-status"
                  : tableData[cellInfo.index].status === "delivered"
                    ? "delivered-status"
                    : tableData[cellInfo.index].status === "out_for_delivery"
                      ? "out-for-delivery-status"
                      : ""

            }`}
        />
      </>
    );
  };

  const renderPriority2 = (cellInfo) => {
    return (
      <>
        <Select
          value={tableData[cellInfo.index].priorityObj}
          onChange={(data) => {
            setChanged(cellInfo.index)
            const List = tableData;

            List[cellInfo.index].priorityObj = data;
            List[cellInfo.index].priority = data.value;
            setTableData(List);
            setRender(!render);
          }}
          options={priorityOptions}
          className={`status-select ${tableData[cellInfo.index].priority === "critical"
            ? "critical-priority"
          
              : tableData[cellInfo.index].priority === "high"
                ? "high-priority"
                : tableData[cellInfo.index].priority === "medium"
                  ? "medium-priority"
                  : tableData[cellInfo.index].priority === "low"
                    ? "low-priority"
                    : ""
            }`}
        />
      </>
    );
  };

  const saveChanges = (index) => {
    const rowToUpdate = tableData.filter((elt, i) => {
      return i === index;
    });

    callSave(rowToUpdate[0]);
  };
  const validate = (rowToValidate) => {
    let isValid = true;

    if (!rowToValidate.status) {
      isValid = false;

      setErrorMsg("Enter status");

      setErrorSubmit(true);
    }
    if (!rowToValidate.priority) {
      isValid = false;

      setErrorMsg("Enter priority");

      setErrorSubmit(true);
    }

    return isValid;
  };

  const callSave = (rowToUpdate) => {
    if (validate(rowToUpdate)) {
      const tokenString = sessionStorage.getItem("usertoken");

      let str = tokenString.replace(/["]+/g, "");
      const order_id = rowToUpdate.id_ord;
      const assigned_to = rowToUpdate.assigned_to;
      let request_status = "";
      const tentative_delivery_date = rowToUpdate.delivery_date;
      // const remarks = rowToUpdate.remarks;
      if (rowToUpdate.status === "new" || rowToUpdate.status === "accepted" || rowToUpdate.status === "pending") {
        if (assigned_to !== null) {
          request_status = "ongoing";
        }
        else

          request_status = rowToUpdate.status;

      } else {
        request_status = rowToUpdate.status;
      }
      let dataToUpdate = {}
      const request_priority = rowToUpdate.priority;
      if (flag === true) {
        dataToUpdate = {
          order_id,
          assigned_to,
          request_status,
          request_priority,
          tentative_delivery_date,

        }
      }
      else {
        dataToUpdate = {
          order_id,
          request_status,
          request_priority,
          tentative_delivery_date,

        }
      }

      axios
        .put(
          `${APIURL}/api/v1/service-provider/service-request-order-update/${order_id}/`,
          dataToUpdate,
          {
            headers: {
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            setFlag(false)
           
            setBackup("");
            alert.success("updated successfully");
            setTableData([]);
            setRerender(!reRender);
          } else {
            setErrorMsg(
              res.data.message ? res.data.message : "Error in submission"
            );
            setErrorSubmit(true);
          }
        })
        .catch((err) => {
          setErrorMsg("Error in submission");
          setErrorSubmit(true);
        });
    }
  };
  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRemark
            data={remarkData}
            id={id}
            list={remarkList}
            update={handleSend}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button className="btn btn-primary btn-col" onClick={props.onSave}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <AddRemark update={handleSend} id={id} patient={patient} order={order} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const handleAdd = (index, id, name, order) => {
    setCell(index);
    setId(id);
    setpatient(name)
    setOrder(order)
    setModalShow(true);
  };

  const handleBill = (item) => {

    setpatient1(item.name)
    setorderBill(item.order)
    setOrderType(item.order_type)
    setContact(item.contact)
    setOrderID(item.id)
    setPayStatus(item.paid_status)
    // setOrder(order)
    setViewShow(true);
  };
  var sendData = null;

  function handleSend(childData) {
    sendData = childData;
  }


  const renderRemarks3 = (cellInfo) => {

    

    return (
      <>
        <i
          style={{ color: "rgb(91 138 218)",cursor:"pointer" }}
          onClick={() => handleAdd(cellInfo.index, tableData[cellInfo.index].id_ord, tableData[cellInfo.index].name, tableData[cellInfo.index].order)}
          className="fas fa-comment"
        ></i>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <OverlayTrigger trigger="hover" rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">

{tableData[cellInfo.index].payment_mode==="cod"? "COD":"Card Payment"}

</Tooltip>}>

<i style={{ color:tableData[cellInfo.index].payment_mode==="cod"? "green":"blue", cursor:"pointer" }} class={tableData[cellInfo.index].payment_mode==="cod"?"fa fa-money":"fa fa-cc-visa"} aria-hidden="true"></i>

</OverlayTrigger>
        
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <OverlayTrigger trigger="hover" rootClose placement="top" overlay={<Tooltip className='my-tooltip' id="button-tooltip">

                {tableData[cellInfo.index].paid_status==="not_paid"? "Not paid":tableData[cellInfo.index].paid_status==="paid"?"Paid":"Partially paid"}

              </Tooltip>}>
              
              <i style={{ color: tableData[cellInfo.index].paid_status==="not_paid"? "red":tableData[cellInfo.index].paid_status==="paid"?"green":"orange",cursor:"pointer" }} class="fa fa-product-hunt" aria-hidden="true"></i>

              </OverlayTrigger>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

              {/* {tableData[cellInfo.index].status==="delivered" ?
              <i class="fa fa-file" aria-hidden="true" style={{color:"#9f49ea6b"}} onClick={ ()=> handleBill(tableData[cellInfo.index])}></i>
              :null} */}
       </>
      // <i style={{ color: "rgb(91 138 218)" }} className="fas fa-credit-card"></i>
    );

  };
  const renderDueDate = (cellInfo) => {
    let dateString = tableData[cellInfo.index][cellInfo.column.id];
    let dateObject = new Date(dateString);

    const updatedDate = dateObject.toString();
    return (
      <div>
        <h6> {updatedDate.slice(4, 10)} </h6>
      </div>
    );
  };
  const renderDeliveryDate = (cellInfo) => {
    const today = new Date();
    const offset = today.getTimezoneOffset();
    let dateUpdated = new Date(today.getTime() - offset * 60 * 1000);
    let DateExtractedToday = dateUpdated.toISOString().split("T")[0];

    let DateExtracted = tableData[cellInfo.index].delivery_date
      ? tableData[cellInfo.index].delivery_date.split("T")[0]
      : "";

    let dateObject = new Date(DateExtracted);
    const updatedDate = dateObject.toString();
    return (
      <div>
        <input
          type="date"
          style={{ textAlign: "center" }}
          data-date={tableData[cellInfo.index].delivery_date ? updatedDate.slice(4, 10) : ""}
          className="date-custom-format"
          min={DateExtractedToday}
          onChange={(e) => {

            if (tableData[cellInfo.index].status !== "Out for delivery" && tableData[cellInfo.index].status !== "delivered") {
              setChanged(cellInfo.index)
              e.preventDefault();
              let date = e.target.value;
              const m = moment(date, "YYYY-MM-DD").format("YYYY-MM-DDTHH:MM:SS");

              const delivery_date = tableData;
              delivery_date[cellInfo.index].delivery_date = m;

              setTableData([...delivery_date]);
            }
          }}
        />
      </div>
    );
  };

  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"><h5>{type}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
           
            
        {infoData.map((item,index) => {
             return (            
             <div className="row-of-features">
               <div className="col-of-features"><br/>
                 <h6> {index+1}&nbsp;{item.item_service_name}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Quantity:&nbsp;{item.request_quantity}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Requested Due Date:&nbsp;{item.request_due_date}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Priority:&nbsp;{item.request_priority}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Status:&nbsp;{item.request_status}</h6>
                 <h6>&nbsp;&nbsp;&nbsp;Amount:&nbsp;{item.order_amount}</h6>
             

              
               </div>
             </div>
             );
          })}
              
          
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const columns = [
    {
      Header: "Id",
      accessor: "ref",
      Cell: renderId,

      sortable: false,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 100,
      minWidth: 100,
    },

    {
      Header: "Service Request",
      accessor: "order",
      Cell: renderService,
      sortable: false,
      filterable: true,
      style: { whiteSpace: "unset" },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 120,
    },

    {
      Header: "Qty",
      accessor: "quantity",
      Cell: renderTable,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
        textAlign: "center"
      },

      width: 60,
      filterable: true,
      sortable: false,
    },
    {
      Header: "Due Date",
      accessor: "required_date",
      Cell: renderDueDate,
      style: {
        whiteSpace: "unset",
        backgroundColor: "rgba(0,0,0,0.1)",
        textAlign: "center"
      },

      width: 90,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        let dateString = String(row[id]);
        let dateObject = new Date(dateString);
        const updatedDate = dateObject.toString();
        return row[id] !== undefined ? updatedDate.toLowerCase().includes(filter.value.toLowerCase()) : true
      },
      sortable: false,
    },

    {
      Header: "Name",
      accessor: "name",
      Cell: renderTable,
      style: { whiteSpace: "unset" },
      sortable: false,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 130,
    },

    {
      Header: "Priority",
      accessor: "priorityObj",
      Cell: renderPriority2,
      sortable: false,
      filterable: true,
      style: { whiteSpace: "unset" },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        
        if (filter.value === "high") {
          return row[filter.id].value === "high";
        }

        if (filter.value === "critical") {
          return row[filter.id].value === "critical";
        }
        if (filter.value === "medium") {
          return row[filter.id].value === "medium";
        }
        if (filter.value === "low") {
          return row[filter.id].value === "low";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="critical">Critical</option>
        
          <option value="high">High</option>
          <option value="medium">Middle</option>
          <option value="low">Low</option>
        </select>
      ),

      width: 120,
    },

    {
      Header: " Status",
      accessor: "statusObj",
      Cell: renderStatus2,
      sortable: false,
      filterable: true,
      style: { whiteSpace: "unset" },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value.toLowerCase() === "ongoing") {
          return row[filter.id].value === "ongoing";
        }
        if (filter.value.toLowerCase() === "accepted") {
          return row[filter.id].value === "accepted";
        }
        if (filter.value.toLowerCase() === "new") {
          return row[filter.id].value === "new";
        }

        if (filter.value.toLowerCase() === "pending") {
          return row[filter.id].value === "pending";
        }
        if (filter.value.toLowerCase() === "delivered") {
          return row[filter.id].value === "delivered";
        }
        if (filter.value.toLowerCase() === "out_for_delivery") {
          return row[filter.id].value === "out_for_delivery";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          className="serviceinput"
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="new">New</option>
          <option value="accepted">Accepted</option>
          <option value="ongoing">Ongoing</option>
          <option value="pending">Pending</option>
          <option value="delivered">Delivered</option>
          <option value="out_for_delivery">Out for Delivery</option>
        </select>
      ),

      width: 150,
    },
    {
      Header: "Assignee",
      accessor: "assigned_name",
      style: { whiteSpace: "unset", backgroundColor: "rgba(0,0,0,0.1)" },
      Cell: renderAssigned2,
      sortable: false,
      filterable: true,

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined || row[id] !== ""
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 150,
    },

    {
      Header: "Delivery Date",
      accessor: "delivery_date",
      Cell: renderDeliveryDate,
      style: {
        // 'whiteSpace': 'unset',
        backgroundColor: "rgba(0,0,0,0.1)",
      },

      width: 120,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        let dateString = String(row[id]);
        let dateObject = new Date(dateString);
        const updatedDate = dateObject.toString();
        return row[id] !== undefined ? updatedDate.toLowerCase().includes(filter.value.toLowerCase()) : true
      },
      sortable: false,
    },
    {
      Header: "",
      accessor: "remarks",
      Cell: renderRemarks3,
      sortable: false,
      style: { textAlign: "center" },
      // filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      width: 120,
    },
    


    {
      Header: "",
      style: { textAlign: "center" },
      Cell: (props) => {

        if (tableData[props.index].status !== "Out for delivery" && tableData[props.index].status !== "delivered") {
          viewbutton = true
        }
        return (
          changed === props.index && viewbutton === true && <button
            className="btn btn-primary btn-col btn-square btn-smallest"
            onClick={() => {
              setSaved(true)
              saveChanges(props.index);
            }}
          >
            Save{" "}
          </button>
        );

      },
      sortable: false,
      filterable: false,
      minWidth: 50,
      width: 50,
    },
  ];

  const displayTable = (
    <ReactTable
      columns={columns}
      data={tableData}
      defaultPageSize={perPage-1}
      className="service-req-table"
      style={{ overflow: "wrap", textAlign: "left", width: "1230px" }}
      resizable
      pagination
      paginationServer
      paginationTotalRows={totalRows}
     
      onPageChange={(pageIndex) => {handlePageChange(pageIndex);setPage(pageIndex+1)}}
      onPageSizeChange={handlePerRowsChange}
    ></ReactTable>
  );

  const reloadTable = (e) => {

    setLoading(true);

    setCount(0);
    setUpdate(false)
    setTableData([]);
    setRerender(!reRender);
    
  };

  return (
    <>

      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "rgba(0, 0, 255, 0.5)",
            },
          }),
          overlay: {},
        }}
      >
        <div className="">
          <div className="text-center">
            <h5 className=""><b>
              Service Fulfillment
            </b></h5>
            <div className="service-table-holder ">
              <div className="service-table-holder" style={{ backgroundColor: "#efff001a", height: "34px", width: "1230px" }}>
                <div  >
                  <div class="row" style={{ textAlign: "left", paddingTop: "4px" }}>
                    <div class="col-5">
                      {/* <h5 className="" style={{ color: "var(--theme-blue)" }}><b> <i class="fa fa-th-large" aria-hidden="true"></i>{" "}Service Fulfillment</b></h5> */}
                    </div>
                    <div class="col">
                      <button onClick={reloadTable}
                        type="button"
                        style={{
                          border: "none",
                          textDecoration: "none",
                          curser: "pointer",
                          color: "#007bff",
                        }}
                        className="notification1  btn-link"
                      >
                        <span
                          style={{ fontSize: 7, curser: "pointer", }}
                          className="badge"
                        >
                          {count}
                        </span>
                        New orders

                      </button>

                    </div>
                    <div class="col">
                      <button onClick={reloadTable}
                        style={{
                          border: "none",
                          textDecoration: "none",
                          color: "#007bff",
                        }}
                        className="notification1  btn-link"
                      >
                        {update ? <span
                          style={{ fontSize: 5, color: "red" }}
                          className="badge"
                        >
                          1
                        </span> : ""}
                        Updates

                      </button>
                    </div>
                    <div class="col">
                      <button disabled type="button" style={{ border: "none", textDecoration: "none" }} class=" btn-link"> Save All</button>
                    </div>
                    <div class="col">
                      <button onClick={() => navigate("/service_history")} type="button" style={{ border: "none", textDecoration: "none" }} class=" btn-link"> Service History{" "}<i class="fa fa-angle-right" aria-hidden="true"></i></button>

                    </div>

                  </div>
                </div>
              </div>

              {displayTable}</div>
          </div>
        </div>
        <br />
        <br />

        <div className="info-notes-sr flex-row">
          <div className="info-div italics-text">
            <h6 className="">
              {" "}
              - Status: New, ongoing, accepted , pending and cancelled requests
              are listed here{" "}
            </h6>

            <h6 className="">
              {" "}
              - Click on column header/title to sort the respective column in
              table{" "}
            </h6>
            <h6 className="">
              {" "}
              - Filter the result by searching in the input field at each column
              header{" "}
            </h6>
            <h6 className="">
              {" "}
              - Multisorting and multifiltering can be done at multiple columns{" "}
            </h6>
          </div>
        </div>
        {modalShow ? (
          <AddPopup
            show={modalShow}
            onHide={() => {
              setModalShow(false);
            }}
          />
        ) : null}
        {editShow ? (
          <EditPopup
            show={editShow}
            onHide={() => {
              setEditShow(false);
            }}
            onSave={() => {
              if (sendData !== tableData[cell].remarks) {
                const data = tableData;
                data[cell].remarks = sendData;
                setBackup(sendData);
                setTableData(data);
                setEditShow(false);
                setRender(!render);
                setUpdateSuccess(true);
                //saveChanges(cell)
              }
            }}
          />
        ) : null}

        {errorMsg !== "" ? (
          <SubmitErrorPopup
            show={errorSubmit}
            onHide={() => {
              setErrorSubmit(false);
              setErrorMsg("");
              setTableData([]);
              setRerender(!reRender);
            }}
          />
        ) : (
          ""
        )}
 {infoShow ? (
        <InfoPopup
          show={infoShow}
          onHide={() => {
            setinfoShow(false);
          }}
        />
      ) : (
        ""
      )}
      
        {
      viewShow ? 
      <InvoiceViewPopup
        show={viewShow}
        onHide= {() => { setViewShow(false);    
        }}
      /> : null
    } 

        {updateSuccess ? (
          <UpdatePopup
            show={updateSuccess}
            onHide={() => {
              setUpdateSuccess(false);
            }}
          />
        ) : null}
      </LoadingOverlay>
    </>
  );
}

export default ServiceRequests;
