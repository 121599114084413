import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { APIURL } from "../../../../Global";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

function ControlledExpandedNode(props) {
  const [loading, setLoading] = useState(false);
  const [floors, setFloors] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [wards, setWards] = useState([]);
  const [perPage] = useState(10);
  const [expand, setExpand] = useState();
  const [expand1, setExpand1] = useState();
  const [bname,setBName]=useState("")
  const [Fname,setFName]=useState("")
  const [bid,setBid]=useState("")
  const [Fid,setFid]=useState("")
  
  const [buildings, setBuildings] = useState([]);
  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index);
    } else {
      setExpand();
    }
  };
  const handleDetail1 = (index) => {
    if (expand1 !== index) {
      setExpand1(index);
    } else {
      setExpand1();
    }
  };
  const handleonSuccess = (id,name) => {
   props.onSuccess({id:id,name:name})
  };
  useEffect(() => {


    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/building/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setBuildings(res.data);

        }
      });
  }, []);

  const handleFloorClick = (floor_id) => {


    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/floor-info/?floor_id=${floor_id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRooms(res.data.room);
          setWards(res.data.ward)

        }
      });

  };
  const handleBuildingClick = (building) => {


    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/service-provider/floor/?building=${building}`, {
        headers: {
          Authorization: "Token " + str,

        },
      })
      .then((res) => {
        if (res.status === 200) {
          setFloors(res.data);

        }
      });

  };
  let display2 =
    rooms.length > 0
      ? rooms.map((data, index) => {
        return (<>
          <div style={{ marginLeft: "18%", cursor:"pointer" }} className="">

            <h6 style={{ display: "flex" }} className="">



              <h6  onClick={() => {handleonSuccess(bid+Fid+"R"+data.room_number,bname+"/"+Fname+"/"+data.room_name)}}>
                {" "}
                {data.room_name}
                {"  "}

              </h6>

            </h6>

          </div></>
        );
      })
      : null;
  let display3 =
    wards.length > 0
      ? wards.map((data, index) => {
        return (<>
          <div style={{ marginLeft: "18%" }} className="">

            <h6 style={{ display: "flex" }} className="">



              <h6 onClick={() => {handleonSuccess(bid+Fid+"W"+data.ward_number,bname+"/"+Fname+"/"+data.ward_name)}}>
                {" "}
                {data.ward_name}
                {"  "}

              </h6>

            </h6>

          </div></>
        );
      })
      : null;

  let display1 =
    floors.length > 0
      ? floors.map((data, index) => {
        return (<>
          <div style={{ marginLeft: "8%", cursor:"pointer" }} className="">

            <h6 style={{ display: "flex" }} className="">
              <Link
                to="#"
                style={{ textDecoration: "none" }}
                // className={
                //   expand === index ? "feature-data mt-3" : "feature-data"
                // }
                onClick={() => { handleDetail1(index); handleFloorClick(data.id); setRooms([]); setWards([]);setFName(data.floor_name);setFid("F"+data.floor_number) }}
              >
                <i
                  className={
                    expand1 === index
                      ? "fa fa-angle-up fa-fw"
                      : "fa fa-angle-down fa-fw"
                  }
                  style={{ color: "#6788bc " }}
                ></i>
              </Link>
              &nbsp;&nbsp;


              <h6  onClick={() => {handleonSuccess(bid+"F"+data.floor_number,bname+"/"+data.floor_name)}}>
                {" "}
                {data.floor_name}
                {"  "}

              </h6>

            </h6>
            {expand1 === index ? (
              <>
                <SimpleBar style={{ maxHeight: "200px", }}>
                  {rooms.length > 0 && <div style={{ marginLeft: "15%" }} className="">

                    <h6  style={{ color: "blue", textDecoration: "underline" }} className="">Rooms</h6></div>}
                  {display2}
                 
                  {wards.length > 0 && <div style={{ marginLeft: "15%" }} className="">

                    <h6 style={{ color: "blue", textDecoration: "underline" }} className="">Wards</h6></div>}
                  {display3}
                </SimpleBar>
              </>
            ) : null}
          </div></>
        );
      })
      : null;

  let display =
    buildings.length > 0
      ? buildings.map((data, index) => {
        return (
          <div style={{ paddingTop: "1%", cursor:"pointer"}} className="">

            <h6 style={{ display: "flex" }} className="">
              <Link
                to="#"
                style={{ textDecoration: "none" }}
                // className={
                //   expand === index ? "feature-data mt-3" : "feature-data"
                // }
                onClick={() => { handleDetail(index); handleBuildingClick(data.build_number); setFloors([]); setExpand1(false) ;setBName(data.build_name);setBid("B"+data.build_number)}}
              >
                <i
                  className={
                    expand === index
                      ? "fa fa-angle-up fa-fw"
                      : "fa fa-angle-down fa-fw"
                  }
                  style={{ color: "#6788bc " }}
                ></i>
              </Link>
              &nbsp;&nbsp;


              <h6 onClick={() => {handleonSuccess("B"+data.build_number,data.build_name)}}>
                {" "}
                {data.build_name}
                {"  "}

              </h6>

            </h6>
            {expand === index ? (
              <>
                {display1}
              </>
            ) : null}
          </div>
        );
      })
      : null;

  return (
    <>

      <div className="container">

      <div className="">
          {display ? (<>
            <button onClick={(e) =>{setFloors([]);setWards([]);setRooms([]);setExpand(null);setExpand1(null);handleonSuccess("","Organization")}}
            type="button"
            style={{
              border: "none",
              textDecoration: "none",
              curser: "pointer",
             color: "black",
             paddingBottom:"3%"
              // float:"right"
            }}
            className="notification1  btn-link"
          >
         
           <b>Organization</b>
          </button><br/>
           { display}
            </>) : (
            <div
              style={{
                color: "red",
                fontSize: "25px",
                height: "200px",
                padding: "40px",
              }}
            >
              {!loading ? <h4>No list to show!!</h4> : ""}
            </div>
          )}
        </div>
      </div>

    </>
  );
}

export default ControlledExpandedNode;
