import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../Global";
import "./prof.css"
import service2 from "../../../assets/images/2.png";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import { CartContext } from "../AddToCartContext";
function MyOrders() {
    const [distance, setDistance] = useState(0.0);
    const [orders, setOrders] = useState([])
    const [order, setOrder] = useState({})
    const [display, setDisplay] = useState(false)
    const [call, setCall] = useState(false)
    const [hasNext, setHasNext] = useState(false)
    const [page,setPage]=useState(1);
    const [load,setLoad]=useState(false);
    const [shipping, setShipping]=useState({})
    const [orderDetails,setOrderDetails]=useState([])
    const { handleAddToCart } = useContext(CartContext);

   
    useEffect(() => {

console.log(service2)
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );
        axios.get(`${APIURL}/api/v1/patient/orders/?page=${page}&per_page=16`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.status === 200) {
                    //setBanerData(res.data)
                    if (res.data.results.length) {
                       
                        setHasNext(res.data.has_next)
                        setPage(res.data.page)
                       
                        let data=[]
                        if (res.data && res.data.results && res.data.results.length > 0) {
                          
                          data= res.data.results
                           
                          
                           
                            setOrders([...orders,...data]);
                           setLoad(!load)
                    }}
                    else {

                    }

                }
            })
    }, [call]);
    useEffect(() => { CheckHasNext()},[load])
    const CheckHasNext = () => {
       
        if (hasNext) {
            setPage(page+1);
            setCall(!call)
        }
    }

    const handleOrder = (order) => {
        setOrder(order)
        getDetails(order.order_id);
        setDisplay(true)
    };
    const getDetails = async (id) => {
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );


        axios.get(`${APIURL}/api/v1/patient/order-log/?order_id=${id}`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then(res => {
                if (res.data.status === "success") {
                  setShipping(res.data.shipping_details)
                  setOrderDetails(res.data.order_details)
                }
                else {
                    alert.error(res.data.message)
                }

            })

    }
    const findDiff= (date, today)=>{
        var time_difference = new Date(date).getTime()+1 - new Date(today).getTime(); 
        var result = time_difference / (1000 * 60 * 60 * 24);
        
        return result
    }
     const addCart = () => {
       
            let date =  new Date()
            let localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
            const dataToSend = {
                item_quantity: 1,
                id: order.item_service,
                service_category: order.order_type,
                date: localDate.toISOString().split("T")[0],
                item_service_priority: "critical",
                distance: distance,
                hospital: order.hospital_name,
                item_name: order.item_service_name,
                image: order.image,
                amount: order.order_amount,
                
               

            };
            handleAddToCart(dataToSend)

    }
    return (
        <div className="row full-div container">
            
            <div className="col-4" >
            <SimpleBar style={{ maxHeight: "600px"}}>
                {orders.length > 0 ? orders.map((value) => {
                    return (
                        <div onClick={() => handleOrder(value)} style={{marginRight:"1%", marginTop: "2%", marginBottom: "0.5%" }} className="each-list-doc">
                            <div className="row">
                                <div className="col-3" >
                                    <div className="flex align-items-center" style={{ padding: "2%" }}>
                                        <img style={{ borderRadius: "50%", width: "80px", height: "80px" }} src={value.image!==null?value.image:service2} alt="image" />

                                    </div>
                                </div>
                                <div className="col-7">
                                    
                                    <span className='doc-details'>{value.request_status==="completed"?<span  style={{fontWeight:"bold"}}>Delivered</span>
                                    :value.tentative_delivery_date!==null?<span style={{color:"green",fontWeight:"bold"}}>Expected to delivered on {" "}{new Date(value.tentative_delivery_date).toISOString().slice(0, 10)}</span>
                                    :value.delivery_time_period!==null?findDiff(new Date(new Date(value.request_due_date).setDate(new Date(value.request_due_date).getDate()+value.delivery_time_period)),new Date())<-1?<span style={{color:"red",fontWeight:"bold"}}>Delayed</span>:<span style={{color:"green",fontWeight:"bold"}}><>Expected to delivered on  {" "}{new Date(new Date(value.request_due_date).setDate(new Date(value.request_due_date).getDate()+value.delivery_time_period)).toISOString().slice(0, 10)}</></span>
                                    :findDiff(new Date(value.request_due_date),new Date())<-1?<span style={{color:"red",fontWeight:"bold"}}>Delayed</span>:<span style={{color:"green",fontWeight:"bold"}}><>Expected to delivered on {" "} {new Date(value.request_due_date).toISOString().slice(0, 10)}</></span>}</span><br />
                                    <span className='content-appointment'>{value.item_service_name}</span><br />
                                    <div style={{ display: "flex", color: "gold", fontSize: "12px" }} className="products-review">
                                        <div className="rating">
                                            <i className='bx bxs-star bx-lg'></i>&nbsp;
                                            <i className='bx bxs-star'></i>&nbsp;
                                            <i className='bx bxs-star'></i>&nbsp;
                                            <i className='bx bxs-star'></i>&nbsp;
                                        </div>
                                        <a href="#" className="rating-count"><small>4 stars</small></a>
                                    </div>
                                </div>
                            </div>
                        </div>)
                }) : ""}
                 </SimpleBar>
                 
            </div>
           
            <div className="col-7">
            <div className="order-devider"></div>
           
                {display && <>
                    <div><span style={{ paddingLeft: "10%" }} className='content-appointment'>Order ID-{order.order_id}</span></div>
                    <hr  style={{color:"#007E85",borderTop:"3px solid"}} className="horizondal" />
                    <div className="row" >
                        <div className="col-2"></div>
                        <div className="col-3" >
                            <div className="flex align-items-center" style={{ padding: "2%" }}>
                                <img style={{ borderRadius: "50%", width: "80px", height: "80px" }} src={order.image===null?service2:order.image} alt="image" />

                            </div>
                        </div>
                        <div className="col-5">

                            <span className='content-appointment'>{order.item_service_name}</span><br />
                            <span className='content-appointment'>${order.order_amount}</span><br />
                            <span className='doc-details'>{order.hospital_name}</span><br />

                        </div>
                        <hr className="horizondal" />
                        <div style={{ paddingLeft: "15%" }}>
                            <div className="row" style={{ paddingLeft: "2%" }}>
                                <div style={{ paddingTop: "1.5%" }} className="col-2"><i style={{ color:orderDetails.length>0? "#11C057":"gray" }} class="fa fa-check-square"></i></div>
                                <div className="col-6">
                                    <span className='doc-details'>Order Confirmation </span><br />
                                   {orderDetails.length>0?<> <span className='doc-details'>{new Date(orderDetails[0].created_at).toISOString().slice(0, 10)} </span>&nbsp;<span className='doc-details'>|&nbsp;{new Date(orderDetails[0].created_at).toLocaleTimeString()}</span></>:""}
                                </div>

                            </div>
                            <div style={{ marginLeft: "2.7%" }}  className={orderDetails.length>1?"vl":"v2"}></div>
                            <div className="row" style={{ paddingLeft: "2%" }}>
                                <div style={{ paddingTop: "1.5%" }} className="col-2"><i style={{color:orderDetails.length>1? "#11C057":"gray"}} class="fa fa-check-square"></i></div>
                                <div className="col-6">
                                    <span className='doc-details'>Dispatched  </span><br />
                                    {orderDetails.length>1? <><span className='doc-details'>{new Date(orderDetails[1].created_at).toISOString().slice(0, 10)} </span>&nbsp;<span className='doc-details'>|&nbsp;{new Date(orderDetails[1].created_at).toLocaleTimeString()}</span></>:""}
                                </div>

                            </div>
                            <div style={{ marginLeft: "2.7%" }} className={orderDetails.length>2?"vl":"v2"}></div>
                            <div className="row" style={{ paddingLeft: "2%" }}>
                                <div style={{ paddingTop: "1.5%" }} className="col-2"><i style={{ color:orderDetails.length>2? "#11C057":"gray" }} class="fa fa-check-square"></i></div>
                                <div className="col-6">
                                    <span className='doc-details'>Delivered</span><br />
                                    {orderDetails.length>2?<> <span className='doc-details'> {new Date(orderDetails[2].created_at).toISOString().slice(0, 10)}</span>&nbsp;<span className='doc-details'>|&nbsp;{new Date(orderDetails[2].created_at).toLocaleTimeString()}</span></>:""}
                                </div>

                            </div>
                        </div>
                        <hr className="horizondal"/>
                        
                        <div style={{ paddingLeft: "15%", display: "flex", color: "gold", fontSize: "15px" }} className="products-review">
                            <div className="rating">
                                <i className='bx bxs-star'></i>&nbsp;&nbsp;
                                <i className='bx bxs-star'></i>&nbsp;&nbsp;
                                <i className='bx bxs-star'></i>&nbsp;&nbsp;
                                <i className='bx bxs-star'></i>&nbsp;&nbsp;

                            </div>
                            <a href="#" className="rating-count"><small>4 stars</small></a>
                            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                            <span className='doc-details'style={{textDecoration:"underline", color:"blue", marginTop:"0.7%"}}> write a review</span>
                        </div>
                        <hr className="horizondal"/>
                        <div><span style={{ paddingLeft: "10%" }} className='content-appointment'>Shipping Details </span></div>
                        <hr className="horizondal"/>
                        <div style={{ paddingLeft: "15%" }}>
                        <span className='doc-details'><b>{shipping.first_name}</b></span>&nbsp;<span className='doc-details'><b>{shipping.last_name}</b></span><br />
                        <span className='doc-details'>{shipping.apt_no}</span><br />
                        <span className='doc-details'>{shipping.landmark}</span><br />
                        <span className='doc-details'>{shipping.province}-</span>
                        <span className='doc-details'>{shipping.pincode}</span><br />
                        <span className='doc-details'>{shipping.state}</span><br />
                        <span className='doc-details'><b>Mobile Number-{shipping.mobile_number}</b></span><br />
                        </div><br/>

                        <div style={{ paddingLeft: "10%" }}>
                            <button onClick={() => { addCart() }} style={{width:"20%",padding:"1%"}} className='book-appointment-btn'>Buy Again</button>
                        </div>
                    </div></>}

            </div>
        </div>
    )
}

export default MyOrders;