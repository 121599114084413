import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from "react-router-dom";
import ItemList from './ItemList'
import './treat.css'
import axios from "axios";
import { APIURL } from "../../../Global";
import { DateRangePicker } from "rsuite";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment'
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { useAlert } from "react-alert";
import { Modal } from 'react-bootstrap';
import EditPlan from './EditPlan';
import CurrentMedications from '../CurrentMedications';
import AddMedicinesPat from '../AddMedicinesPat';
import PastMedications from '../PastMedications';
import ManageProcedure from '../Procedure/ManageaProcedure';
import ManageScan from '../TestScan/ManageScan';
import ManageTest from '../TestScan/ManageTest';
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
const { allowedRange } = DateRangePicker;
function TreatPlanDetails() {
  const [editShow, setEditShow] = useState(false);
  const location = useLocation();
 
  const id =  location.pathname.split('/').pop() ;
  const alert = useAlert();
  const [temp, setTemp] = useState({})
  const [isCopied, setIsCopied] = useState(false)
  const [item, setItem] = useState({})
  const [items, setItems] = useState([])
  const [foodItems, setFoodItems] = useState([])
  const [liquidItems, setLiquidItems] = useState([])
  const [consItems, setConsItems] = useState([])
  const [type, setType] = useState( location.state?location.state.cat:"medicine")
  const [time, setTime] = useState({ morning: false, afternoon: false, evening: false, night: false })
  const [timeArray, setTimeArray] = useState([])
  const [date1, setDate1] = useState({ from: "", to: "" })
  const [n, setN] = useState(1)
  const [LiquidList, setLiquidList] = useState([])
  const [foodList, setFoodList] = useState([])
  const [consList, setConsList] = useState([])
  const [LiquidList1, setLiquidList1] = useState([])
  const [foodList1, setFoodList1] = useState([])
  const [consList1, setConsList1] = useState([])
  const [foods1, setFoods1] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [refresh1, setRefresh1] = useState(false)
  const [isSave, setIsSave] = useState(false)
 const [medaction,setMedaction]=useState("view")
  let ref = useRef();
  var dateObj = new Date();
  // subtract one day from current time                        
  dateObj.setDate(dateObj.getDate());
  const [foods, setFoods] = useState([{}])
  const [updateData, setUpdateData] = useState()
  const [foodsClear, setFoodsClear] = useState([{}])
 
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const newItems = [...items];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setItems(newItems)
  }
 
  useEffect(() => {


    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(
        `${APIURL}/api/v1/doctor/liquid-instruction/?patient_id=${id}&src=web/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        }
      )
      .then((res) => {

        if (res.data.status === "success") {
          let arr = res.data.liquid

          setLiquidList1(arr)
          if (type === "liquid") {
            setFoods1(arr)
          }

        }

      })
    axios
      .get(
        `${APIURL}/api/v1/doctor/food-instruction/?patient_id=${id}&src=web/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        }
      )
      .then((res) => {

        if (res.data.status === "success") {
          let arr = res.data.food
          setFoodList1(arr)
          if (type === "food") {
            setFoods1(arr)
          }

        }

      })
    axios
      .get(
        `${APIURL}/api/v1/doctor/patient-consumable/?patient_id=${id}&src=web/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        }
      )
      .then((res) => {

        if (res.data.status === "success") {
          let arr = res.data.consumables
          setConsList1(arr)
          if (type === "consumables") {
            setFoods1(arr)
          }

        }

      })


  }, [refresh]);

  useEffect(() => {
    if (foods.till_date === undefined) {
      let n = 1
      let today = new Date()
      let arr = []
      while (n < 16) {

        arr.push({
          food: [],
          till_date: today + ""
        })
        today.setDate(today.getDate() + 1);
        n++;


      }
      let dataList = (arr.filter(item => {
        return item.till_date !== ''
      }))
      setFoodsClear(dataList)

    }
  }, [])

  useEffect(() => {

    setFoods(JSON.parse(JSON.stringify(foodsClear)))
    setIsCopied(false)
    setTime({ morning: false, afternoon: false, evening: false, night: false })


  }, [foodsClear, type])

  const handleEvent1 = (event, picker) => {
    const a = moment(event[0]).format('YYYY-MM-DDThh:mm');
    let b = moment(event[1]).format('YYYY-MM-DDThh:mm');

    setDate1({ from: a, to: b })
  }

  const handleClickPaste = (till_date) => {
    setIsSave(true)
    let filtered = foods.filter((product) => {
      if (product.till_date === till_date) {
        temp["serv_time"] = timeArray
        product["food"].push(JSON.parse(JSON.stringify(temp)))
      }
    })
    const newArr = [...foods];
    const index = newArr.indexOf(filtered.till_date)
    if (index !== -1) {
      newArr[index] = item;
    }

    if (type === "liquid") {
      setLiquidList(newArr)
    }
    else if (type === "food") {
      setFoodList(newArr)
    }
    else if (type === "consumables") {
      setConsList(newArr)
    }

  }

  const callSave = () => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    let url = ""
    let foods = []
    let data = {}
    let DataToSend = {}
    if (type === "liquid") {
      url = "/api/v1/doctor/liquid-instruction/"
      LiquidList.map((item) => {
        if (item.food.length > 0) {
          item.food.map((x) => {
            data = {
              liquid_name: x.name,
              quantity: x.qty,
              serv_time: x.serv_time,
              till_date: moment(item.till_date).format('YYYY-MM-DDThh:mm')
            }
            foods.push(data)
          })

        }
      })
      DataToSend = {
        patient_id: id,
        liquids: foods
      }
    } else if (type === "food") {
      url = "/api/v1/doctor/food-instruction/"
      foodList.map((item) => {
        if (item.food.length > 0) {
          item.food.map((x) => {
            data = {
              food_name: x.name,
              quantity: x.qty,
              calorie: x.cal,
              serv_time: x.serv_time,
              till_date: moment(item.till_date).format('YYYY-MM-DDThh:mm')
            }
            foods.push(data)
          })

        }
      })
      DataToSend = {
        patient_id: id,
        foods: foods
      }
    }
    else if (type === "consumables") {
      url = "/api/v1/doctor/patient-consumable/"
      consList.map((item) => {
        if (item.food.length > 0) {
          item.food.map((x) => {
            data = {
              consumable_name: x.name,
              quantity: x.qty,
              consumable: x.consId,
              serv_time: x.serv_time,
              till_date: moment(item.till_date).format('YYYY-MM-DDThh:mm')
            }
            foods.push(data)
          })

        }
      })
      DataToSend = {
        patient_id: id,
        consumables: foods
      }
    }

    axios.post(`${APIURL}${url}?src=web`, DataToSend,
      {
        headers: {

          "Authorization": 'Token ' + str
        }
      })
      .then(res => {

        if (res.data.status === "success") {
          setIsSave(false)
          alert.success("successfully saved")
          if (type === "liquid") { setLiquidList([]); setFoods(JSON.parse(JSON.stringify(foodsClear))) }
          else if (type === "food") { setFoodList([]); setFoods(JSON.parse(JSON.stringify(foodsClear))) }
          else if (type === "consumables") { setConsList([]); setFoods(JSON.parse(JSON.stringify(foodsClear))) }
          setRefresh(!refresh)

        }
      })
      .catch(err => {


      })


  }
  useEffect(() => {

    if (type === "liquid") {
      if (LiquidList.length > 0) {
        setFoods(LiquidList)
      }
      setFoods1(LiquidList1)
    }
    else if (type === "food") {
      if (foodList.length > 0) {
        setFoods(foodList)
      }
      setFoods1(foodList1)
    }
    else if (type === "consumables") {
      if (consList.length > 0) {
        setFoods(consList)
      }
      setFoods1(consList1)
    }
  }, [refresh1, type])

  const handleRemoveItem = (index, i) => {
    if (type === "liquid") {
      LiquidList[i].food.splice(index, 1);
      setLiquidList(LiquidList);
      setRefresh1(!refresh1)
    }
    else if (type === "food") {
      foodList[i].food.splice(index, 1);
      setFoodList(foodList);
      setRefresh1(!refresh1)
    }
    else if (type === "consumables") {
      consList[i].food.splice(index, 1);
      setConsList(consList);
      setRefresh1(!refresh1)
    }

  }


  const handleClick = () => {

    if (timeArray.length === 0 && type !== "consumables") {
      alert.error("Please choose the time")
    }
    else if (date1.from === "") {
      alert.error("please select a date range")
    }
    else {
setIsSave(true)
      const a = moment(date1.from).format('YYYY-MM-DD');
      const b = moment(date1.to).format('YYYY-MM-DD');
      let filtered = foods.filter((product) => {
        const productDate = moment(product.till_date).format('YYYY-MM-DD');
        if (productDate >= a && productDate <= b) {
          temp["serv_time"] = timeArray
          product["food"].push(JSON.parse(JSON.stringify(temp)))

        }
      })

      const newArr = [...foods];
      filtered.map((item) => {
        const index = newArr.indexOf(item.till_date)
        if (index !== -1) {
          newArr[index] = item;
        }

      })
      if (type === "liquid") {
        setLiquidList(newArr)
      }
      else if (type === "food") {
        setFoodList(newArr)
      }
      else if (type === "consumables") {
        setConsList(newArr)
      }

      setIsCopied(false)
      setTime({ morning: false, afternoon: false, evening: false, night: false })

    }
  }

  useEffect(() => {

    if (type === "liquid") {
      setItems(liquidItems)
    }
    else if (type === "food") {
      setItems(foodItems)
    }
    else if (type === "consumables") {
      setItems(consItems)
    }
    else {
      setItems([])
    }

  }, [foodItems, liquidItems, consItems, type])
  useEffect(() => {

    if (item.name) {
      if (type === "liquid") {
        if (liquidItems.length === 0) {

          setLiquidItems([...liquidItems, item])
          setN(n + 1)
        } else {
          const found = liquidItems.find(obj => {
            return obj.name === item.name && obj.qty === item.qty;
          });
          if (!found) {
            setLiquidItems([...liquidItems, item])
            setN(n + 1)
          } else {
            alert.error("already added")
          }
        }
      }
      else if (type === "food") {
        if (foodItems.length === 0) {

          setFoodItems([...foodItems, item])
          setN(n + 1)
        } else {
          const found = foodItems.find(obj => {
            return obj.name === item.name && obj.qty === item.qty;
          });
          if (!found) {
            setFoodItems([...foodItems, item])
            setN(n + 1)
          } else {
            alert.error("already added")
          }

        }
      }
      else if (type === "consumables") {
        if (consItems.length === 0) {

          setConsItems([...consItems, item])
          setN(n + 1)
        } else {
          const found = consItems.find(obj => {
            return obj.name === item.name && obj.qty === item.qty;
          });
          if (!found) {
            setConsItems([...consItems, item])
            setN(n + 1)
          } else {
            alert.error("already added")
          }

        }
      }


    }

  }, [item])
  useEffect(() => {
    let updatedArray = [];
    for (var key in time) {
      if (time[key] === true) {
        updatedArray.push(key);
      }
    }

    setTimeArray(updatedArray);

  }, [time]);
  const handleTime = (e) => {
    const key = e.target.name;
setIsSave(true)
    setTime((currentstate) => ({
      ...currentstate,
      [key]: !time[key],
    })
    );

  };
  const onSuccess = () => {
    setEditShow(false)
    setRefresh(!refresh)


  }
  const handleEdit = (item, key) => {
    if (key === "Edit") {
      setUpdateData(item)
      setEditShow(true)
    }
    else {
      const tokenString = sessionStorage.getItem('usertoken');
      let str = tokenString.replace(/["]+/g, '')
      const DataToSend = {
        is_deleted: true
      }
      let url = ""
      if (type === "liquid") {
        url = "/api/v1/doctor/liquid-instruction-detail/" + item.id + "/"
      }
      else if (type === "food") {
        url = "/api/v1/doctor/food-instruction-detail/" + item.id + "/"
      }
      else if (type === "consumables") {
        url = "/api/v1/doctor/pat-consumable-detail/" + item.id + "/"
      }
      axios.put(`${APIURL}${url}`, DataToSend,
        {
          headers: {

            "Authorization": 'Token ' + str
          }
        })
        .then(res => {

          if (res.data.status === "success") {
            if (key === "Edit") {
              alert.success("successfully Updated")
            }
            else {
              alert.success("Successfully Deleted")
            }
           
            setRefresh(!refresh)

          }
        })
        .catch(err => {


        })


    }
  }
  const EditPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}>
          <b>{type === "liquid" ? updateData.liquid_name : type === "food" ? updateData.food_name : updateData.consumable_name}</b>
          <button style={{ backgroundColor: "white", marginLeft: "75%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times" style={{ color: "#6d6f70" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div><EditPlan id={id} data={updateData} type={type} onSuccess={onSuccess} /></div>
        </Modal.Body>
      </Modal>
    );
  };
 
 
  return (
    <div className='container' style={{ paddingBottom: "3%" }}>
      <div className='treat-nav-main ' style={{ marginTop: "2%",display:"flex" }}>
        <div className="treat-button-main" style={{width:"14%",  color: type === "medicine" && "white",backgroundColor:type === "medicine"&&"rgb(60, 104, 158)", cursor: "pointer" }} onClick={() => { setType("medicine") }} >Medicine</div>&nbsp;
        <div className="treat-button-main "style={{width:"14%", color: type === "liquid" && "white",backgroundColor:type === "liquid"&&"rgb(60, 104, 158)", cursor: "pointer" }} onClick={() => { setType("liquid") }} >Liquid</div>&nbsp;
        <div className="treat-button-main"style={{width:"14%", color: type === "food" && "white",backgroundColor:type === "food"&&"rgb(60, 104, 158)", cursor: "pointer" }} onClick={() => { setType("food") }} >Food</div>&nbsp;
        <div className="treat-button-main"style={{width:"14%", color: type === "consumables" && "white",backgroundColor: type === "consumables"&&"rgb(60, 104, 158)", cursor: "pointer" }} onClick={() => { setType("consumables") }} >Consumables</div>&nbsp;
        <div className="treat-button-main"style={{width:"14%",color: type === "test" && "white",backgroundColor:type === "test" &&"rgb(60, 104, 158)", cursor: "pointer"}} onClick={() => { setType("test") }} >Test</div>&nbsp;
        <div className="treat-button-main"style={{width:"14%",color: type === "scan" && "white",backgroundColor: type === "scan" &&"rgb(60, 104, 158)", cursor: "pointer"}} onClick={() => { setType("scan") }} >Scan</div>&nbsp;
        <div className="treat-button-main"style={{width:"14%",color: type === "procedure" && "white",backgroundColor:type === "procedure"&&"rgb(60, 104, 158)", cursor: "pointer"}} onClick={() => { setType("procedure") }} >Procedure</div>&nbsp;
      </div>
      {type==="medicine"?
           <div className='row' style={{marginTop:"1%"}}>
           {/******Prescription*******/}
         <span className='col-6'></span>
         &nbsp;&nbsp;&nbsp;&nbsp;
             <button  onClick={() =>{setMedaction("view")}}  className="treat-button-main "
              style={{width:"14%",color:medaction==="view"&&"white",backgroundColor:medaction==="view"&&"rgb(60, 104, 158)"}}>
               {" "}
               Current Medicines{" "}
             </button>
             &nbsp;&nbsp;&nbsp;&nbsp;
             <button   onClick={() =>setMedaction("add")} className="treat-button-main"
              style={{width:"14%",color:medaction==="add"&&"white",backgroundColor:medaction==="add"&&"rgb(60, 104, 158)"}}>
              
               {" "}
               Add Medicines{" "}
             </button>
             &nbsp;&nbsp;&nbsp;&nbsp;
             <button  onClick={() =>setMedaction("history")} className="treat-button-main"
             style={{width:"14%",color:medaction==="history"&&"white",backgroundColor:medaction==="history"&&"rgb(60, 104, 158)"}}>
               {" "}
               Past Medications{" "}
             </button>
          
           {medaction==="view"?<CurrentMedications id={id}/>:medaction==="add"?<AddMedicinesPat patient={id} onSuccess={(data) => {setMedaction("view")}}/>:<PastMedications id={id}/>}
         
         
         </div>:
          type==='procedure'?<ManageProcedure id={id}/>
         :
         type==='scan'?<ManageScan id={id}/>:
         type==='test'?<ManageTest id={id}/>:
      <div className='row' style={{ marginTop: "2%", marginLeft: "0.2%" }}>
        <div className='col-4 treat-nav row' style={{ padding: "1%", height: "fit-content" }}>

          <ItemList type={type} onSuccess={setItem} n={n} />
          <SimpleBar style={{ maxHeight: "300px" }}>
            <div style={{ paddingRight: "5%" }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" >
                  {(provided, snapshot) => (
                    <div className='droppable'
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {items.length > 0 && items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <div key={item.id + "key"}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className='treat-nav row'

                                style={{ marginTop: "2%",backgroundColor:temp.id===item.id&& "#c8dff3" }}>
                                <div className='col-2'><i onClick={() => {
                                  if (type === "liquid") {
                                    setLiquidItems((current) =>
                                      current.filter((i) => i.id !== item.id)
                                    );
                                  }
                                  else if (type === "food") {
                                    setFoodItems((current) =>
                                      current.filter((i) => i.id !== item.id)
                                    );
                                  }
                                  else if (type === "consumables") {
                                    setConsItems((current) =>
                                      current.filter((i) => i.id !== item.id)
                                    );
                                  }

                                }} style={{ color: "red", cursor: "pointer" }} class="fa fa-window-close" aria-hidden="true"></i></div>
                                <div className="col-8">
                                  <p style={{ textTransform: "capitalize" }}>{item.name}</p>

                                  {item.qty>0 && <p>{item.qty}{type === "food" ? "gm" : type === "liquid" ? "ml" : item.qty ===1 ? "no" : "nos"}</p>}
                                  {item.cal>0&&<p>{item.cal && item.cal + "cal"}</p>}
                                </div>
                                <div className='col-2'><i style={{ color:temp.id===item.id&&"#5c9ded", cursor: "pointer" }} onClick={() => { setIsCopied(true); setTemp(item) }} class="fa fa-clone" aria-hidden="true"></i></div>

                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </SimpleBar>
        </div>
        <div className='col-8' style={{ paddingLeft: "5%", paddingTop: "1%" }}>

          <div className='row'>
            <div className='col-2' >
              {type !== "consumables" && <><input
                className=""
                type="checkbox"
                id="morning"
                name="morning"
                checked={time.morning}
                onChange={(e) => { handleTime(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Morning</label></>}</div>
            <div className='col-2' >
              {type !== "consumables" && <><input
                className=""
                type="checkbox"
                id="afternoon"
                name="afternoon"
                checked={time.afternoon}
                onChange={(e) => { handleTime(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>After Noon</label></>}</div>
            <div className='col-2'>
              {type !== "consumables" && <><input
                className=""
                type="checkbox"
                id="evening"
                name="evening"
                checked={time.evening}
                onChange={(e) => { handleTime(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Evening</label></>}</div>
            <div className='col-2' >
              {type !== "consumables" && <><input
                className=""
                type="checkbox"
                id="night"
                name="night"
                checked={time.night}
                onChange={(e) => { handleTime(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Night</label></>}</div>
            <div className='col-4'>
              {(type === "liquid" && LiquidList.length > 0) || (type === "food" && foodList.length > 0) || (type === "consumables" && consList.length > 0) ? <button disabled={!isSave} onClick={() => { setIsSave(false);callSave() }} style={{ color: "white", marginLeft: "48%", width: "80px", backgroundColor: "#86d386", marginBottom: "2%" }} className='qty-div'>Save</button> : ""}
            </div>
          </div>
          <div className="treat-nav-all" >
            <div className="treat-nav1" style={{ padding: "0px", paddingLeft: "2%" }}>
              <div className='row' style={{ width: "100%", padding: "1%" }}>
                <div className='col-2 leftmost'> <h6>Date</h6></div>
                <div className='col-3  '><h6 style={{ textTransform: "capitalize", fontWeight: "700", marginLeft: "5%" }}>{type}</h6></div>
                <div className='col-4  ' >

                  <DateRangePicker
                    appearance="default"
                    style={{ width: "220px" }}
                    placeholder={"Select Date Range"}
                    onChange={handleEvent1}

                    disabledDate={allowedRange(new Date(), new Date().setDate(new Date().getDate() + 14))}

                  >
                  </DateRangePicker>
                </div>

                <div className='col-2   ' >
                  <button onClick={() => { handleClick() }} disabled={!isCopied} style={{ backgroundColor: !isCopied && "silver" }} className='treat-paste-button'>
                    Paste All
                  </button>
                </div>
              </div>
            </div>

            {foods.length > 0 && foods.map((item, i) => {
              return (
                <div key={i} className="treat-nav1" style={{ padding: "0px", paddingLeft: "2%" }}>
                  <div className=' row' style={{ width: "100%", padding: "1%" }}>
                    <div className='col-2 leftmost' > <h6>{item.till_date && item.till_date.slice(4, 16)}</h6></div>
                    <div className='col-9 row'>
                      {foods1.length > 0 && foods1.map((y, index) => {
                        return (

                          moment(item.till_date).format('YYYY-MM-DD') === moment(y.till_date).format('YYYY-MM-DD') && <div className='col-4'>
                            <div key={index} className='food-display '>
                            <h6>{type === "consumables"&&y.pharmacy_status!=="rejected"?<><OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled"> Available</Tooltip>}><svg style={{ backgroundColor:"white",borderRadius:"20px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 10 10"><path fill="green" d="M4.998 0a4.998 4.998 0 1 0 0 9.995a4.998 4.998 0 0 0 0-9.995ZM1 4.998a3.998 3.998 0 1 1 7.995 0a3.998 3.998 0 0 1-7.995 0Zm6.104-1.602a.5.5 0 0 1 0 .708l-2.25 2.25a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647l1.896-1.897a.5.5 0 0 1 .708 0Z"/></svg></OverlayTrigger>&nbsp;&nbsp;</>:
       type === "consumables"&&y.pharmacy_status==="rejected"?<> <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">not  Available</Tooltip>}><svg style={{ backgroundColor:"white"}}xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="red" d="M12 20a8 8 0 0 1-8-8H2c0 5.523 4.477 10 10 10v-2Zm0-16a8 8 0 0 1 8 8h2c0-5.523-4.477-10-10-10v2Zm-8 8a7.97 7.97 0 0 1 2.343-5.657L4.93 4.93A9.972 9.972 0 0 0 2 11.999h2Zm2.343-5.657A7.972 7.972 0 0 1 12 4V2a9.972 9.972 0 0 0-7.071 2.929l1.414 1.414Zm-1.414 0l12.728 12.728l1.414-1.414L6.343 4.929L4.93 6.343ZM20 12a7.97 7.97 0 0 1-2.343 5.657l1.414 1.414A9.972 9.972 0 0 0 22 12h-2Zm-2.343 5.657A7.972 7.972 0 0 1 12 20v2a9.972 9.972 0 0 0 7.071-2.929l-1.414-1.414Z"/></svg></OverlayTrigger>
        &nbsp;&nbsp;</>:""}
                              <b>{type === "liquid" ? y.liquid_name : type === "food" ? y.food_name : y.consumable_name}</b>
                                <i onClick={() => { handleEdit(y, "del") }} style={{ cursor: "pointer", float: "right", paddingRight: "3%",color:"red" }} class="fa fa-window-close" aria-hidden="true"></i>
                                <i onClick={() => { handleEdit(y, "Edit") }} style={{ cursor: "pointer", float: "right", paddingRight: "4%",color:"white" }} class="fas fa-pencil-alt" aria-hidden="true"></i></h6>
                              {y.quantity !== 0 && <h6>{y.quantity && y.quantity}{type === "food" ? y.quantity_unit && y.quantity_unit : type === "liquid" ? "ml" : y.quantity === 1 ? "no" : "nos"}</h6>}
                              {y.calorie !== 0 && <h6>{y.calorie && y.calorie + "cal"}</h6>}
                              {type !== "consumables" && y.serv_time && y.serv_time.map((x) => {
                                return (
                                  <span>{x === "morning" ? "M" : x === "afternoon" ? "AF" : x === "evening" ? "E" : "N"}{" "}</span>
                                )
                              })}
                            </div>
                          </div>
                        )
                      })}
                      {item.food && item.food.length > 0 && item.food.map((food, index) => {
                        return (
                          <div className='col-4'>
                            <div key={index} style={{ backgroundColor: " #ff9700" }} className='food-display'>
                              <h6><b>{food.name}</b><i onClick={() => {
                                handleRemoveItem(index, i)
                              }} style={{ cursor: "pointer", float: "right", paddingRight: "3%",color:"red" }} class="fa fa-window-close" aria-hidden="true"></i></h6>
                              {food.qty!==0 && <h6>{food.qty}{type === "food" ? "gram" : type === "liquid" ? "ml" : food.quantity === 1 ? "no" : "nos"}</h6>}
                              {food.cal!==0 && <h6>{food.cal}cal</h6>}
                              {food.serv_time.map((x) => {
                                return (
                                  <span>{x === "morning" ? "M" : x === "afternoon" ? "AF" : x === "evening" ? "E" : "N"}{" "}</span>
                                )
                              })}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className='col-1  ' >
                      {isCopied && <i onClick={() => { timeArray.length === 0 && type !== "consumables" ? alert.error("Please choose time ") : handleClickPaste(item.till_date) }} style={{ color: "black", marginLeft: "90%" }} class="fa fa-clipboard" aria-hidden="true"></i>}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>}
      {editShow ? (
        <EditPopup
          show={editShow}
          onHide={() => {
            setEditShow(false);
          }}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default TreatPlanDetails