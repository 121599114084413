import React, {useState, useRef, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function StageAdd(props) {
  const alert = useAlert();
    const [stage, setStage]=useState(props.setEditStage !== " " ? props.setEditStage : " ")
    const [modalShow, setModalShow]=useState(false)
    const [errorShow, setErrorShow]= useState(false)
    const [limitShow , setLimitShow]= useState(false)
    const inputRef = useRef(null)
    const saveButtonRef = useRef(null)
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {  
        e.preventDefault()
        saveButtonRef.current.click();
      }
    };
    useEffect(() => {
      
      const handleKey = (e) => {
        if (e.altKey && e.key === 's') {
          e.preventDefault()
          saveButtonRef.current.click() 
        }     
      }
      document.addEventListener("keydown", handleKey);
      
      return () => {
        document.removeEventListener("keydown", handleKey);
      }
      
    }, []);

    useEffect(() => {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.selectionEnd = inputRef.current.value.length;
  }, []);

    const handleChange = (e)=> {
        setStage(e.target.value);
    }

    const handleSubmit = () => {


            const id =props.id
            const duration = props.duration.toString();
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               "stage": stage,
               "duration": duration,
           }
     
     
       
         axios.put(`${APIURL}/api/v1/doctor/comorbidities-details/${id}/`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.status==200){
            alert.success("Stage Updated Successfully !!")
            setModalShow(false); props.submitstage()
         
          
       }
       else{
            setErrorShow(true)
        
       }
     
         })
         .catch(err=>{
       
            setErrorShow(true)
     })

       

        
    }

    const SubmitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-success'>Stage Updated Successfully !!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const ErrorPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 style={{color: "red"}}> Error in data submission.Please check again!</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>
      
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

      const LimitPopup =(props)=>{
    

        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
             backdrop="static"
              keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5 className='text-muted'>Please Check Stage Value</h5>
             
            </Modal.Body>
            <Modal.Footer>
             
               <Button variant="secondary" onClick={props.onHide}>Ok</Button>    
             
             
      
            </Modal.Footer>
          </Modal>
        );
      }

    return (
        <div className='flex-col'>
          <label>Edit Stage:</label>
           <input ref={inputRef} onKeyDown={handleKeyDown} style={{width:'50%'}} type="text" min="0" placeholder="Add Stage" value={stage} onChange={handleChange} className="form-control"/><br/>
           <button ref={saveButtonRef} onClick={handleSubmit} className='btn btn-primary btn-col'>Add</button> 


           {
      modalShow ? 
      <SubmitPopup
        show={modalShow}
        onHide= {() => { setModalShow(false); props.submitstage()     
        }}
      /> : null
    }

{
      errorShow ? 
      <ErrorPopup
        show={errorShow}
        onHide= {() => { setErrorShow(false)     
        }}
      /> : null
    }

{
      limitShow ? 
      <LimitPopup
        show={limitShow}
        onHide= {() => { setLimitShow(false)     
        }}
      /> : null
    }

        </div>
    )
}

export default StageAdd
