import React,{useState, useContext} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';
import SubmitConfirmationPopup from '../../common/SubmitConfirm';
import { ConfirmationContext } from '../../contexts/SubmitConfirmationContext';
function EditConsumable(props) {


    const[data, setData]= useState(props.data);
    const [ selectedImage, setSelectedImage] = useState(null);

    const [error,setError]=useState({
      consumable_name:'',
      type:'',
      image:props.data.image
       });

    const alert = useAlert()  
    const [refresh, setRefresh]= useState(false)
    const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext)
    //const [addRentalShow, setAddRentalShow]= useState(false)
    const[errorValidate,setErrorValidate]=useState(false)

//     const serviceOptions = [{ value:'general', label:'General' },
//     { value:'service_request', label:'Service Request' },
//     { value:'rental', label:'Equipment Request' }
// ]
const [colour, setColour]= useState({value:data.color, label:data.color?data.color.charAt(0).toUpperCase() + data.color.slice(1):""});
const [size, setSize]= useState({value:data.size, label:data.size?data.size.charAt(0).toUpperCase() + data.size.slice(1):""});
const group3 = [
  { value:'red', label:'Red' },
  { value:'pink', label:'Pink' },
  {value:'orange', label:'Orange'},
  { value:'yellow', label:'Yellow'},
  { value:'blue', label:'Blue'},
 {value:'lightblue', label:'Light Blue' },
  { value:'darkblue', label:'Dark Blue'},
 {value:'violet', label:'Violet'},
 { value:'purple', label:'Purple' },
 {value:'green', label:'Green'},
 {value:'darkgreen', label:'Dark Green'},
 {value:'lightgreen', label:'Light Green'},
 { value:'black', label:'Black' },
  { value:'white', label:'White'},
 {value:'brown', label:'Brown'},
]
const group = [
  { value:'small', label:'Small' },
  { value:'medium', label:'Medium' },
  {value:'large', label:'Large'},
  { value:'xlarge', label:'X-Large'},
  
]
const handleColorChange= (data) => {
setColour(data)

  setData( current => ({
      ...current , color: data.value
  }))
}
const handleSizeChange= (data) => {
  setSize(data)
  
    setData( current => ({
        ...current , size: data.value
    }))
  }
  



  const handleError = () =>
  {
    setErrorValidate(false);
  }



const handleImageUpload = (event) => {
  const image = event.target.files[0];
  setData(prevData => ({...prevData, image: URL.createObjectURL(image)}));
  setSelectedImage(image);
};




      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }







  const validate=()=>{
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["consumable_name"] || input["consumable_name"]==="" ) {
      isValid = false;
      errors["consumable_name"] = "Enter consumable Name";
    }
    if (!input["type"] || input["type"].value==="" ) {
      isValid = false;
      errors["type"] = "Enter Type";
    }
    

     setError(currentstate=>({
          ...currentstate,
          ...errors
        }));
    

    return isValid;
}
const handleContinue = (e) => {
  e.preventDefault()
  if(validate()){ 
    setShowConfirmation(true)
  };
}

const handleConfirm = () => {
  saveChanges();
};

const handleCancel = () => {
  closeConfirmationPopup()
};

  const saveChanges = ()=> {

        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
     let dataToSend = {};

     if(props.data.consumable_name === data.consumable_name && props.data.type === data.type){
       dataToSend = {
         description: data.description,
         manufacturer: data.manufacturer,
         brand: data.brand,
         volume: data.volume,
         color: data.color,
         model: data.model,
         weight: data.weight,
         size: data.size,
         image:selectedImage
       };
     } else if(props.data.consumable_name === data.consumable_name){
       dataToSend = {
         description: data.description,
         type: data.type,
         image:selectedImage,
         manufacturer: data.manufacturer,
         brand: data.brand,
         volume: data.volume,
         color: data.color,
         model: data.model,
         weight: data.weight,
         size: data.size,
       };
     } else {
       dataToSend = {
         consumable_name: data.consumable_name,
         type: data.type,
         description: data.description,
         image: selectedImage,
         manufacturer: data.manufacturer,
         brand: data.brand,
         volume: data.volume,
         color: data.color,
         model: data.model,
         weight: data.weight,
         size: data.size,
       };
     }
     const dataToSendFormData = new FormData();

     for (const key in dataToSend) {
      if (key === 'image' && !selectedImage) {
        continue; // Skip adding the "image" field if selectedImage is false
      }
      dataToSendFormData.append(key, dataToSend[key]);
    }
    

     
     axios
       .put(`${APIURL}/api/v1/staff/master-consumables-detail/${data.id}/`, dataToSendFormData , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          alert.success('Updated successfully');
          props.modalClose()
         } else {
           alert.error(res.data.error ? res.data.error : 'Consumable already present')
           closeConfirmationPopup()
         }
       })
       .catch((err) => {
        alert.error('Consumable already present')
        closeConfirmationPopup()

       });  
}
const removePicPreview = (e) => {
  e.preventDefault();

  setData((currentData) => ({
    ...currentData,
    image: "",
  }));
};
    
 



    return (
        <>
        
    <h6 style={{marginTop:'0'}} className="title-of-page">Edit Consumables</h6>

    <div style={{}} className=' cons-add'><br />
        <div className='row' style={{ paddingLeft: '2%', paddingRight: "2%" }}>
          <div className='col-4 grid-prop'>
            <small style={{ marginLeft: "3%" }}>Consumable Name</small><br />
            <input type='text' className='gridinputprop' name='consumable_name' value={data.consumable_name} onChange={handleTitle} />
          </div>
          <div className='col-2  grid-prop'>
            <small style={{ marginLeft: "3%" }}>Type</small><br />
            <input type='text' className='gridinputprop' name='type' value={data.type} onChange={handleTitle} />
          </div>
          <div className='col-6  grid-prop'>
            <small style={{ marginLeft: "3%" }}>Manufacturer</small><br />
            <input type='text' className='gridinputprop' name='manufacturer' value={data.manufacturer} onChange={handleTitle} />
          </div>
        </div>

        <div className='row' style={{ paddingLeft: '2%', paddingRight: "2%" }}>
          <div className='col-2 grid-prop'>
            <small style={{ marginLeft: "3%" }}>Brand</small><br />
            <input type='text' className='gridinputprop' name='brand' value={data.brand} onChange={handleTitle} />
          </div>
          <div className='col-2  grid-prop'>
            <small style={{ marginLeft: "3%" }}>Model</small><br />
            <input type='text' className='gridinputprop' name='model' value={data.model} onChange={handleTitle} />
          </div>
          <div className='col-2  grid-prop'>
            <small style={{ marginLeft: "3%" }}>Size</small><br />
            <Select
                    defaultValue={size}
                    onChange={(data,e)=>{handleSizeChange(data,e)
                    }}
                    options={group}
                    name='size'
                    className="gridinputprop "
                  />
           
          </div>
          <div className='col-2 grid-prop'>
            <small style={{ marginLeft: "3%" }}>Weight in Kg</small><br />
            <input type='text' className='gridinputprop' name='weight' value={data.weight} onChange={handleTitle} />
          </div>
          <div className='col-2  grid-prop'>
            <small style={{ marginLeft: "3%" }}>Volume in L</small><br />
            <input type='text' className='gridinputprop' name='volume' value={data.volume} onChange={handleTitle} />
          </div>
          <div className='col-2  grid-prop'>
            <small style={{ marginLeft: "3%", }}>Color</small><br />
            <Select
                   defaultValue={colour}
                     onChange={(data,e)=>{handleColorChange(data,e)
                     }}
                     options={group3}
                    name='group'
                    className="gridinputprop "
                    style={{fontSize:"12px"}}
                  />
            {/* <input type='text' className='gridinputprop' name='color' value={data.color} onChange={handleTitle} /> */}
          </div>
        </div>
        <div className='row' style={{ marginTop: "3%", paddingLeft: '2%', paddingRight: "2%" }}>
          <div className='col-9 grid-prop' >

            <textarea style={{ height: "125px", padding: "2%" }} type='text' placeholder='Description' className='gridinputprop' name='description' value={data.description} onChange={handleTitle} />
          </div>
          <div className='col-3  grid-prop'>
            {data.image ? (<><div onClick={(e) => { removePicPreview(e ) }}>
                                            <i
                                                style={{ position: "absolute" }}
                                                className="fas fa-times-circle"
                                            ></i>
                                        </div>
              <img src={data.image} alt="Selected" style={{ maxWidth: '130px', marginTop: "3%", maxHeight: '130px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
</>
            ) : <div className="image-holder-div">
              
              <input

                name="img1"
                type="file"
                className="custom-file-input"
                id="validatedCustomFile1"
                onChange={handleImageUpload}
              />

              <label style={{ width: "100%" }} className="" htmlFor="validatedCustomFile1">


                <i style={{ marginLeft: "42%", marginTop: "17%", color: "#ADADAD", }} className="fa fa-picture-o fa-2x"></i><br />
                <span style={{ marginLeft: "35%", color: "#ADADAD", fontSize: "10px" }}></span>

              </label>

            </div>

            }
          </div>

        </div>


      </div>



    {/* <div style={{display:"flex" ,marginLeft:"180px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {data.image && (
         <div style={{ display: 'flex' }}>
          <p>Selected Image:</p>
          <img src={data.image} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
        <div style={{}} className='config-box fit-content'>
            
            <div className='feature-data pad-features' ><br/>
                <div className="formField" >
                  <div style={{}}>
                    <label className='col-5'>*Consumable Name: </label>
                    <input type='text' style={{width:"65%", height:"44px"}} className='col-6' name='consumable_name' value={data.consumable_name} onChange={handleTitle} /></div>
                    {error.consumable_name ? <div className="error-validation-msg error-feature-inventory">{error.consumable_name}</div> : null}
                </div>

                <div className="formField">
                  <div  style={{}}>
                    <label className='col-5'>*Consumable Type: </label>
                    <input type='text'  style={{width:"65%", height:"44px"}} className='col-6' name='type' value={data.type} onChange={handleTitle} /></div>
                    {error.type ? <div className="error-validation-msg error-feature-inventory">{error.type}</div> : null}
                </div>
                <div className="formField">
                  <div  style={{display:"flex"}}>
                    <label className='col-5'>Description: </label>
                    <textarea  style={{width:"65%", height:"44px"}} className='col-6' name='description' value={data.description} onChange={handleTitle} /></div>
                
                </div>

            </div> */}

            <div className='text-center'>
                <button onClick={handleContinue} className='cons-button'>Save</button>
                
            </div>
        
            {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want edit?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}

            
        {/* </div> */}
        </>
    )
}

export default EditConsumable;
