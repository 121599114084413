import React, {useState, useRef, useEffect} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function AddPulse(props) {
  const alert = useAlert();
    const [pulse, setPulse]=useState()
    const saveButtonRef = useRef(null)

    useEffect(() => {
      const handleKey = (e) => {
  
        if (e.altKey && e.key === 's') {
          e.preventDefault()
          saveButtonRef.current.click()
        }else if (e.key === 'Enter') {
          e.preventDefault()
          saveButtonRef.current.click()
        }
      
      }
      document.addEventListener("keydown", handleKey);
      
      return () => {
        document.removeEventListener("keydown", handleKey);
      }
      
    }, []);

    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDateTime = toISOString(Date().toLocaleString());
  function toISOString(s) {
    var months = {jan:'01',feb:'02',mar:'03',apr:'04',may:'05',jun:'06',
                  jul:'07',aug:'08',sep:'09',oct:'10',nov:'11',dec:'12'};
    var b = s.split(' ');
  
    return b[3] + '-' +
           months[b[1].toLowerCase()] + '-' +
           ('0' + b[2]).slice(-2) + 'T' +
           b[4] + b[5].substr(3);
  }

    const handleChange = (e)=> {
        const val = e.target.value
        if(val.length < 4){
            setPulse(e.target.value)
        }
        else {
            return false
        }
    }

    const handleSubmit = () => {

        if(pulse > 19 && pulse < 181) {

            const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               'vital_name':"pulse",
               'result' :pulse,
               'date_time':currentDateTime
           }
     
     
       
         axios.post(`${APIURL}/api/v1/patient/vitals/?patient_id=${id}&timeZone=${timezoneOffset}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.data.status==="success"){
                alert.success("Pulse added Successfully")
                props.submitpulse() ;setPulse("") 
          
       }
       else{
            alert.error(res.data.message)
        
       }
     
         })
       
        }
        else {
           alert.error("Pulse value must be between 20bpm and 180bpm")
        }

        
    }

  

    return (
        <div className='flex-col'>
           <input style={{width:'50%'}} type="number" min='0' max='250' placeholder="Add Pulse" value={pulse} onChange={handleChange} className="form-control"/><br/>
           <button disabled={pulse<1 || pulse=== undefined} onClick={handleSubmit} ref={saveButtonRef} className='btn btn-primary btn-col'>Add</button> 


       
        </div>
    )
}

export default AddPulse
