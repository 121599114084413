import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function DialysisCommentPopup({ initialComment, modalClose, handleCommentSave }) {
  const [comment, setComment] = useState(initialComment);
  const saveButtonRef = useRef(null);

  useEffect(() => {
    const handleKey = (e) => {
      if (e.altKey && e.key === 's') {
        e.preventDefault();
        saveButtonRef.current.click();
      } else if (e.altKey && e.key === 'c') {
        e.preventDefault();
        handleCancel();
      }
    };

    document.addEventListener('keydown', handleKey);

    return () => {
      document.removeEventListener('keydown', handleKey);
    };
  }, []);

  const handleCancel = () => {
    setComment('');
    modalClose();
  };

  const handleSave = () => {
    handleCommentSave(comment);
    modalClose();
  };

  return (
    <div className='flex-col'>
      <textarea
        type="text"
        className="form-group text-area remark-add"
        value={comment}
        name="remarks"
        onChange={(e) => {
          e.preventDefault();
          setComment(e.target.value);
        }}
        autoComplete="off"
      />
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <button onClick={handleCancel} className="btn btn-secondary mr-2">
          Cancel
        </button>
        {(initialComment === '' || initialComment === null || initialComment === undefined) ?
          <button ref={saveButtonRef} disabled={comment === '' || initialComment === comment} onClick={handleSave} className="btn btn-primary btn-col">
            Add
          </button>
          : <button ref={saveButtonRef} onClick={handleSave} className="btn btn-primary btn-col">
            Edit
          </button>
        }
      </div>
    </div>
  );
}

DialysisCommentPopup.propTypes = {
  initialComment: PropTypes.string,
  modalClose: PropTypes.func.isRequired,
  handleCommentSave: PropTypes.func.isRequired,
};

DialysisCommentPopup.defaultProps = {
  initialComment: '',
};

export default DialysisCommentPopup;
