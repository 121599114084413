
import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import { NavDropdown } from "react-bootstrap";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import { ToggleButton, Badge,Button } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
import DatePicker from "./Datepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import "../AdmissionReport/reports.css";
import isAfter from 'date-fns/isAfter';
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { Modal } from "react-bootstrap";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { MdDownloadForOffline } from "react-icons/md";
import "./reports.css";
import Nav from 'react-bootstrap/Nav';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { GrClose } from "react-icons/gr";


function TreatmentReport() {
  const [features,setFeatures]=useState(JSON.parse(sessionStorage.getItem("features")))
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1)
  const [hasNext, setHasNext] = useState(false)
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState('')
  const [registerData, setRegisterData] = useState([])
  const [timePeriod, setTimePeriod] = useState("month")
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [refresh,setRefresh]=useState(false);
  const [selectedValue, setSelectedValue] = useState(''); // You can set the initial selected value here
  const [currentDay, setCurrentDay] = useState(moment().format('YYYY-MM-DD'));
  const [currentMonth, setCurrentMonth] = useState(moment().format('MMMM'));
  const [monthsYear, setMonthsYear] = useState(moment().format('YYYY'));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);
  const currentYear = new Date().getFullYear();
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
  const [selectedDownloads, setSelectedDownloads] = useState([]);
  const [dateRange,setDateRange] = useState(null);  
  const [diseaseFilter,setDiseaseFilter]= useState('')
  const [treatmentFilter, setTreatmentFilter] = useState('')
  

  useEffect(() => {
    
   
    setRegisterData([])
    apiCallFilter(1,10);
  setFromDate("")
  setToDate("")
setSelectedValue("")


   
  }, [refresh]);

  const handleRefresh =(e)=>{
    
    e.preventDefault();
    setCurrentDay(moment().toDate());
    setCurrentMonth(moment().format('MMMM'));
    setMonthsYear(moment().format('YYYY'));
    setRegisterData([]);
    setDiseaseFilter('');
    setTreatmentFilter('');
    
    setPage(1);
    setFromDate("")
    setToDate("")
    setRefresh(!refresh)
    setDateRange(null); 
  }

  const downloadOptions = [ "PDF", "CSV" ];
  
  const toggleDownloadDropdown = () => {
    setIsDownloadDropdownOpen(!isDownloadDropdownOpen);
  };

  const handleDownloadChange = (download) => {
    setSelectedDownloads((prevDownloads) => {
      if (prevDownloads.includes(download)) {
        // Deselect the checkbox
        return prevDownloads.filter(item => item !== download);
      } else {
        return [...prevDownloads, download];
      }
    });
  };

  const capitalize = (str) => {
    if (typeof str === 'string') {
        return str&&str!==""&&str.replace(/\b\w/g, c => c.toUpperCase());
    }
    return str;
};

  const handleDownloads = (e) => {
    console.log('Selected Downloads:', selectedDownloads);
    selectedDownloads.forEach((selectedOption) => {
      if (selectedOption === 'PDF') {
        e.preventDefault();
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";
        const marginLeft = 40;
        const marginLeft1 =450;
        const marginBottom =40;
        const doc = new jsPDF(orientation, unit, size);
      
        doc.setFontSize(15);
       
       
        <br/>
        const title = "Treatment Report";
        const headers = ["Name", "Age", "Address", " Disease", "Treatment Details", "Wound & Treatment"];
      
        const data1 = 
        
        registerData.map((patient) => {
          const patientData = patient; // Extract the patient data object from the response
      
          const lab_test = patient.lab_test ? capitalize(patient.lab_test) : [];
          const scans = patient.scans ? capitalize(patient.scans) : [];
          const procedures = patient.procedures ? capitalize(patient.procedures): [];
          const medication = patient.medication ? capitalize(patient.medication) : [];
          const comorbidities = patient.comorbidities ? capitalize(patient.comorbidities) : [];
          const disease = patient.diseases ? capitalize(patient.diseases) : [];
      
          return [
            capitalize(patientData.patient_name),
            patientData.age || "N/A",
            patientData.address ? capitalize(patientData.address) : "N/A",
            ["Comorbidities:",
            ...comorbidities,
            " Primary Disease:",
            ...disease,].join("\n\n"),
            [
              "Lab Tests:",
              ...lab_test,
              "Scans:",
              ...scans,
              "Procedures:",
              ...procedures,
              "Medications:",
              ...medication,
            
            ].join("\n"),
            patientData.wounds.map((wound) => {
              const treatmentName = wound.treatment && wound.treatment.length > 0
                ? wound.treatment[0].treatment_name
                : "N/A";
              return `${wound.type} (${wound.status}) with Treatment: ${treatmentName}`;
            }).join(", ") || "N/A",
          ];
          
        });
      
        let content = {
          startY: 50,
          head: [headers],
          body: data1,
        };
      
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Treatment_Report.pdf");
  } else if (selectedOption === 'CSV') {
    
    const csvData = registerData.map((patientData) => {
      const patient_name = capitalize(patientData.patient_name);
      const address = capitalize(patientData.address);
      const comorbidities = patientData.comorbidities || [];
      const disease = patientData.diseases || [];
      const lab_test = patientData.lab_test || [];
      const scans = patientData.scans || [];
      const procedures = patientData.procedures || [];
      const medication = patientData.medication || [];
  
      const rowData = [
        patient_name,
        patientData.age || "N/A",
        address || "N/A",
        ...(comorbidities.length > 0 ? [`Comorbidities: ${comorbidities.join(", ")} Primary Disease: ${disease.join(", ")}`] : []),
        ...(lab_test.length > 0 ? [`Lab Tests: ${lab_test.join(", ")}`] : []),
        ...(scans.length > 0 ? [`Scans: ${scans.join(", ")}`] : []),
        ...(procedures.length > 0 ? [`Procedures: ${procedures.join(", ")}`] : []),
        ...(medication.length > 0 ? [`Medications: ${medication.join(", ")}`] : []),
        patientData.wounds
          .map((wound) => {
            const treatmentName =
              wound.treatment && wound.treatment.length > 0
                ? wound.treatment[0].treatment_name
                : "N/A";
            return `${wound.type} (${wound.status}) with Treatment: ${treatmentName}`;
          })
          .join(", ") || "N/A",
      ];
  
      return rowData.map((value) => `"${value}"`).join(",");
    });
  
    // Generate CSV content
    const csvContent =
      'Name,Age,Address,Disease,Treatment Details,Wound & Treatment\n' +
      csvData.join('\n');
  
    // Create a Blob and download the CSV file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Treatment_Report.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
  }});
 // Reset selected downloads
 setSelectedDownloads([]);
 setIsDownloadDropdownOpen(false); 
};
  

   
  useEffect(() => {
    if (fromDate) {
      apiCallFilter(1, perPage)
    }

  }, [fromDate,toDate]);

    const handleChangeValue = (val) => {

    setSelectedValue(val);
    registerData.splice(0, registerData.length + 1)
    setTimePeriod(val)
    
      var startOfWeek = moment().startOf('week').toDate();
  
      // Calculate the end of the week (today)
      var endOfWeek = moment().endOf('day').toDate();

      var FormatedFirstday = moment((startOfWeek)).format('YYYY-MM-DD');
      var Formatedlastday = moment((endOfWeek)).format('YYYY-MM-DD');
      setFromDate(FormatedFirstday)
      setToDate(Formatedlastday)   
  
  }

  const handleDayChange = (increment) => {
    registerData.splice(0, registerData.length + 1)

    const newDate = moment(currentDay).add(increment, 'days');
  
    // Set maximum date to the current date
    if (newDate.isAfter(moment(), 'day')) {
      setCurrentDay(moment().format('YYYY-MM-DD'));
      return;
    }
  
    setFromDate(newDate.format('YYYY-MM-DD'));
    setToDate(newDate.format('YYYY-MM-DD'));
    setCurrentDay(newDate.toDate());

    setSelectedValue('day');
  };


  const handleMonthChange = (increment) => {
    registerData.splice(0, registerData.length + 1)
    const currentMoment = moment(`${monthsYear}-${currentMonth}`, 'YYYY-MMMM');
    const newMoment = currentMoment.add(increment, 'months');

    // Set minimum month to one year back
    const minMonth = moment().subtract(1, 'year').endOf('month')-1;
    if (newMoment.isSameOrBefore(minMonth, 'month')) {
      setCurrentMonth(minMonth.format('MMMM'));
      setMonthsYear(minMonth.format('YYYY'));
      return;
    }

    // Set maximum month to the current month
    if (newMoment.isAfter(moment(), 'month')) {
      setCurrentMonth(moment().format('MMMM'));
      setMonthsYear(moment().format('YYYY'));
      return;
    }

   
      const startOfMonth = newMoment.startOf('month').format('YYYY-MM-DD');
      const endOfMonth = newMoment.endOf('month').format('YYYY-MM-DD');
  
      setFromDate(startOfMonth);
      setToDate(endOfMonth);
   
    setCurrentMonth(newMoment.format('MMMM'));
    setMonthsYear(newMoment.format('YYYY'));

    setSelectedValue('month');

  };

  const generateYearOptions = () => {
    const years = [];
    for (let year = 2023; year <= currentYear; year++) {
      years.push(String(year));
    }
    return years;
  };

  const filterOptions = [ ...generateYearOptions()];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const handleFilterChange = (filter) => {
    setSelectedYears((prevYears) => {
      if (prevYears.includes(filter)) {
        // Deselect the checkbox
        return [];
      } else {
        // Select the checkbox and disable others
        return [filter];
      }
    });
  };
  

  const handleApplyFilters = () => {
    registerData.splice(0, registerData.length + 1)

    if (selectedYears.length > 0) {
      const sortedYears = selectedYears.sort((a, b) => Number(a) - Number(b));
  
      const startDate = sortedYears[0] + '-01-01';
      const endDate = sortedYears[selectedYears.length - 1] + '-12-31';
  
      setFromDate(startDate);
      setToDate(endDate);
  
      console.log(`Start Date:`, startDate);
      console.log(`End Date:`, endDate);
  
    }
  
    setSelectedYears([]);
    setIsDropdownOpen(false);
    setSelectedValue('');

  };


  const apiCallFilter = async (page, per_page) => {
    try {
      const tokenString = sessionStorage.getItem('usertoken');
      let str = tokenString.replace(/["]+/g, '');
      const a = fromDate;
      const b = toDate;
  
      const response = await axios.get(`${APIURL}/api/v1/service-provider/treatment-patientlist/?start_date=${a}&end_date=${b}&page=${page}&per_page=${per_page}`, {
        headers: {
          "Authorization": 'Token ' + str
        }
      });
  
      if (response.data.status === "success") {
        const datalist = response.data.patients;
        setHasNext(response.data.has_next);
       // setLoad('');
       setRegisterData(datalist)

       setTotalRows(response.data.total_records)
        
        // Update registerData using the functional form of setRegisterData
        //setRegisterData(prevData => {
          // setRegisterData(prevData =>  [...prevData, ...datalist]);
        //   console.log("Updated registerData", updatedData);
        //   return updatedData;
        // });
      } else {
        setLoad('Error in Fetching Data');
      }
    } catch (error) {
      setLoad('Error in Fetching Data');
    }
  };
  

  const handleEvent = (value, picker) => {
   
    setDateRange(value);    
    if (value && value.length === 2) {
      // Check if event is not null and contains two dates
      const [start, end] = value;
      const fromDate = moment(start).format('YYYY-MM-DD');
      const toDate = moment(end).format('YYYY-MM-DD');
      setFromDate(fromDate);
      setToDate(toDate);
      setSelectedValue('');
    } else {
      // Handle the case where event is null or doesn't contain two dates
      setFromDate(''); // Reset fromDate
      setToDate('');   // Reset toDate
      setSelectedValue('');
      setRefresh(!refresh)
    }
  };

  const renderTable = (cellInfo) => {

    return (
      <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
           {registerData[cellInfo.index][cellInfo.column.id]}
      </div>
    );
  }

  const renderTableAddress = (cellInfo) => {

    if (registerData && registerData[cellInfo.index] && registerData[cellInfo.index][cellInfo.column.id]) {
      const dataLines = registerData[cellInfo.index][cellInfo.column.id]
      const words = dataLines.split(' ');

      // Create an array to hold lines
      const lines = [];
      let currentLine = '';
    
      // Iterate through the words and group them into lines
      for (let i = 0; i < words.length; i++) {
        currentLine += words[i] + ' ';
        if ((i + 1) % 2 === 0 || i === words.length - 1) {
          // Add the current line to the lines array
          lines.push(currentLine.trim());
          currentLine = '';
        }
      }
      return (
        <div style={{ color: "black", textTransform: "capitalize", fontSize: "13px" }}>
          {lines.map((line, index) => (
            <div key={index}>{line}</div>
         ) )}
        </div>
      );
    } else {
      // Handle the case where the data is missing or invalid
      return <div></div>;
    }
  }
  
  const renderTableDisease = (cellInfo) => {

    // Extract the "disease" array from the row
  let treatmentData =[];
    const {diseases,comorbidities} = cellInfo.original
    if (comorbidities && comorbidities.length > 0) {
      treatmentData.push(
        <div key="comorbidities">
          <p>Comorbidities:</p>
          <ul>
            {comorbidities.map((comorbidity, index) => (
              <li key={index} style={{ fontSize: "12px" }}>{comorbidity}</li>
            ))}
          </ul>
        </div>
      );
    }
    if (diseases && diseases.length > 0) {
      treatmentData.push(
        <div key="disease">
          <p> Primary Disease:</p>
          <ul>
            {diseases.map((disease, index) => (
              <li key={index} style={{ fontSize: "12px" }}>{disease}</li>
            ))}
          </ul>
        </div>
      );
    }
      
  
  
      return (
        <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
          {treatmentData.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      );
 
  }

  const renderTableWound = (cellInfo) => {


    // Extract the "disease" array from the row
    const diseaseArray = cellInfo.original.wounds;
  

      
  
      return (
        <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
             <ul>
         {diseaseArray.map((item, index) => (
            <li key={index} style={{fontSize:"12px",marginTop:"10px"}}>{item.type} : {item.status}
           {item.treatment.length>0&& <div ><span >Treatment Updates:</span>
             {item.treatment && item.treatment.length > 0 && (
       
                <div style={{ marginLeft: "20px", whiteSpace: "nowrap", overflowX: "auto"}}>
                  {item.treatment.length>0&&item.treatment.map((treatment, treatmentIndex) => (
                    <p key={treatmentIndex} style={{ margin: 0 ,fontSize:"10px"}}>
                      {treatment}
                    </p>
                  ))} 
                </div>
              )}
               </div> } 
           </li>
          ))}
          </ul>
        </div>
      );

   
  }



  const renderTableTreatment = (cellInfo) => {

    const { lab_test, scans, procedures, medication } = cellInfo.original;
  
    const treatmentData = [];
  
    // Check and add lab tests
    if (lab_test && lab_test.length > 0) {
      treatmentData.push(
        <div key="lab_test">
          <p>Lab Tests:</p>
          <ul>
            {lab_test.map((test, index) => (
              <li key={index} style={{ fontSize: "12px" }}>{test}</li>
            ))}
          </ul>
        </div>
      );
    }
  
    // Check and add comorbidities

  
    // Check and add scans
    if (scans && scans.length > 0) {
      treatmentData.push(
        <div key="scans">
          <p>Scans:</p>
          <ul>
            {scans.map((scan, index) => (
              <li key={index} style={{ fontSize: "12px" }}>{scan}</li>
            ))}
          </ul>
        </div>
      );
    }
  
    // Check and add procedures
    if (procedures && procedures.length > 0) {
      treatmentData.push(
        <div key="procedures">
          <p>Procedures:</p>
          <ul>
            {procedures.map((procedure, index) => (
              <li key={index} style={{ fontSize: "12px" }}>{procedure}</li>
            ))}
          </ul>
        </div>
      );
    }
  
    // Check and add medications
    if (medication && medication.length > 0) {
      treatmentData.push(
        <div key="medication">
          <p>Medications:</p>
          <ul>
            {medication.map((med, index) => (
              <li key={index} style={{ fontSize: "12px" }}>{med}</li>
            ))}
          </ul>
        </div>
      );
    }
  
 
      return (
        <div style={{ color: "black", textTransform: "capitalize", fontSize: "15px" }}>
          {treatmentData.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      );
 
  };
  





  const filterDataName = (filterValue) => {

    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
 

    axios.get(`${APIURL}/api/v1/service-provider/treatment-patientlist/?patient_name=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        let data=[]
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataAge = (filterValue) => {

    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
 

    axios.get(`${APIURL}/api/v1/service-provider/treatment-patientlist/?age=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        let data=[]
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataAddress = (filterValue) => {

    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
 

    axios.get(`${APIURL}/api/v1/service-provider/treatment-patientlist/?address=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        let data=[]
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataDisease = (filterValue) => {
  
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
 

    axios.get(`${APIURL}/api/v1/service-provider/treatment-patientlist/?disease=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.data.status === "success") {
        let data=[]
       
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
         //  const newData = data.filter((item) => !registerData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataTreatment = (filterValue) => {
    
     
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
 

    axios.get(`${APIURL}/api/v1/service-provider/treatment-patientlist/?treatments=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.status === 200) {
        let data=[]
        
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const filterDataWound = (filterValue) => {
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '');
 

    axios.get(`${APIURL}/api/v1/service-provider/treatment-patientlist/?status=${filterValue}`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
    .then(response => {
      if (response.data.status === "success") {
        let data=[]
         data = response.data.patients
         if (data.length>0){
        // if (data && data.length > 0) {
        //   const newData = data.filter((item) => !tableData.some((existingItem) => existingItem.id === item.id));
          setRegisterData(data);
        
         }
         else{
          setRegisterData(data);
         }
        // }

        
      
      } // Update the state with the filtered data from the API
      
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };




  const columns = [
    {
      Header: " Patient Name",
      accessor:"patient_name",
     Cell: renderTable,
     style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
     sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataName(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' ,width:"100%"}}
        />
      ),
      // filterMethod: (filter, row) => {
      //   
      
      //   return row && row._original[0] && row._original[0].patient_name.toLowerCase().includes(filter.value.toLowerCase());
      // },
      
      // width: 220

    },

    {
      Header: "Age",
      accessor: "age",
      style: { whiteSpace: "unset",wordBreak: "break-all" },
      Cell: renderTable,
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataAge(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black', width:"100%" }}
        />
      ),

       width: 80

    },
    {
      Header: "Address",
      accessor: "address",
      Cell: renderTableAddress,
      style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
      sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
    
      Filter: ({ filter, onChange }) => (
        // Custom filter input component
        <input
          value={filter ? filter.value : ''}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue); // Update the filter value
           filterDataAddress(filterValue,); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black', width:"100%" }}
        />
      ),
     width: 150

    },
    {
      Header: "Disease",
      accessor: "disease",
     Cell: renderTableDisease,
     style: { whiteSpace: "unset",wordBreak: "break-all"},
     sortable: true,
      filterable: true,
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      Filter: ({ filter, onChange }) => (
        <select
          value={diseaseFilter}
          onChange={(e) => {
            setDiseaseFilter(e.target.value)
            setTreatmentFilter("")
            const filterValue = e.target.value;
            // onChange(filterValue); // Update the filter value
            filterDataDisease(filterValue,);// Make an API request and update the filteredData state
          }}
          style={{ color: 'black' ,width:"100%"}}
        ><option value='' hidden></option>
          <option value="">All</option>
          <option value="pri">Primary Disease</option>
          <option value="com">Comorbidities</option>
        </select>
      ),
      
     width: 200

    },
    {
      Header: "Treatment Details",
     accessor:"treatment",
      Cell: renderTableTreatment,
      style: { whiteSpace: "unset",wordBreak: "break-all",backgroundColor: "rgba(0,0,0,0.1)" },
      sortable: true,
        filterable: true,
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
        },
        Filter: ({ filter, onChange }) => (
          <select
            value={treatmentFilter}
            // value={filter ? filter.value :''}
            onChange={(e) => {
              setTreatmentFilter(e.target.value)
              setDiseaseFilter("")
              const filterValue = e.target.value;
              // onChange(filterValue);
              filterDataTreatment(filterValue);
            }}
            style={{ color: 'black' ,width:"100%"}}
          > <option value='' hidden></option>
            <option value="">All</option>
            <option value="test">Lab Tests</option>
            <option value="scan">Scans</option>
            <option value="pro">Procedure</option>
            <option value="med">Medications</option>
          </select>
        ),
         width: 250
  
      },
     
     
    
      features.includes("wound_management")&& {
        Header: "Wounds & Treatment",
        accessor: "wound",
        Cell: renderTableWound,
        style: { whiteSpace: "unset",wordBreak: "break-all"},
        sortable: true,
        filterable: true,
        filterMethod: (filter, row, column) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
        },
      
        Filter: ({ filter, onChange }) => (
          // Custom filter input component
          <input
            value={filter ? filter.value : ''}
            onChange={(e) => {
              const filterValue = e.target.value;
              onChange(filterValue); // Update the filter value
             filterDataWound(filterValue,); // Make an API request and update the filteredData state
            }}
            style={{ color: 'black', width:"100%" }}
          />
        ),
  
      },
     



  ]
  const handlePageChange = page => {
    if (hasNext) {
      apiCallFilter(page + 1, perPage);
    }
  }

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage)
    registerData.splice(0, registerData.length + 1)
    apiCallFilter(1, newPerPage);

  }
  useEffect(() => {
    apiCallFilter(page, perPage);
    setSelectedValue('')
  }, [perPage]);

  const CustomPagination = ({
    page,
    pageSize,
    pages,
    canPrevious,
    canNext,
    onPageChange
  }) => {
    return (
      <div style={{display:"flex"}}>
        <button style={{width:"30%",margin: "3px", borderRadius: "5px", padding:"10px"}} onClick={() => onPageChange(page - 1)} disabled={!canPrevious}>
          Previous
        </button>
        <div style={{ width:"40%", textAlign: "center", padding: "10px"}}><span style={{ margin: "10px" }}>Page {page + 1}</span></div> 
        <button style={{width:"30%",margin: "3px", borderRadius: "5px", padding:"10px"}} onClick={() => onPageChange(page + 1)} disabled={!canNext}>
          Next
        </button>
      </div>
    );
  };

  const display = <ReactTable
    columns={columns}
   data={registerData}
    defaultPageSize={perPage - 1}
    className="chol-table"
    resizable
    // pages={Math.ceil(totalRows / perPage)}
    minRows={perPage - 1}
    pageSizeOptions={[perPage - 1,10, 20, 25, 50,100]}
    pagination
    paginationServer
    onPageChange={(pageIndex) => { handlePageChange(pageIndex); setPage(pageIndex + 1) }}
    paginationTotalRows={totalRows}
    onPageSizeChange={handlePerRowsChange}
    PaginationComponent={CustomPagination}
  ></ReactTable>

 
  const data1 = registerData.map((patient) => {
    const patientData = patient; // Extract the patient data object from the response

    const lab_test = patientData.lab_test || [];
    const scans = patientData.scans || [];
    const procedures = patientData.procedures || [];
    const medication = patientData.medication || [];
    const comorbidities = patientData.comorbidities || [];
    const disease = patientData.diseases || [];

    return {
      patient_name: patientData.patient_name,
      age: patientData.age || "N/A",
      address: patientData.address || "N/A",
      disease:    ["Comorbidities:",
      ...comorbidities,
      "Primary Disease:",
      ...disease,].join("\n"),
      treatment: [
        "Lab Tests:",
        ...lab_test,
        "Scans:",
        ...scans,
        "Procedures:",
        ...procedures,
        "Medications:",
        ...medication,
   
      ].join("\n"),
      wounds:  patientData.wounds.map((wound) => `${wound.type} (${wound.status})`).join(", ") || "N/A",
    };
  })

const CsvRegisterHeaders = [
  { label: "Patient Name", key: "patient_name" },
  { label: "Age", key: "age" },
  { label: "Address", key: "address" },
  { label: "Disease", key: "disease" },
  { label: "Treatment Details", key: "treatment" },
  { label: "Wound & Treatment", key: "wound" },
  { label: "Wound Status", key: "status" }
];



  return (
    <>

    <div className="patient-report"> 
      <Nav className="row" style={{ marginTop: "1%",alignContent:'center',boxSizing: 'border-box' }}>
        <span className="col-6" style={{ width: '35.7%' }}>  
          <button className="buttonfilter" style={{ marginTop: "1%", borderRadius: "25px", width: "90px", background: "#1269B9",fontSize:"11px" }} onClick={(e) => handleRefresh(e)}>
            <span>Refresh &nbsp;<i className="fa fa-refresh" aria-hidden="true" style={{ color: "white" }}></i> </span>
          </button> 
        </span>
        <span className="col-6 togglegroup" style={{display:'flex', marginBottom: "1%", textAlign: "right", float: 'right' }}>
        <ToggleButtonGroup className="datepicker-reports" style={{ marginTop: "1%", fontSize: "small"}} type="radio" name="options" >
        <DateRangePicker
          appearance="default"              
          placeholder={"Select Date Range"}  
          value={dateRange} 
          onChange={handleEvent}
          disabledDate={(date) => isAfter(date, new Date())}
        >
          <ToggleButton id="tbg-radio-4" value={'custom'} className="btn btn-light toggle-btns custombtn1" style={{ border: 'none',fontSize:'11px'}}></ToggleButton>
        </DateRangePicker>&nbsp;&nbsp;&nbsp;
            
            <div style={{ display: 'flex', alignItems: 'center',height: '30px', fontSize: '11px', border: `1px solid ${selectedValue === 'day' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }}>
              <button className='arrowButton'  onClick={() => handleDayChange(-1)}>
                {<AiFillCaretLeft />}
              </button>
              <span style={{ margin: '0 8px', width: '50px', textAlign: 'center', fontSize:'11px' }}>
                {moment(currentDay).isSame(moment(), 'day') ? 'Today' : moment(currentDay).format('YYYY/MM/DD')}
              </span>
              <button className='arrowButton' onClick={() => handleDayChange(1)}>
                {<AiFillCaretRight />}
              </button>
            </div>&nbsp;&nbsp;&nbsp;
            
            <div style={{ display: 'flex', alignItems: 'center',height: '30px', fontSize: '11px', fontWeight: '400px', border: `1px solid ${selectedValue === 'month' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }}>
              <button className='arrowButton' onClick={() => handleMonthChange(-1)}>
                {<AiFillCaretLeft />}
              </button>
              <span style={{ margin: '0 4px', width: '50px', textAlign: 'center' }}>{currentMonth}</span>
              <button className='arrowButton' onClick={() => handleMonthChange(1)}>
                {<AiFillCaretRight />}
              </button>
            </div>&nbsp;&nbsp;&nbsp;
            <button   
              style={{ display: 'flex',justifyContent:'center',alignItems:'center', height: '30px',width:'50px', fontSize: '11px',backgroundColor:'white', fontWeight: '400px', border: `1px solid ${selectedValue === 'week' ? 'blue' : 'lightgrey'}`, borderRadius: "5px" }} 
              onClick={() => handleChangeValue('week')}
            > 
              <span>Week</span>
            </button>&nbsp;&nbsp;&nbsp;
            <div style={{ position: 'relative' }}>
          <button
            className="buttonfilter" 
            style={{ borderRadius: "20px",height: '32px', width: '60px', fontSize: '11px', background: isDropdownOpen ? "rgb(13, 197, 197)" : " #1269B9" }}
            onClick={toggleDropdown}
          ><span>
            More
            </span>
          </button>
          {isDropdownOpen && (
            <div
              style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                padding: '10px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                zIndex: 99,
                display: 'flex',
                flexDirection: 'column',
                width:'100px',
                alignItems:'center',
              }}
            >
              {filterOptions.map((filter) => (
                <label key={filter} style={{ marginBottom: '5px' }}>
                  <input
                    type="checkbox"
                    checked={selectedYears.includes(filter)}
                    onChange={() => handleFilterChange(filter)}
                    //disable others if one is selected
                    disabled={selectedYears.length > 0 && !selectedYears.includes(filter)}
                  />&nbsp;
                  {filter}
                </label>
              ))} 
                       <button disabled={selectedYears.length===0} style={{ marginTop: '10px' }} onClick={handleApplyFilters}>
                Apply
              </button>
            </div>
          )}
          </div>&nbsp;          
            
            
            <button className='buttonfilter' style={{ width: '100px',height: '32px', fontSize: '11px' }} onClick={toggleDownloadDropdown}>
              <span>Download  <MdDownloadForOffline /></span>
            </button>
          </ToggleButtonGroup> 
        </span>    
      </Nav>
      {isDownloadDropdownOpen && (
  <div style={{ position: 'relative', zIndex: 1000 }}>
    {/* Backdrop */}
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black color
        zIndex: 1000, // Ensure it's above other elements
      }}
      onClick={() => {}}
    ></div>
    
    {/* Dropdown */}
    <div className='download-dropdown'
      style={{ 
        position: 'absolute',
        margin: 'auto',
        width: '30rem',
        borderRadius: '10px',
        backgroundColor: '#fff',            
        padding: '10px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        zIndex: 1001,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '15rem',
        marginTop: '5%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          borderBottom: '1px solid lightgrey',
          marginBottom: '10px',
          paddingBottom:'10px'
        }}
      >
        <button
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            marginLeft: 'auto',
          }}
          onClick={toggleDownloadDropdown}
        >
          <GrClose />
        </button>
      </div>
      <div style={{display:"flex", flexDirection:"column"}}>
        <h4 style={{ marginBottom:"2rem"}}>Select the formats to download</h4>
        {downloadOptions.map((download) => (
          <label key={download} style={{ marginBottom: '5px' }}>
            <input
              type="checkbox"
              checked={selectedDownloads.includes(download)}
              onChange={() => handleDownloadChange(download)}
            />&nbsp;
            {download}
          </label>
        ))} 
      </div>
      <button
        variant="primary"
        className='buttonfilter'
        style={{width: '100px', height: '32px', fontSize: '11px', marginTop:"10px" }}
        onClick={handleDownloads}
      >
        Download
      </button>
    </div>
  </div>
)}
    
    
            <div className="service-table-holder" style={{  }}> {load === '' ? display
              : load === 'Error in Fetching Data' ? <h6 style={{ color: "red" }}>{load}</h6> : <h6 style={{ color: "grey" }}>{load}</h6>}
            </div>
          </div>
          <br />
          
        </>

      

  )
}

export default TreatmentReport
