import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
import ZoomableImage from '../BDO/Admission/ZoomableImage';
function AddRemarkReport(props) {
  const [remarkData, setRemarkData] = useState('')
  const [comments, setComments] = useState()
  const [refresh, setRefresh] = useState(false)
  const [flag, setFlag] = useState(false)
  const rptImg = props.rptImg
  const handleSave = () => {
    props.save()
  }
  const login_datas = JSON.parse(sessionStorage.getItem("logindatas"))
    ? JSON.parse(sessionStorage.getItem("logindatas"))
    : null;
  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );

    axios.get(`${APIURL}/api/v1/doctor/patient-documents-view/?patient_id=${props.id}`
      , {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          const data = res.data.results
          let filteredList = data.filter((item, index) => {
            return (item.id === props.reportId);
          })
          setComments(filteredList[0].comment)


          //setConsent(datas.consent_level)
        }
        else {

        }
      })
      .catch((err) => {

      })

  }, [refresh])
  const callSave = (rowToUpdate) => {

    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    let comment = comments


    const dataToUpdate = {
      comment,

    };

    axios
      .put(
        `${APIURL}/api/v1/doctor/patient-documents-view/?patient_id=${props.id}&id=${props.reportId}`,
        dataToUpdate,
        {
          headers: {
            Authorization: "Token " + str,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setFlag(false)
          setRefresh(!refresh)
          setRemarkData("")
          props.onSuccess()
        } else {

        }
      })
      .catch((err) => {

      });

  };
  
  return (<>
   
    <div className="form-button" style={{textAlign:"center"}}>


      <ZoomableImage src={rptImg} />
     

    </div>

    <div className=''>
      <small>Add Your Comments Here..</small>
      <textarea
        type="text"
        className="form-group text-area "
        value={comments}
      disabled={login_datas.user_type === "hospital_nurse"}
        onChange={(e) => {
          setFlag(true)
          e.preventDefault();
          setComments(e.target.value)
        }}
        autoComplete="off"
      /><br />
      {flag ? <button onClick={callSave} disabled={login_datas.user_type === "hospital_nurse"} className='btn btn-primary btn-col'>Save</button> : ""}


    </div>
  </>
  )
}

export default AddRemarkReport
