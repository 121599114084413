import React, { useState, useEffect, } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import axios from "axios";
import { APIURL } from '../../../Global'
import { Modal } from 'react-bootstrap';
function Volunteers() {
  const navigate = useNavigate();
  const [patientList, setPatientList] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(10);
  const [hasNext, setHasNext] = useState(true)
  const [page, setPage] = useState(0)
  const [load, setLoad] = useState(false)
  const [show,setShow]=useState(false)
  const [info, setInfo] = useState({})
  const handleLoad = async (page, per_page) => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')  /* REGEX To remove double quotes from tokenstring */




    axios.get(`${APIURL}/api/v1/service-provider/list-volunteer/`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        /* this to be changed once API is fixed */

        if (res.data.status === "success") {
          let Data = res.data.data
         
          setHasNext(res.data.has_next)
          setPage(res.data.page)
        
        //   Data = res.data
        //   .filter(function (o1) {
           
        //     return !patientList.some(function (o2) {
        //         return o1.id === o2.id;
        //     });
        // })
        
        setPatientList(Data);
        // setLoad(!load)
        } else {
          // setErrorMsg("error");
        }

       
      })
      .catch(err => {

        // setErrorMsg("error");
      })

  };

  useEffect(() => {
  
        if (hasNext) {
            handleLoad(page + 1, perPage)
        }
    
},[load])

const InfoPopup = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
                <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
                    <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
                </button>
            </Modal.Header>
            <Modal.Body style={{ padding: "7%",textAlign:'left'}}>
            <div >
                    <div
                        style={{ paddingRight: "4%" }}
                        className="list-group-flush"
                    >
                        <div
                            style={{ paddingRight: "4%" }}
                            className="list-group-flush"
                        >

{/* <h6 className='enquiryheader'  >  <img */}
                  {/* src={info.photo}
                  alt="Photo"
                  style={{ width: "70%", marginBotton:"2%",borderRadius:"50%"}}
                /></h6> */}

                            <div className='row'>
                               <div className='col-7'><b>Name </b></div> <div style={{textTransform:"capitalize"}} className='col-5'>:{info.first_name}{" "}{info.last_name}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>ID </b></div><div className='col-5'>:{info.id}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>Gender</b></div><div style={{textTransform:"capitalize"}}className='col-5'>:{info.gender}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>Date of birth </b></div><div className='col-5'>:{info.dob}</div>

                            </div><br />
                            {info.occupation &&<> <div className='row'>
                               <div className='col-7'><b>Occupation </b></div><div className='col-5'style={{textTransform:"capitalize"}}>:{info.occupation}</div>

                            </div><br /></>}
                            <div className='row'>
                               <div className='col-7'><b>Address: </b></div><div style={{textTransform:"capitalize"}} className='col-5'>:{info.city&&info.city+", "}{info.location&&info.location+", "}{info.state&&info.state+", "}{info.pincode&&info.pincode+", "}{info.country}</div>

                            </div><br />
                            {info.mobile_number &&<> <div className='row'>
                               <div className='col-7'><b>Phone: </b></div><div className='col-5'>:{info.mobile_number}</div>

                            </div><br /></>}
                            {info.email &&<> <div className='row'>
                               <div className='col-7'><b>Email: </b></div><div className='col-5'>:{info.email}</div>

                            </div><br /></>}
                           
                           
                            {info.father_name ||info.mother_name||info.spouse_name||info.no_of_kids?<h6 className='enquiryheader' style={{ textAlign: "left" }} > Family Information</h6>:""}<br/>

                            {info.father_name &&<> <div className='row'>
                               <div className='col-7'><b>Father: </b></div><div style={{textTransform:"capitalize"}} className='col-5'>:{info.father_name}</div>

                            </div><br /></>}
                            {info.mother_name &&<> <div className='row'>
                               <div className='col-7'><b>Mother: </b></div><div  style={{textTransform:"capitalize"}}className='col-5'>:{info.mother_name}</div>

                            </div><br /></>}
                            {info.spouse_name &&<> <div className='row'>
                               <div className='col-7'><b>Spouse: </b></div><div style={{textTransform:"capitalize"}} className='col-5'>:{info.spouse_name}</div>

                            </div><br /></>}
                            {info.no_of_kids&&<> <div className='row'>
                               <div className='col-7'><b>Number of kids: </b></div><div className='col-5'>:{info.no_of_kids}</div>

                            </div><br /></>}
                           
                            {info.graduation||info.post_graduation||info.other_education?<h6 className='enquiryheader' style={{ textAlign: "left" }} > Education Qualification</h6>:""}<br/>
                            {info.graduation &&<> <div className='row'>
                               <div className='col-7'><b>Graduation: </b></div><div className='col-5' style={{textTransform:"capitalize"}}>:{info.graduation}</div>

                            </div><br /></>}
                            {info.post_graduation &&<> <div className='row'>
                               <div className='col-7'><b>Post graduation: </b></div><div style={{textTransform:"capitalize"}}className='col-5'>:{info.post_graduation}</div>
 
                            </div><br /></>}
                            {info.other_education &&<> <div className='row'>
                               <div className='col-7'><b>Other: </b></div><div className='col-5'style={{textTransform:"capitalize"}}>:{info.other_education}</div>

                            </div><br /></>}
                          
                            {info.experience &&<> <div className='row'>
                               <div className='col-7'><b>Experience: </b></div><div className='col-5'style={{textTransform:"capitalize"}}>:{info.experience}</div>

                            </div><br /></>}
                           
                            {info.job_skill.length>0 &&<> <div className='row'>
                               <div className='col-7'><b> Job Skills: </b></div><div className='col-5'>:{info.job_skill.map((item)=>{
                                return(<span style={{textTransform:"capitalize"}}>{item}{", "}</span>)
                               })}</div>

                            </div><br /></>}
                            {info.languages.length>0 &&<> <div className='row'>
                               <div className='col-7'><b>Language Proficiency: </b></div><div className='col-5'>:{info.languages.map((item)=>{
                                return( <span>
                                
                                <span style={{ textAlign: "left" }} className='enquiryheader' > {item.name} </span> &nbsp;&nbsp;
                                <small style={{ color: item.speak === "no" && "#8080805c" }} className=' ' >&nbsp;<b>Speak</b>  </small>
                                <small style={{ color: item.read === "no" && "#8080805c" }} className=' ' >&nbsp; <b>Read </b></small>
                                <small style={{ color: item.write === "no" && "#8080805c " }} className=' ' >&nbsp; <b>Write</b> </small><br/>
                                </span>

                           )
                               })}</div>

                            </div><br /></>}
                        </div>
                    </div>

                  
                </div>
            </Modal.Body>
        </Modal>
    );
};

  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSearchTerm(e.target.value);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  let data =JSON.parse(JSON.stringify(patientList)) 
    .filter((value) => {
      if (searchTerm === "") {
        return value;
      } else if (
        (value.first_name+value.last_name).toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return value;
      }
    });
  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current1 = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = [];
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i);
  }
  const totalPages = pageNumbers.length;




  return (<>



    <div className="search-patient-page directory-page">

      <h2 className=" title-of-tasks"> Volunteers List </h2><br />

      <div className="search-section">


        <div className="search-div">
          <form style={{ display: "flex" }}>
            <input
              className="form-control search-input"
              type="text"
              placeholder="Enter Name"
              value={searchTerm}
              maxLength="150"
              onChange={handleSearchChange}
            />
            <button className="btn btn-primary btn-col search-btn">

              <i className="fas fa-search"></i>
            </button>
          </form>     </div>
        <div className="container ">
          {
            current1.length > 0 ?
              current1.map((value, index) => {
                // 

                return (


                  <div onClick={() => ""} key={index} className='list-item-vol'>
                    <span style={{fontSize:"20px",marginTop:"1%"}} className="each-list-link" >{(currentPage - 1) * 10 + index + 1}.{value.first_name}{" "}{value.last_name}</span>
                    <div className="details" style={{paddingTop:"1%"}}>
                    <h6 className="each-list-link" style={{color:"#1bbc51"}} >  {value.available_from.slice(0,10)}<span style={{color:"black"}}>{" "}--{" "}</span>{value.available_to.slice(0,10)}<br/><small style={{marginLeft:"38%"}}>{value.available_period==="all_days"?"All Days":value.available_period==="weekdays"?"Week Days":"Weekends"}</small> </h6>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h6 className="each-list-link" > Id: {value.id}</h6> &nbsp;&nbsp;&nbsp;&nbsp;
                      {/* <span> Hospital Id: {value.hospital_patient_id} </span> */}
                      <h6 className="each-list-link" > Phone : {value.mobile_number} </h6>&nbsp;&nbsp;&nbsp;&nbsp;
                      <h6 className="each-list-link" ><i  onClick={() => { setShow(true)
                                setInfo(value);
                               
                            }} style={{color:"purple"}} class="fa fa-info-circle" aria-hidden="true"></i></h6>
                      

                    </div>
                  </div>

                )
              }) : <h6 style={{ color: "red" }}>No Volunteers Found</h6>
          }

        </div>

      </div>
      <br />
      <br />
      {totalPages > 0 ?
        <div className="pagn" >

          <Pagination
            activePage={currentPage}
            itemsCountPerPage={perPage}
            totalItemsCount={total}
            pageRangeDisplayed={totalPages}
            onChange={handlePageChange}
            disabledClass="disabled-class"
          />
        </div>
        : null}
         {show ? (
                <InfoPopup
                    show={show}
                    onHide={() => {
                        setShow(false);
                    }}
                />
            ) : (
                ""
            )}
    </div>
  </>
  )
}


export default Volunteers;