import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import "./deathreport.css";
import DeathReportForm from './DeathReportForm';
import ApprovePending from './ApprovePending';
import Rejected from './Rejected';

function DeathReport() {
    const [selected, setSelected] = useState("form")
    const [patientDetails, setPatientDetails] = useState({})
    const [requests, setRequests] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [search, setSearch] = useState("");
    const [patient, setPatient] = useState({});

    useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/service-provider/patient-death/`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.data.status === "success") {

                    setRequests(res.data.message)

                }
            })
            .catch((err) => { });

    }, [refresh]);
    const handleSearchChange = (e) => {
        e.preventDefault();
        let searchTerm = e.target.value
        setSearch(e.target.value)

        let data = requests
            .sort((a, b) => {
                if (a.patient_name.toLowerCase() < b.patient_name.toLowerCase())
                    return -1;
                if (a.patient_name.toLowerCase() > b.patient_name.toLowerCase()) return 1;
                return 0;
                
            })
            .filter((value) => {
                if (searchTerm === "") {
                    setSelected("form")
                    return value;
                } else if (
                    value.patient_name.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                    return value;
                }
                else if (value.patient.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
                    return value
                }


            });
        if (data.length === 0) {
            setPatient([])
            setSelected("form")
        }
        else {
            let status = data[0].status
            const filteredData = data.filter(x => {
                return x.status === status
            })
            setPatient(filteredData)

            if (data.length > 0 && data[0].status === "rejected") {
                setSelected("rejected")
            } else if (data.length > 0 && data[0].status === "pending") {
                setSelected("approved")
            }
            else if (data.length > 0 && data[0].status === "approved") {
                setSelected("reports")
            }
        }
    }
    return (
        <div style={{ paddingLeft: "1rem", paddingRight: "1.5rem" }} className=''>
            <div className='row'>
                <div className="col-lg-2 col-md-2">
                    <h5
                        style={{
                            color: "#245693",
                            marginTop: "8%",
                            fontFamily: "inter",
                            fontWeight: "700",
                            marginLeft: "20px",
                        }}
                    >
                        Death Applications
                    </h5>
                    <div className="leftdivdeathrepo">
                        <div className="searchboxdeathrepo">  <input  placeholder="Search Name or ID"type="text" value={search}
                            onChange={e => { handleSearchChange(e) }} style={{ marginLeft: '10px', marginTop: "3px", border: "0", outline: "none", width: "85%" }}></input> </div>
                        <h6
                            style={{
                                color: "#2D3748",
                                fontFamily: "Helvetica",
                                fontWeight: "700",
                                marginLeft: "20px",
                                marginTop: "5px",
                                fontSize: "12px",

                            }}
                        >
                          
                        </h6>
                        <button className="deathreportbutton" onClick={e => { setPatient([]);setSearch(""); setSelected("form") }} style={{ backgroundColor: selected === "form" ? "#6D4482" : "#F8F8F8", color: selected === "form" ? "white" : "black" }}>
                            {" "}
                            <i
                                class="fa fa-user"
                                aria-hidden="true"
                                style={{ color: selected === "form" ? "white" : "#6D4482" }}
                            ></i>{" "}
                            &nbsp;&nbsp;Declaration
                        </button>
                        <button className="deathreportbutton" onClick={e => { setPatient([]); setSelected("approved");setSearch("") }} style={{ backgroundColor: selected === "approved" ? "#6D4482" : "#F8F8F8", color: selected === "approved" ? "white" : "black" }}>
                            {" "}
                            <i
                                class="fa fa-file"
                                aria-hidden="true"
                                style={{ color: selected === "approved" ? "white" : "#6D4482" }}
                            ></i>{" "}
                            &nbsp;&nbsp;Pending
                        </button>
                        <button className="deathreportbutton" onClick={e => { setPatient([]); setSelected("rejected");setSearch("") }} style={{ backgroundColor: selected === "rejected" ? "#6D4482" : "#F8F8F8", color: selected === "rejected" ? "white" : "black" }}>
                            {" "}
                            <i
                                class="fa fa-file"
                                aria-hidden="true"
                                style={{ color: selected === "rejected" ? "white" : "#6D4482" }}
                            ></i>{" "}
                            &nbsp;&nbsp;Rejected
                        </button>
                        <button
                            className="deathreportbutton"
                            style={{ backgroundColor: selected === "reports" ? "#6D4482" : "#F8F8F8", color: selected === "reports" ? "white" : "black" }}
                            onClick={e => { setPatient([]);setSearch(""); setSelected("reports") }}
                        >
                            {" "}
                            <i
                                class="fa fa-file"
                                aria-hidden="true"
                                style={{ color: selected === "reports" ? "white" : "#6D4482" }}

                            ></i>{" "}
                            &nbsp;&nbsp;Death Reports
                        </button>
                    </div>
                </div>
                <div className="col-lg-10 col-md-10">
                    {selected === "form" ? <DeathReportForm patient={patientDetails} onSuccess={() => { setSelected("approved");setRefresh(!refresh) }} /> :
                        selected === "approved" ? <ApprovePending person={patient}   /> : selected === "rejected" ? <Rejected person={patient} onSuccess={(data) => { setPatientDetails(data); setSelected("form") }} /> : <ApprovePending person={patient} status={"approved"} />}
                </div>
            </div>
        </div>
    )
}
export default DeathReport;