import React, { useState, useEffect, useRef } from 'react'
import './treat.css'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
function EditPlan(props) {
    const alert = useAlert();
  const [time, setTime] = useState(props.type!=="consumables"&&{ morning: props.data.serv_time.includes("morning")?true:false, afternoon: props.data.serv_time.includes("afternoon")?true:false, evening:props.data.serv_time.includes("evening")?true:false, night:props.data.serv_time.includes("night")?true:false })
  const [timeArray, setTimeArray] = useState([])
  const [qty, setQty] = useState(props.data.quantity&&props.data.quantity)
  const [cal, setCal] = useState(props.data.calorie&&props.data.calorie)

  const handleChange = (e) => {

    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
  if (key === "qty") {
    if(val>0){
      setQty(val)
    }else{
      setQty("")
    }
    }
    else if (key === "cal") {
      if(val>0){
        setCal(val)
      }else{
        setCal("")
      }
   
    }
  
  };
  useEffect(() => {
    let updatedArray = [];
    for (var key in time) {
      if (time[key] === true) {
        updatedArray.push(key);
      }
    }

    setTimeArray(updatedArray);

  }, [time]);
  const handleTime = (e) => {
    const key = e.target.name;

    setTime((currentstate) => ({
      ...currentstate,
      [key]: !time[key],
    })
    );

  };
  const callSave = () => {
 {/* props.type==="liquid"? qty===null&&alert.error("Enter quantity ") :
        props.type==="food"?qty===null||cal===null&&alert.error("Enter either quantityor calorie ") : */}

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    let url = ""
    let foods = []
    let data = {}
    let DataToSend = {}
    if (props.type === "liquid") {

      url = "/api/v1/doctor/liquid-instruction-detail/"+props.data.id+"/"
      DataToSend = {
        quantity:qty ,   
        serv_time:timeArray,  
      }
           
          
    
    } else if (props.type === "food") {
      url = "/api/v1/doctor/food-instruction-detail/"+props.data.id+"/"
      DataToSend = {
        quantity:qty!==""?qty:0 ,   
        serv_time:timeArray,    
        calorie:cal!==""?cal:0
      }
    }
    else if (props.type === "consumables") {
      url = "/api/v1/doctor/pat-consumable-detail/"+props.data.id+"/"
      
     
      DataToSend = {
        quantity:qty ,   
        
      }
    }
if(props.type==="liquid"&&qty===""){
    
        alert.error("Enter quantity ")
    
}
else if(props.type==="consumables"&&qty===""){
    
    alert.error("Enter quantity ")

}
else if(props.type==="food"&&(qty===""&&cal==="")){
   
        alert.error("Enter either quantity or calorie ") 

}
else{
    axios.put(`${APIURL}${url}`, DataToSend,
      {
        headers: {

          "Authorization": 'Token ' + str
        }
      })
      .then(res => {

        if (res.data.status === "success") {
alert.success("successfully Updated")
props.onSuccess()

        }
      })
}
  }

return(
    <div>
         {props.type!=="consumables"&& <div className='row'>
          <div className='col-6' >
           <><input
                className=""
                type="checkbox"
                id="morning"
                name="morning"
                checked={time.morning}
                onChange={(e) => { handleTime(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Morning</label></></div>
           <div className='col-6' >
          <><input
                className=""
                type="checkbox"
                id="afternoon"
                name="afternoon"
                checked={time.afternoon}
                onChange={(e) => { handleTime(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>After Noon</label></></div>
            <div className='col-6'>
             <><input
                className=""
                type="checkbox"
                id="evening"
                name="evening"
                checked={time.evening}
                onChange={(e) => { handleTime(e) }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Evening</label></></div>
            <div className='col-6' >
             <><input
                className=""
                type="checkbox"
                id="night"
                name="night"
                checked={time.night}
                onChange={(e) => { handleTime(e);  }}
              />{" "}<label className="" style={{ marginTop: "2%" }}>Night</label></></div>
          
          </div>}
          <div >
          <h6 style={{ marginTop: "2%", marginLeft: "2%" }}>{props.type==="consumables"?"Quantity":"Quantity per serve"}</h6>
          <input

          type="text"
          className="col-2 qty-div"
          maxLength="100"
          placeholder={props.type === "food" ? 'in gm' :props.type === "liquid" ? 'in ml':"in Nos"}
          value={qty}
          name="qty"
          onChange={handleChange}
        /></div>
        {props.type === "food" && <div>
         <h6 style={{ marginTop: "2%", marginLeft: "2%" }}>Calorie per serve</h6>
        <input

            type="text"
            className="col-2 qty-div"
            maxLength="100"
            placeholder='in cal'
            value={cal}
            name="cal"
            onChange={handleChange}
          />
        </div>}
        <button onClick={() => {timeArray.length === 0 &&props.type!=="consumables"? alert.error("Please choose time "): callSave() }} style={{ color: "white", marginLeft: "78%", width: "80px", backgroundColor: "#86d386", marginBottom: "2%" }} className='qty-div'>Save</button> 
        {/* props.type==="liquid"? qty===null&&alert.error("Enter quantity ") :
        props.type==="food"?qty===null||cal===null&&alert.error("Enter either quantityor calorie ") : */}
       
    </div>
)
}
export default EditPlan