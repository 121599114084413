import { useLocation, Navigate,useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";


const getHomeURL = (userType,projType, hospital_pending) => {
    let homeURL = "/";
    if(userType==="config_admin"){
        homeURL += hospital_pending ? "hospital_pending" : userType===null?"login":userType;;
    }
   else if(projType==="home_care"||projType==="elder_care"){   
    homeURL += hospital_pending ? "hospital_pending" :userType===null?"login":userType;;
    }
    // else if((projType==="elder_care"&&userType === "staff")||
    // (projType==="elder_care"&&userType === "hospital")|| (projType==="elder_care"&&userType === "hospital_bdo")||(projType==="elder_care"&&userType === "hospital_doctor")){
    //     homeURL += hospital_pending ? "hospital_pending" : userType;
    // }
  
    else{
        homeURL += hospital_pending ? "hospital_pending" : "AppUser";
    }
    return homeURL;
}

const RequireAuth = ({ type, children }) => {
    // use hooks to check this : TODO
    const loggedIn = sessionStorage.getItem("usertoken");
    const userType = sessionStorage.getItem("usertype");
   const projType=sessionStorage.getItem("project_type")
    const hospital_pending = sessionStorage.getItem("hospital_pending");
    const location = useLocation();

    if (loggedIn && userType||projType) {
        if ((projType==="home_care"&&userType === "staff" && type === "staff") ||
            (projType==="elder_care"&&userType === "staff" && type === "staff") ||
            (userType==="config_admin"&& type === "config_admin") ||
            (projType==="home_care"&&userType === "hospital_doctor" && type === "doctor") ||
            (projType==="elder_care"&&userType === "hospital_doctor" && type === "doctor") ||
            (projType==="home_care"&&userType==="patient" && type==="patient") ||
            (projType==="home_care"&&userType === "hospital_bdo" && type === "bdo") ||
            (projType==="elder_care"&&userType === "hospital_bdo" && type === "bdo") ||
            (projType==="elder_care"&&userType === "hospital_pro" && type === "pro") ||
            (projType==="elder_care"&&userType === "hospital_manager" && type === "manager") ||
            (projType==="elder_care"&&userType === "hospital_higher_official" && type === "higher_official") ||
            (projType==="home_care"&&userType === "hospital_pharmacist" && type === "pharmacy") ||
            (projType==="elder_care"&&userType === "hospital_pharmacist" && type === "pharmacy") ||
            (projType==="home_care"&&userType === "hospital_labuser" && type === "lab") ||
            (projType==="elder_care"&&userType === "hospital_labuser" && type === "lab") ||
            (projType==="home_care"&&userType === "hospital_allied_prof" && type === "allied") ||
            (projType==="elder_care"&&userType === "hospital_allied_prof" && type === "allied") ||
            (projType==="home_care"&&userType === "hospital_nurse" && type === "nurse") ||
            (projType==="elder_care"&&userType === "hospital_nurse" && type === "nurse") ||
            (projType==="home_care"&&userType === "hospital_sda" && type === "sda") ||
            (projType==="elder_care"&&userType === "hospital_sda" && type === "sda") ||
            (projType==="elder_care"&&userType === "visitor" && type === "visitor") ||
            (projType==="home_care"&&userType === "hospital" && type === "hospital" && !hospital_pending) ||
            (projType==="elder_care"&&userType === "hospital" && type === "hospital" && !hospital_pending) ||
            (projType==="home_care"&&userType === "hospital" && type === "hospital_pending" && hospital_pending)) {
            return children;
        }
        else if( (projType==="elder_care" &&userType === "hospital_doctor" && type ==="appuser") ||
        (projType==="elder_care"&&userType === "hospital_bdo" && type ==="appuser")){
            return children;
        }
        return <Navigate to={getHomeURL(userType,projType, hospital_pending)} state={{ from: location }} />;
    }

   
      

return <Navigate to="/login" state={{ from: location }} />;
    
}

export default RequireAuth;