export const handleFavorites = (item) => {
  // const {favListUpdated, setFavListUpdated} = useContext(FavUpdated);
  console.log("in handle fav fun",item);
  let isAlreadyAdded = false;
  let parsedData;
  const userId = sessionStorage.getItem("userid");
  const localStorageKey = `myFavorite_${userId}`;
  try {
    parsedData = JSON.parse(localStorage.getItem(localStorageKey)) || [];
  } catch (error) {
    parsedData = [];
  }
  const alreadyAddedItem = Array.isArray(parsedData) ? parsedData : [];
  isAlreadyAdded = alreadyAddedItem.some(
    (addedItem) => {
      return (
        addedItem.p_id === item.p_id
      );
    }
    //addedItem.item_service === item.item_service || addedItem.id === item.id
  );

  if (isAlreadyAdded) {
    console.log("item already added ");
    deleteFavorite(item.p_id);
    return;
  }
  console.log("data added to fav successfully");
  alreadyAddedItem.push(item); //.push('userid',item)
  localStorage.setItem(localStorageKey, JSON.stringify(alreadyAddedItem));
  //setDeleteFav(true);
  //updatefavWhileAdding();
};

//deleting items from fav_list using item.id
export const deleteFavorite = (id) => {
  console.log("fav delete function called",id);
  const userId = sessionStorage.getItem("userid");
  const localStorageKey = `myFavorite_${userId}`;
  const items = JSON.parse(localStorage.getItem(localStorageKey)) || [];
  const itemIndex = items.findIndex((item) => item.p_id === id);
  if (itemIndex !== -1) {
    items.splice(itemIndex, 1);
  }
  localStorage.setItem(`myFavorite_${userId}`, JSON.stringify(items));
  console.log(
    "fav data after deleting",
    localStorage.getItem(`myFavorite_${userId}`)
  );
};
