import React, { useState, useEffect } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import axios from "axios";
import { APIURL } from "../../../Global";
import { useNavigate, Link } from "react-router-dom";
import BdoNavbar from "../BdoNavbar";
import LoadingOverlay from "react-loading-overlay";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger, Button } from "react-bootstrap";
import GenerateInvoiceView from "./GenerateInvoiceViewBdo";
import "./service.css";
import { Modal } from "react-bootstrap";
let ServiceHistory = () => {
  const [ServiceList, setServiceList] = useState([]);
  const [image, setImage] = useState([]);
  const [activating, setActivating] = useState(false);
  const [viewShow, setViewShow] = useState(false)
  const [patient1, setpatient1] = useState("");
  const [orderbill, setorderBill] = useState("");
  const [orderType, setOrderType] = useState("");
  const [contact, setContact] = useState("");
  const [orderID, setOrderID] = useState("");
  const [infoShow, setinfoShow] = useState(false);
  const [infoData, setInfoData] = useState("");
  const [type, setType] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1)
  let tableData2 = []
  let navigate = useNavigate();
  const loadData = async (page, per_page) => {
    setActivating(true);
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(
        `${APIURL}/api/v1/service-provider/service-request-sp/?request_status=completed&page=${page}&per_page=${per_page}`,
        {
          headers: { Authorization: "Token " + v2 },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          let dataList = res.data.orders.filter((item, index) => {
            return (item.su_orders.length > 0)
          });
          setTotalRows(res.data.total_records)
          let updatedList = [];
          updatedList = dataList.map((item, index) => {

            let status;
            let statusObj;
            if (item.request_status.toLowerCase() === "completed") {
              status = "completed";
              statusObj = { value: "completed", label: "Completed" }
            }
            else if (item.request_status.toLowerCase() === "rejected") {
              status = "rejected";
              statusObj = { value: "rejected", label: "Rejected" }
            } else if (item.request_status.toLowerCase() === "cancelled") {
              status = "cancelled";
              statusObj = { value: "cancelled", label: "Cancelled" }
            }

            return ({
              patient_name: item.patient_name,
              id: item.order_id,
              order_type: item.order_type,
              su_orders: item.su_orders,
              // ref: item.service_request_order,
              patient_contact_no: item.patient_contact_no,
              item_service: item.order_type === "master_medicine" ? "Medicines" : item.order_type === "master_labtest" ? "Lab tests" : item.su_orders[0].item_service_name,
           
              status: status,
              statusObj: statusObj,
            }
            )
          })
          tableData2 = updatedList.filter(function (o1) {
            // filter out (!) items in result2
            return !ServiceList.some(function (o2) {
              return o1.id === o2.id;          // assumes unique id
            });
          })
        
          setActivating(false);

          setServiceList([...ServiceList, ...tableData2]);
        } else {
          setActivating(false);
         
        }
      })
      .catch((err) => {
        setActivating(false);

      });
  };
  const handlePageChange = page => {
    loadData(page + 1, perPage);
  }

  const handlePerRowsChange = async (newPerPage, pageindex) => {
    setPerPage(newPerPage);

  }
  useEffect(() => {
    loadData(page, perPage);
  }, [perPage]);

  const InvoiceData = (orderID) => {



    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");

    axios
      .get(
        `${APIURL}/api/v1/service-provider/order-invoice/?order_id=${orderID}&key=invoice`,
        {
          headers: { Authorization: "Token " + v2 },
        }
      )

      .then((res) => {

        setImage(res.data)
   
        setViewShow(true)

      })
  }
  const renderStatus2 = (cellInfo) => {
    let data = ServiceList[cellInfo.index].statusObj;
    let status = '';
    if (data.value.toLowerCase() === 'completed') {
      status = 'Completed';
    } else if (data.value.toLowerCase() === 'rejected') {
      status = 'Rejected';
    } else if (data.value.toLowerCase() === 'cancelled') {
      status = 'Cancelled';
    }
    return (
      <>
        <span>{status}</span>
      </>
    );
  }

  const InvoiceViewPopup = (props) => {


    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <GenerateInvoiceView patient={patient1} orderdetail={orderbill} ordertype={orderType} contactpt={contact} orderID={orderID} />

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Close</Button>


        </Modal.Footer>
      </Modal>
    );
  }
  const handleInfoPopUp = (item, type) => {
    setInfoData(item);
    setinfoShow(true);
  
    if (type === "master_medicine") {
      setType("Medicines")
    } else {
      setType("Lab Tests")
    }
  };
  const InfoPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"><h5>{type}</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>


            {infoData.map((item, index) => {
              return (
                <div className="row-of-features">
                  <div className="col-of-features"><br />
                    <h6> {index + 1}&nbsp;{item.item_service_name}</h6>
                    <h6>&nbsp;&nbsp;&nbsp;Quantity:&nbsp;{item.request_quantity}</h6>
                    <h6>&nbsp;&nbsp;&nbsp;Requested Due Date:&nbsp;{item.request_due_date}</h6>
                    <h6>&nbsp;&nbsp;&nbsp;Priority:&nbsp;{item.request_priority}</h6>
                    <h6>&nbsp;&nbsp;&nbsp;Status:&nbsp;{item.request_status}</h6>
                    <h6>&nbsp;&nbsp;&nbsp;Amount:&nbsp;{item.order_amount}</h6>



                  </div>
                </div>
              );
            })}


          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const handleBill = (item) => {

    setOrderID(item.id)
    InvoiceData(orderID)
    setpatient1(item.patient_name)
    setorderBill(item.order)
    setOrderType(item.order_type)
    setContact(item.patient_contact)
  }
  const renderRemarks3 = (cellInfo) => {



    return (
      <>




        {ServiceList[cellInfo.index].status === "completed" ? <i class="fa fa-file" aria-hidden="true" style={{ color: "var(--dark-teal)", cursor: "pointer" }} onClick={() => handleBill(ServiceList[cellInfo.index])}></i> : null}

      </>
      // <i style={{ color: "rgb(91 138 218)" }} className="fas fa-credit-card"></i>
    );

  };
  const renderService = (cellInfo) => {

    return (
      <div style={{ display: "flex", textTransform: "capitalize" }}>
        {" "}

        <div

        >
          {ServiceList[cellInfo.index][cellInfo.column.id]}
        </div>
        {ServiceList[cellInfo.index].order_type === "master_medicine" || ServiceList[cellInfo.index].order_type === "master_labtest" ? <i
          style={{ color: "#75759c", paddingLeft: "5%" }}
          className="fas fa-info-circle fa-sm"
          onClick={() => {
            handleInfoPopUp(ServiceList[cellInfo.index].su_orders, ServiceList[cellInfo.index].order_type);
          }}
        ></i> : ""}{" "}
      </div>
    );
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      sortable: true,
      filterable: true,
      style: { whiteSpace: "unset" },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      minWidth: 20,
    },
    {
      Header: "Service",
      accessor: "item_service",
      Cell: renderService,
      sortable: true,
      filterable: true,
      style: { whiteSpace: "unset" },
      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },
      minWidth: 40,
    },
    {
      Header: "Patient Name",
      accessor: "patient_name",
      sortable: true,
      filterable: true,
      style: { whiteSpace: "unset", textTransform: "capitalize" },
      filterMethod: (filter, row) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase())
          : true;
      },

      minWidth: 40,
    },

    {
      Header: "Status",
      accessor: "statusObj",
      Cell: renderStatus2,
      sortable: true,
      filterable: true,
      style: { whiteSpace: "unset" },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        }
        if (filter.value.toLowerCase() === "completed") {
          return row[filter.id].value === "completed";
        }
        if (filter.value.toLowerCase() === "rejected") {
          return row[filter.id].value === "rejected";
        }
        if (filter.value.toLowerCase() === "cancelled") {
          return row[filter.id].value === "cancelled";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">All</option>
          <option value="completed">Completed</option>
          <option value="rejected">Rejected</option>
          <option value="cancelled">Cancelled</option>
        </select>
      ),

      minWidth: 30,
    },
    {
      Header: "Invoice",

      Cell: renderRemarks3,
      style: { textAlign: "center" },
      sortable: true,
      filterable: true,
      minWidth: 40,
    },
  ];


  return (
    <>
      {/* <BdoNavbar/> */}
      <div
        id="patient-presc-page"
        className="appForm "
        style={{ width: "100%" }}
      >

        <LoadingOverlay
          active={activating}
          spinner
          styles={{
            spinner: (base) => ({
              ...base,
              width: "50px",
              "& svg circle": {
                stroke: "rgba(0, 0, 255, 0.5)",
              },
            }),
            overlay: {},
          }}
        >


          <div className="patient-presc-sections patient-presc-sections2">

            <div className="table-holder">
              <h5 className="text-center"><b>
                Service History
              </b></h5>
              <div className="service-table-holder" style={{ backgroundColor: "#efff001a", height: "34px" }}>

                <div class="row" style={{ textAlign: "left", paddingTop: "4px" }}>
                  <div class="col-5">
                    {/* <h5 className="" style={{ color: "var(--theme-blue)" }}><b> <i class="fa fa-th-large" aria-hidden="true"></i>{" "}Service History</b></h5> */}
                  </div>


                  <div class="col">

                  </div>
                  <div class="col">
                    <button onClick={() => navigate("/service_request")} type="button" style={{ border: "none", float: "right", textDecoration: "none" }} class=" btn-link"> Service Fulfillment{" "}<i class="fa fa-angle-right" aria-hidden="true"></i></button>

                  </div>

                </div>
              </div>
              <ReactTable
                filterable

                data={ServiceList}
                columns={columns}
                defaultPageSize={perPage - 1}
                className="service_table"
                pagination
                paginationServer
                paginationTotalRows={totalRows}

                onPageChange={(pageIndex) => { handlePageChange(pageIndex); setPage(pageIndex + 1) }}
                onPageSizeChange={handlePerRowsChange}
              />
            </div>
          </div>
        </LoadingOverlay>{" "}
      </div>

      {viewShow ?
        <InvoiceViewPopup
          show={viewShow}
          onHide={() => {
            setViewShow(false);
          }}
        /> : null}

      {infoShow ? (
        <InfoPopup
          show={infoShow}
          onHide={() => {
            setinfoShow(false);
          }}

        />
      ) : (
        ""
      )}
    </>
  );
};

export default ServiceHistory;
