import React,{useState} from 'react'
import Select from 'react-select';
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';

function EditSymptoms(props) {

    const[data, setData]= useState(props.data);
    const alert = useAlert(); 
    const [errormsg,setErrorMsg]=useState("")
    

    const [error,setError]=useState({
      observation_name:'',
        
       });
       let navigate=useNavigate()
    const [successShow, setSuccessShow] = useState(false)
    const [errorShow, setErrorShow] = useState(false)
    const[completeShow,setCompleteShow]=useState(false)
    const[errorValidate,setErrorValidate]=useState(false)





   
      const handleError = () =>
  {
    setErrorValidate(false);
    setSuccessShow(false)
  }
    
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


   
  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


   
  if (!input["symptom_name"] || input["symptom_name"]==="" ) {
    isValid = false;
    errors["symptom_name"] = "Enter symptom Name";
  }
  if (!input["symptom_type"] || input["symptom_type"]==="" ) {
    isValid = false;
    errors["symptom_type"] = "Enter symptom Type";
  }
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}

  const saveChanges = (e)=> {
    e.preventDefault();
 ;
 console.log(validate())
    if(validate()){
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  let dataToSend = {}
  if(props.data.symptom_name===data.symptom_name&&props.data.symptom_type===data.symptom_type){
    dataToSend={
      symptom_code : data.symptom_code
    }
  }else if(props.data.symptom_name===data.symptom_name){
    dataToSend={
      symptom_code : data.symptom_code,
      symptom_type : data.symptom_type
    }
  }else{
    dataToSend={
      symptom_name: data.symptom_name,                               
      symptom_type : data.symptom_type,
      symptom_code : data.symptom_code
    }
  }
   
   

  
 
     axios
       .put(`${APIURL}/api/v1/staff/master-symptom-detail/${data.id}/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          alert.success('Updated successfully')
          props.modalClose() 
          setData({
            symptom_name: "",                               
            symptom_type : "",
            symptom_code : ""
         });
         
         } else {
          alert.error('Check if Duplicated')
         }
       })
       .catch((err) => {
         alert.error('check if duplicated')
       });
       
    }

    else {
      setErrorValidate(true)
   }

}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Edit Symptoms</h4>
        <div style={{minHeight:'fit-content', width: '100%'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='align-rental'>*Symptom Name: </label>
                    <textarea type='text' className='form-control title-flex' name='symptom_name' value={data.symptom_name} onChange={handleTitle} /></div>
                    {error.symptom_name ? <div className="error-validation-msg error-feature-inventory">{error.symptom_name}</div> : null}
                </div>

                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>*Symptom Type: </label>
                    <input type='text' className='form-control title-flex' name='symptom_type' value={data.symptom_type} onChange={handleTitle} />
                    {error.symptom_type ? <div className="error-validation-msg error-feature-inventory">{error.symptom_type}</div> : null}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Symptom Code: </label>
                    <input type='text' className='form-control title-flex' name='symptom_code' value={data.symptom_code} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>            
            </div>            
        </div>
        <div style={{textAlign:'right'}}>
          <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>Cancel</button>
          <button onClick={saveChanges} className='btn btn-primary btn-col mb-2'>Save</button>   
        </div>
        </>
    )
}

export default EditSymptoms;
