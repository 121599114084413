import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { APIURL } from "../../../Global";
import Pagination from "react-js-pagination";
import "./pagination.css";
import SubServiceTable from "./SubServiceTable";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import BdoNavbar from "../BdoNavbar";

function MasterServices() {
  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [expand, setExpand] = useState();
  let navigate = useNavigate();

  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index);
    } else {
      setExpand();
    }
  };

  const loadData = () => {
    setLoading(true);
    const tokenString = sessionStorage.getItem("usertoken");
    let v2 = tokenString.replace(/["]+/g, "");
    axios
      .get(`${APIURL}/api/v1/service-provider/main-services/`, {
        headers: { Authorization: "Token " + v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setLoading(false);
          const datas = res.data.main_services;

          setServiceList(datas);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onSuccess = () => {
    loadData();
  };

  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let data = serviceList
    ? serviceList
        .sort((a, b) => {
          if (a.service_name.toLowerCase() < b.service_name.toLowerCase())
            return -1;
          if (a.service_name.toLowerCase() > b.service_name.toLowerCase())
            return 1;
          return 0;
        })
        .filter((value) => {
          if (searchTerm === "") {
            return value;
          } else if (
            value.service_name.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return value;
          }
        })
    : [];

  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = [];
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i);
  }
  const totalPages = pageNumbers.length;

  let display =
    current.length > 0
      ? current.map((data, index) => {
          return (
            <div className="list-item" style={{padding:"0px",paddingLeft:"15px"}}>
              {" "}
              <h4 className="each-list-link">
                <Link
                  to="#"
                  style={{ textDecoration: "none" }}
                  className={
                    expand === index ? "feature-data mt-3" : "feature-data"
                  }
                  onClick={() => handleDetail(index)}
                >
                  <h4>
                    {" "}
                    {data.service_name}
                    {"  "}
                    <i
                      className={
                        expand === index
                          ? "fa fa-angle-up fa-fw"
                          : "fa fa-angle-down fa-fw"
                      }
                      style={{ color: "#6788bc " }}
                    ></i>
                  </h4>
                </Link>
              </h4>
              {expand === index ? (
                <>
                  <SubServiceTable
                    id={data.id}
                    servicetype={data.service_type}
                    
                  />
                </>
              ) : null}
            </div>
          );
        })
      : null;
  const handleBack = (e) => {
    navigate(-1);
  };

  return (
    <>
      {/* <BdoNavbar /> */}
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "rgba(0, 0, 255, 0.5)",
            },
          }),
          overlay: {},
        }}
      >
        <div>
          <>
            <div className="container">
              <div className="container failedlist-parentcontainer">
                <div
                  className="title-of-page"
                  style={{}}
                >
                  <h2 className="title-of-tasks">Master Services List</h2>
                </div>

                <div className="search-section">
                  <div className="search-div">
                    <div style={{ display: "flex", marginLeft: "20%"  }}>
                      <input
                        className="form-control search-input"
                        type="text"
                        placeholder="Search a Service"
                        value={searchTerm}
                        maxLength="30"
                        onChange={handleSearchChange}
                      />
                      <button
                        onClick="#"
                        className="btn btn-primary btn-col search-btn"
                      >
                        {" "}
                        <i className="fas fa-search"></i>{" "}
                      </button>
                      <button
                        className="btn btn-primary btn-col"
                        style={{ marginLeft: "70%" }}
                        onClick={() => navigate("/selected-services")}
                      >
                        Services
                      </button>
                    </div>
                  </div>
                </div>
                <div className="failed-list-section">
                  <div className="container">
                    <div className="test-reports-display-list">
                      {display ? (
                        display
                      ) : (
                        <div
                          style={{
                            color: "red",
                            fontSize: "25px",
                            height: "200px",
                            padding: "40px",
                          }}
                        >
                          {!loading ? <h4>No list to show!!</h4> : ""}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div className="failed-list-section">
                  <div className="container">
                    <div className="test-reports-display-list">
                      {totalPages > 1 ? (
                        <div className="pagn ">
                          <Pagination
                            style={{
                              outerWidth: "100%",
                              justifyContent: "center",
                            }}
                            activePage={currentPage}
                            itemsCountPerPage={perPage}
                            totalItemsCount={total}
                            pageRangeDisplayed={totalPages}
                            onChange={handlePageChange}
                            disabledClass="disabled-class"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default MasterServices;
