import React, { useState, useEffect } from 'react'
import { ToggleButton, Badge, Button } from 'react-bootstrap';
import { DateRangePicker } from "rsuite";
import moment from 'moment';
import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
function AvailabilityFilter(props) {
  const { data, fromDate, toDate, fromDate1, toDate1, updateDateRange, onApplyFilter } = props;
  const [localFromDate, setLocalFromDate] = useState(fromDate);
  const [localToDate, setLocalToDate] = useState(toDate);
  const [localToDate1, setLocalToDate1] = useState(toDate1);
  const [registerData, setRegisterData] = useState([])
  const [show, setShow] = useState(false);
  const [timePeriod, setTimePeriod] = useState("month")
  const [localFromDate1, setLocalFromDate1] = useState(fromDate1);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedValue1, setSelectedValue1] = useState('');
  const [showMonths, setShowMonths] = useState(false);
  const [showYears, setShowYears] = useState(false);
  const [showMonths1, setShowMonths1] = useState(false);
  const [showYears1, setShowYears1] = useState(false);
  const [show1, setShow1] = useState(false);
  const months = [
    "January", "February", "March", "April",
    "May", "June", "July", "August",
    "September", "October", "November", "December"
  ];


  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2023;
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push(String(year));
    }
    return years;
  };


  const Years = [...generateYearOptions()];

  const generateYearOptions1 = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2023;
    const years = [];
    for (let year = startYear; year <= currentYear + 2; year++) {
      years.push(String(year));
    }
    return years;
  };


  const Years1 = [...generateYearOptions1()];

  const toggleMonths = () => {
    setShowMonths(!showMonths);
    setSelectedValue("month")

    setSelectedValue1("")
    setShowYears(false)
    setShow(false)
  };

  const toggleYears = () => {
    setShowYears(!showYears);
    setSelectedValue("year")
    setSelectedValue1("")
    setShowMonths(false)
    setShow(false)
  };

  const toggleMonths1 = () => {
    setShowMonths1(!showMonths1);
    setSelectedValue("")
    setSelectedValue1("month")
    setShowYears1(false)

    setShowMonths(false)
    setShowYears(false)
    setShow1(false)
  };

  const toggleYears1 = () => {
    setShowYears1(!showYears1);
    setSelectedValue("")
    setSelectedValue1("year")
    setShowMonths1(false)

    setShow1(false)
  };

  const alert = useAlert();
  const handleEvent = (event, picker) => {
    const a = moment(event[0]).format('YYYY-MM-DD');
    const b = moment(event[1]).format('YYYY-MM-DD');
    setLocalFromDate(a);
    setLocalToDate(b);
  };
  const handleEvent1 = (event, picker) => {
    const a = moment(event[0]).format('YYYY-MM-DD');
    const b = moment(event[1]).format('YYYY-MM-DD');
    setLocalFromDate1(a);
    setLocalToDate1(b);
  };
  const handleApplyFilter = () => {


    if (selectedJobSkills.length === 0 && localFromDate === "" && localFromDate1 === "") {
      alert.error("Please choose filter options")
    }
    else {
      // Call the callback function from the parent component
      onApplyFilter(selectedJobSkills, [localFromDate, localToDate], [localFromDate1, localToDate1]);
      props.handle();
    }
  };

  useEffect(() => {
    apiCallFilter(1, 10);
  }, [])

  const apiCallFilter = (page, per_page) => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //  2021-10-12
    axios.get(`${APIURL}/api/v1/service-provider/pv-reports/?start_date=&end_date=&start_available=&end_available=&page=${page}&per_page=${per_page}&timezone=${currentTimezone}&volunteer=volunteer`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {


          let datalist = res.data.volunteers
          //const data1 = datalist.filter((item) => !registerData.some((existingItem) => existingItem.id === item.id));
          setRegisterData([...registerData, ...datalist])

        }
        else {

        }

      })


      .catch(err => {

      })


  }


  const handleChangeValue = (val, value) => {
    setSelectedValue(val);
    setSelectedValue1("")
    // registerData.splice(0, registerData.length + 1)
    setTimePeriod(val)
    if (val === 'yesterday') {
      setShowMonths(false)
      setShowYears(false)
      setShow(false)
      setShowMonths1(false)
      setShowYears1(false)
      setShow1(false)
      var today = new Date();
      var yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      var formattedYesterdayDate = moment(yesterday).format('YYYY-MM-DD');

      setLocalFromDate(formattedYesterdayDate);
      setLocalToDate(formattedYesterdayDate);
    }
    if (val === 'week') {
      setShowMonths(false)
      setShowYears(false)
      setShow(false)
      setShowMonths1(false)
      setShowYears1(false)
      setShow1(false)
      // Calculate the start of the week (Sunday)
      var startOfWeek = moment().startOf('week').toDate();

      // Calculate the end of the week (today)
      var endOfWeek = moment().endOf('day').toDate();

      var FormatedFirstday = moment((startOfWeek)).format('YYYY-MM-DD');
      var Formatedlastday = moment((endOfWeek)).format('YYYY-MM-DD');
      setLocalFromDate(FormatedFirstday)
      setLocalToDate(Formatedlastday)


    }
    if (value === 'month') {

      const { fromDate, toDate } = calculateMonthDates(val);
      setLocalFromDate(fromDate);
      setLocalToDate(toDate);
    }
    if (value === 'year') {

      const year = parseInt(val, 10);
      const fromDate = moment(`${year}-01-01`).format('YYYY-MM-DD');
      const toDate = moment(`${year}-12-31`).format('YYYY-MM-DD');
      setLocalFromDate(fromDate);
      setLocalToDate(toDate);
    }

  }

  const handleChangeValue1 = (val, value) => {
    setSelectedValue1(val);
    setSelectedValue("")
    // registerData.splice(0, registerData.length + 1)
    setTimePeriod(val)
    if (val === 'yesterday') {
      setShowMonths1(false)
      setShowYears1(false)
      setShowMonths(false)
      setShowYears(false)
      setShow(false)
      setShow1(false)
      var today = new Date();
      var yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      var formattedYesterdayDate = moment(yesterday).format('YYYY-MM-DD');

      setLocalFromDate1(formattedYesterdayDate);
      setLocalToDate1(formattedYesterdayDate);
    }
    if (val === 'week') {
      setShowMonths1(false)
      setShowYears1(false)
      setShow1(false)
      setShowMonths(false)
      setShowYears(false)
      setShow(false)
      // Calculate the start of the week (Sunday)
      var startOfWeek = moment().startOf('week').toDate();

      // Calculate the end of the week (today)
      var endOfWeek = moment().endOf('day').toDate();

      var FormatedFirstday = moment((startOfWeek)).format('YYYY-MM-DD');
      var Formatedlastday = moment((endOfWeek)).format('YYYY-MM-DD');
      setLocalFromDate1(FormatedFirstday)
      setLocalToDate1(Formatedlastday)


    }
    if (value === 'month') {

      const { fromDate, toDate } = calculateMonthDates(val);
      setLocalFromDate1(fromDate);
      setLocalToDate1(toDate);
    }
    if (value === 'year') {

      const year = parseInt(val, 10);
      const fromDate = moment(`${year}-01-01`).format('YYYY-MM-DD');
      const toDate = moment(`${year}-12-31`).format('YYYY-MM-DD');
      setLocalFromDate1(fromDate);
      setLocalToDate1(toDate);
    }

  }
  const calculateMonthDates = (selectedMonth) => {
    const currentDate = moment();
    const targetMonth = moment(selectedMonth, 'MMMM YYYY');

    const fromDate = targetMonth.startOf('month').format('YYYY-MM-DD');
    const toDate = targetMonth.endOf('month').format('YYYY-MM-DD');

    return { fromDate, toDate };
  };

  // Extract all unique job skills from the data
  const jobSkills = Array.from(
    new Set(registerData.map(volunteer => volunteer.job_skill).flat())
  );

  // State to keep track of selected job skills
  const [selectedJobSkills, setSelectedJobSkills] = useState([]);

  // Function to toggle a selected job skill
  const toggleJobSkill = (jobSkill) => {
    if (selectedJobSkills.includes(jobSkill)) {
      setSelectedJobSkills(selectedJobSkills.filter(skill => skill !== jobSkill));
    } else {
      setSelectedJobSkills([...selectedJobSkills, jobSkill]);
    }
  };

  return (
    <div style={{ width:'100%', height: "100%", textAlign: "center", overflow: "auto" }}>
      <div className="row" style={{width:'100%', margin:'auto'}}>&nbsp;&nbsp;&nbsp;&nbsp;

        <div className="col-3" style={{ border: "solid grey 1px" }}>
          <div className="job-skill-list" style={{ textAlign: "left" }} >
            <label style={{ marginTop: "5px" }}> <b>Job Skills </b></label>
            {jobSkills.map((skill, index) => (
              <div key={index} style={{ display: 'block' }}>
                <label >
                  <input
                    type="checkbox"
                    value={skill}
                    checked={selectedJobSkills.includes(skill)}
                    onChange={() => toggleJobSkill(skill)}
                  />
                  &nbsp;&nbsp;{skill}
                </label>
              </div>
            ))}
          </div></div>
        &nbsp;&nbsp;&nbsp;
        <div className="col-4" style={{ border: "solid grey 1px" }}>
          {/* Date Range Picker */}
          <div style={{}}>
            <label style={{ marginTop: "5px" }}> <b>Registration: </b></label>&nbsp;
            <DateRangePicker
              appearance="default"
              style={{ width: "210px", }}
              placeholder={"Select Date Range"}
              onChange={handleEvent1}
              disabledDate={(date) =>
                isAfter(date, new Date())}
            >

            </DateRangePicker>
            <br />
            <br />
            <button className="buttonfilter" style={{ borderRadius: "25px", marginTop: "5%", width: 'fit-content', background: selectedValue1 === 'yesterday' ? "rgb(13, 197, 197)" : " #1269B9" }} onClick={() => handleChangeValue1('yesterday')}>

              <span>Yesterday</span>
            </button>&nbsp;&nbsp;&nbsp;
            <button className="buttonfilter" style={{width: 'fit-content', borderRadius: "25px", marginTop: "5%", background: selectedValue1 === 'week' ? "rgb(13, 197, 197)" : " #1269B9" }} onClick={() => handleChangeValue1('week')}>

              <span>Week</span>
            </button>&nbsp;&nbsp;&nbsp;

            <button className="buttonfilter"
              style={{
                width: 'fit-content',
                position: "relative",
                background: showMonths1 ? "rgb(13, 197, 197)" : "#1269B9", marginTop: "5%",
              }}
              onClick={toggleMonths1}
            >

              <span>Month </span>
              {showMonths1 && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    color: "black",
                    background: "#e4dd9f",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    zIndex: 1,
                    fontWeight: 700
                  }}
                >
                  {months.map((month, index) => {
                    const monthDate = moment(month, 'MMMM YYYY');
                    if (monthDate.isSameOrBefore(moment(), 'month')) {
                      return (
                        <div
                          key={index} className="year-item"
                          onClick={() => {
                            handleChangeValue1(month, "month");
                            toggleMonths1();
                          }}
                          style={{
                            padding: "8px 16px",
                            cursor: "pointer",

                          }}
                        >
                          {month}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </button>

            &nbsp;&nbsp;&nbsp;

            <button className="buttonfilter"
              style={{
                width: 'fit-content',
                position: "relative",
                background: showYears1 ? "rgb(13, 197, 197)" : "#1269B9", marginTop: "5%",
              }}
              onClick={toggleYears1}
            >

              <span>Years </span>

              {showYears1 && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    color: "black",
                    background: "#e4dd9f",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    zIndex: 1,
                    fontWeight: 700
                  }}
                >
                  {Years.map((month, index) => (
                    <div
                      key={index} className="year-item"
                      onClick={() => {
                        handleChangeValue1(month, "year");
                        toggleYears1();
                      }}
                      style={{
                        padding: "8px 16px",
                        cursor: "pointer",
                        transition: "background-color 0.3s ease", // Add a transition for smooth hover effect
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.1)", // Change the background color on hover
                        },
                      }}
                    >
                      {month}
                    </div>
                  ))}

                </div>
              )}</button>
          </div>
        </div>
        &nbsp;&nbsp;&nbsp;
        <div className="col-4" style={{ border: "solid grey 1px" }}>
          {/* Date Range Picker */}
          <div style={{}}>
            <label style={{ marginTop: "5px" }}> <b>Availability: </b></label>&nbsp;
            <DateRangePicker
              appearance="default"
              style={{ width: "210px", }}
              placeholder={"Select Date Range"}
              onChange={handleEvent}
            >

            </DateRangePicker>

            <br />
            <br />
            {/* <button  className="buttonfilter" style={{borderRadius:"25px",marginTop:"5%" , width:"28%",background:selectedValue==='yesterday' ? "rgb(13, 197, 197)" : " #1269B9" }} onClick={()=>handleChangeValue('yesterday')}>
 
 <span>Yesterday</span>
</button>&nbsp;&nbsp;&nbsp; */}
            <button className="buttonfilter" style={{ borderRadius: "25px", marginTop: "5%",width: 'fit-content', background: selectedValue === 'week' ? "rgb(13, 197, 197)" : " #1269B9" }} onClick={() => handleChangeValue('week')}>

              <span>Week</span>
            </button>&nbsp;&nbsp;&nbsp;

            <button className="buttonfilter"
              style={{
                position: "relative",
                width: 'fit-content',
                background: showMonths ? "rgb(13, 197, 197)" : "#1269B9", marginTop: "5%",
              }}
              onClick={toggleMonths}
            >

              <span>Month </span>
              {showMonths && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    color: "black",
                    background: "#e4dd9f",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    zIndex: 1,
                    fontWeight: 700
                  }}
                >
                  {months.map((month, index) => {
                    return (
                      <div
                        key={index}
                        className="year-item"
                        onClick={() => {
                          handleChangeValue(month, "month");
                          toggleMonths();
                        }}
                        style={{
                          padding: "8px 16px",
                          cursor: "pointer",
                        }}
                      >
                        {month}
                      </div>
                    );
                  })}

                </div>
              )}
            </button>

            &nbsp;&nbsp;&nbsp;

            <button className="buttonfilter"
              style={{
                width: 'fit-content',
                position: "relative",
                background: showYears ? "rgb(13, 197, 197)" : "#1269B9", marginTop: "5%",
              }}
              onClick={toggleYears}
            >

              <span>Years </span>

              {showYears && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    color: "black",
                    background: "#e4dd9f",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    zIndex: 1,
                    fontWeight: 700
                  }}
                >
                  {Years1.map((month, index) => (
                    <div
                      key={index} className="year-item"
                      onClick={() => {
                        handleChangeValue(month, "year");
                        toggleYears();
                      }}
                      style={{
                        padding: "8px 16px",
                        cursor: "pointer",
                        transition: "background-color 0.3s ease", // Add a transition for smooth hover effect
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.1)", // Change the background color on hover
                        },
                      }}
                    >
                      {month}
                    </div>
                  ))}

                </div>
              )}</button>
          </div>

          {/* Job Skills List with Checkboxes */}

        </div>

      </div>
      <button className="buttonfilter" style={{ width: "15%", textAlign: "center", marginTop: "30px" }} onClick={handleApplyFilter}>Apply</button>
      <button className="buttonfilter" style={{ width: "15%", textAlign: "center", marginTop: "30px" }} onClick={() => { props.handle() }}>Close</button>
    </div>
  );
}

export default AvailabilityFilter;
