import React,{useState,useEffect,useContext} from 'react';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import "./presc.css";
import "./presctable.css";
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";
import EditMedicine from './EditMedicine'
//import {MedContext} from "../contexts/MedContext";
import "./currentmedications.css";
import DatePopUp from './DatePopUp';
//import ImageScale from '../ImageScale/ImageScale';
import ViewRemark from './ViewRemark';
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { useAlert } from "react-alert";
function CurrentMedications(props){
  const alert = useAlert();

const [img ,setImg] = useState([]);
const [index ,setIndex]= useState();
//const [medRender,setMedRender]=useContext(MedContext);
const [dateShow ,setDateShow] =useState(false)
const [prescData ,setPrescData]= useState([]);
const [url,setUrl]=useState("")
  const [medList,setMedList]=useState([    { drug: "",
                 
  from: "",
  to:"",
 
  dosage: "",

  intake_count: [],
  intake_time: '',
  remarks: ""
   }]);

  const [editShow, setEditShow]= useState(false)      
  const [render,]=useState(true);
  const [errorMsg,setErrorMsg]=useState('');
  const [doctor1,setDoctorname]=useState('');
  const [submitMsg,setSubmitMsg]=useState("");
  const [modalShow,setModalShow] =useState(false);
  const [imageShow, setImageShow]= useState(false)
  //const [clickShow ,setClickShow]= useState(false)
  const [remarkData, setRemarkData]= useState('')
  const [viewShow, setViewShow]= useState(false)
const [errorSubmitModalShow,setErrorSubmitModalShow]=useState(false);
const [deleteMedConfirmMsg,setDeleteMedConfirmMsg]=useState("");
const [deleteMedConfirmModalShow,setDeleteMedConfirmModalShow]=useState(false);
const [refresh, setRefresh]=useState(false);
const [medIndexToDelete,setMedIndexToDelete] = useState();
const [medDelete, setMedDelete] = useState(false);
const [medRefresh, setMedRefresh] = useState(false);
const[dataList,setDataList]=useState([])
 const id = props.id;
 const refrsh = props.refresh;

 const [keys,] = useState({'z' :false });


           useEffect(()=>{
            const handleKeyDown =(e)=>
            {      
              keys[e.key]=true
          
              if(e.altKey && keys['z']){
                setErrorSubmitModalShow(false)
                setErrorMsg('')
                setModalShow(false)
               // setMedRender(!medRender)
                setSubmitMsg('')
              }
          
            }
            window.addEventListener('keydown', handleKeyDown)
            
            
            const handleKeyUp =(ev)=>
          {
              
              keys[ev.key]= false
            }
          
          window.addEventListener('keyup', handleKeyUp)
          
          
          
            return()=>{
              window.removeEventListener('keydown', handleKeyDown)
              window.removeEventListener('keyup', handleKeyUp)
              
            }
          },[submitMsg, errorMsg]);


 
  useEffect(()=>{

    setMedList(medList)
   
  },[render, medList]);
  

  useEffect(()=>{

  

    const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
   const currentDate = new Date();
   const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
   
 // Get current timezone dynamically
 const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   
    
   
    axios.get(`${APIURL}/api/v1/doctor/update-current-medication/?medicines_list=current_medicines&patient_id=${id}&timeZone=${currentTimezone}&current_date=${formattedDate}`, 
    { headers: {
      'Content-Type': 'application/json',
      "Authorization" : 'Token '+str  }})
       .then(res=>{
    

   

      if(res.data.status==="success"){

        let doctorname="";

        let data = []
        if(res.data.doctor.length ===0 && res.data.patient.length ===0){
          data=[]
        }

        if(res.data.doctor.length !==0){
          res.data.doctor.map((item)=> {
            data = data.concat(item.medicines)
            // doctorname=data.doctor_name;
          })
        }
        if(res.data.patient.length !==0){
          res.data.patient.map((item)=> {
            data = data.concat(item.medicine)
            //patient_name=data.patient_name;
          })
        }


        // if(res.data.prescription.length !==0){
        //   res.data.prescription.map((item)=> {
        //     data = data.concat(item.medicines)
        //   })
        // }
        setMedList(data)
        //setDoctorname(doctorname)
       
      //  if (res.data && res.data.doctor && res.data.doctor.length > 0) {
      //     const data = res.data.doctor.medicines;
      //     setMedList(data);
      


      }else{

        setErrorMsg(res.data.message? res.data.message : "Error in fetching data");
        setErrorSubmitModalShow(true);
       // setMedList([]);
      }


    })
    .catch(err=>{
  
  setErrorMsg("Error in fetching data");
  setErrorSubmitModalShow(true);
})
 

 
},[id, refresh, refrsh])


/*const AddRow=()=>{
  const list = medList;
  list.push({drug:"", strength: "",form:"",duration: "",route:"", dosage:"",frequency:"",intake_count:[]});
  
  setMedList(list);
  setRender(!render);
  
}*/





const modalClose = () => {
  setEditShow(false)    
  setRefresh(!refresh);
};

const EditPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Body className="text-center">
        <EditMedicine data={dataList} modalClose={modalClose}/>
      </Modal.Body>
    </Modal>
  );
}


const handleShow=(index)=> {
  const newarr=medList;
  const newList = newarr.filter((elt,i)=>{
    return i=== index
  })

  let data=[]
  for(let i=0; i<prescData.length; i++) {
    if(prescData[i].prescription_id===newList[0].prescription_id){
      prescData[i].image.map(item=>{
            data= data.concat(item)
        })
      setImg(data)
      
      break;
    }
    else {
      setImg([])
    }
  }

  
  setImageShow(true)
  
  
}

const ViewPopup=(props)=>{
    

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className="text-center">
        <ViewRemark data={remarkData} url={url} />

      </Modal.Body>
    </Modal>
  );
}

const handleView =(data,url) => {
 
  setUrl(url)
  setRemarkData(data)
  setViewShow(true)
}

const renderRemarks= (cellInfo) => {
  if(medList[cellInfo.index][cellInfo.column.id]==="" || medList[cellInfo.index][cellInfo.column.id]===null){
    return null
   }
   else{
   
    return (
      <button onClick={()=> handleView(medList[cellInfo.index][cellInfo.column.id],"")} className='btn btn-primary btn-col btn-square btn-small'>View</button>
      )
   }
}


const renderEditable=(cellInfo)=>{


  return(
    <div style={{textTransform:"lowercase"}}
        className="form-control"
      > {medList[cellInfo.index][cellInfo.column.id]}</div> )
}
const renderEditableCr=(cellInfo)=>{


  return(
    <div  style={{textTransform:"capitalize"}}
        className="form-control"
      > {medList[cellInfo.index][cellInfo.column.id]}
      <br/><small>{medList[cellInfo.index].creator_type}</small></div> )
}
const renderEditableStrength=(cellInfo)=>{

  return(
    <div 
        className="form-control"
      > {medList[cellInfo.index].strength}</div> )
}

const renderEditableDuration=(cellInfo)=>{
let day = 'day';
if (medList[cellInfo.index][cellInfo.column.id] && parseInt(medList[cellInfo.index][cellInfo.column.id]) > 1) {
  day = 'days'
}
  return(
    <div 
        className="form-control"
      > {medList[cellInfo.index][cellInfo.column.id]} {medList[cellInfo.index].duration_unit} </div> )
}

const renderDr=(cellInfo)=>{
const creator =medList[cellInfo.index]
const type =creator.creator_type
  return(

    type==='doctor'?

    <div 
        className="form-control"
      ><h6> {medList[cellInfo.index].doctor}</h6>
       <h6> {medList[cellInfo.index].hospital} </h6> </div> :

       type==='self'?

       <div 
        className="form-control"
      ><h6>Added by Patient</h6>
       </div> : 

       type=== undefined ?
       <div 
        className="form-control"
      ><h6>Added as a Prescription</h6>
      <button className="presc-btn" 
       onClick={()=>{handleShow(cellInfo.index);}}>Click to View</button>
       </div> : null

        )
}
const renderIntakeTime=(cellInfo)=>{
  const food_time=medList[cellInfo.index][cellInfo.column.id];
  // let food_label="";
 
  // if(food_time=== "1"){
  //   food_label= "Before food";
  // }
  // else if(food_time=== "2"){
  //   food_label= "After food";
  // } 
   return(

    <div 
        className="form-control"
      > {food_time}</div> 
    

)
}
const renderIntakeCount=(cellInfo)=>{
  const intakeval=medList[cellInfo.index][cellInfo.column.id];
  let intakecount_label="";
  if(intakeval=== "1"){
    intakecount_label= "Daily";
  }
  else if(intakeval=== "2"){
    intakecount_label= "Weekly";
  }
  else if(intakeval=== "3"){
    intakecount_label= "Monthly";
  } 
  else if(intakeval=== "4"){
    intakecount_label= "Alternate days";
  } 
  else if(intakeval=== "5"){
    intakecount_label= "weekly twice";
  } 
  else if(intakeval=== "6"){
    intakecount_label= "weekly Thrice";
  } 
 
   return(

    <div 
        className="form-control"
      > {intakeval}</div> 
    

)
}


  const deleteMedicineRow = (index)=>{

    setDeleteMedConfirmMsg('success');
    setDeleteMedConfirmModalShow(true);
    setMedIndexToDelete(index);
     
  }
  const handleEdit = (data)=>{
  setDataList(data)
  setEditShow(true)
  }
  const renderEdit= (cellInfo) => {
    if(medList[cellInfo.index][cellInfo.column.id]==="" || medList[cellInfo.index][cellInfo.column.id]===null){
      return null
     }
     else{
     
      return (
        <i style={{color:" var(--theme-blue)"}}  onClick={()=>handleEdit(medList[cellInfo.index]) } class="fas fa-pencil-alt" aria-hidden="true"></i>
        )
     }
  }



const columns = [

  {
    Header: "",
    Cell: props=>{
      return (<>
        <i className="fas fa-trash-alt" style={{marginLeft:"20%",color:"red"}}  onClick={() => { deleteMedicineRow(props.index) }} ></i>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {medList[props.index].pharmacy_status!=="rejected"?<OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled"> Available</Tooltip>}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 10 10"><path fill="green" d="M4.998 0a4.998 4.998 0 1 0 0 9.995a4.998 4.998 0 0 0 0-9.995ZM1 4.998a3.998 3.998 0 1 1 7.995 0a3.998 3.998 0 0 1-7.995 0Zm6.104-1.602a.5.5 0 0 1 0 .708l-2.25 2.25a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647l1.896-1.897a.5.5 0 0 1 .708 0Z"/></svg></OverlayTrigger>:
        <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">not  Available</Tooltip>}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path fill="red" d="M12 20a8 8 0 0 1-8-8H2c0 5.523 4.477 10 10 10v-2Zm0-16a8 8 0 0 1 8 8h2c0-5.523-4.477-10-10-10v2Zm-8 8a7.97 7.97 0 0 1 2.343-5.657L4.93 4.93A9.972 9.972 0 0 0 2 11.999h2Zm2.343-5.657A7.972 7.972 0 0 1 12 4V2a9.972 9.972 0 0 0-7.071 2.929l1.414 1.414Zm-1.414 0l12.728 12.728l1.414-1.414L6.343 4.929L4.93 6.343ZM20 12a7.97 7.97 0 0 1-2.343 5.657l1.414 1.414A9.972 9.972 0 0 0 22 12h-2Zm-2.343 5.657A7.972 7.972 0 0 1 12 20v2a9.972 9.972 0 0 0 7.071-2.929l-1.414-1.414Z"/></svg></OverlayTrigger>}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
       {(medList[props.index].med_interactions!=="null"&&medList[props.index].med_interactions!==""&&medList[props.index].med_interactions!==null)||
    (medList[props.index].med_url!=="null"&&medList[props.index].med_url!==""&&medList[props.index].med_url!==null)?<i style={{color:"Highlight"}}  onClick={() => {handleView(medList[props.index].med_interactions,medList[props.index].med_url)}} class="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i>:""}</>
      )
    },
    sortable:false,
    filterable:false,
    minWidth:120
  
  },
{
  Header: "Drug Name",
  accessor: "drug",
  Cell: renderEditable,
  
  minWidth:100,
   sortable:false,
   filterable:false,
   width:200

},

{
  Header: "Strength",
  accessor: "strength",
  minWidth:80,
  width:110,
  Cell: renderEditableStrength,
  sortable:false,
  filterable:false

},
{
  // Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
  //              data-placement = "top" title = "INTAKE COUNT">COURSE</span>,
  Header: "Intake Time",
  accessor: "intake_time",
  Cell: renderEditable,
  sortable:false,
  width:180,
  minWidth:100,
  filterable:false

},
{
  // Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
  //              data-placement = "top" title = "INTAKE COUNT">COURSE</span>,
  Header: "Intake Period",
  accessor: "intake",
  Cell: renderEditable,
  sortable:false,
  width:180,
  minWidth:100,
  filterable:false

},
{
  Header:"Intake Count",
  accessor: "intake_count",
  minWidth:100,
  width:180,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header:"Intake Unit",
  accessor: "intake_unit",
  minWidth:100,
  width:180,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header: "Dosage Form",
  accessor: "form",
  minWidth:120,
  width:150,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
// {
  // Header: "Intake Time",
  // accessor: "intake_time",
  // minWidth:120,
  // width:150,
  // Cell: renderIntakeCount,
  // sortable:false,
  // filterable:false

// },
// {
//   Header: "TO",
//   accessor: "to",
//   minWidth:80,
//   width:120,
//   Cell: renderEditable,
//   sortable:false,
//   filterable:false

// },
{
  Header: "Frequency",
  accessor: "frequency",
  minWidth:140,
  width:140,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header: "Route",
  accessor: "route",
  minWidth:80,
  width:130,
  Cell: renderEditable,
  sortable:false,
  filterable:false

},
{
  Header: "Duration",
  accessor: "duration",
  minWidth:80,
  width:130,
  Cell: renderEditableDuration,
  sortable:false,
  filterable:false

},
{
  Header: "Prescribed by",
  accessor: "creator",
  Cell: renderEditableCr,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:100,
   sortable:false,
   filterable:false,
   width:200

},

{
  Header: "Remarks",
  accessor: "remarks",
  Cell: renderRemarks,
  sortable:false,
  width:120,
  minWidth:70,
  filterable:false

},
{
  Header: "",
  accessor: "edit",
  Cell: renderEdit,
  sortable:false,
  width:50,
  minWidth:100,
  filterable:false

},
]





const handleDeleteMed=()=>{


  const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '');
    

const dataToSend={
  is_deleted:"True"
}
  
    axios.put(`${APIURL}/api/v1/doctor/patient-prescriptions-detail/${medList[medIndexToDelete].id}/`, dataToSend,{ headers: {
      "Content-Type": "application/json",
      
      Authorization : 'Token '+str,
  }


    })



    .then(res=>{

       if(res.status==200){
   
    alert.success("Deleted successfully")
    setMedIndexToDelete(null)
    setRefresh(!refresh)
   }
 else{
     setSubmitMsg("error");
    
   
   }

    })
    .catch(err=>{
  
  setSubmitMsg("error");

})


 }

const DeleteMedConfirmPopup =(props)=>{
    

  return (
    <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    keyboard={false}
>
    <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
    </Modal.Header>
    <Modal.Body>
                    <h4 id="confirm-reject">Please confirm to delete Current Medicine</h4>
                </Modal.Body>
      
      <Modal.Footer>
                   

                   <Button
                       variant="danger"
                       onClick={()=>{
    
                         setDeleteMedConfirmModalShow(false);
                           handleDeleteMed();
                           }}
                   >
                       {" "}
                       Confirm
                   </Button>
               </Modal.Footer>
    </Modal>
  );
}

  return(
    <div className="overlay-body">


    <div className="add-presc-page">
   

<div className="current-table-holder">
    <ReactTable 
         columns={columns}
         data={medList}
         defaultPageSize = {5}
         filterable
         resizable
         noDataText={"No data"}
         className="observation-table"
         >

         </ReactTable>
         <br/>
       
  </div>
       
          
        
        
      </div>
     

{deleteMedConfirmMsg==="success" ? 

<DeleteMedConfirmPopup
show={deleteMedConfirmModalShow}
onHide={() =>  setDeleteMedConfirmModalShow(false)}
index= {medIndexToDelete}
/>

 : 
   '' } 
{
    editShow ? 
      <EditPopup
        show={editShow}
        onHide= {() => { setEditShow(false)     
        }}
      /> : null
    }
{
      viewShow ? 
      <ViewPopup
        show={viewShow}
        onHide= {() => { setViewShow(false)     
        }}
      /> : null
    }
  
</div>    
)
}

export default CurrentMedications;