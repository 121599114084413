import React, { useState, useEffect, useRef } from 'react'
import './therapy.css'
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";
import AddTherapy from './AddTherapy';
import ShowHistory from './ShowHistory';
import "simplebar-react/dist/simplebar.min.css";
import SimpleBar from "simplebar-react";
import moment from 'moment';
import { useAlert } from 'react-alert';
import { Flex } from 'antd';
function Therapy(id) {
    const [data, setData] = useState('')
    const [selected, setSelected] = useState("current")
    const [show, setShow] = useState(false)
    const userData = JSON.parse(sessionStorage.getItem("logindatas"));
    const name = userData.name;
    const alert = useAlert();
    const today = moment(new Date()).format('YYYY-MM-DD')
    useEffect(() => {
        therapy()
    }, [])

    const therapy = async () => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");


        const date = moment(new Date()).format('YYYY-MM-DD')
        const patient_id = id.id


        try {
            const currentRes = await axios.get(`${APIURL}/api/v1/patient/therapy-consultation/?current=True&date=${date}&patient_id=${patient_id}`, {
                headers: {
                    Authorization: "Token " + str,
                    "Content-Type": "application/json",
                },
            });

            if (currentRes.data.status === "success") {
                setData(currentRes.data.message);
            } else {
                setData('')
            }

        } catch (err) {
            alert.error('An error occurred while fetching therapy consultation data.');
        }
    };

    const handleChangeMode = (e) => {
        const { value } = e.target;
        setData((currentState) => ({
            ...currentState,
            therapy_requirement: value
        }));
    };

    const handleOnChange = (e) => {
        const value = e.target.value;
        const key = e.target.name
        setData((currentstate) => ({
            ...currentstate,
            [key]: value,
        }))
    };

    const handleUpdate = async () => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const id = data.id
        const payload = {
            therapy_unit: data.therapy_unit,
            therapy_requirement: data.therapy_requirement,
            therapy_type: data.therapy_type,
            therapy_status: true
        };

        try {
            const currentRes = await axios.put(`${APIURL}/api/v1/patient/therapy-consultation/?therapy_id=${id}`, payload, {
                headers: {
                    Authorization: "Token " + str,
                    "Content-Type": "application/json",
                }
            });

            if (currentRes.data.status === "success") {
                alert.success(currentRes.data.message);
                therapy()
            } else {
                alert.error(currentRes.data.message);
            }

        } catch (err) {
            alert.error('An error occurred while fetching therapy consultation data.');
        }
    }
    const onClose = () => {
        setShow(false);
    };

    return (
        <div className='therapy-container'>
            {(selected === "current" && data !== '') ?
                <>
                    <div className='therapy-head-container' style={{ justifyContent: 'space-between' }}>
                        <label><b>Upcoming Consultation:</b></label>
                        <div style={{ display: 'flex' }}>
                            <button onClick={() => setSelected("addnew")}>Add New +</button>
                            <button onClick={() => setShow(true)}>History</button>
                        </div>
                    </div >
                    <div className='therapy-body-container'>
                        <div className='therapy-input-container'>
                            <div className='therapyinput-container col-6'>
                                <input className='therapy-input'
                                    style={{ textTransform: 'capitalize' }}
                                    name="doctor_name"
                                    placeholder='Name'
                                    value={name && name}
                                    disabled
                                />
                            </div>
                            <div className='therapyinput-container col-6'>
                                <input className='therapy-input'
                                    type="date"
                                    name="date"
                                    value={data.date}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='therapy-input-container'>
                            <div className='therapyinput-container col-4'>
                                <select className='therapy-input'
                                    name="therapyType"
                                    value={data.therapy_requirement}
                                    onChange={handleChangeMode}
                                    disabled={data.date !== today}
                                >
                                    <option value="">Select Type</option>
                                    <option value="movement_exercise">Movement and Exercise</option>
                                    <option value="manual_therapy">Manual Therapy</option>
                                    <option value="ultrasound">Ultrasound</option>
                                    <option value="dry_needling">Dry Needling</option>
                                    <option value="traction">Traction</option>
                                    <option value="hydrotherapy">Hydrotherapy</option>
                                </select>
                            </div><div className='therapyinput-container col-4'>
                                <input className='therapy-input'
                                    type="text"
                                    name="therapy_type"
                                    value={data.therapy_type !== 'pending' ? data.therapy_type : ''}
                                    placeholder='Requirement mode of therapy'
                                    onChange={handleOnChange}
                                    disabled={data.date !== today}
                                />
                            </div><div className='therapyinput-container col-4'>
                                <input className='therapy-input'
                                    type="text"
                                    name="therapy_unit"
                                    value={data.therapy_unit}
                                    placeholder='Unit number'
                                    onChange={handleOnChange}
                                    disabled={data.date !== today}
                                />
                            </div>
                        </div>
                        <div><button disabled={data.date !== today} onClick={() => handleUpdate()} className='therapy-button'>Update</button></div>
                    </div>
                </> : selected === 'addnew' ?
                    <AddTherapy selected={selected} setSelected={setSelected} patientId={id.id} name={name} />
                    : (selected === 'current' && data === '') ? <label className='nodata' style={{ marginTop: '14.5%' }}>No Appoinment scheduled!!</label> : ''
            }
            {show && (
                <ShowHistory patientId={id.id} onHide={onClose} />
            )}
        </div>
    )
}

export default Therapy
