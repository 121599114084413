import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "../BDO/addbdo.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "../BDO/dob.css";
import Select from 'react-select';
import DatePicker from "react-date-picker";
import { APIURL } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import BackButton from "../common/BackButton";
import { useAlert } from "react-alert";

function AddFloor(props) {
  
  const [visitorDatas, setVisitorDatas] = useState({
    floorname: "",
    floornumber: "",
    wardcapacity:"",
    roomcapacity:""
  
   
  })

  const [error, setError] = useState({
   
  });


  const alert =useAlert()
  const [errorMsg, setErrorMsg] = useState("");


  const [submitMsg, setSubmitMsg] = useState("");
  const [validateRawPhoneMsg, setValidateRawPhoneMsg] = useState("");


  const [errorShow, setErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  //const history = useHistory();

  const handleSubmit = () => {
    
    
   
      
      const tokenStr = sessionStorage.getItem("usertoken");

      if (tokenStr) {
        const v = tokenStr;
        let v2 = v.replace(/["]+/g, "");

        const dataToSend = {
            floor_name: visitorDatas.floorname ,
            floor_number:visitorDatas.floornumber,
            building:props.data,
            room_capacity:visitorDatas.roomcapacity!==""?visitorDatas.roomcapacity:0,
            ward_capacity:visitorDatas.wardcapacity!==""?visitorDatas.wardcapacity:0
         }
  axios
          .post(`${APIURL}/api/v1/service-provider/floor/`, dataToSend, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + v2,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              alert.success('Added successfully')
              props.handle()
             
            } else {
              alert.error(res.data.message ? res.data.message : "Error in submission");
            }
          })
          .catch((err) => {
            alert.error("Error in submission");
          });
      }
  
  };




  const handleContinue = async (e) => {
    
    
    e.preventDefault();

    if (validate() ) {
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
if(key==="floorname"){
  setError((currentstate) => ({
    ...currentstate,
    floorname:"",
  }));
}
if(key==="floornumber"){
  setError((currentstate) => ({
    ...currentstate,
    floornumber:"",
  }));
}

    setVisitorDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
  };








  const validate = () => {
    let input = visitorDatas;
    let focussed = false;
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["floorname"] || input["floorname"] === "") {
      isValid = false;
      errors["floorname"] = "Enter floor name ";
    }

    if (!input["floornumber"] || input["floornumber"] === "") {
      isValid = false;
      errors["floornumber"] = "Enter floor number";
    }

    // if (!input["wardcapacity"] || input["wardcapacity"] === "") {
    //   isValid = false;
    //   errors["wardcapacity"] = "Enter ward capacity";
    // }
    // if (!input["roomcapacity"] || input["roomcapacity"] === "") {
    //   isValid = false;
    //   errors["roomcapacity"] = "Enter room capacity";
    // }
    if (/^0[0-9]+$/.test(input["floornumber"]) || (input["floornumber"]<1)) {
      isValid = false;
      errors["floornumber"] = "Floor number cannot be lessthan 1 and cannot start with 0";
       }

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };


  return (
    <div className="add-dr-page">
 

      <div className="add-dr-form-holder">

     
        <form className="add-dr-form" onSubmit={handleContinue} style={{width:"80%" , backgroundColor:"rgb(217 214 189)"}}>
          <h4 className="title-of-page"> Add New Floor</h4>
          <div className="add-dr-div">
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Floor Name: </label>
              <input
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=" Floor Name"
                name="floorname"
                onChange={handleChange}
              />
              {error.floorname ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.floorname}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Floor Number: </label>

              <input
                className="form-control"
                type="number"
               
                placeholder=" Floor Number"
                name="floornumber"
                onChange={handleChange}
              />
              {error.floornumber ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.floornumber}
                </div>
              ) : null}
            </div>
      
            <div className="form-group col-lg-6 item-left-row">
              <label className=""> Room Count: </label>

              <input
                className="form-control"
                type="number"
                min="1"
                max="50"
                placeholder="Room Count"
                name="roomcapacity"
                onChange={handleChange}
              />
              {error.roomcapacity ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.roomcapacity}
                </div>
              ) : null}
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className=""> Ward Count: </label>

              <input
                className="form-control"
                type="number"
                min="1"
                max="50"
                placeholder="Ward Count"
                name="wardcapacity"
                onChange={handleChange}
              />
              {error.wardcapacity ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.wardcapacity}
                </div>
              ) : null}
            </div>

            

          

        

            

          
           
          </div>
          <div className="form-button">
          <button type="submit" className="btn btn-primary btn-col">
            {" "}
            Add
          </button>{" "}
          </div>
          <br />
          <br />
          <h6 className="text-muted">All fields marked * are mandatory</h6>
        </form>


        {/************************Unused***************
    {(() => {
      switch(submitMsg){
      case "success":
      return   (
          <div>
            <h5 style={{ color: "green" }}> Data submitted sucessfully !!</h5>{" "}

            


            <br />
            <br />
           
          
            
          </div>
        );

        case "error": 
        return (
          <h5 style={{ color: "red" }}>
            
            Error in data submission.Please check again!
          </h5>
        );
        default: 
        return "";
           }
      })()}

    *************************/}

        {validateRawPhoneMsg === "error" ? (
          <h5 style={{ color: "red" }}>Phone number field is mandatory!</h5>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AddFloor;
