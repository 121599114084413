import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const ConfirmationPopup = ({ message, onConfirm, onCancel }) => {
  const handleConfirm = () => {
    Swal.fire({
      html: `
        <div style="font-size: 14px;">${message}</div>
        <div style="font-size: 14px;">Add Comments:</div>
        <textarea id="swal-input1" class="swal2-input" rows="1" style="width: 360px; height: auto; padding: 5px"></textarea>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      reverseButtons: true,
      confirmButtonColor: "#2b69a3",
      cancelButtonColor: "#dc3545",
      backdrop: "static",
      allowOutsideClick: false,
      preConfirm: () => {
        const comment = Swal.getPopup().querySelector("#swal-input1").value;
        if (!comment) {
          Swal.showValidationMessage(`Please enter a comment`);
        }
        return { comment };
      },
      didOpen: () => {
        const confirmButton = Swal.getConfirmButton();
        const textarea = Swal.getPopup().querySelector("#swal-input1");

        confirmButton.disabled = true; 
        textarea.addEventListener("input", () => {
          confirmButton.disabled = !textarea.value.trim(); 
        });
      }
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm(result.value.comment);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        onCancel();
      }
    });
  };

  useEffect(() => {
    handleConfirm();
  }, []);

  return null;
};

export default ConfirmationPopup;
