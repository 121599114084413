import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, NavLink,useLocation  } from "react-router-dom";
import { APIURL } from "../../Global";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Modal,Button } from "react-bootstrap";
import { Carousel } from 'react-bootstrap';
import AddToCart from './AddToCart';
import { Badge } from 'react-bootstrap';

function DealPromotions1({  }) {

    const [promotions, setPromotions] = React.useState([]);
    const navigate = useNavigate();
    useEffect(() => {
   
        // const tokenString = sessionStorage.getItem("usertoken");
    
        // let v2 = tokenString.replace(
        //   /["]+/g,
        //   ""
        // ); /* REGEX To remove double quotes from tokenstring */
    
        axios
          .get(`${APIURL}/api/v1/patient/list-promotions/?promo_type=deal_promotion`,
          //  {
          //   headers: { Authorization: "Token " + v2 },
          // }
          )
          .then((res) => {
            //console.log(res);
            if (res.status == 200) {
            
                // const dealPromotions = res.data.details.filter(
                //     (promo) => promo.promo_type === "deal_promotion"
                //   );
                  setPromotions(res.data.details);
              //console.log(featureList)
            } else {
              //setLoading(false);
            }
          })
          .catch((err) => {
           // setLoading(false);
          });
        }, []);

const handleShop=(e)=>
{
    e.preventdefault();
    navigate('/dealspage')
}



return(
    <>
      <div style={{ display: "contents",color:"#F0A639" }} className="home-slides owl-carousel owl-theme" >
        {promotions.length>0 ? 
    <OwlCarousel items={1}
                            className="owl-theme"
                            loop
                            nav
                            dots={false}
                            margin={0}
                            autoplay={true}
            navigation={true}
            autoplayTimeout={3000}
                     
                        >

        {promotions.map((promo) => {
  if (promo.promo_display === "dp1") {
    return (

      <a href={`/dealspage?promo_id=${promo.promotion_id}`}>

        <section style={{ 
          backgroundImage: `url(${promo["promo_banner"]})`, 
          backgroundSize: "cover", /* adjust the size of the background image to cover the entire section */
          backgroundPosition: "center", /* center the background image within the section */
          marginTop:"-5%", 
          width:"100%", /* adjust the width of the section element to 100% */
          height:"600px", 
          marginLeft:"-1%" 
        }} className="" >
        <div className="container">
        <div className="row">
       
            <div className="col-lg-6 col-md-12"  key={promo.promotion_id}>
                <div>
                
                    <br/>
                   
                   
                    <div id="timer" className="flex-wrap d-flex justify-content-center">
               
                        {/* <div id="days" className="align-items-center flex-column d-flex justify-content-center"></div>
                        <div id="hours" className="align-items-center flex-column d-flex justify-content-center"></div>
                        <div id="minutes" className="align-items-center flex-column d-flex justify-content-center"></div>
                        <div id="minutes" className="align-items-center flex-column d-flex justify-content-center"></div> */}
                      
                        {/* <div id="seconds" className="align-items-center flex-column d-flex justify-content-center"></div> */}
                    </div>
                  
                    <br/>
                    <br/>
                    <br/>
                    
                       {promo.overlay_text && (
                <p style={{ fontSize: parseInt(promo.overlay_size), color:promo.overlay_colour }}>
                  {promo.overlay_text} 
                </p>
                )} 
                  
                   
                </div>
              
            </div>
          
    </div>
    <a href={`/dealspage?promo_id=${promo.promotion_id}`} className="default-btn" style={{color:"white",marginBottom:"0%",marginLeft:"70%",backgroundColor:"#F0A639",marginTop:"23%"}}><i className="flaticon-trolley" style={{marginTop:"4%"}}></i> Shop Now</a>
      </div>
      </section >
  </a>
    );
  } else {
    return null;
  }
      
})}


</OwlCarousel>
: ""}
      </div>
    </>






)







}
export default DealPromotions1