import React, { useState } from 'react';

import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";

// Create a CommentInput component
 const AddComments = ( props ) => {
 

const[data,setData]=useState('')

  return ( <>
    <textarea
      type="text"
      className="form-group text-area remark-add"
      value={data}
      name="comments"
      onChange={(e) => {
        const trimmedValue = e.target.value.startsWith(' ')
        ? e.target.value.trimLeft()
        : e.target.value;
        setData(trimmedValue);}}
   
      placeholder="Enter your comments here..."
     
    />
    <br/>
    <Button variant="primary" disabled={data===""} onClick={()=>props.onSuccess(data)}>Add</Button></>
  );
};
export default AddComments

// The rest of your ApproveCommentPopUp component and other code...
