import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from "axios";
import { APIURL } from "../../../Global";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
import { ConfirmationContext } from "../../contexts/SubmitConfirmationContext";
import DialysisFormView from './DialysisFormView';
import { Modal } from 'react-bootstrap';
import { useAlert } from 'react-alert';

const PreviewQueue = ({ pending, approved, setPending, setApproved, reload, search, type }) => {
  const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
  const [priorityItems, setPriorityItems] = useState([]);
  const [pendingItems, setPendingItems] = useState([]);
  const [filteredPriorityItems, setFilteredPriorityItems] = useState([]);
  const [filteredPendingItems, setFilteredPendingItems] = useState([]);
  const [approvedCount, setApprovedCount] = useState('');
  const [pendingCount, setPendingCount] = useState('');
  const [patientDetails, setPatientDetails] = useState({});
  const [formShow, setFormShow] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    setPendingItems(pending.map((item, index) => ({ ...item, index: index + 1 })));
  }, [pending]);

  useEffect(() => {
    setPriorityItems(approved.map((item, index) => ({ ...item, index: index + 1 })));
  }, [approved]);

  const filterItems = useCallback(() => {
    if (search === '') {
      setFilteredPendingItems(pendingItems);
      setFilteredPriorityItems(priorityItems);
    } else {
      const searchLower = search.toLowerCase();
      setFilteredPendingItems(pendingItems.filter(item =>
        item.full_name.toLowerCase().includes(searchLower) ||
        item.id.toString().includes(searchLower)
      ));
      setFilteredPriorityItems(priorityItems.filter(item =>
        item.full_name.toLowerCase().includes(searchLower) ||
        item.id.toString().includes(searchLower)
      ));
    }
  }, [search, pendingItems, priorityItems]);

  useEffect(() => {
    filterItems();
  }, [search, pendingItems, priorityItems, filterItems]);

  useEffect(() => {
    const fetchQueueCounts = async (endpoint) => {
      const tokenString = sessionStorage.getItem("usertoken");
      if (tokenString) {
        let str = tokenString.replace(/["]+/g, "");
        try {
          const res = await axios.get(`${APIURL}/api/v1/service-provider/${endpoint}/?count=true`, {
            headers: {
              Authorization: "Token " + str,
            },
          });
          if (res.status === 200) {
            const approvedcount = res.data.approved_count;
            const pendingcount = res.data.pending_count;

            setApprovedCount(approvedcount);
            setPendingCount(pendingcount);
          }
        } catch (err) {
          console.error("Error fetching counts:", err);
        }
      }
    };

    if (type === "dialysis") {
      fetchQueueCounts('dialysis-queue');
    } else if (type === 'palliative') {
      fetchQueueCounts('palliative-queue')
    } else {
      fetchQueueCounts('physio-queue');
    }
  }, []);

  const FormPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ marginLeft: "auto" }}>Application Details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "left" }}>
          <DialysisFormView content={patientDetails} />
        </Modal.Body>
      </Modal>
    );
  };

  const onDragEnd = useCallback((result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    let updatedPriorityItems = [...priorityItems];
    let updatedPendingItems = [...pendingItems];

    const moveItem = (sourceList, destinationList) => {
      const [removed] = sourceList.splice(source.index, 1);
      destinationList.splice(destination.index, 0, removed);
    };

    if (source.droppableId === 'priorityQueue' && destination.droppableId === 'priorityQueue') {
      moveItem(updatedPriorityItems, updatedPriorityItems);
      setPriorityItems(updatedPriorityItems.map((item, index) => ({ ...item, index: index + 1 })));
      setApproved(updatedPriorityItems); // Update approved state
    } else if (source.droppableId === 'pendingQueue' && destination.droppableId === 'pendingQueue') {
      moveItem(updatedPendingItems, updatedPendingItems);
      setPendingItems(updatedPendingItems.map((item, index) => ({ ...item, index: index + 1 })));
      setPending(updatedPendingItems); // Update pending state
    } else if (source.droppableId === 'priorityQueue' && destination.droppableId === 'pendingQueue') {
      moveItem(updatedPriorityItems, updatedPendingItems);
      setPriorityItems(updatedPriorityItems.map((item, index) => ({ ...item, index: index + 1 })));
      setPendingItems(updatedPendingItems.map((item, index) => ({ ...item, index: index + 1 })));
      setApproved(updatedPriorityItems); // Update approved state with priorityItems
      setPending(updatedPendingItems); // Update pending state with pendingItems
    } else if (source.droppableId === 'pendingQueue' && destination.droppableId === 'priorityQueue') {
      moveItem(updatedPendingItems, updatedPriorityItems);
      setPendingItems(updatedPendingItems.map((item, index) => ({ ...item, index: index + 1 })));
      setPriorityItems(updatedPriorityItems.map((item, index) => ({ ...item, index: index + 1 })));
      setApproved(updatedPriorityItems); // Update approved state with priorityItems
      setPending(updatedPendingItems); // Update pending state with pendingItems
    }

    filterItems(); // Update filtered items

  }, [priorityItems, pendingItems, filterItems, setApproved, setPending, setPendingItems, setPriorityItems]);


  const togglePopup = (item) => {
    setPatientDetails(item);
    setFormShow(!formShow);
  };

  const confirmSubmit = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    handleSubmit();
  };

  const handleCancel = () => {
    closeConfirmationPopup();
  };

  const handleSubmit = () => {
    const startingPriority = approvedCount + 1;
    const startingPending = pendingCount + 1;

    const approvedList = priorityItems.map((item, index) => ({
      [type === 'dialysis' ? 'de_number' : type === 'physio' ? 'phe_number' : 'pape_number']: type === 'dialysis' ? item.de_number : type === 'physio' ? item.phe_number : item.pape_number,
      priority: startingPriority + index,
      eligibility: 'approved',
    }));

    const pendingList = pendingItems.map((item, index) => ({
      [type === 'dialysis' ? 'de_number' : type === 'physio' ? 'phe_number' : 'pape_number']: type === 'dialysis' ? item.de_number : type === 'physio' ? item.phe_number : item.pape_number,
      priority: startingPending + index,
      eligibility: 'pending',
    }));

    const result = {
      approved_list: [...approvedList, ...pendingList],
    };

    const tokenString = sessionStorage.getItem('usertoken');
    if (tokenString) {
      let str = tokenString.replace(/["]+/g, '');
      const data = JSON.stringify(result, null, 2);
      const endpoint = type === 'dialysis' ? 'service-provider/dialysis-queue/' : type === 'palliative' ? 'service-provider/palliative-queue/' : 'service-provider/physio-queue/';
      axios
        .post(`${APIURL}/api/v1/${endpoint}`, data, {
          headers: {
            "Authorization": 'Token ' + str,
            "Content-Type": "application/json"
          }
        })
        .then(res => {
          if (res.data.status === "success") {
            alert.success("Updated successfully");
            setPending([]);
            setApproved([]);
            reload();
          } else {
            alert.error(res.data.message);
          }
        })
        .catch(err => {
          console.error("Error submitting data:", err);
          alert.error("An error occurred while submitting the data.");
        });
    }
  };

  return (
    <div className='queue-container2'>
      <div className='row queue-head'>
        <div className='col-6 queue-head1'>Approved Queue</div>
        <div className='col-6 queue-head2'>Pending Queue</div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className='row queue-content-trust'>
          <Droppable droppableId="priorityQueue">
            {(provided) => (
              <div
                className='col-6 queue-content1'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {filteredPriorityItems.length > 0 ? filteredPriorityItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={search !== '' ? item.index - 1 : index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          marginRight: ".6%"
                        }}
                        className="dialysis_priorityqueue"
                      >
                        <div className="col-8 dename-content">
                          {item.index}. {item.full_name && item.full_name}
                        </div>
                        <div className="col-4 eye-content">
                          <div className="row eye-content-div">
                            <div className="col-6 envelop">
                              <span className="fa-stack fa-lg">
                                <i className="fa fa-envelope" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                              </span>
                            </div>
                            <div className="col-6 eye">
                              <i onClick={() => togglePopup(item)} className="fa fa-eye" style={{ fontSize: "18px", margin: "4px 0" }} aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                )) : <div className="nodata" >No requests approved in preview!!</div>}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="pendingQueue">
            {(provided) => (
              <div
                className='col-6 queue-content2'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {filteredPendingItems.length > 0 ? filteredPendingItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={search !== '' ? item.index - 1 : index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          marginLeft: ".6%",
                        }}
                        className="dialysis_pendingqueue"
                      >
                        <div className="col-8 dename-content">
                          {item.index}.  {item.full_name && item.full_name}
                        </div>
                        <div className="col-4 eye-content">
                          <div className="row eye-content-div">
                            <div className="col-6 envelop">
                              <span className="fa-stack fa-lg">
                                <i className="fa fa-envelope" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                              </span>
                            </div>
                            <div className="col-6 eye">
                              <i onClick={() => togglePopup(item)} className="fa fa-eye" style={{ fontSize: "18px", margin: "4px 0" }} aria-hidden="true"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                )) : <div className="nodata" >No requests pending in preview!!</div>}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <div className="row queue-button">
        <button className="submit" onClick={confirmSubmit} disabled={pendingItems.length === 0 && priorityItems.length === 0}><b>Submit</b></button>
      </div>

      {formShow ? (
        <FormPopUp
          show={formShow}
          onHide={() => {
            setFormShow(false);
          }}
        />
      ) : null}

      {showConfirmation && (
        <SubmitConfirmationPopup
          message="Are you sure you want to submit the Queue?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default PreviewQueue;
