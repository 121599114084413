import React,{useState, useContext} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
import { ConfirmationContext } from '../../contexts/SubmitConfirmationContext';

function AddDiscease(props) {

    const[data, setData]= useState({
        diseasename:'',
        diseasetype:'',
        diseasestage:'',
        genericname:'',
        icdcode:''
        

    })
    const alert = useAlert();
    const [error,setError]=useState({
      diseasename:''
        
       });

    const[errorValidate,setErrorValidate]=useState(false)
    const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
      const handleError = () =>
  {
    setErrorValidate(false);
  }
    
     
      const handleTitle = (e)=> {
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
            ...current , [key] : val
        }))
      }


  

  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["diseasename"] || input["diseasename"]==="" ) {
      isValid = false;
      errors["diseasename"] = "Enter disease Name";
    }
    
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}
const handleContinue = () => {
  if(validate()){ 
    setShowConfirmation(true)
  };
}

const handleConfirm = () => {
  saveChanges();
};

const handleCancel = () => {
  closeConfirmationPopup()
};

  const saveChanges = (e)=> {
    // e.preventDefault();
 

        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
        disease_name: data.diseasename.slice(0,1).toUpperCase() +data.diseasename.slice(1, data.diseasename.length),  
        disease_type:data.diseasetype,
        disease_stage:data.diseasestage,
        generic_name:data.genericname,
        icd_code:data.icdcode
        

  }
 
     axios
       .post(`${APIURL}/api/v1/staff/master-primary-diseases/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          alert.success('Saved successfully!');
          props.modalClose();  
          setData({
            diseasename : '',
            diseasetype : '',
            diseasestage : '',
            genericname : '',
            icdcode : ''
         });
         } else {
           alert.error('Check if duplicated')
         }
       })
       .catch((err) => {
        alert.error('Check if duplicated')
       });
}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Add Disease</h4>
        <div style={{minHeight:'fit-content', width:'100%'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='align-rental'>*Disease Name: </label>
                    <input type='text' className='form-control title-flex' name='diseasename' value={data.diseasename} onChange={handleTitle} /></div>
                    {error.diseasename ? <div className="error-validation-msg error-feature-inventory">{error.diseasename}</div> : null}
                </div>

                <div className="formField" style={{display:"flex"}}>
                    <span className='align-rental'>Disease Type: </span>
                    <input type='text' className='form-control title-flex' name='diseasetype' value={data.diseasetype} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Disease Stage: </label>
                    <input type='text' className='form-control title-flex' name='diseasestage' value={data.diseasestage} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'>Disease Generic Name:</label>
                    <input type='text' className='form-control title-flex' name='genericname' value={data.genericname} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" style={{display:"flex"}}>
                    <label className='align-rental'> ICD Code: </label>
                    <input type='text' className='form-control title-flex' name='icdcode' value={data.icdcode} onChange={handleTitle} />
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>

            </div>
          </div>
          <div style={{textAlign:'right'}}>
          <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>Cancel</button>
          <button onClick={handleContinue} className='btn btn-primary btn-col mb-2'>Save</button>   
        </div>
        {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to add the disease?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}
        </>
    )
}

export default AddDiscease
