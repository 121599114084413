import React, { useState, useEffect, useRef } from 'react'

import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import "./inventory.css";
import moment from "moment";
import DatePicker from 'react-date-picker';
function ChangeStatus(props) {
    const alert = useAlert()
    const [data, setData] = useState(props.data)
    const [fileName1, setFileName1] = useState("Upload Acknowledgement");
    const [fileName, setFileName] = useState(!props.data.is_consent_uploaded ? "Upload Consent" : "Consent Uploaded");
    const [change1, setChange1] = useState(false)
    const [change2, setChange2] = useState(false)
    const handleChange = (e) => {
        if(data.status==="collected"){

            setData(current => ({
                ...current, status: "returned"
            }))
        }else{
            setData(current => ({
                    ...current, status: "collected"
                }))}
            
           
       
    }

    const handleTitle = (e) => {
        e.preventDefault();
        const val = trimspace(e.target.value);
        const key = e.target.name;
        setData(current => ({
            ...current, [key]: val
        }))
    }
    const submit = (e) => {
        const tokenString = sessionStorage.getItem('usertoken');
        let fd = new FormData();
        let str = tokenString.replace(/["]+/g, '')
    
        if (data.status === "returned" && data.acknowledgement_form === null) {
            alert.error("Please upload acknowledgement")
        }
        else if (data.status === "returned" && data.returned_on === null) {
            alert.error("Enter return date")
        } else if (data.status === "returned" && data.returned_to === null) {
            alert.error("Enter return details")
        }
        else {
            fd.append("status", data.status);
            if (change1 === true) { fd.append("consent_form", data.consent_form); fd.append("is_consent_uploaded", true); }
            fd.append("returned_to", data.returned_to !== null ? data.returned_to : "")
            if (change2 === true) { fd.append("acknowledgement_form", data.acknowledgement_form); }
            fd.append("returned_on", data.returned_on !== null ? moment(data.returned_on).format("YYYY-MM-DDTHH:mm:ss") : "")
            axios.put(`${APIURL}/api/v1/service-provider/pers-inventory-det/${data.id}/ `, fd, {
                headers: {

                    "Authorization": 'Token ' + str
                }
            })
                .then(res => {


                    if (res.data.status === "success") {
                        setChange1(false)
                        setChange2(false)
                        alert.success("successfully Updated")
                        props.onSuccess()


                    } else {
                        alert.error("error in submission")
                    }

                })
                .catch(err => {


                })
        }
    }

    const reader = new FileReader();
    const uploadImage = async (e) => {
        const key = e.target.name;
        const file = e.target.files[0];
        const imageUrl = URL.createObjectURL(file);


        if (!file) {
            alert.error("Please Select an Image")
            if (key === "image") {
                setFileName1('Upload Acknowledgement')
                setChange2(false)
            }
            else {
                setFileName('Upload Consent form');
                setChange1(false)
            }
            return false;
        }
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
            alert.error("Select valid image")
            if (key === "image") {
                setFileName1('Upload Acknowledgement')
                setChange2(false)
            }
            else {
                setFileName('Upload Consent form');
                setChange1(false)
            }
            return false;
        }
        if (file.size > 10e6) {
            alert.error("Select valid image")
            return false;
        }

          
           
                if (key === "image") {
                    setFileName1(file.name)
                    setData((currentData) => ({
                        ...currentData,
                        acknowledgement_form: file,
                        imageUrl1: imageUrl
                    }));
                    setChange2(true)
                }
                else {
                    setFileName(file.name);
                    setData((currentData) => ({
                        ...currentData,
                        consent_form: file,
                        imageUrl: imageUrl,
                    }));
                    setChange1(true)
                }

        reader.readAsDataURL(file);


    };

    const trimspace=(val)=>{
    
        return  val.startsWith(' ')
        ? val.trimLeft()
        : val;
      }


    const handleDate = (date) => {

     
            setData(currentstate => ({
                ...currentstate,
                returned_on: date
            }));
       
    }

    return (
        <div className='add-inventory ' style={{ backgroundColor: "white", border: "1px solid black" }} >

            <div className="">


                <div className="" >
                   

                    <div className='' style={{textAlign:"center",border:"none"}}>
                                <span style={{ fontSize: "17px", color:data.status === "collected" ? "#2196F3":"gray",marginTop:"55%"  }} className="text-style-here"><b>Accepted</b></span>
                                &nbsp; &nbsp; &nbsp; &nbsp;
                                <label class="switch">
                                    <input style={{width:"1%"}} type="checkbox" onChange={handleChange} />
                                    <span class="slider round"></span>
                                </label>
                                &nbsp; &nbsp; &nbsp; &nbsp;
                                <span style={{ fontSize: "17px",color:data.status === "returned"? "#2196F3":"gray", marginTop:"5%" }} className="text-style-here"><b>Returned</b></span>
                            </div>
                    
                    
                  



                </div><br/>
                <div className={data.status === "returned"?"":""} >
                    <span className="text-style-here">{data.status === "collected" ? "Date Recieved" : "Return Date"}</span>

                    {data.status === "collected" ? <input
                        disabled

                        type="text"
                        className="enquiryinput"
                        placeholder=" "
                        value={data.deposited_at.split('T')[0]}
                        onChange={handleTitle}
                        style={{ width: "98%", fontSize: "15px", }}
                    />
                        : <DatePicker
                            className="enquiryinput"
                            onChange={date => { handleDate(date) }}
                            style={{ width: "80%", border: "none" }}
                            maxDate={new Date()}
                            minDate={new Date(data.deposited_at)}
                            value={data.returned_on}
                             />}



                </div><br/>
                <div className={data.status === "returned"?"":""} >
                    <span className="text-style-here">{data.status === "collected" ? "Recieved By" : "Return To"}</span>

                    <input
                        disabled={data.status === "collected"}
                        name={data.status === "collected" ? "deposited_by" : "returned_to"}
                        type="text"
                        className="enquiryinput"
                        placeholder=" "
                        value={data.status === "collected" ? data.deposited_by : data.returned_to === null ? "" : data.returned_to}
                        onChange={handleTitle}
                        style={{ width: "98%", fontSize: "15px", }}
                    />
                </div><br/>
                {data.status === "returned" ? <div style={{width:"100%"}} className="custom-file ">

                    <input type="file" name="image" className="custom-file-input" id={"inputGroupFile02"} onChange={uploadImage} />
                    <label style={{ border: "1px dotted black", marginTop: "6%",width:"100%" }} className="custom-file-label" htmlFor="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{fileName1} </label>
                </div> :""}
            </div><br />
            {(data.status === "collected" && change1) || (data.status === "returned" && change2) ?<><br/> <div className="flex-row" >
                <button onClick={() => { submit() }} className='inv-button '>Submit</button>
            </div></> : ""}<br/>



        </div>
    )
}
export default ChangeStatus