import React,{useState, useContext} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import './profile.css'
import { RegionDropdown } from 'react-country-region-selector';
import { useAlert } from 'react-alert';


function CountryUpdate(props) {
    const [state ,setState] =useState(props.state !== " " ? props.state : " ");
    const alert = useAlert()
    const [error,setError]=useState({
        state:''
       });

    const selectRegion=(val)=>{
        setState(val)
        }

  const  validate = () => {
      //let input = email;
      let errors = {};
      let isValid = true;

      if (state==="") {
        isValid = false;
        errors["state"] = "Select a state";
      }
  
      setError(currentstate=>({
        ...currentstate,...errors
      }))
  
      return isValid;
  }

  const handleContinue =async(e) => {
    e.preventDefault();

    if( validate() ){
      submitRegion()
    }
  }

    const submitRegion = (e) =>{
        const tokenString= sessionStorage.getItem('usertoken');
         
         let str= tokenString.replace(/["]+/g, '') 
      
         const data = {
           state : state
         }
      
         axios.put(`${APIURL}/api/v1/account/profile/` ,data, { headers: {
      
          "Authorization" : 'Token '+str  }})
        .then(res=>{
        
              
          if(res.data.status==="success"){

            alert.success("Updated Successfully")    
            props.handle()    
        
          }else{
            alert.error("Error in Data Submission. Please try again!")
          }
        
        })
        .catch(err=>{
          alert.error("Error in Data Submission. Please try again!")

        
        })
      
      }
      return (
        <div className='flex-phone'>

            <div className="region-selector item-left-row">
                    <RegionDropdown
                        country={props.country}
                        value={state}
                        defaultOptionLabel={'Select State'}
                        onChange={(val) => selectRegion(val)} />
                {error.region ? <div className="error-validation-msg"> {error.state}</div> : null}
                {/* <small className="form-text text-muted">Enabled once you select State</small> */}
            </div>
            <div className="form-button">
                <button style={{marginLeft:'10px'}} onClick={handleContinue} disabled={!state} className="btn btn-primary btn-col" >Update State</button>
            </div>           
        </div>
    )
}

export default CountryUpdate
