import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-date-picker";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import Keyboard from "react-simple-keyboard";
import { Button } from "react-bootstrap";
import moment from "moment";
import "react-simple-keyboard/build/css/index.css";
import "./enquiry.css";
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
import { ConfirmationContext } from '../../contexts/SubmitConfirmationContext';
function PrimaryEnquiry() {
  const [pedate,setPeDate]=useState("")
  const [eqnum,setEqnum]=useState("")
  let navigate = useNavigate();
  const [previewOn, setPreviewOn] = useState(false);
  const [identityNumber,setIdentityNumber]=useState("");
  const[identityCard,setIdentityCard]=useState("")
  const [isValidIdentity, setIsValidIdentity] = useState(true);
  const [validShow, setValidShow] = useState(false);
  const [isValidEq, setIsValidEq] = useState(true);
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
  const [isIdentityNumberDisabled, setIdentityNumberDisabled] = useState(false);
  const [, setFormattedDob] = useState("");
  const [isRefData1,set]=useState(false)
  const [isRefData2,setIsRefData2]=useState(false)
  const [referrer1, setReferrer1] = useState({
    name: "",
    contact: "",
    comments: "",
  });
  const location = useLocation();
  const pat_id = location.state ? location.state.itemId : "";
  const [referrer2, setReferrer2] = useState({
    name: "",
    contact: "",
    comments: "",
  });
  const [allreferrer, setAllReferrer] = useState([]);

  const [alertShow, setAlertShow] = useState(false);
  const [pvShow, setPVShow] = useState(false);
  const [invalidPic, setInvalidPic] = useState(null);
  const [dob, setDob] = useState();
  const alert = useAlert();
  const [fileName, setFileName] = useState("Primary Form");
  const [img, setImg] = useState("");
  const [uploadShow, setUploadShow] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [userList, setUserList] = useState([]);
  const [useMalayalamInput, setUseMalayalamInput] = useState(false); // Initial state is set to false
  const [useMalayalamInput1, setUseMalayalamInput1] = useState(false); // Initial state is set to false
  const [keyboardLayout, setKeyboardLayout] = useState("english"); // Default layout
  const [userDatas, setUserDatas] = useState({
    id: "",
    firstname: "",
    lastname: "",
    email: "",

    enquiry: "",
    gender: "",
    dob: "",
    age: "",
    country: "India",
    state: "",
    phone: {
      dialcode: "",
      rawphone: "",
      mobile_number: "",
    },
    phone1: {
      dialcode1: "",
      rawphone1: "",
      mobile_number1: "",
    },
    identitycard: { value: "", label: "" },
    pincode: "",
    identitynumber: "",
    idcard: "",
    province: "",
    city: "",
    img_url: "",
    chairman_status: "",
    location: "",
    reference: "",
    report: "",
    recommend: "",
  });
  const handleCheckExistingeqno = (e) => {
    setIsValidEq(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        enquiry_number: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        pv_enquiry_number: e.target.value,
       
      };

      axios
        .post(`${APIURL}/api/v1/account/check-id/ `, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "error") {
              setError((currentstate) => ({
                ...currentstate,
                enquiry_number:  res.data.message,
              }));
              setIsValidEq(false);
            } else {
              setIsValidEq(true);
            }
          } else {
            setIsValidEq(true);
          }
        })
        .catch((err) => {});
    }
    else if (e.target.value === "") {
      setError((currentstate) => ({
        ...currentstate,
        enquiry_number: "",
      }));
  }
  };
  const handleReferrer1Change = (e) => {
    const value = e.target.value;
    const key=e.target.name
    if(key==="name"&&value===""){
      setReferrer1((currentstate) => ({
        ...currentstate,
        name: "",
        contact:"91",
        comments:""
      }))
    }
    setReferrer1((currentstate) => ({
      ...currentstate,
      [key]: value,
      
    }))
  };

  const handleReferrer2Change = (e) => {
    const value = e.target.value;
    const key=e.target.name
    if(key==="name"&&value===""){
      setReferrer2((currentstate) => ({
        ...currentstate,
        name: "",
        contact:"91",
        comments:""
      }))
    }
    setReferrer2((currentstate) => ({
      ...currentstate,
      [key]: value,
      
    }))
  };

  const [pvId, setPvId] = useState("");
  const [error, setError] = useState({
    username: "",
    //email: '',
    phone: "",
  });
  const handleCheckExistingPhone = (e) => {
    setIsValidPhone(true);
    if (e.target.value !== "") {
      const rawPhone = userDatas.phone.rawphone;

      const dialcode = userDatas.phone.dialcode;
      setError((currentstate) => ({
        ...currentstate,
        phone: "",
      }));

      const data = {
        code: dialcode,
        mobile_number: rawPhone,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                phone: "Phone Number already registered!",
              }));
              setIsValidPhone(false);
            } else {
              setIsValidPhone(true);
            }
          } else {
            setIsValidPhone(true);
          }
        })
        .catch((err) => {});
    }
  };

  // const newReferrer = {
  //   refername: referrer.refername,
  //   refercontact: referrer.refercontact,
  //   comment: referrer.comment,
  // };

  // // Create a new referrer1 object by combining values from refername1, refercontact1, and comment1
  // const newReferrer1 = {
  //   refername: referrer.refername1,
  //   refercontact: referrer.refercontact1,
  //   comment: referrer.comment1,
  // };

  const handleCheckExistingIdentity = (e) => {
    setIsValidIdentity(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        identitynumber: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        idcard_no: e.target.value,
        idcard_type: userDatas.identitycard.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-id/ `, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "error") {
              setError((currentstate) => ({
                ...currentstate,
                identitynumber: `This identity number is already registered!`,
              }));
              setIsValidIdentity(false);
            } else {
              setIsValidIdentity(true);
            }
          } else {
            setIsValidIdentity(true);
          }
        })
        .catch((err) => {});
    }
    else if (e.target.value === "") {
        setError((currentstate) => ({
          ...currentstate,
          identitynumber: "",
        }));
    }
  };

  const IdentityChange = (data) => {
    setUserDatas((current) => ({
      ...current,
      identitycard: data,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleCheckExisting = (e) => {
    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        email: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        email: e.target.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                email: `This ${key} is already registered!`,
              }));
              setIsValidEmail(false);
            } else {
              setIsValidEmail(true);
            }
          } else {
            setIsValidEmail(true);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {

   
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    axios

      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_enquiry_number=${pat_id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setEqnum(res.data[0].pv_enquiry_number)
          setPeDate( res.data[0].pe_date !== null ? new Date(res.data[0].pe_date) : "")
          const dob1 =
            res.data[0].dob !== null ? new Date(res.data[0].dob) : null;
            setDob(dob1)  
          setUserDatas({
            id: res.data[0].id,
            pe_date:res.data[0].pe_date,
            firstname: res.data[0].first_name,
            lastname: res.data[0].last_name!==null?res.data[0].last_name:"",
            email: res.data[0].email !== null ? res.data[0].email : "",
pv_enquiry_number:res.data[0].pv_enquiry_number!==null?res.data[0].pv_enquiry_number:"",
            enquiry: "",
            gender: res.data[0].gender,
            dob: dob1 !== null ? dob1 : "",
            age:dob1!==null?(new Date().getFullYear() -new Date(dob1).getFullYear()):"",
            country: "India",
            state: res.data[0].state===null?"":res.data[0].state,
            phone: {
              dialcode:
                res.data[0].country_code !== null
                  ? res.data[0].country_code
                  : "91",
              rawphone: "",
              mobile_number:
              res.data[0].country_code !== null &&
              res.data[0].mobile_number !== null
                ? res.data[0].mobile_number.startsWith(res.data[0].country_code)
                  ? res.data[0].mobile_number
                  : res.data[0].country_code + res.data[0].mobile_number
                : // Check if mobile_number doesn't start with "91" (country code)
                  res.data[0].mobile_number !== null &&
                  !res.data[0].mobile_number.startsWith("91")
                  ? "91" + res.data[0].mobile_number
                  : // Handle the case where mobile_number is null
                  res.data[0].mobile_number !== null
                  ? res.data[0].mobile_number
                  : "91", 
            },
            phone1: {
              dialcode1: 91,
              rawphone1: "",
              mobile_number1: "",
            },
            identitycard: {
              value: res.data[0].idcard_type,
              label: res.data[0].idcard_type,
            },
            pincode: res.data[0].pincode===null?"":res.data[0].pincode,
            identitynumber: res.data[0].idcard_no,
            idcard: "",
            province: res.data[0].province!==null?res.data[0].province:"",
            city: res.data[0].city!==null?res.data[0].city:"",
            img_url: res.data[0].photo,
            chairman_status: res.data[0].chairman_status,
            location: res.data[0].location!==null?res.data[0].location:"",
            reference: "",
            report: "",
            recommend: "",
            pe_report:""
            
          });

          if (
            res.data[0].idcard_type !== null &&
            res.data[0].idcard_no !== ""
          ) {
            setIdentityCard(res.data[0].idcard_type,)
            setIdentityNumber(res.data[0].idcard_no,)
            // Both fields are present, disable the input
            // setIdentityNumberDisabled(true);
          } else {
            // At least one field is missing, enable the input
            // setIdentityNumberDisabled(false);
          }

          setPreviewOn(true);
          setImg(res.data[0].photo);
          // setDob(res.data[0].dob)
          // Parse the JSON array in the referer field
          const refererData = res.data[0].referer;

          // Assuming refererData is an array with two objects
          if (refererData.length === 2 || refererData.length===1 ) {
            // Assign data to referrer1 and referrer2
          
            let arr=[]
            refererData.length>0&&refererData.map((item,i)=>{
              item.name&&item.name!=="null"&&arr.push(item)
            })

            // if(arr[0].name){
            //   setIsRefData1(true)
            // }
            // if(arr[1].name){
            //   setIsRefData2(true)
            // }
           if (arr.length===1){
            setReferrer1({
              name: arr[0].name||arr[0].name!=="null"||arr[0].name!==null ? arr[0].name :"",
              contact: arr[0].contact ? arr[0].contact :"",
              comments: arr[0].comments ? arr[0].comments :"",
            });
           }
           if(arr.length===2){
            setReferrer1({
              name: arr[0].name||arr[0].name!=="null"||arr[0].name!==null ? arr[0].name :"",
              contact: arr[0].contact ? arr[0].contact :"",
              comments: arr[0].comments ? arr[0].comments :"",
            });
            setReferrer2({
              name: arr[1].name||arr[1].name!=="null"||arr[1].name!==null ? arr[1].name :"",
              contact: arr[1].contact ? arr[1].contact :"" ,
              comments: arr[1].comments ? arr[1].comments :"",
            });
           }
           
          }
        }
      });
  }, [pat_id]);

  const AlertPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject" style={{ color: "red" }}>
            This User with same details exist. Do you want to continue?
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setAlertShow(false);
              handleRgisterAnyway();
            }}
          >
            {" "}
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ViewPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="remark-width"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div>
            <div className="flex-col">
              <h4>
                {" "}
                You PV Enquiry id is{" "}
                <h1 style={{ color: "green", fontWeight: "700" }}>
                  {pvId}
                </h1>{" "}
                Please note for future enquiry
              </h4>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  // const handlePhoneInputmember = (value, name, data, event, formattedValue) => {

  //   setReferrer(currentstate => ({
  //     ...currentstate,
  //     refercontact: value

  //   }));

  // }
  // const handlePhoneInputmember1 = (value, name, data, event, formattedValue) => {

  //   setReferrer(currentstate => ({
  //     ...currentstate,
  //     refercontact1: value

  //   }));

  // }

  const handleFile = async (e) => {
    
    const key = e.target.name;
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);
  
    if (!file) {
      alert.error("Please Select a File");
      setFileName("Upload File");
      return;
    }
  
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
      alert.error("Select a valid image or PDF file");
      setFileName("Upload File");
      return;
    }
  
    if (file.size > 10e6) {
      alert.error("Select a file smaller than 10MB");
      setFileName("Upload File");
      return;
    }
   
      const shortFileName = file.name.length > 10 ? file.name.slice(0, 10) + '...' : file.name;
  
      if (file.type.startsWith('image/')) {
        setFileName(shortFileName);
        setUserDatas((currentData) => ({
          ...currentData,
          pe_report: file, // Store the base64 encoded image data
          imageUrl1: imageUrl, // Optional for image preview
        }));
      }  if (file.type === 'application/pdf') {
      // Handle PDF file
      setFileName(file.name);
      setUserDatas((currentData) => ({
        ...currentData,
        pe_report: file,
        imageUrl: imageUrl,
      }));
    
  }
  };
  const identityoptions = [
    {value:'',label:'Select type'},
    { value: "pan", label: "PAN" },
    { value: "passport", label: "PASSPORT" },
    { value: "aadhar", label: "AADHAR" },
    { value: "driving_license", label: "DRIVING LICENCE" },
    { value: "ration_card", label: "RATION CARD" },
    { value: "voter_id", label: "VOTER ID" },
  ];

  const removePicPreview = (e) => {
    e.preventDefault();
    setPreviewOn(false);
    setImg("");
  };

  // const handlemember = e => {

  //   e.preventDefault();
  //   const val = e.target.value;
  //   const key = e.target.name;
  // setReferrer(currentstate => ({
  //       ...currentstate,
  //       [key]: val
  //     }));

  // }

  const handleContinue = async (e) => {
    
    e.preventDefault();
    const isValid = await validate(); 
    if (isValid && isValidEmail) {
      
      if((referrer1.error===undefined||referrer1.error===null||referrer1.error==="")&&(referrer2.error===undefined||referrer2.error===null||referrer2.error==="")&&(error.phone===undefined||error.phone==="")){
        
        setShowConfirmation(true)
      }else{
        
        alert.error("Check the data entered")
      }
    }
  };

  const handleConfirm = () => {
    handleRegister();
  };

  const handleCancel = () => {
    closeConfirmationPopup()
  };

  const handleRegister = () => {
    
    if (userDatas.location !== "" && userDatas.img_url !== "") {
      const tokenString = sessionStorage.getItem("usertoken");
      let reff=[]
     
      if(referrer1.name!=="null"){
        reff.push(referrer1)
      }
     
     
      if(referrer2.name!=="null"){
        reff.push(referrer2)
      }
      
      console.log(reff,"reff")
      let str = tokenString.replace(/["]+/g, "");
      const formData = new FormData();

      formData.append("first_name", userDatas.firstname);

      formData.append("gender", userDatas.gender);

      formData.append("location", userDatas.location);
         
      // if(referrer1.name===""){
      //   formData.append("referer[0]name", null);
      // }
    reff.length>0&&reff.map((item,i)=>{
    formData.append(`referer[${i}]name`, item.name);

        formData.append(`referer[${i}]contact`, item.contact);
        formData.append(`referer[${i}]comments`, item.comments);
    })
     
      

      
      formData.append("dob",(userDatas.dob!==null&&userDatas.dob!=="")? moment(userDatas.dob).format("YYYY-MM-DD"):"");
       
      
       
       
      
      if (userDatas.chairman_status === "rejected") {
        formData.append("chairman_status", "pending");
      }
     
      // if (dob) {
      //   formData.append('dob',dob);
      // }
      if(userDatas.lastname!==null){
        formData.append("last_name", userDatas.lastname);
        }
  
        if(userDatas.email!==null){
          formData.append("email", userDatas.email.trim());
        }
      
      if (userDatas.phone.dialcode) {
        formData.append("country_code", userDatas.phone.dialcode);
      }
      if (userDatas.pe_date) {
        formData.append('pe_date', userDatas.pe_date);
      }
      if (userDatas.phone.rawphone) {
        formData.append("mobile_number", userDatas.phone.rawphone);
      }
      if (userDatas.phone.mobile_number!=="91") {
        formData.append("mobile_number", userDatas.phone.mobile_number);
      }
      if (userDatas.phone.mobile_number==="91") {
        formData.append("mobile_number", "");
      }
      if (userDatas.pe_report) {
        formData.append("pe_report", userDatas.pe_report);
      }
      
      if(userDatas.pincode!==null){
        formData.append("pincode", userDatas.pincode);
      }
      if (userDatas.pv_enquiry_number&&userDatas.pv_enquiry_number.toLowerCase()!==eqnum.toLowerCase()) {
        formData.append('pv_enquiry_number', userDatas.pv_enquiry_number);
      }
      if(userDatas.province!==null){
        formData.append("province", userDatas.province);
        }
      
        if(userDatas.city!==null){
          formData.append("city", userDatas.city);
          }
  
          if(userDatas.state!==null){
            formData.append("state", userDatas.state===null?"":userDatas.state);
            }
      if (userDatas.country) {
        formData.append("country", userDatas.country);
      }
      if(userDatas.identitycard.value!==null){
        formData.append("idcard_type", userDatas.identitycard.value);
      }
      if(userDatas.identitycard.value!==null){
        formData.append("idcard_no", userDatas.identitynumber);
      }
      // if (userDatas.reference) {
      //   formData.append('referer', userDatas.reference);
      // }

      // if (userDatas.recommend) {
      //   formData.append('manager_recommend', userDatas.recommend);
      // }

      if (img !== userDatas.img_url) {
        formData.append("photo", img); // Assuming this is a base64 encoded image
      }

      
        formData.append("pe_report", userDatas.enquiry);
      
      // if (allreferrer.length > 0) {
      //   formData.append("referer", JSON.stringify(allreferrer));
      // }

      axios
        .put(
          `${APIURL}/api/v1/service-provider/complete-enquiry/?pv_enquiry_number=${pat_id}&reopen=pe`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.message === "similar accounts found") {
              setAlertShow(true);
            } else if (res.data.message === "similar identitys found") {
              closeConfirmationPopup()              
              alert.error("Enter valid identity card number");
            } else if (res.data.message === "similar identitys found") {
              closeConfirmationPopup()
              alert.error("Enter valid identity card number");}
            else {
              alert.success(res.data.message);
              setPvId(pat_id);
              setPVShow(true);
              navigate("/admission");

              setAllReferrer([]);
              setUserDatas({
                firstname: "",
                lastname: "",
                email: "",

                enquiry: "",
                gender: "",
                dob: "",
                country: "India",
                state: "",
                phone: {
                  dialcode: 91,
                  rawphone: "",
                  mobile_number: "",
                },
                phone1: {
                  dialcode1: 91,
                  rawphone1: "",
                  mobile_number1: "",
                },
                identitycard: { value: "", label: "" },
                pincode: "",
                identitynumber: "",
                idcard: "",
                province: "",
                city: "",
                img_url: "",

                location: "",
                reference: "",
                report: "",
                recommend: "",
              });
              setDob("");
              setPreviewOn(false);
            }
          }
        });
    } else {
      closeConfirmationPopup()
      alert.error(" enter all mandatory fields");
    }
  };
  const handleChangeidnum = (e) => {
    setError((currentstate) => ({
      ...currentstate,
      identitynumber: "",
    }));
    setIsValidIdentity(true)
   
   
    const x=e.target.value
    const val = x.trim()
    const key = e.target.name;
    
      setUserDatas((currentstate) => ({
        ...currentstate,
        [key]: val,
      }));
      if (
        identityCard !== null &&
        identityNumber !== "" &&
        identityCard === userDatas.identitycard.value &&
        identityNumber === val
      ) {

        setError((currentstate) => ({
          ...currentstate,
          identitynumber: "",
        }));
        setIsValidIdentity(true)

        // No need to call handleCheckExistingIdentity
      } else {
        const data = {
          idcard_no: val,
          idcard_type: userDatas.identitycard.value,
        };
        axios
        .post(`${APIURL}/api/v1/account/check-id/ `, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "error") {
              setError((currentstate) => ({
                ...currentstate,
                identitynumber: `This identity number is already registered!`,
              }));
              setIsValidIdentity(false);
            } else {
              setIsValidIdentity(true);
            }
          } else {
            setIsValidIdentity(true);
          }
        })
        .catch((err) => {});
      }
    }
    const handleChangeenqnum = (e) => {
      setError((currentstate) => ({
        ...currentstate,
        enquiry_number: "",
      }));
      setIsValidEq(true)
      const x=e.target.value
    const val = x.trim()
      const key = e.target.name;
      
        setUserDatas((currentstate) => ({
          ...currentstate,
          [key]: val,
        }));
      if(eqnum.toLowerCase()===(e.target.value).toLowerCase()){
        setError((currentstate) => ({
          ...currentstate,
          enquiry_number: "",
        }));
        setIsValidEq(true)
      }
      else{
      e.preventDefault();
      
      const data = {
        pv_enquiry_number:val
      };

      axios
        .post(`${APIURL}/api/v1/account/check-id/ `, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "error") {
              setError((currentstate) => ({
                ...currentstate,
                enquiry_number: res.data.message,
              }));
              setIsValidEq(false);
            } else {
              setIsValidEq(true);
            }
          } else {
            setIsValidEq(true);
          }
        })
        .catch((err) => { });
    
      }
  };
  
  const handleRgisterAnyway = () => {
    if (userDatas.location !== "" && userDatas.img_url !== "") {
      const tokenString = sessionStorage.getItem("usertoken");
      let reff=[]
     
      if(referrer1.name&&referrer1.name!==null){
        reff.push(referrer1)
      }
     
      if(referrer2.name&&referrer2.name!==null){
        reff.push(referrer2)
      }
      let str = tokenString.replace(/["]+/g, "");
      const formData = new FormData();

      formData.append("first_name", userDatas.firstname);

      formData.append("gender", userDatas.gender);

      formData.append("location", userDatas.location);
      formData.append("save_anyway", true);
      if (userDatas.chairman_status === "rejected") {
        formData.append("chairman_status", "pending");
      }
      if(userDatas.email!==null){
        formData.append("email", userDatas.email.trim());
      }
      if (userDatas.pe_date) {
        formData.append('pe_date', userDatas.pe_date);
      }
        if(userDatas.lastname!==null){
        formData.append("last_name", userDatas.lastname);
        }
      if (userDatas.dob) {
        formData.append("dob", userDatas.dob);
      }
      // if (userDatas.phone.dialcode) {
      // formData.append('country_code', userDatas.phone.dialcode);
      // }
      if (userDatas.phone.rawphone) {
        formData.append("mobile_number", userDatas.phone.rawphone);
      }
      
      if(userDatas.pincode!==null){
        formData.append("pincode", userDatas.pincode);
      }
        if(userDatas.province!==null){
        formData.append("province", userDatas.province);
        }
        if(userDatas.city!==null){
        formData.append("city", userDatas.city);
        }
        if(userDatas.state!==null){
        formData.append("state", userDatas.state===null?"":userDatas.state);
        }
     
        formData.append("country", userDatas.country);
            if (userDatas.identitycard.value) {
        formData.append("idcard_type", userDatas.identitycard.value);
      }
      if (userDatas.identitynumber) {
        formData.append("idcard_no", userDatas.identitynumber);
      }
      // if (userDatas.reference) {
      // formData.append('referer', userDatas.reference);
      // }
      if (userDatas.report) {
        formData.append("field_report", userDatas.report);
      }
      reff.length>0&&reff.map((item,i)=>{
        formData.append(`referer[${i}]name`, item.name);
    
            formData.append(`referer[${i}]contact`, item.contact);
            formData.append(`referer[${i}]comments`, item.comments);
        })
     

      if (img !== userDatas.img_url) {
        formData.append("photo", img); // Assuming this is a base64 encoded image
      }
      formData.append("pe_report", userDatas.enquiry);
      if (userDatas.pv_enquiry_number&&userDatas.pv_enquiry_number!==eqnum) {
        formData.append('pv_enquiry_number', userDatas.pv_enquiry_number);
      }
      axios
        .put(
          `${APIURL}/api/v1/service-provider/complete-enquiry/?pv_enquiry_number=${pat_id}&reopen=pe`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + str,
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            alert.success(res.data.message);
            setPvId(pat_id);
            setPVShow(true);
            navigate("/admission");

            setUserDatas({
              firstname: "",
              lastname: "",
              email: "",

              enquiry: "",
              gender: "",
              dob: "",
              country: "India",
              state: "",
              phone: {
                rawphone: "",
                mobile_number: "",
              },
              phone1: {
                rawphone1: "",
                mobile_number1: "",
              },
              identitycard: { value: "", label: "" },
              pincode: "",
              identitynumber: "",
              idcard: "",
              province: "",
              city: "",
              img_url: "",

              location: "",
              reference: "",
              report: "",
              recommend: "",
            });
            setDob("");
            setPreviewOn(false);
          }
        });
    } else {
      alert.error(" Enter all mandatory fields");
    }
  };

  const validate = async () => {
    
    let input = userDatas;
    let errors = {};
    let isValid = true;
    setError({});
    if(referrer1.name!==""&&referrer1.contact.length!==12){
      isValid = false;
      setReferrer1({
        ...referrer1,
        error: "Contact number must have  10 digits", // Update the input value as entered
       
      });
    }
    if(userDatas.phone.mobile_number.length>2&&userDatas.phone.mobile_number.length<12){
      isValid=false;
      errors["phone"] = " Contact number must have  10 digits";
    }
    if(referrer2.name!==""&&referrer2.contact.length!==12){
      isValid = false;
      setReferrer2({
        ...referrer2,
        error: "Contact number must have  10 digits", // Update the input value as entered
       
      });
    }
    if (!input["firstname"] || input["firstname"] === "") {
      isValid = false;
      errors["firstname"] = " Enter first name";
    }
    if (!input["pv_enquiry_number"] || input["pv_enquiry_number"]==="" ||input["pv_enquiry_number"]===null) {
      isValid = false;
      errors["enquiry_number"] = " Enter enquiry number";
    }
    if (pedate===""||pedate===null ) {
      isValid = false;
      errors["pe_date"] = " Enter primary enquiry date";
    }
    if (isValidIdentity === false) {
      isValid = false;
      errors["identitynumber"] = "Identity Card number already present!";
    }
    if (!input["location"] || input["location"] === "") {
      isValid = false;
      errors["location"] = " Enter location";
    }
    if (!input["img_url"] || input["img_url"] === "") {
      isValid = false;
      errors["img_url"] = " Upload a photo";
    }
    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
    }
    if (input["email"] !== "") { 
      const trimmed_email = input["email"].trim(); 
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(trimmed_email)) {
        isValid = false;
        errors["email"] = "Enter a valid email address.";
      }
    }   
    if (typeof input["firstname"] !== "undefined") {
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern2.test(input["firstname"])) {
        isValid = false;
        errors["firstname"] = "Special Characters are not allowed";
      }
    }
    if (typeof input["lastname"] !== "undefined") {
      var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/);
      if (pattern3.test(input["lastname"])) {
        isValid = false;
        errors["lastname"] = "Special Characters are not allowed";
      }
    }
    if (typeof userDatas.gender == "undefined" || userDatas.gender === "" || userDatas.gender === null ) {
      isValid = false;
      errors["gender"] = "Select gender";
    }
    if(userDatas.identitycard.label!==null && userDatas.identitycard.label!==""){
      if ((userDatas.identitynumber === "" || userDatas.identitynumber === null || userDatas.identitynumber === undefined)) {
      isValid = false;
      errors["identitynumber"] = "Enter identity number";
    }}    
    if(isValidEq===false){ 
      isValid = false
      errors["enquiry_number"]="Similar enquiry number found"
    }
    if (((referrer1.name !== ""&& referrer1.name !== "null"&& (referrer1.contact === ""||referrer1.contact === "91")) || 
        (((referrer2.name !== ""&&referrer2.name !== "null") && (referrer2.contact === ""||referrer2.contact === "91")) ))) {
          isValid = false
          alert.error("referrer name and contact is manadatory")
    } 
      

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));
    
    return isValid;
  };
  const trimspace=(val)=>{
    
    return  val.startsWith(' ')
    ? val.trimLeft()
    : val;
  }

  const reader = new FileReader();
  const uploadImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      setUploadShow(!uploadShow);
      //setInvalidPic('Please Select an Image')
      return false;
    }
    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      setValidShow(!validShow);
      //setInvalidPic('Select valid image')
      return false;
    }
    if (file.size > 10e6) {
      return false;
    }

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        setImg(file);
        setInvalidPic(null);
      };

      img.onerror = () => {
        setPreviewOn(false);

        //setInvalidPic('Invalid Image Content')
        return false;
      };
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);

    const objectURL = URL.createObjectURL(file);

    setUserDatas((currentstate) => ({
      ...currentstate,
      img_url: objectURL,
    }));
    setPreviewOn(true);
  };

  const handleGenderRadioButton = (e) => {
    let genderOpted = e.target.value;
    setUserDatas((currentstate) => ({
      ...currentstate,
      gender: genderOpted,
    }));
  };

  const handlemalayalamInput = (e) => {
    e.preventDefault();
    setUseMalayalamInput(true);
  };

  const handleMalayalamInputToggle = () => {
    setUseMalayalamInput((prevState) => !prevState);
  };
  const handleMalayalamInputToggle1 = () => {
    setUseMalayalamInput1((prevState) => !prevState);
  };

  const handlePhoneInput = (value, data, event, formattedValue) => {
 
      const rawPhone = value.slice(data.dialCode.length);
      const dialcode = value.slice(0, data.dialCode.length);
      const numericValue = rawPhone.replace(/\D/g, ''); // Remove non-numeric characters
      setError({ ...error, phone: null });
      setUserDatas(currentstate => ({
        ...currentstate,
        phone: {
            dialcode: dialcode,
            rawphone: rawPhone,
            mobile_number: value
        }

    }));
     
      if(numericValue.length===0 && dialcode.length===2){
        setError({ ...error, phone: "" });
        setUserDatas(currentstate => ({
          ...currentstate,
          phone: {
              dialcode: dialcode,
              rawphone: "",
              mobile_number: "91"
          }

      }));
      }
      
      else {
        
      }
     

  }
  const handlePhoneInput1 = (value, data, event, formattedValue) => {
    const rawPhone = value.slice(data.dialCode.length);
    const dialcode = value.slice(0, data.dialCode.length);
    setUserDatas((currentstate) => ({
      ...currentstate,
      phone1: {
        dialcode1: dialcode,
        rawphone1: rawPhone,
        mobile_number1: value,
      },
    }));
  };
  const handleDOB = (date) => {
    setDob(date);

    let dateUpdated = date;
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];

      setFormattedDob(DateExtracted);

      const age = new Date().getFullYear() - new Date(DateExtracted).getFullYear();
      setUserDatas(currentstate => ({
          ...currentstate,
          dob: DateExtracted,
          age:age
      }));
    }
    else if (date === null||date === "") {
      setUserDatas(currentstate => ({
        ...currentstate,
        dob: "",
        age:""
    }));
    }
  };
  const handlePEDate= (date) => {
    setPeDate(date)
    let dateUpdated = date;
    if (date !== null) {
        const offset = date.getTimezoneOffset();
        dateUpdated = new Date(date.getTime() - (offset * 60 * 1000))
        let DateExtracted = dateUpdated.toISOString().split('T')[0];
       
        setUserDatas(currentstate => ({
            ...currentstate,
            pe_date: DateExtracted,
            
        }));
    }
    else  if (date === null||date === "") {
      setUserDatas(currentstate => ({
        ...currentstate,
        pe_date: "",
       
    }));
    }
    setError(currentstate=>({
      ...currentstate,
      pe_date: ""
    }))
}
  const handleChangeAge = (event) => {
    const inputAge = parseInt(event.target.value, 10);
    if (!isNaN(inputAge) && inputAge >= 1 && inputAge <= 150) {
   
      // Calculate DOB from age and set it
      const currentDate = new Date();
      const birthYear = currentDate.getFullYear() - inputAge;
      const birthDate = new Date(currentDate);
      birthDate.setFullYear(birthYear);

      handleDOB(birthDate);
      setUserDatas((currentState) => ({
        ...currentState,
        dob: birthDate.toISOString().split('T')[0],
        age:event.target.value
      }));
      setError({ dob: "" });
    }  else if(event.target.value===""||event.target.value===null) {
      setDob(null);
      setUserDatas((currentState) => ({
        ...currentState,
        dob: null,
        age:null
      }));
    }
    else {
      setError({ dob: "Invalid age input" });
    }
  };
  const selectRegion = (val) => {
    // let value1=e.target.value;
    setUserDatas((currentstate) => ({
      ...currentstate,
      state: val,
    }));
  };
  const handleConsentRadioButton = (e) => {
    if (userDatas.phone !== "") {
      let consentOpted = e.target.value;

      setUserDatas((currentstate) => ({
        ...currentstate,
        consent: consentOpted,
      }));
    }
  };
  const handleChange = (e) => {
    setIsValidIdentity(true);
    e.preventDefault();
    const val = trimspace(e.target.value);
    const key = e.target.name;
    setUserDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
  };
  return (
    <div
      className="container"
      style={{ paddingTop: "2%", paddingBottom: "2%" }}
    >
      <div
        className="enquirycontainer"
        style={{ paddingTop: "1%", paddingLeft: "2%", paddingRight: "2%" }}
      >
        <h6 className="enquiryheader">Patient Primary Enquiry</h6>
        <div className="row">
          <div className="col-6" style={{ padding: "2%" }}>
          <div className='row primary-enquiry'>
          <div className='col-6' style={{paddingLeft:0}}>
<span className="text-style-here">*Enquiry Number</span>
                        <input value={userDatas.pv_enquiry_number}  type="text" name="pv_enquiry_number"   onChange={handleChangeenqnum}    maxLength='30' className='enquiryinput'  />
                        { error.enquiry_number ? <div className="error-validationmsg">{error.enquiry_number}</div> : null}
</div>
          <div className='col-6' style={{padding:0}}>
          <span className="text-style-here">*PE Date</span>
                        
                        <DatePicker
                        className="enquiryinput"
                            onChange={date => { handlePEDate(date) }}
                            style={{ width: "80%", border: "none" }}
                            maxDate={new Date()}
                            value={pedate}

                        />
{ error.pe_date ? <div className="error-validationmsg">{error.pe_date}</div> : null}
         
            </div>
            </div>
            <div className="row primary-enquiry">
            <span className="text-style-here">Date of Birth</span>
            <div 
              className="enquiryinput"
              style={{ textAlign: "center", padding: "1%" }}
            >
              <DatePicker
                onChange={(date) => {
                  handleDOB(date);
                }}
                style={{ width: "80%", border: "none" }}
                maxDate={new Date()}
                value={(dob === undefined || dob === null) ? dob : new Date(dob)}
              />
              {""}
              <span
                style={{ fontSize: "10px", marginLeft: "10px", color: "grey" }}
              >
                {" "}
                OR{" "}
              </span>
              <input
                type="number"
                min="1"
                max="150"
                value={userDatas.age}
                placeholder="Age"
                name="age"
                onChange={handleChangeAge}
                style={{ marginLeft: "3%", borderRadius: "5px", width: "30%" }}
              ></input>
            </div>
            </div><div className="row primary-enquiry">
            <span className="text-style-here">Phone</span>
            <PhoneInput
              className="enquiryinput"
              style={{ width: "100%" }}
              country={"in"}
              onlyCountries={["in"]}
              countryCodeEditable={false}
              value={userDatas.phone.mobile_number}
              onChange={handlePhoneInput}
              //  onBlur={handleCheckExistingPhone}
            />
            {error.phone ? (
              <div className="error-validation-msg"> {error.phone}</div>
            ) : null}
            </div>
            <div className="row primary-enquiry"> 
               <div className={userDatas.identitycard&&userDatas.identitycard.value!=="" &&userDatas.identitycard.value!==null?"col-6":"col-12"} style={{padding:0}}>
               <span className="text-style-here">Identity Card</span>
            <Select
               value={userDatas.identitycard.value!==""?userDatas.identitycard:"Select type"}
              onChange={(data, e) => {
                IdentityChange(data, e);
              }}
              options={identityoptions}
              
              name="identitycard"
              isDisabled={userDatas.identitynumber}
            />
                </div> 
                {userDatas.identitycard&&userDatas.identitycard.value!=="" &&userDatas.identitycard.value!==null? (
              <div className="col-6" style={{paddingRight:0}}>
                {" "}
                <span className="text-style-here">Identity Card Number</span>
                <input
                  className="enquiryinput"
                  type="text"
                  value={userDatas.identitynumber}
                  name="identitynumber"
                 // disabled={isIdentityNumberDisabled}
                  placeholder="Space not allowed"
                  onChange={handleChangeidnum}
                />{" "}
                 {error.identitynumber ? (
              <div className="error-validation-msg">
                {" "}
                {error.identitynumber}
              </div>
            ) : null}
              </div>
            ) : (
              ""
            )}
           
            </div>
          
            <div className="row primary-enquiry">
            <span className="text-style-here">Address Line 2</span>
            <input
              className="enquiryinput"
              placeholder="Province"
              type="text"
              name="province"
              maxLength="200"
              value={userDatas.province}
              onChange={handleChange}
            />
            </div>
            <div className="row primary-enquiry">
                <span className="text-style-here">Country</span>
                <input
                  className="enquiryinput"
                  value={"India"}
                  disabled
                  type="text"
                />
            </div>
            <h6
              style={{
                marginLeft: "35%",
                marginTop: "2%",
                color: "#245693",
                fontSize: "15px",
              }}
            >
              {" "}
              <b> Referrer 1 </b>
            </h6>
            <div className="row">
              <div className="col-6" style={{ padding: "2%", marginTop: "2%" }}>
                <span className="text-style-here">Name</span>
                <input
                  className="enquiryinput"
                  type="text"
                  name="name"
                  value={referrer1.name==="null"?"":referrer1.name}
                  onChange={handleReferrer1Change}
                />
              </div>

              <div className="col-6" style={{ padding: "2%", marginTop: "1%" }}>
                <span className="text-style-here">Contact no</span>
                <PhoneInput
                  country={"in"}
                  onlyCountries={["in"]}
                  countryCodeEditable={false}
                  value={referrer1.contact===""?"91":referrer1.contact}
                  disabled={!referrer1.name||referrer1.name===""||referrer1.name==="null"}
                  name="contact"
                  onChange={(value) => {
                 
                    const numericValue = value.replace(/\D/g, "");
  
                    setReferrer1({
                      ...referrer1,
                      error: "", 
                      contact: value, 
                    });
                   
                    delete referrer1.error
                  
                     if(numericValue.length<2){
                      setReferrer1({
                        ...referrer1,
                      contact:"91",
                       
                      });
                    
                    }
                    
                    
                   
                  }}
                  className='enquiryinput'
                  style={{ width: "100%",marginTop:"1%" }}
                />
                    {referrer1.error&&referrer1.error!==""  ? <div className="error-validationmsg"> {referrer1.error }</div> : null}
              </div>
            </div>
            <span className="text-style-here">Comments</span>
            <textarea
              className="enquiryinputtext"
              name="comments"
              value={referrer1.comments}
              disabled={!referrer1.name||referrer1.name===""||referrer1.name==="null"}
             
              onChange={handleReferrer1Change}
              type="textarea"
            />
            <button onClick={handleMalayalamInputToggle}>
              Switch to Malayalam Input
            </button>{" "}
            <span style={{ fontSize: "10px", color: "grey" }}>
              *referer name is mandatory before entering comments 
            </span>
            {useMalayalamInput && (
              <iframe
                title="Google Malayalam Input"
                src={process.env.PUBLIC_URL + "/malayalam.html"}
                width="100%"
                height="400px"
              ></iframe>
            )}
          </div>

          <div className="col-6" style={{ padding: "2%" }}>
            <div className="row">
              <div className="col-8">
                <div className="row primary-enquiry">
                  <div className="col-6" style={{paddingLeft:0}}>
                  <span className="text-style-here">*First Name</span>
            <input
              value={userDatas.firstname}
              type="text"
              name="firstname"
              onChange={handleChange}
              maxLength="30"
              disabled={userDatas.firstname}
              className="enquiryinput"
            />
            {error.firstname ? (
              <div className="error-validation-msg">{error.firstname}</div>
            ) : null}
                  </div>
                  <div className="col-6" style={{padding:0}}>
                  <span className="text-style-here">Last Name</span>
                <input
                  className="enquiryinput"
                  type="text"
                  name="lastname"
                  maxLength="100"
                  value={userDatas.lastname}
                  onChange={handleChange}
                />
                {error.lastname ? (
                  <div className="error-validation-msg">{error.lastname}</div>
                ) : null}
                  </div>
                </div>
                <div className="row primary-enquiry">
                <span className="text-style-here">*Gender</span>
                <div
                  className="enquiryinput"
                  style={{ textAlign: "center", padding: "2%" }}
                >
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    value="male"
                    checked={userDatas.gender === "male"}
                    onClick={handleGenderRadioButton}
                    disabled={userDatas.gender !== ""}
                  />
                  <label htmlFor="male">Male</label>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="female"
                    checked={userDatas.gender === "female"}
                    onClick={handleGenderRadioButton}
                    disabled={userDatas.gender !== ""}
                  />
                  <label htmlFor="female">Female</label>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <input
                    type="radio"
                    id="others"
                    name="gender"
                    value="others"
                    checked={userDatas.gender === "others"}
                    onClick={handleGenderRadioButton}
                    disabled={userDatas.gender !== ""}
                  />
                  <label htmlFor="others">Others</label>
                  </div>
                  {error.gender ? (
                    <div className="error-validation-msg"> {error.gender}</div>
                  ) : null}
                </div>
                <div className="row primary-enquiry">
                <span className="text-style-here">Email</span>
                <input
                  className="enquiryinput"
                  type="text"
                  name="email"
                  maxLength="254"
                  value={userDatas.email}
                  onChange={handleChange}
                  onBlur={handleCheckExisting}
                /></div>
                {error.email ? (
                  <div className="error-validation-msg"> {error.email}</div>
                ) : null}
              </div>
              <div className="col-4" style={{ paddingBottom:'20px' }}>
                <span className="text-style-here">*photo</span>
                <div className="enquiryimage">
                  
                  {previewOn === false ? (
                    <div className="pic-container" style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", borderRadius: ".25rem"}}>
                      <input
                        type="file"
                        className="custom-file-input"
                        id="validatedCustomFile"
                        onChange={uploadImage}
                      />
                      {invalidPic && <p className="error">{invalidPic}</p>}
                      <label className="" htmlFor="validatedCustomFile">
                        <p>Upload Profile Picture</p>
                        <div className="camera-icon">
                          <i className="fa fa-camera camera-icon"></i>
                        </div>
                      </label>
                    </div>
                  ) : (
                    <div>
                      {/* <div>
                         onClick={() => {
                           setPreviewOn(false);
                         }}
                      
                        <i className="fa fa-window-close circular-button"></i>
                      </div> */}
                      <img 
                        src={userDatas.img_url}
                        alt="Hospital Picture"
                        className="avatar avatar-admission"
                      />
                    </div>
                  )}{" "}
                  {error.img_url ? (
                    <div className="error-validation-msg"> {error.img_url}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row primary-enquiry">
            <span className="text-style-here">Address Line1</span>
            <input
              className="enquiryinput"
              name="city"
              placeholder="City"
              maxLength="200"
              value={userDatas.city}
              onChange={handleChange}
              type="text"
            /></div>
            <div className="row primary-enquiry">
            <span className="text-style-here">*AddressLine 3</span>
            <input
              className="enquiryinput"
              placeholder="Location"
              type="text"
              maxLength="200"
              name="location"
              disabled={userDatas.location}
              value={userDatas.location}
              onChange={handleChange}
            /></div>
            {error.location ? (
              <div className="error-validation-msg">{error.location}</div>
            ) : null}
            <div className="row primary-enquiry">
              <div className="col-6" style={{ paddingLeft: 0 }}>
                <span className="text-style-here">Region</span>
                <RegionDropdown
                  className="enquiryinput"
                  country={"India"}
                  value={userDatas.state}
                  onChange={(val) => selectRegion(val)}
                />
              </div>
              <div className="col-6" style={{ padding:0 }}>
                <span className="text-style-here">Pincode</span>
                <input
                  className="enquiryinput"
                  type="text"
                  name="pincode"
                  maxLength="10"
                  value={userDatas.pincode}
                  onChange={handleChange}
                />
              </div>
            </div>

            <h6
              style={{
                marginLeft: "35%",
                marginTop: "2%",
                color: "#245693",
                fontSize: "15px",
              }}
            >
              {" "}
              <b> Referrer 2 </b>
            </h6>
            <div className="row">
              <div className="col-6" style={{ padding: "2%" }}>
                <span className="text-style-here">Name</span>
                <input
                  className="enquiryinput"
                  type="text"
                  name="name"
                  value={referrer2.name==="null"?"":referrer2.name}
                  onChange={handleReferrer2Change}
                />
              </div>

              <div className="col-6" style={{ padding: "2%" }}>
                <span className="text-style-here">Contact no</span>
                <PhoneInput
                  country={"in"}
                  onlyCountries={["in"]}
                  countryCodeEditable={false}
                  value={referrer2.contact}
                  disabled={!referrer2.name||referrer2.name===""||referrer2.name==="null"}
                  name="contact"
                  onChange={(value) => {
                 
                    const numericValue = value.replace(/\D/g, "");
  
                    setReferrer2({
                      ...referrer2,
                      error: "", 
                      contact: value, 
                    });
                   
                    delete referrer2.error
                  
                     if(numericValue.length<2){
                      setReferrer2({
                        ...referrer2,
                      contact:"91",
                       
                      });
                    
                    }
                  }}
                  className='enquiryinput'
                  style={{ width: "100%" }}
                />
             {referrer2.error&&referrer2.error!==""  ? <div className="error-validationmsg"> {referrer2.error }</div> : null}
              </div>
            </div>
            <span className="text-style-here">Comments</span>
            <textarea
              className="enquiryinputtext"
              name="comments"
              value={referrer2.comments}
             
              onChange={handleReferrer2Change}
              disabled={!referrer2.name||referrer2.name===""||referrer2.name==="null"}
              type="textarea"
            />
            <button onClick={handleMalayalamInputToggle1}>
              Switch to Malayalam Input
            </button>
            {useMalayalamInput1 && (
              <iframe
                title="Google Malayalam Input"
                src={process.env.PUBLIC_URL + "/malayalam.html"}
                width="100%"
                height="400px"
              ></iframe>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6" style={{ padding: "2%" }}>
            <label
              style={{
                backgroundColor: "white",
                border: "1px dashed #104173",
                height: "40px",
                width: "98%",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                padding: "0 12px",
                cursor: "pointer",
              }}
            >
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleFile}
              />
              <i className="fa fa-cloud-upload" aria-hidden="true"></i>
              {fileName}
            </label>
          </div>

          <div className="col-6" style={{ padding: "2%" }}>
            <button
              style={{
                backgroundColor: "#9069A5",
                color: "white",
                height: "40px",
                border: "none",
                width: "98%",
                borderRadius: "10px",
              }}
              onClick={(e) => handleContinue(e)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {pvShow ? (
        <ViewPopup
          show={pvShow}
          onHide={() => {
            setPVShow(false);
            window.location.reload(false);
          }}
        />
      ) : null}
      {alertShow ? (
        <AlertPopUp
          show={alertShow}
          onHide={() => {
            setAlertShow(false);
          }}
        />
      ) : null}
      {showConfirmation && (
      <SubmitConfirmationPopup
        message="Are you sure you want to submit the form?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    )}
    </div>
  );
}
export default PrimaryEnquiry;
