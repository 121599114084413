import React, { useState, useEffect } from 'react';
import { Button, Card, ListGroup, ListGroupItem, ModalTitle } from 'react-bootstrap';
import axios from 'axios';
import { APIURL } from '../../../Global';
import { Modal } from "react-bootstrap";
import DetailView from './DetailView';


function AdmissionView(props) {
  const [viewData, setViewData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentDetail, setCurrentDetail] = useState(null);
  const [selectedDetail, setSelectedDetail] = useState(null);

  useEffect(() => {  
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    
    axios
      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_enquiry_number=${props.id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setViewData(res.data[0]); // Assuming the response is an array with one object
        } else {
          alert.error(res.data.message);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      }); 
  }, [props.id]);

   console.log(viewData)


   const handleViewDetail = (detailType) => {
    let detailName = '';
    let detailData = [];
  
    switch (detailType) {
      case 'Witness':
        case 'Witness':
          detailName = 'Witness';
          const witnessesData = viewData.witness_info ? viewData.witness_info : [];
          for (let i = 0; i < witnessesData.length; i++) {
            const witness = witnessesData[i];
            detailData.push(`Witness ${i + 1}`);
            detailData.push(witness.name);
            detailData.push(witness.contact);
            if (i === 0 && viewData.witness1_sign) {
              detailData.push(viewData.witness1_sign);
            } else if (i === 1 && viewData.witness2_sign) {
              detailData.push(viewData.witness2_sign);
            }
          }
          break;
  
      case 'Guardian':
        detailName = 'Guardian';
        const guardianData = viewData.guardian_info;
        if (guardianData) {
          detailData = [guardianData.name, guardianData.address, guardianData.contact, viewData.guardian_sign].filter(Boolean);
        }
        break;
  
      case 'Residents Signature':
        detailName = 'Residents Signature';
        detailData = [viewData.admission_consent_form].filter(Boolean);
        break;
  
      default:
        break;
    }
  
    setCurrentDetail({ name: detailName, data: detailData });
    setSelectedDetail(detailType);
    setShowModal(true);
  };
  
  
  
  
  const ViewPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <DetailView detail={props.detail} />
        </Modal.Body>
      </Modal>
    );
  };
  

  return (
    <div>
      {viewData && (
        <Card className="mb-2" bg="light" border="light" style={{ height: "auto", top: "-10%" }}>
          <hr />
          <Card.Body>           
            <ListGroup style={{ textAlign: "left" }} className="list-group-flush">
              <ListGroupItem>
                <b>Enquiry Number</b>
                <span style={{ float: "right" }}>{viewData.pv_enquiry_number}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>PE Date</b>
                <span style={{ float: "right" }}>{viewData.pe_date && viewData.pe_date.slice(0, 10)}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Admission Number</b>
                <span style={{ float: "right" }}>{viewData.pv_admission_number}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Admission Date</b>
                <span style={{ float: "right" }}>{viewData.admission_date && viewData.admission_date.slice(0, 10)}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Name</b>
                <span style={{ float: "right", textTransform:"capitalize" }}>{(viewData.first_name)} {viewData.last_name && viewData.last_name}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Gender</b>
                <span style={{ float: "right", textTransform:"capitalize" }}>{viewData.gender}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>DOB</b>
                <span style={{ float: "right" }}>{viewData.dob}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Address</b>
                <span style={{ float: "right", textTransform:"capitalize" }}>
                  {`${viewData.location || ''} ${viewData.city || ''} ${viewData.state || ''} ${viewData.country || ''} ${viewData.pincode || ''}`}
                </span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Marital status</b>
                <span style={{ float: "right" }}>{viewData.marital_status===true ? "Married" : viewData.marital_status===false ? "Unmarried":""}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Bank Account</b>
                <span style={{ float: "right" }}>{viewData.bank_account}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Religion</b>
                <span style={{ float: "right" ,textTransform:"capitalize"}}>{viewData.religion}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Caste</b>
                <span style={{ float: "right", textTransform:"capitalize" }}>{viewData.caste}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Group</b>
                <span style={{ float: "right", textTransform:"capitalize" }}>{viewData.group}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Education</b>
                <span style={{ float: "right" , textTransform:"capitalize" }}>{viewData.education}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Occupation</b>
                <span style={{ float: "right", textTransform:"capitalize" }}>{viewData.job}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>ID card</b>
                <span style={{ float: "right", textTransform:"capitalize" }}>{viewData.idcard_type}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>ID Card Number</b>
                <span style={{ float: "right" }}>{viewData.idcard_no}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Income</b>
                <span style={{ float: "right", textTransform:"capitalize"}}>
                  {viewData.own_income 
                    ? (viewData.own_income) 
                    : "No"}
                </span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Licence/Programme</b>
                <span style={{ float: "right", textTransform: "capitalize" }}>
                  {viewData.license}
                </span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Basis for Admission</b>
                <span style={{ float: "right", textTransform:"capitalize" }}>{viewData.basis_for_admission}</span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Residents Signature</b>
                <span style={{ float: "right" }}>
                  <button style={{ backgroundColor: "rgb(52, 104, 194)", color: "white" }} onClick={() => handleViewDetail('Residents Signature')}>View</button>
                </span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Witness/Social worker</b>
                <span style={{ float: "right" }}>
                  <button style={{ backgroundColor: "rgb(52, 104, 194)", color: "white" }} onClick={() => handleViewDetail('Witness')}>View</button>
                </span>
              </ListGroupItem>
              <ListGroupItem>
                <b>Guardian</b>
                <span style={{ float: "right" }}>
                  <button style={{ backgroundColor: "rgb(52, 104, 194)", color: "white" }} onClick={() => handleViewDetail('Guardian')}>View</button>
                </span>
              </ListGroupItem>              
            </ListGroup>
          </Card.Body>
        </Card>
      )}

      {showModal && (
        <ViewPopUp
          show={showModal}
          onHide={() => setShowModal(false)}
          detail={currentDetail}
          title={selectedDetail}
        />
      )}
    </div>
  );
}

export default AdmissionView;
