import React, { useState, useEffect, useRef, useMemo } from "react";
//import HospBdoNavbar from '../HospBdoNavbar/HospBdoNavbar'
import axios from "axios";
import { APIURL } from "../../../Global";
import Carousel from 'react-bootstrap/Carousel';
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import ReactTable from "react-table-v6";
import { useAlert } from "react-alert";
import "./payments.css";
import paymentPage from "../../../assets/images/paymentpage.jpg";
import PaymentConfigNew from "./PaymentConfigNew";
import PaymentConfig from "./PaymentConfig";
import PaymentInvoice from "./PaymentInvoice";
import PaymentInvoiceTest from "./PaymentInvoiceTest";
import PaymentInvoiceMedicine from "./PaymentInvoiceMedicine";
import PaymentInvoiceScan from "./PaymentInvoiceScan";
import PaymentInvoiceMisc from "./PaymentInvoiceMisc";
import PaymentInvoiceLab from "./PaymentInvoiceLab";
import PaymentInvoiceReg from "./PaymentInvoiceReg";
import PaymentInvoiceScanPre from "./PaymentInvoiceScanPre";
import PaymentInvoiceProPre from "./PaymentInvoiceProPre";
import PaymentInvoiceMedPre from "./PaymentInvoiceMedPre";
import PaymentInvoiceLiability from "./PaymentInvoiceLiability";
import { Tooltip , OverlayTrigger } from "react-bootstrap";
import PastPayments from "./PastPayments";
import moment from "moment";

function Payments(serviceList) {


  const [liabilityList, setLiability] = useState([]);

  const [messageData, setMessageData] = useState([]);
  const [expand, setExpand] = useState();
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [expandedCardData, setExpandedCardData] = useState([]);
  const [detailsData, setDetailsData] = useState({
    details: []
  });

  let totalAmount1;
  const [searchTerm, setSearchTerm] = useState("");
  const [patientName, setPatientName] = useState("");
  const [reload, setReload] = useState(false);
  const [patient, setPatient] = useState([]);
  const [datas,setDatas]=useState("")
  const [scanPreData, setScanPreData] = useState([]);
  const [liabilityInvoice, setLiabilityInvoice] = useState([]);
  const [proPreData, setProPreData] = useState([]);
  const [medPreData, setMedPreData] = useState([]);
  const [medProData, setMedProData] = useState([]);
  const [miscPreData, setMiscPreData] = useState([]);
  const [load, setLoad] = useState(false);
  const [proID, setProIDs] = useState([]);
  const [hasNext, setHasNext] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [config, setConfig] = useState([]);
  const [invoiceShow, setInvoiceShow] = useState(false);
  // const [flag, setFlag]= useState(0)
  const [invoiceShow1, setInvoiceShow1] = useState(false);
  const [invoiceShow2, setInvoiceShow2] = useState(false);
  const [invoiceShow3, setInvoiceShow3] = useState(false);
  const [invoiceShow5, setInvoiceShow5] = useState(false);
  const [invoiceShow6, setInvoiceShow6] = useState(false);
  const [invoiceShow8, setInvoiceShow8] = useState(false);
  const [invoiceShow9, setInvoiceShow9] = useState(false);
  const [invoiceShow10, setInvoiceShow10] = useState(false);
  const [invoiceShow11, setInvoiceShow11] = useState(false);
  const [invoiceShow20, setInvoiceShow20] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const alert = useAlert();
  const [errorShow, setErrorShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  // const [totalPages, setTotalPages]= useState(0)
  // const [perPage, setPerPage]= useState(2)
  // const [currentPage, setCurrentPage]= useState(1)
  // const [totalRecords, setTotalRecords]= useState(0)
  const [pastShow, setPastShow] = useState(false);
  const [validateErrorShow, setValidateErrorShow] = useState(false);
  const [display, setDisplay] = useState(false);
  const [display1, setDisplay1] = useState(false);
  const [allData, setAllData] = useState([]);
  const [misce, setMisc] = useState([]);
const [perPage, setPerPage] = useState(10);
  const [patientID, setPatientID] = useState("");
  const [idVal, setIdVal] = useState("");
  const [procedureData, setProcedureData] = useState([]);
  const [scanData, setScanData] = useState([]);
  const [testData, setTestData] = useState([]);
  const [labData, setLabData] = useState([]);
  const [medicineData, setMedicineData] = useState([]);
  const [regData, setRegData] = useState([]);
  const [page, setPage] = useState(0);
  let ProcedureIDs = [];
  let ScanIDs = [];
  let TestIDs = [];
  let MiscIDs = [];
  let RegIDs = [];
  let MedIDs =[];
  let LabIDs= [];
  let ScanPreIDs =[];
  let MedPreIDs =[];
  let ProPreIDs =[];

  let TestPutIds = [];
  let ScanPutIds =[];
  let ProcedurePutIds=[];
  let MedicinePutIds=[];
  const [patientDetails, setPatientDetails] = useState({
    id: "",
    name: "",
    mobile_number: "",
    dob: "",
    address: "",
    next_visit: "",
    cpr: "",
    file_number: "",
  });

  const handleDisplay = () => {
    setDisplay(false);
  };

  useEffect(() => {
if(patientID!==""){
   liability(patientID,page)}
  }, [hasNext,page]);

  useEffect(() => {
    

  }, [medPreData]);



  ProcedureIDs = procedureData.map((item) => {
    return item.id;
  });
  ScanIDs = scanData.map((item) => {
    return item.id;
  });
  TestIDs = testData.map((item) => {
    return item.id;
  });

  MiscIDs = misce.map((item) => {
    return item.id;
  });
  RegIDs = regData.map((item) => {
    return item.id;
  });
  MedIDs = medicineData.map((item) => {
    return item.id;
  });

  LabIDs = labData.map((item) => {
    return item.hosp_service_id;
  });
  ScanPreIDs = scanPreData.map((item) => {
    return item.hosp_service_id;
  });
  ProPreIDs = proPreData.map((item) => {
    return item.hosp_service_id;
  });
  MedPreIDs = medPreData.map((item) => {
    return item.hosp_service_id;
  });
  ScanPutIds = scanPreData.map((item) => {
    return item.schedule_id;
  });
 TestPutIds = labData.map((item) => {
    return item.schedule_id;
  });
  ProcedurePutIds = proPreData.map((item) => {
    return item.schedule_id;
  });
  MedicinePutIds = medPreData.map((item) => {
    return item.schedule_id;
  });

  const totalAmount = procedureData.reduce((sum, row) => sum + row.amount, 0);
  const vatTotal = procedureData.reduce((sum, row) => sum + row.vat, 0);
  const discountTotal = procedureData.reduce(
    (sum, row) => sum + row.discount,
    0
  );
  const totalAfterDiscount =
    ((totalAmount + vatTotal) * (100 - discountTotal)) / 100;

  const totalAmountSacn = scanData.reduce((sum, row) => sum + row.amount, 0);
  const vatTotalScan = scanData.reduce((sum, row) => sum + row.vat, 0);
  const discountTotalScan = scanData.reduce(
    (sum, row) => sum + row.discount,
    0
  );
  const totalAfterDiscountScan =
    ((totalAmountSacn + vatTotalScan) * (100 - discountTotalScan)) / 100;

  const totalAmountTest = testData.reduce((sum, row) => sum + row.amount, 0);
  const vatTotalTest = testData.reduce((sum, row) => sum + row.vat, 0);
  const discountTotalTest = testData.reduce(
    (sum, row) => sum + row.discount,
    0
  );
  const totalAfterDiscountTest =
    ((totalAmountTest + vatTotalTest) * (100 - discountTotalTest)) / 100;

  const totalAmountMisc = misce.reduce((sum, row) => sum + row.amount, 0);
  const vatTotalMisc = misce.reduce((sum, row) => sum + row.vat, 0);
  const discountTotalMisc = misce.reduce((sum, row) => sum + row.discount, 0);
  const totalAfterDiscountMisc =
    ((totalAmountMisc + vatTotalMisc) * (100 - discountTotalMisc)) / 100;
  const totalAmountReg = regData.reduce((sum, row) => sum + row.amount, 0);
  const totalAmountMed = medicineData.reduce((sum, row) => sum + row.amount, 0);
  const totalAmountLab = labData.reduce((sum, row) => sum + row.amount, 0);
  const totalAmountScanPre = scanPreData.reduce((sum, row) => sum + row.amount, 0);
  const totalAmountProPre = proPreData.reduce((sum, row) => sum + row.amount, 0);
  const totalAmountMedPre = medPreData.reduce((sum, row) => sum + row.amount, 0);
 

 
  const liability = async (item) => {
    const tokenString = sessionStorage.getItem("usertoken");
    const v2 = tokenString.replace(/["]+/g, "");
  
    let data = [];
    let currentPage = 1;
    let totalPages = 1;
  
    while (currentPage <= totalPages) {
      await axios
        .get(`${APIURL}/api/v1/service-provider/patient-liabilities/?patient_id=${item}&page=${currentPage}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            const newLiabilities = res.data.liabilities;
  
            if (newLiabilities && newLiabilities.length > 0) {
              const newData = newLiabilities.filter(function (o1) {
                return !data.some(function (o2) {
                  return o1.id === o2.id;
                });
              });
              data = [...data, ...newData];
            }
  
            totalPages = res.data.total_pages;
          } else {
            setErrorMsg("error");
          }
        })
        .catch((err) => {
          setErrorMsg("error");
        });
  
      currentPage++;
    }
  
    setLiability(data);
    setDisplay1(true);
  };
  
  const handleInvoiceCardData =(itemId,checkoutcartId) =>{
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");
    axios.get(
      `${APIURL}/api/v1/service-provider/liability-detail/${itemId}/?checkoutcart_id=${checkoutcartId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + v2,
        },
      }
    )
    .then((res) => {
          
      if (res.data.status === "success") {

        setIdVal(itemId)
     
        setLiabilityInvoice(res.data.message)
        
        setInvoiceShow20(true);

  }
})
  }


  
  const handleExpandCard = (itemId, checkoutcartId) => {
    
    // Find the liability item in the data that matches the checkout cart ID of the expanded card
    const matchingLiability = liabilityList && liabilityList.find(liability => liability.checkoutcart === checkoutcartId);
 
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");
    // If a matching liability item was found, fetch data from the second API based on item ID and checkout cart ID
    if (matchingLiability) {
     axios.get(
                      `${APIURL}/api/v1/service-provider/liability-detail/${itemId}/?checkoutcart_id=${checkoutcartId}`,
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "Token " + v2,
                        },
                      }
                    )
      
          // Set the expanded card data to be an object containing both the liability and message data
          .then((res) => {
          
            if (res.data.status === "success") {
          
          
            //           //  //setDetailData(res.data.message)
                           
          
            //           //     }
            //             })

            let data = res.data.message

           if(data.length>0) {
          // setExpandedCardData({

          //  // liability: matchingLiability,
          //   message: res.data.message[0]
          // }) }
          setExpandedCardData(data)}
          else{
            setExpandedCardData([])
            setExpand()
           
          }
    
         
        }
        })
        .catch(error => console.error(error));
    }

    setExpandedCardId(checkoutcartId);

  }
  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index);
      
    } else {
      setExpand();
  
    }
  };
let display2 = liabilityList.length > 0 ? (
  
  <>
       <h5 className="text-center" style={{ marginTop: "-2%" }}>
          <b>Liabilities</b>
        </h5>

<div className="drs-container">

{liabilityList.map((item,index) => (

<>

<div className="doctor-card45">
<div key={item.id} onClick={() => handleDetail(index)}>
              <div className="boxsidebar" style={{marginLeft:"-19%"}}>
          <span>&nbsp;</span>{" "}
           {item.type === "master_scan"
            ? "Scan"
            : item.type === "misc"
            ? "Misc"
            : item.type === "master_medicine"
            ? "Medicine"
            : item.type === "master_procedure"
            ? "Procedure"
            : item.type === "master_consumable"
            ? "Consumables"
            : item.type === "master_labtest"
            ? "Test"
            : item.type === "master_procedure"
            ? "Procedure"
            : item.type === "master_consumable"
            ? "Consumables"
            : item.type === "master_labtest"
            ? "Test"
            : item.type === "registration"
            ? "Registration"
            : ""}

         

        </div>
        <div className="text-center123">

        <b>
           {item.type === "master_scan"
              ? "Scan"
              : item.type === "misc"
              ? "Misc"
              : item.type === "master_medicine"
              ? "Medicine"
              : item.type === "master_procedure"
              ? "Procedure"
              : item.type === "master_consumable"
              ? "Consumables"
              : item.type === "master_labtest"
              ? "Test"
              : item.type === "registration"
              ? "Registration"
              : ""} 
          </b> <i class="fa fa-credit-card" aria-hidden="true" onClick={() => handleInvoiceCardData(item.id, item.checkoutcart)} style={{cursor:"pointer"}}></i>
          <br />
          {new Date(item.payment_date).toLocaleDateString()}
          <br />

          <b>
            {" "}
            <i class="fa fa-inr" aria-hidden="true"></i>{" "}
            {item.pending_amount}
          </b>
          
          <i
                        className={
                          expand === index
                            ? "fa fa-angle-up fa-fw"
                            : "fa fa-angle-down fa-fw"
                        }
                        style={{ color: "#6788bc " }}
                        onClick={() => handleExpandCard(item.id, item.checkoutcart)}
                      ></i>

{/* <button onClick={() => handleExpandCard(item.id, item.checkoutcart)}>Expand</button> */}
{expand === index && expandedCardData.length>0 ? 

// expandedCardId === item.checkoutcart &&  &&  ( 


  
 
    expandedCardData.map((item)=>{
      return(
        <div>
        <h6><b>{item.item_service_name}</b></h6>
       
      </div>
      )
      })


 :""}
</div>
</div>
</div>


</>  ))}  </div> </> ): <h4 className="text-center" style={{ color: "red" }}>
       No liabilities!!
       </h4>

 
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (searchTerm === "") {
      setPatient([]);
    } else {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      axios
        .get(
          `${APIURL}/api/v1/staff/patient-list/?per_page=1000&page=10&q=${searchTerm}`,
          {
            headers: { Authorization: "Token " + v2 },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            setPatient(res.data.patient);
            setDisplay(true);

            // setTotalPages(res.data.total_pages)
            // setCurrentPage(res.data.page)
            // setTotalRecords(res.data.total_records)
          } else {
          }
        })
        .catch((error) => {});
    }
  }, [searchTerm]);

  useEffect(() => {


    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(/["]+/g, "");

 axios
      .get(
        `${APIURL}/api/v1/service-provider/hospital-payments/?patient_id=${patientDetails.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + v2,
          },
        }
      )
      .then((res) => {
       
     
        setDatas(res.data);
          
          
      
           // setHasNext(() => res.data.has_next);
         
           // setDisplay1(true);
       
        
       
    
      })
      .catch((err) => {
      
      });

}, [refresh]);

  const handleSearch = () => {
    const tokenString = localStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */

    axios
      .get(
        `${APIURL}/api/v1/staff/patient-list/?per_page=1000&page=10&q=${searchTerm}`,
        {
          headers: { Authorization: "Token " + v2 },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setPatient(res.data.patient);
          setDisplay(true);
        } else {
        }
      })
      .catch((error) => {});
  };

  const handleGeneratePro = (e) => {
    setInvoiceShow(true);
  };
  const handleGenerateMedicine = (e) => {
    setInvoiceShow6(true);
  };
  const handleGenerateLab = (e) => {
    setInvoiceShow8(true);
  };



  const handleGenerateScanPre = (e) => {
    setInvoiceShow9(true);
  };
  const handleGenerateProPre = (e) => {
    setInvoiceShow10(true);
  };
  const handleGenerateMedPre = (e) => {
    setInvoiceShow11(true);
  };
  const handleGenerateScan = (e) => {
    setInvoiceShow1(true);
  };

  const handleGenerateTest = (e) => {
    setInvoiceShow2(true);
  };
  const handleGenerateReg = (e) => {
    setInvoiceShow5(true);
  };
  const handleGenerateMisc = (e) => {
    setInvoiceShow3(true);
  };
  const dataLabData1 = (childData) => {

    
    let allMedicines = [];
    let filterMedicines =[];
    // childData.forEach((item) => {
    //   allMedicines = [...allMedicines, ...childData];
    // });

    //setMedProData(allMedicines)

   
    filterMedicines = childData.filter((item)=>{
      return item.hosp_service_id !== null;

    }
    
    )


    setLabData(filterMedicines);
    setDisplay1(false);
    //
   
  }
  const dataScanData1 = (childData) => {


    let allMedicines = [];
    let filterMedicines =[];
    // childData.forEach((item) => {
    //   allMedicines = [...allMedicines, ...childData];
    // });

    //setMedProData(allMedicines)
   
    filterMedicines = childData.filter((item)=>{
      return item.hosp_service_id !== null;

    }
    
    )


    setScanPreData(filterMedicines);
    setDisplay1(false);
    //

  }

  const dataMedData1 = (childData) => {
    

    let allMedicines = [];
    let filterMedicines =[];
    childData.forEach((item) => {
      allMedicines = [...allMedicines, ...item.medicines];
    });

    //setMedProData(allMedicines)
   
    filterMedicines = allMedicines.filter((item)=>{
      return item.hosp_service_id !== null;

    }
    
    )


    setMedPreData(filterMedicines);
    setDisplay1(false);
    //

   
  }

 
  const dataProData1 = (childData) => {

    let allMedicines = [];
    let filterMedicines =[];
    // childData.forEach((item) => {
    //   allMedicines = [...allMedicines, ...childData];
    // });

    //setMedProData(allMedicines)
   
    filterMedicines = childData.filter((item)=>{
      return item.hosp_service_id !== null;

    }
    
    )


    setProPreData(filterMedicines);
    setDisplay1(false);
    //

  }


  const configDataHandle = (childData) => {
    
   


    let dataList = childData.filter((item) => {
      return item.main_service_name === "Hospital procedures";
    });

    setProcedureData(dataList);
    setDisplay1(false);

    setScanData(
      childData.filter((item) => {
        return item.main_service_name === "Scan";
      })
    );
    setDisplay1(false);

    setTestData(
      childData.filter((item) => {
        return item.main_service_name === "laboratory";
      })
    );
    setDisplay1(false);
    setMisc(
      childData.filter((item) => {
        return (
          item.main_service_name === "misc" &&
          item.sub_service !== "Registration" &&
          item.sub_service !== "registration"
        );
      })
    );

    const registrationItems = childData.filter((item) => item.sub_service.toLowerCase() === "registration");
    if (registrationItems.length > 0) {
      const registrationStatus = datas.registration;
      if (datas.status === "success" && registrationStatus === "not_paid") {
        setRegData(registrationItems);
        setDisplay1(false);
      } else if (registrationStatus === "paid") {
        alert.error("Already registered");
      } else {
        alert.error("No registration required");
      }
    }


    setMedicineData(
      childData.filter((item) => {
        return item.main_service_name === "medicine";
      })
    );
    setDisplay1(false);

   
  };

  const paymentdata = (data) => {};

  const handleModal = () => {
    setModalShow(false);
    setSearchTerm("");
  };

  const ConfigPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        dialogClassName="config-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className="title-of-tasks" style={{ marginLeft: "350%" }}>
              Payment
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentConfigNew
            dataHandle={configDataHandle}
            dataLab={dataLabData1}
            dataScan={dataScanData1}
            
            dataPro={dataProData1}
           dataMed={dataMedData1}
            dataPayment={paymentdata}
            modalClose={handleModal}
            config={allData}
            patientID={patientDetails.id}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const DataProcedureData = () => {
    setProcedureData([]);
    setInvoiceShow(false);
  };
  const DataScanData = () => {
    setScanData([]);
    setInvoiceShow1(false);
  };
  const DataTestData = () => {
    setTestData([]);
    setInvoiceShow2(false);
  };
  const DataMiscData = () => {
    setMisc([]);
    setInvoiceShow3(false);
  };
  const DataMedData = () => {
    setMedicineData([]);
    setInvoiceShow6(false);
  };
  const DataLabData = () => {
    setLabData([]);
    setInvoiceShow8(false);
  };
  const DataScanPreData = () => {
    setScanPreData([]);
    setInvoiceShow9(false);
  };
  const DataProPreData = () => {
    setProPreData([]);
    setInvoiceShow10(false);
  };
  const DataMedPreData = () => {
    setMedPreData([]);
    setInvoiceShow11(false);
  };
  const DataLiabilityPreData = () => {
    
    setLiabilityInvoice([]);
    setInvoiceShow20(false);
    initialRender.current = true;

    setSearchTerm(patientName)

    setLiability([]);

    liability(patientID,1)
    setLiability(liabilityList)

  };
  const DataRegData = () => {
    setRegData([]);
    setInvoiceShow5(false);
  };

  const InvoicePopup = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <PaymentInvoice
            invoiceData={procedureData}
            name={patientDetails.full_name}
            total={totalAmount}
            vat={vatTotal}
            discount={discountTotal}
            netTotal={totalAfterDiscount}
            mobile={patientDetails.mobile_number}
            id={patientDetails.id}
            ProcedureID={ProcedureIDs}
            dataHandle1={DataProcedureData}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const InvoicePopup1 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <PaymentInvoiceScan
            invoiceData={scanData}
            name={patientDetails.full_name}
            total={totalAmountSacn}
            vat={vatTotalScan}
            discount={discountTotalScan}
            netTotal={totalAfterDiscountScan}
            mobile={patientDetails.mobile_number}
            id={patientDetails.id}
            dataHandle1={DataScanData}
            ScanID={ScanIDs}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const InvoicePopup2 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <PaymentInvoiceTest
            invoiceData={testData}
            name={patientDetails.full_name}
            total={totalAmountTest}
            vat={vatTotalTest}
            discount={discountTotalTest}
            netTotal={totalAfterDiscountTest}
            mobile={patientDetails.mobile_number}
            id={patientDetails.id}
            dataHandle1={DataTestData}
            TestID={TestIDs}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const InvoicePopup3 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <PaymentInvoiceMisc
            invoiceData={misce}
            name={patientDetails.full_name}
            total={totalAmountMisc}
            vat={vatTotalMisc}
            discount={discountTotalMisc}
            netTotal={totalAfterDiscountMisc}
            mobile={patientDetails.mobile_number}
            id={patientDetails.id}
            dataHandle1={DataMiscData}
            MiscID={MiscIDs}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const InvoicePopup5 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <PaymentInvoiceReg
            invoiceData={regData}
            name={patientDetails.full_name}
            total={totalAmountReg}
            mobile={patientDetails.mobile_number}
            id={patientDetails.id}
            dataHandle1={DataRegData}
            RegID={RegIDs}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const InvoicePopup6 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <PaymentInvoiceMedicine
            invoiceData={medicineData}
            name={patientDetails.full_name}
            total={totalAmountMed}
            mobile={patientDetails.mobile_number}
            id={patientDetails.id}
            dataHandle1={DataMedData}
            MedID={MedIDs}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const InvoicePopup8 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <PaymentInvoiceLab
            invoiceData={labData}
            name={patientDetails.full_name}
            total={totalAmountLab}
            mobile={patientDetails.mobile_number}
            id={patientDetails.id}
            dataHandle1={DataLabData}
            LabID={LabIDs}
            LabPutId={TestPutIds}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const InvoicePopup9 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <PaymentInvoiceScanPre
            invoiceData={scanPreData}
            name={patientDetails.full_name}
            total={totalAmountScanPre}
            mobile={patientDetails.mobile_number}
            id={patientDetails.id}
            dataHandle1={DataScanPreData}
            ScanPreID={ScanPreIDs}
            ScanPutId={ScanPutIds}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const InvoicePopup10 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <PaymentInvoiceProPre
            invoiceData={proPreData}
            name={patientDetails.full_name}
            total={totalAmountProPre}
            mobile={patientDetails.mobile_number}
            id={patientDetails.id}
            dataHandle1={DataProPreData}
            ProPreID={ProPreIDs}
            ProcedurePutId={ProcedurePutIds}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const InvoicePopup11 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <PaymentInvoiceMedPre
            invoiceData={medPreData}
            name={patientDetails.full_name}
            total={totalAmountMedPre}
            mobile={patientDetails.mobile_number}
            id={patientDetails.id}
            medPutID={MedicinePutIds}
            dataHandle1={DataMedPreData}
            MedPreID={MedPreIDs}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const InvoicePopup20 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <PaymentInvoiceLiability
            invoiceData={liabilityInvoice}
            name={patientDetails.full_name}
            total={totalAmountMedPre}
            mobile={patientDetails.mobile_number}
            id={patientDetails.id}
            master_id={idVal}
           // functionData={liability}

            dataHandle1={DataLiabilityPreData}
           // MedPreID={MedPreIDs}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const PastPaymentPopup = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-80w"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <PastPayments
            id={patientDetails.id}
            name={patientDetails.full_name}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            Error in Data Submission. Please try again!
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ValidateErrorPopup = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>Please Select a Patient !!</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const renderTotalReg = (cellInfo) => {
    // if(procedureData[cellInfo.index].amount){

    if (regData[cellInfo.index].amount) {
      let vatpercentage5 = regData[cellInfo.index].vat;
      let discountperentage5 = regData[cellInfo.index].discount;

      let discountedAmount5 =
        (regData[cellInfo.index].amount * discountperentage5) / 100;
      let discountedTotalAmount5 =
        regData[cellInfo.index].amount - discountedAmount5;
      let vatAmount5 = discountedTotalAmount5 * (vatpercentage5 / 100);
      let totalAmount5 = discountedTotalAmount5 + vatAmount5;

      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
          >
            {totalAmount5}
          </div>
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };

  const renderTotal = (cellInfo) => {
    // if(procedureData[cellInfo.index].amount){

    if (procedureData[cellInfo.index].amount) {
      let vatpercentage = procedureData[cellInfo.index].vat;
      let discountperentage = procedureData[cellInfo.index].discount;

      let discountedAmount =
        (procedureData[cellInfo.index].amount * discountperentage) / 100;
      let discountedTotalAmount =
        procedureData[cellInfo.index].amount - discountedAmount;
      let vatAmount = discountedTotalAmount * (vatpercentage / 100);
      let totalAmount = discountedTotalAmount + vatAmount;

      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
          >
            {totalAmount}
          </div>
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };
  const renderTotalTest = (cellInfo) => {
    // if(procedureData[cellInfo.index].amount){

    if (testData[cellInfo.index].amount) {
      let vatpercentage2 = testData[cellInfo.index].vat;
      let discountperentage2 = testData[cellInfo.index].discount;

      let discountedAmount2 =
        (testData[cellInfo.index].amount * discountperentage2) / 100;
      let discountedTotalAmount2 =
        testData[cellInfo.index].amount - discountedAmount2;
      let vatAmount2 = discountedTotalAmount2 * (vatpercentage2 / 100);
      let totalAmount2 = discountedTotalAmount2 + vatAmount2;

      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
          >
            {totalAmount2}
          </div>
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };
  const renderTotalScan = (cellInfo) => {
    // if(procedureData[cellInfo.index].amount){

    if (scanData[cellInfo.index].amount) {
      let vatpercentage1 = scanData[cellInfo.index].vat;
      let discountperentage1 = scanData[cellInfo.index].discount;

      let discountedAmount1 =
        (scanData[cellInfo.index].amount * discountperentage1) / 100;
      let discountedTotalAmount1 =
        scanData[cellInfo.index].amount - discountedAmount1;
      let vatAmount1 = discountedTotalAmount1 * (vatpercentage1 / 100);
      let totalAmount1 = discountedTotalAmount1 + vatAmount1;

      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
          >
            {totalAmount1}
          </div>
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };

  const renderTotalMisc = (cellInfo) => {
    // if(procedureData[cellInfo.index].amount){


    if (misce[cellInfo.index].amount) {
      let vatpercentage1 = misce[cellInfo.index].vat;
      let discountperentage1 = misce[cellInfo.index].discount;

      let discountedAmount1 =
        (misce[cellInfo.index].amount * discountperentage1) / 100;
      let discountedTotalAmount1 =
      misce[cellInfo.index].amount - discountedAmount1;
      let vatAmount1 = discountedTotalAmount1 * (vatpercentage1 / 100);
      let totalAmount1 = discountedTotalAmount1 + vatAmount1;

      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
          >
            {totalAmount1}
          </div>
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
    // }
  };
  const renderTotalMedicine = (cellInfo) => {
    // if(procedureData[cellInfo.index].amount){

 
    if (medicineData[cellInfo.index].amount) {
      let vatpercentage1 = medicineData[cellInfo.index].vat;
      let discountperentage1 = medicineData[cellInfo.index].discount;
      let totalAmountwithQuantity =  (medicineData[cellInfo.index].amount*medicineData[cellInfo.index].quantity)
      let discountedAmount1 =
        (totalAmountwithQuantity * discountperentage1) / 100;
      let discountedTotalAmount1 =
      totalAmountwithQuantity - discountedAmount1;
      let vatAmount1 = discountedTotalAmount1 * (vatpercentage1 / 100);
      totalAmount1 = discountedTotalAmount1 + vatAmount1;

      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
          >
            {totalAmount1}
          </div>
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
    // }
  };

  const renderTotallab = (cellInfo) => {
    // if(procedureData[cellInfo.index].amount){

 
    if (labData[cellInfo.index].amount) {
      let vatpercentagelab = labData[cellInfo.index].vat;
      let discountperentagelab = labData[cellInfo.index].discount;

      let discountedAmountlab =
        (labData[cellInfo.index].amount * discountperentagelab) / 100;
      let discountedTotalAmountlab =
      labData[cellInfo.index].amount - discountedAmountlab;
      let vatAmountlab = discountedTotalAmountlab * (vatpercentagelab / 100);
      let totalAmountLab = discountedTotalAmountlab + vatAmountlab;

      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
          >
            {totalAmountLab}
          </div>
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
    // }
  };

  const renderTotalScanPre = (cellInfo) => {
    // if(procedureData[cellInfo.index].amount){

 
    if (scanPreData[cellInfo.index].amount) {
      let vatpercentagelab = scanPreData[cellInfo.index].vat;
      let discountperentagelab = scanPreData[cellInfo.index].discount;

      let discountedAmountlab =
        (scanPreData[cellInfo.index].amount * discountperentagelab) / 100;
      let discountedTotalAmountlab =
      scanPreData[cellInfo.index].amount - discountedAmountlab;
      let vatAmountlab = discountedTotalAmountlab * (vatpercentagelab / 100);
      let totalAmountScanPre = discountedTotalAmountlab + vatAmountlab;

      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
          >
            {totalAmountScanPre}
          </div>
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
    // }
  };

  const renderTotalProPre = (cellInfo) => {
    // if(procedureData[cellInfo.index].amount){

 
    if (proPreData[cellInfo.index].amount) {
      let vatpercentagelab = proPreData[cellInfo.index].vat;
      let discountperentagelab = proPreData[cellInfo.index].discount;

      let discountedAmountlab =
        (proPreData[cellInfo.index].amount * discountperentagelab) / 100;
      let discountedTotalAmountlab =
      proPreData[cellInfo.index].amount - discountedAmountlab;
      let vatAmountlab = discountedTotalAmountlab * (vatpercentagelab / 100);
      let totalAmountProPre = discountedTotalAmountlab + vatAmountlab;

      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
          >
            {totalAmountProPre}
          </div>
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
    // }
  };

  const renderTotalMedPre = (cellInfo) => {
    // if(procedureData[cellInfo.index].amount){

 
    if (medPreData[cellInfo.index].amount) {
      let vatpercentagelab = medPreData[cellInfo.index].vat;
      let discountperentagelab = medPreData[cellInfo.index].discount;
      let dataAmountwithQty = (medPreData[cellInfo.index].amount*medPreData[cellInfo.index].intake_count);
      let discountedAmountlab =
        (dataAmountwithQty * discountperentagelab) / 100;
      let discountedTotalAmountlab =
      dataAmountwithQty - discountedAmountlab;
      let vatAmountlab = discountedTotalAmountlab * (vatpercentagelab / 100);
      let totalAmountMedPre = discountedTotalAmountlab + vatAmountlab;

      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
          >
            {totalAmountMedPre}
          </div>
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
    // }
  };


  const renderAmount = (cellInfo) => {
    // if(procedureData[cellInfo.index].amount){

    if (procedureData[cellInfo.index].amount) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={procedureData[cellInfo.index].amount}
            onBlur={(e) => {
              const procedure = procedureData;
              procedure[cellInfo.index].amount = e.target.innerHTML;

              setProcedureData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: procedureData[cellInfo.index].amount,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };
  const renderScanAmount = (cellInfo) => {
    if (scanData[cellInfo.index].amount) {
      if (scanData[cellInfo.index].amount) {
        return (
          <>
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="form-control test-name-col doc-detail-payment"
              name="date"
              value={scanData[cellInfo.index].amount}
              onBlur={(e) => {
                const procedure = scanData;
                procedure[cellInfo.index].amount = e.target.innerHTML;

                setScanData(procedure);
              }}
              autoComplete="off"
              dangerouslySetInnerHTML={{
                __html: scanData[cellInfo.index].amount,
              }}
            />
          </>
        );
      } else {
        return <h6 className="text-danger">NIL</h6>;
      }
    }
  };
  const renderTestAmount = (cellInfo) => {
    if (testData[cellInfo.index].amount) {
      if (testData[cellInfo.index].amount) {
        return (
          <>
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="form-control test-name-col doc-detail-payment"
              name="date"
              value={testData[cellInfo.index].amount}
              onBlur={(e) => {
                const procedure = testData;
                procedure[cellInfo.index].amount = e.target.innerHTML;

                setTestData(procedure);
              }}
              autoComplete="off"
              dangerouslySetInnerHTML={{
                __html: testData[cellInfo.index].amount,
              }}
            />
          </>
        );
      } else {
        return <h6 className="text-danger">NIL</h6>;
      }
    }
  };

  const renderAmountMisc = (cellInfo) => {
    if (misce[cellInfo.index].amount) {
      if (misce[cellInfo.index].amount) {
        return (
          <>
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="form-control test-name-col doc-detail-payment"
              name="date"
              value={misce[cellInfo.index].amount}
              onBlur={(e) => {
                const procedure = misce;
                procedure[cellInfo.index].amount = e.target.innerHTML;

                setMisc(procedure);
              }}
              autoComplete="off"
              dangerouslySetInnerHTML={{
                __html: misce[cellInfo.index].amount,
              }}
            />
          </>
        );
      } else {
        return <h6 className="text-danger">NIL</h6>;
      }
    }
  };

  const renderRegAmount = (cellInfo) => {
    if (regData[cellInfo.index].amount) {
      if (regData[cellInfo.index].amount) {
        return (
          <>
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="form-control test-name-col doc-detail-payment"
              name="date"
              value={regData[cellInfo.index].amount}
              onBlur={(e) => {
                const procedure = regData;
                procedure[cellInfo.index].amount = e.target.innerHTML;

                setRegData(procedure);
              }}
              autoComplete="off"
              dangerouslySetInnerHTML={{
                __html: regData[cellInfo.index].amount,
              }}
            />
          </>
        );
      } else {
        return <h6 className="text-danger">NIL</h6>;
      }
    }
  };

  
  const renderAmountMedicine = (cellInfo) => {
    if (medicineData[cellInfo.index].amount) {
      if (medicineData[cellInfo.index].amount) {
        return (
          <>
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="form-control test-name-col doc-detail-payment"
              name="date"
              value={medicineData[cellInfo.index].amount}
              onBlur={(e) => {
                const procedure = medicineData;
                procedure[cellInfo.index].amount = e.target.innerHTML;

                setMedicineData(procedure);
              }}
              autoComplete="off"
              dangerouslySetInnerHTML={{
                __html: medicineData[cellInfo.index].amount,
              }}
            />
          </>
        );
      } else {
        return <h6 className="text-danger">NIL</h6>;
      }
    }
  };

    
  const renderAmountLab = (cellInfo) => {
    if (labData[cellInfo.index].amount) {
      if (labData[cellInfo.index].amount) {
        return (
          <>
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="form-control test-name-col doc-detail-payment"
              name="date"
              value={labData[cellInfo.index].amount}
              onBlur={(e) => {
                const procedure = labData;
                procedure[cellInfo.index].amount = e.target.innerHTML;

                setLabData(procedure);
              }}
              autoComplete="off"
              dangerouslySetInnerHTML={{
                __html: labData[cellInfo.index].amount,
              }}
            />
          </>
        );
      } else {
        return <h6 className="text-danger">NIL</h6>;
      }
    }
  };

  const renderAmountScanPre = (cellInfo) => {
    if (scanPreData[cellInfo.index].amount) {
      if (scanPreData[cellInfo.index].amount) {
        return (
          <>
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="form-control test-name-col doc-detail-payment"
              name="date"
              value={scanPreData[cellInfo.index].amount}
              onBlur={(e) => {
                const procedure = scanPreData;
                procedure[cellInfo.index].amount = e.target.innerHTML;

                setScanPreData(procedure);
              }}
              autoComplete="off"
              dangerouslySetInnerHTML={{
                __html: scanPreData[cellInfo.index].amount,
              }}
            />
          </>
        );
      } else {
        return <h6 className="text-danger">NIL</h6>;
      }
    }
  };

  const renderAmountProPre = (cellInfo) => {
    if (proPreData[cellInfo.index].amount) {
      if (proPreData[cellInfo.index].amount) {
        return (
          <>
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="form-control test-name-col doc-detail-payment"
              name="date"
              value={proPreData[cellInfo.index].amount}
              onBlur={(e) => {
                const procedure = proPreData;
                procedure[cellInfo.index].amount = e.target.innerHTML;

                setProPreData(procedure);
              }}
              autoComplete="off"
              dangerouslySetInnerHTML={{
                __html: proPreData[cellInfo.index].amount,
              }}
            />
          </>
        );
      } else {
        return <h6 className="text-danger">NIL</h6>;
      }
    }
  };

  
  const renderAmountMiscPre = (cellInfo) => {
    if (miscPreData[cellInfo.index].amount) {
      if (miscPreData[cellInfo.index].amount) {
        return (
          <>
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="form-control test-name-col doc-detail-payment"
              name="date"
              value={miscPreData[cellInfo.index].amount}
              onBlur={(e) => {
                const procedure = miscPreData;
                procedure[cellInfo.index].amount = e.target.innerHTML;

                setMiscPreData(procedure);
              }}
              autoComplete="off"
              dangerouslySetInnerHTML={{
                __html: miscPreData[cellInfo.index].amount,
              }}
            />
          </>
        );
      } else {
        return <h6 className="text-danger">NIL</h6>;
      }
    }
  };
  const renderAmountMedPre = (cellInfo) => {
    
      if (medPreData[cellInfo.index].amount) {
        return (
          <>
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="form-control test-name-col doc-detail-payment"
              name="date"
              value={medPreData[cellInfo.index].amount}
              onBlur={(e) => {
                const procedure = medPreData;
                procedure[cellInfo.index].amount = e.target.innerHTML;

                setMedPreData(procedure);
              }}
              autoComplete="off"
              dangerouslySetInnerHTML={{
                __html: medPreData[cellInfo.index].amount,
              }}
            />
          </>
        );
      } else {
        return <h6 className="text-danger">NIL</h6>;
      }
    
  };

  const renderQuantityMedPre = (cellInfo) => {
    
    if (medPreData[cellInfo.index].intake_count) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={medPreData[cellInfo.index].intake_count}
            onBlur={(e) => {
              const procedure = medPreData;
              procedure[cellInfo.index].amount = e.target.innerHTML;

              setMedPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: medPreData[cellInfo.index].intake_count,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  
};

  const renderVat = (cellInfo) => {
    //if(procedureData[cellInfo.index].vat){

    if (procedureData[cellInfo.index].vat) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={procedureData[cellInfo.index].vat}
            onBlur={(e) => {
              const procedure = procedureData;
              procedure[cellInfo.index].vat = e.target.innerHTML;

              setProcedureData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: procedureData[cellInfo.index].vat,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };
  const renderTestVat = (cellInfo) => {
    if (testData[cellInfo.index].vat) {
      if (testData[cellInfo.index].vat) {
        return (
          <>
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="form-control test-name-col doc-detail-payment"
              name="date"
              value={testData[cellInfo.index].vat}
              onBlur={(e) => {
                const procedure = testData;
                procedure[cellInfo.index].vat = e.target.innerHTML;

                setTestData(procedure);
              }}
              autoComplete="off"
              dangerouslySetInnerHTML={{
                __html: testData[cellInfo.index].vat,
              }}
            />
          </>
        );
      } else {
        return <h6 className="text-danger">NIL</h6>;
      }
    }
  };

  const renderRegVat = (cellInfo) => {
    // if(scanData[cellInfo.index].vat){

    if (regData[cellInfo.index].vat) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={regData[cellInfo.index].vat}
            onBlur={(e) => {
              const procedure = regData;
              procedure[cellInfo.index].vat = e.target.innerHTML;

              setRegData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: regData[cellInfo.index].vat,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };

  const renderScanVat = (cellInfo) => {
    // if(scanData[cellInfo.index].vat){

    if (scanData[cellInfo.index].vat) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={scanData[cellInfo.index].vat}
            onBlur={(e) => {
              const procedure = scanData;
              procedure[cellInfo.index].vat = e.target.innerHTML;

              setScanData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: scanData[cellInfo.index].vat,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };
  const renderVatMisc = (cellInfo) => {
    // if(scanData[cellInfo.index].vat){

    if (misce[cellInfo.index].vat) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={misce[cellInfo.index].vat}
            onBlur={(e) => {
              const procedure = misce;
              procedure[cellInfo.index].vat = e.target.innerHTML;

              setMisc(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: misce[cellInfo.index].vat,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };

  const renderVatMedicine = (cellInfo) => {
    // if(scanData[cellInfo.index].vat){

    if (medicineData[cellInfo.index].vat) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={medicineData[cellInfo.index].vat}
            onBlur={(e) => {
              const procedure = medicineData;
              procedure[cellInfo.index].vat = e.target.innerHTML;

              setMedicineData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: medicineData[cellInfo.index].vat,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };

  const renderLabVat = (cellInfo) => {
    // if(scanData[cellInfo.index].vat){

    if (labData[cellInfo.index].vat) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={labData[cellInfo.index].vat}
            onBlur={(e) => {
              const procedure = labData;
              procedure[cellInfo.index].vat = e.target.innerHTML;

              setLabData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: labData[cellInfo.index].vat,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };

  const renderScanPreVat = (cellInfo) => {
    // if(scanData[cellInfo.index].vat){

    if (scanPreData[cellInfo.index].vat) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={scanPreData[cellInfo.index].vat}
            onBlur={(e) => {
              const procedure = scanPreData;
              procedure[cellInfo.index].vat = e.target.innerHTML;

              setScanPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: scanPreData[cellInfo.index].vat,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };

  const renderProPreVat = (cellInfo) => {
    // if(scanData[cellInfo.index].vat){

    if (proPreData[cellInfo.index].vat) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={proPreData[cellInfo.index].vat}
            onBlur={(e) => {
              const procedure = proPreData;
              procedure[cellInfo.index].vat = e.target.innerHTML;

              setScanPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: proPreData[cellInfo.index].vat,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };

  const renderMiscPreVat = (cellInfo) => {
    // if(scanData[cellInfo.index].vat){

    if (miscPreData[cellInfo.index].vat) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={miscPreData[cellInfo.index].vat}
            onBlur={(e) => {
              const procedure = miscPreData;
              procedure[cellInfo.index].vat = e.target.innerHTML;

              setMiscPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: miscPreData[cellInfo.index].vat,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };

  
  const renderMedPreVat = (cellInfo) => {
    // if(scanData[cellInfo.index].vat){

    if (medPreData[cellInfo.index].vat) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={medPreData[cellInfo.index].vat}
            onBlur={(e) => {
              const procedure = medPreData;
              procedure[cellInfo.index].vat = e.target.innerHTML;

              setMedPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: medPreData[cellInfo.index].vat,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };


  const renderDiscount = (cellInfo) => {
    // if(procedureData[cellInfo.index].discount){

    if (procedureData[cellInfo.index].discount) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={procedureData[cellInfo.index].discount}
            onBlur={(e) => {
              const procedure = procedureData;
              procedure[cellInfo.index].discount = e.target.innerHTML;

              setProcedureData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: procedureData[cellInfo.index].discount,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };

  const renderRegDiscount = (cellInfo) => {
    // if(procedureData[cellInfo.index].discount){

    if (regData[cellInfo.index].discount) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={regData[cellInfo.index].discount}
            onBlur={(e) => {
              const procedure = regData;
              procedure[cellInfo.index].discount = e.target.innerHTML;

              setRegData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: regData[cellInfo.index].discount,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    // }
  };

  const renderTestDiscount = (cellInfo) => {
    //if(testData[cellInfo.index].discount){

    if (testData[cellInfo.index].discount) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={testData[cellInfo.index].discount}
            onBlur={(e) => {
              const procedure = testData;
              procedure[cellInfo.index].discount = e.target.innerHTML;

              setTestData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: testData[cellInfo.index].discount,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    //}
  };

  const renderScanDiscount = (cellInfo) => {
    //if(scanData[cellInfo.index].discount){

    if (scanData[cellInfo.index].discount) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={scanData[cellInfo.index].discount}
            onBlur={(e) => {
              const procedure = scanData;
              procedure[cellInfo.index].discount = e.target.innerHTML;

              setScanData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: scanData[cellInfo.index].discount,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    //  }
  };

  const renderDiscountMisc = (cellInfo) => {
    //if(scanData[cellInfo.index].discount){

    if (misce[cellInfo.index].discount) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={misce[cellInfo.index].discount}
            onBlur={(e) => {
              const procedure = misce;
              procedure[cellInfo.index].discount = e.target.innerHTML;

              setMisc(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: misce[cellInfo.index].discount,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    //  }
  };
  const renderDiscountMedicine = (cellInfo) => {
    //if(scanData[cellInfo.index].discount){

    if (medicineData[cellInfo.index].discount) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={medicineData[cellInfo.index].discount}
            onBlur={(e) => {
              const procedure = medicineData;
              procedure[cellInfo.index].discount = e.target.innerHTML;

              setMedicineData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: medicineData[cellInfo.index].discount,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    //  }
  };

  const renderDiscountLab = (cellInfo) => {
    //if(scanData[cellInfo.index].discount){

    if (labData[cellInfo.index].discount) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={labData[cellInfo.index].discount}
            onBlur={(e) => {
              const procedure = labData;
              procedure[cellInfo.index].discount = e.target.innerHTML;

              setLabData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: labData[cellInfo.index].discount,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    //  }
  };

  const renderDiscountScanpre = (cellInfo) => {
    //if(scanData[cellInfo.index].discount){

    if (scanPreData[cellInfo.index].discount) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={scanPreData[cellInfo.index].discount}
            onBlur={(e) => {
              const procedure = scanPreData;
              procedure[cellInfo.index].discount = e.target.innerHTML;

              setScanPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: scanPreData[cellInfo.index].discount,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    //  }
  };

  const renderDiscountPropre = (cellInfo) => {
    //if(scanData[cellInfo.index].discount){

    if (proPreData[cellInfo.index].discount) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={proPreData[cellInfo.index].discount}
            onBlur={(e) => {
              const procedure = proPreData;
              procedure[cellInfo.index].discount = e.target.innerHTML;

              setProPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: proPreData[cellInfo.index].discount,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    //  }
  };
  const renderDiscountMedpre = (cellInfo) => {
    //if(scanData[cellInfo.index].discount){

    if (medPreData[cellInfo.index].discount) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={medPreData[cellInfo.index].discount}
            onBlur={(e) => {
              const procedure = medPreData;
              procedure[cellInfo.index].discount = e.target.innerHTML;

              setMedPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: medPreData[cellInfo.index].discount,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }

    //  }
  };


  const renderDate = (cellInfo) => {
    if (procedureData[cellInfo.index].status) {
      if (procedureData[cellInfo.index].date) {
        return (
          <>
            <div
              style={{ backgroundColor: "#fafafa" }}
              className="form-control test-name-col doc-detail-payment"
              name="date"
              value={moment
                .utc(procedureData[cellInfo.index].date)
                .local()
                .format("DD-MM-YYYY")}
              onBlur={(e) => {
                const procedure = procedureData;
                procedure[cellInfo.index].date = e.target.innerHTML;

                setProcedureData(procedure);
              }}
              autoComplete="off"
              dangerouslySetInnerHTML={{
                __html: moment
                  .utc(procedureData[cellInfo.index].date)
                  .local()
                  .format("DD-MM-YYYY"),
              }}
            />
          </>
        );
      }

      //  <DatePicker
      //               format='dd-MM-yyyy'
      //               value={new Date(config[cellInfo.index].date)}
      //               onChange={date=>{handleDate(date,cellInfo.index)}}
      //               clearIcon={null}
      //               //  minDate = {new Date()}

      //          />
      else {
        return <h6 className="text-danger">NIL</h6>;
      }
    }
  };

  const renderProcedureName = (cellInfo) => {
    if (procedureData[cellInfo.index].sub_service) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={procedureData[cellInfo.index].sub_service}
            onBlur={(e) => {
              const procedure = procedureData;
              procedure[cellInfo.index].sub_service = e.target.innerHTML;

              setProcedureData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: procedureData[cellInfo.index].sub_service,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };

  const renderRegName = (cellInfo) => {
    if (regData[cellInfo.index].sub_service) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={regData[cellInfo.index].sub_service}
            onBlur={(e) => {
              const procedure = regData;
              procedure[cellInfo.index].sub_service = e.target.innerHTML;

              setRegData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: regData[cellInfo.index].sub_service,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };
  const renderMiscName = (cellInfo) => {
    if (misce[cellInfo.index].sub_service) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={misce[cellInfo.index].sub_service}
            onBlur={(e) => {
              const procedure = misce;
              procedure[cellInfo.index].sub_service = e.target.innerHTML;

              setMisc(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: misce[cellInfo.index].sub_service,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };
  const renderScanName = (cellInfo) => {
    if (scanData[cellInfo.index].sub_service) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={scanData[cellInfo.index].sub_service}
            onBlur={(e) => {
              const procedure = scanData;
              procedure[cellInfo.index].sub_service = e.target.innerHTML;

              setScanData(procedure);
            }}
            autoComplete="off"
            e
            dangerouslySetInnerHTML={{
              __html: scanData[cellInfo.index].sub_service,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };

  const renderTestName = (cellInfo) => {
    if (testData[cellInfo.index].sub_service) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={testData[cellInfo.index].sub_service}
            onBlur={(e) => {
              const procedure = testData;
              procedure[cellInfo.index].sub_service = e.target.innerHTML;

              setTestData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: testData[cellInfo.index].sub_service,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };
  const renderMedicineName = (cellInfo) => {
    if (medicineData[cellInfo.index].sub_service) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={medicineData[cellInfo.index].sub_service}
            onBlur={(e) => {
              const procedure = medicineData;
              procedure[cellInfo.index].sub_service = e.target.innerHTML;

              setTestData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: medicineData[cellInfo.index].sub_service,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };

  const renderMedicineQuantity = (cellInfo) => {
    if (medicineData[cellInfo.index].quantity) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={medicineData[cellInfo.index].quantity}
            onBlur={(e) => {
              const procedure = medicineData;
              procedure[cellInfo.index].quantity = e.target.innerHTML;

              setTestData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: medicineData[cellInfo.index].quantity,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };

  const renderLabName = (cellInfo) => {
    if (labData[cellInfo.index].schedule_name) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={labData[cellInfo.index].schedule_name}
            onBlur={(e) => {
              const procedure = labData;
              procedure[cellInfo.index].schedule_name = e.target.innerHTML;

              setLabData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: labData[cellInfo.index].schedule_name,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };
  const renderScanPreName = (cellInfo) => {
    if (scanPreData[cellInfo.index].schedule_name) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={scanPreData[cellInfo.index].schedule_name}
            onBlur={(e) => {
              const procedure = scanPreData;
              procedure[cellInfo.index].schedule_name = e.target.innerHTML;

              setScanPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: scanPreData[cellInfo.index].schedule_name,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };
  const renderProPreName = (cellInfo) => {
    if (proPreData[cellInfo.index].schedule_name) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={proPreData[cellInfo.index].schedule_name}
            onBlur={(e) => {
              const procedure = proPreData;
              procedure[cellInfo.index].schedule_name = e.target.innerHTML;

              setProPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: proPreData[cellInfo.index].schedule_name,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };
  const renderMiscPreName = (cellInfo) => {
    if (miscPreData[cellInfo.index].schedule_name) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={miscPreData[cellInfo.index].schedule_name}
            onBlur={(e) => {
              const procedure = miscPreData;
              procedure[cellInfo.index].schedule_name = e.target.innerHTML;

              setMiscPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: miscPreData[cellInfo.index].schedule_name,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };

  const renderMedPreName = (cellInfo) => {
    if (medPreData[cellInfo.index].schedule_name) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={medPreData[cellInfo.index].schedule_name}
            onBlur={(e) => {
              const procedure = medPreData;
              procedure[cellInfo.index].schedule_name = e.target.innerHTML;

              setMedPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: medPreData[cellInfo.index].schedule_name,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };


  const renderDoctorName = (cellInfo) => {
    if (labData[cellInfo.index].doctor_name) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={labData[cellInfo.index].doctor_name}
            onBlur={(e) => {
              const procedure = labData;
              procedure[cellInfo.index].doctor_name = e.target.innerHTML;

              setLabData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: labData[cellInfo.index].doctor_name,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };

  const renderDoctorNameScan = (cellInfo) => {
    if (scanPreData[cellInfo.index].doctor_name) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={scanPreData[cellInfo.index].doctor_name}
            onBlur={(e) => {
              const procedure = scanPreData;
              procedure[cellInfo.index].doctor_name = e.target.innerHTML;

              setScanPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: scanPreData[cellInfo.index].doctor_name,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };

  const renderDoctorNamePro = (cellInfo) => {
    if (proPreData[cellInfo.index].doctor_name) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={proPreData[cellInfo.index].doctor_name}
            onBlur={(e) => {
              const procedure = proPreData;
              procedure[cellInfo.index].doctor_name = e.target.innerHTML;

              setProPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: proPreData[cellInfo.index].doctor_name,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };

  const renderDoctorNameMisc = (cellInfo) => {
    if (miscPreData[cellInfo.index].doctor_name) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={miscPreData[cellInfo.index].doctor_name}
            onBlur={(e) => {
              const procedure = miscPreData;
              procedure[cellInfo.index].doctor_name = e.target.innerHTML;

              setMiscPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: miscPreData[cellInfo.index].doctor_name,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };
  const renderDoctorNameMed = (cellInfo) => {
    if (medPreData[cellInfo.index].doctor_name) {
      return (
        <>
          <div
            style={{ backgroundColor: "#fafafa" }}
            className="form-control test-name-col doc-detail-payment"
            name="date"
            value={medPreData[cellInfo.index].doctor_name}
            onBlur={(e) => {
              const procedure = medPreData;
              procedure[cellInfo.index].doctor_name = e.target.innerHTML;

              setMedPreData(procedure);
            }}
            autoComplete="off"
            dangerouslySetInnerHTML={{
              __html: medPreData[cellInfo.index].doctor_name,
            }}
          />
        </>
      );
    } else {
      return <h6 className="text-danger">NIL</h6>;
    }
  };




  const deleteRowMisc = (index) => {
    const data = misce;

    const filteredIndex = data.filter((item, pos) => {
      return pos !== index;
    });

    // setConfig(filtered)
    setMisc(filteredIndex);
  };
  const deleteRowMedicine = (index) => {
    const data = medicineData;

    const filteredIndex = data.filter((item, pos) => {
      return pos !== index;
    });

    // setConfig(filtered)
    setMedicineData(filteredIndex);
  };
  const deleteRowlab = (index) => {
    const data = labData;

    const filteredIndex = data.filter((item, pos) => {
      return pos !== index;
    });

    // setConfig(filtered)
    setLabData(filteredIndex);
  };

  const deleteRowScanPre = (index) => {
    const data = scanPreData;

    const filteredIndex = data.filter((item, pos) => {
      return pos !== index;
    });

    // setConfig(filtered)
    setScanPreData(filteredIndex);
  };
  const deleteRowProPre = (index) => {
    const data = proPreData;

    const filteredIndex = data.filter((item, pos) => {
      return pos !== index;
    });

    // setConfig(filtered)
    setProPreData(filteredIndex);
  };

  const deleteRowMedPre = (index) => {
    const data = medPreData;

    const filteredIndex = data.filter((item, pos) => {
      return pos !== index;
    });

    // setConfig(filtered)
    setMedPreData(filteredIndex);
  };
  const deleteRowMiscPre = (index) => {
    const data = miscPreData;

    const filteredIndex = data.filter((item, pos) => {
      return pos !== index;
    });

    // setConfig(filtered)
    setMiscPreData(filteredIndex);
  };

  const deleteRowProcedure = (index) => {
    const data = procedureData;

    const filteredIndex = data.filter((item, pos) => {
      return pos !== index;
    });

    // setConfig(filtered)
    setProcedureData(filteredIndex);
  };
  const deleteRowScan = (index) => {
    const data = scanData;

    const filteredIndex = data.filter((item, pos) => {
      return pos !== index;
    });

    // setConfig(filtered)
    setScanData(filteredIndex);
  };
  const deleteRowTest = (index) => {
    const data = testData;

    const filteredIndex = data.filter((item, pos) => {
      return pos !== index;
    });

    // setConfig(filtered)
    setTestData(filteredIndex);
  };

  const deleteRowReg = (index) => {
    const data = regData;

    const filteredIndex = data.filter((item, pos) => {
      return pos !== index;
    });

    // setConfig(filtered)
    setRegData(filteredIndex);
  };
  const columnsMedicine = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
          <div style={{marginLeft:"25%"}}>
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteRowMedicine(props.index);
            }}
          ></i>
          </div>
        );
      },
      width: 60,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Medicine Name",
      Cell: renderMedicineName,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 200,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Quantity",
      Cell: renderMedicineQuantity,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 100,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "Amount",
      Cell: renderAmountMedicine,
      sortable: false,
      width: 150,
    },
    {
      Header: "Vat%",
      Cell: renderVatMedicine,
      sortable: false,
      width: 120,
    },
    {
      Header: "Discount%",
      accessor: "discount",
      Cell: renderDiscountMedicine,
      sortable: false,
      width: 120,
    },
    {
      Header: "Total",
      // accessor: (row) => row.amount + row.vat - row.discount,
      Cell: renderTotalMedicine,
      //Cell: renderTotal,
      sortable: false,
      width: 120,
    },
    // {
    //   Header: "Date",
    //   Cell: renderDate,

    //   width:140,
    //   minWidth:100,
    //    sortable:false

    // },
  ];


  const columnsLab = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
          <div style={{marginLeft:"25%"}}>
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteRowlab(props.index);
            }}
          ></i>
          </div>
        );
      },
      width: 60,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Test Name",
      Cell: renderLabName,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Prescribed BY",
      Cell: renderDoctorName,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "Amount",
      Cell: renderAmountLab,
      sortable: false,
      width: 150,
    },
    {
      Header: "Vat%",
      Cell: renderLabVat,
      sortable: false,
      width: 120,
    },
    {
      Header: "Discount%",
      accessor: "discount",
      Cell: renderDiscountLab,
      sortable: false,
      width: 120,
    },
    {
      Header: "Total",
      // accessor: (row) => row.amount + row.vat - row.discount,
      Cell: renderTotallab,
      //Cell: renderTotal,
      sortable: false,
      width: 120,
    },

  ];

  const columnsScanPre = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
          <div style={{marginLeft:"25%"}}>
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteRowScanPre(props.index);
            }}
          ></i>
          </div>
        );
      },
      width: 60,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Scan Name",
      Cell: renderScanPreName,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Prescribed BY",
      Cell: renderDoctorNameScan,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "Amount",
      Cell: renderAmountScanPre,
      sortable: false,
      width: 150,
    },
    {
      Header: "Vat%",
      Cell: renderScanPreVat,
      sortable: false,
      width: 120,
    },
    {
      Header: "Discount%",
      accessor: "discount",
      Cell: renderDiscountScanpre,
      sortable: false,
      width: 120,
    },
    {
      Header: "Total",
      // accessor: (row) => row.amount + row.vat - row.discount,
      Cell: renderTotalScanPre,
      //Cell: renderTotal,
      sortable: false,
      width: 120,
    },

  ];


  const columnsMedPre = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
          <div style={{marginLeft:"25%"}}>
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteRowMedPre(props.index);
            }}
          ></i>
            </div>
        );
      },
      width: 50,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Medicine Name",
      Cell: renderMedPreName,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 180,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Prescribed BY",
      Cell: renderDoctorNameMed,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 180,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "Amount",
      Cell: renderAmountMedPre,
      sortable: false,
      width: 100,
    },
    {
      Header: "Qty",
      Cell: renderQuantityMedPre,
      sortable: false,
      width: 60,
    },
    {
      Header: "Vat%",
      Cell: renderMedPreVat,
      sortable: false,
      width: 100,
    },
    {
      Header: "Discount%",
      accessor: "discount",
      Cell: renderDiscountMedpre,
      sortable: false,
      width: 100,
    },
    {
      Header: "Total",
      // accessor: (row) => row.amount + row.vat - row.discount,
      Cell: renderTotalMedPre,
      //Cell: renderTotal,
      sortable: false,
      width: 100,
    },

  ];


  const columnsProPre = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
          <div style={{marginLeft:"25%"}}>
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteRowProPre(props.index);
            }}
          ></i>
          </div>
        );
      },
      width: 60,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Procedure Name",
      Cell: renderProPreName,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,
      minWidth: 100,
      sortable: false,
    },
    {
      Header: "Prescribed BY",
      Cell: renderDoctorNamePro,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "Amount",
      Cell: renderAmountProPre,
      sortable: false,
      width: 150,
    },
    {
      Header: "Vat%",
      Cell: renderProPreVat,
      sortable: false,
      width: 120,
    },
    {
      Header: "Discount%",
      accessor: "discount",
      Cell: renderDiscountPropre,
      sortable: false,
      width: 120,
    },
    {
      Header: "Total",
      // accessor: (row) => row.amount + row.vat - row.discount,
      Cell: renderTotalProPre,
      //Cell: renderTotal,
      sortable: false,
      width: 120,
    },

  ];

 




  const columnsProcedure = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
          <div style={{marginLeft:"25%"}}>
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteRowProcedure(props.index);
            }}
          ></i>
          </div>
        );
      },
      width: 60,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Procedure Name",
      Cell: renderProcedureName,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "Amount",
      Cell: renderAmount,
      sortable: false,
      width: 150,
    },
    {
      Header: "Vat%",
      Cell: renderVat,
      sortable: false,
      width: 120,
    },
    {
      Header: "Discount%",
      accessor: "discount",
      Cell: renderDiscount,
      sortable: false,
      width: 120,
    },
    {
      Header: "Total",
      // accessor: (row) => row.amount + row.vat - row.discount,
      Cell: renderTotal,
      //Cell: renderTotal,
      sortable: false,
      width: 120,
    },
    // {
    //   Header: "Date",
    //   Cell: renderDate,

    //   width:140,
    //   minWidth:100,
    //    sortable:false

    // },
  ];
  const columnMisc = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
          <div style={{marginLeft:"25%"}}>
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteRowMisc(props.index);
            }}
          ></i>
          </div>
        );
      },
      width: 60,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Name",
      Cell: renderMiscName,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "Amount",
      Cell: renderAmountMisc,
      sortable: false,
      width: 150,
    },
    {
      Header: "Vat%",
      Cell: renderVatMisc,
      sortable: false,
      width: 120,
    },
    {
      Header: "Discount%",
      accessor: "discount",
      Cell: renderDiscountMisc,
      sortable: false,
      width: 120,
    },
    {
      Header: "Total",
      // accessor: (row) => row.amount + row.vat - row.discount,
      Cell: renderTotalMisc,
      //Cell: renderTotal,
      sortable: false,
      width: 120,
    },
    // {
    //   Header: "Date",
    //   Cell: renderDate,

    //   width:140,
    //   minWidth:100,
    //    sortable:false

    // },
  ];
  const columnsScan = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
          <div style={{marginLeft:"25%"}}>
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteRowScan(props.index);
            }}
          ></i>
          </div>
        );
      },
      width: 60,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Scan Name",
      Cell: renderScanName,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "Amount",
      Cell: renderScanAmount,
      sortable: false,
      width: 150,
    },
    {
      Header: "Vat%",
      Cell: renderScanVat,
      sortable: false,
      width: 120,
    },
    {
      Header: "Discount%",
      Cell: renderScanDiscount,
      sortable: false,
      width: 120,
    },

    {
      Header: "Total",
      // accessor: (row) => row.amount + row.vat - row.discount,
      Cell: renderTotalScan,
      //Cell: renderTotal,
      sortable: false,
      width: 120,
    },
  ];
  const columnsTest = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
        <div style={{marginLeft:"25%"}}>
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteRowTest(props.index);
            }}
          ></i>
          </div>
        );
      },
      width: 60,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Test Name",
      Cell: renderTestName,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "Amount",
      Cell: renderTestAmount,
      sortable: false,
      width: 150,
    },
    {
      Header: "Vat%",
      Cell: renderTestVat,
      sortable: false,
      width: 120,
    },
    {
      Header: "Discount%",
      Cell: renderTestDiscount,
      sortable: false,
      width: 120,
    },

    {
      Header: "Total",
      // accessor: (row) => row.amount + row.vat - row.discount,
      Cell: renderTotalTest,
      //Cell: renderTotal,
      sortable: false,
      width: 120,
    },
  ];

  const columnReg = [
    {
      Header: <i className="fas fa-trash-alt"></i>,
      Cell: (props) => {
        return (
          <div style={{marginLeft:"25%"}}>
          <i
            className="fas fa-trash-alt trash-btn"
            onClick={() => {
              deleteRowReg(props.index);
            }}
          ></i>
          </div>
        );
      },
      width: 60,
      sortable: false,
      filterable: false,
    },
    {
      Header: "Registration",
      Cell: renderRegName,
      style: {
        backgroundColor: "rgba(0,0,0,0.1)",
      },
      width: 250,
      minWidth: 100,
      sortable: false,
    },

    {
      Header: "Amount",
      Cell: renderRegAmount,
      sortable: false,
      width: 150,
    },
    {
      Header: "Vat%",
      Cell: renderRegVat,
      sortable: false,
      width: 120,
    },
    {
      Header: "Discount%",
      Cell: renderRegDiscount,
      sortable: false,
      width: 120,
    },

    {
      Header: "Total",
      // accessor: (row) => row.amount + row.vat - row.discount,
      Cell: renderTotalReg,
      //Cell: renderTotal,
      sortable: false,
      width: 120,
    },
  ];

  const handleClick = (item) => {

    initialRender.current = true;
    setPatientID(item.id);
    setSearchTerm(item.full_name);
    setPatientName(item.full_name)
    setPatientDetails(item);
    setDisplay(false);
   setRefresh(true);
    setLiability([]);
    setPage(1)
    liability(item.id,1);

    setProcedureData([]);
    setScanData([]);
    setTestData([]);
    setMisc([]);
    setMedPreData([]);
    setLabData([]);
    setProPreData([]);
    setScanPreData([]);

    //setAllData([])
  };

  return (
    <>
      {/* <HospBdoNavbar /> */}
      {/* <div className="flex-head">       
            <button className="btn btn-info topleft-single-btn" onClick={handleBack}> <i className="fas fa-chevron-left back-btn-icon"></i>Back</button> 
            <h2 className=" title-of-tasks title-mg"> Payments </h2><br/>
        </div> 

        <br/> */}
      <div className="pad-payment">
        <div className="payment-page">
          <div className="payment-display">
            {/* {
  patientName ?
  <h5 className='selected-display-payment'>Selected Patient : {patientName}</h5> : null
} */}
            <div className="payment-search-grid">
              <div className="flex-col justify-content-start">
                <h5 className="title-of-tasks">Search Patient</h5>
                <div style={{ width: "100%" }} className="flex-row">
                  <input
                    style={{ width: "70%" }}
                    className="form-control"
                    type="text"
                    maxLength="100"
                    placeholder="Enter Name"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />

                  <button
                    disabled={searchTerm === ""}
                    onClick={handleSearch}
                    className="btn btn-primary btn-col search-btn"
                  >
                    {" "}
                    <i className="fas fa-search"></i>{" "}
                  </button>
                </div>
                {display ? (
                  <div className="suggest-display adjust-dimensions">
                    {patient.length > 0 ? (
                      patient.map((item) => {
                        return (
                          <p
                            onClick={() => handleClick(item)}
                            className="suggest-item text-secondary"
                          >
                            {item.full_name}
                          </p>
                        );
                      })
                    ) : (
                      <h6 className="text-muted">No Patients found !!</h6>
                    )}
                  </div>
                ) : null}
              </div>

              <div className="detail-input-grid">
                <input
                  placeholder="Name"
                  type="text"
                  value={patientDetails.full_name}
                  className="form-control"
                />
                <input
                  placeholder="Mobile Number"
                  type="text"
                  value={patientDetails.mobile_number}
                  className="form-control"
                />
                <input
                  placeholder="Address"
                  type="text"
                  value={patientDetails.city}
                  className="form-control"
                />
                <input
                  placeholder="Gender"
                  type="text"
                  value={patientDetails.gender}
                  className="form-control"
                />
              </div>

              <div style={{width:"100%",display:"flex" ,marginTop:"-22%"}}>
                <button
                  onClick={() => {
                    patientDetails.id !== ""
                      ? setPastShow(true) 
                      : setValidateErrorShow(true);
                  }}
                  className="btn btn-primary btn-col btn-small"
                >
                  Past Payments
                </button>
                <button
                  onClick={() => {
                    patientDetails.id !== ""
                      ? setModalShow(true)
                      : setValidateErrorShow(true);
                  }}
                  className="btn btn-primary btn-col btn-small ml-2"
                >
                  + Payment
                </button><br/>
                {/* <button
                  onClick={() => {
                    patientDetails.id !== ""
                      ? setDisplay1(true)
                      : setValidateErrorShow(true);
                  }}
                  className="btn btn-primary btn-col btn-small ml-2"
                >
                  Liabilities
                </button> */}
              </div>
            </div>
          </div>



        

          {display1 ? display2 : ""}


          {scanPreData.length > 0 ? (
            <div className="flex-row">
              <ReactTable
                columns={columnsScanPre}
                data={scanPreData}
                defaultPageSize={5}
                resizable
                className="payment-react-table"
              ></ReactTable>
            </div>
          ) : (
            ""
          )}
        
          {scanPreData.length > 0 ? (
            <div className="form-button" style={{marginTop:"1%"}}>
              <button
                disabled={scanPreData.length < 1}
                onClick={handleGenerateScanPre}
                className="btn btn-primary btn-col"
              >
                Generate Bill
              </button>
            </div>
          ) : (
            ""
          )}

          
{medPreData.length > 0 ? (
            <div className="flex-row">
              <ReactTable
                columns={columnsMedPre}
                data={medPreData}
                defaultPageSize={5}
                resizable
                className="payment-react-table"
              ></ReactTable>
            </div>
          ) : (
            ""
          )}
        
          {medPreData.length > 0 ? (
            <div className="form-button" style={{marginTop:"1%"}}>
              <button
                disabled={medPreData.length < 1}
                onClick={handleGenerateMedPre}
                className="btn btn-primary btn-col"
              >
                Generate Bill
              </button>
            </div>
          ) : (
            ""
          )}






{proPreData.length > 0 ? (
            <div className="flex-row">
              <ReactTable
                columns={columnsProPre}
                data={proPreData}
                defaultPageSize={5}
                resizable
                className="payment-react-table"
              ></ReactTable>
            </div>
          ) : (
            ""
          )}
        
          {proPreData.length > 0 ? (
            <div className="form-button" style={{marginTop:"1%"}}>
              <button
                disabled={proPreData.length < 1}
                onClick={handleGenerateProPre}
                className="btn btn-primary btn-col"
              >
                Generate Bill
              </button>
            </div>
          ) : (
            ""
          )}



          {labData.length > 0 ? (
            <div className="flex-row">
              <ReactTable
                columns={columnsLab}
                data={labData}
                defaultPageSize={5}
                resizable
                className="payment-react-table"
              ></ReactTable>
            </div>
          ) : (
            ""
          )}
        
          {labData.length > 0 ? (
            <div className="form-button" style={{marginTop:"1%"}}>
              <button
                disabled={labData.length < 1}
                onClick={handleGenerateLab}
                className="btn btn-primary btn-col"
              >
                Generate Bill
              </button>
            </div>
          ) : (
            ""
          )}

          
          {medicineData.length > 0 ? (
            <div className="flex-row">
              <ReactTable
                columns={columnsMedicine}
                data={medicineData}
                defaultPageSize={5}
                resizable
                className="payment-react-table"
              ></ReactTable>
            </div>
          ) : (
            ""
          )}
        
          {medicineData.length > 0 ? (
            <div className="form-button" style={{marginTop:"1%"}}>
              <button
                disabled={medicineData.length < 1}
                onClick={handleGenerateMedicine}
                className="btn btn-primary btn-col"
              >
                Generate Bill
              </button>
            </div>
          ) : (
            ""
          )}
         
     


          {procedureData.length > 0 ? (
            <div className="flex-row">
              <ReactTable
                columns={columnsProcedure}
                data={procedureData}
                defaultPageSize={5}
                resizable
                className="payment-react-table"
              ></ReactTable>
            </div>
          ) : (
            ""
          )}
      
          {procedureData.length > 0 ? (
            <div className="form-button" style={{marginTop:"1%"}}>
              <button
                disabled={procedureData.length < 1}
                onClick={handleGeneratePro}
                className="btn btn-primary btn-col"
              >
                Generate Bill
              </button>
            </div>
          ) : (
            ""
          )}
       
       

          {scanData.length > 0 ? (
            <div className="flex-row">
              <ReactTable
                columns={columnsScan}
                data={scanData}
                defaultPageSize={5}
                resizable
                className="payment-react-table"
              ></ReactTable>
            </div>
          ) : (
            ""
          )}
       
          {scanData.length > 0 ? (
            <div className="form-button" style={{marginTop:"1%"}}>
              <button
                disabled={scanData.length < 1}
                onClick={handleGenerateScan}
                className="btn btn-primary btn-col"
              >
                Generate Bill
              </button>
            </div>
          ) : (
            ""
          )}
         
     

          {testData.length > 0 ? (
            <div className="flex-row">
              <ReactTable
                columns={columnsTest}
                data={testData}
                defaultPageSize={5}
                resizable
                className="payment-react-table"
              ></ReactTable>
            </div>
          ) : (
            ""
          )}
    
          {testData.length > 0 ? (
            <div className="form-button" style={{marginTop:"1%"}}>
              <button
                disabled={testData.length < 1}
                onClick={handleGenerateTest}
                className="btn btn-primary btn-col"
              >
                Generate Bill
              </button>
            </div>
          ) : (
            ""
          )}
    
         {regData.length > 0 ? (
            <div className="flex-row">
              <ReactTable
                columns={columnReg}
                data={regData}
                defaultPageSize={5}
                resizable
                className="payment-react-table"
              ></ReactTable>
            </div>
          ) : (
          ""
          )}
            {regData.length > 0 ? (
            <div className="form-button" style={{marginTop:"1%"}}>
              <button
                disabled={regData.length < 1}
                onClick={handleGenerateReg}
                className="btn btn-primary btn-col"
              >
                Generate Bill
              </button>
            </div>
          ) : (
            ""
          )}
      
      
          {misce.length > 0 ? (
            <div className="flex-row" style={{marginTop:"2%"}}>
              <ReactTable
                columns={columnMisc}
                data={misce}
                defaultPageSize={5}
                resizable
                className="payment-react-table"
              ></ReactTable>
            </div>
          ) : (
            ""
          )}
    
          {misce.length > 0 ? (
            <div className="form-button" style={{marginTop:"1%"}}>
              <button
                disabled={misce.length < 1}
                onClick={handleGenerateMisc}
                className="btn btn-primary btn-col"
              >
                Generate Bill
              </button>
            </div>
          ) : (
            ""
          )}
          <br />
      
        </div>
      </div>

      {modalShow ? (
        <ConfigPopUp
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
      ) : null}

      {invoiceShow ? (
        <InvoicePopup
          show={invoiceShow}
          onHide={() => {
            setInvoiceShow(false);
            //setRerender(!reRender)
          }}
        />
      ) : null}
      {invoiceShow1 ? (
        <InvoicePopup1
          show={invoiceShow1}
          onHide={() => {
            setInvoiceShow1(false);
            //setRerender(!reRender)
          }}
        />
      ) : null}
      {invoiceShow2 ? (
        <InvoicePopup2
          show={invoiceShow2}
          onHide={() => {
            setInvoiceShow2(false);
            //setRerender(!reRender)
          }}
        />
      ) : null}

      {invoiceShow3 ? (
        <InvoicePopup3
          show={invoiceShow3}
          onHide={() => {
            setInvoiceShow3(false);
            //setRerender(!reRender)
          }}
        />
      ) : null}

      {invoiceShow5 ? (
        <InvoicePopup5
          show={invoiceShow5}
          onHide={() => {
            setInvoiceShow5(false);
            //setRerender(!reRender)
          }}
        />
      ) : null}
          {invoiceShow6 ? (
        <InvoicePopup6
          show={invoiceShow6}
          onHide={() => {
            setInvoiceShow6(false);
            //setRerender(!reRender)
          }}
        />
      ) : null}
                {invoiceShow8 ? (
        <InvoicePopup8
          show={invoiceShow8}
          onHide={() => {
            setInvoiceShow8(false);
            //setRerender(!reRender)
          }}
        />
      ) : null}
                      {invoiceShow9 ? (
        <InvoicePopup9
          show={invoiceShow9}
          onHide={() => {
            setInvoiceShow9(false);
            //setRerender(!reRender)
          }}
        />
      ) : null}
                      {invoiceShow10 ? (
        <InvoicePopup10
          show={invoiceShow10}
          onHide={() => {
            setInvoiceShow10(false);
            //setRerender(!reRender)
          }}
        />
      ) : null}
                      {invoiceShow11 ? (
        <InvoicePopup11
          show={invoiceShow11}
          onHide={() => {
            setInvoiceShow11(false);
            //setRerender(!reRender)
          }}
        />
      ) : null}

{invoiceShow20 ? (
        <InvoicePopup20
          show={invoiceShow20}
          onHide={() => {
            setInvoiceShow20(false);
            //setRerender(!reRender)
          }}
        />
      ) : null}

      {pastShow ? (
        <PastPaymentPopup
          show={pastShow}
          onHide={() => {
            setPastShow(false);
            //setRerender(!reRender)
          }}
        />
      ) : null}

      {errorShow ? (
        <ErrorPopup
          show={errorShow}
          onHide={() => {
            setErrorShow(false);
            //setTableData([]);
            //setRerender(!reRender)
          }}
        />
      ) : (
        ""
      )}

      {validateErrorShow ? (
        <ValidateErrorPopup
          show={validateErrorShow}
          onHide={() => setValidateErrorShow(false)}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Payments;
