import React, { useEffect, useState, useContext } from "react";
import { Link, useParams, useLocation,useNavigate } from "react-router-dom";

function PatientNavigation() {
    const location = useLocation();
    const id = location.pathname.match(/\/id\/([0-9]+)/)[1];
    const mob = location.pathname.split('/').pop()
    const navigate = useNavigate();
    useEffect(() => {
      
          navigate(`/patient-record/id/${id}/mob/${mob}`,);
       
      }, [id]);
   
   
return(
  <div></div>
)
}
export default PatientNavigation;