import React, { useState, useEffect, useContext } from 'react';
import './therapy.css';
import axios from "axios";
import { APIURL } from "../../Global";
import { useAlert } from 'react-alert';
import AddTherapySlots from './AddTherapySlots';
import { IoIosArrowBack } from "react-icons/io";
import SubmitConfirmationPopup from "../common/SubmitConfirm";
import { ConfirmationContext } from '../contexts/SubmitConfirmationContext';
import moment from 'moment';
function AddTherapy({ selected, setSelected, patientId, name }) {
    const userData = JSON.parse(sessionStorage.getItem("logindatas"));
    const alliedId = userData.allied_id;
    const [showConfirmation, setShowConfirmation] = useContext(ConfirmationContext);
    const [show, setShow] = useState(false);
    const [slot, setSlot] = useState({});
    const [date, setDate] = useState('')
    const [next, setNext] = useState('')
    const alert = useAlert();

    const onSave = (date, slot) => {
        if (next.date) {
            setShowConfirmation(true)
        }
        setDate(moment(date).format('DD/MM/YYYY'))
        setSlot(slot);
        setShow(false);
        console.log(slot)
    };

    const onClose = () => {
        setSlot({});
        setShow(false);
    };

    useEffect(() => {
        therapy()
    }, [])

    const therapy = async () => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");


        const date = moment(new Date()).format('YYYY-MM-DD')
        const patient_id = patientId


        try {
            const nextRes = await axios.get(`${APIURL}/api/v1/patient/therapy-consultation/?next=True&date=${date}&patient_id=${patient_id}`, {
                headers: {
                    Authorization: "Token " + str,
                    "Content-Type": "application/json",
                },
                // params: payload
            });

            if (nextRes.data.status === "success") {
                setSlot({})
                setNext(nextRes.data.message);
            } else {
            }
        } catch (err) {
            alert.error('An error occurred while fetching therapy consultation data.');
        }
    };

    const handleConfirm = () => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");
        const payload = {
            booking_id: next.appt_id,
            remarks: `Rescheduled by allied professional ${name}`
        }
        axios
            .put(`${APIURL}/api/v1/service-provider/allied-booking-detail/?cancel=True`, payload, {
                headers: {
                    Authorization: "Token " + str,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {

                if (res.data.status === "success") {
                    handleSave()
                }
                else {
                    alert.error(res.data.message)
                }
            })
            .catch((err) => {
            });
    }

    const handleCancel = () => {
        setShowConfirmation(false);
    }

    const handleSave = async () => {
        if (slot.id === '' || slot.id === undefined || slot.id === null) {
            alert.error('Pick a slot')
        }
        else {

            const tokenString = sessionStorage.getItem("usertoken");
            let str = tokenString.replace(/["]+/g, '');
            const formattedDate = moment(date, "DD/MM/YYYY").format('YYYY-MM-DD');
            const patient_id = patientId
            const payload = {
                date: formattedDate,
                slot: slot.id,
                schedule_id: slot.schedule_id,
                allied_id: alliedId
            };

            try {
                const res = await axios.post(`${APIURL}/api/v1/patient/therapy-consultation/?patient_id=${patient_id}`, payload, {
                    headers: {
                        Authorization: "Token " + str,
                        "Content-Type": "application/json",
                    }
                });

                if (res.data.status === "success") {
                    if (next.date) {
                        alert.success('Recheduled the slot')
                    } else {
                        alert.success(res.data.message);
                    }
                    therapy()
                } else {
                    alert.error(res.data.message);
                }
            } catch (err) {
                alert.error('An error occurred while saving the therapy consultation data.');
            }
        }
    };

    return (
        <>
            <div className='therapy-head-container'>
                <IoIosArrowBack onClick={() => setSelected("current")} style={{ height: '20px', width: '20px', color: '#004280', cursor: 'pointer' }} />
                <label><b>Next Consultation:</b></label>
            </div >
            <div className='therapy-body-container'>
                <div className='therapy-input-container'>
                    <div className='therapyinput-container col-6'>
                        <input className='therapy-input'
                            style={{ textTransform: 'capitalize' }}
                            type="text"
                            placeholder='Name'
                            value={name}
                            disabled
                        />
                    </div>
                    <div className='therapyinput-container col-6'>
                        <div className='therapy-input'
                            style={{ cursor: !next.date ? 'pointer' : '' }}
                            onClick={() => { !next.date && setShow(true) }}
                        >{slot.id ? <div className='labels'><label>{date}</label>&nbsp;<label>{slot.from_time}-{slot.to_time}</label></div> : next.date ? moment(next.date).format('DD/MM/YYYY') : 'Pick a slot'}</div>
                    </div>
                </div>
                <div>
                    <button
                        onClick={next.date ? () => setShow(true) : handleSave}
                        className='therapy-button'>
                        {next.date ? 'Reschedule' : 'Save'}
                    </button>
                </div>
            </div>
            {show && (
                <AddTherapySlots alliedId={alliedId} onSave={onSave} onHide={onClose} />
            )}
            {
                showConfirmation && (
                    <SubmitConfirmationPopup
                        message="Are you sure you want to reschedule the appointment?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                    />
                )
            }
        </>
    );
}

export default AddTherapy;
