import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ZoomableImage from '../../BDO/Admission/ZoomableImage';

const DialysisFormView = ({ content }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState('');

    const fields = [
        { label: "Enquiry Number", key: "phe_number" },
        { label: "Admission Number", key: "pv_admission_number" },
        { label: "Enquiry Number", key: "de_number" },
        { label: "Enquiry Number", key: "pape_number" },
        { label: "First Name", key: "first_name", style: { textTransform: "capitalize" } },
        { label: "Last Name", key: "last_name", style: { textTransform: "capitalize" } },
        { label: "Gender", key: "gender", style: { textTransform: "capitalize" } },
        { label: "Date of Birth", key: "dob" },
        { label: "Contact no", key: "mobile_number", style: { textTransform: "capitalize" } },
        { label: "Occupation", key: "occupation", style: { textTransform: "capitalize" } },
        { label: "Income", key: "income" },
        { label: "Apartment number", key: "apt_no" },
        { label: "Location", key: "location", style: { textTransform: "capitalize" } },
        { label: "District", key: "province", style: { textTransform: "capitalize" } },
        { label: "City", key: "city", style: { textTransform: "capitalize" } },
        { label: "Panchayat", key: "panchayat", style: { textTransform: "capitalize" } },
        { label: "Country", key: "country", style: { textTransform: "capitalize" } },
        { label: "Pincode", key: "pincode" },
        { label: "ID Card Type", key: "idcard_type", style: { textTransform: "capitalize" } },
        { label: "ID Card Number", key: "idcard_no" },
        { label: "Insurance Provider", key: "insurance_provider", style: { textTransform: "capitalize" } },
        { label: "Insurance Number", key: "policy_no", style: { textTransform: "capitalize" } },
        { label: "Route Map", key: "route_map", style: { textTransform: "capitalize" } },
        { label: "Medical Expense", key: "med_expense", style: { textTransform: "capitalize" } },
        { label: "Previous Dialysis", key: "prev_dialysis", style: { textTransform: "capitalize" } },
        { label: "Kidney Transplant", key: "prev_kidney_transplant", style: { textTransform: "capitalize" } },
        { label: "Medical information", key: "med_information", style: { textTransform: "capitalize" } },
        { label: "Blood Transfusion", key: "prev_blood_transfusion", style: { textTransform: "capitalize" } },
        { label: "Enquiry Comment", key: "enquiry_comments", style: { textTransform: "capitalize" } },
        { label: "Manager Comment", key: "manager_comments", style: { textTransform: "capitalize" } },
        { label: "Approval Comment", key: "approval_comments", style: { textTransform: "capitalize" } },
        { label: "Chairman Comment", key: "chairman_comment", style: { textTransform: "capitalize" } },
        { label: "Referral Name", key: "referral_name", style: { textTransform: "capitalize" } }
    ];

    const extraFields = [
        { label: "Referral Document", key: "referral_document" },
        { label: "Relatives", key: "relatives", isJson: true },
        { label: "Recommendations", key: "govt_member" },
        { label: "Recommender Document-1", key: "gm_document1" },
        { label: "Recommender Document-2", key: "gm_document2" },
        { label: "Recommender Document-3", key: "gm_document3" },
        { label: "Enquiry Report", key: "enquiry_report" },
        { label: "Medical Prescription", key: "med_document" },
        { label: "Emergency contact", key: "guardian_info" },
        { label: "Photo", key: "photo" },
    ];

    const handleViewClick = (label, content, isJson) => {
        let parsedContent = content;

        if (isJson && typeof content === 'string') {
            try {
                parsedContent = JSON.parse(content);
            } catch (e) {
                console.error('Failed to parse JSON:', e);
            }
        }

        setModalTitle(label);
        setModalContent(parsedContent);
        setShowModal(true);
    };


    const renderModalContent = (content) => {
        if (!content || content === null || (Array.isArray(content) && content.length === 0)) {
            return <div>No content available</div>
        }
        if (Array.isArray(content) && content.length > 0) {
            return (
                <div>
                    {content.map((item, index) => (
                        <div key={index}>
                            {Object.keys(item).map((key) => (
                                key !== 'error' && <div className='row' key={key}>
                                    <div className='col-7'><b>{key.charAt(0).toUpperCase() + key.slice(1)}</b></div>
                                    <div className='col-5' style={{ textTransform: 'capitalize' }}>
                                        :{" "}{key === 'contact' ? `+${item[key]}` : item[key]}
                                    </div>
                                </div>
                            ))}
                            {index < content.length - 1 && <hr />}
                        </div>
                    ))}
                </div>
            );
        } else if (typeof content === 'string' && content.startsWith('http')) {
            return (
                <div style={{ textAlign: "center", color: "red" }}>
                    <ZoomableImage src={content} style={{ width: '100%' }} />
                </div>
            );

        } else if (typeof content === 'string') {
            try {
                content = JSON.parse(content);
                if (content.length > 0) {
                    return (
                        <div>
                            {content.map((item, index) => (
                                <div key={index}>
                                    {Object.keys(item).map((key) => (
                                        key !== 'error' && <div className='row' key={key}>
                                            <div className='col-7'><b>{key.charAt(0).toUpperCase() + key.slice(1)}</b></div>
                                            <div className='col-5' style={{ textTransform: 'capitalize' }}>
                                                :{" "}{key === 'contact' ? `+${item[key]}` : item[key]}
                                            </div>
                                        </div>
                                    ))}
                                    {index < content.length - 1 && <hr />}
                                </div>
                            ))}
                        </div>
                    );
                } else {
                    return <div>No content available</div>
                }
            } catch (e) {
                return <div>{content}</div>;
            }
        }
        else if (typeof content === 'object' && content !== null) {
            return (
                <div>
                    {Object.keys(content).map((key) => (
                        (content[key] !== '' && content[key] !== undefined && content[key] !== null && content[key] !== '91') && (
                            <div className='row' key={key}>
                                <div className='col-7'>
                                    <b>{key === 'secondary_contact' ? 'Secondary contact' : key.charAt(0).toUpperCase() + key.slice(1)}</b>
                                </div>
                                <div className='col-5' style={{ textTransform: 'capitalize' }}>
                                    :{" "}{key === 'contact' || key === 'secondary_contact' ? `+${content[key]}` : content[key]}
                                </div>
                            </div>
                        )
                    ))}
                </div>
            );
        } else {
            return <div>No data available</div>
        }
    };

    return (
        <div>
            {fields.map(field => (
                content[field.key] !== undefined && content[field.key] !== null && content[field.key] !== '' && (
                    <div key={field.key}>
                        <div className='row'>
                            <div className='col-7'>
                                <b>{field.label}</b>
                            </div>
                            <div className='col-5' style={field.style || {}}>
                                : {field.key === 'mobile_number' ? `+${content[field.key]}` : content[field.key]}
                            </div>
                        </div>
                        <br />
                    </div>
                )
            ))}
            {extraFields.map(field => (
                content[field.key] && (
                    <div key={field.key}>
                        <div className='row'>
                            <div className='col-7'>
                                <b>{field.label}</b>
                            </div>
                            <div className='col-5'>
                                <button className='save-button' onClick={() => handleViewClick(field.label, content[field.key], field.isJson)}>View</button>
                            </div>
                        </div>
                        <br />
                    </div>
                )
            ))}

            <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" className="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: "center" }}>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "left" }}>
                    {renderModalContent(modalContent)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DialysisFormView;
