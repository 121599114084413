import React, { useEffect, useState, useContext } from "react";
import { Link, useParams, useLocation, } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";
import { Badge } from 'react-bootstrap';
import DatePicker from "react-date-picker";
import { useAlert } from "react-alert";
import service1 from "../../assets/images/1.png";
import service2 from "../../assets/images/2.png";
import PatientNav from "./PatientNav";
import PatientFooter from "./PatientFooter";
import { CartContext } from "./AddToCartContext";
import OwlCarousel from 'react-owl-carousel';
import { Modal } from "react-bootstrap";
import Addresspage from "./Addresspage";
import { MdFavorite } from "react-icons/md";
import classes from "./favorite.module.css";
import { handleFavorites } from "./handleFav";
import { FavDelete, Fav } from "../patient/Context";
function SearchResult({ setShowNavbarAndFooter }) {
    const { favDeletedList, setFavDeletedList } = useContext(FavDelete);
    const { favAdded, setFavAdded } = useContext(Fav);
    const { state } = useLocation()
    const [id, setId] = useState(state?.details || '');
    const [item, setItem] = useState({service: "",
        service_category: "",
        amount: 0,
        discount: 0,
        main_service:"",
        hospital: "",
        name: "",
        delivery: 0,
        shipping: "",
        description:""
    });
    const alert = useAlert();
    const [isEmpty, setIsEmpty] = useState(false)
    const [prod, setProd] = useState("")
    const [selected, setSelected] = useState([])
    const [color, setColor] = useState("")
    const [size, setSize] = useState("")
    const [weight, setWeight] = useState("")
    const { handleAddToCart } = useContext(CartContext);
    const [volume, setVolume] = useState("")
    const [colors, setColors] = useState([])
    const [sizes, setSizes] = useState([])
    const [weights, setWeights] = useState([])
    const [volumes, setVolumes] = useState([])
    const [colors1, setColors1] = useState([])
    const [sizes1, setSizes1] = useState([])
    const [weights1, setWeights1] = useState([])
    const [volumes1, setVolumes1] = useState([])
    const [img, setImg] = useState("")
    const [similarProd, setSimilarProd] = useState([])
    const [similarProdHosp, setSimilarProdHosp] = useState([])
    const [prodDetails, setProdDetails] = useState([])
    const [dateval, setDateVal] = useState("");
    const [dateToday, setDateToday] = useState("");
    const [distance, setDistance] = useState(0.0);
    const [qty, setQty] = useState(1);
    const [isImgupdate, setImgUpdate] = useState(false)
    const [description, setDescription] = useState(true)
    const [reviews, setReviews] = useState(false)
    const [login_datas, setLogin_datas] = useState(
        JSON.parse(sessionStorage.getItem("logindatas"))
            ? JSON.parse(sessionStorage.getItem("logindatas"))
            : null
    );
    const [refresh, setRefresh] = useState(false)
    const [addressview, setAddressView] = useState(false);
    const [checkId, setCheckId] = useState("");
    const [addressList, setAddressList] = useState([])
    const [addres, setAddress] = useState()
    const [lines, setLines] = useState([])
    const [varientDetails, setVarientDeails] = useState()
    const [favData, setFavData] = useState([]);

     //useEffect for fetching favorite datas from localstorage
     useEffect(() => {
        const userId = sessionStorage.getItem("userid");
        console.log("userid", userId);
        const localStorageKey = `myFavorite_${userId}`;
        try {
          const favoriteDatas = JSON.parse(localStorage.getItem(localStorageKey));
          setFavData(favoriteDatas);
        } catch (error) {
          let favoriteDatas = [];
        }
        setFavDeletedList(false);
      }, [favAdded, favDeletedList]);
      //add to favorite list function calling and state management
      const AddTohandleFavorites = (item) => {
        console.log("add handFav fun", item);
        // const DataToFav = {
        //     item.id? p_id:item.id : p_id:item[0].item_service,
        //     item.service_name? p_name: p_name: item.service_name : item[0].consumable_name ,
        //     item.amount? p_amount : p_amount : item.amount : item[0].price,
        //     item.service_category? p_category: item.service_category : p_category:'' 
        // }
        const DataToFav = {
            p_id: item.id ? item.id : id,
            p_name: item.service_name ? item.service_name : item.service ? item.service : item[0].consumable_name,
            p_amount: item.amount ? item.amount : item.amount ? item.amount : item[0].price,
            p_category: item.service_category ? item.service_category : item.service_category ? item.service_category : '',
            p_img:img
           // p_img: item.ser
          };
        // const DataToFav = {
        //     p_id:item.id,
        //     p_name:item.service_name,
        //     p_amount:item.amount,
        //     p_category:item.service_category
        // }
        // console.log("id is",id);
        // console.log("name is",item.service_category);
        // console.log("service  is",item.service);
        // console.log("img is",img);
        // console.log("amt   is",item.amount);
        handleFavorites(DataToFav);
        console.log("after handleFavorites fun call",DataToFav);
        setFavAdded(true);
      };
    useEffect(() => {
        setShowNavbarAndFooter(false);
        return () => setShowNavbarAndFooter(true);
    }, [setShowNavbarAndFooter]);
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        axios
            .get(`${APIURL}/api/v1/patient/hospital-services-detail/${id}`,

            )

            .then((res) => {

                if (res.data.status === "success") {

                    const datas = res.data.data;
                    console.log("selected item details",datas);
                    setItem({
                        service: datas.service_name,
                        service_category: datas.service_category,
                        amount: datas.amount,
                        discount: datas.discount,
                        main_service: datas.main_service_name,
                        hospital: datas.hospital,
                        name: datas.hospital_name,
                        delivery: datas.delivery_time_period,
                        shipping: datas.shipping_charge_id,
                        description:datas.description

                    })
                    setProd(datas)
                } else {
                    //setLoading(false);
                }
            })
            .catch((err) => {
                // setLoading(false);
            });
    }, [id]);
    useEffect(() => {
        console.log("images", img); // []
    }, [img]);
    useEffect(() => {
        setIsEmpty(false)


        axios.get(`${APIURL}/api/v1/patient/product-variations/?service_name=${item.service}`, {

        })
            .then((res) => {
                if (res.data.status === "success") {
                    setSimilarProd(res.data.variations)

                    const filteredData = res.data.variations.filter(x => {
                        return x.item_service === item.item_service

                    })
                    setProdDetails(filteredData)

                }
            })

        axios.get(`${APIURL}/api/v1/patient/product-variations/?service_name=${item.service}&hospital_id=${item.hospital}`, {
            // headers: {
            //     Authorization: "Token " + str,
            // },
        })
            .then((res) => {
                if (res.data.status === "success") {
                    let data = res.data.variations

                  

                    const tempArray = data.filter(function (x) {
                        return x.item_service === id
                    })
                    if (item.service_category === "master_consumable" || item.service_category === "master_medicine") {
                        setImg(tempArray[0].image)
                    }
                    if (item.service_category === "master_consumable") {
                        setProd(tempArray)
                        setSimilarProdHosp(res.data.variations)
                        setLines(tempArray[0].description.split("\n"))
                        setSelected(tempArray)
                        res.data.variations.map((x) => {
                            if (x.color !== "" || x.color !== null) colors.push(x.color);
                        })
                        const colorTempArray = colors.filter(function (elem, pos) {
                            return colors.indexOf(elem) == pos;
                        });
                        const colorTempArray1 = colorTempArray.filter(function (x) {
                            return x !== null && x !== "" && x !== "null"
                        });
                        setColors(colorTempArray1)

                        res.data.variations.map((x) => {
                            if (x.size !== "" || x.size !== null) sizes.push(x.size);
                        })
                        const sizeTempArray = sizes.filter(function (elem, pos) {
                            return sizes.indexOf(elem) == pos;
                        });
                        const sizeTempArray1 = sizeTempArray.filter(function (x) {
                            return x !== null && x !== "" && x !== "null"
                        });
                        setSizes(sizeTempArray1)
                        res.data.variations.map((x) => {
                            if (x.weight !== "" || x.weight !== null) weights.push(x.weight);
                        })
                        const weightTempArray = weights.filter(function (elem, pos) {
                            return weights.indexOf(elem) == pos;
                        });
                        const weightTempArray1 = weightTempArray.filter(function (x) {
                            return x !== null && x !== "" && x !== "null" && x !== 0
                        });
                        setWeights(weightTempArray1)
                        res.data.variations.map((x) => {
                            if (x.volume !== "" || x.volume !== null) volumes.push(x.volume);
                        })
                        const volumeTempArray = volumes.filter(function (elem, pos) {
                            return volumes.indexOf(elem) == pos;
                        });
                        const volumeTempArray1 = volumeTempArray.filter(function (x) {
                            return x !== null && x !== "" && x !== 0 && x !== "null"
                        });
                        setVolumes(volumeTempArray1)


                    }

                  
                }
            })


        setColors1(colors)
        setVolumes1(volumes)
        setWeights1(weights)
        setSizes1(sizes)


    }, [item.service]);


    useEffect(() => {
        // if (login_datas) {
        //     const tokenString = sessionStorage.getItem("usertoken");

        //     let v2 = tokenString.replace(/["]+/g, "");
        //     axios
        //         .get(`${APIURL}/api/v1/patient/shipping-address/ `, {
        //             headers: { Authorization: "Token " + v2 },
        //         })
        //         .then((res) => {

        //             setAddressList(res.data.message);
        //             const filteredData = res.data.message.filter(x => {
        //                 return x.is_default === true
        //             })
        //             setAddress(filteredData)
        //         })
        //         .catch((err) => { });
        // }
    }, [refresh]);
    const onSuccess = (data) => {
       setAddress([data])
       setAddressView(false)
      };
    function AddressPopUp(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    style={{ borderBottom: "none", height: "30px", marginTop: "2%" }}
                >
                    <button
                        style={{ backgroundColor: "white", marginLeft: "95%" }}
                        variant="secondary"
                        onClick={(props) => { setAddressView(false); setRefresh(!refresh) }}
                    >
                        <i
                            class="fa fa-times-circle fa-2x"
                            style={{ color: "#F0A639" }}
                            aria-hidden="true"
                        ></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Addresspage addList={addressList} address={addres} onSuccess={onSuccess}/>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        );
    }

    useEffect(() => {
        if (isEmpty === true)
            alert.error('no product available for given choice')
    }, [item.service_category === "master_consumable" && selected.length === 0]);


    useEffect(() => {

        if (item.service_category === "master_consumable") {
            const tempArray = similarProdHosp.filter(function (x) {
                return (color ? x.color === color : true)
                    && (weight ? x.weight === weight : true)
                    && (size ? x.size === size : true)
                    && (volume ? x.volume === volume : true);
            });

            setSelected(tempArray)

            if (similarProdHosp.length > 0 && tempArray.length === 0) {
                setIsEmpty(true)
                setId("0")
            }
            else if (similarProdHosp.length > 0 && tempArray.length !== 0) {
                setIsEmpty(false)
                setId(tempArray[0].item_service)
                setImg(tempArray[0].image)
            }


            tempArray.map((x) => {
                colors1.push(x.color)
            })
            tempArray.map((x) => {
                sizes1.push(x.size);
            })
            tempArray.map((x) => {
                weights1.push(x.weight);
            })
            tempArray.map((x) => {
                volumes1.push(x.volume);
            })
            if (selected.length > 0) {
                setProd(selected[0])
                setImg(selected[0].image)
            }
            else {
                setProd("")
            }

        }
    }, [isImgupdate === true && prod, color, size, volume, weight])



    const handleChangeRadio = (event) => {
        console.log(selected)
        if (event.target.value == 1) {
            setDateVal('');
            setDateToday(new Date())

        }
        if (event.target.value == 2) {
            // setDateVal(event.target.value);
            setDateVal(new Date());
        }


    }
    const handleDateChange = (date) => {
        if (date !== null) {

            setDateVal(date)
        }
    };
    const handleChangeQty = (e) => {
        setQty(e.target.value)
    };

    const addCart = () => {
       
        if (dateToday === "" && dateval === "") {
            alert.error("Select date for delivery");

        }
        else {

            let date = dateToday !== "" ? dateToday : dateval
            let localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
            const dataToSend = {
                item_quantity: qty,
                id: id,
                service_category: item.service_category,
                date: localDate.toISOString().split("T")[0],
                item_service_priority: "critical",
                distance: distance,
                hospital: item.hospital,
                item_name: item.service,
                image: img,
                amount: item.amount,
                discount: item.discount,
                vat: item.vat,
               
                volume:volume,
                color: colors,
                size:sizes,
                weight:weights
               

            };
            handleAddToCart(dataToSend)

        }


    }

    const addToCart = (val) => {
      


        const dataToSend = {
            item_quantity: qty,
            id: val.item_service,
            service_category: item.service_category,

            item_service_priority: "critical",
            distance: distance,
            hospital: val.hospital_id,

            item_name: val.consumable_name,
            image: val.image,
            amount: val.price,
            discount: val.discount,
            vat: val.vat

        }
        handleAddToCart(dataToSend)

    }


    const calculateDistance = (latt1, latt2, long1, long2) => {

        var R = 6371; // Radius of the earth in km
        const lat1 = parseFloat(latt1)
        const lat2 = parseFloat(latt2)
        const lon1 = parseFloat(long1)
        const lon2 = parseFloat(long2)
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        // return d;
        setDistance(parseFloat(d).toFixed(4));


    };
    const deg2rad = (deg) => {
        return deg * (Math.PI / 180)
    }
    // {
    //     console.log("search r page item",item);
    //     console.log("details",prod);
    // const isFavorite = favData.some(
    //     (favItem) => id === favItem.p_id

    //   );
    return (<>
        <PatientNav />
        <br /><br />
        <br />

        <div className="row align-items-center">

            <div className="col-lg-5 col-md-5" style={{ paddingLeft: "5%", marginTop: "3%" }}>
                <div className="product-search prod-div-search ">
                <MdFavorite
                                 onClick={() => AddTohandleFavorites(prod)}
                                style={{ fontSize: "35px", marginTop:"1%",marginLeft:"80%"}}
                                //   className={
                                //     isFavorite
                                //       ? classes.favAdded
                                //       : classes.favNotAdded
                                //   }
                                />
                    {/* <a style={{ marginLeft: "90%" }} href="#" data-toggle="modal" data-target="#shoppingWishlistModal"><i style={{ fontSize: "20px", color: "#C3B7B7" }} className='fa fa-heart fa-lg'></i></a> */}
                    {img !== "" ? <img style={{ marginTop: "5%", opacity: item.service_category === "master_consumable" && similarProdHosp.length > 0 && isEmpty && "0.2" }} src={img} alt="image" />
                        : <img style={{ borderRadius: "20px", }} src={service2} alt="image" />}

                </div>
                <br />
                <div className="" style={{ display: "flex", paddingLeft: "8%" }}>

                    <button type="submit" style={{ marginTop: "4%", backgroundColor: "#F0A639", fontSize: "12px", marginRight: "0px", width: "180px", }} className="default-btn " onClick={() => { addCart() }}> Buy Now</button>&nbsp;&nbsp;&nbsp;&nbsp;

                    <button type="submit" style={{ marginTop: "4%", fontSize: "12px", marginRight: "0px", width: "180px", backgroundColor: "white", color: "red", border: "1px solid #E73C17", }} className="default-btn default-btn-white" onClick={() => { addCart() }}> Add to Cart</button>
                </div>
            </div>

            <div className="col-lg-4 col-md-4" style={{ boxShadow: "2px 0px 0px #b4b2b2", height: " 464px" }} >
                <div className="" style={{ fontSize: "15px", paddingLeft: "5%" }}>
                    <div style={{ fontSize: "12px" }}><Link to="/webordering">Home</Link>{" "}<i class="fa fa-angle-right fa-lg" aria-hidden="true"></i><Link to={item.service_category === "master_scan" ? "/categories?categorynew=Scan" : item.service_category === "misc" ? "/categories?categorynew=Misc" : item.service_category === "master_medicine" ? "/categories?categorynew=Medicine" : item.service_category === "master_procedure" ? "/categories?categorynew=Procedure" : item.service_category === "master_consumable" ? "/categories?categorynew=Consumable" : "/categories?categorynew=Lab"}> {item.service_category === "master_scan"
                        ? "Scan"
                        : item.service_category === "misc"
                            ? "Misc"
                            : item.service_category === "master_medicine"
                                ? "Medicine"
                                : item.service_category === "master_procedure"
                                    ? "Procedure"
                                    : item.service_category === "master_consumable"
                                        ? "Consumables"
                                        : item.service_category === "master_labtest"
                                            ? "Test"
                                            : ""} </Link>{" "}<i class="fa fa-angle-right fa-lg" aria-hidden="true"></i>
                        <Link to="#">{item.service}</Link></div>

                    <h6 style={{ marginTop: "1%", fontSize: "18px" }}>{item.service}{" "}</h6>
                    {selected.length > 0 && <div style={{ fontSize: "12px" }} className="">
                        {selected[0].color && <span><b>Color:</b>{" "}<b>{selected[0].color}</b><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>}

                        {selected[0].weight && <span><b>Weight:</b>{" "}<b>{selected[0].weight}Kg</b><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>}

                        {selected[0].size && <span><b>Size:</b>{" "}<b>{selected[0].size}</b> <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>}

                        {selected[0].volume && <span><b>Volume:</b>{" "}<b>{selected[0].volume}L</b> </span>}
                    </div>}
                    <div style={{ display: "flex", color: "gold", fontSize: "12px" }} className="products-review">
                        <div className="rating">
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>

                        </div>
                        <a href="#" className="rating-count"><small>4 stars</small></a>
                    </div>
                    <div className="">
                        <div className="" style={{ fontSize: "22px", fontWeight: "700", }}>
                            {selected.length === 0 ? <> {item.discount !== 0 ? <>&nbsp;&nbsp;</> : ""}
                                <span style={{ fontWeight: "700", paddingTop: "1%" }} className="discounted-price">${(item.amount * (100 - item.discount) / 100).toFixed(2)}</span>
                                <span>&nbsp;&nbsp;</span>
                                {item.discount !== 0 ? <span><span className="" style={{ fontSize: "17px" }}>${item.amount}</span> <Badge pill variant="success" style={{ fontSize: '12px', marginBottom: "3%" }}>
                                    {item.discount}% Off!
                                </Badge></span> : ""}</> :
                                <> {selected[0].discount !== 0 ? <>&nbsp;&nbsp;</> : ""}
                                    <span style={{ fontWeight: "700", paddingTop: "1%" }} className="discounted-price">${(selected[0].price * (100 - selected[0].discount) / 100).toFixed(2)}</span>
                                    <span>&nbsp;&nbsp;</span>
                                    {item.discount !== 0 ? <span><span style={{ fontSize: "17px" }} className="old-price">${selected[0].price}</span> <Badge pill variant="success" style={{ fontSize: '12px', marginBottom: "3%" }}>
                                        {selected[0].discount}% Off!
                                    </Badge></span> : ""}</>}
                        </div>
                    </div>

                    {/* {selected.length > 0 &&selected[0].brand&& <a style={{ fontSize: "12px" }}>Brand:<Link to="/webordering">{selected[0].brand}</Link></a>} */}

                    <ul style={{ fontSize: "12px", marginLeft: "-27px", marginTop: "1%" }} className="products-info">
                        {selected.length > 0 && selected[0].brand && <li><span>Brand:</span> <Link to="">{selected[0].brand}</Link></li>}
                        {selected.length > 0 && selected[0].model && <li><span>Model:</span> <Link to="">{selected[0].model}</Link></li>}
                        {selected.length > 0 && selected[0].manufacturer && <li><span>Manufacturer:</span> <Link to="">{selected[0].manufacturer}</Link></li>}
                        <li><span>Vendor:</span> <Link to="">{item.name}</Link></li>
                        <li><span>Availability:</span> <Link to="">In stock</Link></li>
                        <li><span>Products Type:</span> <Link to="">{item.main_service} </Link></li>
                    </ul>

                    {colors.length > 0 && <div style={{ display: "flex", marginTop: "1%" }}>
                        <span style={{ fontSize: "13px" }} className="col-3 atrname">Color</span>
                        {colors.map((x) => {
                            return (
                                <>
                                    <div className="prod-size-onselect" style={{ backgroundColor: color === x && "#439efe7a", border: !colors1.includes(x) && "1px solid #80808017" }}><div onClick={() => {
                                        setColor(color === x ? "" : x);
                                        setColors1([]);
                                        setVolumes1([]);
                                        setWeights1([]);
                                        setSizes1([]);
                                        setImgUpdate(true)
                                    }} style={{ backgroundColor: x, opacity: colors1.includes(x) ? "1" : "0.1" }} className="colorattr"></div></div><span>&nbsp;&nbsp;&nbsp;</span>
                                </>
                            )
                        })}</div>}
                    {sizes.length > 0 && <div style={{ display: "flex", marginTop: "1%" }}>
                        <span style={{ fontSize: "13px" }} className="col-3 atrname">Size</span>
                        {sizes.map((x) => {
                            return (
                                <>
                                    <div style={{ backgroundColor: size === x && "#439efe7a", textDecoration: sizes1.includes(x) ? "none" : "line-through", border: !sizes1.includes(x) && "1px solid #80808017" }} onClick={() => {
                                        setSize(size === x ? "" : x); setColors1([]);
                                        setVolumes1([]);
                                        setWeights1([]);
                                        setSizes1([])
                                        setImgUpdate(true)
                                    }} className="prod-size-onselect">{x}</div><span>&nbsp;&nbsp;&nbsp;</span>
                                </>
                            )
                        })}</div>}

                    {volumes.length > 0 && <div style={{ display: "flex", marginTop: "1%" }}>
                        <span style={{ fontSize: "13px" }} className="col-3 atrname">Volume</span>
                        {volumes.map((x) => {
                            return (
                                <>
                                    <div style={{ backgroundColor: volume === x && "#439efe7a", textDecoration: volumes1.includes(x) ? "none" : "line-through", border: !volumes1.includes(x) && "1px solid #80808017" }} onClick={() => {
                                        setVolume(volume === x ? "" : x); setColors1([]);
                                        setVolumes1([]);
                                        setWeights1([]);
                                        setSizes1([])
                                        setImgUpdate(true)
                                    }} className="prod-size-onselect">{x}L</div><span>&nbsp;&nbsp;&nbsp;</span>
                                </>
                            )
                        })}</div>}
                    {weights.length > 0 && <div style={{ display: "flex", marginTop: "1%" }}>
                        <span style={{ fontSize: "13px" }} className="col-3 atrname">Weight</span>
                        {weights.map((x) => {
                            return (
                                <>
                                    <div style={{ backgroundColor: weight === x && "#439efe7a", textDecoration: weights1.includes(x) ? "none" : "line-through", border: !weights1.includes(x) && "1px solid #80808017" }} onClick={() => {
                                        setWeight(weight === x ? "" : x); setColors1([]);
                                        setVolumes1([]);
                                        setWeights1([]);
                                        setSizes1([]);
                                        setImgUpdate(true)
                                    }} className="prod-size-onselect">{x}Kg</div><span>&nbsp;&nbsp;&nbsp;</span>
                                </>
                            )
                        })}</div>}
                    <br />
                    <div className="row " style={{ width: "95%", marginLeft: "1%" }}>

                        <div style={{ fontSize: "13px", }} className="col-6 ">
                            <div>
                                <input type="radio" style={{ width: "9px" }} name="status" onChange={handleChangeRadio} value="1" />
                                {" "}
                                <span className="atrname" style={{ color: 'black' }}>Today</span>
                            </div>

                            <div>
                                <input type="radio" style={{ width: "9px" }} name="status" onChange={handleChangeRadio} value="2" />
                                {" "}
                                {dateval ?

                                    <DatePicker
                                        value={new Date(dateval)}
                                        onChange={(date) => {
                                            handleDateChange(date);

                                        }}
                                        minDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        className="custom-datepicker"

                                    />

                                    :
                                    <span className="atrname" style={{ color: 'black' }}>Set Delivery Date</span>}
                            </div>

                        </div>
                        <div className="col-6">
                            {item.service_category === "master_consumable" || item.service_category === "master_medicine" ?
                                <div className="input-counter" style={{ display: 'flex' }}>
                                    <span className="col-6 atrname">Quantity</span>

                                    <input style={{ paddingLeft: "1%" }} onChange={handleChangeQty} className="pro-counter" value={qty} type={"number"} />
                                </div>
                                : ""}

                        </div>
                    </div>
                    <br />
                    <div className="del-date prod-div">
                        <i style={{ color: "#F0A639" }} class="fa fa-truck fa-flip-horizontal" aria-hidden="true"></i>&nbsp;&nbsp;
                        {item.delivery !== null && <span style={{ color: "#33A10D" }}>{Number(item.delivery) === 0 ? "Expected Delivery-Today." : Number(item.delivery) === 1 ? "Expected Delivery-Tomorrow." : "Usually ships within" + item.delivery + " " + " to" + " " + Number(item.delivery + 1) + " days."}</span>}</div><br />

                </div>

            </div>
            <div className="col-lg-3 col-md-3 " style={{ height: " 464px", }}>
                {(login_datas) ?
                    <div
                        className="shipping"
                        style={{ flex: "2 2 70%", marginBottom: "3%", width: "90%" }}
                    >
                        <span
                            style={{
                                color: "#000000",
                                marginLeft: "4%",
                                textDecoration: "underline",
                            }}
                        >
                            Shipping Address <i
                                class="fas fa-pencil-alt-square"
                                onClick={() => {
                                    setAddressView(true);
                                }}
                                style={{ color: "#F0A639", marginLeft: "47%" }}
                                aria-hidden="true"
                            ></i>
                        </span>
                        <h6
                            style={{
                                fontSize: "13px",
                                marginLeft: "5%",
                                color: "#6F6F6F",
                            }}
                        >
                            {addres && addres.length > 0 ? <><span> {addres[0].first_name}{" "}{addres[0].last_name}</span><br />
                                {addres[0].apt_no !== null && <span>{addres[0].apt_no},</span>}
                                {addres[0].landmark !== null && <span>{" "}{addres[0].landmark},</span>}
                                {addres[0].city !== null && <span>{" "}{addres[0].city},</span>}
                                {addres[0].province !== null && <span>{" "}{addres[0].province},</span>}
                                {addres[0].pincode !== null && <span>{" "}{addres[0].pincode},</span>}
                                {addres[0].state !== null && <span>{" "}{addres[0].state}</span>}<br />
                                {addres[0].mobile_number !== null && <span>Mobile:{addres[0].mobile_number}</span>}
                            </>
                                : "Please Add Address"}

                        </h6>

                    </div>
                    : ""}
                {/* <div className="" style={{marginRight:"3%"}}>
                <h6>Similar Products</h6>
            </div> */}
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>

            </div>

        </div><br /><br />
        <div className="prod-div" style={{ display: "flex", paddingLeft: "10%" }}>

            <div className="col-2" onClick={() => {

                setDescription(true);
                setReviews(false);
            }}
                style={{ textDecoration: description ? "underline #E73C17" : "" }}>Description</div>
            <div className="col-2" onClick={() => {
                setDescription(false);
                setReviews(true);
            }} style={{ textDecoration: reviews ? "underline #E73C17" : "" }}>Reviews</div>

        </div>
        <div className="devider"></div>
        {description ? <div style={{ fontSize: "12px", padding: "5%" }} className="prod-div">
        {item.description!==""&&item.description!==null?item.description: selected.length > 0? selected[0].description:""}
          


        </div>
            : <div style={{ marginTop: "3%" }} className="prod-div">Reviews</div>}

        <div className="appForm">
            <div className="section-title">
                <h6>Similar Products</h6>
            </div>

            <div className="row">
                <div style={{ display: "contents", width: "200px" }} className="home-slides owl-carousel owl-theme" >
                    <OwlCarousel items={similarProd.length > 3 ? 4 : similarProd.length}
                        className="owl-theme"
                        loop
                        nav
                        dots={false}
                        margin={0}

                    >
                        {similarProd.map((x) => {
                            // const isFavorite = favData.some(
                            //     (favItem) => id === favItem.p_id
                            //   );
                              return (

                            <div className="single-products-box" style={{ width: "100%" }}>
                                <div className="image prod-div" style={{ backgroundColor: "white" }} >


                                    <a href="#" className="d-block">    <Link

                                        to="/search-nav"
                                        state={{ details: x.item_service }}

                                    > <img src={item.service_category === "master_medicine" || item.service_category === "master_consumable" ? x.image : service2} alt="image" style={{ height: "150px", width: "175px", backgroundColor: "#f7f8fa" }} onClick={() => ""} /></Link></a>

                                    <div className="buttons-list" >
                                        <ul>
                                            <li>
                                                <div onClick={() => addToCart(x)} className="cart-btn">
                                                    <a data-toggle="modal" data-target="#productsQuickView">
                                                        <i class="fa fa-cart-plus" aria-hidden="true"></i>
                                                        <span className="tooltip-label">Add to Cart</span>
                                                    </a>
                                                </div>
                                            </li>
                                            <li>
                                                <div onClick={() => ""} className="quick-view-btn">
                                                    <Link

                                                        to="/search-nav"
                                                        state={{ details: x.item_service }}


                                                    >
                                                        <i className='bx bx-search-alt'></i>
                                                        <span className="tooltip-label">Quick View</span>
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                          {login_datas ? 
                              <div
                                onClick={() => AddTohandleFavorites(prod)}
                                className="quick-view-btn"
                              >
                                <a
                                  data-toggle="modal"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: "2px",
                                  }}
                                >
                                  <MdFavorite
                                    // className={
                                    //   isFavorite
                                    //     ? classes.favAdded
                                    //     : classes.favNotAdded
                                    // }
                                  />
                                  <span className="tooltip-label">
                                    Add to Favorite
                                  </span>
                                </a>
                              </div>
                              :""}
                            </li>


                                        </ul>
                                    </div>
                                </div>

                                <div className="content" style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                                    <h6 style={{ fontFamily: "Arial, monospace" }}><b>{item.service}</b>  <div style={{ float: "right" }} className="price">
                                        <span className="old-price"><i class="fa fa-usd" aria-hidden="true"></i>{x.price}</span>
                                        <span className="discounted-price">{(x.price * (100 - x.discount) / 100).toFixed(2)}</span>
                                    </div></h6>

                                    <small>{x.hospital_name}</small>
                                </div>
                            </div>);

                                })}
                    </OwlCarousel>
                </div>
            </div>
        </div>
        <PatientFooter />
        {addressview ? (
            <AddressPopUp
                show={addressview}
                onHide={() => {
                    setAddressView(false);
                }}
            />
        ) : null}
    </>
    )}
// }
export default SearchResult;