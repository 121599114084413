import axios from "axios";
import React, { useState, useEffect, useRef } from 'react'
import { APIURL } from "../../Global";
import { useAlert } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import moment from "moment";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { DatePicker } from "rsuite";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Button, Card, ListGroup, ListGroupItem, ToggleButton, ToggleButtonGroup, } from "react-bootstrap";

import SimpleBar from 'simplebar-react';
import FormView from "./FormView";
function ApprovedRejected(props) {
    const [requests, setRequests] = useState([])
    const [requestsAll, setRequestsAll] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [category, setCategory] = useState("relative")
    const [search, setSearch] = useState("");
    const [formShow, setFormShow] = useState(false)
    const [general, setGeneral] = useState([])
    const [relative, setrelative] = useState([])
    const [friend, setFriend] = useState([])
    const [organisation, setOrganisation] = useState([])
    const [patientDetails, setPatientDetails] = useState({})
    const handleChangeRadio = (val) => {
        setCategory(val);
    }

    const FormPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <FormView patient={patientDetails} />

                </Modal.Body>
            </Modal>
        );
    };
    useEffect(() => {
        setRequests([])
        setCategory("relative")
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/service-provider/visitor-list/?status=${props.status}`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {

                if (res.status === 200) {
                    const filteredData = res.data.visitors.filter(x => {
                        return x.visitor_type === "general"
                    })
                    setGeneral(filteredData)
                    const filteredData1 = res.data.visitors.filter(x => {
                        return x.visitor_type === "relative"
                    })
                    setrelative(filteredData1)
                    const filteredData2 = res.data.visitors.filter(x => {
                        return x.visitor_type === "friend"
                    })
                    setFriend(filteredData2)
                    const filteredData3 = res.data.visitors.filter(x => {
                        return x.visitor_type === "organisation"
                    })
                    setOrganisation(filteredData3)
                    setRequestsAll(res.data.visitors)
                   setRequests(filteredData1)

                }
            })
            .catch((err) => { });

    }, [props.status]);

    useEffect(() => {
        if (category === "relative") {
            setRequests(relative)
        }
        else if (category === "friend") {
            setRequests(friend)
        }
        else if (category === "general") {
            setRequests(general)
        }
        else if (category === "organisation") {
            setRequests(organisation)
        }
    }, [category, refresh, props.status])
    const handleSearchChange = (e) => {
        e.preventDefault();
        let searchTerm = e.target.value
        setSearch(e.target.value)
        if (e.target.value === "") {
            setRefresh(!refresh)
            setRequests([])

        }
        else {
            let data = requestsAll
                .sort((a, b) => {
                    if ((a.first_name + a.last_name).toLowerCase() < (b.first_name + b.last_name).toLowerCase())
                        return -1;
                    if ((a.first_name + a.last_name).toLowerCase() > (b.first_name + b.last_name).toLowerCase()) return 1;
                    return 0;

                })
                .filter((value) => {
                    if (
                        (value.first_name +" "+ value.last_name).toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                        return value;
                    }
                    else if (value.id.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
                        return value
                    }


                });
            if (data.length === 0) {
                setRequests([])
                setCategory("")
            }
            else {
                let type = data[0].visitor_type
                const filteredData = data.filter(x => {
                    return x.visitor_type === type
                })
                setRequests(filteredData)

                if (data.length > 0 && data[0].visitor_type === "relative") {
                    setCategory("relative")
                } else if (data.length > 0 && data[0].visitor_type === "friend") {
                    setCategory("friend")
                }
                else if (data.length > 0 && data[0].visitor_type === "organisation") {
                    setCategory("organisation")
                }
                else if (data.length > 0 && data[0].visitor_type === "general") {
                    setCategory("general")
                }
            }
        }
    }

    return (<>
        <div className="row" style={{ paddingLeft: "2%", marginTop: "3%" }}>
            <div className="col-6 row" style={{ }}>
                <div className="col-3" onClick={e => { setCategory("relative") }} style={{ textAlign: "center", padding: "1.2%", color: category === "relative" ? "#FFFFFF" : "black", backgroundColor: category === "relative" ? "#6d4482" : "#f8f9fa" }}>Relatives</div>
                <div className="col-3" onClick={e => { setCategory('friend') }} style={{ textAlign: "center", padding: "1.2%", color: category === "friend" ? "#FFFFFF" : "black", backgroundColor: category === "friend" ? "#6d4482" : "#f8f9fa" }}>Friends</div>
                <div className="col-3" onClick={e => { setCategory('general') }} style={{ textAlign: "center", padding: "1.2%", color: category === "general" ? "#FFFFFF" : "black", backgroundColor: category === "general" ? "#6d4482" : "#f8f9fa" }}>General</div>
                <div className="col-3" onClick={e => { setCategory('organisation') }} style={{ textAlign: "center", padding: "1.2%", color: category === "organisation" ? "#FFFFFF" : "black", backgroundColor: category === "organisation" ? "#6d4482" : "#f8f9fa" }}>Organisation</div>
            </div>
<div className="col-1"></div>
            <div className="col-5">
                <div className="searchboxdeathrepo" >  <input placeholder="Search Name or ID" type="text" value={search}
                    onChange={e => { handleSearchChange(e) }} style={{ marginLeft: '10px', marginTop: "3px", border: "0", outline: "none", width: "88%", }}></input> <i class="fa fa-search" aria-hidden="true"></i></div>
            </div>
        </div>
        <div className="leftdivdeathrepo" style={{ padding: "1%", minHeight: "460px", marginBottom: "1%" }}>
            {requests.length > 0 && <div className="row">
                <label className='visitor-text-style-here col-5 ' ></label>
                <label className='visitor-text-style-here col-2' >Application Form</label>
                <label className='visitor-text-style-here col-2' >Purpose of Visit</label>
                <label className='visitor-text-style-here col-3' >Date</label>

            </div>}
            <SimpleBar style={{ maxHeight: '800px', paddingLeft: "2%", paddingRight: "2%", marginTop: "1%" }}>
                {requests.length > 0 ? requests.map((item) => {
                    return (
                        <div style={{ paddingRight: "1%" }} className="deathreq_lists row">
                            <div className="col-5" style={{ padding: "1%", fontSize: "18px", textTransform: "capitalize" }}>&nbsp;&nbsp;{item.first_name}{" "}{item.last_name}</div>
                            <div className="col-2" style={{ paddingLeft: "2%" }} >
                                <div style={{ border: "1px solid rgba(205, 205, 205, 1)", borderRadius: "5px", padding: "1%", width: "70%" }} className="row">
                                    <div style={{ borderRight: "1px solid rgba(158, 158, 158, 1)", color: "rgba(79, 135, 203, 1)" }} className="col-6">


                                        <span class="fa-stack fa-lg" style={{}}  >
                                            <i class="fa fa-envelope" aria-hidden="true" style={{ fontSize: "20px", color:item.status==="approved"?"rgba(94, 203, 91, 1)": "rgba(229, 89, 11, 1)" }} ></i>
                                            <i class="fa fa-question-circle-o" aria-hidden="true" style={{ fontSize: '12px', color: "#4F87CB" }}></i>
                                        </span>

                                    </div>
                                    <div style={{ paddingTop: "7%" }} className="col-6">
                                        <i onClick={() => { setFormShow(true); setPatientDetails(item) }}
                                            class="fa fa-eye" style={{ fontSize: "23px", marginLeft: "5%", color: "rgba(36, 86, 147, 1)" }} aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2" style={{ padding: "1%", textTransform: "capitalize" }}>&nbsp;&nbsp;&nbsp;{item.purpose.replace("_", " ")}</div>
                            <div className="col-3" style={{ padding: "1%" }}><span style={{ color: "blue" }}>&nbsp;&nbsp;&nbsp;{item.from_date}</span>{item.to_date!==item.from_date && "--"}<span style={{ color: "blue" }}>{item.to_date!==item.from_date && item.to_date}</span></div>

                        </div>
                    )

                }) :

                    <div className="" style={{ marginTop: "10%", color: "red" }}>No Visitors!!</div>
                }
            </SimpleBar>
        </div>
        {formShow ? (
            <FormPopUp
                show={formShow}
                onHide={() => {
                    setFormShow(false);
                }}
            />
        ) : null}
    </>
    )
}
export default ApprovedRejected;