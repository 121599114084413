import React, { useState, useEffect, useRef, useContext } from 'react'
import { useParams, useNavigate, Link, useLocation, } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from 'react-date-picker';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import Keyboard from 'react-simple-keyboard';
import { Button } from 'react-bootstrap';
import 'react-simple-keyboard/build/css/index.css';
import "./enquiry.css"
import peace from "../../../assets/images/icons/peacevillage.png";
import AssignPatient from '../../HospSearchPatient/AssignPatient';
import AddInventory from '../Inventory/AddInventory';
import WoundDoc from '../woundDocumentation/woundDoc';
import Report from '../woundDocumentation/Report';
import UserListAddDoc from '../../HospSearchPatient/UserListAddDoc'
import PersonalInventory from '../Inventory/PersonalInventory';
import ViewRoom from './ViewRoom';
import ViewDoc from './ViewDoc';
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
import { ConfirmationContext } from '../../contexts/SubmitConfirmationContext';
function AdmissionForm() {
  let navigate = useNavigate();
  const [popStateEventFired, setPopStateEventFired] = useState(false);
  const alert = useAlert();
  const location = useLocation();
  const pat_id = location.state ? location.state.itemId :sessionStorage.pv_enquiry_number?sessionStorage.pv_enquiry_number: "";
  const [userDatas, setUserDatas] = useState({})
  const [error, setError] = useState({})
  const [id, setId] = useState({ value: "", label: "Select type" })
  const [license, setLicense] = useState({ value: "", label: "Select license" })
  const [religion, setReligion] = useState({ value: "", label: "Select religion" })
  const [identityCard, setIdentityCard] = useState("")
  const [isValidIdentity, setIsValidIdentity] = useState(true);
  const [idcard_no, setidcard_no] = useState("");
  const [show, setShow] = useState(false);
  const [type, setType] = useState()
  const [eqDate, seteqDate] = useState("")
  const [browserBack,setBrowserBack]=useState(false)
  const [licenseoptions, setlicenseoptions] = useState([])
  const [fileNameRes, setFileNameRes] = useState("Upload Signature");
  const [fileNamewit1, setFileNameWit1] = useState("Upload Signature");
  const [fileNamewit2, setFileNameWit2] = useState("Upload Signature");
  const [fileNameGd, setFileNameGd] = useState("Upload Signature");
  const [bdUnit, setBdUnit] = useState(sessionStorage.getItem("bedunit")&&sessionStorage.getItem("bedunit.id")===pat_id?sessionStorage.getItem("bedunit.bedunit"):"")
  const [isValidAd, setIsValidAd] = useState(true);
  const [woundAdded, setWoundAdded] = useState(false)
  const [validgdcontact, setValidgdContact] = useState(true)
  const [validWt1contact, setValidWt1Contact] = useState(true)
  const [validWt2contact, setValidWt2Contact] = useState(true)
  const [browserBackPopup, setBrowserBackPopup] = useState(false);
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
  const [invAdded,setInvAdded]=useState(false)
  const [docAdded,setDocAdded]=useState(false)
  const [viewRoom,setViewRoom]=useState(false)
  const [reassign,setReassign]=useState(false)
  const [refresh,setRefresh]=useState(false)
  const [dob, setDob]=useState('')
  const [witness1, setwitness1] = useState({
    name: '',
    contact: '91',

  });
 
  const [witness2, setwitness2] = useState({
    name: '',
    contact: '91',

  });

  useEffect(() => {
    const handlePopState = (e) => {
      if (!popStateEventFired) {
        setPopStateEventFired(true);
        setBrowserBack(!browserBack);

        if (browserBackPopup) {
          cancelAdmission(false);
        } else {
          setBrowserBackPopup(true);
        }
      }else{
        cancelAdmission(true);
        navigate('/admission');
        
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [browserBack, browserBackPopup, popStateEventFired]);

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  }, []);
  
  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    
    let str = tokenString.replace(/["]+/g, "");
    axios

      .get(`${APIURL}/api/v1/service-provider/pri-enquiry/?pv_enquiry_number=${pat_id}`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("pv_enquiry_number",res.data[0].pv_enquiry_number)
          setId({
            value: res.data[0].idcard_type!==null?res.data[0].idcard_type:"",
            label: res.data[0].idcard_type!==null?res.data[0].idcard_type:""
          })
          setUserDatas(currentstate => ({
            ...currentstate,
            id: res.data[0].id,
            enquiry_date: res.data[0].enquiry_date!==null? res.data[0].enquiry_date.slice(0, 10):"",
            pv_enquiry_number: res.data[0].pv_enquiry_number,
            first_name: res.data[0].first_name,
            last_name: res.data[0].last_name !== null ? res.data[0].last_name : "",
            own_income: res.data[0].own_income,
            user: res.data[0].user,
            gender: res.data[0].gender,
            address:(res.data[0].province!==null?(res.data[0].province+","):"")
            +(res.data[0].city!==null?(res.data[0].city+","):"")
            +(res.data[0].location!==null?(res.data[0].location+","):"")
            +(res.data[0].pincode!==null?("Pincode "+res.data[0].pincode+","):"")
            +(res.data[0].state!==null?(res.data[0].state+","):"")
            +(res.data[0].country&&(res.data[0].country+".")),
            marital_status:res.data[0].marital_status,
            age: res.data[0].dob,
            idcard_no: res.data[0].idcard_no ? res.data[0].idcard_no : "",
            idcard_type:res.data[0].idcard_type ? res.data[0].idcard_type : "",
            mobile_number: res.data[0].mobile_number!==null ?  res.data[0].mobile_number:"",
            bedunit_code:res.data[0].bedunit_code!==null?res.data[0].bedunit_code:""
          }));
          if (
            res.data[0].idcard_type !== null &&
            res.data[0].idcard_no !== ""
          ) {
            setIdentityCard(res.data[0].idcard_type,)
            setidcard_no(res.data[0].idcard_no,)
           
          }
          setDob(res.data[0].dob)
          setBdUnit(res.data[0].bedunit_code!==null?res.data[0].bedunit_code:"")
        } else {
          alert.error(res.data.message);
        }
      });
  }, [pat_id,refresh]);

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");
    axios

      .get(`${APIURL}/api/v1/service-provider/master-licenses/`, {
        headers: {
          Authorization: "Token " + str,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          let data = [{ value: "", label: "Select License" }]
          res.data.length > 0 && res.data.map((item) => {
            data = ([...data, { value: item.id, label: item.license_name }])
          })
          setlicenseoptions(data)

        } else {
          alert.error(res.data.message);
        }
      });
  }, [pat_id]);

  const calculateAge = (dob) => {
    if(dob!==""&&dob!==undefined&&dob!==null){
    const dobDate = new Date(dob);
    const ageDate = new Date(Date.now() - dobDate.getTime());
    return Math.abs(ageDate.getUTCFullYear() - 1970).toString();
  }
  else {return ""}
  };


  const cancelAdmission = (cancelback) => {


    const tokenStr = sessionStorage.getItem("usertoken");

    let v2 = tokenStr.replace(/["]+/g, "");
    let dataTosend = {}
  
        dataTosend = {
            is_deleted: true

        }
    
    axios
        .put(`${APIURL}/api/v1/service-provider/cancel-admission/?pv_enquiry_number=${userDatas.pv_enquiry_number} `, dataTosend, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + v2,
            },
        })
        .then((res) => {
            if (res.data.status === "success") {
              sessionStorage.removeItem("pv_enquiry_number");
              sessionStorage.removeItem("bedunit");
              if(!cancelback){
                navigate("/admission");
              }
              setBrowserBackPopup(false)

            } else {
                alert.error(res.data.message);
            }
        })
        .catch((err) => {
            // setErrorMsg("Error in submission");
        });

};
  const identityoptions = [
    { value: '', label: 'Select type' },
    { value: "pan", label: "PAN" },
    { value: "passport", label: "PASSPORT" },
    { value: "aadhar", label: "AADHAR" },
    { value: "driving_license", label: "DRIVING LICENSE" },
    { value: "ration_card", label: "RATION CARD" },
    { value: "voter_id", label: "VOTER ID" },
  ];
  const religionoptions = [
    { value: '', label: 'Select religion' },
    { value: "islam", label: "Islam" },
    { value: "hindu", label: "Hindu" },
    { value: "christian", label: "Christian" },
  ];
  const trimspace = (val) => {

    return val.startsWith(' ')
      ? val.trimLeft()
      : val;
  }

  const handleTitle = (e) => {
    e.preventDefault();
    const val = trimspace(e.target.value);
    const key = e.target.name;
    if(key==="pv_admission_number"){
      setError((currentstate) => ({
        ...currentstate,
        pv_admission_number: "",
      }));
    }
    if(key==="guardian_name"){
      
      if(val===""){
        setError((currentstate) => ({
          ...currentstate,
          guardian_name: "",
          guardian_address:"",
          guardian_sign:"",
          guardian_contact:""
        }));
        delete userDatas.guardian_name;
        delete userDatas.guardian_address;
        delete userDatas.guardian_sign;
        setFileNameGd("Upload Signature");
        setUserDatas(currentstate => ({
          ...currentstate,
          guardian_address:'',
          guardian_contact:'91'
        }));
      }
    }
    if(key==="guardian_address"){
      setError((currentstate) => ({
        ...currentstate,
        guardian_address:"",
      }));
    }
    setUserDatas(currentstate => ({
      ...currentstate,
      [key]: val
    }));

  }
  const BrowserBackOptionPopup = (props) => {
    return (
      <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}

    >

      <Modal.Body >


          <h4 id="confirm-reject">The admission form has not been submitted. Do you want to exit from page?</h4>
        </Modal.Body>
       
        <div className="form-button " >
          <button  type="button" className="btn btn-danger btn-cancel" onClick={() =>{setBrowserBack(!browserBack);setPopStateEventFired(false); cancelAdmission(true)}}> Cancel </button>
            <button type="submit" className="btn btn-primary btn-col" onClick={() =>{cancelAdmission(false)}} >Confirm </button>
        </div><br/>
      </Modal>
    );
  };
  const RoomPopup = (props) => {
    return (
      <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}

    >
  <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
      <Modal.Body >
    

      <div className="form-button " >
        <ViewRoom id={userDatas.user} onSuccess={(data) => { setReassign(true); setType("room") ;setViewRoom(false); setShow(true)}}/>
      </div>
        
        </Modal.Body>
       
        
         
      </Modal>
    );
  };
  const handleTitlewit = (e) => {
    e.preventDefault();
    const val = trimspace(e.target.value);
    const key = e.target.name;

    if (key === "name1") {      
      setwitness1(currentstate => ({
        ...currentstate,
        name: val
      }));
      if (val === "") {
        setError((currentstate) => ({
          ...currentstate,
          witness1_sign: "",
          witness1_contact:""
        }));
        setwitness1(currentstate => ({
          ...currentstate,
          contact: "91"
        }));
        delete userDatas.witness1_sign;
        setFileNameWit1("Upload Signature");
      }      
    }
    else {      
      setwitness2(currentstate => ({
        ...currentstate,
        name: val
      }));
      if (val === "") {
        setError((currentstate) => ({
          ...currentstate,
          witness2_sign: "",
          witness2_contact:""
        }));
        setwitness2(currentstate => ({
          ...currentstate,
          contact: "91"
        }));
        delete userDatas.witness2_sign;
        setFileNameWit2("Upload Signature");
      }
    }
  }

  const handleDate = (date) => {
    seteqDate(date)
    let dateUpdated = date;

    setError(currentstate => ({
      ...currentstate,
      admission_date: ""
    }))
    if (date !== null) {
      const offset = date.getTimezoneOffset();
      dateUpdated = new Date(date.getTime() - (offset * 60 * 1000))
      let DateExtracted = dateUpdated.toISOString().split('T')[0];
      setUserDatas(currentstate => ({
        ...currentstate,
        admission_date: DateExtracted,

      }));

    }
    else if (date === null || date === "") {
      setUserDatas(currentstate => ({
        ...currentstate,
        admission_date: "",

      }));
    }

  }
 
  const handleRadioButton = (e) => {
    let genderOpted = e.target.value;
    setUserDatas((currentstate) => ({
      ...currentstate,
      marital_status: genderOpted==="married"?true:false,
    }));
  };
  const IdentityChange = (data) => {
    setId({ value: data.value, label: data.label })
    setUserDatas((current) => ({
      ...current,
      idcard_type: data.value,
    }));
  };
  const religionChange = (data) => {
    setReligion({ value: data.value, label: data.label })
    setUserDatas((current) => ({
      ...current,
      religion: data.value,
    }));
  };
  const licenseChange = (data) => {
    setError(currentstate => ({
      ...currentstate,
      license: ""
    }))

    setLicense({ value: data.value, label: data.label })
    setUserDatas((current) => ({
      ...current,
      license: data.value,
    }));
  };
  const handleChangeidnum = (e) => {
    setError((currentstate) => ({
      ...currentstate,
      idcard_no: "",
    }));
    setIsValidIdentity(true)


    const x = e.target.value
    const val = x.trim()
    const key = e.target.name;

    setUserDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
    if (
      identityCard !== null &&
      idcard_no !== "" &&
      identityCard === userDatas.idcard_type &&
      idcard_no === val
    ) {

      setError((currentstate) => ({
        ...currentstate,
        idcard_no: "",
      }));
      setIsValidIdentity(true)

      // No need to call handleCheckExistingIdentity
    } else {
      const data = {
        idcard_no: val,
        idcard_type: userDatas.idcard_type,
      };
      axios
        .post(`${APIURL}/api/v1/account/check-id/ `, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === "error") {
              setError((currentstate) => ({
                ...currentstate,
                idcard_no: `This identity number is already registered!`,
              }));
              setIsValidIdentity(false);
            } else {
              setIsValidIdentity(true);
            }
          } else {
            setIsValidIdentity(true);
          }
        })
        .catch((err) => { });
    }
  }
  const uploadImage = async (e) => {

    const key = e.target.name;
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);

    if (!file) {
      if (key === "consent") {

        alert.error(" Select a File");
        setFileNameRes("Upload File");
        return;
      }
      else if (key === "consentwit1") {
        alert.error(" Select a File");
        setFileNameWit1("Upload File");
        return;
      }
      else if (key === "consentwit2") {
        alert.error(" Select a File");
        setFileNameWit2("Upload File");
        return;
      }
      else if (key === "consentgd") {
        alert.error(" Select a File");
        setFileNameGd("Upload File");
        return;
      }
    }

    if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
      alert.error("Select a valid image or PDF file");
      if (key === "consent") {
        alert.error(" Select a File");
        setFileNameRes("Upload File");
        return;
      }
      else if (key === "consentwit1") {
        alert.error(" Select a File");
        setFileNameWit1("Upload File");
        return;
      }
      else if (key === "consentwit2") {
        alert.error(" Select a File");
        setFileNameWit2("Upload File");
        return;
      }
      else if (key === "consentgd") {
        alert.error(" Select a File");
        setFileNameGd("Upload File");
        return;
      }
    }

    if (file.size > 10e6) {
      alert.error("Select a file smaller than 10MB");
      if (key === "consent") {
        alert.error(" Select a File");
        setFileNameRes("Upload File");
        return;
      }
      else if (key === "consentwit1") {
        alert.error(" Select a File");
        setFileNameWit1("Upload File");
        return;
      }
      else if (key === "consentwit2") {
        alert.error(" Select a File");
        setFileNameWit2("Upload File");
        return;
      }
      else if (key === "consentgd") {
        alert.error(" Select a File");
        setFileNameGd("Upload File");
        return;
      }

    }

    const shortFileName = file.name.length > 10 ? file.name.slice(0, 10) + '...' : file.name;
    if (key === "consent") {
    if (file.type.startsWith('image/')) {
     
        setError((currentstate) => ({
          ...currentstate,
          admission_consent_form: "",
        }));
        setFileNameRes(shortFileName);
        setUserDatas((currentData) => ({
          ...currentData,
          admission_consent_form: file, // Store the base64 encoded image data
          // Optional for image preview
        }));
      } if (file.type === 'application/pdf') {
        setError((currentstate) => ({
          ...currentstate,
          admission_consent_form: "",
        }));
        // Handle PDF file
        setFileNameRes(file.name);
        setUserDatas((currentData) => ({
          ...currentData,
          admission_consent_form: file,

        }));

      }
    }
      else if (key === "consentwit1") {
        if (file.type.startsWith('image/')) {
        setError((currentstate) => ({
          ...currentstate,
          witness1_sign: "",
        }));
        setFileNameWit1(shortFileName);
        setUserDatas((currentData) => ({
          ...currentData,
          witness1_sign: file, // Store the base64 encoded image data
          // Optional for image preview
        }));
      } if (file.type === 'application/pdf') {
        setError((currentstate) => ({
          ...currentstate,
          witness1_sign: "",
        }));
        // Handle PDF file
        setFileNameWit1(file.name);
        setUserDatas((currentData) => ({
          ...currentData,
          witness1_sign: file,

        }));
      }
    }
      else if (key === "consentwit2") {
        if (file.type.startsWith('image/')) {
        setFileNameWit2(shortFileName);
        setError((currentstate) => ({
          ...currentstate,
          witness2_sign: "",
        }));
        setUserDatas((currentData) => ({
          ...currentData,
          witness2_sign: file, // Store the base64 encoded image data
          // Optional for image preview
        }));
      } if (file.type === 'application/pdf') {
        setError((currentstate) => ({
          ...currentstate,
          witness2_sign: "",
        }));
        // Handle PDF file
        setFileNameWit2(file.name);
        setUserDatas((currentData) => ({
          ...currentData,
          witness2_sign: file,

        }));
      }
    }
      else if (key === "consentgd") {
        if (file.type.startsWith('image/')) {
        setError((currentstate) => ({
          ...currentstate,
          guardian_sign: "",
        }));
        setFileNameGd(shortFileName);
        setUserDatas((currentData) => ({
          ...currentData,
          guardian_sign: file, // Store the base64 encoded image data
          // Optional for image preview
        }));
      }
       if (file.type === 'application/pdf') {
        setError((currentstate) => ({
          ...currentstate,
          guardian_sign: "",
        }));
        // Handle PDF file
        setFileNameGd(file.name);
        setUserDatas((currentData) => ({
          ...currentData,
          guardian_sign: file,

        }));
      }


    }
  };
  const ViewPopup = (props) => {
    return (
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
          <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
            <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
          </button>
        </Modal.Header>
        <Modal.Body style={{ justifyContent: "left",minHeight:"400px" }}>
          {type === "room" ? <AssignPatient from="admission" reassign={reassign} id={userDatas.user} onSuccess={(data) => { setRefresh(!refresh); setShow(false) ; setError((currentstate) => ({
        ...currentstate,
        room: "",
      }));}} /> 
          : type === "inv"&&!invAdded ? <AddInventory from="admission" patId={userDatas.user} admission={eqDate} onSuccess={(data) => { setShow(false);setInvAdded(true) }} /> 
          : type === "inv"&&invAdded ? <PersonalInventory from="admission" patId={userDatas.user} patName={`${userDatas.first_name} ${userDatas.last_name}`} patRoom={bdUnit} patPhone={userDatas.mobile_number} admission={eqDate} onSuccess={(data) => { setShow(false);setInvAdded(true) }} /> 
          : type === "wound" && woundAdded ? <Report from="admission" id={userDatas.user} onSuccess1={(data) => { setShow(false) }} />
          : type === "wound" && !woundAdded ? <WoundDoc from="admission" id={userDatas.user} onSuccess={(data) => { setShow(false); setWoundAdded(true) }} /> 
          :type==="doc"&&!docAdded? <UserListAddDoc id={userDatas.user} from="admission" onSuccess={(data) => { setShow(false); setDocAdded(true) }} />:<ViewDoc id={userDatas.user} from="admission"/>}
        </Modal.Body>
      </Modal>
    );
  };
  const validatePhoneInput = (value,) => {
    if (value.length !== 2) {
      if (value.length !== 12) {
        return "Invalid phone number"; 

      }
      else {
        return true
      }
    }

  }

  const handlePhoneInputgd = (value, data, event, formattedValue) => {
    setValidgdContact(true)
    setError((currentstate) => ({
      ...currentstate,
      guardian_contact: "",
    }));
    if (value.length === 2) {
      setUserDatas((currentstate) => ({
        ...currentstate,
        guardian_contact: "91"
      }));
    }
    else {
      setUserDatas((currentstate) => ({
        ...currentstate,
        guardian_contact: value
      }));
    }
    if (value.length < 12) {

      setError((currentstate) => ({
        ...currentstate,
        guardian_contact: "contact must be of 10 digit",
      }));
      setValidgdContact(false)
    };
  }
  const validate = async () => {
    let input = userDatas;
    let isValid = true;
    if ((!userDatas.admission_date || userDatas.admission_date === "")) {
      setError((currentstate) => ({
        ...currentstate,
        admission_date: "Enter admission date",
      }));
      isValid = false
    }
    if (!userDatas.pv_admission_number || userDatas.pv_admission_number === "") {
      setError((currentstate) => ({
        ...currentstate,
        pv_admission_number: "Enter admission number",
      }));
      isValid = false
    }
    if (!userDatas.admission_consent_form || userDatas.admission_consent_form === "") {
      setError((currentstate) => ({
        ...currentstate,
        admission_consent_form: "Upload resident's signature",
      }));
      isValid = false
    }
    if (witness1.name !== "" && (userDatas.witness1_sign === ""||userDatas.witness1_sign ===undefined)) {
      setError((currentstate) => ({
        ...currentstate,
        witness1_sign: "Upload witness signature",
      }));
      isValid = false
    }
    if (witness2.name !== "" &&( userDatas.witness2_sign === ""||userDatas.witness2_sign ===undefined)) {
      setError((currentstate) => ({
        ...currentstate,
        witness2_sign: "Upload witness signature",
      }));
      isValid = false
    }
    if (witness2.name !== "" && witness2.contact === "91") {
      setError((currentstate) => ({
        ...currentstate,
        witness2_contact: "Enter witness contact",
      }));
      isValid = false
    }
    if (witness1.name !== "" && witness1.contact === "91") {
      setError((currentstate) => ({
        ...currentstate,
        witness1_contact: "Enter witness contact",
      }));
      isValid = false
    }
    if (userDatas.guardian_name&&userDatas.guardian_name !== "" && (userDatas.guardian_sign === ""||userDatas.guardian_sign === undefined)) {
      setError((currentstate) => ({
        ...currentstate,
        guardian_sign: "Upload guardian signature",
      }));
      isValid = false
    }
    if (userDatas.guardian_name&&userDatas.guardian_name !== "" && (userDatas.guardian_contact === ""||userDatas.guardian_contact === undefined)) {
      setError((currentstate) => ({
        ...currentstate,
        guardian_contact: "Enter guardian contact",
      }));
      isValid = false
    }
    if (userDatas.guardian_name&&userDatas.guardian_name !== "" && (userDatas.guardian_address === ""||userDatas.guardian_address === undefined)) {
      setError((currentstate) => ({
        ...currentstate,
        guardian_address: "Enter guardian address",
      }));
      isValid = false
    }
    if (!userDatas.license || userDatas.license === "") {
      setError((currentstate) => ({
        ...currentstate,
        license: "Select license ",
      }));
      isValid = false
    }
    if (bdUnit === "" || bdUnit === null) {
      setError((currentstate) => ({
        ...currentstate,
        room: "Assign room ",
      }));
      isValid = false
    }
    if (!isValidAd) {

      isValid = false
    }
    if(!isValidIdentity){
      isValid = false
    }
    if (!validWt1contact) {

      isValid = false
    }
    if (!validWt2contact) {
      isValid = false
    }
    if (!validgdcontact) {
      isValid = false
    }

    if (isValid === true) {
      setShowConfirmation(true)
    }
  }
  const handleChangeenqnum = (e) => {
    setError((currentstate) => ({
      ...currentstate,
      pv_admission_number: "",
    }));
    setIsValidAd(true)
    const x=e.target.value
  const val = x.trim()
    const key = e.target.name;
    
      setUserDatas((currentstate) => ({
        ...currentstate,
        [key]: val,
      }));
   
    e.preventDefault();
    
    const data = {
      pv_admission_number:val
    };

    axios
      .post(`${APIURL}/api/v1/account/check-id/ `, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === "error") {
            setError((currentstate) => ({
              ...currentstate,
              pv_admission_number: res.data.message,
            }));
            setIsValidAd(false);
          } else {
            setIsValidAd(true);
          }
        } else {
          setIsValidAd(true);
        }
      })
      .catch((err) => { });
  
    
};

const handleConfirm = () => {
  submit();
};

const handleCancel = () => {
  closeConfirmationPopup()
};

  const submit = () => {
    const formData = new FormData();
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tokenString = sessionStorage.getItem('usertoken');
    let str = tokenString.replace(/["]+/g, '')
    let data1 = JSON.parse(JSON.stringify(userDatas))
    if(data1.guardian_name === ""){
      delete data1.guardian_contact;
      delete data1.guardian_name;
      delete data1.guardian_address;
    }
    if(data1.own_income === null){
      delete data1.own_income;
    }
    delete data1.id;
    delete data1.enquiry_date;
    delete data1.pv_enquiry_number;
    delete data1.first_name;
    delete data1.last_name;
    delete data1.user;
    delete data1.gender;
    delete data1.address;
    if (
      identityCard !== null &&
      idcard_no !== "" &&
      identityCard === userDatas.idcard_no 
      
    ) {
      delete data1.idcard_no;
      delete data1.idcard_type;
    }
    let wit = []

    if (witness1.name && witness1.contact && witness1.contact !== "91" && witness1.name !== "" && witness1.name !== null) {
      wit.push(witness1)
    }

    if (witness2.name && witness2.contact && witness2.contact !== "91" && witness2.name !== "" && witness2.name !== null) {
      wit.push(witness2)
    }
    if(wit.length === 1){
      if(data1.witness2_sign){
        formData.append("witness1_sign",userDatas.witness2_sign)
        delete data1.witness2_sign;       
      } 
    }
    wit.length > 0 && wit.map((item, i) => {
      formData.append(`witness_info[${i}]name`, item.name);

      formData.append(`witness_info[${i}]contact`, item.contact);

    })
    
    Object.keys(data1).forEach(key => {
      formData.append(key, userDatas[key]);
    });
    axios.post(`${APIURL}/api/v1/service-provider/complete-admission/?pv_enquiry_number=${userDatas.pv_enquiry_number}&timeZone=${currentTimezone} `, formData, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {


        if (res.data.status === "success") {
          sessionStorage.removeItem("pv_enquiry_number");
              sessionStorage.removeItem("bedunit");
            alert.success(res.data.message)
           
            navigate("/admission");
          
        


        } else {
          alert.error(res.data.message)
          closeConfirmationPopup()
        }

      })

  }
  return (
    <div style={{ padding: "5%", }}>
      <div className='enquirycontainer' style={{ paddingLeft: "2%", paddingRight: "2%" }}>
        <div style={{ justifyContent: "center" }}>

          <h4
            className=""
          >
            <img style={{ marginLeft: "5px" }} src={peace} className="" width="150px" />
            <label style={{
              color: "blue",
              fontWeight: "600",
              width: "79%",
              textAlign: "center"
            }}>Admission form</label>
          </h4>
        </div>
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>

          <div className='col-6' >
            <label className='text-style-here ' >Enquiry Number</label><br />
            <input disabled style={{ cursor: "not-allowed" }} value={userDatas.pv_enquiry_number} onChange={handleTitle} className='admissioninputs' />
          </div>
          <div className='col-6'>
            <label className='text-style-here ' >Enquiry Date</label><br />
            <input
              className="admissioninputs "
              appearance="default"
              disabled style={{ cursor: "not-allowed" }}
              onClear={() => { }}
              value={userDatas.enquiry_date}
            />
          </div>
        </div><br />
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-6'>
            <label className='text-style-here ' >*Admission Date</label><br />

            <DatePicker

              maxDate={new Date()}
              name="pe_date"
              minDate={new Date(userDatas.enquiry_date)}
              value={eqDate}
              style={{ border: "none" }}
              className="admissioninputs deathtime"
              appearance="default"
              onChange={date => { handleDate(date) }}

              onClear={() => { }}

            />
          </div>
          <div className='col-6' >
            <label className='text-style-here ' >*Admission Number</label><br />
            <input type="text" value={userDatas.pv_admission_number} onChange={handleChangeenqnum} name="pv_admission_number" className='admissioninputs' />
            { }
          </div>

        </div>
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-6'>
            {error.admission_date ? (
              <div className="error-validation-msg"> {error.admission_date}</div>
            ) : null}
          </div>
          <div className='col-6' >
            {error.pv_admission_number &&error.pv_admission_number!==""? (
              <div className="error-validation-msg"> {error.pv_admission_number}</div>
            ) : null}
          </div>

        </div><br />

        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-3' >
            <label className='text-style-here ' >Name</label><br />
            <input value={userDatas.first_name + " " + userDatas.last_name} disabled style={{ cursor: "not-allowed" }} className='admissioninputs' />
          </div>
          <div className='col-3' >
            <label className='text-style-here ' >Gender</label><br />
            <div className='admissioninputs' style={{ paddingTop: "2%" }} >&nbsp;
              <input
                type="radio"
                name="gender"
                value="male"
                checked={userDatas.gender === "male"}
                disabled={userDatas.gender !== ""}
              //onClick={handleGenderRadioButton}
              />&nbsp;
              <label className="text-style-here" htmlFor="">Male</label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                name="gender"
                value="female"
                checked={userDatas.gender === "female"}
                disabled={userDatas.gender !== ""}
              // onClick={handleGenderRadioButton}
              />&nbsp;

              <label className="text-style-here" htmlFor="">Female</label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                name="gender"
                value="others"
                checked={userDatas.gender === "others"}
                disabled={userDatas.gender !== ""}
              //onClick={handleGenderRadioButton}
              />&nbsp;
              <label className="text-style-here" htmlFor="others">Others</label>
            </div>
          </div>
          <div className='col-6'>
            <label className='text-style-here ' >Address</label><br />
            <input disabled type="text" value={userDatas.address} name="address"  className='admissioninputs' />
          </div>
        </div><br />

        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end", }}>
          <div className='col-4 ' >
            <label className='text-style-here ' >Marital Status</label><br />
            <div className='admissioninputs' style={{ paddingTop: "2%" }} >&nbsp;
              <input
                className=''
                type="radio"
                name="marital_status"
                value="married"
                checked={userDatas.marital_status === true}
                onChange={handleRadioButton}
                
              />
              <label className="col-6 text-style-here" htmlFor="">Married</label>

              <input
                className=''
                type="radio"
                name="marital_status"
                value="unmarried"
                checked={userDatas.marital_status === false}
                onChange={handleRadioButton}
              />

              <label className="col-4 text-style-here" htmlFor="">Unmarried</label>
            </div>
          </div>
          <div className='col-4'>
            <label className='text-style-here ' >Age</label><br />
            <input type="number" min={1} value={calculateAge(dob)} name="age" disabled className='admissioninputs' />
          </div>
          <div className='col-4'>
            <label className='text-style-here ' >Account Number</label><br />
            <input type="text" name="bank_account" value={userDatas.bank_account} onChange={handleTitle} className='admissioninputs' />
            {error.bank_account ? (
              <div className="error-validation-msg"> {error.bank_account}</div>
            ) : null}
          </div>
        </div><br />

        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-4' >
            <label className='text-style-here ' >Religion</label><br />
            <Select
              value={religion}
              onChange={(data, e) => {
                religionChange(data, e);
              }}
              options={religionoptions}
              name="religion"
              className="  admissioninputs"

            />
          </div>
          <div className='col-4'>
            <label className='text-style-here ' >Caste</label><br />
            <input disabled={religion.value = ""} name="caste" value={userDatas.caste} onChange={handleTitle} className='admissioninputs' />
          </div>
          <div className='col-4'>
            <label className='text-style-here ' >Group</label><br />
            <input disabled={religion.value = ""} name="group" value={userDatas.group} onChange={handleTitle} className='admissioninputs' />
          </div>
        </div><br />
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-3' >
            <label className='text-style-here ' >Education </label><br />
            <input name="education" value={userDatas.education} onChange={handleTitle} className='admissioninputs' />
          </div>
          <div className='col-3'>
            <label className='text-style-here ' >Occupied job</label><br />
            <input name="job" value={userDatas.job} onChange={handleTitle} className='admissioninputs' />
          </div>
          <div className={userDatas.idcard_type !== "" && userDatas.idcard_type !== null ? 'col-3' : "col-6"}>
            <label className='text-style-here ' >Identity Card</label><br />
            <Select
              value={id}
              onChange={(data, e) => {
                IdentityChange(data, e);
              }}
              options={identityoptions}

              name="identitycard"
              isDisabled={userDatas.idcard_no}
              className="  admissioninputs"

            />

          </div>
          {userDatas.idcard_type !== "" && userDatas.idcard_type !== null ? (
            <div className="col-3" >
              <span className="text-style-here" > Identity Card Number:</span>
              <input
                className="form-control"
                value={userDatas.idcard_no}
                type="text"
                name="idcard_no"
                placeholder="Space not allowed"
                onChange={handleChangeidnum}
                maxLength="20"


              />
              
            </div>
            
          ) : null}
        </div>
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-3' >
          </div>
          <div className='col-3'>
          </div>
          <div className='col-3'>
          </div>
          <div className='col-3'>
            {error.idcard_no ? (
              <div className="error-validation-msg">
                {error.idcard_no}
              </div>       
            ) : null}
            
            </div>
        </div>       
        <br />

        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-6' >
            <label className='text-style-here ' >Source of income?</label><br />
            <input placeholder='If yes explain' value={userDatas.own_income} name="own_income" onChange={handleTitle} className='admissioninputs' />
          </div>
          <div className='col-6'>
            <label className='text-style-here ' >*Resident's signature</label><br />
            <div
              style={{ backgroundColor: "#E4DD9F", width: "100%" }}
              className="admissioninputs custom-file"
            >
              <input
                type="file"
                name="consent"
                className="admissioninputs"
                id="inputGroupFile"
                onChange={uploadImage}
              />
              <label
                style={{
                  backgroundColor: "#1269B9",
                  color: "white",
                  border: "1px dotted white",
                }}
                className="custom-file-label"
                htmlFor="inputGroupFile"
                aria-describedby="inputGroupFileAddon"
              >
                {fileNameRes}{" "}
              </label>
            </div>
            
          </div>
        </div>
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-6' >            
          </div>
          <div className='col-6'>
            {error.admission_consent_form ? (
              <div className="error-validation-msg"> {error.admission_consent_form}</div>
            ) : null} 
          </div>          
        </div><br />

        <h6>Witness / Social worker’s information :-</h6>
        <div style={{ backgroundColor: "rgba(217, 217, 217, 0.34)", padding: "1%", borderRadius: "8px" }}>
          <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
            <div className='col-4' >

              <input placeholder='1.Name' name="name1" value={witness1.name} onChange={handleTitlewit} className='admissioninputs' />
            </div>
            <div className='col-4'>

              <div
                style={{ backgroundColor: "#E4DD9F", width: "100%" }}
                className="admissioninputs custom-file"
              >
                <input
                  disabled={witness1.name === ""}
                  style={{ cursor: witness1.name === "" ? "not-allowed" : "pointer" }}
                  type="file"
                  name="consentwit1"
                  className="admissioninputs"
                  id="inputGroupFile012"
                  onChange={uploadImage}
                />
                <label
                  style={{
                    backgroundColor: "#1269B9",
                    color: "white",
                    border: "1px dotted white",
                  }}
                  className="custom-file-label"
                  htmlFor="inputGroupFile012"
                  aria-describedby="inputGroupFileAddon01"
                >
                  {fileNamewit1}{" "}
                </label>
              </div>              
            </div>
            <div className='col-4'>
              <PhoneInput
                disabled={witness1.name === ""}
                country={'in'}
                onlyCountries={['in']}
                countryCodeEditable={false}
                value={witness1.contact}
                name="contact"
                onChange={(value) => {
                  setValidWt1Contact(true)
                  setError((currentstate) => ({
                    ...currentstate,
                    witness1_contact: "",
                  }));
                  const numericValue = value.replace(/\D/g, "");
                  if (numericValue.length < 12 && numericValue.length !== 2) {
                    setwitness1({
                      ...witness1,
                      conatct: "91",

                    });
                    setError((currentstate) => ({
                      ...currentstate,
                      witness1_contact: "Enter 10 digits ",
                    }));
                    setValidWt1Contact(false)
                  } else if (numericValue.length === 2) {
                    setwitness1({
                      ...witness1,
                      contact: "91",
                      
                    });
                    setError((currentstate) => ({
                      ...currentstate,
                      witness1_contact: "",
                    }));
                  } else {
                    setwitness1({
                      ...witness1,
                      contact: value, // Update the input value asEntered
                     
                    });
                    setError((currentstate) => ({
                      ...currentstate,
                      witness1_contact: "",
                    }));
                  }
                }}
                className='enquiryinput'
                style={{ width: "100%", margin: 0 }}
              />
                            
            </div>
           
          </div><div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
            <div className='col-4' >
            </div>
            <div className='col-4'>
              {error.witness1_sign && error.witness1_sign !== null && error.witness1_sign !== "" ? (
                <div className="error-validation-msg"> {error.witness1_sign}</div>
              ) : null}
            </div>
            <div className='col-4'>              
              {error.witness1_contact && error.witness1_contact !== null && error.witness1_contact !== "" ? (
                <div className="error-validation-msg"> {error.witness1_contact}</div>
              ) : null}
              
            </div>
           
          </div><br />
          <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
            <div className='col-4' >

              <input placeholder='2.Name' value={witness2.name} name="name2" onChange={handleTitlewit} className='admissioninputs' />
            </div>
            <div className='col-4'>

              <div
                style={{ backgroundColor: "#E4DD9F", width: "100%" }}
                className="admissioninputs custom-file"
              >
                <input
                  disabled={witness2.name === ""}
                  style={{ cursor: witness2.name === "" ? "not-allowed" : "pointer" }}
                  type="file"
                  name="consentwit2"
                  className="admissioninputs"
                  id="inputGroupFile022"
                  onChange={uploadImage}
                />
                <label
                  style={{
                    backgroundColor: "#1269B9",
                    color: "white",
                    border: "1px dotted white",
                  }}
                  className="custom-file-label"
                  htmlFor="inputGroupFile022"
                  aria-describedby="inputGroupFileAddon02"
                >
                  {fileNamewit2}{" "}
                </label>
              </div>
            </div>
            <div className='col-4'>
              <PhoneInput
                disabled={witness2.name === ""}
                country={'in'}
                onlyCountries={['in']}
                countryCodeEditable={false}
                value={witness2.contact}
                name="contact"
                onChange={(value) => {
                  setError((currentstate) => ({
                    ...currentstate,
                    witness2_contact: "",
                  }));
                  setValidWt2Contact(true)
                  const numericValue = value.replace(/\D/g, "");
                  if (numericValue.length < 12 && numericValue.length !== 2) {
                    setwitness2({
                      ...witness2,
                      conatct: "91",

                    });
                    setError((currentstate) => ({
                      ...currentstate,
                      witness2_contact: "Enter 10 digits ",
                    }));
                    setValidWt2Contact(false)
                  } else if (numericValue.length === 2) {
                    setwitness2({
                      ...witness2,
                      contact: "91",
                      
                    });
                    setError((currentstate) => ({
                      ...currentstate,
                      witness2_contact: "",
                    }));
                  } else {
                    setwitness2({
                      ...witness2,
                      contact: value, // Update the input value asEntered
                     
                    });
                    setError((currentstate) => ({
                      ...currentstate,
                      witness2_contact: "",
                    }));
                  }
                }}
                className='enquiryinput'
                style={{ width: "100%", margin: 0}}
              />
            </div>
          </div>
        </div>
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
            <div className='col-4' >
            </div>
            <div className='col-4'>
              {error.witness2_sign && error.witness2_sign !== null && error.witness2_sign !== "" ? (
                <div className="error-validation-msg"> {error.witness2_sign}</div>
              ) : null}
            </div>
            <div className='col-4'>              
              {error.witness2_contact && error.witness2_contact !== null & error.witness2_contact !== "" ? (
                <div className="error-validation-msg"> {error.witness2_contact}</div>
              ) : null}
            </div>
          </div>
        
        <br />
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-12' >
            <label className='text-style-here ' >*license / programme </label><br />
            <Select
              value={license}
              onChange={(data, e) => {
                licenseChange(data, e);
              }}
              options={licenseoptions}
              name="license"
              className="  admissioninputs"

            />
            {error.license ? (
              <div className="error-validation-msg"> {error.license}</div>
            ) : null}
          </div>
        </div><br />
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-12' >
            <label className='text-style-here ' >Basis for Admission</label><br />
            <input value={userDatas.basis_for_admission} name="basis_for_admission" onChange={handleTitle} className='admissioninputs' />
          </div>
        </div><br />
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end", paddingLeft: "1%", paddingRight: "1%" }}>
          <div className='col-3' style={{ padding: "0.2%" }}>
            <button style={{cursor:"pointer"}} onClick={() => {
              if (bdUnit === "") {
                setType("room"); setShow(true)
              }
              else { setViewRoom(true)}
            }} className=' ads-button'>{bdUnit === "" ? "Add room" : "View room"}</button>
          </div>
          <div className='col-3' style={{ padding: "0.2%" }}>
            <button style={{cursor:bdUnit===""?"not-allowed":"pointer"}} disabled={bdUnit===""} onClick={() => {if(eqDate===""||eqDate===null){
              alert.error("Enter admission date")
            }else{
              setType("inv"); setShow(true)}
            }} className=' ads-button'>{invAdded?"View inventory":"Add inventory"}</button>
          </div>
          <div className='col-3' style={{ padding: "0.2%" }}>
            <button style={{cursor:bdUnit===""?"not-allowed":"pointer"}} disabled={bdUnit===""}onClick={() => {
              setType("doc"); setShow(true)
            }} className='ads-button'>{docAdded?"View documents":"Add documents"}</button>
          </div>
          <div className='col-3' style={{ padding: "0.2%" }}>
            <button style={{cursor:bdUnit===""?"not-allowed":"pointer"}} disabled={bdUnit===""} onClick={() => {
              setType("wound"); setShow(true)
            }} className='ads-button'>{woundAdded ? "View wound" : "Add wound"}</button>
          </div>
        </div>
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end", paddingLeft: "1%", paddingRight: "1%" }}>
          <div className='col-3' style={{ padding: "0.2%" }}>
            {error.room&&error.room!=="" ? (
              <div className="error-validation-msg"> {error.room}</div>
            ) : null}
          </div>
          <div className='col-3' style={{ padding: "0.2%" }}></div>
          <div className='col-3' style={{ padding: "0.2%" }}></div>
          <div className='col-3' style={{ padding: "0.2%" }}></div>
        </div>
        <br />
        <h6 style={{ textDecoration: "underLine" }}>Guardian Declaration</h6>
        <label className='text-style-here ' >I affirm, to the best of my knowledge and belief, the accuracy of the aforementioned details. I acknowledge that any consequences arising from admitting the person to Peace Village, including disciplinary issues as per the institution's policies, or in the event of death, I am solely prepared to accept all responsibilities.</label><br />
        <label className='text-style-here ' >By</label><br />
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-6' >
            <label className='text-style-here ' >Name</label><br />
            <input value={userDatas.guardian_name} name="guardian_name" onChange={handleTitle} className='admissioninputs' />
          </div>
          <div className='col-6'>
            <label className='text-style-here ' >Address</label><br />
            <input  disabled={!userDatas.guardian_name||userDatas.guardian_name===""} value={userDatas.guardian_address} name="guardian_address" onChange={handleTitle} className='admissioninputs' />
          </div>
        </div>
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-6' ></div>
          <div className='col-6'>
          {error.guardian_address && error.guardian_address !== null & error.guardian_address !== "" ? (
                <div className="error-validation-msg"> {error.guardian_address}</div>
              ) : null}
          </div>
        </div><br />
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-6' >
            <label className='text-style-here ' >Signature</label><br />
            <div
              style={{ backgroundColor: "#E4DD9F", width: "100%" }}
              className="admissioninputs custom-file"
            >
              <input
               disabled={!userDatas.guardian_name||userDatas.guardian_name===""}
                type="file"
                name="consentgd"
                className="admissioninputs"
                id="inputGroupFile03"
                onChange={uploadImage}
              />
              <label
                style={{
                  backgroundColor: "#1269B9",
                  color: "white",
                  border: "1px dotted white",
                }}
                className="custom-file-label"
                htmlFor="inputGroupFile03"
                aria-describedby="inputGroupFileAddon03"
              >
                {fileNameGd}{" "}
              </label>
            </div>            
          </div>
          <div className='col-6'>
            <label className='text-style-here ' >Phone number</label><br />
            <PhoneInput
             disabled={!userDatas.guardian_name||userDatas.guardian_name===""}
              style={{ marginBottom: "0px" }}
              country={"in"}
              name="guardian_contact"
              onlyCountries={["in"]}
              countryCodeEditable={false}
              value={
                userDatas.guardian_contact

              }
              onChange={handlePhoneInputgd}
              isValid={validatePhoneInput}

            />             
          </div>
        </div>
        <div className='row' style={{ justifyContent: 'flex-end', alignItems: "flex-end" }}>
          <div className='col-6' >
          {error.guardian_sign && error.guardian_sign !== null & error.guardian_sign !== "" ? (
                <div className="error-validation-msg"> {error.guardian_sign}</div>
              ) : null}
          </div>
          <div className='col-6'>
          {error.guardian_contact && error.guardian_contact !== null & error.guardian_contact !== "" ? (
                <div className="error-validation-msg"> {error.guardian_contact}</div>
              ) : null}
          </div>
        </div><br />
        <div style={{ justifyContent: "center", textAlign: "center" }}>

          <button className='ads-button' onClick={() => { validate() }} style={{ width: "15%" }}>Save</button>

        </div>
      </div>
      {
  browserBackPopup === true ? (
    <BrowserBackOptionPopup
      show={browserBackPopup}
      onHide={() => {
        setBrowserBackPopup(false);
      }}
    />
  ) : null
}
      {show ? (
        <ViewPopup
          show={show}
          onHide={() => {
            setShow(false);
          }}
        />
      ) : (
        ""
      )}
        {viewRoom? (
        <RoomPopup
          show={viewRoom}
          onHide={() => {
            setViewRoom(false);
          }}
        />
      ) : (
        ""
      )}
      {showConfirmation && (
      <SubmitConfirmationPopup
        message="Are you sure you want to submit the form?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      )}
    </div>
  )
}
export default AdmissionForm
