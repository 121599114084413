import React,{useState, useContext} from 'react'
import Select from 'react-select';
import axios from "axios";
import {APIURL} from "../../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useAlert } from 'react-alert';
import SubmitConfirmationPopup from '../../common/SubmitConfirm';
import { ConfirmationContext } from '../../contexts/SubmitConfirmationContext';
function AddComo(props) {

    const[data, setData]= useState({
      
      comorbidityname : '',
      comorbiditytype  :'',
      comorbidityindex : '',
      comorbiditystage  : ''

    })
    const alert = useAlert();
    const [error,setError]=useState({
      comorbidityname : ''
        
       });

    const[errorValidate,setErrorValidate]=useState(false)
    const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);


      const handleError = () =>
  {
    setErrorValidate(false);
  }
      
      const handleTitle = (e)=> {
        
        e.preventDefault();        
        const val=e.target.value;
        const key=e.target.name;
        setData( current => ({
          
            ...current , [key] : val
        }))
      }


      const handleSave=() =>
{
 
}
  
  const validate=()=>{
    
    let input = data;
    let errors = {};
    let isValid = true;
  setError({});


    if (!input["comorbidityname"] || input["comorbidityname"]==="" ) {
      isValid = false;
      errors["comorbidityname"] = "Enter Comorbidity Name";
    }
    
    

     setError(currentstate=>({
           ...currentstate,
          ...errors
        }));
    

    return isValid;
}
const handleContinue = () => {
  if(validate()){ 
    setShowConfirmation(true)
  };
}

const handleConfirm = () => {
  saveChanges();
};

const handleCancel = () => {
  closeConfirmationPopup()
};

  const saveChanges = ()=> {
 
        const tokenString = sessionStorage.getItem("usertoken");
 
     let v2 = tokenString.replace(
       /["]+/g,
       ""
     ); /* REGEX To remove double quotes from tokenstring */
 
  const dataToSend = {
    comorbidity_name: data.comorbidityname.slice(0,1).toUpperCase() +data.comorbidityname.slice(1, data.comorbidityname.length),
    comorbidity_type:data.comorbiditytype,
    comorbidity_index:data.comorbidityindex,
    comorbidity_stage:data.comorbiditystage,
       
        

  }
 
     axios
       .post(`${APIURL}/api/v1/staff/master-comorbidities/`, dataToSend , {
         headers: { Authorization: "Token " + v2 },
       })
       .then((res) => {
         if (res.data.status === "success") {
          alert.success('Saved successfully');
          props.modalClose();
         setData({
          comorbidityname : '',
          comorbiditytype : '',
          comorbidityindex : '',
          comorbiditystage : ''

      });
        
         } else {
           alert.error('Check if duplicated')
         }
       })
       .catch((err) => {
        alert.error('Check if duplicated')
       });
}

    return (
        <>
    <h4 style={{marginTop:'0'}} className="title-of-page">Add comorbidities</h4>
        <div style={{minHeight:'fit-content', width:'100%'}} className='config-box fit-content'>
            
            <div className='feature-data pad-features'>
                <div className="formField" >
                  <div style={{display:"flex"}}>
                    <label className='col-4'>*Comorbidity Name: </label>
                    <input type='text' className='form-control col-8' name='comorbidityname' value={data.comorbidityname} onChange={handleTitle} /></div>
                    {error.comorbidityname ? <div style={{marginLeft:"15%"}} className="error-validation-msg">{error.comorbidityname}</div> : null}
                </div>

                <div className="formField" >
                <div style={{display:"flex"}}>
                    <label className='col-4 '>Comorbidity Type: </label>
                    <input type='text' className='form-control col-8' name='comorbiditytype' value={data.comorbiditytype} onChange={handleTitle} /></div>
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" >
                <div style={{display:"flex"}}>
                    <label className='col-4'>Comorbidity Index: </label>
                    <input type='text' className='form-control col-8' name='comorbidityindex' value={data.comorbidityindex} onChange={handleTitle} /></div>
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
                <div className="formField" >
                <div style={{display:"flex"}}>
                    <label className='col-4 '>Comorbidity Stage : </label>
                    <input type='text' className='form-control col-8' name='comorbiditystage' value={data.comorbiditystage} onChange={handleTitle} /></div>
                    {/* {error.title ? <div className="error-validation-msg error-feature-inventory">{error.title}</div> : null} */}
                </div>
            </div>
          </div>
          <div style={{textAlign:'right'}}>
          <button onClick={props.modalClose} className='btn btn-secondary mb-2 mr-2'>Cancel</button>
          <button onClick={handleContinue} className='btn btn-primary btn-col mb-2'>Save</button>   
        </div>
        {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to add the comorbidity?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}
        </>
    )
}

export default AddComo
