import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoIosCloseCircleOutline } from "react-icons/io";

const OpBookingConfirmPopup = ({ close, confirm, content }) => {

    return (
        <div>
            <Modal
                show={true} centered
            >
                <Modal.Body style={{ textAlign: "left" }}>
                    <div style={{ display: "flex" }} >
                        <label style={{ flex: 11, textAlign: "center", fontSize: "24px", fontWeight: 400 }} className='blue-label'>Confirm Booking</label>
                        {/* <label style={{ flex: 1, textAlign: "right" }}><IoIosCloseCircleOutline onClick={() => close()} style={{ width: "20px", height: "20px", cursor: "pointer" }} /></label> */}
                    </div>
                    <div>
                        {Object.entries(content).map(([key, value]) => (
                            <div key={key} style={{ display: "flex" }}>
                                <label style={{ flex: 6, textTransform: "capitalize" }}>{key.replace('_', ' ')}</label>
                                <label style={{ flex: 6 }}><strong> :{' '}{value}</strong></label>
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div className='col-6'></div>
                        <button className='col-3' style={{ display: "block", height: "40px", margin: '10px 3px 0px 0px', borderRadius: "3px", color: "#fff", backgroundColor: '#dc3545', width: "100px" }} onClick={() => close()}> Cancel</button>
                        <button className='col-3' style={{ display: "block", height: "40px", margin: '10px 0px 0px 3px', borderRadius: "3px", color: "#fff", backgroundColor: '#2b69a3', width: "100px" }} onClick={() => confirm()}> Confirm</button>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default OpBookingConfirmPopup;
