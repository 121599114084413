import { useState, useEffect, useContext } from "react"
import axios from "axios";
import { APIURL } from "../../../Global";
import { Button } from 'react-bootstrap';
import { ConfirmationContext } from "../../contexts/SubmitConfirmationContext";
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
function EditService({ onSuccess,service_name1,id,consent_Level1 }) {
    
    const [serviceName, setServiceName] = useState(service_name1)
    const [error, setError] = useState("")
    const [consent, setConsent] = useState(0)
    const [consentLevel, setConsentLevel] = useState(consent_Level1)
    const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);

    useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );

        axios.get(`${APIURL}/api/v1/staff/consent-level/`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.status === 200) {
                    const datas = res.data
                    getConsentLevel(datas);
                   
                    //setConsent(datas.consent_level)
                }
                else {

                }
            })
            .catch((err) => {

            })

    }, [])

    const getConsentLevel = (datas) => {
        let consentarr = datas.map(item =>{
            return {label: item.consent_level, value: item.id, users :item.level_users}
        });
        setConsent(consentarr);
    }
    const handleChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
      
        if (key === "Servicename") {
            setServiceName(val);
        }
        if (key === "Consentlevel") {
            setConsentLevel(val);
            
           
        }
    };

    const handleContinue = (e) => {
        e.preventDefault()
        setShowConfirmation(true)
      }
      
      const handleConfirm = () => {
        editService();
      };
    
      const handleCancel = () => {
        closeConfirmationPopup()
      };

   
    const editService = () => {


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */
        let dataToSend={}
        if (serviceName!==service_name1&&consentLevel===consent_Level1){
           dataToSend={
            service_name: serviceName,
           }}
        if(consentLevel!==consent_Level1&&serviceName===service_name1){
            dataToSend={
                consent_level: consentLevel
           }
        }else{
           dataToSend = {
            service_name: serviceName,
            consent_level: consentLevel

        }}

        axios
            .put(`${APIURL}/api/v1/staff/service-list-detail/${id}/`, dataToSend, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {

                    onSuccess();
                    //setRefresh(true)
                    //setCompleteShow(true)
                    //setSuccessShow(false)
                } else {
                    alert.error(res.data.message ? res.data.message : "Error occured")
                    closeConfirmationPopup()
                }
            })
            .catch((err) => {
                alert.error("Error occured, Try again")
                closeConfirmationPopup()
                //setErrorShow(true)
            });
        // setSuccessShow(false)






    }

    return (
        <div className="appForm" >
            <div className="">

            <div className="title-of-tasks" style={{}}><h5>Edit Service</h5></div><br/>
                <form className="formFields" onSubmit={handleContinue}>

                    <div className="formField">

                        <input
                            type="text"
                            className="formFieldInput"
                            name="Servicename"
                            value={serviceName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="formField">
                        <label  className="formFieldInput">
                            Consent Level:{" "}
                            {consent && <select  className="formFieldInput"
                                                name="Consentlevel" 
                                                value={consentLevel} 
                                                defaultValue="null"
                                                onChange={handleChange}>
                                
                                {consent.map(item => (
                                    <option value={item.value}>
                                        {item.label}
                                    </option>
                                ))}
                               
                            </select>
                           
                           
                            }
                           
                            
                        </label>

                    </div>
                   
                    <div className="form-heading title-of-tasks" >
                        <button className=" btn formFieldButton">Save Changes</button>
                        {error ? (
                            <div className="error-validation-msg">
                                {error}
                            </div>
                        ) : null}




                    </div>


                </form>

            </div>
            {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to edit?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}

        </div>

    )
}
export default EditService