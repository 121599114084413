import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../Global";
import "../PRO/Dialysis/Dialysis.css"
import 'react-tooltip/dist/react-tooltip.css'
import PriorityQueue from '../PRO/Dialysis/PriorityQueue';
import { Tooltip } from 'react-tooltip'
import PreviewQueue from '../PRO/Dialysis/PreviewQueue';
import ZoomableImage from "../BDO/Admission/ZoomableImage";
import { Button } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { Modal } from 'react-bootstrap';
import DialysisFormView from "../PRO/Dialysis/DialysisFormView"
import DialysisCommentPopup from '../PRO/Dialysis/DialysisCommentPopup';
import SubmitConfirmationPopup from "../common/SubmitConfirm";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAlert } from 'react-alert';
import RejectpopUp from '../PRO/Dialysis/RejectPopup'
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { FaRegFileAlt } from "react-icons/fa";
function Palliative() {
    const [showBackPopup, setShowBackPopup] = useState(false)
    const [selected, setSelected] = useState("applications")
    const [applications, setApplications] = useState([])
    const [approved, setApproved] = useState([])
    const [pending, setPending] = useState([])
    const [rejected, setRejected] = useState([])
    const [approvedQueue, setApprovedQueue] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState({})
    const [search, setSearch] = useState('')
    const [formShow, setFormShow] = useState(false)
    const [addCommentShow, setAddCommentShow] = useState(false)
    const [comments, setComments] = useState({})
    const [patientDetails, setPatientDetails] = useState({})
    const [selectedPatient, setSelectedPatient] = useState({})
    const [popStateEventFired, setPopStateEventFired] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [rejectApproval, setRejectApproval] = useState('')
    const userData = JSON.parse(sessionStorage.getItem("logindatas"));
    const isOfficialAccess = userData.is_official_access;
    const navigate = useNavigate()
    const role = userData.role
    const alert = useAlert()


    useEffect(() => {
        if (search === '') {
            setSelected('applications')
            if (role === 'bdo') {
                allapps()
            } else if (role === 'pro') {
                application()
            } else if (role == 'manager') {
                request()
            }
        }
    }, [search]);

    useEffect(() => {
        if (search === '') {
            if (role == 'higher_official') {
                request()
            }
        }
    }, [search]);

    const reload = () => {
        setSearch('')
        request()
    }

    const handlePopState = (e) => {
        if (!popStateEventFired) {
            console.log('User clicked back button');
            setPopStateEventFired(true);
            setShowBackPopup(true);
        }
    };

    useEffect(() => {
        if (role === "higher_official") {
            window.history.pushState(null, "", window.location.href);
            const handlePopStateOverride = () => {
                window.history.pushState(null, "", window.location.href);
            };
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('popstate', handlePopStateOverride);

            return () => {
                window.removeEventListener('popstate', handlePopState);
                window.removeEventListener('popstate', handlePopStateOverride);
            };
        }
    }, [role]);

    const handleConfirm = () => {
        setShowBackPopup(false);
        navigate("../");
    };

    const handleCancel = () => {
        setRejectApproval(false)
        setPopStateEventFired(false);
        setShowBackPopup(false);
    };

    const FormPopUp = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ marginLeft: "auto" }}>Application Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "left" }}>
                    <DialysisFormView content={patientDetails} />
                </Modal.Body>
            </Modal>
        );
    };

    const AddPopup = (props) => {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                dialogClassName="remark-width"
            >
                <Modal.Body className="text-center">
                    <DialysisCommentPopup
                        initialComment={
                            role === "pro"
                                ? (comments[selectedPatient.id] !== '' && comments[selectedPatient.id] !== null && comments[selectedPatient.id] !== undefined)
                                    ? comments[selectedPatient.id]
                                    : (selectedPatient.enquiry_comments !== '' && selectedPatient.enquiry_comments !== null && selectedPatient.enquiry_comments !== undefined)
                                        ? selectedPatient.enquiry_comments
                                        : (comments[selectedPatient.id] !== '' && comments[selectedPatient.id] !== null && comments[selectedPatient.id] !== undefined)
                                            ? comments[selectedPatient.id]
                                            : ''
                                : (selectedPatient.id in comments)
                                    ? comments[selectedPatient.id]
                                    : (selectedPatient.manager_comments !== '' && selectedPatient.manager_comments !== null && selectedPatient.manager_comments !== undefined)
                                        ? selectedPatient.manager_comments
                                        : ''
                        }
                        modalClose={() => setAddCommentShow(false)}
                        handleCommentSave={(text) => handleCommentSave(selectedPatient.id, text)}
                    />
                </Modal.Body>
            </Modal>
        );
    };

    const EnquiryReportView = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}

            >
                <Modal.Body
                    style={{ padding: "7%" }}
                >
                    <div style={{ textAlign: "center", color: "red" }}>
                        {imageUrl === null ? "No preview available" : <ZoomableImage src={imageUrl} />}
                    </div>
                </Modal.Body>
                <Modal.Footer><Button variant="primary" onClick={closeModal}>
                    Ok
                </Button></Modal.Footer>
            </Modal>
        );
    };

    const handleCommentSave = (id, text) => {
        setComments(currentstate => ({
            ...currentstate,
            [id]: text,
        }));

        console.log("Saved comment:", comments[id]);
    };

    const handleClickRejected = () => {
        setSelected("rejected");
        if (search === '') {
            setRejected([]);
            rejectedRequests()
        }
    }

    const handlePatientSearch = (e) => {
        const val = e.target.value
        setSearch(val)
        if (val !== '') {
            const tokenString = sessionStorage.getItem("usertoken");
            let str = tokenString.replace(/["]+/g, "");

            axios
                .get(`${APIURL}/api/v1/service-provider/palliative-enquiry/?search=${val}`, {
                    headers: {
                        Authorization: "Token " + str,
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        let filteredData1 = []
                        let pendingData = []
                        let approvedData = []
                        if (role === 'bdo') {
                            filteredData1 = res.data.filter(x => {
                                return x.approval_status === "pending"
                            })
                        } else if (role === 'pro') {
                            filteredData1 = res.data.filter(x => {
                                return x.enquiry_status === "pending" && x.approval_status === "pending"
                            })
                        } else if (role === 'manager') {
                            filteredData1 = res.data.filter(x => {
                                return x.enquiry_status === "approved" && x.approval_status === "pending"
                            })
                        } else if (role === 'higher_official') {
                            const filteredData = res.data.filter(x => {
                                return x.enquiry_status === "approved" && x.approval_status === "pending"
                            })
                            const approvedIds = new Set(approved.map(item => item.id));
                            const pendingIds = new Set(pending.map(item => item.id));

                            filteredData1 = filteredData.filter(item =>
                                !approvedIds.has(item.id) && !pendingIds.has(item.id)
                            );
                            approvedData = filteredData.filter(item =>
                                approvedIds.has(item.id)
                            );
                            pendingData = filteredData.filter(item =>
                                pendingIds.has(item.id)
                            );

                        }
                        const filteredData2 = res.data.filter(x => {
                            return x.approval_status === "rejected" || x.enquiry_status === "rejected"
                        })
                        const filteredData3 = res.data.filter(x => {
                            return x.approval_status === "approved"
                        })
                        setApplications(filteredData1)
                        setRejected(filteredData2)
                        setApprovedQueue(filteredData3)

                        if ((filteredData1.length !== 0) || pendingData.length !== 0 || approvedData.length !== 0) {
                            setSelected('applications')
                        }
                        else if (filteredData3.length !== 0) {
                            setSelected('priority')
                        }
                        else if (filteredData2.length !== 0) {
                            setSelected('rejected')
                        }
                    }
                })
                .catch((err) => { });
        }
    }

    const application = async (currentPage) => {

        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/service-provider/palliative-enquiry/?enquiry_status=pending`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    const filteredData = res.data
                    setApplications(filteredData)
                }
            })
            .catch((err) => { });
    }

    const allapps = (currentPage) => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/service-provider/palliative-enquiry/?approval_status=pending`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    const filteredData = res.data.filter(item => item.enquiry_status !== 'rejected');
                    setApplications(filteredData)
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };


    const request = (currentPage) => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/service-provider/palliative-enquiry/?approval_status=pending`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    const filteredData = res.data.filter(item => item.enquiry_status === 'approved');

                    const approvedIds = new Set(approved.map(item => item.id));
                    const pendingIds = new Set(pending.map(item => item.id));

                    const remainingData = filteredData.filter(item =>
                        !approvedIds.has(item.id) && !pendingIds.has(item.id)
                    );

                    setApplications(remainingData);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const rejectedRequests = async () => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        try {
            const [approvalRes, enquiryRes] = await Promise.all([
                axios.get(`${APIURL}/api/v1/service-provider/palliative-enquiry/?approval_status=rejected`, {
                    headers: {
                        Authorization: "Token " + str,
                    },
                }),
                axios.get(`${APIURL}/api/v1/service-provider/palliative-enquiry/?enquiry_status=rejected`, {
                    headers: {
                        Authorization: "Token " + str,
                    },
                })
            ]);

            if (approvalRes.status === 200 && enquiryRes.status === 200) {
                const approvalData = approvalRes.data;
                const enquiryData = enquiryRes.data;

                const combinedData = [...approvalData, ...enquiryData];
                const uniqueData = combinedData.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.id === item.id
                    ))
                );

                setRejected(uniqueData);
            }
        } catch (err) {
            console.error("Error fetching rejected requests:", err);
        }
    };

    // const submit = (status, item) => {
    //     if (status === "approved") {
    //         setApproved(prevApproved => [...prevApproved, item]);
    //     } else {
    //         setPending(prevPending => [...prevPending, item]);
    //     }
    //     setApplications(prevApplications => prevApplications.filter(req => req.id !== item.id));
    // };

    const submit = (status, item) => {
        if (status === "approved") {
            setApproved((prevApproved) => [...prevApproved, item]);
            setApplications((prevApplications) => prevApplications.filter(app => app.id !== item.id));
        } else if (status === "pending") {
            setPending((prevPending) => [...prevPending, item]);
            setApplications((prevApplications) => prevApplications.filter(app => app.id !== item.id));
        }
    };

    const confirmRejectApproval = (item) => {
        setSelectedPatient(item)
        setRejectApproval(true)
    }

    const handleRejectApproval = (data) => {
        const id = selectedPatient.id
        const tokenString = sessionStorage.getItem('usertoken')
        let str = tokenString.replace(/["]+/g, '')
        const formData = new FormData();
        formData.append("approval_status", "rejected");
        formData.append("chairman_comment", data);
        axios.put(`${APIURL}/api/v1/service-provider/palliative-enquiry-detail/${id}/`, formData, {
            headers: {
                "Authorization": 'Token ' + str
            }
        })
            .then(res => {
                if (res.data.status === "success") {
                    setRejectApproval(false)
                    alert.success("successfully Updated")
                    request()
                } else {
                    alert.error(res.data.message)
                }
            })
    }

    const handleSave = (id) => {

        if (uploadedFiles[id] === undefined) {
            alert.error("Upload enquiry report")
        } else {
            const tokenString = sessionStorage.getItem('usertoken')
            let str = tokenString.replace(/["]+/g, '')
            const formData = new FormData();
            formData.append("enquiry_status", "approved");
            formData.append("enquiry_report", uploadedFiles[id]);

            if (role === "pro" && comments[id]) {
                formData.append("enquiry_comments", comments[id]);
            }
            if (role === "manager" && comments[id]) {
                formData.append("manager_comments", comments[id]);
            }

            axios.put(`${APIURL}/api/v1/service-provider/palliative-enquiry-detail/${id}/`, formData, {
                headers: {
                    "Authorization": 'Token ' + str,
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(res => {
                    if (res.data.status === "success") {
                        alert.success("successfully Updated")
                        setSearch('')
                        application()
                    } else {
                        alert.error(res.data.message)
                    }
                })
        }
    }

    const handleSaveComment = (id) => {


        const tokenString = sessionStorage.getItem('usertoken')
        let str = tokenString.replace(/["]+/g, '')
        const formData = new FormData();
        formData.append("manager_comments", comments[id]);

        axios.put(`${APIURL}/api/v1/service-provider/palliative-enquiry-detail/${id}/`, formData, {
            headers: {
                "Authorization": 'Token ' + str,
                "Content-Type": "multipart/form-data"
            }
        })
            .then(res => {
                if (res.data.status === "success") {
                    alert.success("successfully Updated")
                    if (role === 'pro') {
                        application()
                    } else if (role == 'manager') {
                        request()
                    }
                } else {
                    alert.error(res.data.message)
                }
            })
    }

    const togglePopup = (item) => {
        setPatientDetails(item)
        setFormShow(true);
    };

    const handleAdd = (item) => {
        setSelectedPatient(item)
        setAddCommentShow(true);
    };

    const uploadEnquiryReport = async (e, item) => {
        const file = e.target.files[0];

        if (!file) {
            alert.error("Please Select a File");
            return;
        }

        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
            alert.error("Select a valid image or PDF file");
            return;
        }

        if (file.size > 10e6) {
            alert.error("Select a file smaller than 10MB");
            return;
        }

        const shortFileName = file.name.length > 10 ? file.name.slice(0, 10) + '...' : file.name;

        setUploadedFiles(prevState => ({
            ...prevState,
            [item.id]: file,
        }));

        alert.success(`File ${shortFileName} uploaded successfully`);
    };

    const viewEnquiryReport = (item) => {
        setImageUrl(item.enquiry_report);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setImageUrl('');
    };

    return (
        <div className='dialysishome-container'>
            <div className='row application-contents'>
                <div className="col-lg-2 col-md-2" style={{ padding: 0, textAlign: 'center' }}>
                    <label className='contents-head' style={{ margin: 0, fontSize: '22px' }}>Palliative Care</label>
                    <div className="side-tabs" >
                        <div className='sidebox-search-input-container' style={{ marginBottom: '10px' }}>
                            <input
                                className='sidebox-search-input'
                                type='text'
                                value={search}
                                placeholder='Search Patient...'
                                onChange={(e) => handlePatientSearch(e)} />
                            <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.4374 15.7502H16.5487L16.2337 15.4464C16.9367 14.6296 17.4506 13.6675 17.7384 12.629C18.0262 11.5904 18.081 10.5011 17.8987 9.43891C17.3699 6.31141 14.7599 3.8139 11.6099 3.4314C10.5025 3.2913 9.37767 3.4064 8.32156 3.76788C7.26546 4.12936 6.30604 4.72765 5.51672 5.51697C4.72741 6.30628 4.12912 7.2657 3.76764 8.32181C3.40615 9.37792 3.29106 10.5027 3.43116 11.6102C3.81366 14.7602 6.31116 17.3702 9.43866 17.8989C10.5008 18.0812 11.5902 18.0265 12.6287 17.7386C13.6673 17.4508 14.6294 16.937 15.4462 16.2339L15.7499 16.5489V17.4377L20.5312 22.2189C20.9924 22.6802 21.7462 22.6802 22.2074 22.2189C22.6687 21.7577 22.6687 21.0039 22.2074 20.5427L17.4374 15.7502ZM10.6874 15.7502C7.88616 15.7502 5.62491 13.4889 5.62491 10.6877C5.62491 7.8864 7.88616 5.62515 10.6874 5.62515C13.4887 5.62515 15.7499 7.8864 15.7499 10.6877C15.7499 13.4889 13.4887 15.7502 10.6874 15.7502Z" fill="#5D5D5D" />
                            </svg>
                        </div>
                        <button
                            className={selected === "applications" ? "selected-button" : "side-button"}
                            onClick={e => { setSelected("applications") }}
                        >
                            Applications
                        </button>
                        <button
                            className={selected === "priority" ? "selected-button" : "side-button"}
                            onClick={e => { setSelected("priority"); }}
                        >
                            Priority Queue
                        </button>
                        <button
                            className={selected === "rejected" ? "selected-button" : "side-button"}
                            onClick={e => { handleClickRejected() }}
                        >
                            Rejected
                        </button>
                        {role === 'bdo' &&
                            <button
                                className={selected === "form" ? "selected-button" : "side-button"}
                                onClick={e => { setSelected('form'); navigate('/palliative_form') }}
                            >
                                Application form
                            </button>
                        }
                    </div>
                </div>
                {(((selected === "applications" && role === "pro") || (selected === "applications" && role === "bdo")) && applications.length > 0) && <div className="col-lg-10 col-md-10 contents">
                    {role !== "bdo" ? <>
                        <div className='row head'>
                            <div className='col-4'></div>
                            <div className='col-2'>
                                <label className='contents-head'>
                                    Enquiry
                                </label>
                            </div>
                            <div className='col-3' style={{ display: "flex", padding: 0 }}>
                                <div className='col-6'>
                                    <label className='contents-head'>
                                        Notes
                                    </label>
                                </div>
                                <div className='col-6' style={{ padding: 0 }}>
                                    <label className='contents-head'>
                                        Report Upload
                                    </label>
                                </div>
                            </div>
                            <div className='col-3'>
                                <label className='contents-head'>
                                    Approve
                                </label>
                            </div>
                            <div className='col-2'></div>
                        </div>
                    </> : <>
                        <div className='row head'>
                            <div className='col-4'></div>
                            <div className='col-2'>
                                <label className='contents-head'>
                                    Enquiry
                                </label>
                            </div>
                            <div className='col-4'>
                                <label className='contents-head'>
                                    Status
                                </label>
                            </div>
                        </div>
                    </>}
                    <div className='row content'>
                        {applications.length > 0 && applications.map((item) => {
                            return (
                                <div key={item.id} className="dialysisreq_lists">
                                    <div className="col-4 dename-content">
                                        {item.first_name}{" "}{item.last_name ? item.last_name : ""}
                                    </div>
                                    <div className="col-2 eye-content">
                                        <div style={{ border: "1px solid rgba(205, 205, 205, 1)", borderRadius: "5px", width: "70%", height: "55%", margin: "auto" }} className="row">
                                            <div style={{ borderRight: "1px solid rgba(158, 158, 158, 1)", color: "rgba(79, 135, 203, 1)", padding: 0 }} className="col-6">
                                                <span class="fa-stack fa-lg"  >
                                                    <i class="fa fa-envelope" aria-hidden="true" style={{ fontSize: "20px", color: "#4F87CB", margin: "auto" }} ></i>
                                                </span>
                                            </div>
                                            <div style={{ alignContent: "center", padding: 0, cursor: "pointer", textAlign: "center" }} className="col-6">
                                                <i onClick={() => togglePopup(item)}
                                                    class="fa fa-eye" style={{ fontSize: "20px", color: "rgba(36, 86, 147, 1)", margin: "auto" }} aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                    {role !== "bdo" ? <><div className='col-3' style={{ display: "flex", padding: 0 }}>
                                        <div className='col-6 icon-col'>
                                            <div className='icon-background'><BiEdit onClick={() => handleAdd(item)} className='icon-item' /></div>
                                        </div>
                                        <div className='col-6 icon-col'>
                                            <div className='icon-background' >
                                                <input
                                                    type="file"
                                                    id={`upload-enquiry-report-${item.id}`}
                                                    style={{ display: "none" }}
                                                    onChange={(e) => uploadEnquiryReport(e, item)} />
                                                <label style={{ margin: 0 }} htmlFor={`upload-enquiry-report-${item.id}`}>
                                                    <span data-tooltip-id="my-tooltip" data-tooltip-content={uploadedFiles[item.id] ? uploadedFiles[item.id].name : 'Upload Enquiry Report'}>
                                                        {uploadedFiles[item.id] ?
                                                            <IoCheckmarkDoneCircleOutline style={{ width: '25px', height: '25px' }} />
                                                            : <svg className='icon-item' width="29" height="29" style={{ width: '25px', height: '25px' }} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.83325 19.3382V20.5417C4.83325 21.5031 5.21517 22.4251 5.89499 23.1049C6.57481 23.7847 7.49684 24.1667 8.45825 24.1667H20.5416C21.503 24.1667 22.425 23.7847 23.1048 23.1049C23.7847 22.4251 24.1666 21.5031 24.1666 20.5417V19.3333M14.4999 18.7292V5.4375M14.4999 5.4375L18.7291 9.66667M14.4999 5.4375L10.2708 9.66667" stroke="#2456AE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>}
                                                    </span>
                                                </label>
                                                <Tooltip id="my-tooltip" style={{ backgroundColor: "#04040466;", color: "#FFFFFF" }} />
                                            </div>
                                        </div>
                                    </div>
                                        <div className='col-3' style={{ margin: "auto", textAlign: "center" }}><button onClick={() => handleSave(item.id)} className='save-button'>Save</button>
                                        </div>
                                    </> :
                                        <div className='col-4 status-render'>
                                            {(item.enquiry_status === "pending") ?
                                                <h6 style={{ fontSize: "12px", fontFamily: "inter", fontStyle: "italic", color: '#2456AE' }}                                            >
                                                    Waiting for enquiry approval
                                                </h6>
                                                :
                                                (item.enquiry_status === "approved") ?
                                                    <h6 style={{ fontSize: "12px", fontFamily: "inter", fontStyle: "italic", color: '#2456AE' }}                                            >
                                                        Waiting for trust decision
                                                    </h6> : ''
                                            }
                                        </div>
                                    }
                                </div>
                            )

                        })}
                    </div>
                </div>}
                {(selected === "applications" && role === "manager" && applications.length > 0) && <div className="col-lg-10 col-md-10 contents">
                    <div className='row head'>
                        <div className='col-4'></div>
                        <div className='col-2'>
                            <label className='contents-head'>
                                Enquiry
                            </label>
                        </div>
                        <div className='col-2'>
                            <label className='contents-head'>
                                Notes
                            </label>
                        </div>
                        <div className='col-2'>
                            <label className='contents-head'>
                                Report
                            </label>
                        </div>
                        <div className='col-2'>
                            <label className='contents-head'>
                                Save
                            </label>
                        </div>
                    </div>
                    <div className='row content'>
                        {applications.length > 0 && applications.map((item) => {
                            return (
                                <div key={item.id} className="dialysisreq_lists">
                                    <div className="col-4 dename-content">
                                        {item.first_name}{" "}{item.last_name ? item.last_name : ""}
                                    </div>
                                    <div className="col-2 eye-content">
                                        <div style={{ border: "1px solid rgba(205, 205, 205, 1)", borderRadius: "5px", width: "70%", height: "55%", margin: "auto" }} className="row">
                                            <div style={{ borderRight: "1px solid rgba(158, 158, 158, 1)", color: "rgba(79, 135, 203, 1)", padding: 0 }} className="col-6">
                                                <span class="fa-stack fa-lg"  >
                                                    <i class="fa fa-envelope" aria-hidden="true" style={{ fontSize: "20px", color: "#4F87CB", margin: "auto" }} ></i>
                                                </span>
                                            </div>
                                            <div style={{ alignContent: "center", padding: 0, cursor: "pointer", textAlign: "center" }} className="col-6">
                                                <i onClick={() => togglePopup(item)}
                                                    class="fa fa-eye" style={{ fontSize: "20px", color: "rgba(36, 86, 147, 1)", margin: "auto" }} aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-2 icon-col'>
                                        <div className='icon-background'><BiEdit onClick={() => handleAdd(item)} className='icon-item' /></div>
                                    </div>
                                    <div className='col-2 icon-col'>
                                        <div className='icon-background'>
                                            <label style={{ margin: 0 }} onClick={() => viewEnquiryReport(item)}>
                                                <FaRegFileAlt />
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-2 save-col'>
                                        <button onClick={() => handleSaveComment(item.id)} className='save-button'>Save</button>
                                    </div>
                                </div>
                            )

                        })}
                    </div>
                </div>}
                {(selected === "applications" && role === "higher_official") && <div className="col-lg-10 col-md-10 contents">
                    {isOfficialAccess && <>
                        <div className='row head'>
                            <div className='col-5'></div>
                            <div className='col-2'></div>
                            <div className='col-5' style={{ display: "flex" }}>
                                <div className='col-4 dialysis-request-heads'>
                                    <label className='contents-head'>
                                        Approve
                                    </label>
                                </div>
                                <div className='col-4 dialysis-request-heads'>
                                    <label className='contents-head'>
                                        Pending
                                    </label>
                                </div>
                                <div className='col-4 dialysis-request-heads'>
                                    <label className='contents-head'>
                                        Reject
                                    </label>
                                </div>
                            </div>
                        </div>
                    </>}
                    <div className='queue-container1' style={{ height: !isOfficialAccess ? '80vh' : '', marginTop: !isOfficialAccess ? '35px' : '' }}>
                        {/* <div className={`row queue-content ${!isOfficialAccess ? 'two-columns' : ''}`}> */}
                        <div className='row queue-content' style={{ height: !isOfficialAccess ? '80vh' : '' }}>
                            <div className={`queue-content3 ${!isOfficialAccess ? 'queue-content-grid' : ''}`}>
                                {applications.length > 0 ? applications.map((item) => {
                                    return (
                                        <div key={item.id} className={`dialysis_priorityqueue color ${!isOfficialAccess ? 'grid-view' : ''}`}>
                                            <div className={`dename-content ${!isOfficialAccess ? 'col-8' : 'col-5'}`}>
                                                {item.first_name}{" "}{item.last_name ? item.last_name : ""}
                                            </div>
                                            <div className={`eye-content ${!isOfficialAccess ? 'col-3' : 'col-2'}`}>
                                                <div className=" row eye-content-div">
                                                    <div className="col-6 envelop">
                                                        <span class="fa-stack fa-lg" style={{}}  >
                                                            <i class="fa fa-envelope" aria-hidden="true" style={{ fontSize: "20px", }} ></i>
                                                        </span>
                                                    </div>
                                                    <div className="col-6 eye">
                                                        <i onClick={() => togglePopup(item)}
                                                            class="fa fa-eye" style={{ fontSize: "18px", margin: "4px 0" }} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`${!isOfficialAccess ? 'col-1' : 'col-5'}`} style={{ display: "flex" }}>
                                                {isOfficialAccess && <>
                                                    <div className='col-4' style={{ display: "flex", margin: "auto" }}><button onClick={() => submit("approved", item)} style={{ boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)", width: "99%", height: "35px", borderRadius: "5px", backgroundColor: "#49B644", margin: "auto" }}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="22"
                                                            height="21"
                                                            viewBox="0 0 22 21"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M22 10.46L19.56 7.68L19.9 4L16.29 3.18L14.4 0L11 1.46L7.6 0L5.71 3.18L2.1 3.99L2.44 7.67L0 10.46L2.44 13.24L2.1 16.93L5.71 17.75L7.6 20.93L11 19.46L14.4 20.92L16.29 17.74L19.9 16.92L19.56 13.24L22 10.46ZM9 15.46L5 11.46L6.41 10.05L9 12.63L15.59 6.04L17 7.46L9 15.46Z"
                                                                fill="rgba(255, 255, 255, 1)"
                                                            />
                                                        </svg>{" "}</button>
                                                    </div>
                                                    <div className='col-4' style={{ display: "flex" }}><button onClick={() => submit("pending", item)} style={{ boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)", width: "99%", height: "35px", borderRadius: "5px", backgroundColor: "#17A1FA", margin: "auto" }}>
                                                        <svg
                                                            width="22"
                                                            height="21"
                                                            viewBox="0 0 28 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M14.0001 0.666504C6.64008 0.666504 0.666748 6.63984 0.666748 13.9998C0.666748 21.3598 6.64008 27.3332 14.0001 27.3332C21.3601 27.3332 27.3334 21.3598 27.3334 13.9998C27.3334 6.63984 21.3601 0.666504 14.0001 0.666504ZM7.33341 15.9998C6.22675 15.9998 5.33341 15.1065 5.33341 13.9998C5.33341 12.8932 6.22675 11.9998 7.33341 11.9998C8.44008 11.9998 9.33341 12.8932 9.33341 13.9998C9.33341 15.1065 8.44008 15.9998 7.33341 15.9998ZM14.0001 15.9998C12.8934 15.9998 12.0001 15.1065 12.0001 13.9998C12.0001 12.8932 12.8934 11.9998 14.0001 11.9998C15.1067 11.9998 16.0001 12.8932 16.0001 13.9998C16.0001 15.1065 15.1067 15.9998 14.0001 15.9998ZM20.6667 15.9998C19.5601 15.9998 18.6667 15.1065 18.6667 13.9998C18.6667 12.8932 19.5601 11.9998 20.6667 11.9998C21.7734 11.9998 22.6667 12.8932 22.6667 13.9998C22.6667 15.1065 21.7734 15.9998 20.6667 15.9998Z" fill="white" />
                                                        </svg></button>
                                                    </div>
                                                    <div className='col-4' style={{ display: "flex" }}><button onClick={() => confirmRejectApproval(item)} style={{ boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)", width: "99%", height: "35px", borderRadius: "5px", backgroundColor: "#ED0000", margin: "auto" }}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="22"
                                                            height="21"
                                                            viewBox="0 0 22 21"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM14.3 14.3C14.2075 14.3927 14.0976 14.4663 13.9766 14.5164C13.8557 14.5666 13.726 14.5924 13.595 14.5924C13.464 14.5924 13.3343 14.5666 13.2134 14.5164C13.0924 14.4663 12.9825 14.3927 12.89 14.3L10 11.41L7.11 14.3C6.92302 14.487 6.66943 14.592 6.405 14.592C6.14057 14.592 5.88698 14.487 5.7 14.3C5.51302 14.113 5.40798 13.8594 5.40798 13.595C5.40798 13.4641 5.43377 13.3344 5.48387 13.2135C5.53398 13.0925 5.60742 12.9826 5.7 12.89L8.59 10L5.7 7.11C5.51302 6.92302 5.40798 6.66943 5.40798 6.405C5.40798 6.14057 5.51302 5.88698 5.7 5.7C5.88698 5.51302 6.14057 5.40798 6.405 5.40798C6.66943 5.40798 6.92302 5.51302 7.11 5.7L10 8.59L12.89 5.7C12.9826 5.60742 13.0925 5.53398 13.2135 5.48387C13.3344 5.43377 13.4641 5.40798 13.595 5.40798C13.7259 5.40798 13.8556 5.43377 13.9765 5.48387C14.0975 5.53398 14.2074 5.60742 14.3 5.7C14.3926 5.79258 14.466 5.90249 14.5161 6.02346C14.5662 6.14442 14.592 6.27407 14.592 6.405C14.592 6.53593 14.5662 6.66558 14.5161 6.78654C14.466 6.90751 14.3926 7.01742 14.3 7.11L11.41 10L14.3 12.89C14.68 13.27 14.68 13.91 14.3 14.3Z"
                                                                fill="rgba(255, 255, 255, 1)"
                                                            />
                                                        </svg></button>
                                                    </div>
                                                </>}
                                            </div>
                                        </div>
                                    )

                                }) :

                                    <div className="nodata">No requests pending!!</div>
                                }
                            </div>
                        </div>
                    </div>
                    {isOfficialAccess && <PreviewQueue
                        approved={approved}
                        pending={pending}
                        search={search}
                        setPending={setPending}
                        setApproved={setApproved}
                        reload={reload}
                        type="palliative"
                    />}
                </div>}
                {(selected === 'applications' && !applications.length > 0 && role !== "higher_official") &&
                    <div className="col-lg-10 col-md-10 nodata" >No requests pending!!</div>
                }
                {selected === "priority" && <div className="col-lg-10 col-md-10 contents">
                    {<PriorityQueue approvedQueue={approvedQueue} search={search} type="palliative" />}
                </div>}
                {(selected === "rejected" && rejected.length > 0) && <div className="col-lg-10 col-md-10 contents">
                    {
                        <div className='row head'>
                            <div className='col-6'></div>
                            <div className='col-2'>
                                <label className='contents-head'>
                                    Enquiry
                                </label>
                            </div>
                            <div className='col-4'>
                                <label className='contents-head'>
                                    Status
                                </label>
                            </div>
                        </div>
                    }
                    <div className='row content'>
                        {rejected.length > 0 && rejected.map((item) => {
                            return (
                                <div key={item.id} className="dialysisreq_lists">
                                    <div className="col-6 dename-content">
                                        {item.first_name}{" "}{item.last_name ? item.last_name : ""}
                                    </div>
                                    <div className="col-2 eye-content">
                                        <div style={{ border: "1px solid rgba(205, 205, 205, 1)", borderRadius: "5px", width: "70%", height: "55%", margin: "auto" }} className="row">
                                            <div style={{ borderRight: "1px solid rgba(158, 158, 158, 1)", color: "rgba(79, 135, 203, 1)", padding: 0 }} className="col-6">
                                                <span class="fa-stack fa-lg"  >
                                                    <i class="fa fa-envelope" aria-hidden="true" style={{ fontSize: "20px", color: "#4F87CB", margin: "auto" }} ></i>
                                                </span>
                                            </div>
                                            <div style={{ alignContent: "center", padding: 0, cursor: "pointer", textAlign: "center" }} className="col-6">
                                                <i onClick={() => togglePopup(item)}
                                                    class="fa fa-eye" style={{ fontSize: "20px", color: "rgba(36, 86, 147, 1)", margin: "auto" }} aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 rejected'><h6>
                                        {item.enquiry_status === "rejected" ? "Enquiry Rejected" : "Trust Rejected"}</h6></div>
                                </div>
                            )

                        })}
                    </div>
                </div>}
                {(selected === 'rejected' && !rejected.length > 0) &&
                    <div className="col-lg-10 col-md-10 nodata" >No requests pending!!</div>
                }
            </div>
            {
                formShow ? (
                    <FormPopUp
                        show={formShow}
                        onHide={() => {
                            setFormShow(false);
                        }}
                    />
                ) : null
            }

            {
                addCommentShow ? (
                    <AddPopup
                        show={addCommentShow}
                        onHide={() => {
                            setAddCommentShow(false);
                        }}
                    />
                ) : null
            }

            {showBackPopup && (
                <SubmitConfirmationPopup
                    message="Are you sure you want to Exit the page without submitting the queue? "
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}

            {rejectApproval && (
                <RejectpopUp
                    selectedPatient={selectedPatient}
                    handleRejectApproval={handleRejectApproval}
                    onHide={() => setRejectApproval(false)}
                />
            )}

            {modalIsOpen ? (
                <EnquiryReportView
                    show={EnquiryReportView}
                    onHide={() => modalIsOpen(false)}
                />
            ) : null}

        </div >

    )
}
export default Palliative;