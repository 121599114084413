import React, { useState, useEffect, useRef } from 'react'

import axios from "axios";
import { APIURL } from '../../Global'
import { useAlert } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import moment from "moment";

import DatePicker from 'react-date-picker';
import ZoomableImage from '../BDO/Admission/ZoomableImage';

function AddDoc(props) {
    const alert = useAlert()
    const [report, setReport] = useState({})
    const [reportArray, setReportArray] = useState([])
    const [show, setShow] = useState(false);
    const [info, setInfo] = useState("")
    const [content, setContent] = useState("")
    const [fileName1, setFileName1] = useState("Upload Image");



    const handleTitle = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        if (key === "doc_type") {
            if (val === "prescription" || val === "lab_report" || val === "scan_report" || val === "discharge_summary")
                setReport(current => ({
                    ...current, [key]: val,
                    is_health: true
                }))
            else {
                setReport(current => ({
                    ...current, [key]: val,
                    is_health: false
                }))
            }
        }else{
            setReport(current => ({
                ...current, [key]: val,
               
            }))
        }
    }
    const submit = (e) => {
        const tokenString = sessionStorage.getItem('usertoken');
        let fd = new FormData();
        let str = tokenString.replace(/["]+/g, '')
        fd.append('patient', props.id)
        reportArray.map((item, index) => {
            Object.keys(item).forEach(key => {
                fd.append("documents[" + index + "]" + key, item[key]);
            });



        })


        axios.post(`${APIURL}/api/v1/doctor/patient-documents-view/ `, fd, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {


                if (res.data.status === "success") {

                    alert.success("successfully Updated")
                    props.onSuccess()

                } else {


                }

            })
            .catch(err => {


            })

    }
    const reader = new FileReader();
    const uploadImage = async (e) => {
        const key = e.target.name;
        const file = e.target.files[0];
        const imageUrl = URL.createObjectURL(file);


        if (!file) {
            alert.error("Please Select an Image")

            setFileName1('Upload Image')
            setReport((currentData) => ({
                ...currentData,
                image: null,
                imageUrl: null
            }));

            return false;
        }
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
            alert.error("Select valid image")

            setFileName1('Upload Image')
            setReport((currentData) => ({
                ...currentData,
                image: null,
                imageUrl: null
            }));

            return false;
        }
        if (file.size > 10e6) {
            alert.error("Select valid image")
            return false;
        }
        setFileName1("Image Uploaded")
        setReport((currentData) => ({
            ...currentData,
            image: file,
            imageUrl: imageUrl
        }));

        reader.readAsDataURL(file);


    };



    const InfoPopup = (props) => {
        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
                    <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
                        <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
                    </button>
                </Modal.Header>
                <Modal.Body style={{ paddingRight: "7%", textAlign: "center" }}>
                    <ZoomableImage src={info} />
                </Modal.Body>
            </Modal>
        );
    };
    const handleDeleteMember = (indexToDelete) => {
        const updatedMembers = reportArray.filter(
            (_, index) => index !== indexToDelete
        );
        setReportArray(updatedMembers);
    };
    return (
        <div style={{ marginTop: "2%" }}>
            <div className=' ' style={{ backgroundColor: " rgba(207, 207, 207, 1)", padding: "1%" }}  >

                <div className="row">


                    <div className="col-12" >
                        <select

                            style={{ backgroundColor: "white", paddingLeft: "1%" }}
                            className="enquiryinput"
                            value={report.doc_type}
                            name="doc_type"
                            onChange={handleTitle}
                            placeholder='Select'
                        >
                            <option hidden value="" >Select Type</option>
                            <option value="pan" >PAN</option>
                            <option value="passport" >Passport</option>
                            <option value="aadhar" >Aadhar</option>
                            <option value="voter_id" >Voter ID</option>
                            <option value="driving_license" >Driving License</option>
                            <option value="ration_card" >Ration Card</option>
                            <option value="authority_certificate" >Authority Certificate</option>
                            <option value="police_certificate" >Police Certificate</option>
                            <option value="prescription" >Prescription</option>
                            <option value="lab_report" >Lab Report</option>
                            <option value="scan_report" >Scan Report</option>
                            <option value="discharge_summary" >Discharge Summary</option>
                            <option value="others" >Others</option>


                        </select>
                    </div>




                </div><br />
                <div className='row' >
                    <div className='col-6' >


                        <input
                            name="comment"
                            type="text"
                            maxLength={75}
                            className="enquiryinput"
                            placeholder="Remarks "
                            value={report.comment}
                            onChange={handleTitle}
                            style={{ border: "none", fontSize: "15px", }}
                        />



                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div style={{ backgroundColor: "rgba(207, 207, 207, 1)", }} className="custom-file col-4">
                        <input type="file" name="image" className="custom-file-input" id="inputGroupFile01" onChange={uploadImage} />
                        <label style={{ backgroundColor: "rgba(207, 207, 207, 1)", border: "1px dotted black" }} className="custom-file-label" htmlFor="inputGroupFile01" aria-describedby="inputGroupFileAddon01">{fileName1} </label></div>
                    &nbsp;&nbsp;&nbsp;&nbsp;

                    <button style={{ backgroundColor: "rgba(36, 86, 147, 1)", color: "white", borderRadius: "10px" }} onClick={() => {
                        if (!report.doc_type || report.item === "") {
                            alert.error(" Enter Document type")
                        }
                        else if (!report.image || report.image === "") {
                            alert.error('upload image')
                        }

                        else {

                            setReportArray([...reportArray, report]);
                            setFileName1("Upload Image")
                            setReport(currentstate => ({ ...currentstate, image: "", comment: "" }))


                        }

                    }} className='dir-button col-1'>Add</button>
                </div>



            </div>
            <div style={{ minHeight: "300px" }}>
                {reportArray.length > 0 && <> <div style={{ marginTop: "2%" }}>
                    <div className='text-center'>
                        <h6 style={{ textDecoration: "underline", }}><b>Uploads</b></h6>
                    </div>
                    {reportArray.map((item, index) => {
                        return (
                            <div className='row' style={{ marginBottom: "2%", paddingLeft: "6%", marginTop: "2%" }}>
                                <div className='col-8 row listofrep ' style={{ padding: "1%" }}>
                                    <div className='col-6' style={{ borderRight: "1.5px solid rgba(0, 0, 0, 1)" }}>
                                        <h6 style={{ textTransform: "capitalize" }}>{item.doc_type.replace("_", " ")}</h6>
                                    </div>
                                    <div style={{ backgroundColor: "rgba(240, 240, 240, 1)" }} className='col-6'>
                                        <h6>{item.comment}</h6>
                                    </div>
                                </div>
                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                <div className='col-3   ' style={{ padding: "1%", display: "flex" }}>
                                    <button onClick={() => { setInfo(item.imageUrl); setShow(true) }} style={{ backgroundColor: "rgba(36, 86, 147, 1)", color: "white", width: "55px", borderRadius: "10px" }} className='dir-button '>View</button>
                                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                    <i onClick={() => handleDeleteMember(index)} style={{ color: "rgba(229, 89, 11, 1)" }} class="fa fa-trash fa-2x" aria-hidden="true"></i>

                                </div>



                            </div>

                        )
                    })}

                </div></>}
            </div>
            <div className='text-center'>
                <button disabled={reportArray.length === 0} onClick={() => { submit() }} style={{ width: "20%", backgroundColor: "rgba(36, 86, 147, 1)", height: "40px" }} className='cons-button'>
                    <span style={{ paddingTop: "1%" }}>Save</span></button>
                &nbsp;&nbsp;&nbsp;
                <button onClick={() => { props.onClose() }} style={{ width: "20%", backgroundColor: "rgba(229, 89, 11, 1)", height: "40px" }} className='cons-button'>
                    <span style={{ paddingTop: "1%" }}>Close</span></button>

            </div>
            {show ? (
                <InfoPopup
                    show={show}
                    onHide={() => {
                        setShow(false);
                    }}
                />
            ) : (
                ""
            )}

        </div>

    )
}
export default AddDoc