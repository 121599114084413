import { useState, useEffect, useContext } from "react"
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useAlert } from "react-alert";
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
import { ConfirmationContext } from '../../contexts/SubmitConfirmationContext';

function AddService({ onSuccess }) {
    const [consent, setConsent] = useState(0)
    const [serviceName, setServiceName] = useState("")
    const [consentLevel, setConsentLevel] = useState(0)
    const[servicetype,setServiceType]= useState({ value:'', label:'' });
    const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
    const [consentUser,setConscentUser]=useState("")
    const alert =useAlert()
    useEffect(() => {

        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        );

        axios.get(`${APIURL}/api/v1/staff/consent-level/`, {
            headers: { Authorization: "Token " + v2 },
        })
            .then((res) => {
                if (res.status === 200) {
                    const datas = res.data
                    getConsentLevel(datas);
                   
                    //setConsent(datas.consent_level)
                }
                else {

                }
            })
            .catch((err) => {

            })

    }, [])

    const serviceoptions = [{ value:'general_services', label:'General Services' },
    { value:'consumables', label:'Consumable' },  
   
 ]
    const getConsentLevel = (datas) => {
        let consentarr = datas.map(item =>{
            return {label: item.consent_level, value: item.id, users :item.level_users}
        });
        setConsent(consentarr);
    }

    const handleChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        const user= e.target.user;
        const label= e.target.label;
        if (key === "Servicename") {
            setServiceName(val);
        }
        if (key === "Consentlevel") {
            setConsentLevel(val);
            setConscentUser(user)
           
        }
        if (key === "servicetype") {
            setServiceType(val);
           
        }
       
    };

    const handleContinue = (e) => {
        e.preventDefault();
        if (serviceName!==""&&servicetype.label!==""&&consentLevel!==0){
            
            setShowConfirmation(true); 
        }else{
            alert.error("Please fill all mandatory fields!")
            
            
        }
    };
    

    const handleConfirm=() =>{
        addService()
    }

    const handleCancel=()=>{
        closeConfirmationPopup()
    }
    
    const addService = () => {
        
        // e.preventDefault();


        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */
       
        const dataToSend = {
            service_name: serviceName.slice(0,1).toUpperCase() +serviceName.slice(1,serviceName.length),
            consent_level: consentLevel,
            service_type:servicetype,
           
        }

        axios
            .post(`${APIURL}/api/v1/staff/service-list/`, dataToSend, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {
                   onSuccess();
                   alert.success("Added successfully")
                    //setRefresh(true)
                    //setCompleteShow(true)
                } else {
                    alert.error(res.data.message)
                    closeConfirmationPopup()
                }
            })
            .catch((err) => {
                alert.error("Please fill all mandatory fields!")
                closeConfirmationPopup()
            });
  }
    return (<>
        <div className="appForm" >
            <div className="">

                <div className="title-of-tasks" style={{}}><h5>Add Service</h5></div><br/>
                <form className="formFields" onSubmit={handleContinue}>

                    <div className="formField">

                        <input
                            type="text"
                            className="formFieldInput"
                            name="Servicename"
                            placeholder={"*Service Name"}
                            value={serviceName}
                            onChange={handleChange}
                         
                        />
                    </div>
                    <div className="formField">
                     
                            
                            {consent && <select  className="formFieldInput"
                                                name="Consentlevel" 
                                                value={consentLevel} 
                                                defaultValue="null"
                                                onChange={handleChange}>
                                 <option selected hidden style={{color:"#495057cf"}} >*Consent Level</option>
                                {consent.map(item => (
                                    <><option value={item.value} user={item.users}>
                                        {item.label}
                                    </option><p>{item.users}</p></>
                                ))}
                               
                            </select>
                           
                           
                           
                            }
                           
                            
                      

                    </div>
                    <div className="formField">
                    <select  className="formFieldInput"
                                                name="servicetype" 
                                                value={servicetype} 
                                               onChange={handleChange}
                                              
                                                >
                                <option  value="" selected  hidden style={{color:"#495057cf"}} >*Service Type</option>
                                <option value="general_services">General Services</option>
                                 <option value="consumables">Consumable</option>
                               
                            </select>
                        </div>
                      

                    <div className="form-heading title-of-tasks"style={{}}>
                        <button  className="btn formFieldButton">Save</button>
                    </div>


                </form>

            </div>


        </div>
        {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to add the service?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}
        </>
    )
}
export default AddService