import React, { useState, useEffect,useContext } from "react";
import "./cart.css";
import { Link, useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import PatientNav from "./PatientNav";
import { APIURL } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import LoadingOverlay from "react-loading-overlay";
import { MdFavorite } from "react-icons/md";
import { Badge } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useAlert } from "react-alert";
import cartempty from "./assets/img/cart.jpeg";
import SimpleBar from "simplebar-react";
import service2 from "../../assets/images/2.png";
import "simplebar-react/dist/simplebar.min.css";
function PaymentPage (){


    return(
 <>

<section
        className="products-area ptb-70"
        style={{ marginTop: "-3%", backgroundColor: "#f7f8fa",marginTop:"1%" }}
      >



       
       
       
  </section>     
       
        </>
    )
}
export default PaymentPage