import React from 'react'
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
function ViewRemark(props) {
   
    return (
        <div className='flex-col'>        

               {props.data!=="null"&& <> <SimpleBar style={{ maxHeight: "300px",width:"96%" }}><p
                type="text" 
                className=""
              
                name="remarks" 
            
            autoComplete="off"
                >{props.data}</p></SimpleBar><br/></>}
                {props.url!=="null" ?<a href={props.url}>{props.url}</a>:""} 
        </div>
    )
}

export default ViewRemark
