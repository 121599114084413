import React, { useState, useEffect, useRef } from 'react';
import { Switch } from "antd";
import '../PRO/Dialysis/addMachine.css';
import axios from "axios";
import { APIURL } from "../../Global";
import { useAlert } from 'react-alert';
import { CgCloseR } from "react-icons/cg";
import moment from 'moment';

const ShowHistory = (props) => {
    const modalContentRef = useRef(null);
    const [data, setData] = useState([]);
    const alert = useAlert()

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
            props.onHide();
        }
    };

    useEffect(() => {
        therapy()
    }, [])

    const therapy = async () => {
        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");


        const date = moment(new Date()).format('YYYY-MM-DD')
        const patient_id = props.patientId


        try {
            const currentRes = await axios.get(`${APIURL}/api/v1/patient/therapy-consultation/?previous=true&date=${date}&patient_id=${patient_id}`, {
                headers: {
                    Authorization: "Token " + str,
                    "Content-Type": "application/json",
                },
            });

            if (currentRes.data.status === "success") {
                setData(currentRes.data.message);
            } else {
                setData([])
            }

        } catch (err) {
            alert.error('An error occurred while fetching therapy consultation data.');
        }
    };

    return (
        <div className='addmachine-modal' style={{ zIndex: 999 }}>
            <div className='addmachine-modal-content' ref={modalContentRef}>
                <div style={{ textAlign: 'right' }}><CgCloseR onClick={props.onHide} style={{ cursor: 'pointer' }} /></div>
                <div>
                    <div className='addmachine-head'>
                        <label>Physiotherapy History</label>
                    </div>
                    {data.length === 0 ?
                        <div style={{ height: '100px', width: '100%', overflow: 'auto' }}>
                            <label>No data available</label>
                        </div> : <div style={{ height: 'fit-content', maxHeight: '400px', width: '100%', overflow: 'auto', marginLeft: '10px', textAlign:'left' }}>
                            {data.map(item => (
                                <div style={{ display: 'flex', flexDirection: 'column' }} key={item.id}>
                                    {item.date && <div style={{ display: 'flex' }}><label className='col-4' style={{ padding: 0 }}><strong>Date:</strong> </label><label className='col-8' style={{ padding: 0 }}> {item.date}</label></div>}
                                    {item.therapy_type && <div style={{ display: 'flex' }}> <label className='col-4' style={{ padding: 0 }}><strong>Therapy Type:</strong> </label><label className='col-8' style={{ padding: 0 }}> {item.therapy_type}</label></div>}
                                    {item.therapy_requirement && <div style={{ display: 'flex' }}> <label className='col-4' style={{ padding: 0 }}><strong>Therapy Requirement:</strong> </label><label className='col-8' style={{ padding: 0 }}>{item.therapy_requirement}</label></div>}
                                    {item.therapy_unit && <div style={{ display: 'flex' }}><label className='col-4' style={{ padding: 0 }}><strong>Therapy Unit:</strong> </label><label className='col-8' style={{ padding: 0 }}> {item.therapy_unit}</label></div>}
                                    {data.length === 2 && <hr />}
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ShowHistory;
