import React, {useState,useEffect} from 'react'
import axios from "axios";
import { APIURL } from "../../Global";
import { useAlert } from 'react-alert' 
function AddRemark(props) {

    const [remarkData, setRemarkData]= useState('')
    const [comments, setComments] = useState([])
    const[refresh,setRefresh]=useState(false)
    const alert = useAlert()
    let data = remarkData
       props.update(data)

    const callSave = (rowToUpdate) => {
      

        // if (validate(rowToUpdate)) {
    
          const tokenString = sessionStorage.getItem('usertoken');
    
          let str = tokenString.replace(/["]+/g, '');
         
         
          let remarks=remarkData
          
           
          const dataToUpdate = {
            remarks,
          
          };
           
    
          
    
          axios.put(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/${props.trackId}/`, dataToUpdate, {
            headers: {
    
              "Authorization": 'Token ' + str
            }
          })
          .then((res) => {
            if (res.data.status === "success") {
           
     
            alert.success("Remarks added !!")
            props.update(remarkData);
            props.render1();
           
            } else {
              
              
            }
          })
          .catch((err) => {
          
          });
      
      }

      const callSave1 = (rowToUpdate) => {

        // if (validate(rowToUpdate)) {
    
          const tokenString = sessionStorage.getItem('usertoken');
    
          let str = tokenString.replace(/["]+/g, '');
         
         
          let remarks=remarkData
          
           
          const dataToUpdate = {
            remarks,
          
          };
           
    
          
    
          axios.put(`${APIURL}/api/v1/doctor/pharmacy-fulfillment/${props.trackId}/`, dataToUpdate, {
            headers: {
    
              "Authorization": 'Token ' + str
            }
          })
          .then((res) => {
            if (res.data.status === "success") {
           
              
            alert.success("Remarks added !!")
            props.render1();
           
            } else {
              
              
            }
          })
          .catch((err) => {
          
          });
      
      }


    return (
        <div className='flex-col'>  
        {/* <h4>{props.order}</h4>     */}
        <> <small >Patient Name : <b>{props.patient}</b></small>
                <textarea 
                type="text" 
                className="form-group text-area remark-add"
                value= {remarkData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setRemarkData(e.target.value)
          } }
            autoComplete="off"
                /> </> 
         {props.order===null ?  <button disabled={remarkData===''} onClick={callSave} className='btn btn-primary btn-col'>Save</button>
           :<button disabled={remarkData===''} onClick={callSave1} className='btn btn-primary btn-col'>Update</button>} <div className='seperate-content' />
          
           
                <div className='list-item ' style={{display:"flex" ,padding:"5px",marginBottom:"5px",marginTop:"5px"}}>
                 
                    <p>{props.order}</p></div>
         
        </div>
        
    )
}

export default AddRemark
