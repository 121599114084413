import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function AddBp(props) {
  const alert = useAlert();
    const [systolic, setSystolic]=useState()
    const [diastolic, setDiastolic]=useState()
  
    const [errorMsg,setErrorMsg]=useState()
  
    const [limitShow , setLimitShow]= useState(false)
    const saveButtonRef = useRef(null)

    useEffect(() => {
      const handleKey = (e) => {
  
        if (e.altKey && e.key === 's') {
          e.preventDefault()
          saveButtonRef.current.click()
        }else if (e.key === 'Enter') {
          e.preventDefault()
          saveButtonRef.current.click()
        }
      
      }
      document.addEventListener("keydown", handleKey);
      
      return () => {
        document.removeEventListener("keydown", handleKey);
      }
      
    }, []);


    const timezoneOffset = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDateTime = toISOString(Date().toLocaleString());
  function toISOString(s) {
    var months = {jan:'01',feb:'02',mar:'03',apr:'04',may:'05',jun:'06',
                  jul:'07',aug:'08',sep:'09',oct:'10',nov:'11',dec:'12'};
    var b = s.split(' ');
  
    return b[3] + '-' +
           months[b[1].toLowerCase()] + '-' +
           ('0' + b[2]).slice(-2) + 'T' +
           b[4] + b[5].substr(3);
  }

    const handleSystolic = (e)=> {
        const val = e.target.value
        if(val.length < 4){
        setSystolic(e.target.value)
        }
        else return false
    }

    const handleDiastolic = (e)=> {
        const val = e.target.value
        if(val.length < 4){
        setDiastolic(e.target.value)
        }
        else return false
    }


    const handleSubmit = () => {
         
       if(systolic < 40||systolic >400 ) {
            alert.error("Systolic value must be between 40mmHg and 400mmHg")
             
        }
        else if( diastolic< 20|| diastolic>250){
          alert.error("Diastolic value must be between 20mmHg and 250mmHg")
         
        }else if(systolic-diastolic<15){
          alert.error("Minimum difference should be 15 mmHg")
         
        }
   else{
            const id =props.id
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               'result':systolic,
               'additional_result':diastolic,
               
            'vital_name': "blood_pressure",
            'date_time':currentDateTime
           }
     
     
       
         axios.post(`${APIURL}/api/v1/patient/vitals/?patient_id=${id}&timeZone=${timezoneOffset}`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
           
        
           if(res.data.status==="success"){
                alert.success("Blood Pressure added Successfully")
                 props.submitbp(); setSystolic("");setDiastolic("")
          
       }
       else{
            alert.error(res.data.message)
       }
     
         })
         .catch(err=>{
       
           
     })

        }

   


        
    }

  
     
    
    return ( 
        <div className='flex-col'>
           <input style={{width:'50%'}} type="number" min = "1" max = "250"  placeholder="Add Systolic BP"        value={systolic} onChange={handleSystolic} className="form-control"/><br/>
           <input style={{width:'50%'}} type="number" min = "1" max = "250"  placeholder="Add Diastolic BP"    value={diastolic} onChange={handleDiastolic} className="form-control"/><br/>
           <button disabled={ systolic < 1 || systolic=== undefined || diastolic < 1 || diastolic===undefined } onClick={handleSubmit} ref={saveButtonRef} className='btn btn-primary btn-col'>Add</button> 


           
    

        </div>
    )
}

export default AddBp
