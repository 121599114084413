import React, { useState, createContext } from "react";
import Swal from "sweetalert2";
export const ConfirmationContext = createContext();
 
export const ConfirmationProvider = (props) => {
  const [showConfirmation,setShowConfirmation] = useState(false);
  const closeConfirmationPopup = () => {
    setShowConfirmation(false);
    Swal.close();
  };
  return (
    <ConfirmationContext.Provider value={[showConfirmation,setShowConfirmation,closeConfirmationPopup]}>
      {props.children}
    </ConfirmationContext.Provider>
  );
};