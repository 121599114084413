import React, { useState,useRef,useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useAlert } from "react-alert";
function AddAller(props) {
  const alert = useAlert();
  const [allerData, setAllergyData] = useState("");
  const [allergy, setAllergy] = useState([]);
  // const [modalShow, setModalShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [display, setDisplay] = useState(false);
  // const [errorShow, setErrorShow] = useState(false);
  const [allerduration, setAllerDuration] = useState("");
  const inputRefs = useRef([])
  const saveButtonRef = useRef(null)

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {  
      e.preventDefault()
      if(index === 1 ) {
        saveButtonRef.current.click();
      }else{
      e.preventDefault();    
      const nextIndex = index + 1;
      if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
        inputRefs.current[nextIndex].focus();
      }
    }
    }else if (e.altKey && e.key === 's') {
      saveButtonRef.current.click(); 
    }
  };


  const handleAllerduration = (e) => {
    ;
  };


  const handleClick = (item) => {
    initialRender.current = true;
    setSearchTerm(item.allergy_name);
    setAllergyData(item.allergy_name);
    // setPatientDetails(item)

    setDisplay(false);
    // setAllData([])
    // setRefresh(!refresh)
  };
  const handleDisplay = () => {
    setDisplay(false);

  }
  useEffect(() => {
    document.addEventListener('click', handleDisplay)

    return () => {
      document.removeEventListener('click', handleDisplay)
    }
  }, [])
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (searchTerm === "") {
      setAllergy([]);
      setAllergyData("");
      setDisplay(false);
    } else {
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */

      axios
        .get(`${APIURL}/api/v1/doctor/allergies/?search=${searchTerm}`, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
   
          if (res && res.data) {
            setAllergy(res.data);

            setDisplay(true);
            // setTotalPages(res.data.total_pages)
            // setCurrentPage(res.data.page)
            // setTotalRecords(res.data.total_records)
          } else {
          }
        })
        .catch((error) => {});
    }
  }, [searchTerm]);

  const handleSave = () => {
    if (searchTerm === "" || searchTerm === null) {
    alert.error("Select a Valid Allergy!")
    } else if (allerduration === "" || allerduration === null) {
      alert.error("Please Enter Duration"); 
     
    } else {
    const id = props.id;
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    const dataToUpdate = {
      allergy: searchTerm,
      patient: id,
      duration: allerduration,
    };
    ;

    axios
      .post(`${APIURL}/api/v1/doctor/allergies-list/`, dataToUpdate, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        ;
        if (res.data.status === "success") {
          alert.success("Allergy added successfully")
          props.handleAdd();
         } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => {
        alert.error("error in submission")
      });
    }
  };
 

  
  return (
    <div className="flex-col">
       <div className="formField" style={{paddingBottom:"0px !important"}}>
        <input
          ref={el => inputRefs.current[0] = el} 
          onKeyDown={(e) => handleKeyDown(e,0)}
          className="formFieldInput"
          type="text"
          maxLength="100"
          placeholder="Add Allergy"
          value={searchTerm}
          onChange={handleSearchChange}
          // value={comorbValue}

          // onChange={handleChange}

         
        />
 {display ? (
        <div className="suggest-display adjust-dimensions comor-auto-suggest ">
          {allergy.length > 0 ? (
            allergy.map((item) => {
              return (
                <p
                  onClick={() => handleClick(item)}
                  className="suggest-item text-secondary"
                >
                  {item.allergy_name}
                </p>
              );
            })
          ) : (
            <h6 className="text-muted">No Allergy found !!</h6>
          )}
        </div>
      ) : null}
        
      </div>
     

      {/* <textarea
        type="text"
        className="form-group text-area remark-add"
        value={obsData}
        name="remarks"
        onChange={(e) => {
          e.preventDefault();
          setObsData(e.target.value);
        }}
        autoComplete="off"
      /> */}
      <div className="formField">
       
        <input
          ref={el => inputRefs.current[1] = el} 
          onKeyDown={(e) => handleKeyDown(e,1)}
          placeholder="Duration in yrs"
          type="number"
          className="formFieldInput"
          name="duration"
          value={allerduration}
          onChange={(e) => {
            e.preventDefault();
            setAllerDuration(e.target.value);
          }}
        ></input>
      
      
     
      </div>
      <div className="form-button">
          <button 
          ref={saveButtonRef}
          type="submit" className="btn btn-primary btn-col"     
          onClick={handleSave}
        >
          {" "}
          Add{" "}
        </button>
</div>
    




   
    </div>
  );
}

export default AddAller;
