import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { APIURL } from "../../Global";
import "./forgotpassword.css";
import axios from "axios";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';


function ForgotPassword() {
  const [loginUsername, setLoginUsername] = useState("");

  const [errorMsg, setErrorMsg] = useState(null);
  const [errorSubmit, setErrorSubmit] = useState(false);
  const navigate = useNavigate();
 const [route,setRoute]=useState("")

  const ErrorPopup = (props) => {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}> {errorMsg} </h5>

        </Modal.Body>
       
      </Modal>
    );
  }


  const handleForgotPassword = (e) => {
    

    e.preventDefault();

    axios.get(`${APIURL}/api/v1/account/forgot-password/?username=${loginUsername}&src=web&route=${route}`, {
      headers: {


        'Content-Type': 'application/json'
      }


    })

      .then(res => {

        if (res.data.status === "success") {
          if (res.data.is_existing_user === true) {

            const msg = res.data.message;
            const usr = res.data.username;
            navigate('/input-otp', {
              replace: false,
              state: {
                username: usr,
                responseMessage: msg,
                route:route
              }
            });


          }
          else {
            setErrorMsg(res.data.message);
            setErrorSubmit(true);

          }

        }
        else {

          setErrorMsg(res.data.message);
          setErrorSubmit(true);

        }

      })
      .catch(err => {
        setErrorMsg("Error in submission");
        setErrorSubmit(true);

      })

    setLoginUsername('');

  }

  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;

    setLoginUsername(val);

  };
  const handleKeypress = e => {


    if (e.keyCode === 13) {
      e.preventDefault();
      handleForgotPassword(e);
    }
  };
  const handleRadioButton = (e) => {
   
    setRoute(e.target.value)
  }
  return (<div className="wrapper-content">
    <div className="forgot-password-page">

      {/*  <img src={graphic3} width="25%" height="auto" alt="reset password"/> */}

      <form className="login-form forgot-password-form" onSubmit={handleForgotPassword}>
        <h4 className="form-heading title-of-tasks"> Password Reset </h4>
        <input
          className="formFieldInput"
          type="text"
          name="loginUsername"
          placeholder=" Enter Username"
          value={loginUsername}
          maxLength='50'
          onChange={handleChange}
          onKeyDown={handleKeypress}
          required
        />
  <div className="" style={{paddingTop:"2%"}}>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                id="email"
                name="route"
                value="email"
                checked={route === "email"}
                onChange={handleRadioButton}
              />
              <label htmlFor="email">Email</label>
              &nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                id="mobile"
                name="route"
                value="mobile"
                checked={route === "mobile"}
                onChange={handleRadioButton} />

              <label htmlFor="mobile">Mobile</label>

            

            </div>


        <br /><br />
        <div className="form-button-forgot-pw">
          <Link to="/login"><button className="btn btn-secondary "style={{backgroundColor:"rgb(229, 89, 11)"}} >
            Goto Login
          </button></Link>
         
          <button id="submit" type="submit" className="btn btn-primary btn-col btn-reset-pw " >
            Send OTP
          </button>
        </div>







      </form>






      {errorMsg !== null ?
        <ErrorPopup
          show={errorSubmit}
          onHide={() => setErrorSubmit(false)}

        /> : null}

    </div>
  </div>
  );
}

export default ForgotPassword;
