import React, { useState, useEffect, useRef } from 'react'

import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import moment from "moment";
import "./inventory.css";
import DatePicker from 'react-date-picker';
import ZoomableImage from '../Admission/ZoomableImage';
function AddInventory(props) {
    const alert = useAlert()
    const [invData, setInvData] = useState({})
    const [invDataArray, setInvDataArray] = useState([])
    const [show, setShow] = useState(false);
    const [info, setInfo] = useState("")
    const [content, setContent] = useState("")
    const [fileName1, setFileName1] = useState("Upload Image");
    const [fileName, setFileName] = useState("Upload Consent form");
    const handleDate = (date) => {

        setInvData(currentstate => ({
            ...currentstate,
            deposited_on: date
        }));

    }
    const trimspace=(val)=>{
    
        return  val.startsWith(' ')
        ? val.trimLeft()
        : val;
      }
    const handleTitle = (e) => {
        e.preventDefault();
        const val = trimspace(e.target.value);
        const key = e.target.name;
        if (key === "value") {
            if (val < 1) {
                setInvData(current => ({
                    ...current, [key]: ""
                }))
            }
            else {
                setInvData(current => ({
                    ...current, [key]: val
                }))
            }
        }
        else {
            setInvData(current => ({
                ...current, [key]: val
            }))
        }
    }
    const submit = (e) => {
        const tokenString = sessionStorage.getItem('usertoken');
        let fd = new FormData();
        let str = tokenString.replace(/["]+/g, '')
        invDataArray.map((item, index) => {

            fd.append("patient_id", props.patId);
            if(item.remarks){fd.append(`inventories[${index}]remarks`, item.remarks)}
            fd.append(`inventories[${index}]item`, item.item);
            fd.append(`inventories[${index}]deposited_at`, moment(item.deposited_on).format("YYYY-MM-DDTHH:mm:ss"));
            fd.append(`inventories[${index}]deposited_by`, item.deposited_by);
            if (item.consent_form) {
                fd.append(`inventories[${index}]consent_form`, item.consent_form);
                fd.append(`inventories[${index}]is_consent_uploaded`, true);
            }
            if (item.item_image) {
                fd.append(`inventories[${index}]item_image`, item.item_image);
            }
            fd.append(`inventories[${index}]value`, item.value > 0 ? item.value : 0);



        })


        axios.post(`${APIURL}/api/v1/service-provider/pers-inventory/ `, fd, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {


                if (res.data.status === "success") {

                    alert.success("successfully Updated")
                    props.onSuccess()

                } else {


                }

            })
            .catch(err => {


            })

    }
    const reader = new FileReader();
    const uploadImage = async (e) => {
        const key = e.target.name;
        const file = e.target.files[0];
        const imageUrl = URL.createObjectURL(file);


        if (!file) {
            alert.error("Please Select an Image")
            if (key === "image") {
                setFileName1('Upload Image')
            }
            else {
                setFileName('Upload Consent form');
            }
            return false;
        }
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
            alert.error("Select valid image")
          

            if (key === "image") {
                setFileName1('Upload Image')
            }
            else {
                setFileName('Upload Consent form');
            }
            return false;
        }
        if (file.size > 10e6) {
            alert.error("Select valid image")
            return false;
        }

     
            
                if (key === "image") {
                    setFileName1("Image Uploaded")
                    setInvData((currentData) => ({
                        ...currentData,
                        item_image: file,
                        imageUrl1: imageUrl
                    }));
                }
                else {
                    setFileName("Consent Uploaded");
                    setInvData((currentData) => ({
                        ...currentData,
                        consent_form: file,
                        imageUrl: imageUrl,
                    }));
                }

        reader.readAsDataURL(file);


    };

    const renderImage = (cellInfo) => {
        return (
            <div>{invDataArray[cellInfo.index][cellInfo.column.id] && invDataArray[cellInfo.index][cellInfo.column.id] !== null && invDataArray[cellInfo.index][cellInfo.column.id] !== "" ?
              
            <i onClick={() => {
                    setInfo(invDataArray[cellInfo.index][cellInfo.column.id]);
                    setContent("img")
                    setShow(true)
                }} class="fa fa-file-text fa-lg" style={{ color: "#33C300" }} aria-hidden="true"></i> : ""}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               
              
            <i onClick={() => {if(invDataArray[cellInfo.index].imageUrl1){
                    setInfo(invDataArray[cellInfo.index].imageUrl1);
                    setContent("img")
                    setShow(true)}
                }} class="fa fa-eye fa-lg" style={{ color:invDataArray[cellInfo.index].imageUrl1? "Highlight":"lightgrey" }} aria-hidden="true"></i> 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               
                <i onClick={() => {if(invDataArray[cellInfo.index].remarks){
                    setInfo(invDataArray[cellInfo.index].remarks);
                    setContent("remark")
                    setShow(true)}
                }} class="fa fa-comment fa-lg" style={{ color:invDataArray[cellInfo.index].remarks? "purple":"lightgray" }} aria-hidden="true"></i> </div>
        )
    }
    const renderTable = (cellInfo) => {
        return (
            <div style={{ overflow: "wrap",textTransform:"capitalize" }}>{invDataArray[cellInfo.index][cellInfo.column.id]}</div>
        )
    }
    const renderDate = (cellInfo) => {
        let dateString = invDataArray[cellInfo.index][cellInfo.column.id]
        let dateObject = new Date(dateString);

        const updatedDate = dateObject.toString();
        return (
            <div style={{ color: "black" }}>
                {updatedDate.slice(4, 16)}

            </div>
        )
    }
    const columns = [
        {
            Header: " ",
            accessor: "",
            Cell: (props) => {
                return (
                    <div style={{ marginLeft: "25%", color: "red" }}>
                        <i
                            className="fa fa-times-circle"
                            onClick={() => {
                                deleteRowScanPre(props.index);
                            }}
                        ></i>


                    </div>
                );
            },
            width: 50,
        },
        {
            Header: "Item",
            accessor: "item",
            Cell: renderTable,
            sortable: false,

        },
        {
            Header: "Value",
            accessor: "value",
            Cell: renderTable,
            sortable: false,
            // width: 100,
        },
       
        {
            Header: "Date Received",
            accessor: "deposited_on",
            Cell: renderDate,
            sortable: false,
            // width: 60,
        },
        {
            Header: "Deposited By",
            accessor: "deposited_by",
            Cell: renderTable,
            sortable: false,
            // width: 60,
        }, {
            Header: "",
            accessor: "imageUrl",
            Cell: renderImage,
            //  ({ value }) => {
            //   return value ? <img src={value} alt="Image" style={{ maxWidth: "100%", maxHeight: "100%" }} /> : null;
            // },
            sortable: false,
            width: 100,
        },



    ];
    const deleteRowScanPre = (index) => {


        const data = invDataArray;

        const filteredIndex = data.filter((item, pos) => {
            return pos !== index;
        });

        // setConfig(filtered)
        setInvDataArray(filteredIndex);

    };
    const InfoPopup = (props) => {
        return (
            <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            >
                <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
      <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
        <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
      </button>
    </Modal.Header>
    <Modal.Body style={{ paddingRight: "7%", textAlign: "center" }}>
                    <div className='flex-row' style={{textAlign:"center"}}>
                    {content==="remark"?<p className='initial-remarks'>{info}</p>
                        :<ZoomableImage src={info}/>}
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

  
    return (
        <div style={{marginTop:"2%"}}>
            <div className='add-inventory ' >

                <div className="row">


                    <div className="col-3" >
                        <span className="text-style-here">Item</span>

                        <input
                            name="item"
                            type="text"
                            className="enquiryinput"
                            placeholder=" "
                            value={invData.item}
                            onChange={handleTitle}
                            style={{ width: "98%", border: "none", fontSize: "15px" }}
                        />



                    </div>
                    <div className="col-3" >
                        <span className="text-style-here">Value</span>

                        <input
                            name="value"
                            type="number"
                            className="enquiryinput"
                            placeholder=" "
                            value={invData.value}
                            onChange={handleTitle}
                            style={{ width: "98%", border: "none", fontSize: "15px", }}
                        />



                    </div> <div className="col-3" >
                        <span className="text-style-here">Date Received</span>





                        <DatePicker
                            className="enquiryinput"
                            onChange={date => { handleDate(date) }}
                            style={{ width: "80%", border: "none" }}
                            maxDate={new Date()}
                            minDate={new Date(moment(props.admission, 'YYYY-MM-DD HH:mm:ss zZ').format('YYYY-MM-DDTHH:mm:ssZ'))}
                            value={invData.deposited_on}


                        />


                    </div> <div className="col-3" >
                        <span className="text-style-here">Deposited By</span>

                        <input
                            name="deposited_by"
                            type="text"
                            className="enquiryinput"
                            placeholder=" "
                            value={invData.deposited_by}
                            onChange={handleTitle}
                            style={{ width: "98%", border: "none", fontSize: "15px", }}
                        />



                    </div>
                   
                </div><br />
                <div className="flex-row" >
                <div  >
                     

                <textarea
                            name="remarks"
                            type="text"
                            className="enquiryinput"
                            placeholder="Remarks "
                            value={invData.remarks}
                            onChange={handleTitle}
                            style={{ width: "200px", border: "none", fontSize: "15px", }}
                        />



                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div style={{ backgroundColor: "#E4DD9F", width: "200px", textAlign:'left' }} className="custom-file">
                        <input type="file" name="image" className="custom-file-input" id="inputGroupFile01" onChange={uploadImage} />
                        <label style={{ backgroundColor: "#E4DD9F", border: "1px dotted black" }} className="custom-file-label" htmlFor="inputGroupFile01" aria-describedby="inputGroupFileAddon01">{fileName1} </label></div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div style={{ backgroundColor: "#E4DD9F", width: "200px", textAlign:'left' }} className="custom-file">
                        <input type="file" name="id_proof" className="custom-file-input" id="inputGroupFile02" onChange={uploadImage} />
                        <label style={{ backgroundColor: "#E4DD9F", border: "1px dotted black" }} className="custom-file-label" htmlFor="inputGroupFile02" aria-describedby="inputGroupFileAddon02">{fileName} </label></div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button onClick={() => {
                        if (!invData.item || invData.item === "") {
                            alert.error("please Enter item")
                        } else if (!invData.deposited_on || invData.deposited_on === "") {
                            alert.error('please Enter the Date')
                        } else if (!invData.deposited_by || invData.deposited_by === "") {
                            alert.error('please Enter the deposite details')
                        }
                        else if (!invData.consent_form || invData.consent_form === "") {
                            alert.error('please upload the Consent')
                        }
                        else {

                            setInvDataArray([...invDataArray, invData]);

                            setInvData({
                                consent_form: "",
                                deposited_by: "",
                                deposited_on: "",
                                imageUrl: "",
                                imageUrl1: "",
                                item: "",
                                item_image: "",
                                value: "",
                                remarks:""
                            })
                            setFileName("Upload Consent Form")
                            setFileName1("Upload Image")


                        }

                    }} className='inv-button '>Add</button>
                </div>



            </div>
            {invDataArray.length > 0 ? <> <div>
                <div style={{ marginBottom: "-18px" }} className='prod-div'  ><h6 className="title-of-page" style={{ marginTop: '0' }}>Preview</h6></div>
                <div className="">
                    <ReactTable
                        columns={columns}
                        data={invDataArray}
                        defaultPageSize={5}
                        style={{ overflow: "wrap", textAlign: "left" }}
                        resizable
                        className="inventory-table"
                    ></ReactTable>
                </div>

            </div>
                <div className='text-center'>
                    <button onClick={submit} className='cons-button'>Submit</button>

                </div></> : ""}
            {show ? (
                <InfoPopup
                    show={show}
                    onHide={() => {
                        setShow(false);
                    }}
                />
            ) : (
                ""
            )}

        </div>

    )
}
export default AddInventory