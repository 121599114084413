import React, { useState, useContext } from "react";
import Select from "react-select";
import axios from "axios";
import { APIURL } from "../../../Global";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";
import { useAlert } from "react-alert";
import SubmitConfirmationPopup from "../../common/SubmitConfirm";
import { ConfirmationContext } from "../../contexts/SubmitConfirmationContext";
function AddTest(props) {
  const [data, setData] = useState({
    testname: "",
    testsample: "",
    testmethod: "",
    unit: "",
    minrefrange: "",
    maxrefrange: "",
    group: { value: "", label: "" },
    country : { value:'India', label:'India' },
    cptcode: "",
   // agegroup:{value:'',label:''}, 
    description: "",
    disease: "",
    //is_verified : ""
  });
  const alert = useAlert();
  let formData =new FormData();
  const [image, setImage] = useState(null);
  const [error, setError] = useState({
    testname: "",
    testsample: "",
    testmethod: "",
    unit: "",
    minrefrange: "",
    maxrefrange: "",
    group: "",
    country: "",
    //agegroup:'',
    //is_verified:""
  });

  const group1 = [
    { value: "male", label: "MALE" },
    { value: "female", label: "FEMALE" },
  
   

    { value: "others", label: "OTHERS" },
  
  ];

  const agegroupvalue = [{ value:'1', label:'Infants' },
  { value:'2', label:'kids' },{value:'3', label:'teen'},{value:'4', label:'young'},{value:'5', label:'adult'},{value:'6', label:'elderly'}
 
]
  const [showConfirmation, setShowConfirmation,closeConfirmationPopup] = useContext(ConfirmationContext);
  const [errorValidate, setErrorValidate] = useState(false);

  //     const serviceOptions = [{ value:'general', label:'General' },
  //     { value:'service_request', label:'Service Request' },
  //     { value:'rental', label:'Equipment Request' }
  // ]

  
 
  const handleError = () => {
    setErrorValidate(false);
  };

  const handleImageUpload = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };


  const ErrorPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ color: "red" }}>
            Please check data correct or duplicated
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={props.onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
 
  const handleTitle = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;
    setData((current) => ({
      ...current,
      [key]: val,
    }));
  };
  const handleAgeGroup= (data) => {

    setData( current => ({
        ...current , agegroup: data
    }))
  }


  const handleServiceChange = (data) => {
    setData((current) => ({
      ...current,
      group: data,
    }));
  };

  const handleUnitChange = (data) => {
    setData((current) => ({
      ...current,
      country: data,
    }));
  };



 
  const validate = () => {
    let input = data;
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["testname"].trim() || input["testname"].trim() === "") {
      isValid = false;
      errors["testname"] = "Enter Test Name";
    }
   
    // if (typeof input["testname"] !== "undefined") {
    //   var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
    //   if (pattern3.test(input["testname"])) {
    //     isValid = false;
    //     errors["testname"] = "Special Characters are not allowed";
    //   }
    // }
    if (!input["testsample"].trim() || input["testsample"].trim() === "") {
      isValid = false;
      errors["testsample"] = "Enter test sample";
    }
    // if (typeof input["testsample"] !== "undefined") {
    //   var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
    //   if (pattern3.test(input["testsample"])) {
    //     isValid = false;
    //     errors["testsample"] = "Special Characters are not allowed";
    //   }
    // }
    if (!input["testmethod"].trim()|| input["testmethod"].trim() === "") {
      isValid = false;
      errors["testmethod"] = "Enter test method";
    }
    // if (typeof input["testmethod"] !== "undefined") {
    //   var pattern3 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
    //   if (pattern3.test(input["testmethod"])) {
    //     isValid = false;
    //     errors["testmethod"] = "Special Characters are not allowed";
    //   }
    // }
    if (!input["unit"] || input["unit"] === "") {
      isValid = false;
      errors["unit"] = "Enter unit";
    }
    if (!input["minrefrange"] || input["minrefrange"] === "") {
      isValid = false;
      errors["minrefrange"] = "Enter minimum refer range";
    }
  
    if (!input["maxrefrange"] || input["maxrefrange"] === "") {
      isValid = false;
      errors["maxrefrange"] = "Enter maximum refer range";
    }
 
    if (input["minrefrange"] && input["maxrefrange"] && parseFloat(input["minrefrange"]) > parseFloat(input["maxrefrange"])){
      isValid = false;
      errors["minrefrange"] = "Enter minimum refer range lesser than max";
    }
    // if (!input.agegroup || (input.agegroup && !input.agegroup.value) ) {
    //   isValid = false;
    //   errors["agegroup"] = "Select Agegroup";
    // }
    if (!input.group || (input.group && !input.group.value) ) {
      isValid = false;
      errors["group"] = "Select group";
    }
    // if (!input["country"] || input["country"] === "") {
    //   isValid = false;
    //   errors["country"] = "Select country";
    // }
  

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };

  const handleContinue = () =>{
    if(validate()){
      setShowConfirmation(true)
    }
  }
  const handleConfirm = () => {
    
    saveChanges();
  };

  const handleCancel = () => {
    closeConfirmationPopup()
  };

  const saveChanges = (e) => {
    // e.preventDefault();
      console.log("save")
      const tokenString = sessionStorage.getItem("usertoken");

      let v2 = tokenString.replace(
        /["]+/g,
        ""
      ); /* REGEX To remove double quotes from tokenstring */
if (data.maxrefrange<=99999999999999999999){
      const dataToSend = {
        test_name:data.testname.slice(0,1).toUpperCase() +data.testname.slice(1, data.testname.length),
        test_sample: data.testsample,
        test_method: data.testmethod,
        unit: data.unit,
        min_ref_range: data.minrefrange,
        max_ref_range: data.maxrefrange,
        group: data.group.value,
        country: data.country.value,
        is_verified : false,
        cpt_code: data.cptcode,
      
       // age_group :data.agegroup.value,
        description: data.description,
       asso_disease: data.disease,
      };

  const formData = new FormData();
  if (image) {
    formData.append('image', image);
  }
Object.keys(dataToSend).forEach(key => formData.append(key, dataToSend[key]));

      axios
        .post(`${APIURL}/api/v1/staff/master-labtests/`, formData, {
          headers: { Authorization: "Token " + v2 },
        })
        .then((res) => {
        
          if (res.data.status === "success") {
          alert.success('Successfully added to tests')
            setData({
              testname: "",
              testsample: "",
              testmethod: "",
              unit: "",
              minrefrange: "",
              maxrefrange: "",
              group: { value: "", label: "" },
              country: { value: "", label: "" },
              cptcode: "",
              description: "",
              disease: "",
            });
            props.modalClose();
        
          } else {
           alert.error(res.data.message)
           closeConfirmationPopup()
          }
        })
        .catch((err) => {
          closeConfirmationPopup()
          //setErrorShow(true);
        });
    }
    else{
      alert.error("The max range value limited to 20 numbers")
      closeConfirmationPopup()
    }
  };

  return (
    <>
   

    
      <h2 style={{ marginTop: "0" }} className="title-of-page">
        Add Test
      </h2>
      <div style={{display:"flex", marginLeft: "4rem", marginRight: "4rem", overlap:"hidden", padding: "0 10px"}}>
      <label htmlFor="image-upload" >Upload Image:</label>
      <input 
        id="image-upload" 
        type="file" 
        accept="image/*" 
        onChange={handleImageUpload} 
      />
      </div>
      {image && (
         <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", padding: "5px" }}>
          <img src={URL.createObjectURL(image)} alt="Selected"  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '5px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)' }} />
        </div>
      )}
      <div style={{ maxHeight: "1000px", width:"90%" }} className="config-box fit-content">
  
        <div className="feature-data pad-features">
          <div className="flex-col justify-feature">

   
            <span className="align-rental">* Test Name: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="testname"
              value={data.testname}
              onChange={handleTitle}
            />
            {error.testname ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.testname}
              </div>
            ) : null}
          </div>

          <div className="flex-col justify-feature">
            <span className="align-rental">* Test Method : </span>
            <input
              type="text"
              className="form-control title-flex"
              name="testmethod"
             
              value={data.testmethod}
              onChange={handleTitle}
            />
            {error.testmethod ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.testmethod}
              </div>
            ) : null}
          </div>

          <div className="flex-col justify-feature">
            <span className="align-rental">* Test Sample : </span>
            <input
              type="text"
              className="form-control title-flex"
              name="testsample"
             
              value={data.testsample}
              onChange={handleTitle}
            />
            {error.testsample ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.testsample}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">* Unit: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="unit"
              min="0"
              max="100"
              value={data.unit}
              onChange={handleTitle}
            />
            {error.unit ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.unit}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">* Min Range: </span>
            <input
              type="number"
              className="form-control title-flex"
              name="minrefrange"
          
            
              value={data.minrefrange}
              onChange={handleTitle}
            />
            {error.minrefrange ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.minrefrange}
              </div>
            ) : null}
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">* Max Range: </span>
            <input
              type="number"
              className="form-control title-flex"
              name="maxrefrange"
              min = "0"
              max = "100"
              value={data.maxrefrange}
              onChange={handleTitle}
            />
            {error.maxrefrange ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.maxrefrange}
              </div>
            ) : null}
          </div>
         <div className="flex-col justify-feature">
            <span className="align-rental">CPT Code: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="cptcode"
              value={data.cptcode}
              onChange={handleTitle}
            />
           
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Associate Disease: </span>
            <input
              type="text"
              className="form-control title-flex"
              name="disease"
              value={data.disease}
              onChange={handleTitle}
            />
             
          </div>
          <div className="flex-col justify-feature">
            <span className="align-rental">Description: </span>
            <textarea
              type="text"
              className="form-control title-flex"
              name="description"
              value={data.description}
              onChange={handleTitle}
            />
            
          </div>
          {/* <div className='flex-col justify-feature'>
                  <span className='align-rental'>*Age Group: </span>
                  <Select
                    value={data.agegroup}
                    onChange={(data,e)=>{handleAgeGroup(data,e)
                    }}
                    options={agegroupvalue}
                    name='agegroup'
                    className="select-currency select-flex title-flex"
                  />
                  {error.agegroup ? <div className="error-validation-msg error-feature-inventory">{error.agegroup}</div> : null}
                </div> */}

          <div className="flex-col justify-feature">
            <span className="align-rental">* Group: </span>
            <Select
              value={data.group}
              onChange={(data, e) => {
                handleServiceChange(data, e);
              }}
              options={group1}
              name="group"
              className="select-currency select-flex title-flex"
            />
            {  error.group ? (
              <div className="error-validation-msg error-feature-inventory">
                {error.group}
              </div>
            ) : null}
          </div>

           {/* <div className="flex-row name-content">
            <span className="align-rental">Country: </span>
            <Select
              value={data.country}
              onChange={(data, e) => {
                handleUnitChange(data, e);
              }}
              options={country}
              name="country"
              className="select-currency select-flex"
            />
            {error.country ? (
              <div className="error-validation-msg error-feature">
                {error.country}
              </div>
            ) : null}
          </div> */}
        
       
        </div>

        <div className="text-center">
          <button onClick={handleContinue} className="btn btn-primary btn-col mb-2">
            Save
          </button>
        </div>
      </div>
      {showConfirmation && (
            <SubmitConfirmationPopup
                message="Are you sure you want to add the test?"
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            )}
    </>
  );
}

export default AddTest;
