import { useState, useEffect } from "react"
import axios from "axios";
import { APIURL } from "../../../Global";
import { useNavigate } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

function AddNewService({ mainid, id, servicename, servicetype,cateogory, unified_code }) {
    let navigate = useNavigate();
    const [error, setError] = useState("")
    const [amount, setAmount] = useState(0)
    const [vat, setVAT] = useState(0)
    const [discount, setDiscount] = useState(0)
   
    const [description, setDescription] = useState('')
    const [returnPeriod,setReturnPeriod]=useState(0)
    const [returnable, setReturnable] = useState(false)
    let [deliveryTime,setDeliveryTime]=useState(0)
    const [dataval,setDataval]=useState([])
    const [successShow, setSuccessShow] = useState(false);

    const handleChange = (e) => {
        e.preventDefault();
        setError("")
        const val = e.target.value;
        const key = e.target.name;
        if (key === "amount") {
            if(val>=10000000000){
                setError("Enter valid amount")
            }else
            setAmount(val);
        }
        if (key === "vat") {
            if(val>100){
                setError("Enter valid amount")
            }else
            setVAT(val);
        }
        if (key === "discount") {
            if(val>100){
                setError("Enter valid amount")
            }else
                setDiscount(val);
        }
        if (key === "description") {
            setDescription(val);
        }
        if(key==="returnPeriod"){
            setReturnPeriod(val)
        }
        if(key==="deliveryTime"){
            ;
            setDeliveryTime(val)
        }
      
 
 
    };
    const handleChecked = () => {
        setReturnable(!returnable);
      };

    const sendRequest = () => {
    
        if(deliveryTime >=24){
            ;

            const time =parseInt(deliveryTime)
            const dataval=Math.floor(time/24).toString()
   
            ;
           
        
            setDeliveryTime(dataval)
          
          
        }
  if(deliveryTime<1){
    setError("Please Enter Waiting Time Period")
  }
        else{
        const tokenString = sessionStorage.getItem("usertoken");

        let v2 = tokenString.replace(
            /["]+/g,
            ""
        ); /* REGEX To remove double quotes from tokenstring */
     let unifiedvalue=Number(unified_code.split("_")[1])

        const dataToSend = {

            amount: amount,
            service: id,
            currency:"inr",
            is_active:'true',
            vat: vat,
            main_service: mainid,
            discount: discount,
            description: description,
            is_returnable:returnable,
            return_period:returnPeriod,
            delivery_time_period:deliveryTime,
            is_popular:false
        }

        const data1= {
            amount: amount,
            currency: "inr",
            is_active: 'true',
            procedure: unifiedvalue,
            vat: vat
        }

   

        axios
            .post(`${APIURL}/api/v1/service-provider/hospital-services/`, dataToSend, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {
                    setSuccessShow(true);
             

                } else {
                    setError(res.data.message)
                }
            })
            .catch((err) => {
                setError("Enter valid amount")
            });

            if (cateogory==="master_procedure"){
                axios
            .post(`${APIURL}/api/v1/service-provider/hospital-procedure/`, data1, {
                headers: { Authorization: "Token " + v2 },
            })
            .then((res) => {
                if (res.data.status === "success") {
                   // setSuccessShow(true);
                    // navigate("/selected-services")

                } else {
                   // setError(res.data.message)
                }
            })
            .catch((err) => {
                //setError("Enter valid amount")
            });
            }
        }
        // setSuccessShow(false)

    }

    const SubmitPopUp1 = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className="" >Successfully Added </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-primary btn-col" onClick={props.onHide}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (<>
        <div className="" >
            <div className="">

                <div className="formFields">

                    <div className="formField" style={{display:"flex"}}>
                        <label className="col-sm-4 service-label">*Amount<small>(Rs)</small>: </label>
                        <input
                            type="number"
                            name="amount"
                            min={0}
                            style={{width:"32%"}}
                            max={100}
                            value={amount}
                            onChange={handleChange}
                            required
                        ></input>
                    </div>
                    <div className="formField " style={{display:"flex"}}>
                        <label className="col-sm-4 service-label">VAT<small>(%)</small>: </label>
                        <input
                            type="number"
                            min={0}
                            max={100}
                            style={{width:"32%"}}
                            name="vat"
                            value={vat}
                            onChange={handleChange}
                        ></input></div>
                    <div className="formField" style={{display:"flex"}}>

                        <label className="col-sm-4 service-label">Discount<small>(%)</small>: </label>
                        <input
                            type="number"
                            min={0}
                            style={{width:"32%"}}
                            max={100}
                            name="discount"
                            value={discount}
                            onChange={handleChange}
                        ></input></div>

                    {servicetype !== "general_services" ?
                        <div className="formField " style={{ width: "95%"  }}>

                            <input type="checkbox"
                                id="returnable"
                                name="returnable"
                                value={returnable}
                                onChange={handleChecked}
                              style={{marginLeft:"8%"}}
                            />
                            <label style={{fontSize:"small"}}>Returnable</label>
                            
                           {returnable?<> <label className=" service-label">Return Period<small>(Days):</small> </label>
                            <input
                                type="text"
                                min={0}
                                size="5"
                                name="returnPeriod"
                                value={returnPeriod}
                                onChange={handleChange}
                            ></input></>:" "}



                        </div>:" "
                    }

                    <div className="formField" style={{display:"flex"}}>

                        <label className="col-sm-4 service-label ">*Waiting Time Period<small>(Days)</small>: </label>
                        <input
                            type="number"
                            style={{width:"32%", height:"32px"}}
                            min={0}
                            name="deliveryTime"
                            value={deliveryTime}
                            onChange={handleChange}
                            required
                        ></input></div>
                    <div className="formField" style={{display:"flex"}}>
                        <label className="col-sm-4 service-label">Description: </label>
                        <textarea
                            type="text"
                            style={{width:"39%",height:"55px"}}
                            name="description"
                            value={description}
                            onChange={handleChange}
                            required
                        ></textarea></div>

                    <div className="form-heading title-of-tasks">
                        <button onClick={sendRequest} className="btn formFieldButton">Add </button>
                        {error ? (
                            <div className="error-validation-msg">
                                {error}
                            </div>
                        ) : null}

                    </div>
                </div>
            </div>
        </div>
        {successShow ? (
            <SubmitPopUp1
                show={successShow}
                onHide={() => {
                    setSuccessShow(false);
                    navigate("/selected-services")
                }}
            />
        ) : null}</>
    )
}
export default AddNewService