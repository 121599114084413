import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {RegionDropdown } from "react-country-region-selector";
import axios from "axios";
import Select from 'react-select';
// import Navbar from "../common/Navbar"
// import Footer from "../common/Footer"
// import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import { useAlert } from "react-alert";
import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
import DatePicker from "react-date-picker";
import { APIURL } from "../../Global";
import SubmitConfirmationPopup from "../common/SubmitConfirm";
import { Switch } from "antd";
import { UserManagementContext } from "../contexts/UserManagementContext";
function AddDoctor(props) {
    const [error, setError] = useState({
        email: "",
        phone: "",
    });
    const [dobValue, setDobValue] = useState();
    const [, setFormattedDob] = useState("");
    const [specializations, setSpecializations] = useState([]);
    const [specializationOptions, setSpecializationOptions] = useState([])
    const [department, setDepartment] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([])
    const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
    const [validateRawPhoneMsg, setValidateRawPhoneMsg] = useState("");
    const [rawPhone, setRawPhone] = useState("");
    const [dialCode, setDialCode] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPhone, setIsValidPhone] = useState(true);
    const alert = useAlert();
    const navigate = useNavigate();
    const { state } = useLocation()
    const [selected, setSelected] = useContext(UserManagementContext)
    const [userDatas, setUserDatas] = useState({
        firstname: "",
        lastname: "",
        phone: "",
        code: "",
        email: "",
        country: "India",
        state: "",
        location: "",
        specializations: [],
        qualification: [],
        department: [],
        dob: "",
        gender: "",
        officialAccess: "false"
    });
    const inputRefs = useRef([])
    const submitButtonRef = useRef(null);

    useEffect(() => {
        const handlePopState = (e) => {
            e.preventDefault()
            console.log(selected)
            navigate('/usermanagement');
        };
        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {

            if (index === 5) {
                submitButtonRef.current.click();
            } else {
                e.preventDefault();
                const nextIndex = index + 1;
                if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const handleKey = (e) => {
        if (e.key === "Enter") {
            const activeElement = document.activeElement;
            if (
                !inputRefs.current.includes(activeElement) &&
                activeElement !== submitButtonRef.current
            ) {
                submitButtonRef.current.click();
            }
        } else if (e.altKey && e.key === "s") {
            submitButtonRef.current.click();
        } else if (e.altKey && e.key === 'c') {
            navigate("../");
        }
    };

    useEffect(() => {


        document.addEventListener("keydown", handleKey);

        return () => {
            document.removeEventListener("keydown", handleKey);
        };
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");
        axios

            .get(`${APIURL}/api/v1/service-provider/master-specializations/`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    let data = []
                    res.data.length > 0 && res.data.map((item) => {
                        data = ([...data, { value: item.id, label: item.specialization_name }])
                    })
                    setSpecializationOptions(data)

                } else {
                    alert.error(res.data.message);
                }
            });
    }, []);

    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");
        axios

            .get(`${APIURL}/api/v1/service-provider/master-departments/`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    let data = []
                    res.data.length > 0 && res.data.map((item) => {
                        data = ([...data, { value: item.id, label: item.department_name }])
                    })
                    setDepartmentOptions(data)
                    setDepartment([data[0]]);
                    setUserDatas(current => ({
                        ...current,
                        department: [1]
                    }));

                } else {
                    alert.error(res.data.message);
                }
            });
    }, []);

    const specializationChange = (selectedOptions) => {
        setError(currentState => ({
            ...currentState,
            specializations: ""
        }));

        if (!selectedOptions) {
            setSpecializations([]);
            setUserDatas(current => ({ ...current, specializations: [] }));
            return;
        }

        const selectedValues = selectedOptions.map(option => option.label);
        setSpecializations(selectedOptions);
        setUserDatas(current => ({
            ...current,
            specializations: selectedValues
        }));
    };

    const departmentChange = (selectedOptions) => {
        setError(currentState => ({
            ...currentState,
            department: ""
        }));

        if (!selectedOptions) {
            setDepartment([]);
            setUserDatas(current => ({ ...current, department: [] }));
            return;
        }

        const selectedValues = selectedOptions.map(option => option.value);
        setDepartment(selectedOptions);
        setUserDatas(current => ({
            ...current,
            department: selectedValues
        }));
    };

    const handleDOB = (date) => {
        setDobValue(date);

        let dateUpdated = date;
        if (date !== null) {
            const offset = date.getTimezoneOffset();

            dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

            let DateExtracted = dateUpdated.toISOString().split("T")[0];

            /*let dateString= DateExtracted.toString(); */
            setFormattedDob(DateExtracted);

            setUserDatas((currentstate) => ({
                ...currentstate,
                dob: DateExtracted,
            }));
        }
        setError((currentstate) => ({
            ...currentstate,
            ["dob"]: "",
        }));
    };

    const handleGenderRadioButton = (e) => {
        let genderOpted = e.target.value;
        setUserDatas((currentstate) => ({
            ...currentstate,
            gender: genderOpted,
        }));
        setError((currentstate) => ({
            ...currentstate,
            ["gender"]: "",
        }));
    };

    const handleConfirm = () => {

        handleSubmit();
        closeConfirmationPopup()
    };

    const handleCancel = () => {
        closeConfirmationPopup()
    };

    const handleSubmit = () => {
        if (rawPhone !== null && rawPhone !== "" && rawPhone !== " ") {
            setValidateRawPhoneMsg("success");
            const tokenStr = sessionStorage.getItem("usertoken");

            if (tokenStr) {
                const v = tokenStr;
                let v2 = v.replace(/["]+/g, "");
                const dataToSend = {
                    name: (selected === 'doctors' ? "Dr. " : "") + " " + userDatas.firstname + " " + userDatas.lastname,
                    location: userDatas.location,
                    state: userDatas.state,
                    country: userDatas.country,
                    specializations: userDatas.specializations,
                    email: userDatas.email,
                    qualification: userDatas.qualification,
                    phone: userDatas.phone,
                    code: userDatas.code,
                    department: userDatas.department,
                    dob: userDatas.dob,
                    gender: userDatas.gender,
                };
                if (selected === 'higher-officials') {
                    dataToSend.is_official_access = userDatas.officialAccess;
                }
                const role = selected;
                axios
                    .post(`${APIURL}/api/v1/service-provider/${role}/`, dataToSend, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Token " + v2,
                        },
                    })
                    .then((res) => {

                        if (res.data.status === "success") {
                            alert.success('Successfully added!');
                            navigate('/usermanagement');
                        } else {
                            closeConfirmationPopup();
                            alert.error(
                                res.data.message ? res.data.message : "Error in submission"
                            );
                        }
                    })
                    .catch((err) => {
                        closeConfirmationPopup();
                        alert.error("Error in submission");
                    });
            }
        } else {
            closeConfirmationPopup();
            setValidateRawPhoneMsg("error");
        }
    };

    const handleCheckEmail = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;

        setUserDatas((currentstate) => ({
            ...currentstate,
            [key]: val,
        }));

        setIsValidEmail(true);

        if (val !== "") {
            setError((currentstate) => ({
                ...currentstate,
                email: "",
            }));

            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (pattern.test(val)) {

                const data = {
                    [key]: val,
                };

                axios
                    .post(`${APIURL}/api/v1/account/check-user/`, data, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        if (res.data.status === "success") {
                            if (res.data.is_existing_user === true) {
                                setError((currentstate) => ({
                                    ...currentstate,
                                    [key]: `This ${key} is already registered!`,
                                }));
                                setIsValidEmail(false);
                            } else {
                                setIsValidEmail(true);
                            }
                        } else {
                            setIsValidEmail(true);
                        }
                    })
                    .catch((err) => { });
            }
        }
    };

    const handleCheckExistingPhone = (value, country) => {
        const rawPhone = value.slice(country.dialCode.length);
        const dialCode = value.slice(0, country.dialCode.length);

        setRawPhone(rawPhone);
        setDialCode(dialCode);
        setUserDatas((currentstate) => ({
            ...currentstate,
            phone: rawPhone,
            code: dialCode,
        }));
        setError((currentstate) => ({
            ...currentstate,
            phone: "",
        }));
        setIsValidPhone(true);

        if (value !== "" && value.length > 10) {
            const data = {
                code: dialCode,
                mobile_number: rawPhone,
            };

            axios
                .post(`${APIURL}/api/v1/account/check-user/`, data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        if (res.data.is_existing_user) {
                            setError((currentstate) => ({
                                ...currentstate,
                                phone: "This phone number is already registered!",
                            }));
                            setIsValidPhone(false);
                        } else {
                            setIsValidPhone(true);
                        }
                    } else {
                        setIsValidPhone(true);
                    }
                })
                .catch((err) => {
                    console.error("Error checking phone number:", err);
                });
        }
    };

    const handleContinue = async (e) => {
        e.preventDefault();

        if (validate() && isValidEmail && isValidPhone) {
            setShowConfirmation(true);
        }
    };

    const handleQualification = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        let arr = [];
        if (val && val.includes(",")) {
            arr = val.split(",");
        } else {
            arr.push(val);
        }
        setUserDatas((currentstate) => ({
            ...currentstate,
            [key]: arr,
        }));
        setError((currentstate) => ({
            ...currentstate,
            "qualification": "",
        }));
    };
    const handleChange = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;

        setUserDatas((currentstate) => ({
            ...currentstate,
            [key]: val,
        }));
        setError((currentstate) => ({
            ...currentstate,
            [key]: "",
        }));
    };

    const handleCountry = (val) => {
        setUserDatas((currentstate) => ({
            ...currentstate,
            country: val,
        }));
    };

    const handleRegion = (val) => {
        setUserDatas((currentstate) => ({
            ...currentstate,
            state: val,
        }));
        setError((currentstate) => ({
            ...currentstate,
            ["state"]: "",
        }));
    };

    const handlePhoneInput = (value, data, event, formattedValue) => {
        setRawPhone(value.slice(data.dialCode.length));
        setDialCode(value.slice(0, data.dialCode.length));
        setUserDatas((currentstate) => ({
            ...currentstate,
            phone: value.slice(data.dialCode.length),
            code: value.slice(0, data.dialCode.length),
        }));
        setError((currentstate) => ({
            ...currentstate,
            "phone": "",
        }));
    };

    const validate = () => {
        let input = userDatas;
        let errors = {};
        let isValid = true;
        setError({});

        if (!input["firstname"] || input["firstname"] === "") {
            isValid = false;
            errors["firstname"] = "Enter first name";
        }
        if (input["firstname"] && typeof input["firstname"] !== "undefined") {
            var pattern2 = new RegExp(/^[a-zA-Z ]+$/);
            if (!pattern2.test(input["firstname"])) {
                isValid = false;
                errors["firstname"] = "Special Characters and Numbers are not allowed";
            }
        }
        if (input["firstname"] && typeof input["firstname"] !== "undefined") {
            if (input["firstname"].length > 0 && input["firstname"].length < 3) {
                isValid = false;
                errors["firstname"] = "Must contain atleast 3 characters";
            }
        }

        if (rawPhone.length > 0 && rawPhone.length < 10) {
            isValid = false;
            errors["phone"] = "Check phone number ";
        }

        if (!input["lastname"] || input["lastname"] === "") {
            isValid = false;
            errors["lastname"] = "Enter last name";
        }

        if (input["lastname"] && typeof input["lastname"] !== "undefined") {
            var pattern3 = new RegExp(/^[a-zA-Z ]*$/);
            if (!pattern3.test(input["lastname"])) {
                isValid = false;
                errors["lastname"] = "Special Characters and Numbers are not allowed";
            }
        }

        if (selected !== 'bdo' && selected !== 'pro' && selected !== 'manager' && selected !== 'higher-officials') {
            if (!input["specializations"] || input["specializations"].length === 0 || input["specializations"][0] === ""
            ) {
                isValid = false;
                errors["specializations"] = "Select specializations";
            }
        }

        if (!input["department"] || input["department"].length === 0 || input["department"][0] === ""
        ) {
            isValid = false;
            errors["department"] = "Select departments";
        }

        if (!input["location"] || input["location"] === "") {
            isValid = false;
            errors["location"] = "Enter location";
        }

        if (!input["country"] || input["country"] === "") {
            isValid = false;
            errors["country"] = "Select a country";
        }

        if (!input["state"] || input["state"] === "") {
            isValid = false;
            errors["state"] = "Select a state";
        }

        if (!input["qualification"] || input["qualification"].length === 0 || input["qualification"][0] === "") {
            isValid = false;
            errors["qualification"] = "Enter qualification";
        }

        if (!dialCode || dialCode === "" || !rawPhone || rawPhone === "") {
            isValid = false;
            errors["phone"] = "Enter phone number";
        }

        if (
            typeof dobValue == "undefined" ||
            dobValue === "" ||
            dobValue === null
        ) {
            isValid = false;
            errors["dob"] = "Enter date of birth";
        }
        if (
            typeof userDatas.gender == "undefined" ||
            userDatas.gender === "" ||
            userDatas.gender === null
        ) {
            isValid = false;
            errors["gender"] = "Select gender";
        }

        if (typeof input["email"] !== "undefined") {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(input["email"])) {
                isValid = false;
                errors["email"] = "Enter a valid email address.";
            }
        }

        if (!input["email"]) {
            isValid = false;

            errors["email"] = "Enter email address.";
        }

        if (isValidPhone === false) {
            isValid = false;
            errors["phone"] = "Phone number already registered!";
        }
        if (isValidEmail === false) {
            isValid = false;
            errors["email"] = "Email already registered!";
        }

        setError((currentstate) => ({
            ...currentstate,
            ...errors,
        }));

        return isValid;
    };
    return (
        <div className="add-dr-page">


            <div className="add-dr-form-holder">

                <div className="quick-links" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to="/usermanagement">
                        <button type="button" className="btn btn-primary btn-col dr-btn" style={{ textTransform: 'capitalize' }}>
                            {" "}
                            Goto {selected === 'bdo' ? 'Supervisors' : selected} List{" "}
                        </button>
                    </Link>
                </div>
                <form className="add-dr-form" onSubmit={handleContinue}>
                    <h4 className="title-of-page" style={{ textTransform: 'capitalize' }}> Add {selected === 'bdo' ? 'Supervisors' : selected} Details</h4>
                    <div className="add-dr-div">
                        <div className="form-group col-lg-6 item-left-row">
                            <label className="">* First Name: </label>
                            <input
                                ref={el => inputRefs.current[0] = el}
                                onKeyDown={(e) => handleKeyDown(e, 0)}
                                className="form-control"
                                type="text"
                                maxLength="50"
                                placeholder=""
                                name="firstname"
                                onChange={handleChange}
                            />
                            {error.firstname ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {error.firstname}
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group col-lg-6 item-left-row">
                            <label className="">* Last Name: </label>

                            <input
                                ref={el => inputRefs.current[1] = el}
                                onKeyDown={(e) => handleKeyDown(e, 1)}
                                className="form-control"
                                type="text"
                                maxLength="50"
                                placeholder=""
                                name="lastname"
                                onChange={handleChange}
                            />
                            {error.lastname ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {error.lastname}
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group col-lg-6 item-left-row">
                            <label className="">* Email Address: </label>
                            <input
                                ref={el => inputRefs.current[2] = el}
                                onKeyDown={(e) => handleKeyDown(e, 2)}
                                className="form-control "
                                type="email"
                                maxLength="50"
                                placeholder=""
                                name="email"
                                onChange={handleCheckEmail}
                            />
                            {error.email ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {" "}
                                    {error.email}
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group col-lg-6 item-left-row">
                            <label className="">* Phone: </label>
                            <PhoneInput
                                inputProps={{
                                    ref: el => inputRefs.current[3] = el,
                                    tabIndex: 0,
                                    onKeyDown: e => handleKeyDown(e, 3),
                                }}
                                country={"in"}
                                onlyCountries={['in']}
                                countryCodeEditable={false}
                                onChange={handleCheckExistingPhone}
                            />
                            {error.phone ? (
                                <div style={{ zIndex: "auto" }} className="error-validation-msg">
                                    {error.phone}
                                </div>
                            ) : null}
                        </div>

                        <div className="form-group col-lg-6 item-left-row">
                            <label className="">* Qualifications: </label>
                            <input
                                ref={el => inputRefs.current[4] = el}
                                onKeyDown={(e) => handleKeyDown(e, 4)}
                                className="form-control"
                                type="text"
                                maxLength="150"
                                placeholder=""
                                name="qualification"
                                onChange={handleQualification}
                            />
                            {error.qualification ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {error.qualification}
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group col-lg-6 item-left-row">
                            <label className=""> * Location/ City : </label>
                            <input
                                ref={el => inputRefs.current[5] = el}
                                onKeyDown={(e) => handleKeyDown(e, 5)}
                                className="form-control"
                                type="text"
                                maxLength="50"
                                placeholder=""
                                name="location"
                                onChange={handleChange}
                            />
                            {error.location ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {error.location}
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group dob-check col-lg-6">
                            <div className="dob-container form-control">
                                <span>*Date of Birth : </span>
                                <DatePicker
                                    onChange={(date) => {
                                        handleDOB(date);
                                    }}
                                    maxDate={new Date()}
                                    value={dobValue}
                                />
                            </div>
                            {error.dob ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {" "}
                                    {error.dob}
                                </div>
                            ) : null}
                        </div>

                        <div className="form-group gender-radio-check col-lg-6">
                            <div className="form-control">
                                <span>*Gender:</span>&nbsp;
                                <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    value="male"
                                    checked={userDatas.gender === "male"}
                                    onChange={handleGenderRadioButton}
                                />
                                <label htmlFor="male">Male</label>

                                <input
                                    type="radio"
                                    id="female"
                                    name="gender"
                                    value="female"
                                    checked={userDatas.gender === "female"}
                                    onChange={handleGenderRadioButton}
                                />

                                <label htmlFor="female">Female</label>

                                <input
                                    type="radio"
                                    id="other"
                                    name="gender"
                                    value="other"
                                    checked={userDatas.gender === "other"}
                                    onChange={handleGenderRadioButton}
                                />
                                <label htmlFor="other">Other</label>
                            </div>
                            {error.gender ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {" "}
                                    {error.gender}
                                </div>
                            ) : null}
                        </div>

                        <div className="form-group col-lg-6 country-selector-dr">
                            <label className="">Country: </label>
                            <input
                                className="form-control"
                                type="text"
                                value={userDatas.country}
                                maxLength="150"
                                disabled={true}
                            />
                            {/* <CountryDropdown
              disabled={true}
                value={userDatas.country}
                onChange={(val) => handleCountry(val)}
              /> */}
                            {error.country ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {" "}
                                    {error.country}
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group col-lg-6 region-selector-dr">
                            <label className="">* State: </label>
                            <RegionDropdown
                                defaultOptionLabel={'Select State'}
                                country={userDatas.country}
                                value={userDatas.state}
                                onChange={(val) => handleRegion(val)}
                            />
                            {error.state ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {" "}
                                    {error.state}
                                </div>
                            ) : null}

                        </div>
                        {(selected !== 'bdo' && selected !== 'pro' && selected !== 'manager' && selected !== 'higher-officials') && <div className="form-group col-lg-6 item-left-row multi-select">
                            <label>* Speciality: </label>
                            <Select
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: '44px',
                                        border: '1px solid #E3E3E3',
                                    })
                                }}
                                isMulti
                                value={specializations}
                                onChange={(data, e) => {
                                    specializationChange(data, e);
                                }}
                                options={specializationOptions}
                                name="specializations"
                            />
                            {error.specializations && (
                                <div style={{ zIndex: "auto" }} className="error-validation-msg">
                                    {error.specializations}
                                </div>
                            )}
                        </div>}
                        <div className="form-group col-lg-6 item-left-row">
                            <label className="">* Access Departments: </label>
                            <Select
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        minHeight: '44px',
                                        border: '1px solid #E3E3E3',
                                    })
                                }}
                                isMulti
                                value={department}
                                onChange={(data, e) => {
                                    console.log(departmentOptions)
                                    departmentChange(data, e);
                                }}
                                options={departmentOptions}
                                name="department"
                            />
                            {error.department ? (
                                <div
                                    style={{ zIndex: "auto" }}
                                    className="error-validation-msg"
                                >
                                    {error.department}
                                </div>
                            ) : null}
                        </div>
                        {selected === 'higher-officials' && <div className="col-lg-6">
                            <div className="form-control" style={{ textAlign: "left", marginTop: "30px" }}>
                                Official Access: &nbsp;
                                <Switch
                                    checkedChildren="On"
                                    unCheckedChildren="Off"
                                    onChange={(checked) => {
                                        setUserDatas(current => ({
                                            ...current,
                                            officialAccess: checked ? "true" : "false"
                                        }));
                                    }} />
                            </div>
                        </div>}
                    </div>

                    <div className="form-button">

                        <button
                            ref={submitButtonRef}
                            type="submit"
                            className="btn btn-primary btn-col">
                            {" "}
                            Submit Data
                        </button>
                    </div>
                </form>
                {validateRawPhoneMsg === "error" ? (
                    <h5 style={{ color: "red" }}>Phone number field is mandatory!</h5>
                ) : (
                    ""
                )}
            </div>
            {showConfirmation && (
                <SubmitConfirmationPopup
                    message="Are you sure you want to submit the form?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
        </div>
    );
}

export default AddDoctor;