import React, { useState, useEffect, useRef } from 'react'

import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css';
import { Modal } from 'react-bootstrap';
import "./inventory.css";
import moment from "moment";
import DatePicker from 'react-date-picker';
import ZoomableImage from '../Admission/ZoomableImage';
function InvDetails(props) {
    const alert = useAlert()
    const [data, setData] = useState(props.data)
    const [show,setShow]=useState(false);
    const [info,setInfo]=useState("")
    const [change1, setChange1] = useState(false)
    const [change2, setChange2] = useState(false)


    const handleTitle = (e) => {
        e.preventDefault();
        const val = e.target.value;
        const key = e.target.name;
        if (key === "remarks") {
            if (val === "") {
                setChange1(false)
            }
            else {
                setChange1(true)
            }
        } else {
            if (val === "") {
                setChange2(false)
            }
            else {
                setChange2(true)
            }
        }
        setData(current => ({
            ...current, [key]: val
        }))
    }
    const submit = (e) => {
        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')
        let dataToSend = {}
        if (change1 && change2) {
            dataToSend = {
                remarks: data.remarks,
                value: data.value
            }
        } else if (change1) {
            dataToSend = {
                remarks: data.remarks,
            }

        }
        else {
            dataToSend = {
                value: data.value
            }
        }


        axios.put(`${APIURL}/api/v1/service-provider/pers-inventory-det/${data.id}/ `, dataToSend, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {


                if (res.data.status === "success") {
                    setChange1(false)
                    setChange2(false)
                    props.onSuccess()
                    alert.success("successfully Updated")
                    

                } else {


                }

            })
            .catch(err => {


            })

    }

    const InfoPopup = (props) => {
        return (
            <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
              <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={props.onHide}>
                <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
              </button>
            </Modal.Header>
            <Modal.Body style={{ paddingRight: "7%", textAlign: "center" }}>
              <ZoomableImage src={info} />
            </Modal.Body>
          </Modal>
        );
      };





    return (
        <div className='add-inventory ' style={{ backgroundColor: "white", border: "1px solid black" }} >

            <div className="row">

                {change1 || change2 ? <div className="flex-row" >
                    <button style={{ marginLeft: "90%", backgroundColor: "white" }} onClick={() => { submit() }} className=' '><i style={{ color: "#108963" }} class="fa fa-floppy-o fa-lg" aria-hidden="true"></i></button>
                </div> : ""}
                <div className="col-4" >
                    <span className="text-style-here">Item</span>
                    <input
                        disabled

                        type="text"
                        className="enquiryinput"
                        placeholder=" "
                        value={data.item}
                        style={{ width: "98%", fontSize: "15px", }}
                    />




                </div>
                <div className="col-4" >
                    <span className="text-style-here">Value</span>
                    <input
                        disabled
                        name="value"
                        type="text"
                        className="enquiryinput"
                        placeholder=" "
                        value={data.value!==0?data.value:""}
                        onChange={handleTitle}
                        style={{ width: "98%", fontSize: "15px", border: change2 && "1px solid #F0A639" }}
                    />




                </div>
                <div className="col-4" >
                    <span className="text-style-here">Remarks</span>
                    <textarea
                        type="text"
                        name="remarks"
                        className="enquiryinput"
                        placeholder=" "
                        value={data.remarks}
                        onChange={handleTitle}
                        style={{ width: "98%", fontSize: "15px", border: change1 && "1px solid #F0A639",paddingTop:"2%" }}
                    />




                </div>

                <div className='row'>
                    <div className='col-3'></div>
                   <div className='col-3'>
                   {data.is_consent_uploaded&&<div className="flex-row" >
                        <button onClick={() => {   setInfo(data.consent_form);
                   setShow(true)}} className=' ' style={{  border: "1px dotted #33C300",width:"180px",height:"45px", color: "#33C300",backgroundColor:"white" ,borderRadius:"10px",}} >Consent</button>
                    </div>}</div>
                    <div className='col-3'>
                    {data.acknowledgement_form!==null&&<div className="flex-row" >
                        <button onClick={() => {setInfo(data.acknowledgement_form);
                   setShow(true)  }} className=' ' style={{  border: "1px dotted #ECBB0F",width:"180px",color: "#ECBB0F",height:"45px",backgroundColor:"white" ,borderRadius:"10px"}} >Acknowledgement</button>
                    </div>}</div>

                </div>

            </div>

            {show ? (
                <InfoPopup
                    show={show}
                    onHide={() => {
                        setShow(false);
                    }}
                />
            ) : (
                ""
            )}


        </div>
    )
}
export default InvDetails