import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../Global";
import DatePicker from "react-date-picker";
import { useAlert } from 'react-alert'
import moment from 'moment'
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
function CancelSlot(props) {
    const alert = useAlert()
    const [slotGroup, setSlotGroup] = useState({})
    const [slotGroupArray, setSlotGroupArray] = useState({})
    const [date1, setDate1] = useState("")
    const [ToDate, setToDate] = useState(moment(props.item.date.slice(25, 37)).format('YYYY,MM,DD'))
    const [fromDate, setFromDate] = useState(moment(props.item.date.slice(5, 16)).format('YYYY,MM,DD'))
    const [slots, setSlots] = useState(props.item.slots)
    useEffect(() => {
      
        slots.map((item) => {
            setSlotGroup({ value: false, label: item.slot_id })
        });
    }, [slots]);
    useEffect(() => {
        let updatedAgeArray = [];
        for (var key in slotGroup) {
            if (slotGroup[key] === true) {
                updatedAgeArray.push(key);
            }
        }

        setSlotGroupArray(updatedAgeArray);

    }, [slotGroup]);
    const handleDate = (date) => {
    
        // const offset = new Date(date).getTimezoneOffset();
      
        // let dateUpdated = new Date((date).getTime() - offset * 60 * 1000);
  
        // let DateExtracted = dateUpdated.toISOString().split("T")[0];
       setDate1(date)
        
    }
   
    const handleCancelSlot = (e) => {
        //const val=e.target.value;
        const key = e.target.id;


        setSlotGroup((currentstate) => ({
            ...currentstate,
            [key]: !slotGroup[key],
        }))
    };
    const CancelSlotCall = async () => {
        var numberArray = [];
        slotGroupArray.forEach( ele => numberArray.push(+ele))
       
            const offset = date1.getTimezoneOffset();
      
            let dateUpdated = new Date(date1.getTime() - offset * 60 * 1000);
      
            let DateExtracted = dateUpdated.toISOString().split("T")[0];
          
        
        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')

        const data = {
            date: DateExtracted,
            slot: numberArray
        }
        if(DateExtracted===""){
            alert.error("Please choose the Date")
        }
        else if(numberArray.length===0){
            alert.error("Select the Slot")
        }
else{
        axios.post(`${APIURL}/api/v1/doctor/cancel-schedule/`, data, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {

                if (res.data.status === "success") {
                   alert.success("Slot Cancelled Successfully")
                   props.refresh();
                }
                else {

                }

            })
            .catch(err => {

            })
    }

    }



    return (
        <div className="container slotDisplay">
            <div className="" style={{ marginLeft: "5%" }}>
                <label>Select a date</label>
                &nbsp;&nbsp;
                <DatePicker
              
                    onChange={e => { handleDate(e) }}
                    minDate={new Date(fromDate)<new Date()?new Date():new Date(fromDate)}
                    maxDate={new Date(ToDate)}
                    value={date1}

                /></div>


            <div className="conslt-div title-of-page">
                {slots.map((item) => {
                    return (

                     !(props.slots).includes({"date":date1,"slot":item.slot_id}) &&<> <div className="" style={{ display: "flex" }}>

                            <input
                                className=""
                                type="checkbox"
                                id={item.slot_id}
                                name={item.slot_id}
                                checked={slotGroup[item.slot_id]}
                                onChange={handleCancelSlot}
                            />&nbsp;&nbsp;
                            <label style={{ fontWeight: "500", marginTop: "2%" }} className="schedule-subtitle">
                                {item.from_time}&nbsp;-&nbsp;{item.to_time}&nbsp;&nbsp;-
                                {item.token}&nbsp;Tokens{item.overbooking_count===0?"":"(+"+item.overbooking_count+")"}.</label>

                        </div></>


                    );
                })}
            </div>
            <button
                onClick={() => { date1 !== "" ? CancelSlotCall() : alert.error("Select a Date") }}
                className=" add-schedule-btn"
            >
                Cancel Slot
            </button>
        </div>
    )
}
export default CancelSlot;