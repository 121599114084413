import React, {useState, useEffect, useRef} from 'react'
import axios from "axios";
import {APIURL} from "../../Global";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import { useAlert } from "react-alert";
function EditObs(props) {
  const alert = useAlert();
    const {data, id} =props
    const [obsData, setObsData]= useState(data.observation)
    const [modalShow, setModalShow]=useState(false)
    const [errorShow, setErrorShow]= useState(false)
    const saveButtonRef = useRef(null)
    const inputRef = useRef(null)
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {  
        e.preventDefault()
        saveButtonRef.current.click();
      }
    };
    useEffect(() => {
      
      const handleKey = (e) => {
        if (e.altKey && e.key === 's') {
          e.preventDefault()
          saveButtonRef.current.click() 
        }     
      }
      document.addEventListener("keydown", handleKey);
      
      return () => {
        document.removeEventListener("keydown", handleKey);
      }
      
    }, []);
    useEffect(() => {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.selectionEnd = inputRef.current.value.length;
  }, []);
  
    
    const handleSave = ()=> {
        const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
         
         
       const dataToUpdate = 
           {
               "observations": obsData,
              // "observation_id": data.id,
               "patient_id": id
           }
     
     ;
       
         axios.put(`${APIURL}/api/v1/patient/observations-details/${data.id}/`,dataToUpdate, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     
     
         .then(res=>{
        
           if(res.status==200){
            alert.success("updated successfully")
            props.handleObs();
         
          
       }
       else{
        alert.error("error")
        
       }
     
         })
         .catch(err=>{
       
          alert.error("error")
     })

    }


  
    return (
        <div className='flex-col'>        

                <textarea         
                ref={inputRef}        
                onKeyDown={handleKeyDown}
                type="text" 
                className="form-group text-area remark-add"
                value= {obsData}
                name="remarks" 
                 onChange={(e)=>{
          
                  e.preventDefault();
                  setObsData(e.target.value)
          } }
            autoComplete="off"
                />
            <button ref={saveButtonRef} disabled={obsData=== data.observation} onClick={handleSave} className="btn  btn-square btn-primary btn-col ">Save</button>

        </div>



    )
}

export default EditObs
