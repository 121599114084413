import React, {useState} from 'react'
import axios from "axios";
import {APIURL} from "../../../Global";
import {Button ,Modal } from 'react-bootstrap';
import { useAlert } from 'react-alert';
function OtpUpdate(props) {

    const [otp, setOtp]= useState('')
    const [otpmsg,setotpmsg]= useState(false);
    const [errorOTPSubmit,setErrorOTPSubmit]=useState(false);
    const alert = useAlert()
    //const [otpStatus, setOtpStatus]= useState(false)
    const handleChange=e=>{
        e.preventDefault();
        setOtp(e.target.value)
    }

    const handleReSendOTP=(e)=> {
        e.preventDefault();

        const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') 

        const data = {
            mobile_number: props.num,
            code: props.code,
                      
        }
       
        axios.post(`${APIURL}/api/v1/account/change-number/`, {
          

           mobile_number: data.mobile_number,
          code: data.code,
             

    },{ headers: {
      "Authorization" : 'Token '+str,
     'Content-Type': 'application/json'}})
    .then(res =>{
            
            if(res.data.status === "success"){
                    
                    setotpmsg(true);
                     
                }
                
            })
     .catch(err =>{

  })
    }

    async function handleVerifyOTP() {

        if(otp!=="" && otp!==null){
          const tokenString= sessionStorage.getItem('usertoken');
   
   let str= tokenString.replace(/["]+/g, '') 

        let status = null;
           // e.preventDefault();
            const data = {
                 code : props.code,
                mobile_number: props.num,
                otp: otp
              
            }
           
    
    
            axios.put(`${APIURL}/api/v1/account/change-number/`, {
              
    
               mobile_number: data.mobile_number,
              code: data.code,
              otp: data.otp
          
    
        }, { headers: {
          "Authorization" : 'Token '+str,
         'Content-Type': 'application/json'
          }})
        .then(res =>{
                
                if(res.data.status === "success"){
                       status = true;
                           //localStorage.setItem("usertoken", JSON.stringify(res.data.token));      
           
                  //submitPhone();
                    alert.success("Updated successfully")
                    props.handler();
                    }
                    else{
                      
                      status= false;
                      alert.error(res.data.message)
                    }
                    
                })
         .catch(err =>{
          alert.error("Error in OTP verification")
        
       })
    
        }
        else{
          alert.error("Enter OTP")
        }
  }
    

          const handleRegister=(e)=>{
            e.preventDefault();    
           handleVerifyOTP();      
          
      }

    return (
        <>
        <div className='flex-col'>
        <p className="msg-otp">We have sent an OTP to the mobile number you have entered.<br/></p>
        <div className="click-resendotp-div">
                <span className="click-resendotp" onClick={handleReSendOTP}>Click here</span> to Resend OTP
                <br/>
                {otpmsg === true ? <span style={{color:"green"}}> Successfully resend OTP </span> : ""}
        </div>
            <input className="form-control otp-input" type="text" maxLength='30' name="otp" placeholder="Enter OTP here" value={otp} onChange={handleChange}
                required/>
        <br/>
        <button className='btn btn-primary btn-col' type="submit" onClick={handleRegister} >Submit</button>
      
        </div>
                       
        </>
    )
}

export default OtpUpdate
