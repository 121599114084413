import React, { useState, useEffect, useRef } from 'react'

import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from "react-alert";

import 'react-table-v6/react-table.css';

import moment from "moment";
// import DatePicker from 'react-date-picker';
import "./wound.css"

import "rsuite/dist/rsuite.min.css";


function Management(props) {
    const alert = useAlert()
    const [wound, setWound] = useState({ status: props.status })
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        if (wound.status === "healed") {
            setWound(currentstate => ({
                ...currentstate,
                treatment: "",
                progress: "",

            }))
        }
    }, [refresh])
    useEffect(() => {

        setWound(currentstate => ({
            ...currentstate,
            wound: props.woundId,
            patient: props.patientId,
            treated_time: moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
        }))

    }, [])
    const trimspace=(val)=>{
    
        return  val.startsWith(' ')
        ? val.trimLeft()
        : val;
      }
    const handleTitle = (e) => {
        e.preventDefault();
        const val = trimspace(e.target.value);
        const key = e.target.name;
        setWound(currentstate => ({
            ...currentstate,
            [key]: val
        }))
    }
    const submit = (e) => {
        if (!wound.treatment || wound.treatment === "") {
            alert.error("Enter treatment details")
        }
        else {
            const tokenString = sessionStorage.getItem('usertoken');
            const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let str = tokenString.replace(/["]+/g, '')
            axios.post(`${APIURL}/api/v1/service-provider/wound-treatment/?timeZone=${currentTimezone} `, wound, {
                headers: {

                    "Authorization": 'Token ' + str
                }
            })
                .then(res => {


                    if (res.data.status === "success") {

                        alert.success("successfully Updated")
                        props.onSuccess()

                    } else {


                    }

                })
        }
    }

    const update = (e) => {
        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let data = {
            status: wound.status,
            end_date: moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
        }

        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')
        axios.put(`${APIURL}/api/v1/service-provider/wound-info/?timeZone=${currentTimezone}&wound_id=${props.woundId}`, data, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {


                if (res.data.status === "success") {

                    alert.success("successfully Updated")
                    props.onSuccess()

                } else {
                    alert.error("error")

                }

            })

    }
    const handleChange = (e) => {
       if(wound.status==="not_healed"){

        setWound(current => ({
            ...current, status: "healed"
        }))}
        else{
            setWound(current => ({
                ...current, status: "not_healed"
            }))}
        
        setRefresh(!refresh)
    }
    const handleSubmit = (e) => {
        if (wound.status === "not_healed") {
            setWound(currentstate => ({
                ...currentstate,
                wound: props.woundId,
                patient: props.patientId,
                treated_time: moment(new Date()).format("YYYY-MM-DD hh:mm:ss")
            }))
            submit()
        } else {

            update()
        }
    }
    return (
        <div>
            <div className=' ' style={{ padding: "2%" ,backgroundColor:"#f5f5f4",}} >

                <div className="">
                  
                        <div className="col-12" >
                            <div className='' style={{textAlign:"center",border:"none"}}>
                                <span style={{ fontSize: "17px", color:wound.status==="not_healed"? "#2196F3":"gray",marginTop:"55%"  }} className="text-style-here"><b>Unhealed</b></span>
                                &nbsp; &nbsp; &nbsp; &nbsp;
                                <label class="switch">
                                    <input style={{width:"1%"}} type="checkbox" onChange={handleChange} />
                                    <span class="slider round"></span>
                                </label>
                                &nbsp; &nbsp; &nbsp; &nbsp;
                                <span style={{ fontSize: "17px",color:wound.status==="healed"? "#2196F3":"gray", marginTop:"5%" }} className="text-style-here"><b>Healed</b></span>
                            </div>
                           
                        </div>
                        <div className="col-12" >
                            &nbsp;&nbsp;<span className="text-style-here">Wound Progress</span>

                            <input
                             maxLength="255"
                                disabled={wound.status === "healed"}
                                name="progress"
                                type="text"
                                className="enquiryinput"
                                placeholder=" "
                                value={wound.progress}
                                onChange={handleTitle}
                                style={{ width: "98%", fontSize: "15px" }}
                            />

                        </div>
                  
                    <div className="col-12" >
                        &nbsp;&nbsp; <span className="text-style-here">Wound Treatment</span>

                        <textarea
                        maxLength="255"
                            disabled={wound.status === "healed"}
                            name="treatment"
                            type="text"
                            className="enquiryinputtext"
                            placeholder=" "
                            value={wound.treatment}
                            onChange={handleTitle}

                            style={{ cursor: wound.status === "healed" && "not-allowed", width: "98%", fontSize: "15px", }}
                        />

                    </div>

                </div>



            </div>
            <div>

            </div>
            <div className='text-center'>
                <button onClick={handleSubmit} className='cons-button'>Submit</button>

            </div>
        </div>
    )
}
export default Management