import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import FullCalendar, { formatDate } from '@fullcalendar/react'
import { APIURL } from "../../../Global";
import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import {Modal} from 'react-bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import { useAlert } from "react-alert";
function AvailableSlots(data) {
    const [currentEvents, setCurrentEvents] = useState([]);
    let navigate = useNavigate();
    const alert = useAlert();
    const [show, setShow] = useState(false)
    const [slots, setSlots] = useState('')
    const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10))
    const [endDate, setEndDate] = useState(getEndDate(new Date()))
    const [schedule, setSchedule] = useState([])
    let eventGuid = 1
    const [date, setDate] = useState(data.date?new Date(data.date):new Date())
    const [date1, setDate1] = useState(new Date().toISOString().slice(0, 10))
    const [date3,setDate3]=useState(new Date().setMonth(new Date().getMonth() + 3))
    const [slotId,setSlotId]=useState(data.id)
  
    const Update = (slot_id) => {

        const tokenString = sessionStorage.getItem("usertoken");
  
        let str = tokenString.replace(/["]+/g, "");
  
        const dataToSend = {
            doctor_id: data.docid1,
            slot_id:slotId,
            date:date1,
        }
      
        axios
          .put(`${APIURL}/api/v1/patient/appointments/${data.appId}/`, dataToSend , {
            headers: {
              Authorization: "Token " + str,
            },
          })
          .then((res) => {
      
            if (res.data.status === "success") {
             alert.success("Cancelled successfully")
            data.onSuccess()
            }
            else{
                
            }
          })
          .catch((err) => {
         
          });
  
      }
    useEffect(() => {
        if(date!==""){
        setDate1(date.toISOString().slice(0, 10))
        }
        else{
            setDate1("")
        }
        
    }, [date])
    useEffect(() => {
        if(startDate!==""&&endDate!=="")
        handleSchedule()
    }, [startDate,endDate])
    useEffect(() => {



        axios
            .get(`${APIURL}/api/v1/patient/appointments/availability/?start_date=${date1}&end_date=${date1}&doctor_id=${data.doc?data.doc.id:data.docid1}&src=web`, {

            })
            .then((res) => {

                if (res.data.status === "success") {
                    const data = res.data.data
                    const filtered = data.filter(item => {
                        return item.slots.length > 0
                    })

                    setSlots(res.data.data)

                }
            })
            .catch((err) => {

            });

    }, [date1])

    const handleSchedule = (date1) => {


        axios
            .get(`${APIURL}/api/v1/patient/appointments/availability/?start_date=${startDate}&end_date=${endDate}&doctor_id=${data.doc?data.doc.id:data.docid1}&src=web`, {

            })
            .then((res) => {

                if (res.data.status === "success") {
                    const data = res.data.data
                    const filtered = data.filter(item => {
                        return item.slots.length > 0
                    })

                    let currentEv = filtered.map(item => {

                     

                        return {
                            id: createEventId(),
                            title: item.slots.length ,
                            start: item.day,
                            slots: item.slots
                        }
                    })

                    setCurrentEvents(currentEv)

                    setSchedule(filtered)

                }
            })
            .catch((err) => {

            });

    }
    function getNextDate(date1) {
        var tomorrow = new Date(date1);
        var today=new Date()
        let difference = tomorrow.getTime() - today.getTime();
     
        if( Math.ceil(difference / (1000 * 3600 * 24))<92){
            tomorrow.setDate(tomorrow.getDate()+1);
            setDate(tomorrow)
        }
        else{
            alert.error("booking is available for 3 months only")
            setDate("")
        }
        


    }
    function getPrevDate(date1) {
        if(date1===""){
            setDate1(new Date().toISOString().slice(0, 10))
            setDate(new Date())
        }
        else{
            var yest = new Date(date1);
            yest.setDate(yest.getDate()-1);
            setDate(yest)
    
        }
      

    }
    function getEndDate(date) {
        const updated = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        return moment(updated).format('YYYY-MM-DD')
    }
    const createEventId = () => {
        return String(eventGuid++)
    }

    const initRef = useRef(true)

    const handleDateChange = (date) => {
        if (initRef.current) {
            initRef.current = false
        }

        else {
            const start = date.startStr.slice(0, 10)
            const end = date.endStr.slice(0, 10)

            setStartDate(start)
            setEndDate(end)
        }


    }
    const handleEventClick = (clickInfo) => {
        var theDate = clickInfo.date;
        var today=new Date()
        let difference = theDate.getTime() - today.getTime();
     
        if( Math.ceil(difference / (1000 * 3600 * 24))<92){
            theDate.setDate(theDate.getDate()+1);
            setDate(theDate)
            setShow(false)
        }
        else{
            alert.error("booking is available for 3 months only")
            setDate("")
            setSlots([])
        }
      
      
    }
  

    return (
        <div style={{marginTop:"3%"}} className='each-list-doc'>
           
            <div className='row'>
                <div className='col-2'>
                    <div className='round-button'><button onClick={() => { getPrevDate(date) }} style={{ background: "none" }} disabled={date1 === new Date().toISOString().slice(0, 10)}><i class="fa fa-angle-left" aria-hidden="true"></i></button></div>
                </div>
                <div className='col-3 ' style={{alignContent:"center",alignItems:"center"}}>
                    {date1 === new Date().toISOString().slice(0, 10) ? <><span className='day-head'>Today</span><br />
                        <span className='doc-details'>{date1}</span></> : <span className='day-head'>{date1}</span>}
                </div>


                <div className='col-4'>
                    <div className='round-button'><button onClick={() => { getNextDate(date) }} style={{ background: "none" }}><i class="fa fa-angle-right" aria-hidden="true"></i></button></div>
                </div>
                <div className='col-3'>
                    <div className='' onClick={() => { console.log("tomorrow", date1); handleSchedule(); setShow(!show) }}><span className='day-head'><i style={{cursor:"pointer", color:"#007E85"}} class="fa fa-calendar" aria-hidden="true"></i>{" "}Select Date</span></div>
                </div>
                {show? <div className='each-list-doc' style={{marginTop:"2%"}}>
                        <span className='day-head'>
                            <div className='calender-app calender-holder' style={{ width: "100%" }}>

                                <div className='calender-app-main' style={{}}>
                                    <FullCalendar
                                        plugins={[dayGridPlugin, interactionPlugin]}
                                        headerToolbar={{
                                            left: 'prev today',
                                            center: 'title',
                                            right: 'next'
                                        }}
                                        datesSet={handleDateChange}
                                        height="auto"
                                        validRange= {
                                           { start: new Date(),
                                            end:date3 }
                                          }
                                        initialView='dayGridMonth'
                                        editable={false}
                                        selectable={true}
                                        selectMirror={true}
                                        dayMaxEvents={true}
                                        weekends={true}
                                        dateClick={handleEventClick}
                                        events={[...currentEvents]}

                                    />
                                   <span style={{display:"flex",fontSize:"10px"}}> &nbsp;&nbsp;&nbsp;<i style={{fontSize:"5px",color:"#007bff", marginTop:"1.4%"}} class="fa fa-circle" aria-hidden="true"></i>&nbsp;<span>-Available slots</span></span>
                                </div>
                            </div>
                        </span>
                    </div>:""}
            </div>
            <div className='row'>
               <div className=' row' style={{paddingLeft:"5%"}}>
                    {slots.length > 0 ? slots[0].slots.map((value) => {
                        return (
                          <div style={{paddingTop:"2%"}} className='col-4' >
                               {value.remaining_token>0&&<span style={{marginLeft:"15%"}} className='doc-details'>{value.remaining_token}{" "}tokens</span>} <br />
                               <span style={{paddingTop:"-10px",paading:"1%",cursor:"pointer", borderColor:value.remaining_token===0&&"#f2e6e6", color:value.remaining_token===0&&"#3c3a4a69"}} onClick={() => {data.id?setSlotId(value.id):(date!==""&&value.remaining_token>0&&navigate("/booking-details" ,{
                                state: {
                                 slots:value,
                                 doc:data.doc,
                                 date:date1
                                }
                              }))}}  
                              className={slotId===value.id?'each-list-doc-selected doc-details':"each-list-doc doc-details"}>{value.is_online?<><i style={{color:value.remaining_token>0?"#007E85":"#3c3a4a69", fontSize:"small"}} class="fa fa-video-camera" aria-hidden="true"></i></>:
                              <i style={{color:value.remaining_token>0?"#007E85":"#3c3a4a69", fontSize:"small"}} class="fa fa-user-md" aria-hidden="true"></i>}&nbsp; {value.from.slice(0,5)}-{value.to.slice(0,5)}</span>
                            </div>
                        )
                    }) :""}
                    {data.id&&<div style={{paddingLeft:"38%"}}><button onClick={() => {Update() }}  style={{marginTop:"5%", width:"25%", padding:"2%"}} className='book-appointment-btn'> Update</button></div>}
                </div>

                
            </div>
          
        </div>
    )
}
export default AvailableSlots