import React,{useState, useEffect, useRef} from 'react';
import axios from "axios";
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import {APIURL} from "../../Global";
import EditAller from './EditAller';
import './observations.css';

function ViewAller(props){
  
  const patientId = props.patientId;
  const {data, id} =props
const [observationsArray,setObservationsArray]=useState(props.obs);
  const [errorMsg,setErrorMsg]=useState("");
   const [modalShow,setModalShow]=useState(false);
  const [errorSubmit,setErrorSubmit]=useState(false);
  const [editShow, setEditShow]= useState(false)

  const [deleteIndex, setDeleteIndex]= useState('');
  const [deleteShow, setDeleteShow]= useState(false)
  
  const [obsData, setObsData]= useState('')
     
           const [submitMsg,setSubmitMsg] = useState("");

           const [,setGetError]=useState('');
           const allergyDelButtonRef = useRef(null)
           
           useEffect(() => {
           const handleKey = (e) => {

             if ((e.altKey && e.key === 'c') && allergyDelButtonRef.current) {
                 e.preventDefault()
                 setDeleteShow(false)
             }else if ((e.altKey && e.key === 's') && allergyDelButtonRef.current) {
               e.preventDefault()
               allergyDelButtonRef.current.click()
             }else if ((e.key === 'Enter') && allergyDelButtonRef.current) {
               e.preventDefault()
               allergyDelButtonRef.current.click()
             }else if(e.altKey && e.key === "c"){
                setEditShow(false)
             }
           
           }
           document.addEventListener("keydown", handleKey);
           
           return () => {
             document.removeEventListener("keydown", handleKey);
           }
           
         }, []);
           useEffect(()=> {
            setObservationsArray(props.obs)
           },[props.obs])
          
        //    useEffect(()=> {

        //     const tokenString= localStorage.getItem('usertoken');
           
        //     let str= tokenString.replace(/["]+/g, '')  
                   
             
             
           
        //      axios.get(`${APIURL}/api/v1/hospital/add-hospital-userdetails/?patient_id=${patientId}`, { headers: {
               
        //        "Authorization" : 'Token '+str  }})
        //      .then(res=>{
                 
        //        if(res.data.status==="success"){
               
        //         setObservationsArray(res.data.observations)
         
        //        }
         
         
        //      })
        //      .catch(err=>{
         
        //  })
        
        //   },[])


const SubmitPopUp=({show,onHide})=> {
 

  return (
    <Modal
      show
      onHide
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
       backdrop="static"
        keyboard={false}
        centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{submitMsg}</h4>
       
      </Modal.Body>
      <Modal.Footer>
       
        
       <Button onClick={onHide}>Continue</Button>
       
       
      </Modal.Footer>
    </Modal>
  );
}


const DeleteConfirmPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <h4 id="confirm-reject">Are you sure you want to delete?</h4>
       
      </Modal.Body>
      <Modal.Footer><Button ref={allergyDelButtonRef} variant="danger" onClick={()=>{deleteRow(deleteIndex)}}> Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}


const SubmitErrorPopup =(props)=>{
    

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       backdrop="static"
        keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 style={{color: "red"}}> {errorMsg!=='' ? "Error:"+ ''+ errorMsg : "Error in Data Submission. Please try again!" } </h5>
       
      </Modal.Body>
      <Modal.Footer>
       
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>

       
       

      </Modal.Footer>
    </Modal>
  );
}






const renderData=(cellInfo)=> {

    let dateString = observationsArray[cellInfo.index][cellInfo.column.id];

let dateObject = new Date(dateString);

const updatedDate = dateObject.toString();
    return (<div >
      <h6> {updatedDate.slice(4,16)}</h6>
     </div>)

   }

const renderInfo=(cellInfo)=> {
  let data = observationsArray[cellInfo.index]
  return(<div>
  <h6> {data.added_by}</h6>
  </div>)
}



/*const renderObs=(cellInfo)=> {

  if(observationsArray[cellInfo.index][cellInfo.column.id]){
    const list= observationsArray[cellInfo.index][cellInfo.column.id].map((itemObs,i)=>{
              return (
             
                    <h6> {itemObs}</h6>
                 
                  )
                  });
      
      return list;
    }

  }
*/

const renderEditable=(cellInfo)=> {
  const obsList= observationsArray;

  return (<div >
    <h6> {obsList[cellInfo.index][cellInfo.column.id]}</h6>
   </div>)
 /* if(obsList[cellInfo.index][cellInfo.column.id]){
    const list= obsList[cellInfo.index][cellInfo.column.id].map((itemObs,itemIndex)=>{
    return (<>
      <div 
            className="each-item-obs-sym"
            onBlur={e=>{

               

              let obsArray = observationsArray;
        
          obsArray[cellInfo.index][cellInfo.column.id][itemIndex] = e.target.innerHTML.trim().replace(/&nbsp;/g, '')
      .replace(/&amp;/g, '&')
      .replace(/&gt;/g, '>')
      .replace(/&lt;/g, '<');


           setObservationsArray(obsArray);
       
        }
        }
            contentEditable
            dangerouslySetInnerHTML={{__html: observationsArray[cellInfo.index][cellInfo.column.id][itemIndex]}}
            onPaste={ evt => {
                  evt.preventDefault()

                  const text = evt.clipboardData.getData('text/plain')
                  document.execCommand('insertHTML', false, text)
          }}
            onKeyPress={evt => {
              const keyCode = evt.keyCode || evt.which;

              if (keyCode === 13) {
                evt.returnValue = false;
                if (evt.preventDefault) evt.preventDefault()
              } }}

             />



     
      
    
      </>
    );


  });
    return list;
  }*/

}


const editObservations = (cellInfo) => {
  setObsData(observationsArray[cellInfo])
  setEditShow(true)
}

const ObsEditPopup =(props)=>{
  
  return (
       <Modal
      {...props}
     
       aria-labelledby="example-custom-modal-styling-title"
       backdrop="static"
        keyboard={false}
        size='lg'
      >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <h3 className="title-of-tasks header"> Edit Allergy</h3>
       
        <EditAller data={obsData} id={patientId} handleObs={props.handle} allData={observationsArray} />
       
      </Modal.Body>
    </Modal>
  );
}

const dataHandle= () => {
  props.handleObs();
  setEditShow(false)
}


const callDelete=(idToDelete)=>{
  ;
  const tokenString= sessionStorage.getItem('usertoken');
   
        let str= tokenString.replace(/["]+/g, '');
       
         axios.delete(`${APIURL}/api/v1/doctor/allergies-details/${idToDelete}/`, { headers: {
           
           "Authorization" : 'Token '+str,
           'Content-Type': 'application/json'  }
     
     
         })
     
     

     
         .then(res=>{
        
           if(res.status==204){
                props.handleObs()
       }
       else{
         setErrorMsg('in Data Submission. Please try again!')
         setErrorSubmit(true)
        
       }

       setDeleteIndex('');
       setDeleteShow(false);
     
     
         })
         .catch(err=>{
            setErrorMsg('in Data Submission. Please try again!')
            setErrorSubmit(true)
     })

}
const deleteRow=(index)=>{
  const itemToDelete = observationsArray.filter((elt,i)=>{
    return i=== index
  })
 
callDelete(itemToDelete[0].id);

}

const columns = [

{
  Header: "Allergy",
  accessor: "allergy",
  Cell: renderEditable,
   sortable:false,
  
   minWidth:180,

},
{
  Header: "Date",
  accessor: "created_at",
  Cell: renderData,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:100,
  width:100,
   sortable:false

},

{
  Header: "Added By",
  accessor: "doctor_name",
  Cell: renderEditable,
  style:{
    backgroundColor:"rgba(0,0,0,0.1)"
  },
  
  minWidth:220,
  width:220,
   sortable:false

},
{
  Header: "Duration in Years",
  accessor: "duration",
  Cell: renderEditable,
   sortable:false,
   minWidth:100,
   width:170

},
{
  Header: <span className="table-header-tooltip" data-toggle = "tooltip" 
               data-placement = "top" title = "Delete Row"><i className="fas fa-trash-alt"></i></span>,
  Cell: props=>{
    return (
    <i  className="fas fa-times close-button"
    onClick={()=>{setDeleteIndex(props.index);
      setDeleteShow(true);}}>  </i>
   )
  },
  sortable:false,
  filterable:false,
  minWidth:50,
  width:50
},
{
  Header: "Edit",
  Cell: props=>{
    return (
    <button className="btn btn-primary btn-col btn-square btn-smallest" 
       onClick={()=>{editObservations(props.index)}}>Edit</button>
    )
  },
  sortable:false,
  filterable:false,
  minWidth:50,
  width:80
}






]
        
return (<>

<>
 
 





<div className="">
{/*<h5 className="title-of-tasks"> Data Table </h5>*/}

 <ReactTable 
         columns={columns}
         data={observationsArray}
         defaultPageSize = {5}
        // minRows={5}
         resizable
          className="allergy-table"               
                 
         />
 
    <br/>
    </div>



             <br/>

{submitMsg!=="" && submitMsg!== null ? 
          <SubmitPopUp
        show={modalShow}
        onHide={() =>  {setModalShow(false);
                        setSubmitMsg('');
                      }}

       
      /> : null }
         
      {errorMsg!=='' ?
    <SubmitErrorPopup
        show={errorSubmit}
        onHide={() =>  {setErrorSubmit(false);
                       setErrorMsg('');}}
       
      />: ''
    }

    {
      editShow ?
      <ObsEditPopup
        show={editShow}
        onHide={() =>  {setEditShow(false);}}
        handle={dataHandle}
      />: ''
    }


{deleteIndex!=='' ?
    <DeleteConfirmPopup
        show={deleteShow}
        onHide={() =>  {setDeleteShow(false);
                       setDeleteIndex('');}}
       
      />: ''
    }

 </>

             </>

)
}
export default ViewAller;