
import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { Modal } from 'react-bootstrap';
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import printJS from "print-js";
import ZoomableImage from '../../BDO/Admission/ZoomableImage';
import pv from "../../../assets/images/Peace-Village-Logo.png";
import moment from "moment";
function FormView(props) {
    const [patientDetails, setPatientDetails] = useState(props.patient)
    const [info, setInfo] = useState("")
    const [show, setShow] = useState(false);
    const InfoPopup = (props) => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header style={{ borderBottom: "none", height: "30px", marginTop: "2%", }}>
                    <button style={{ backgroundColor: "white", marginLeft: "95%" }} variant="secondary" onClick={() => { setShow(false) }}>
                        <i class="fa fa-times-circle fa-2x" style={{ color: "#F0A639" }} aria-hidden="true"></i>
                    </button>
                </Modal.Header>
                <Modal.Body style={{ padding: "7%" }}>
                    <div className="form-button"style={{textAlign:"center"}}>
                    <ZoomableImage  src={info} />

                    </div>
                </Modal.Body>
            </Modal>
        );
    };
    return (

        <div className='container'>
             
            <svg style={{ marginLeft: "95%", }} onClick={() => { printJS("printablediv", "html") }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#245693" d="M18 17.5v1.25A2.25 2.25 0 0 1 15.75 21h-7.5A2.25 2.25 0 0 1 6 18.75v-1.251l-1.75.001A2.25 2.25 0 0 1 2 15.25V9.254a3.25 3.25 0 0 1 3.25-3.25l.749-.001L6 5.25A2.25 2.25 0 0 1 8.25 3h7.502a2.25 2.25 0 0 1 2.25 2.25v.753h.75a3.254 3.254 0 0 1 3.252 3.25l.003 5.997a2.249 2.249 0 0 1-2.248 2.25H18Zm-2.25-4h-7.5a.75.75 0 0 0-.75.75v4.5c0 .414.336.75.75.75h7.5a.75.75 0 0 0 .75-.75v-4.5a.75.75 0 0 0-.75-.75Zm.002-9H8.25a.75.75 0 0 0-.75.75l-.001.753h9.003V5.25a.75.75 0 0 0-.75-.75Z" /></svg>
            <Print><div id="printablediv">
                <div className='row'>
<div className='col-6'> <img src={pv}  width="60%" height="60%" alt="image" /></div>
<div className='col-6'>
     <div><span>Peace Village Foundation Charitable Trust </span></div>
     <div><span>Pinangode P.O, Wayanad, Kerala, India</span></div>
     <div><span>Phone: 04936 293136  Mobile: +91 8281345535 </span></div>
     <div><span>www.peacevillage.in Email:info@peacevillage.com </span></div>
    </div>
                </div>
                <br/>
                <div className=''>
                    
                <h5 style={{textDecoration:"underline", textAlign:"center",fontSize:"22px"  }}>
                    <b>Transfer Form Details</b>

                </h5>
                
                </div><br/>


                <div  style={{textAlign:'left'}}>
                    <div
                        style={{ paddingRight: "4%" }}
                        className="list-group-flush"
                    >
                        <div
                            style={{ paddingRight: "4%" }}
                            className="list-group-flush"
                        >



                            <div className='row'>
                               <div className='col-7'><b>Name </b></div> <div style={{textTransform:"capitalize"}}className='col-5'>:{patientDetails.patient_name}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>ID </b></div><div className='col-5'>:{patientDetails.patient}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>Gender</b></div><div  className='col-5' style={{textTransform:"capitalize"}}>:{patientDetails.gender}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>Date of demise </b></div><div className='col-5'>:{patientDetails.date_of_death}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>Time of demise </b></div><div className='col-5'>:{patientDetails.time_of_death}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>Period of stay at the peace village </b> </div><div className='col-5'>:{patientDetails.admitted_date.slice(0,10)}{" To "}{patientDetails.date_of_death}</div>

                            </div><br />
                           
                           
                            <div className='row'>
                               <div className='col-7'><b>Death confirmed by </b></div><div className='col-5'>:{patientDetails.doctor_confirmed}</div>

                            </div><br />
                            {patientDetails.reason_of_death && <><div className='row'>
                               <div className='col-7'><b>Reason of death </b></div><div className='col-5'>:{patientDetails.reason_of_death}</div>

                               </div><br/></>}
                               {patientDetails.cremation_detail && <><div className='row'>
                               <div className='col-7'><b>Cremation details </b></div><div className='col-5'>:{patientDetails.cremation_detail}</div>

                                </div><br/></>}
                                <div className='row'>
                               <div className='col-7'><b>Place of demise </b></div><div style={{textTransform:"capitalize"}} className='col-5'>:{patientDetails.place_of_demise}</div>

                            </div><br />

                            {patientDetails.destination&&<><div className='row'>
                               <div className='col-7'><b>Location to which the deceased will be transported </b></div><div style={{textTransform:"capitalize"}} className='col-5'>:{patientDetails.destination}</div>

                            </div><br /></>}
                           
                            <h6 className='enquiryheader' style={{ textAlign: "left" }} > Details about the individual who retrieves the deceased.</h6>


                            <div className='row'>
                               <div className='col-7'><b>Name </b></div><div style={{textTransform:"capitalize"}} className='col-5'>:{patientDetails.retriever_name}{" "}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>Mobile Number </b></div><div className='col-5'>:{patientDetails.retriever_contact}{" "}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>Address </b></div><div className='col-5'style={{textTransform:"capitalize"}}>:{patientDetails.retriever_address}{" "}</div>

                            </div><br />
                            <div className='row'>
                               <div className='col-7'><b>Relationship with the deceased </b></div><div className='col-5'style={{textTransform:"capitalize"}}>:{patientDetails.relationship}{" "}</div>

                            </div><br />
                            {patientDetails.witness1_name && <h6 className='enquiryheader' style={{ textAlign: "left" }} > Witness1</h6>}
                            {patientDetails.witness1_name && <><div className='row'>
                               <div className='col-7'><b>Name </b></div><div style={{textTransform:"capitalize"}} className='col-5'>:{patientDetails.witness1_name}{" "}</div>

                               </div><br/></>}
                            {patientDetails.witness1_number && <><div className='row'>
                               <div className='col-7'><b>Mobile Number </b></div><div className='col-5'>:{patientDetails.witness1_number}{" "}</div>

                                </div><br/></>}
                            {patientDetails.witness1_address && <><div className='row'>
                               <div className='col-7'><b>Address </b></div><div className='col-5'style={{textTransform:"capitalize"}}>:{patientDetails.witness1_address}{" "}</div>

                                </div><br/></>}
                            {patientDetails.witness2_name && <h6 className='enquiryheader' style={{ textAlign: "left" }} > Witness2</h6>}
                            {patientDetails.witness2_name &&<> <div className='row'>
                               <div className='col-7'><b>Name </b></div><div className='col-5'style={{textTransform:"capitalize"}}>:{patientDetails.witness2_name}{" "}</div>

                                </div><br/></>}
                            {patientDetails.witness2_number && <><div className='row'>
                               <div className='col-7'><b>Mobile Number </b></div><div className='col-5'>:{patientDetails.witness2_number}{" "}</div>

                                 </div><br/></>}
                            {patientDetails.witness2_address && <><div className='row'>
                               <div className='col-7'><b>Address </b></div><div className='col-5'style={{textTransform:"capitalize"}}>:{patientDetails.witness2_address}{" "}</div>

                                </div></>}

                        </div>
                    </div>

                  
                </div>
            </div>
            </Print>
            {patientDetails.consent && <div>
                <b>Consent :</b>{" "}<i onClick={() => {

                    setInfo(patientDetails.consent);
                    setShow(true)
                }} style={{ color: "green", fontSize: "24px", marginTop: "10%" }} class="fa fa-file-image-o" aria-hidden="true"></i>
                <span style={{ float: "right" }}> </span>
            </div>}

            {show ? (
                <InfoPopup
                    show={show}
                    onHide={() => {
                        setShow(false);
                    }}
                />
            ) : (
                ""
            )}
        </div>

    )
}
export default FormView;